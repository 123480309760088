import React from "react";
import moment, {now} from "moment";
import {useEffect, useState} from "react";
import {carrierService, deliveriesService, orderDeliveryService} from "../../../services";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import {Button, Col, DatePicker, Modal, notification, Row, Spin} from "antd";
import SelectField from '../../select-field';
import NumberInputField from "../../number-input-field";
import BitInputField from "../../bit";
import Percent from "../../percent";
import FieldBlock from "../../field-block";
import '../modals.css';
import {Redirect, useHistory} from "react-router-dom";

export default function ModalCreateOrderReturnDelivery({
                                                         isShow,
                                                         setIsShow,
                                                         deliveries,
                                                         setNeedUpdate,
                                                         setStopListUpdate,
                                                         paymentType,
                                                         setPaymentType,
                                                         paymentTypeReturnDelivery,
                                                         setPaymentTypeReturnDelivery
                                                       }) {
  const [loadingContentModal, setLoadingContentModal] = useState(false);
  const [listCompanies, setListCompanies] = useState([]);
  const [listPersons, setListPersons] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [date, setDate] = useState(moment(now()));
  const [dateReturnDelivery, setDateReturnDelivery] = useState(moment(now()));
  const [priceDelivery, setPriceDelivery] = useState(null);
  const [prepaymentPercent, setPrepaymentPercent] = useState(0);
  const [priceReturnDelivery, setPriceReturnDelivery] = useState(null);
  const [prepaymentPercentReturnDelivery, setPrepaymentPercentReturnDelivery] = useState(0);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const history = useHistory();

  let bigButtonStyle = {
    width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px'
  };

  useEffect(() => {
    if (loadingContentModal) {
      carrierService.getCompanies()
        .then((companies) => {
          setListCompanies(companies.LegalEntities);
          setListPersons(companies.Persons);
        })
        .catch((error) => {
          setLoadingContentModal(false);
          notification.error({
            message: "Получение данных о компаниях", description: error,
          });
        });
    }
  }, [loadingContentModal]);

  useEffect(() => {
    if (isShow && !loadingContentModal) {
      setLoadingContentModal(true);
    }
  }, [isShow]);

  useEffect(() => {
    if (deliveries && deliveries.length) {
      const date = moment(deliveries[0].DateShipment);
      setDate(date);
      const date1 = moment(deliveries[1].DateShipment);
      setDateReturnDelivery(date1);
    }
  }, [deliveries]);

  useEffect(() => {
    if (listCompanies && listCompanies.length) {
      setLoadingContentModal(false);
    }
  }, [listCompanies]);


  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined") ? fieldsErrors[nameField][0] : null;
  };

  if (!loadingContentModal && listCompanies) {
    if (listCompanies.length && selectedCompany === null) {
      const firstCompany = listCompanies[0].Id;
      setSelectedCompany(firstCompany);
    }
  }

  const onClickSubmitBtnCreateOrder = (e) => {
    e.preventDefault();
    setLoadingContentModal(true);

    orderDeliveryService.createWithReturnDelivery(
      selectedCompany,
      deliveries[0].Id,
      priceDelivery,
      date,
      paymentType,
      prepaymentPercent,
      deliveries[1].Id,
      priceReturnDelivery,
      dateReturnDelivery,
      paymentTypeReturnDelivery,
      prepaymentPercentReturnDelivery
    )
      .then((response) => {

        if (response.status === "success") {
          notification.success({
            message: "Подача заявки", description: response.message,
          });
          setIsShow(false);
          history.push('/');
        } else if (response.status === "error") {

          setLoadingContentModal(false);
          setFieldsErrors(response.errors);
        }
      })
      .catch((error) => {
        setErrorMsg(error);
        setLoadingContentModal(false);
      });
  };

  let content;

  if (errorMsg) {
    content = (<div className="content-error-block">{errorMsg}</div>);
  } else if (deliveries && deliveries.length){
    content = (<Spin spinning={loadingContentModal} tip="Загрузка...">
        <Row gutter={[24, 26]}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <SelectField
              title="Компания"
              name="companyId"
              selectedValue={selectedCompany}
              values={listCompanies}
              onChange={(value) => {
                setSelectedPerson(null);
                setSelectedCompany(value);
              }}
              getErrors={getFieldErrors}
              notFoundText="Отсутствуют юр.лица"
              style={{width: "100%"}}
            />
          </Col>
        </Row>
        <Row gutter={[24, 26]}>
          <Col>
            <p>
              Рейс: <span style={{color: "#1CAA53"}}>№ {deliveries[0].NumberDelivery}</span></p>
          </Col>
          <Col lg={6} md={24} sm={24} xs={24}>
            <FieldBlock title="Дата загрузки" name="dateShipment" getErrors={getFieldErrors}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={date}
                format="DD.MM.YYYY"
                size="large"
                name="date_shipment"
                locale={localeDatePicker}
                allowClear={false}
                onChange={(date) => {
                  if (date != null) {
                    setDate(date);
                  }
                }}
              />
            </FieldBlock>
          </Col>
          <Col lg={9} md={12} sm={12} xs={24}>
            <BitInputField
              name="price" label="Ставка"
              priceDelivery={priceDelivery}
              setPrice={setPriceDelivery}
              setPaymentType={setPaymentType}
              getFieldErrors={getFieldErrors}
              delivery={deliveries[0]}
            />
          </Col>
          <Col lg={9} md={12} sm={12} xs={24}>
            <NumberInputField
              min={0}
              max={50}
              name="prepaymentPercent" label="Предоплата, %"
              value={prepaymentPercent} setValue={setPrepaymentPercent}
              getFieldErrors={getFieldErrors}
            />
            <Percent value={0} setPrepaymentPercent={setPrepaymentPercent}></Percent>
          </Col>
        </Row>
        <Row gutter={[24, 26]}>
          <Col>
            <p>
              Рейс: <span style={{color: "#1CAA53"}}>№ {deliveries[1].NumberDelivery}</span></p>
          </Col>
          <Col lg={6} md={24} sm={24} xs={24}>
            <FieldBlock title="Дата загрузки" name="dateShipment" getErrors={getFieldErrors}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateReturnDelivery}
                format="DD.MM.YYYY"
                size="large"
                name="date_shipment"
                locale={localeDatePicker}
                allowClear={false}
                onChange={(date) => {
                  if (date != null) {
                    setDateReturnDelivery(date);
                  }
                }}
              />
            </FieldBlock>
          </Col>
          <Col lg={9} md={12} sm={12} xs={24}>
            <BitInputField
              name="price" label="Ставка"
              priceDelivery={priceReturnDelivery}
              setPrice={setPriceReturnDelivery}
              setPaymentType={setPaymentTypeReturnDelivery}
              getFieldErrors={getFieldErrors}
              delivery={deliveries[1]}
            />
          </Col>
          <Col lg={9} md={12} sm={12} xs={24}>
            <NumberInputField
              min={0}
              max={50}
              name="prepaymentPercent" label="Предоплата, %"
              value={prepaymentPercentReturnDelivery} setValue={setPrepaymentPercentReturnDelivery}
              getFieldErrors={getFieldErrors}
            />
            <Percent value={0} setPrepaymentPercent={setPrepaymentPercentReturnDelivery}></Percent>
          </Col>
        </Row>
      </Spin>);
  } else {
    content = null;
  }

  return (
    <Modal
      title="Подача заявки"
      okText="Подать заявку"
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
        setErrorMsg(null)
      }}
      footer={[(errorMsg === null) ?
        (<Button htmlType="button"
                 style={bigButtonStyle}
                 key="submit"
                 type="primary"
                 onClick={onClickSubmitBtnCreateOrder}>
          Отправить
        </Button>) :
        null,]}
    >
      {content}
    </Modal>);
}
