import React, {useState} from 'react';
import {Row,Col} from 'antd';
import InputField from '../../../../input-field';
import DividerBlock from '../../../../divider-block';
import SelectField from "../../../../select-field";
import {systemService} from "../../../../../services";

export default function SecondStep({ setFields, stepFields, getFieldErrors, organizationalForm, country }) {

    const firstToUpper = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    function getBanks(bic, setBanksList) {
        systemService.getBanksList(bic)
            .then(data => {
                setBanksList(data);
            })
            .catch();
    }

    return (
        <>
          <DividerBlock label="Данные о руководителе"/>
          
          <Row>
            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                <InputField name="secondName" label="Фамилия" value={stepFields.secondName} setValue={value => setFields({...stepFields, secondName: firstToUpper(value)})} getFieldErrors={getFieldErrors} />
            </Col>
            <Col lg={8} sm={24} style={{ margin: "0 0 20px 0" }}>
              <InputField name="firstName" label="Имя" value={stepFields.firstName} setValue={value => setFields({...stepFields, firstName: firstToUpper(value)})} getFieldErrors={getFieldErrors} />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
              <InputField name="patronymic" label="Отчество" value={stepFields.patronymic} setValue={value => setFields({...stepFields, patronymic: firstToUpper(value)})} getFieldErrors={getFieldErrors} />
            </Col>
          </Row>
          <Row>
            { organizationalForm !== 1 ?
              <>
                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                  <InputField name="position" label="Должность" value={stepFields.position} setValue={value => setFields({...stepFields, position: firstToUpper(value)})} getFieldErrors={getFieldErrors} />
                </Col>
                  <Col lg={8} sm={24} style={{ margin: "0 0 20px 0" }}>
                      <SelectField
                          title="Основание действий"
                          name="legalReason"
                          selectedValue={stepFields.legalReason}
                          values={stepFields.legalReasonsList}
                          onChange={(value) => {
                              setFields({
                                  ...stepFields,
                                  legalReason: value,
                              });
                          }}
                          getErrors={getFieldErrors}
                          placeholder="Укажите основание действий"
                          notFoundText={null}
                          showArrow={true}
                          style={{ width: "100%" }}
                      />
                  </Col>
              </> :
                <>
                    <Col lg={8} sm={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="legalReason"
                            isDisabled={true}
                            label="Основание действий"
                            value={'Свидетельство о регистрации ИП'}
                            setValue={value => setFields({...stepFields, legalReason: value})}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                </>
            }
          </Row>

          <DividerBlock label="Банковские реквизиты" />
          
          <Row>
            <Col lg={8} sm={24} style={{ margin: "0 0 20px 0" }}>
              <InputField name="rs" label="Расчетный счет" value={stepFields.rs} setValue={value => setFields({...stepFields, rs: value.replace(/[^0-9]/g, '')})} getFieldErrors={getFieldErrors} />
            </Col>

            {country !== 1 ? (
                <Col lg={8} sm={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField name="bic" label="БИК" value={stepFields.bic} setValue={value => setFields({...stepFields, bic: value})} getFieldErrors={getFieldErrors} />
                </Col>
            ) : (
                <Col lg={8} sm={24} style={{ margin: "0 0 20px 0" }}>
                    <SelectField
                        title="БИК"
                        name="bic"
                        selectedValue={stepFields.bic}
                        values={stepFields.banksList}
                        onChange={(value) => {
                            setFields({
                                ...stepFields,
                                bic: value,
                            });
                        }}
                        onSearch={(value) => {
                            if (value.length > 2) {
                                getBanks(value, (data) => {
                                    setFields({
                                        ...stepFields,
                                        banksList: data
                                    });
                                });
                            }
                        }}
                        getErrors={getFieldErrors}
                        placeholder="Укажите БИК"
                        notFoundText={null}
                        showArrow={false}
                        style={{ width: "100%" }}
                    />
                </Col>
            )}
            {country !== 1 ? (
                <>
                  <Col lg={8} sm={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField name="bankName" label="Название банка" value={stepFields.bankName} setValue={value => setFields({...stepFields, bankName: value})} getFieldErrors={getFieldErrors} />
                  </Col>
                  <Col lg={8} sm={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField name="bankCorS" label="КОР/С банка" value={stepFields.bankCorS} setValue={value => setFields({...stepFields, bankCorS: value})} getFieldErrors={getFieldErrors} />
                  </Col>
                </>
            ) : null}
          </Row>
        </>
    );
}
