import React, {useState, useEffect, useContext} from 'react';


export default function Instruction() {
    return (
        <>
            <p className={"content-header"}>Инструкции</p>
            <p>В настоящий момент на сайте доступны инструции:</p>
            <ol>
                <li>
                    <a target="_blank" href="https://www.youtube.com/watch?v=aE5nJP9I-9o">Регистрация юридического лица</a>
                </li>
                <li>
                    <a target="_blank" href="https://www.youtube.com/watch?v=RoUd5Mhp1QQ">Использование "Ножниц"</a>
                </li>
            </ol>
            <p>
                После завершения модернизации сайта, тут будет полноценный раздел пошаговыми и интерактивными инструкциями.
            </p>
        </>
    )
}