import React, { useEffect, useState } from 'react';
import { Button, Col, Table, Row, notification } from 'antd';
import ModalCreateTrail from "../../../../modals/create-trail";
import ModalEditTrail from "../../../../modals/edit-trail";
import Spinner from "../../../../spinner";
import { tableHelper } from '../../../../../helpers';
import { companyService, systemService } from "../../../../../services";
import ModalShowCarParkInfo from "../../../../modals/show-car-park-info";

export default function Trailers({ companyId }) {

	const [showModalCreateTrail, setShowModalCreateTrail] = useState(false);
	const [showModalEditTrail, setShowModalEditTrail] = useState(false);

	const [isShowModal, setIsShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isNeedUpdateList, setIsNeedUpdateList] = useState(true);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [data, setData] = useState([]);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [carryingCapacityValues, setCarryingCapacityValues] = useState(null);

	let bigButtonStyle = { width: '190px', height: '40px', background: '#1CAA53', position: 'static', borderRadius: '10px', float: 'right', borderColor: "#1CAA53" };
	let editButtonStyle = { fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '600', fontSize: '10px', lineHeight: '12px', color: '#2B3F63', borderRadius: '0px', borderColor: 'transparent', background: "transparent", boxShadow: 'none', padding: "0px" };
	let deleteButtonStyle = { fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '600', fontSize: '10px', lineHeight: '12px', color: '#EB5757', borderRadius: '0px', borderColor: 'transparent', background: "transparent", boxShadow: 'none' };

	useEffect(() => {
		if (isNeedUpdateList) {
			setIsShowModal(true);
			setLoading(true);
			companyService.getCompanyTrails().then((response) => {
				setData(response);
				setDataLoaded(true);
				setIsNeedUpdateList(false);
				setLoading(false);
			})
		}

		if (!isShowModal) {
			systemService.getCarryingCapacity()
				.then((response) => {
					setCarryingCapacityValues(response);
				})
				.catch((error) => {
					notification.error({
						message: "Получение грузоподъемностей и вместимостей",
						description: error,
					});
				});
		}
	}, [isNeedUpdateList]);



	const columns = [
		{
			title: 'Грузо-подьемность (т)',
			dataIndex: 'carrying',
			key: 'carrying',
			width: '15%',
			sorter: (a, b) => {
				return Number(a.carrying) - Number(b.carrying);
			},
		},
		{
			title: 'Страна',
			dataIndex: 'regCountry',
			key: 'regCountry',
			width: '15%',
			sorter: (a, b) => {
				return a.regCountry.localeCompare(b.regCountry);
			},
		},
		{
			title: 'Гос.номер',
			dataIndex: 'number',
			key: 'number',
			width: '15%',
			sorter: (a, b) => {
				return a.number.localeCompare(b.number);
			},
		},
		{
			title: 'Выполнено рейсов',
			dataIndex: 'deliveryCount',
			key: 'deliveryCount',
			width: '15%',
			sorter: (a, b) => {
				return Number(a.deliveryCount) - Number(b.deliveryCount);
			},
		},
		{
			title: 'Километраж',
			dataIndex: 'mileage',
			key: 'mileage',
			width: '15%',
			sorter: (a, b) => {
				return Number(a.mileage) - Number(b.mileage);
			},
		},
		{
			title: 'Заработано',
			dataIndex: 'salary',
			key: 'salary',
			width: '15%',
			sorter: (a, b) => {
				return Number(a.salary) - Number(b.salary);
			},
		},
		{
			title: 'Анкета',
			key: 'questionnaire',

			render: (data) => {
				return (
					<React.Fragment>
						<Button
							htmlType="button"
							onClick={() => setShowModalEdit(data.id)}
							size="small"
							style={editButtonStyle}>
							Посмотреть
						</Button>
						<ModalShowCarParkInfo
							isShow={(showModalEdit === data.id)}
							onCloseModal={(isNeedRefresh = false) => {
								setShowModalEdit(false);
								setLoading(isNeedRefresh);
								setIsNeedUpdateList(isNeedRefresh);
							}}
							trailId={data.id}
							info={data}
						/>
					</React.Fragment >
				);
			}
		},
		{
			title: 'Действия',
			key: 'edit',
			render: (data) => {
				return (
					<React.Fragment>
						<Button
							htmlType="button"
							onClick={() => setShowModalEditTrail(data.id)}
							size="small"
							style={editButtonStyle}>
							Редактировать
						</Button>
						<ModalEditTrail
							isShow={(showModalEditTrail === data.id)}
							onCloseModal={() => {
								setShowModalEditTrail(null);
								setLoading(true);
								setIsNeedUpdateList(true);
							}}
							trailId={data.id}
						/>
					</React.Fragment >
				);
			}
		},
		/* {
			title: '',
			key: 'delete',
			render: (data) => {
				return (
					<React.Fragment>
						<Button
							htmlType="button"
							size="small"
							style={deleteButtonStyle}
						>
							Удалить
						</Button>
					</React.Fragment>
				);
			}
		} */
	];

	return (
		<React.Fragment>
			<Spinner loading={loading} tip="Загрузка...">
				<Row style={{ marginBottom: "25" }}>
					<Col className={"content-header"} style={{ marginBottom: "0px" }} span={12}>Прицепы</Col>
					<Col span={8} style={{ marginBottom: "0px" }} offset={4}>
						<Button type="primary"
							htmlType="button"
							style={bigButtonStyle}
							onClick={() => {
								setIsShowModal(true);
								setShowModalCreateTrail(true);
							}}>
							Создать прицеп
						</Button>
						<ModalCreateTrail
							companyId={companyId}
							driverId={null}
							isShow={showModalCreateTrail}
							setIsShow={setShowModalCreateTrail}
							setShowAttachDriverOrderDelivery={setIsShowModal}
							truck={null}
							carryingCapacityValues={carryingCapacityValues}
							setIsNeedUpdateList={setIsNeedUpdateList}
						/>
					</Col>
				</Row>
				<Col span={24}>
					<Table
						style={{ minWidth: "700px" }}
						columns={columns}
						dataSource={data}
						locale={{ emptyText: "Данные отсутствуют" }}
						rowKey="myid"
						size="small"
						pagination={{
							pageSize: 50,
						}}
						className="car-park"
						rowClassName={(record, index) => ("car-park-row")
						}
					/>

				</Col>
			</Spinner>
		</React.Fragment >
	);
}
