import axios from "axios";
import {mainConfig} from "../config";
import {httpRequest} from "./http-request";

const _apiBase = mainConfig.apiUrl;

export const informServices = {
  getInfo
}

async function getInfo() {
  return await httpRequest
    .get(`inform/info`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}
