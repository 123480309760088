import React, {useState} from 'react';
import {Col, Row, DatePicker, Checkbox} from 'antd';
import localeDatePicker from 'antd/lib/date-picker/locale/ru_RU';
import InputField from '../../../../input-field';
import NumberInputField from '../../../../number-input-field';
import SelectField from '../../../../select-field';
import FieldBlock from '../../../../field-block';
import * as moment from 'moment';
import DividerBlock from "../../../../divider-block";

export default function MainDataFields({ stepFields, setFields, getFieldErrors, edit = false }) {
	let dividerStyle = {
		margin: "15px 0px 45px 0px",
		display: "block",
		clear: "both",
		width: "100%",
		minWidth: "100%",
		height: "1px",
	};

    return (
        <>
          <Row gutter={[16,24]}>
            <Col lg={8} sm={24}>
              <InputField
                name="name"
                title='Название'
                label="Organizacijos pavadinimas"
                required={true}
                value={stepFields.name}
                setValue={(value) => {setFields({...stepFields, name: value});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={8} sm={24}>
              <InputField
                  name="fullName"
                  title='Полное название'
                  label="Pilnas pavadinimas organizacijos"
                  required={true}
                  value={stepFields.fullName}
                  setValue={(value) => {setFields({...stepFields, fullName: value});}}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
		
			<span className="divider-label">Kontaktiniai duomenys</span>
			<DividerBlock style={dividerStyle} />
		 
          <Row gutter={[16,24]}>
            <Col lg={8} sm={24}>
              <InputField
                name="firstName"
                title='Имя'
                label="Vardas"
                required={true}
                value={stepFields.firstName}
                setValue={(value) => {setFields({...stepFields, firstName: value});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={8} sm={24}>
              <InputField
                name="secondName"
                title='Фамилия'
                label="Pavardė"
                required={true}
                value={stepFields.secondName}
                setValue={(value) => {setFields({...stepFields, secondName: value});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={8} sm={24}>
              <InputField
                name="patronymic"
                title='Отчество'
                label="Antras vardas"
                required={true}
                value={stepFields.patronymic}
                setValue={(value) => {setFields({...stepFields, patronymic: value});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
          <Row gutter={[16,24]}>
            <Col lg={8} sm={24}>
              <InputField
                  name="phone"
                  title='Моб. номер'
                  label="Telefonas"
                  required={true}
                  value={stepFields.phone}
                  setValue={(value) => {setFields({...stepFields, phone: value});}}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={8} sm={24}>
              <InputField
                  name="email"
                  label="Email"
                  required={true}
                  value={stepFields.email}
                  setValue={(value) => {setFields({...stepFields, email: value});}}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
		  <span className="divider-label">Banko duomenis</span>
		  <DividerBlock style={dividerStyle} />
          <Row gutter={[16,24]}>
            <Col lg={8} sm={24}>
              <InputField
                  name="bankName"
                  title='Название банка'
                  label="Bankas"
                  required={true}
                  value={stepFields.bankName}
                  setValue={(value) => {setFields({...stepFields, bankName: value});}}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={8} sm={24}>
              <InputField
                  name="accountNumber"
                  title='Номер счёта'
                  label="Sąskaitos numeris"
                  required={true}
                  value={stepFields.accountNumber}
                  setValue={(value) => {setFields({...stepFields, accountNumber: value});}}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={8} sm={24}>
              <InputField
                  name="swift"
                  label="SWIFT"
                  required={true}
                  value={stepFields.swift}
                  setValue={(value) => {setFields({...stepFields, swift: value});}}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
			<span className="divider-label">Juridinio asmens duomenys</span>
			<DividerBlock style={dividerStyle} />
          <Row gutter={[16,24]}>
            <Col lg={8} sm={24}>
              <InputField
                  name="position"
                  title='Должность'
                  label="Pasirašiusiosios pozicija"
                  required={true}
                  value={stepFields.position}
                  setValue={(value) => {setFields({...stepFields, position: value});}}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={8} sm={24}>
              <InputField
                  name="registerNumber"
                  title='Регистрационный номер'
                  label="Registracijos numeris"
                  required={true}
                  value={stepFields.registerNumber}
                  setValue={(value) => {setFields({...stepFields, registerNumber: value});}}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={8} sm={24}>
              <InputField
                  name="legalNumber"
                  title='Юридический номер'
                  label="Asmens kodas verslininko / fizinio asmens"
                  required={true}
                  value={stepFields.legalNumber}
                  setValue={(value) => {setFields({...stepFields, legalNumber: value});}}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={8} sm={24}>
              <InputField
                name="legalReason"
                title='Основание действий'
                label="Veiksmo pagrindas"
                required={true}
                value={stepFields.legalReason}
                setValue={(value) => {setFields({...stepFields, legalReason: value});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
			<span className="divider-label">Adresus</span>
			<DividerBlock style={dividerStyle} />
          <Row gutter={[16,24]}>
            <Col lg={24} sm={24}>
              <InputField
                  name="address"
                  title='Адрес'
                  label="Adresas"
                  required={true}
                  value={stepFields.address}
                  setValue={(value) => {setFields({...stepFields, address: value});}}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={24} sm={24}>
              <InputField
                  name="mailingAddress"
                  title='Почтовый адрес'
                  label="El. pašto adresas"
                  required={true}
                  value={stepFields.mailingAddress}
                  setValue={(value) => {setFields({...stepFields, mailingAddress: value});}}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
        </>
    );
}
