import React, {useEffect, useState} from 'react';
import Spinner from "../../../spinner";
import {Button, Col, DatePicker, Icon, Row, Table} from "antd";
import {reportService} from "../../../../services/report.services";
import moment from "moment";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import SelectField from "../../../select-field";
import {tableHelper} from "../../../../helpers";


export default function ReportStatsContracts() {

  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [searchText, setSearchText] = useState('');
  // параметры для построения отчета
  const [dateStart, setDateStart] = useState(moment().startOf('month'));
  const [dateEnd, setDateEnd] = useState(moment().endOf('month'));

  const [tableData, setTableData] = useState([]);

  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateReport(true);
    }

    if (isNeedUpdateReport) {
      // запрос на получение данных отчета
      reportService
        .getStatsContracts(
          dateStart.format("YYYY-MM-DD"),
          dateEnd.format("YYYY-MM-DD")
        )
        .then((response) => {
          response.sort(function (a, b) {
            return Number(b.deliveryCount) - Number(a.deliveryCount);
          });
          const DataParsed = response.map((item) => {
            var dateLastLaunch = "";
            if (item.dateLastLaunch != "1999-11-30 00:00:00") {
              dateLastLaunch = moment(item.dateLastLaunch).format(
                "D.MM.YYYY HH:mm:ss"
              );
            }
            return {
              legalEntities: item.legalEntities,
              siteContract: item.siteContract,
              deliveryCount: item.deliveryCount,
              dateLastLaunch: dateLastLaunch,
              dateLastLaunchObj: item.dateLastLaunch,
            };
          });
          setTableData(DataParsed);
          setIsNeedUpdateReport(false);
          setLoading(false);
        })
        .catch((errorMsg) => {
          setIsNeedUpdateReport(false);
          setLoading(false);
          setErrorMsg(errorMsg);
        });
    }
  }, [isNeedUpdateReport]);

  // список столбцов таблицы
  const columns = [
    {
      title: 'Наименование перевозчика',
      dataIndex: 'legalEntities',
      key: 'legalEntities',
      width: '27%',
      sorter: (a, b) => {
        return a.legalEntities.localeCompare(b.legalEntities);
      },
      ...tableHelper.getColumnSearchProps('legalEntities', searchText, setSearchText)
    },
    {
      title: 'Договор через сайт',
      dataIndex: 'siteContract',
      key: 'siteContract',
      width: '12%',
      sorter: (a, b) => {
        return a.siteContract.localeCompare(b.siteContract);
      },
    },
    {
      title: 'Количество рейсов',
      dataIndex: 'deliveryCount',
      key: 'column2',
      width: '12%',
      sorter: (a, b) => {
        return Number(a.deliveryCount) - Number(b.deliveryCount);
      },
    },
    {
      title: ' Дата последнего рейса',
      dataIndex: 'dateLastLaunch',
      key: 'column3',
      width: '10%',
      sorter: (a, b) => {
        if (a.dateLastLaunch != "" || b.dateLastLaunch != "") {
          return moment(a.dateLastLaunchObj).unix() - moment(b.dateLastLaunchObj).unix();
        }

      },
    },
  ];

  return (
    <React.Fragment>
      <div className="reports__header">
        <h1 className="content-header">Отчет «Статистика по перевозчикам с договорами заключенными через сайт»</h1>
        <Icon type='filter'
              theme={isFilterOpen ? '' : "filled"}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen)
              }}
              className="reports__filter-toggle"/>
        {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
          <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start">
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateStart}
                format="DD.MM.YYYY"
                size="normal"
                name="dateStart"
                placeholder="Выберите дату начала периода"
                locale={localeDatePicker}
                onChange={(date) => {
                  setDateStart(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateEnd}
                format="DD.MM.YYYY"
                size="normal"
                name="dateEnd"
                placeholder="Выберите дату окончания периода"
                locale={localeDatePicker}
                onChange={(date) => {
                  setDateEnd(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{textAlign: 'right', height: '40px'}}>
              <Button type="primary"
                      htmlType="button"
                      onClick={() => {
                        setLoading(true);
                        setIsNeedUpdateReport(true);
                      }}
                      style={{backgroundColor: '#1CAA53', border: 'none', height: '100%', width: '100%'}}
              >
                Применить
              </Button>
            </Col>
          </Row>
        </div>}
      </div>
      <div className="reports__table">
        <div className="reports__table-wrapper">
          <Spinner loading={loading}>
            <Table
              style={{minWidth: '100%'}}
              columns={columns}
              dataSource={tableData}
              locale={{emptyText: "Данные отсутствуют"}}
              rowKey="key"
              size="small"
              pagination={{
                pageSize: 50
              }}
            />
          </Spinner>
        </div>
      </div>
    </React.Fragment>
  );
}
