import React, { useEffect, useState } from 'react';

import {Row, Col, DatePicker, Button, notification, Input, Checkbox} from 'antd';
import localeDatePicker from 'antd/lib/date-picker/locale/ru_RU';

import DividerBlock from "../../../divider-block";
import FieldBlock from '../../../field-block';
import InputField from "../../../input-field";
import NumberInputField from "../../../number-input-field";
import SelectField from "../../../select-field";
import Spinner from '../../../spinner';

import {
  companyService,
  consignorService,
  deliveriesService,
  distributionCenterService,
  systemService,
  trailService
} from "../../../../services";
import {uuidHelper} from "../../../../helpers/uuid.helper";
import moment from "moment/moment";


function Point(type) {

  this.uuid = uuidHelper.generateUUID();
  this.isAddressString = true;

  this.country = "Россия";
  this.countryId = 1;
  this.address = "";
  this.lat = "";
  this.lon = "";
  this.date = "";
  this.type = type;

}


export default function CreateDelivery({onCreate = null}) {

    const { TextArea } = Input;

    const [loading, setLoading] = useState(false);

    const [shipperCompaniesList, getShipperCompaniesList] = useState([]);
    const [shipperCompaniesListLoaded, getShipperCompaniesListLoaded] = useState(false);

    const [distributionCenterList, setDistributionCenterList] = useState([]);
    const [distributionCenterListLoaded, setDistributionCenterListLoaded] = useState(false);

    const [countyList, setCountryList] = useState([]);
    const [countyListLoaded, setCountryListLoaded] = useState(false);

    const [carcassTypes, setCarcassTypes] = useState([]);
    const [carcassTypesLoaded, setCarcassTypesLoaded] = useState(false);

    const [packageTypesList, setPackageTypesList] = useState([
    { Id: 'Валом', Name: 'Валом' },
    { Id: 'Паллеты', Name: 'Паллеты' },
    ]);

    const [loadingTypesList, setLoadingTypesList] = useState([
    { Id: 'Задняя', Name: 'Задняя' },
    { Id: 'Верхняя', Name: 'Верхняя' },
    { Id: 'Боковая', Name: 'Боковая' },
    { Id: 'Растентовка', Name: 'Растентовка' },
    ]);

    let dividerStyle = { margin: '15px 0px 45px 0px', display: "block", clear: "both", width: "100%", minWidth: "100%", height: "1px" }

    useEffect(() => {
    if (!shipperCompaniesListLoaded) {
      consignorService.getShipperCompaniesList()
        .then(res => {
          getShipperCompaniesListLoaded(true);
          getShipperCompaniesList(res);
        })
        .catch(errMsg => {
          getShipperCompaniesListLoaded(true);
          notification.error(
            {
              message: "Получение списка юр. лиц",
              description: errMsg
            });
        });
    }
    }, []);

    useEffect(() => {
    if (!distributionCenterListLoaded) {
      distributionCenterService.getCompanies()
        .then(res => {
          setDistributionCenterListLoaded(true);
          setDistributionCenterList(res);
        })
        .catch(errMsg => {
          setDistributionCenterListLoaded(true);
          notification.error(
            {
              message: "Получение списка распределительных центров",
              description: errMsg
            });
        });
    }
    }, []);

    useEffect(() => {
    if (!countyListLoaded) {
      systemService
        .getCountryList()
        .then(response => {
          setCountryList(response);
          setCountryListLoaded(true);
        })
        .catch(errorMsg => {
          notification.error({
            message: "Получение списка стран",
            description: errorMsg,
          });
        });
    }
    }, []);

    useEffect(() => {
    if (!carcassTypesLoaded) {
      trailService
        .getTrailTypes()
        .then((response) => {
          setCarcassTypes(response);
          setCarcassTypesLoaded(true);
        })
        .catch((errorMsg) => {
          notification.error({
            message: "Получение списка типов кузовов",
            description: errorMsg,
          });
        });
    }
    }, []);

    // Если передали в параметрах id рейса, то берем дефолтные данные формы из него
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const deliveryID = urlParams.get('deliveryID');
        if (deliveryID) {
            deliveriesService
                .getInfoDelivery(deliveryID)
                .then(resp => {
                    setNumber(resp.NumberDelivery);
                    setWeight(resp.Weight);
                    setVolume(resp.Volume);
                    setPackageType(resp.PackageType);
                    setLoadingType(resp.UnloadingType);
                    setComments(resp.Comments);
                    setShipperCompanyId(resp.CustomerCompanyID);
                    setDistributionCenterId(resp.DistributionCenter.Id);
                    setAllowedCarcassTypes(resp.AllowedCarcassTypes.map(value => {
                        return value.Id;
                    }));
                    setAssessedValue(resp.AssessedVal);
                    setNdsPrice(resp.NdsPrice);
                    setNoNdsPrice(resp.NoNdsPrice);
                    setCashPrice(resp.CashPrice);

                    // Точки рейса
                    let pointsArr = [...points];
                    // Добавить данные для первой точки загрузки
                    pointsArr[0].address = resp.PlaceLoad.Address;
                    pointsArr[0].lat = resp.PlaceLoad.CoordinateLat;
                    pointsArr[0].lon = resp.PlaceLoad.CoordinateLon;
                    pointsArr[0].country = resp.PlaceLoad.Country.Name;
                    pointsArr[0].countryId = resp.PlaceLoad.Country.Id;

                    // Добавить остальные точки
                    resp.PlacesUnload.forEach((value, index) => {
                        const pointIndexInArr = index + 1;
                        let newPoint;
                        if (pointsArr.hasOwnProperty(pointIndexInArr)) {
                            newPoint = pointsArr[pointIndexInArr];
                            newPoint.type = value.Type;
                        } else {
                            newPoint = new Point(value.Type);
                        }

                        newPoint.address = value.Address;
                        newPoint.lat = value.CoordinateLat;
                        newPoint.lon = value.CoordinateLon;
                        newPoint.country = value.Country.Name;
                        newPoint.countryId = value.Country.Id;

                        pointsArr[pointIndexInArr] = newPoint;
                    })
                    // Обновить точки рейса
                    setPoints(pointsArr);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, []);

    const [fieldsErrors, setFieldsErrors] = useState([]);

    const [shipperCompanyId, setShipperCompanyId] = useState(null);
    const [distributionCenterId, setDistributionCenterId] = useState(null);
    const [countryDeparture, setCountryDeparture] = useState(null);
    const [addressWarehouse, setAddressWarehouse] = useState(null);
    const [coordinateLoadLat, setCoordinateLoadLat] = useState('');
    const [coordinateLoadLon, setCoordinateLoadLon] = useState('');
    const [number, setNumber] = useState(null);
    const [weight, setWeight] = useState('');
    const [volume, setVolume] = useState('');
    const [packageType, setPackageType] = useState(null);
    const [ndsPrice, setNdsPrice] = useState(null);
    const [noNdsPrice, setNoNdsPrice] = useState(null);
    const [cashPrice, setCashPrice] = useState(null);
    const [loadingType, setLoadingType] = useState(null);
    const [dateShipment, setDateShipment] = useState(null);
    const [comments, setComments] = useState('');
    // const [partialCargo, setPartialCargo] = useState(false);
    const [assessedValue, setAssessedValue] = useState('');
    const [allowedCarcassTypes, setAllowedCarcassTypes] = useState([]);
    const [needUpdateDate, setNeedUpdateDate] = useState(true);


    const [points, setPoints] = useState([new Point("load"), new Point("unload")])
    const [maxPointsUnload, setMaxPointsUnload] = useState(2);
    const [maxPointsLoad, setMaxPointsLoad] = useState(2);

    const getFieldErrors = (nameField) => {
        return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
          ? fieldsErrors[nameField][0]
          : null;
    };

    const getPointErrors = (nameField, pointKey) => {
        return (fieldsErrors && typeof fieldsErrors[pointKey] !== "undefined" && typeof fieldsErrors[pointKey][nameField] !== "undefined")
          ? fieldsErrors[pointKey][nameField][0]
          : null;
    };

  const saveDelivery = () => {
    setLoading(true);

    deliveriesService.createDelivery(
      {
        ShipperCompanyId: shipperCompanyId,
        DistributionCenterId: distributionCenterId,
        CountryDeparture: countryDeparture ? countyList.find(element => {
          return element.Id === countryDeparture;
        }).Name : '',
        AddressWarehouse: addressWarehouse,
        CoordinateLoadLat: coordinateLoadLat,
        CoordinateLoadLon: coordinateLoadLon,
        Number: number,
        NumberDelivery: number,
        Weight: weight,
        Volume: volume,
        NdsPrice: ndsPrice,
        NoNdsPrice: noNdsPrice,
        CashPrice: cashPrice,
        LoadingType: loadingType,
        DateShipment: dateShipment,
        Comments: comments,
        Points: points,
        PackageType: packageType,
        AssessedValue: assessedValue,
        AllowedCarcassTypes: allowedCarcassTypes,
        NeedUpdateDate: needUpdateDate,
      }
    )
      .then(response => {
        setLoading(false);
        if (response.code === 400) {
          notification.error({
            message: "Создание рейса",
            description: response.message,
          });
          setFieldsErrors(response.errors);
        } else {
          notification.success({
            message: "Создание рейса",
            description: response.message,
          });

            if (typeof onCreate === 'function') {
                onCreate();
            }

          setTimeout(document.location.reload(), 2000);
        }

      })
      .catch(errMsg => {
        setLoading(false);

        notification.error({
          message: "Создание рейса",
          description: errMsg,
        });
      });

  };

  let bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px', borderColor: 'transparent', boxShadow: 'none' };

  const renderPoints = (pointsArr, setPointsArr, maxPoints, setMaxPoints, type) => {
      const removePointUnload = (uuid) => {
          pointsArr.forEach((e, i) => {
              if (i > 1) {
                  if (e.uuid === uuid) {
                      const newPoints = [...pointsArr];
                      newPoints.splice(i, 1);
                      setPointsArr(newPoints);
                      setMaxPoints(maxPoints - 1);
                  }
              }
          })
      };

      const updatePointsPoint = (key, value, uuid) => {
          const newPointsUnload = [...pointsArr];
          newPointsUnload.forEach((e, i) => {
              if (e.uuid === uuid) {
                  newPointsUnload[i][key] = value;
                  setPointsArr(newPointsUnload);
              }
          });
      };

      const changeAddressType = (isAddressString, uuid) => {
          updatePointsPoint('isAddressString', isAddressString, uuid);

          updatePointsPoint('lat', 0, uuid);
          updatePointsPoint('lon', 0, uuid);
          updatePointsPoint('address', '', uuid);

      }


    return pointsArr.filter((point) => point.type === type).map((point, i) => (
      <div style={{ border: '1px solid rgba(0, 0, 0, 0.1)', margin: '10px', padding: '15px' }} key={`point-${i}`}>
        <Row gutter={[16, 24]} key={i + 1} >
          <Col lg={12} md={24} sm={24} sx={24} style={{ float: 'left' }}>
            <span>Точка {point.type === 'load' ? 'загрузки': 'разгрузки'} № {i + 1}</span>
          </Col>
          <Col lg={12} md={24} sm={24} sx={24}>
            <Button
                type="danger"
                icon="close"
                onClick={() => removePointUnload(point.uuid)}
                style={{ ...bigButtonStyle, backgroundColor: 'red', color: 'white', float: 'right', width: '50px' }}
            />
          </Col>

          <Col lg={12} md={24} sm={24} sx={24}>
            <SelectField
              title="Страна"
              name="country"
              selectedValue={point.countryId}
              values={countyList}
              keyNum={point.uuid}
              onChange={(value, option) => {
                updatePointsPoint('countryId', value, point.uuid);
                updatePointsPoint('country', option.props.children, point.uuid);
              }}
              getErrors={getPointErrors}
              style={{ width: "100%" }}
            />
          </Col>

          <Col lg={12} md={24} sm={24} sx={24}>
            <FieldBlock keyNum={point.uuid} name="date" title={point.type === 'load' ? 'Дата загрузки': 'Дата разгрузки'} getErrors={getPointErrors}>
              <DatePicker
                style={{ width: "100%" }}
                format="DD.MM.YYYY"
                size="large"
                name="date"
                locale={localeDatePicker}
                allowClear={false}
                onChange={value => updatePointsPoint('date', value, point.uuid)}
              />
            </FieldBlock>
          </Col>

            <Col lg={24} md={24} sm={24} sx={24} style={{ float: 'left' }}>
                <div>
                    <div className="form-switch form-switch--role">
                        <input
                            value={true}
                            className="form-switch-input"
                            type="radio"
                            name={"isAddressString"+point.uuid}
                            id={"isAddressString"+point.uuid}
                            checked={point.isAddressString}
                            onChange={() => changeAddressType(true, point.uuid)}
                        />
                        <label className="form-switch-label" htmlFor={"isAddressString"+point.uuid}>
                            <span className="only-desktop">Адрес</span>
                            <span className="only-mobile">Адрес</span>
                        </label>
                        <input
                            value={false}
                            className="form-switch-input"
                            type="radio"
                            name={"isAddressString"+point.uuid}
                            id={"isAddressCoordinates"+point.uuid}
                            checked={!point.isAddressString}
                            onChange={() => changeAddressType(false, point.uuid)}
                        />
                        <label className="form-switch-label" htmlFor={"isAddressCoordinates"+point.uuid}>
                            <span className="only-desktop">Координаты</span>
                            <span className="only-mobile">Коор.</span>
                        </label>
                    </div>
                </div>
            </Col>
            <Col lg={24} md={24} sm={24} sx={24}>
                <InputField
                    name="address" label={point.type === 'load' ? 'Точный адрес загрузки': 'Точный адрес разгрузки'}
                    value={point.address} setValue={value => updatePointsPoint('address', value, point.uuid)}
                    keyNum={point.uuid}
                    getFieldErrors={getPointErrors}
                />
            </Col>
            {!point.isAddressString &&
            (
                <>
                    <Col lg={12} md={24} sm={24} sx={24}>
                        <InputField
                            name="latitude" label="Широта"
                            value={point.lat} setValue={value => updatePointsPoint('lat', value, point.uuid)}
                            keyNum={point.uuid}
                            getFieldErrors={getPointErrors}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} sx={24}>
                        <InputField
                            name="longitude" label="Долгота"
                            value={point.lon} setValue={value => updatePointsPoint('lon', value, point.uuid)}
                            keyNum={point.uuid}
                            getFieldErrors={getPointErrors}
                        />
                    </Col>
                </>
            )
            }
        </Row >
      </div >
    ));

  };

  const renderPointsAddButton = (points, setPoints, type, maxPoints, setMaxPoints) => {
      const addPointUnload = () => {
          if (maxPoints <= 15) {
              const newPointsUnload = [...points];
              newPointsUnload.push(new Point(type));
              setPoints(newPointsUnload);
              setMaxPoints(parseInt(maxPoints) + 1);
          }
      };
      if (maxPoints <= 20) {
          return (
              <Row gutter={[16, 24]} style={{ marginBottom: '60px' }}>
                  <Col lg={24} md={24} sm={24} sx={24} style={{ textAlign: 'center' }}>
                      <Button type="primary" size="small" htmlType="button" onClick={addPointUnload} style={{ ...bigButtonStyle }}>Добавить точку</Button>
                  </Col>
              </Row>
          );
      } else {
          return null;
      }
  }

  return (
    <Spinner loading={(!shipperCompaniesListLoaded && !distributionCenterListLoaded) || loading} tip="Загрузка...">
      <span className="divider-label">Основная информация</span>
      <DividerBlock style={dividerStyle} />

      <Row gutter={[16, 24]} >
        <Col lg={12} md={24} sm={24} sx={24}>
          <SelectField
            title="Компания"
            name="shipperCompanyId"
            selectedValue={shipperCompanyId}
            values={shipperCompaniesList}
            onChange={value => {
              setShipperCompanyId(value);
            }}
            getErrors={getFieldErrors}
            style={{ width: "100%" }}
          />
        </Col>

        <Col lg={12} md={24} sm={24} sx={24}>
          <SelectField
            title="Подразделение"
            name="distributionCenterId"
            selectedValue={distributionCenterId}
            values={distributionCenterList}
            onChange={value => {
              setDistributionCenterId(value);
            }}
            getErrors={getFieldErrors}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>

      <span className="divider-label">Информация о грузе</span>
      <DividerBlock style={dividerStyle} />
      <Row gutter={[16, 24]} >
        <Col lg={12} md={24} sm={24} sx={24}>
          <NumberInputField
            name="number" label="Номер"
            value={number} setValue={setNumber}
            getFieldErrors={getFieldErrors}
          />
        </Col>

        <Col lg={12} md={24} sm={24} sx={24}>
          <SelectField
            title="Тип упаковки"
            name="packageType"
            selectedValue={packageType}
            values={packageTypesList}
            onChange={value => {
              setPackageType(value);
            }}
            getErrors={getFieldErrors}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>

      <Row gutter={[16, 24]} >
        <Col lg={8} md={24} sm={24} sx={24}>
          <NumberInputField
            name="weight" label="Вес (кг)"
            value={weight} setValue={setWeight}
            getFieldErrors={getFieldErrors}
          />
        </Col>

        <Col lg={8} md={24} sm={24} sx={24}>
          <NumberInputField
            name="volume" label="Объём (м³)"
            value={volume} setValue={setVolume}
            getFieldErrors={getFieldErrors}
          />
        </Col>

        <Col lg={8} md={24} sm={24} sx={24}>
          <NumberInputField
            name="assessedValue" label="Оценочная стоимость"
            value={assessedValue} setValue={setAssessedValue}
            getFieldErrors={getFieldErrors}
          />
        </Col>

      </Row>

      <span className="divider-label">Цена</span>
      <DividerBlock style={dividerStyle} />

      <Row gutter={[16, 24]} >

        <Col lg={8} md={24} sm={24} sx={24}>
          <NumberInputField
            name="ndsPrice" label="Цена с НДС"
            value={ndsPrice} setValue={setNdsPrice}
            getFieldErrors={getFieldErrors}
          />
        </Col>

        <Col lg={8} md={24} sm={24} sx={24}>
          <NumberInputField
            name="noNdsPrice" label="Цена без НДС"
            value={noNdsPrice} setValue={setNoNdsPrice}
            getFieldErrors={getFieldErrors}
          />
        </Col>

        <Col lg={8} md={24} sm={24} sx={24}>
          <NumberInputField
            name="cashPrice" label="Цена наличными"
            value={cashPrice} setValue={setCashPrice}
            getFieldErrors={getFieldErrors}
          />
        </Col>

      </Row>

      <span className="divider-label">Дополнительная информация</span>
      <DividerBlock style={dividerStyle} />

      <Row gutter={[16, 24]} >

        <Col lg={12} md={24} sm={24} sx={24}>
          <SelectField
            title="Тип загрузки"
            name="loadingType"
            selectedValue={loadingType}
            values={loadingTypesList}
            onChange={value => {
              setLoadingType(value);
            }}
            getErrors={getFieldErrors}
            style={{ width: "100%" }}
          />
        </Col>

        <Col lg={24} md={24} sm={24} sx={24}>
          <SelectField
            name="type"
            mode="multiple"
            title="Тип кузова (не обязательно)"
            values={carcassTypes}
            selectedValue={allowedCarcassTypes}
            onChange={(value) => {
              setAllowedCarcassTypes(value);
            }}
            getErrors={getFieldErrors}
            style={{ width: "100%" }}
          />
        </Col>

        <Col lg={24} md={24} sm={24} sx={24}>
          <TextArea
            placeholder="Комментарий"
            value={comments}
            onChange={(e) => {
              setComments(e.target.value)
            }}
            autoSize={{ minRows: 4, maxRows: 8 }}
          />
        </Col>
      </Row>

        <Row gutter={[16, 24]}>
            <Col lg={12} md={24} sm={24} sx={24}>
                <Checkbox
                    name="needUpdateDate"
                    checked={needUpdateDate}
                    onChange={(e) => {
                        setNeedUpdateDate(e.target.checked);
                    }}
                    style={{fontSize: "10pt", paddingTop: "15px"}}
                >
                    Автоматически обновлять дату
                </Checkbox>
            </Col>
        </Row>

        <span className="divider-label">Точки загрузки</span>
        <DividerBlock style={dividerStyle} />
        <>
            {renderPoints(points, setPoints, maxPointsLoad, setMaxPointsLoad, 'load')}
        </>
        <>
            {renderPointsAddButton(points, setPoints, 'load', maxPointsLoad, setMaxPointsLoad)}
        </>

        <span className="divider-label">Точки разгрузки</span>
        <DividerBlock style={dividerStyle} />
        <>
            {renderPoints(points, setPoints, maxPointsUnload, setMaxPointsUnload, 'unload')}
        </>
        <>
            {renderPointsAddButton(points, setPoints, 'unload', maxPointsUnload, setMaxPointsUnload)}
        </>

      <Row gutter={[16, 24]}>
        <Col lg={24} md={24} sm={24} sx={24} style={{ textAlign: 'center' }}>
          <Button type="primary" size="large" htmlType="button" onClick={saveDelivery} style={{ margin: '0 5px 10px 0', ...bigButtonStyle }}>Создать</Button>
        </Col>
      </Row>

    </Spinner >
  );

}
