
import React, {useEffect, useState} from 'react';
import { Row, Col, Checkbox, DatePicker } from 'antd';
import { systemService } from "../../../../../services";
import InputField from "../../../../input-field";
import SelectField from "../../../../select-field";
import DividerBlock from '../../../../divider-block';

import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import FieldBlock from "../../../../field-block";


export default function ThirdStep({ stepFields, setFields, getFieldErrors, country, isProprietor, currentStep }) {

    let dividerStyle = { margin: '15px 0px 45px 0px', display: "block", clear: "both", width: "100%", minWidth: "100%", height: "1px" }

    useEffect(() => {
        if (currentStep === 4) {
            if (stepFields.legalRegion) {
                getRegions(stepFields.legalRegion, (data) => {
                    setFields({
                        ...stepFields,
                        legalRegionsList: data
                    });
                });

                if (stepFields.legalLocality) {
                    getLocalities(stepFields.legalLocality, stepFields.legalRegion, (data) => {
                        setFields({
                            ...stepFields,
                            legalLocalitiesList: data
                        });
                    });

                    if (stepFields.legalStreet) {
                        getStreets(stepFields.legalStreet, stepFields.legalLocality, (data) => {
                            setFields({
                                ...stepFields,
                                legalStreetsList: data
                            });
                        });

                        if (stepFields.legalHouseNumber) {
                            getHouses(stepFields.legalHouseNumber, stepFields.legalStreet, (data) => {
                                setFields({
                                    ...stepFields,
                                    legalHousesList: data
                                });
                            });
                        }
                    }
                }
            }
        }
    }, currentStep);

    function getRegions(regionName, setRegion) {
        systemService.getRegionsList(regionName)
            .then(data => {
                setRegion(data);
            })
            .catch();
    }

    function getLocalities(localityName, parentGuid, setLocalities) {
        systemService.getLocalitiesList(localityName, parentGuid)
            .then(data => {
                setLocalities(data);
            })
            .catch();
    }

    function getStreets(streetName, parentGuid, setStreets) {
        systemService.getStreetsList(streetName, parentGuid)
            .then(data => {
                setStreets(data);
            })
            .catch();
    }

    function getHouses(houseNumber, parentGuid, setHouses) {
        systemService.getHousesList(houseNumber.replace(/\D.*/, ''), parentGuid)
            .then(data => {
                setHouses(data);
            })
            .catch();
    }

    return (
        < Row gutter={[12, 24]}>
            <span className="divider-label">Юридический адрес</span>
            <DividerBlock style={dividerStyle} />
            {country === 1 ? (
                < Row gutter={[12, 24]}>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <SelectField
                            title="Регион"
                            name="legalRegion"
                            selectedValue={stepFields.legalRegion}
                            values={stepFields.legalRegionsList}
                            onChange={(value) => {
                                setFields({
                                    ...stepFields,
                                    legalRegion: value,
                                    legalLocality: null,
                                    legalStreet: null,
                                    legalHouseNumber: null,

                                    legalLocalitiesList: [],
                                    legalStreetsList: [],
                                    legalHousesList: []
                                });
                            }}
                            onSearch={(value) => {
                                if (value.length > 2) {
                                    getRegions(value, (data) => {
                                        setFields({
                                            ...stepFields,
                                            legalRegionsList: data
                                        });
                                    });
                                }
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Укажите регион"
                            notFoundText={null}
                            showArrow={false}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <SelectField
                            title="Населенный пункт"
                            name="legalLocality"
                            selectedValue={stepFields.legalLocality}
                            values={stepFields.legalLocalitiesList}
                            isDisabled={(!stepFields.legalRegion)}
                            onChange={(value) => {
                                setFields({
                                    ...stepFields,
                                    legalLocality: value,
                                    legalStreet: null,
                                    legalHouse: null,

                                    legalStreetsList: [],
                                    legalHousesList: []
                                });
                            }}
                            onSearch={(value) => {
                                if (value.length > 2) {
                                    getLocalities(value, stepFields.legalRegion, (data) => {
                                        setFields({
                                            ...stepFields,
                                            legalLocalitiesList: data
                                        });
                                    });
                                }
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Укажите населенный"
                            notFoundText={null}
                            showArrow={false}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <SelectField
                            title="Улица"
                            name="legalStreet"
                            selectedValue={stepFields.legalStreet}
                            values={stepFields.legalStreetsList}
                            isDisabled={(!stepFields.legalLocality)}
                            onChange={(value) => {
                                setFields({
                                    ...stepFields, legalStreet: value,

                                    legalHousesList: []
                                });
                            }}
                            onSearch={(value) => {
                                if (value.length >= 1) {
                                    getStreets(value, stepFields.legalLocality, (data) => {
                                        setFields({
                                            ...stepFields,
                                            legalStreetsList: data
                                        });
                                    });
                                }
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Укажите улицу"
                            notFoundText={null}
                            showArrow={false}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <SelectField
                            title="Номер дома"
                            name="legalHouseNumber"
                            selectedValue={stepFields.legalHouseNumber}
                            values={stepFields.legalHousesList}
                            isDisabled={(!stepFields.legalStreet && !stepFields.legalLocality)}
                            onChange={(value) => {
                                setFields({ ...stepFields, legalHouseNumber: value });
                            }}
                            onSearch={(value) => {
                                if (value.length > 0) {
                                    getHouses(value, stepFields.legalStreet ? stepFields.legalStreet : stepFields.legalLocality, (data) => {
                                        setFields({
                                            ...stepFields,
                                            legalHousesList: data
                                        });
                                    });
                                }
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Укажите улицу"
                            notFoundText={null}
                            showArrow={false}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="legalPremiseNumber"
                            label="Номер помещения"
                            value={stepFields.legalPremiseNumber}
                            setValue={value => setFields({ ...stepFields, legalPremiseNumber: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                </Row>
            ) : (
                < Row gutter={[12, 24]}>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="legalRegion"
                            label="Регион"
                            value={stepFields.legalRegion}
                            setValue={value => setFields({ ...stepFields, legalRegion: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="legalLocality"
                            label="Населённый пункт"
                            value={stepFields.legalLocality}
                            setValue={value => setFields({ ...stepFields, legalLocality: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="legalStreet"
                            label="Улица"
                            value={stepFields.legalStreet}
                            setValue={value => setFields({ ...stepFields, legalStreet: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="legalHouseNumber"
                            label="Номер дома"
                            value={stepFields.legalHouseNumber}
                            setValue={value => setFields({ ...stepFields, legalHouseNumber: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="legalPremiseNumber"
                            label="Номер помещения"
                            value={stepFields.legalPremiseNumber}
                            setValue={value => setFields({ ...stepFields, legalPremiseNumber: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="legalAddressPostalCode"
                            label="Почтовый индекс"
                            value={stepFields.legalAddressPostalCode}
                            setValue={value => setFields({ ...stepFields, legalAddressPostalCode: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                </Row>)
            }
            <span className="divider-label">Фактический адресс</span>
            <DividerBlock style={dividerStyle} />
            {country === 1 ? (
                <Row gutter={[12, 24]}>
                    <Col lg={16} md={24} sm={24} xs={24} ></Col>
                    <Col lg={8} md={24} sm={24} xs={24} style={{marginBottom: "30px", display: "inline", textAlign: "right"}}>
                        <span className="checkbox-label">Совпадает с юридическим адресом</span>
                        <Checkbox
                            name="accreditedIntDeliveries"
                            checked={stepFields.regAddressCheckBox}
                            onChange={(e) => {
                                setFields({
                                    ...stepFields,
                                    regAddressCheckBox: e.target.checked,
                                    regRegion: '',
                                    regLocality: '',
                                    regStreet: '',
                                    regHouseNumber: '',
                                    regPremiseNumber: '',
                                    regAddressPostalCode: '',
                                });
                            }}
                            
                        >
                        </Checkbox>
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <SelectField
                            title="Регион"
                            name="regRegion"
                            isDisabled={stepFields.regAddressCheckBox}
                            selectedValue={stepFields.regRegion}
                            values={stepFields.regRegionsList}
                            onChange={(value) => {
                                setFields({
                                    ...stepFields,
                                    regRegion: value,
                                    regLocality: null,
                                    regStreet: null,

                                    regLocalitiesList: [],
                                    regStreetsList: [],
                                    regHousesList: []
                                });
                            }}
                            onSearch={(value) => {
                                if (value.length > 2) {
                                    getRegions(value, (data) => {
                                        setFields({
                                            ...stepFields,
                                            regRegionsList: data
                                        });
                                    });
                                }
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Укажите регион"
                            notFoundText={null}
                            showArrow={false}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <SelectField
                            title="Населенный пункт"
                            name="regLocality"
                            selectedValue={stepFields.regLocality}
                            values={stepFields.regLocalitiesList}
                            isDisabled={(!stepFields.regRegion || stepFields.regAddressCheckBox)}
                            onChange={(value) => {
                                setFields({
                                    ...stepFields,
                                    regLocality: value,
                                    regStreet: null,

                                    regStreetsList: [],
                                    regHousesList: []
                                });
                            }}
                            onSearch={(value) => {
                                if (value.length > 2) {
                                    getLocalities(value, stepFields.regRegion, (data) => {
                                        setFields({
                                            ...stepFields,
                                            regLocalitiesList: data
                                        });
                                    });
                                }
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Укажите населенный"
                            notFoundText={null}
                            showArrow={false}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <SelectField
                            title="Улица"
                            name="regStreet"
                            selectedValue={stepFields.regStreet}
                            values={stepFields.regStreetsList}
                            isDisabled={(!stepFields.regLocality || stepFields.regAddressCheckBox)}
                            onChange={(value) => {
                                setFields({
                                    ...stepFields,
                                    regStreet: value,

                                    regHousesList: []
                                });
                            }}
                            onSearch={(value) => {
                                if (value.length >= 1) {
                                    getStreets(value, stepFields.regLocality, (data) => {
                                        setFields({
                                            ...stepFields,
                                            regStreetsList: data
                                        });
                                    });
                                }
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Укажите улицу"
                            notFoundText={null}
                            showArrow={false}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <SelectField
                            title="Номер дома"
                            name="regHouseNumber"
                            selectedValue={stepFields.regHouseNumber}
                            values={stepFields.regHousesList}
                            isDisabled={((!stepFields.regStreet && !stepFields.regLocality) || stepFields.regAddressCheckBox)}
                            onChange={(value) => {
                                setFields({ ...stepFields, regHouseNumber: value });
                            }}
                            onSearch={(value) => {
                                if (value.length > 0) {
                                    getHouses(value, stepFields.regStreet ? stepFields.regStreet : stepFields.regLocality, (data) => {
                                        setFields({
                                            ...stepFields,
                                            regHousesList: data
                                        });
                                    });
                                }
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Укажите дом"
                            notFoundText={null}
                            showArrow={false}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="regPremiseNumber"
                            label="Номер помещения"
                            isDisabled={stepFields.regAddressCheckBox}
                            value={stepFields.regPremiseNumber}
                            setValue={value => setFields({ ...stepFields, regPremiseNumber: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                </Row>
            ) : (
                < Row gutter={[12, 24]}>
                    <Col lg={16} md={24} sm={24} xs={24} ></Col>
                        <Col lg={8} md={24} sm={24} xs={24} style={{ marginBottom: "30px", display: "inline", textAlign: "right" }}>
                        <span className="checkbox-label">Совпадает с юридическим адресом</span>
                        <Checkbox
                            name="accreditedIntDeliveries"
                            checked={stepFields.regAddressCheckBox}
                            onChange={(e) => {
                                setFields({
                                    ...stepFields,
                                    regAddressCheckBox: e.target.checked,
                                    regRegion: '',
                                    regLocality: '',
                                    regStreet: '',
                                    regHouseNumber: '',
                                    regPremiseNumber: '',
                                    regAddressPostalCode: '',
                                });
                            }}
                            
                        >
                        </Checkbox>
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="regRegion"
                            label="Регион"
                            isDisabled={stepFields.regAddressCheckBox}
                            value={stepFields.regRegion}
                            setValue={value => setFields({ ...stepFields, regRegion: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="regLocality"
                            label="Населённый пункт"
                            isDisabled={stepFields.regAddressCheckBox}
                            value={stepFields.regLocality}
                            setValue={value => setFields({ ...stepFields, regLocality: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="regStreet"
                            label="Улица"
                            isDisabled={stepFields.regAddressCheckBox}
                            value={stepFields.regStreet}
                            setValue={value => setFields({ ...stepFields, regStreet: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="regHouseNumber"
                            label="Номер дома"
                            isDisabled={stepFields.regAddressCheckBox}
                            value={stepFields.regHouseNumber}
                            setValue={value => setFields({ ...stepFields, regHouseNumber: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="regPremiseNumber"
                            label="Номер помещения"
                            isDisabled={stepFields.regAddressCheckBox}
                            value={stepFields.regPremiseNumber}
                            setValue={value => setFields({ ...stepFields, regPremiseNumber: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="regAddressPostalCode"
                            label="Почт. Почтовый индекс"
                            isDisabled={stepFields.regAddressCheckBox}
                            value={stepFields.regAddressPostalCode}
                            setValue={value => setFields({ ...stepFields, regAddressPostalCode: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                </Row>)
            }
            {!isProprietor &&
                <>
                    < Row gutter={[12, 24]}>
                        <span className="divider-label">Почтовый адрес</span>
                        <DividerBlock style={dividerStyle} />
                        {country === 1 ? (
                            < Row gutter={[12, 24]}>
                                <Col lg={16} md={24} sm={24} xs={24} ></Col>
                            <Col lg={8} md={24} sm={24} xs={24} style={{ marginBottom: "30px", display: "inline", textAlign: "right" }}>
                                    <span className="checkbox-label">Совпадает с фактическим адресом</span>
                                    <Checkbox
                                        name="accreditedIntDeliveries"
                                        checked={stepFields.factAddressCheckBox}
                                        onChange={(e) => {
                                            setFields({
                                                ...stepFields,
                                                factAddressCheckBox: e.target.checked,
                                                mailingRegion: '',
                                                mailingLocality: '',
                                                mailingStreet: '',
                                                mailingHouseNumber: '',
                                                mailingPremiseNumber: '',
                                                mailingAddressPostalCode: '',
                                            });
                                        }}
                                        
                                    >
                                    </Checkbox>
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                    <SelectField
                                        title="Регион"
                                        name="mailingRegion"
                                        selectedValue={stepFields.mailingRegion}
                                        values={stepFields.mailingRegionsList}
                                        isDisabled={stepFields.factAddressCheckBox}
                                        onChange={(value) => {
                                            setFields({
                                                ...stepFields,
                                                mailingRegion: value,
                                                mailingLocality: null,
                                                mailingStreet: null,

                                                mailingLocalitiesList: [],
                                                mailingStreetsList: [],
                                                mailingHousesList: []
                                            });
                                        }}
                                        onSearch={(value) => {
                                            if (value.length > 2) {
                                                getRegions(value, (data) => {
                                                    setFields({
                                                        ...stepFields,
                                                        mailingRegionsList: data
                                                    });
                                                });
                                            }
                                        }}
                                        getErrors={getFieldErrors}
                                        placeholder="Укажите регион"
                                        notFoundText={null}
                                        showArrow={false}
                                        style={{ width: "100%" }}
                                    />
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                    <SelectField
                                        title="Населенный пункт"
                                        name="mailingLocality"
                                        selectedValue={stepFields.mailingLocality}
                                        values={stepFields.mailingLocalitiesList}
                                        isDisabled={(!stepFields.mailingRegion || stepFields.factAddressCheckBox)}
                                        onChange={(value) => {
                                            setFields({
                                                ...stepFields,
                                                mailingLocality: value,
                                                mailingStreet: null,

                                                mailingStreetsList: [],
                                                mailingHousesList: []
                                            });
                                        }}
                                        onSearch={(value) => {
                                            if (value.length > 2) {
                                                getLocalities(value, stepFields.mailingRegion, (data) => {
                                                    setFields({
                                                        ...stepFields,
                                                        mailingLocalitiesList: data
                                                    });
                                                });
                                            }
                                        }}
                                        getErrors={getFieldErrors}
                                        placeholder="Укажите населенный"
                                        notFoundText={null}
                                        showArrow={false}
                                        style={{ width: "100%" }}
                                    />
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                    <SelectField
                                        title="Улица"
                                        name="mailingStreet"
                                        selectedValue={stepFields.mailingStreet}
                                        values={stepFields.mailingStreetsList}
                                        isDisabled={(!stepFields.mailingLocality || stepFields.factAddressCheckBox)}
                                        onChange={(value) => {
                                            setFields({
                                                ...stepFields, mailingStreet: value,

                                                mailingHousesList: []
                                            });
                                        }}
                                        onSearch={(value) => {
                                            if (value.length >= 1) {
                                                getStreets(value, stepFields.mailingLocality, (data) => {
                                                    setFields({
                                                        ...stepFields,
                                                        mailingStreetsList: data
                                                    });
                                                });
                                            }
                                        }}
                                        getErrors={getFieldErrors}
                                        placeholder="Укажите улицу"
                                        notFoundText={null}
                                        showArrow={false}
                                        style={{ width: "100%" }}
                                    />
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                    <SelectField
                                        title="Номер дома"
                                        name="regHouseNumber"
                                        selectedValue={stepFields.mailingHouseNumber}
                                        values={stepFields.mailingHousesList}
                                        isDisabled={((!stepFields.mailingStreet && !stepFields.mailingLocality) || stepFields.factAddressCheckBox)}
                                        onChange={(value) => {
                                            setFields({ ...stepFields, mailingHouseNumber: value });
                                        }}
                                        onSearch={(value) => {
                                            if (value.length > 0) {
                                                getHouses(value, stepFields.mailingStreet ? stepFields.mailingStreet : stepFields.mailingLocality, (data) => {
                                                    setFields({
                                                        ...stepFields,
                                                        mailingHousesList: data
                                                    });
                                                });
                                            }
                                        }}
                                        getErrors={getFieldErrors}
                                        placeholder="Укажите улицу"
                                        notFoundText={null}
                                        showArrow={false}
                                        style={{ width: "100%" }}
                                    />
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                    <InputField
                                        name="mailingPremiseNumber"
                                        label="Номер помещения"
                                        value={stepFields.mailingPremiseNumber}
                                        isDisabled={stepFields.factAddressCheckBox}
                                        setValue={value => setFields({ ...stepFields, mailingPremiseNumber: value })}
                                        getFieldErrors={getFieldErrors}
                                    />
                                </Col>
                            </Row>
                        ) : (
                            < Row gutter={[12, 24]}>
                                <Col lg={16} md={24} sm={24} xs={24} ></Col>
                                <Col lg={8} md={24} sm={24} xs={24} style={{ marginBottom: "30px", display: "inline", textAlign: "right" }}>
                                    <span className="checkbox-label">Совпадает с фактическим адресом</span>
                                    <Checkbox
                                        name="accreditedIntDeliveries"
                                        checked={stepFields.factAddressCheckBox}
                                        onChange={(e) => {
                                            setFields({
                                                ...stepFields,
                                                factAddressCheckBox: e.target.checked,
                                                mailingRegion: '',
                                                mailingLocality: '',
                                                mailingStreet: '',
                                                mailingHouseNumber: '',
                                                mailingPremiseNumber: '',
                                                mailingAddressPostalCode: '',
                                            });
                                        }}
                                        
                                    >
                                    </Checkbox>
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                    <InputField
                                        name="mailingRegion"
                                        label="Регион"
                                        value={stepFields.mailingRegion}
                                        isDisabled={stepFields.factAddressCheckBox}
                                        setValue={value => setFields({ ...stepFields, mailingRegion: value })}
                                        getFieldErrors={getFieldErrors}
                                    />
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                    <InputField
                                        name="mailingLocality"
                                        label="Населённый пункт"
                                        value={stepFields.mailingLocality}
                                        isDisabled={stepFields.factAddressCheckBox}
                                        setValue={value => setFields({ ...stepFields, mailingLocality: value })}
                                        getFieldErrors={getFieldErrors}
                                    />
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                    <InputField
                                        name="mailingStreet"
                                        label="Улица"
                                        value={stepFields.mailingStreet}
                                        isDisabled={stepFields.factAddressCheckBox}
                                        setValue={value => setFields({ ...stepFields, mailingStreet: value })}
                                        getFieldErrors={getFieldErrors}
                                    />
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                    <InputField
                                        name="mailingHouseNumber"
                                        label="Номер дома"
                                        value={stepFields.mailingHouseNumber}
                                        isDisabled={stepFields.factAddressCheckBox}
                                        setValue={value => setFields({ ...stepFields, mailingHouseNumber: value })}
                                        getFieldErrors={getFieldErrors}
                                    />
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                    <InputField
                                        name="mailingPremiseNumber"
                                        label="Номер помещения"
                                        value={stepFields.mailingPremiseNumber}
                                        isDisabled={stepFields.factAddressCheckBox}
                                        setValue={value => setFields({ ...stepFields, mailingPremiseNumber: value })}
                                        getFieldErrors={getFieldErrors}
                                    />
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                    <InputField
                                        name="mailingAddressPostalCode"
                                        label="Почтовый индекс"
                                        value={stepFields.mailingAddressPostalCode}
                                        isDisabled={stepFields.factAddressCheckBox}
                                        setValue={value => setFields({ ...stepFields, mailingAddressPostalCode: value })}
                                        getFieldErrors={getFieldErrors}
                                    />
                                </Col>
                            </Row>)
                        }
                    </Row>
                </>
            }
        </Row>
    );
}
