import React, {useEffect, useState} from 'react';
import {Col, Modal, notification, Row, Spin} from "antd";
import '../modals.css';
import {approveOrderService} from "../../../services/approve-order.service";

export default function ModalShowContractOrderInfo({isShow, setIsShow, id}) {

  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({});

  const textStyle = {
    textAlign: 'center',
    color: 'black'
  };

  useEffect(() => {
    setLoading(true);
    if (id && isShow) {
      approveOrderService
        .getContractOrderInfo(id)
        .then(resp => {
          setInfo(resp);
          setLoading(false);
        })
        .catch(errMsg => notification.error(
          {
            message: "Получение причины отказа",
            description: errMsg
          }));
    }
  }, [id, isShow]);

  return (
    <Modal
      title={<span className="ant-modal-title">Причины отмены</span>}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
      }}
      onOk={() => {
        setIsShow(false);
      }}
    >
      <Spin spinning={loading} tip="Загрузка...">
        {info.refuseReason !== undefined &&
          <Row className="add-padding-10 -centered" style={{fontSize: "14px", paddingBottom: 60}}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>Статус: {info.status}</Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>Дата: {info.refuseReason.dateCreated}</Col>
            <Col span={24}><b>Причина отказа: </b>
              <p>{info.refuseReason.message}</p>
            </Col>
          </Row>
        }
        {info.refuseReason === undefined &&
          <p>Нет данных</p>
        }
      </Spin>
    </Modal>
  );
}
