import React, {useState} from 'react';
import {Col, Row, DatePicker, Checkbox} from 'antd';
import localeDatePicker from 'antd/lib/date-picker/locale/ru_RU';
import InputField from '../../../../input-field';
import NumberInputField from '../../../../number-input-field';
import SelectField from '../../../../select-field';
import FieldBlock from '../../../../field-block';
import * as moment from 'moment';
import DividerBlock from "../../../../divider-block";

export default function AddressesFields({ stepFields, setFields, getFieldErrors, edit = false }) {

    return (
        <>
            <span className="divider-label">Юридический адрес</span>
            <Row gutter={[12, 24]}>
                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="addressCity"
                        label="Город"
                        value={stepFields.addressCity}
                        setValue={value => setFields({ ...stepFields, addressCity: value })}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={24} md={24} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="address"
                        label="Полный адрес"
                        value={stepFields.address}
                        setValue={value => setFields({ ...stepFields, address: value })}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
            </Row>

            <span className="divider-label">Почтовый адрес</span>
            <Row gutter={[12, 24]}>
                <Col lg={24} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="mailingAddress"
                        label="Полный адрес"
                        value={stepFields.mailingAddress}
                        setValue={value => setFields({ ...stepFields, mailingAddress: value })}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
            </Row>
        </>
    );
}
