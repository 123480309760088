import React, {useEffect, useState} from "react";
import FirstStep from "./steps/first";
import SecondStep from "./steps/second";
import ThirdStep from "./steps/third";
import FourthStep from "./steps/fourth";
import FifthStep from "./steps/fifth";
import {companyService} from "../../../../services";
import {notification} from "antd";
import ModalListShipCompanies from "../../list-ship-companies";
import FillInnStep from "./steps/inn";
import {legalEntityService} from "../../../../services/legal-entity.service";
import moment from "moment";
import {stringsHelper} from "../../../../helpers/strings.helper";

export default function CreateRusLegalEntity({
    isActive = false,

    currentStep,
    setCurrentStep,

    orgFormId,
    isProprietor,
    workWithNds,
    countryId,

    setLoading,

    setShowModal,

    setStepsList,

    changeStepEvent,
    setChangeStepEvent,

    createLegalEntityEvent,
    setCreateLegalEntityEvent,
    purposeType,

    setIsShowParent
}) {
  const [showModalListShipCompanies, setShowModalListShipCompanies] = useState(false);
  const [legalEntityId, setLegalEntityId] = useState(null);

  const [fieldsErrors, setFieldsErrors] = useState({});

  const [firstStepFields, setFirstStepFields] = useState({
    ndsList: [
      {
        Id: 1,
        Name: 'С НДС'
      },
      {
        Id: 2,
        Name: 'Без НДС'
      }
    ],

    name: '',
    orgFormId: orgFormId,
    country: countryId,
    dateRegistration: '',
    inn: '',
    ogrn: '',
    ogrnip: '',
    okpo: '',
    atiCode: '',
    kpp: '',
    workWithNds: workWithNds,
    noAtiCode: false,
    archiveByInn: false,
  });

  const [secondStepFields, setSecondStepFields] = useState({
    legalReasonsList: [
      {
        Id: 'Устав',
        Name: 'Устав'
      },
      {
        Id: 'Приказ',
        Name: 'Приказ'
      },
      {
        Id: 'Доверенность (ООО)',
        Name: 'Доверенность (ООО)'
      }
    ],
    legalReason: '',
    firstName: '',
    secondName: '',
    patronymic: '',

    banksList: [],
    bic: '',
    bankName: '',
    bankCorS: '',
    rs: '',
    position: ''
  });

  const [thirdStepFields, setThirdStepFields] = useState({
    certificateNumber: '',
    certificateSerial: '',

    regAddressCheckBox: false,
    regRegionsList: [],
    regLocalitiesList: [],
    regStreetsList: [],
    regHousesList: [],
    regRegion: '',
    regLocality: '',
    regStreet: '',
    regHouseNumber: '',
    regPremiseNumber: '',
    regAddressPostalCode: '',

    factAddressCheckBox: false,
    mailingRegionsList: [],
    mailingLocalitiesList: [],
    mailingStreetsList: [],
    mailingHousesList: [],
    mailingRegion: '',
    mailingLocality: '',
    mailingStreet: '',
    mailingHouseNumber: '',
    mailingPremiseNumber: '',
    mailingAddressPostalCode: '',

    legalRegionsList: [],
    legalLocalitiesList: [],
    legalStreetsList: [],
    legalHousesList: [],
    legalRegion: '',
    legalLocality: '',
    legalStreet: '',
    legalHouseNumber: '',
    legalPremiseNumber: '',
    legalAddressPostalCode: '',

    guarantorFirstName: '',
    guarantorSecondName: '',
    guarantorPatronymic: '',
    guarantorPassportSerial: '',
    guarantorPassportNumber: '',
    guarantorPassportDivisionsList: [],
    guarantorPassportDivision: '',
    guarantorPassportRegistration: '',
    guarantorPassportDate: '',

    guarantorFactAddressCheckBox: false,
    guarantorFactRegionsList: [],
    guarantorFactLocalitiesList: [],
    guarantorFactStreetsList: [],
    guarantorFactHousesList: [],
    guarantorFactRegion: '',
    guarantorFactLocality: '',
    guarantorFactStreet: '',
    guarantorFactHouseNumber: '',
    guarantorFactPremiseNumber: '',
    guarantorFactAddressPostalCode: '',

    guarantorRegRegionsList: [],
    guarantorRegLocalitiesList: [],
    guarantorRegStreetsList: [],
    guarantorRegHousesList: [],
    guarantorRegRegion: '',
    guarantorRegLocality: '',
    guarantorRegStreet: '',
    guarantorRegHouseNumber: '',
    guarantorRegPremiseNumber: '',
    guarantorRegAddressPostalCode: '',
  });

  const [fourthStepFields, setFourthStepFields] = useState({
    guarantorFirstName: '',
    guarantorSecondName: '',
    guarantorPatronymic: '',
    guarantorPassportSerial: '',
    guarantorPassportNumber: '',
    guarantorPassportDivisionsList: [],
    guarantorPassportDivision: '',
    guarantorPassportRegistration: '',
    guarantorPassportDate: '',

    guarantorFactAddressCheckBox: false,
    guarantorFactRegionsList: [],
    guarantorFactLocalitiesList: [],
    guarantorFactStreetsList: [],
    guarantorFactHousesList: [],
    guarantorFactRegion: '',
    guarantorFactLocality: '',
    guarantorFactStreet: '',
    guarantorFactHouseNumber: '',
    guarantorFactPremiseNumber: '',
    guarantorFactAddressPostalCode: '',

    guarantorRegRegionsList: [],
    guarantorRegLocalitiesList: [],
    guarantorRegStreetsList: [],
    guarantorRegHousesList: [],
    guarantorRegRegion: '',
    guarantorRegLocality: '',
    guarantorRegStreet: '',
    guarantorRegHouseNumber: '',
    guarantorRegPremiseNumber: '',
    guarantorRegAddressPostalCode: '',
  });

  const [fifthStepFields, setFifthStepFields] = useState({
    // Общие
    partnerCard: [],
    copyOfThePassport: [],
    powerOfAttorneyForAPerson: [],
    copyOfBankCard: [],
    certificateOfStateRegistration: [],

    incomeTaxForTheLastReportingPeriod: [],
    NDSReturnForTheLastReportingPeriod: [],
    bookkeepingBalance: [],

    // ИП
    certificateOfRegistrationIndividualEntrepreneurWithTaxAuthority: [],
    // Не ИП
    charterWithAllAmendments: [],
    protocolOfTheGeneralMeetingOfParticipants: [],
    registrationOfALegalEntityWithTaxAuthority: [],
    proofOfOwnership: [],

    //ТНВ
    registeredMemorandumOfAssociation: [],
    decisionToCreate: [],
    requisites: [],
  });

  const [innInfoErr, setInnInfoErr] = useState(null);

  const getFieldErrors = (fieldName) => {
    return typeof fieldsErrors[fieldName] !== "undefined"
        ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
          return fieldErrors + "; " + error;
        })
        : null;
  };

  useEffect(() => {
    setFifthStepFields({
      // Общие
      partnerCard: [],
      copyOfThePassport: [],
      powerOfAttorneyForAPerson: [],
      copyOfBankCard: [],
      certificateOfStateRegistration: [],

      incomeTaxForTheLastReportingPeriod: [],
      nDSReturnForTheLastReportingPeriod: [],
      bookkeepingBalance: [],
      certificateFromIFNSOnTheStatusOfSettlementsWithTheBudget: [],
      staffingTable: [],

      // ИП
      dischargeFromEGRIP: [],
      certificateOfRegistrationIndividualEntrepreneurWithTaxAuthority: [],
      certificateOfCalculationsWithTheBudget: [],
      // Не ИП
      dischargeFromEGRUL: [],
      charterWithAllAmendments: [],
      decisionToCreateSociety: [],
      protocolOfTheGeneralMeetingOfParticipants: [],
      certificateOfEntry: [],
      registrationOfALegalEntityWithTaxAuthority: [],
      // bookkeepingBalance: [],
      profitsAndLossesReport: [],
      incomeTaxAndNDSDeclaration: [],
      proofOfOwnership: []
    });
  }, [currentStep]);

  useEffect(() => {
    if (isActive) {
      if (isProprietor || purposeType === 'consignor_purpose_type') {
        setStepsList([
          'ИНН',
          'Реквизиты',
          'Основная информация',
          'Адреса',
          'Поручитель',
          'Документы'
        ]);
      } else {
        setStepsList([
          'ИНН',
          'Реквизиты',
          'Основная информация',
          'Адреса',
          'Поручитель',
          'Документы'
        ]);
      }
    }
  }, [isActive]);

  useEffect(() => {
    if (changeStepEvent) {
      if (changeStepEvent === 'next') {
        nextStep();
      } else if (changeStepEvent === 'previous') {
        previousStep();
      }

      setChangeStepEvent('');
    }
  }, [changeStepEvent]);

  useEffect(() => {
    if (createLegalEntityEvent) {
      setLoading(true);

      let data = Object.assign(firstStepFields, secondStepFields, thirdStepFields, fourthStepFields);
      companyService.createLegalEntity(data, fifthStepFields, isProprietor, purposeType)
          .then(response => {
            if (response.code === 400) {
              notification.error({
                message: "Создание юридического лица",
                description: response.message,
              });
              setFieldsErrors(response.errors);
            } else {
              notification.success({
                message: "Создание юридического лица",
                description: response.message,
              });

              setShowModalListShipCompanies(true);
              setLegalEntityId(response.data);
            }

            setCreateLegalEntityEvent(false);
            setLoading(false);
          })
          .catch(errMsg => {
            notification.error({
              message: "Создание юридического лица",
              description: errMsg,
            });

            setLoading(false);
            setCreateLegalEntityEvent(false);
          });
    }
  }, [createLegalEntityEvent]);

  const incrementStep = () => {
        if (currentStep < 6) {
            if ((currentStep + 1) === 5 && (isProprietor || purposeType === 'consignor_purpose_type')) {
                setCurrentStep(currentStep + 2);
            } else {
                setCurrentStep(currentStep + 1);
            }
            setFieldsErrors([]);
        }
    };

  const nextStep = () => {
    const stepData = (currentStep) => {
      let stepFields = {
        2: firstStepFields,
        3: secondStepFields,
        4: thirdStepFields,
        5: fourthStepFields,
        6: fifthStepFields
      };

      // Баг. Без этого вместо stepFields undefiend

      return stepFields[currentStep];
    };

    setLoading(true);

    let step = currentStep;
    if (currentStep === 1) {
        if (!firstStepFields.inn) {
            setFieldsErrors({
                inn: ["Необходимо заполнить ИНН"]
            });
            setLoading(false);
            return;
        }

        if (!firstStepFields.atiCode) {
            setFieldsErrors({
                atiCode: ["Необходимо заполнить код АТИ"]
            });
            setLoading(false);
            return;
        }

        setInnInfoErr(null);
        legalEntityService.getNewLegalEntityInfo({inn: firstStepFields.inn})
            .then((resp) => {
                setFirstStepFields({
                    ...firstStepFields,
                    name: resp.name,
                    ogrn: resp.ogrn,
                    ogrnip: resp.ogrnip,
                    dateRegistration: resp.dateRegistration ?  moment(resp.dateRegistration, 'DD.MM.YYYY') : '',
                    okpo: resp.okpo,
                    kpp: resp.kpp,
                });
                setSecondStepFields({
                    ...secondStepFields,
                    firstName: resp.firstName,
                    secondName: resp.secondName,
                    patronymic: resp.patronymic,
                    position: resp.position ? stringsHelper.ucFirst(resp.position) : '',
                });
                setThirdStepFields({
                    ...thirdStepFields,
                    legalRegion: resp.regionAoguid !== null ? resp.regionAoguid : '',
                    legalLocality: resp.localityAoguid !== null ? resp.localityAoguid : '',
                    legalStreet: resp.streetAoguid !== null ? resp.streetAoguid : '',
                    legalHouseNumber: resp.houseAoguid !== null ? resp.houseAoguid : '',
                });

                setLoading(false);
                incrementStep();
            })
            .catch((err) => {
                setInnInfoErr(err);
                notification.error({
                    message: "Проверка ИНН",
                    description: err,
                });
                setFieldsErrors({
                    inn: [err]
                });
                setLoading(false);
            });
        return;
    } else {
        step -= 1;
    }

    companyService
      .createLegalEntityValidateStepData(stepData(currentStep), step, orgFormId, countryId, false, false, isProprietor)
      .then(response => {
        setLoading(false);

        if (response.status === 'success') {
          incrementStep();
        } else {
          setFieldsErrors(response.errors);
        }
      })
      .catch(error => {
          setLoading(false);
          notification.error({
            message: "Создание юр.лица",
            description: error,
          });
        });
  };

  const previousStep = () => {
        if (currentStep > 0) {
            if (currentStep === 6 && (isProprietor || purposeType === 'consignor_purpose_type')) {
                setCurrentStep(currentStep - 2);
                return;
            }
            setCurrentStep(currentStep - 1);
        } else {
            setCurrentStep(0);
        }
    };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
            <FillInnStep
                setFields={setFirstStepFields}
                stepFields={firstStepFields}
                getFieldErrors={getFieldErrors}
                isProprietor={isProprietor}
                innInfoError={innInfoErr}
                setInnInfoErr={setInnInfoErr}
                nextStepFunc={incrementStep}
            />
        );
      case 2:
          return (
              <FirstStep
                  setFields={setFirstStepFields}
                  stepFields={firstStepFields}
                  getFieldErrors={getFieldErrors}
                  isProprietor={isProprietor}
              />
          );
      case 3:
          return (
              <SecondStep
                  setFields={setSecondStepFields}
                  stepFields={secondStepFields}
                  getFieldErrors={getFieldErrors}
                  organizationalForm={firstStepFields.orgFormId}
                  country={firstStepFields.country}
                  isProprietor={isProprietor}
              />
          );
      case 4:
          return (
              <ThirdStep
                  setFields={setThirdStepFields}
                  stepFields={thirdStepFields}
                  getFieldErrors={getFieldErrors}
                  country={firstStepFields.country}
                  isProprietor={isProprietor}
                  currentStep={currentStep}
              />
          );
      case 5:
          return (
              <FourthStep
                  setFields={setFourthStepFields}
                  stepFields={fourthStepFields}
                  getFieldErrors={getFieldErrors}
                  country={firstStepFields.country}
                  isProprietor={isProprietor}
              />
          );
        case 6:
            return (
                <FifthStep
                    setFields={setFifthStepFields}
                    stepFields={fifthStepFields}
                    getFieldErrors={getFieldErrors}
                    organizationalForm={firstStepFields.orgFormId}
                    isNeedPowerOfAttorneyForAPerson={(secondStepFields.legalReason === 'Доверенность (ООО)')}
                    isNdsPayer={(firstStepFields.workWithNds === 1)}
                    isProprietor={isProprietor}
                    purposeType={purposeType}
                />
            );
      default:
        return <div />;
    }
  };

  return (
      <div>
        {renderCurrentStep()}
        <ModalListShipCompanies isShow={showModalListShipCompanies} setIsShow={setShowModalListShipCompanies} legalEntityId={legalEntityId} setIsShowParent={setIsShowParent} />
      </div>
  );
}
