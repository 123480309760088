import React from 'react';

export default function DeliveryGroupList({groupName, deliveriesItems}) {

    return (
        <div className="application-block">
           {/*  <h1 className="group-name">{groupName}</h1> */}
            {deliveriesItems}
        </div>
    );
}
