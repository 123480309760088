import axios from "axios";
import {mainConfig} from "../config";
import {httpRequest} from "./http-request";

const _apiBase = mainConfig.apiUrl;
const clientHash = btoa(mainConfig.authClient.id + ":" + mainConfig.authClient.secret);

export const userServices = {
  login,
  logout,
  getUser,
  getUsersList,
  editStaffUser,
  editCarrierUser,
  createStaffUser,
  resetPass,
  addMessage,
  deleteMessage,
  getMessage,
  register,
  getDeliveriesDirections,
  saveDeliveriesDirections,
  getInfo,
  readTermsOfUse,
  readPrivacyPolicy,
  updateUserInfo,
  getTelegramVerifyCode,
  switchType,
  changePassword,
  getVerifyCode,
  changeName,
  changeLogin,
  getAds,
  changeUserLogin,
  getSmsApprovePhone,
  getUserInfoByPhone,
  deactiveUserByPhone
};

/**
 * Авторизоваться
 */
async function login(login, password) {
  return await axios
    .post(
      `${_apiBase}/auth/token`,
      {
        grant_type: "password",
        username: login,
        password: password,
        scope: "",
      },
      {
        headers: {Authorization: "Basic " + clientHash},
      }
    )
    .then(
      (response) => {
        if (
          response.status === 200 &&
          typeof response.data.access_token !== "undefined" &&
          typeof response.data.refresh_token !== "undefined" &&
          typeof response.data.expires_in !== "undefined"
        ) {
          return Promise.resolve({...response.data});
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
      },
      (error) => {
        const errorMsg =
          typeof error.response !== "undefined" && error.response.status === 401 ? error.response.data.message : httpRequest.getDefaultMessageError();

        return Promise.reject(errorMsg);
      }
    )
    .then(async (authData) => {
      return await getInfo(authData.access_token).then((response) => {
        return Promise.resolve({
          loggedIn: true,
          auth: {...authData},
          info: {...response},
        });
      });
    });
}

async function register({fio, email, phone, countryId, legalNumber}) {
  const formData = new FormData();

  formData.append("Fio", fio);
  formData.append("Email", email);
  formData.append("Phone", phone);
  formData.append("CountryId", countryId);
  formData.append("LegalNumber", legalNumber);

  return await httpRequest
    .post("auth/register-company-director", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Получить информацию о пользователе
 */
async function getInfo(token) {
  return await axios
    .get(`${_apiBase}/user/info`, {
      headers: {Authorization: "Bearer " + token},
    })
    .then(
      (response) => {
        if (
          response.status === 200 &&
          typeof response.data.Id !== "undefined" &&
          typeof response.data.Name !== "undefined" &&
          typeof response.data.Role !== "undefined" &&
          typeof response.data.RoleName !== "undefined"
        ) {
          return Promise.resolve({
            id: response.data.Id,
            fio: response.data.Name,
            login: response.data.Login,
            role: response.data.Role,
            roleName: response.data.RoleName,
            userType: response.data.UserType,
            isStaff: response.data.IsStaff,
            permissions: response.data.Permissions,
            company: {
              name: response.data.CompanyName,
              type: response.data.CompanyType,
            },
            notificationsSettings: response.data.NotificationsSettings,
            termsOfUse: response.data.TermsOfUse,
            privacyPolicy: response.data.PrivacyPolicy,
            isTelegramAttached: response.data.IsTelegramAttached,
            rules: response.data.Rules,
            country: {
              id: response.data.Country.Id
            },
          });
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
      },
      (error) => {
        const errorMsg = error.response.status === 401 ? "Неверный логин или пароль" : httpRequest.getDefaultMessageError();

        return Promise.reject(errorMsg);
      }
    );
}

async function updateUserInfo() {
  const encodeUserData = localStorage.getItem("user_auth_v2");
  let userObjDataDecode = null;

  if (encodeUserData) {
    const strDecode = new Buffer(encodeUserData, "base64").toString("utf8");
    userObjDataDecode = JSON.parse(strDecode);
  }

  if (userObjDataDecode && userObjDataDecode.loggedIn) {
    await userServices.getInfo(userObjDataDecode.auth.access_token).then((response) => {
      userObjDataDecode.info = response;

      const objJsonStr = JSON.stringify(userObjDataDecode);
      const objJsonB64 = Buffer.from(objJsonStr).toString("base64");

      localStorage.setItem("user_auth_v2", objJsonB64);
    });
  }
  return Promise.resolve({});
}

/**
 * Выйти
 */
async function logout() {
  localStorage.removeItem("user_auth_v2");

  return Promise.resolve({});
}

/**
 * Получить объект пользователя
 */
function getUser() {
  const encodeUserData = localStorage.getItem("user_auth_v2");
  let userObjDataDecode = null;

  if (encodeUserData) {
    const strDecode = new Buffer(encodeUserData, "base64").toString("utf8");
    userObjDataDecode = JSON.parse(strDecode);
  }

  const defUserGuestData = {
    loggedIn: false,
    info: {
      role: "guest",
    },
  };

  const userData = userObjDataDecode ? {...defUserGuestData, ...userObjDataDecode, loggedIn: true} : defUserGuestData;

  const isGuest = () => {
    return typeof userData.info === "undefined" || (typeof userData !== "undefined" && userData.info.role === "guest");
  };

  return {
    ...userData,
    isGuest: isGuest,
    isAccessTo: (permissionName) => {
      return (
        typeof userData.info !== "undefined" &&
        typeof userData.info.permissions !== "undefined" &&
        userData.info.permissions.filter((permData) => permData.Name === permissionName).length
      );
    },
    getAuthToken: () => {
      return !isGuest() ? userData.auth.access_token : "";
    },
    getRefreshToken: () => {
      return !isGuest() ? userData.auth.refresh_token : "";
    },
  };
}

/**
 * Получить список пользователей
 */
async function getUsersList(params) {
  const addLimit = params.limit ? `?limit=${params.limit}` : '?limit=50';
  const addOffset = params.offset ? `&offset=${params.offset}` : '&offset=0';
  const addFio = params.fio ? `&fio=${params.fio}` : '';
  const addLogin = params.login ? `&login=${params.login}` : '';
  const addEmail = params.email ? `&email=${params.email}` : '';
  const addPhone = params.phone ? `&phone=${params.phone}` : '';
  const addRole = params.role && Boolean(params.role.length) ?
    params.role.map((item,index)=>{return `&role[${index}]=${item}`}).join('')
    : '';
  const addStatus = params.status && Boolean(params.status.length) ? `&status=${params.status}` : '';
  const orderBy = params.orderBy ? `&orderBy=${params.orderBy}` : '';
  const isSortASC = params.orderBy ? `&isSortASC=${params.isSortASC}` : '';

  return await httpRequest
    .get('user/users-info' + addLimit + addOffset + addFio + addLogin + addEmail + addPhone + addRole + addStatus + orderBy + isSortASC)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Изменить информацию о пользователе
 */
async function editStaffUser(id, fio, login, password, email, phone, role, status, distributionsCentersIds) {
  return await httpRequest
    .patch(`user/${id}/edit-staff-user`, {
      fio: fio,
      login: login,
      password: password,
      email: email,
      phone: phone,
      role: role,
      status: status,
      distributionsCentersIds: distributionsCentersIds
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function editCarrierUser(id, password, status, accreditedIntDeliveries, deliveryDisplayDelay, role) {
  return await httpRequest
    .patch(`user/${id}/edit-carrier-user`, {
      password: password,
      status: status,
      accreditedIntDeliveries: accreditedIntDeliveries,
      delivDisplayDelay: deliveryDisplayDelay,
      role: role,
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function createStaffUser(fio, login, password, email, phone, role, distributionsCentersIds, consignorId, countryId) {
  return await httpRequest
    .post("user/create-staff-user", {
      fio: fio,
      login: login,
      password: password,
      email: email,
      phone: phone,
      role: role,
      distributionsCentersIds: distributionsCentersIds,
      consignorId: consignorId,
      countryId: countryId,
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function resetPass(username) {
  return await httpRequest
    .post(
      "auth/reset-password",
      {
        username: username,
      },
      {headers: {Authorization: "Bearer " + clientHash}}
    )
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve("Пароль успешно восстановлен.");
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function addMessage(subject, message) {
  return await httpRequest
    .post("user/set-message", {
      subject: subject,
      message: message,
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve("Сообщение успешно установлено");
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function deleteMessage() {
  return await httpRequest.get("user/delete-message").then((response) => {
    if (response.status === 200) {
      return Promise.resolve("Сообщение успешно удалено");
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getMessage() {
  return await httpRequest.get("user/get-message").then((response) => {
    if (response.status === 200) {
      return Promise.resolve(JSON.parse(response.data));
    }
    return false;
  });
}

async function getDeliveriesDirections() {
  return await httpRequest.get("user/deliveries-directions").then((response) => {
    if (response.status === 200) {
      return response.data;
    }
    return false;
  });
}

async function saveDeliveriesDirections(directionsIDs) {
  return await httpRequest.post("user/save-favorites-directions", {
    FavoritesDirections: directionsIDs
  }).then((response) => {
    if (response.status === 200) {
      return Promise.resolve("Избранные направления успешно сохранены");
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function readTermsOfUse() {
  return await httpRequest.get("user/read-terms-of-use").then((response) => {
    if (response.status === 200) {
      return response.data;
    }
    return false;
  });
}

async function readPrivacyPolicy() {
  return await httpRequest.get("user/read-privacy-policy").then((response) => {
    if (response.status === 200) {
      return response.data;
    }
    return false;
  });
}

async function getTelegramVerifyCode() {
  return await httpRequest.get("user/get-telegram-verify-code").then((response) => {
    if (response.status === 200) {
      return response.data;
    }
    return false;
  });
}

async function switchType() {
  return await httpRequest.get("user/switch-type").then((response) => {
    if (response.status === 200) {
      return response.data;
    }
    return false;
  });
}

async function changePassword(data) {
  return await httpRequest
    .post(`user/change-password`, data)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function changeName(name) {
  return await httpRequest
    .post(`user/change-name`, {name: name})
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getVerifyCode() {
  return await httpRequest
    .get(`user/get-verify-code`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function changeLogin(data) {
  return await httpRequest
    .post(`user/change-login`, data)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getAds() {
  return await httpRequest
    .get(`inform/info`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Запрос на изменение логина пользователя
 * @param userId
 * @param newLogin
 * @param newLoginCountryID
 * @returns {Promise<any>}
 */

async function changeUserLogin(userId, newLogin, newLoginCountryID) {
  return await httpRequest
    .post(`user/request-change-login`, {
      "userId": userId,
      "newLogin": newLogin,
      "newLoginCountryID": newLoginCountryID
    })
    .then((response) => {
      if (response.status === 200 || response.status === 202) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getSmsApprovePhone({phone}) {
  const formData = new FormData();
  formData.append("phone", phone);

  return await httpRequest
    .post("user/get-sms-approve-phone-access", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getUserInfoByPhone({phoneToken, phoneCode, phone}) {
  const formData = new FormData();
  formData.append("phoneToken", phoneToken);
  formData.append("phoneCode", phoneCode);
  formData.append("phone", phone);

  return await httpRequest
    .post("user/get-user-info-by-phone", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function deactiveUserByPhone({phoneToken, phoneCode, phone}) {
  const formData = new FormData();
  formData.append("phoneToken", phoneToken);
  formData.append("phoneCode", phoneCode);
  formData.append("phone", phone);

  return await httpRequest
    .post("user/deactive-user-by-phone", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}