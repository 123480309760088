import {Collapse} from "antd";
import React from "react";
import './style.less'

export default function Accordion() {
  const Panel = Collapse.Panel;

  const content = [
    {
      title: "В чем суть и преимущества платформы Светофор Логистика?",
      text: "Наша платформа, соединяет фактических перевозчиков с грузоотправителем. Позволяя сэкономить время обоих."
    },
    {
      title: "Кто может стать участником Светофор Логистика?",
      text: "Стать участником могут любые юридические лица, не запятнавшие себя и прошедшие нашу Службу Безопасности."
    },
    {
      title: "Обязательно ли регистрироваться на сайте?",
      text: "Регистрация обязательна. С её помощью вы сможете настроить свой личный кабинет, " +
          "добавить и согласовать своё юридическое лицо, и использовать все возможности нашей логистической платформой."
    },
    {
      title: "Есть ли пробный бесплатный период пользования площадкой Светофор Логистика?",
      text: "Для перевозчиков платформа бесплатна всегда. Для грузоотправителей пробный период 3 месяца."
    },
    {
      title: "Как узнавать о новых рейсах быстрее?",
      text: <>Вы можете поставить наше приложение или установить <a href="https://t.me/VitalLogisticBot">telegram бот</a>. Туда приходят уведомления о новых рейсах.</>
    },
    {
      title: "Я не помню свой логин или пароль для входа, что мне делать?",
      text: <>Вы можете обратиться в техническую поддержку <a href="mailto:support@svetofore.ru">support@svetofore.ru</a>, укажите ИНН компании, от которой хотите войти, и вам подскажут логин.</>
    },
  ]

  return (
    <Collapse bordered="false"
              className="qa__items"
              style={{background: "transparent", border: "none"}}
              expandIcon={() => <button className="qa-card__btn">
                <svg width="42" height="44" viewBox="0 0 42 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M42 38L21 44L-5.24537e-07 38L-3.8466e-06 3.67176e-06L42 0L42 38Z" fill="#FFDB06"/>
                  <path d="M27 18L21 24L15 18" stroke="#44803F" strokeWidth="2"/>
                </svg>
              </button>}
    >
      {content.map((item, index) =>
        <Panel
          header={
            <div className="qa-card__show">
              <div className="qa-card__title display4 green-text">{item.title}</div>
            </div>
          }
          key={index + 1}
          className="qa-card"
        >
          <div className="qa-card__hidden">
            <div className="text20 medium-weight gray-text">{item.text}</div>
          </div>
        </Panel>
      )}
    </Collapse>
  )
};
