import React, {useState} from "react";
import {Button, Row, Col, Modal, notification, Table, Icon} from "antd";
import Spinner from "../../spinner";
import {distributionCenterService} from "../../../services";
import InputField from "../../input-field";
import '../modals.css';


export default function ModalEditDistributionCenterOrderTimeSums({
                                                                   isShow, distCenterId,
                                                                   onCloseModal,
                                                                   name,
                                                                 }) {

  const [loadingContentModal, setLoadingContentModal] = useState(true);
  const [fieldsErrors, setFieldsErrors] = useState(null);

  const [timeAndSums, setTimeAndSums] = useState([]);

  const [time, setTime] = useState('');
  const [sum, setSum] = useState('');

  let bigButtonStyle = {
    width: '200px',
    height: '43px',
    background: '#1CAA53',
    position: 'static',
    borderRadius: '10px'
  };
  let smallButtonStyle = {
    width: '100%',
    height: '40px',
    background: '#2B3F63',
    position: 'static',
    borderRadius: '10px',
    marginTop: "31px"
  };
  let BackButtonStyle = {
    width: '190px',
    height: '40px',
    background: '#EB5757',
    position: 'static',
    borderRadius: '10px',
    marginBottom: "13px",
    marginTop: "13px"
  };

  if (isShow && loadingContentModal) {
    distributionCenterService.getOrderTimeSums(distCenterId)
      .then((response) => {
        const timeAndSumsData = response.map((item) => {
          return {
            hour: item.hour,
            sum: item.sum,
            id: item.id
          };
        });
        setTimeAndSums(timeAndSumsData);
        setLoadingContentModal(false);
      })
      .catch((error) => {
        setLoadingContentModal(false);
      });
  }

  const deleteOrderTimeSum = (id) => {
    distributionCenterService.deleteOrderTimeSum(id)
      .then((response) => {
        notification.success({
          message: "Удаление",
          description: response.message,
        });
        setLoadingContentModal(true);
      })
      .catch((error) => {
        notification.error({
          message: "Удаление",
          description: error.message,
        });
        setLoadingContentModal(true);
      })
  };

  // сохранение новых данных о РЦ
  const onClickAdd = () => {
    setLoadingContentModal(true);
    distributionCenterService.addOrderTimeSum(distCenterId, time, sum)
      .then((response) => {
        if (response.status === 'success') {
          notification.success({
            message: "Добавление",
            description: response.message,
          });
          setFieldsErrors(null);
        } else {
          setFieldsErrors(response.errors);
        }
        setLoadingContentModal(true);
      })
      .catch((error) => {
        notification.error({
          message: "Добавление",
          description: error,
        });
        setLoadingContentModal(true);
      });
  };

  const columns = [
    {
      title: 'Кол-во часов',
      dataIndex: 'hour',
      key: 'hour',
      width: '35%',
    },
    {
      title: 'Надбавочная сумма',
      dataIndex: 'sum',
      key: 'sum',
      width: '35%',
    },
    {
      title: 'Действие',
      key: 'edit',
      width: '15%',
      render: (data) => {
        return (
          <React.Fragment>
            <Button htmlType="button" key="submitPrev" style={BackButtonStyle}
                    onClick={() => deleteOrderTimeSum(data.id)} type="danger">
              <Icon style={{position: "absolute", paddingTop: "3px", display: "flex"}} type="delete"
                    theme="filled"/> Удалить
            </Button>
          </React.Fragment>
        );
      },
    },
  ];

  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
      ? fieldsErrors[nameField][0]
      : null;
  };

  return (
    <Modal
      title={<span>Редактирование времени и сумм «{name}»</span>}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        onCloseModal();
        setLoadingContentModal(true);
      }}
      width={1140}
      forceRender={true}
      footer={[
        <Button htmlType="submit" key="submit" type="primary" onClick={onClickAdd}
                style={bigButtonStyle}>Сохранить</Button>,
      ]}
    >
      <Spinner loading={loadingContentModal} label={null}>
        <Row gutter={[16, 24]} style={{marginBottom: "54px"}}>
          <Col lg={10} md={12} sm={24} xs={24}>
            <InputField
              name="time" label="Время в часах"
              value={time} setValue={setTime}
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col lg={10} md={12} sm={24} xs={24}>
            <InputField
              name="sum" label="Сумма"
              value={sum} setValue={setSum}
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col lg={4} md={24} sm={24} xs={24}>
            <Button style={smallButtonStyle} htmlType="submit" key="submit" type="primary"
                    onClick={onClickAdd}>Сохранить</Button>
          </Col>
        </Row>
        <Table
          style={{minWidth: '100%'}}
          columns={columns}
          className="distribution-centres-table"
          dataSource={timeAndSums}
          locale={{emptyText: "Данные отсутствуют"}}
          rowKey="id"
          size="small"
        />
      </Spinner>
    </Modal>
  );
}
