import React from 'react';


export default function Support() {
    return (
        <>
            <p className={"content-header"}>Техническая поддержка</p>
            <p>Техническая поддержка осуществляется по адресу <a href="mailto:support@svetofore.ru">support@svetofore.ru</a>.</p>
            <br />
            <p>Для оперативного решения вашего вопроса укажите при обращении:</p>
            <p>
                <ol>
                    <li>Чётко опишите проблему, что именно у вас не получается сделать.</li>
                    <li>Укажите ИНН компании, по которой возникли проблемы.</li>
                    <li>Если речь про документ, приложите его к письму, или отправьте в облако и укажите ссылку.</li>
                    <li>Если нужно скорректировать данные на сайте, приложите документы, чтобы было основание для корректировки.</li>
                </ol>
            </p>
            <p>Не обязательно указать именно все пункты, но полная информация существенно ускорит решение вопроса.</p>
        </>

    )
}