import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Table, Row, Icon, Tooltip, Checkbox} from "antd";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import {reportService} from "../../../../services/report.services";
import moment from "moment";
import {tableHelper} from "../../../../helpers";
import SelectField from "../../../select-field";
import NumberInputField from "../../../number-input-field";
import InputField from "../../../input-field";


export default function ReportStatsIP() {

  const typeReports = [
    {
      name: "Пользователь",
      id: "user"
    },
    {
      name: "Юр.лицо",
      id: "legal_entity"
    }];
  const multiRegionsList = [
    {
      name: "все",
      id: "all"
    }, {
      name: "да",
      id: "multi_region"
    }, {
      name: "нет",
      id: "none"
    }];

  const [loading, setLoading] = useState(false);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [name, setName] = useState('');
  const [searchText, setSearchText] = useState('');
  const [fieldsErrors, setFieldsErrors] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);

  const [pagination, setPagination] = useState({
    defaultPageSize: 50,
    pageSize: 50,
  });

  // параметры для построения отчета
  const [minIpCount, setMinIpCount] = useState(null);
  const [maxIpCount, setMaxIpCount] = useState(null);
  const [minRegionsCount, setMinRegionsCount] = useState(null);
  const [maxRegionsCount, setMaxRegionsCount] = useState(null);
  const [mode, setMode] = useState("user");
  const [multiRegionMode, setMultiRegionMode] = useState("all");
  // полученные данные отчета
  const [reportData, setReportData] = useState([]);

  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
      ? fieldsErrors[nameField][0]
      : null;
  };

  // отображение фатальной ошибки
  if (errorMsg) {
    return (<div className="content-error-block">{errorMsg}</div>);
  }

  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateReport(true);
    }
  }, []);

  useEffect(() => {
    if (isNeedUpdateReport) {
      reportService
        .getStatsUsersIp(
            limit, offset,
            minIpCount, maxIpCount,
            minRegionsCount, maxRegionsCount,
            multiRegionMode, mode,
            name
        )
        .then((response) => {
          const adaptedData = response.data.map((item) => {
            return {
              name: item.name,
              ipList: item.ipList.map((el) => {
                return <p>{el.ip} - {el.dateCreated}</p>
              }),
              regionsList: item.regionsList.map((el) => {
                return <p>{el}</p>
              }),
              multiRegions: item.multiRegions,
            }
          })
          setReportData(adaptedData);

          setPagination({
            ...pagination,
            total: response.totalCount
          });

          setIsNeedUpdateReport(false);
          setLoading(false);
        })
        .catch((errorMsg) => {
          setIsNeedUpdateReport(false);
          setLoading(false);
          setErrorMsg(errorMsg);
        });
    }
  }, [isNeedUpdateReport]);


  // список столбцов таблицы
  const columns = [
    {
      title: <>`{mode === "user" ? "Пользователь" : "Юр. лицо"}`</>,
      dataIndex: 'name',
      key: 'name',
      width: '35%',
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      ...tableHelper.getColumnSearchProps('name', searchText, setSearchText)
    },
    {
      title: 'Список IP адресов',
      key: 'ipList',
      width: '325px',
      dataIndex: 'ipList',
    },
    {
      title: <Tooltip title='Список регионов'>Список регионов</Tooltip>,
      dataIndex: "regionsList",
      key: 'regionsList',
    },
    {
      title: <Tooltip title='Мультирегиональность'>Мультирегиональность</Tooltip>,
      key: 'multiRegions',
      dataIndex: 'multiRegions',
      render: multiRegions => {
        return multiRegions ? 'Да' : 'Нет';
      },
      className: "center"
    },
  ];

  const onTableChange = (pagination)  => {
    setPagination({
      ...pagination
    });
    setOffset((pagination.current - 1) * pagination.pageSize);
    setLimit(pagination.pageSize);
    setIsNeedUpdateReport(true);
  }

  return (
    <React.Fragment>
      <div className="reports__header">
        <h1 className="content-header">Отчет «Статистика IP»</h1>
        <Icon type='filter'
              theme={isFilterOpen ? '' : "filled"}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen)
              }}
              className="reports__filter-toggle"/>
        {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
          <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start">
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <span className="form-field-label">Выберите тип отчета</span>
              <SelectField
                name="typeReport"
                selectedValue={mode}
                values={typeReports}
                onChange={(value) => {
                  setMode(value);
                }}
                getErrors={() => {
                }}
                style={{width: "100%"}}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24}>
              <span className="form-field-label">Мультирегиональность</span>
              <SelectField
                name="multiRegions"
                selectedValue={multiRegionMode}
                values={multiRegionsList}
                onChange={(value) => {
                  setMultiRegionMode(value);
                }}
                getErrors={() => {
                }}
                style={{width: "100%"}}
              />
            </Col>
          </Row>
          <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start">
            <Col lg={4} md={4} sm={12} xs={12} style={{alignSelf: "flex-end"}}>
              <NumberInputField
                name="minIpCount"
                label="Количество IP, от"
                value={minIpCount}
                setValue={setMinIpCount}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} style={{alignSelf: "flex-end"}}>
              <NumberInputField
                name="countIPEndIntervalEnd"
                label="до"
                value={maxIpCount}
                setValue={setMaxIpCount}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} style={{alignSelf: "flex-end"}}>
              <NumberInputField
                name="minRegionsCount"
                label="Количество регионов, от"
                value={minRegionsCount}
                setValue={setMinRegionsCount}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} style={{alignSelf: "flex-end"}}>
              <NumberInputField
                name="maxRegionsCount"
                label="до"
                value={maxRegionsCount}
                setValue={setMaxRegionsCount}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} style={{alignSelf: "flex-end"}}>
              <InputField
                  name="name"
                  label="Имя"
                  value={name}
                  setValue={setName}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{textAlign: 'right', height: '40px', alignSelf: "flex-end"}}>
              <span className="form-field-label"></span>
              <Button type="primary"
                      htmlType="button"
                      onClick={() => {
                        setLoading(true);
                        setIsNeedUpdateReport(true);
                      }}
                      style={{backgroundColor: '#1CAA53', border: 'none', height: '100%', width: '100%'}}
              >
                Применить
              </Button>
            </Col>
          </Row>
        </div>}
      </div>
      <div className="reports__table">
        <div className="reports__table-wrapper">
          <Col span={24} style={{marginTop: "20px"}}>
            <Table
              style={{minWidth: '100%'}}
              columns={columns}
              dataSource={reportData}
              locale={{emptyText: "Данные отсутствуют"}}
              rowKey={"userId"}
              loading={loading}
              size="small"
              pagination={pagination}
              onChange={onTableChange}
            />
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
}
