import React, {useEffect, useState} from "react";
import {Form, Button, Col, DatePicker, Input, Modal, notification, Row} from "antd";
import '../../../components/modals/modals.css';
import InputField from "../../input-field";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import {orderDeliveryService} from "../../../services";

export default function ModalCreateOrderDocs({id, num, isShow, onCloseModal}) {
  const [fieldsErrors, setFieldsErrors] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [actNumber, setActNumber] = useState(null);
  const [actDate, setActDate] = useState(null);
  const [waybillsNumbers, setWaybillsNumbers] = useState("");
  const [isValidated, setIsValidated] = useState(false);

  const bigButtonStyle = {
    width: '116px',
    height: '40px',
    background: '#1CAA53',
    borderRadius: '10px',
    borderColor: "#1CAA53"
  };

  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
      ? fieldsErrors[nameField]
      : null;
  };

  const clearForm = () => {
    setInvoiceNumber(null);
    setInvoiceDate(null);
    setActNumber(null);
    setActDate(null);
    setWaybillsNumbers('');
  }

  const validateFormData = () => {
    let errors = {};

    if (invoiceNumber === null || invoiceNumber === "") {
      errors = {
        ...errors,
        invoiceNumber: ["Введите номер"]
      }
    }

    if (invoiceDate === null) {
      errors = {
        ...errors,
        invoiceDate: ["Введите дату"]
      }
    }

    if (actNumber === null || actNumber === "") {
      errors = {
        ...errors,
        actNumber: ["Введите номер"]
      }
    }

    if (actDate === null) {
      errors = {
        ...errors,
        actDate: ["Введите номер"]
      }
    }

    // if (!waybillsNumbers.length) {
    //   errors = {
    //     ...errors,
    //     waybillsNumbers: ["Введите номера ТН"]
    //   }
    // }

    if (Object.keys(errors).length !== 0) {
      setFieldsErrors(errors);
      setIsValidated(false);
    } else {
      setFieldsErrors(null);
      setIsValidated(true);
    }
  };

  useEffect(() => {
    if (
      invoiceNumber !== null &&
      // waybillsNumbers !== null &&
      invoiceDate !== null &&
      actNumber !== null &&
      actDate !== null
    ) {
      validateFormData();
    }
  }, [invoiceNumber, waybillsNumbers, invoiceDate, actNumber, actDate]);

  const onClickCreateDocs = (e) => {
    e.preventDefault();

    orderDeliveryService
      .createInvoice(id, Number(invoiceNumber), invoiceDate, waybillsNumbers)
      .then((response) => {
        notification.success({
          message: "Создание счета",
          description: response.message,
        });
      })
      .then(() => {
        orderDeliveryService
          .createAct(id, Number(actNumber), actDate, waybillsNumbers)
          .then((response) => {
            notification.success({
              message: "Создание акта",
              description: response.message,
            });
            setFieldsErrors(null);
            onCloseModal();
            clearForm();
          })
          .catch((error) => {
            notification.error({
              message: "Создание акта",
              description: error,
            });
          });
      })
      .catch((error) => {
        notification.error({
          message: "Создание счета",
          description: error,
        });
      });

  };

  return <Modal title={`Создание счета и акта для рейса № ${num}`}
                cancelText="Закрыть"
                className="modal-window"
                align={null}
                visible={isShow}
                onCancel={onCloseModal}
                footer={<div>
                  <Button type={"primary"} style={bigButtonStyle}
                          disabled={!isValidated}
                          onClick={onClickCreateDocs}>Создать</Button>
                </div>}
  >
    <Row gutter={16}>
      <Col md={24} lg={12}>
        <InputField type={"number"}
                    label={"Номер счета"}
                    name={"invoiceNumber"}
                    getFieldErrors={getFieldErrors}
                    setValue={value => {
                      setInvoiceNumber(value);
                    }}
                    value={invoiceNumber}
                    placeholder={"Введите номер"}
        />
      </Col>
      <Col md={24} lg={12}>
        <span className="form-field-label">{"Дата"}</span>
        <Form.Item validateStatus={fieldsErrors && typeof fieldsErrors.date !== "undefined" ? `error` : ""}
                   help={fieldsErrors && typeof fieldsErrors.date !== "undefined" ? fieldsErrors.date : ""}>
          <DatePicker className={"ant-input ant-input-lg"}
                      format="DD.MM.YYYY"
                      size="large"
                      name="invoiceDate"
                      locale={localeDatePicker}
                      allowClear={false}
                      width={"100%"}
                      onChange={(date) => {
                        setInvoiceDate(date);
                      }}
          />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col md={24} lg={12}>
        <InputField type={"number"}
                    label={"Номер акта"}
                    name={"actNumber"}
                    getFieldErrors={getFieldErrors}
                    setValue={setActNumber}
                    value={actNumber}
                    placeholder={"Введите номер акта"}
        />
      </Col>
      <Col md={24} lg={12}>
        <span className="form-field-label">{"Дата акта"}</span>
        <Form.Item validateStatus={fieldsErrors && typeof fieldsErrors.date !== "undefined" ? `error` : ""}
                   help={fieldsErrors && typeof fieldsErrors.date !== "undefined" ? fieldsErrors.date : ""}>
          <DatePicker className={"ant-input ant-input-lg"}
                      format="DD.MM.YYYY"
                      size="large"
                      name="actDate"
                      locale={localeDatePicker}
                      allowClear={false}
                      width={"100%"}
                      onChange={(date) => {
                        setActDate(date);
                      }}
          />
        </Form.Item>
      </Col>
    </Row>
    {/*<Row gutter={16}>*/}
    {/*  <Col>*/}
    {/*    <span className="form-field-label">{"Номера ТН"}</span>*/}
    {/*    <Form.Item validateStatus={fieldsErrors && typeof fieldsErrors.waybillsNumbers !== "undefined" ? `error` : ""}*/}
    {/*               help={fieldsErrors && typeof fieldsErrors.waybillsNumbers !== "undefined" ? fieldsErrors.waybillsNumbers : ""}>*/}
    {/*      <Input.TextArea rows={4}*/}
    {/*                      name={"waybillsNumbers"}*/}
    {/*                      placeholder={"Введите номера ТН через запятую"}*/}
    {/*                      allowClear*/}
    {/*                      defaultValue={""}*/}
    {/*                      onChange={(e) => {*/}
    {/*                        setWaybillsNumbers(e.target.value)*/}
    {/*                      }}*/}
    {/*                      value={waybillsNumbers}*/}
    {/*      />*/}
    {/*    </Form.Item>*/}
    {/*  </Col>*/}
    {/*</Row>*/}
  </Modal>
}
