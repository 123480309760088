import {Button, notification, Row, Col} from "antd";
import React, {useEffect, useState} from "react";
import {approveOrderService} from "../../../../services/approve-order.service";

export default function ChooseContractType({legalEntityID, shipperCompanyID, needUpdate, onChooseContract}) {
    const [contractTypes, setContractTypes] = useState([]);

    useEffect(() => {
        if (legalEntityID && shipperCompanyID) {
            approveOrderService.getContractsTypes(legalEntityID, shipperCompanyID)
                .then((resp) => {
                    setContractTypes(resp);
                })
                .catch((err) => {
                    notification.error({
                        message: "Получение списка договоров на заключиение",
                        description: err,
                    });
                })
        }
    }, [legalEntityID, shipperCompanyID])

    const renderContractTypesButtons = () => {
        return contractTypes.map((value => {
            return (
                <Col lg={8} sm={24}>
                    <Button onClick={() => {onChooseContract(value.type)}} className="small-button typical-btn-green" disabled={value.orderCreated}>
                        {value.name}
                    </Button>
                </Col>
            );
        }))
    };

    return (
        <Row>
            {renderContractTypesButtons()}
        </Row>
    );
}