import React, {useEffect, useState} from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  notification,
  Table,
  Icon,
  TimePicker,
  Checkbox,
  Card,
  Tooltip,
  Descriptions
} from "antd";
import Spinner from "../../spinner";
import {distributionCenterService} from "../../../services";
import '../modals.css';
import moment from "moment";
import ModalCreateGateForDistributionCenter from "../create-gate";


export default function ModalListOfGates({
                                           isShow, distCenterId,
                                           onCloseModal, name
                                         }) {

  const [loadingContentModal, setLoadingContentModal] = useState(true);
  const [isNeedUpdateList, setIsNeedUpdateList] = useState(false);
  const [gates, setGates] = useState([]);
  const [showModalEditGate, setShowModalEditGate] = useState(null);
  const [showModalCreateGates, setShowModalCreateGates] = useState(false);

  const namesDay = [
    "понедельник",
    "вторник",
    "среда",
    "четверг",
    "пятница",
    "суббота",
    "воскресенье"
  ]

  useEffect(() => {
    if (isShow && loadingContentModal || isNeedUpdateList) {
      distributionCenterService.getGates(distCenterId)
        .then((response) => {
          const adaptedData = response.map((item) => {
            return {
              id: item.id,
              name: item.name,
              isActive: item.isActive,
              serviceTime: item.serviceTime,
              workingDays: item.workingDays.map((workingDay) => {
                if (workingDay.breaks && workingDay.breaks.length) {
                  return {
                    dayNum: workingDay.dayNum,
                    startWorkingTime: workingDay.startWorkingTime.slice(0, 5),
                    endWorkingTime: workingDay.endWorkingTime.slice(0, 5),
                    startBreakTime: workingDay.breaks[0].startTime.slice(0, 5),
                    endBreakTime: workingDay.breaks[0].endTime.slice(0, 5),
                  }
                } else {
                  return {
                    dayNum: workingDay.dayNum,
                    startWorkingTime: workingDay.startWorkingTime.slice(0, 5),
                    endWorkingTime: workingDay.endWorkingTime.slice(0, 5),
                  }
                }
              })
            }
          })
          setGates(adaptedData);
          setLoadingContentModal(false);
          setIsNeedUpdateList(false);
        })
        .catch((error) => {
          setLoadingContentModal(false);
          setIsNeedUpdateList(false);
        });
    }
  });

  const openModalEditGate = (id) => {
    setShowModalEditGate(id);
  }

  const scheduleElement = (text, record, index) => {
    return (
      record.workingDays.map((item, index) =>
        <div className="working-time" key={index}>
          <div className="working-time__item working-time__item--day">
            {namesDay[item.dayNum - 1]}
          </div>
          <div className="working-time__schedule">
            <div className="working-time__item">
              <TimePicker value={moment(item.startWorkingTime, "HH:mm")} size="small" format="HH:mm"
                          disabled={true}/>
              <TimePicker value={moment(item.endWorkingTime, "HH:mm")} size="small" format="HH:mm"
                          disabled={true}/>
            </div>
            {item.startBreakTime &&
              <>
                <div className="working-time__subtitle"><Icon type="coffee" style={{fontSize: "10px"}}/> перерыв
                </div>
                <div className="working-time__item working-time__item--breaks" key={index}>
                  <TimePicker value={moment(item.startBreakTime, "HH:mm")} size="small"
                              suffixIcon={<Icon type="coffee"/>} format="HH:mm" disabled={true}/>
                  <TimePicker value={moment(item.endBreakTime, "HH:mm")} size="small"
                              suffixIcon={<Icon type="coffee"/>} format="HH:mm" disabled={true}/>
                </div>
              </>
            }
          </div>
        </div>
      )
    )
  };

  const editButton = (text, record, index) => {
    return (
      <React.Fragment>
        <Button htmlType="button" icon="edit" type="primary"
                onClick={() => openModalEditGate(record.id)}/>
        <ModalCreateGateForDistributionCenter
          id={distCenterId}
          idGate={Number(record.id)}
          isShow={(record.id === showModalEditGate)}
          onCloseModal={() => {
            setShowModalEditGate(null);
          }}
          setIsNeedUpdateList={setIsNeedUpdateList}
          nameD={name}
          gateData={{...record}}
        />
      </React.Fragment>
    );
  }

  const columns = [
    {
      title: 'Ворота',
      key: 'name',
      width: '25%',
      render: (text, record, index) => {
        return <div>
          <div>{record.name}</div>
          <div style={!record.isActive ? {color: "red"} : {}}>{!record.isActive ?
            <sup>(ворота недоступны для записи)</sup> : ""}</div>
        </div>
      }
    },
    {
      title: 'Время погрузки, мин',
      dataIndex: 'serviceTime',
      key: 'serviceTime',
      width: '15%',
    },
    {
      title: 'Часы работы',
      dataIndex: 'workingDays',
      key: 'workingDays',
      render: (text, record, index) => scheduleElement(text, record, index)
    },
    {
      title: '',
      key: 'edit',
      width: '5%',
      render: (text, record, index) => editButton(text, record, index),
    },
  ];

  return (
    <Modal
      title={<span>Ворота погрузки РЦ «{name}»</span>}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        onCloseModal();
      }}
      forceRender={true}
      footer={''}
    >
      <Spinner loading={loadingContentModal} label={null}>
        <Row gutter={[16, 24]} style={{marginBottom: "34px"}}>
          <Col>
            <Button htmlType="button" type="primary" onClick={() => setShowModalCreateGates(true)}>Добавить
              ворота</Button>
            <ModalCreateGateForDistributionCenter
              id={Number(distCenterId)}
              isShow={showModalCreateGates}
              onCloseModal={() => {
                setShowModalCreateGates(false);
              }}
              setIsNeedUpdateList={setIsNeedUpdateList}
              nameD={name}
            />
          </Col>
        </Row>
        <Table
          style={{minWidth: '100%'}}
          columns={columns}
          className="app-table app-table--with-card-mobile distribution-centres-table"
          dataSource={gates}
          locale={{emptyText: "Данные отсутствуют"}}
          rowKey="id"
          size="small"
        />
      </Spinner>
    </Modal>
  );
}
