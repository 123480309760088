import React, {useEffect, useState} from "react";
import {Button, Divider, Modal, notification, Spin, Table} from "antd";
import {companyService} from "../../../services";
import './style.less'
import ModalChangeUserLogin from "../change-user-login";

export default function ModalEmployeeListForConsignor({isShow, setIsShow, companyID}) {
  const [loading, setLoading] = useState(true);
  const [employeesList, setEmployeesList] = useState([]);
  const [needUpdate, setNeedUpdate] = useState(true);
  const [showUserChangeLoginModal, setShowUserChangeLoginModal] = useState(null);

  useEffect(() => {
    if (companyID && isShow && needUpdate) {
      setLoading(true);
      companyService.getEmployeeListForConsignor(companyID)
        .then(resp => {
          setEmployeesList(resp.Employees);
          setLoading(false);
          setNeedUpdate(false);
        })
        .catch((err) => {
          notification.error({
            message: "Получение списка сотрудников компании",
            description: err,
          });
        });
    }
  }, [companyID, isShow, needUpdate]);

  const columns = [

    {
      title: "Сотрудники",
      key: 'mobile',
      render: (record) => (
        <>
          <b>{record.FirstName} {record.Patronymic} {record.SecondName}</b>
          <Divider>Email</Divider>
          {record.Email}
          <Divider>Телефон</Divider>
          {record.Phone}
          <Divider>Статус</Divider>
          {record.Status}
          <Divider/>
          {record.Status !== "Удален" && <>
            <Button style={{marginLeft: "0px", width: "100%"}}
                    size="small"
                    className="small-button typical-btn-blue"
                    onClick={() => {
                      setShowUserChangeLoginModal(record.Id);
                    }}>
              Изменить логин
            </Button>
            <ModalChangeUserLogin
              id={record.UserId}
              isShow={showUserChangeLoginModal === record.Id}
              setIsShow={() => {
                setShowUserChangeLoginModal(null)
              }}
              setNeedUpdateList={setNeedUpdate}
            />
          </>
          }
        </>
      ),
      className: "desktop-hidden"
    },
    {
      title: "ФИО",
      key: 'fio',
      render: (record) => (
        <React.Fragment>
          {record.FirstName} {record.Patronymic} {record.SecondName}
        </React.Fragment>
      ),
      className: "mobile-hidden"
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'email',
      className: "mobile-hidden"
    },
    {
      title: 'Телефон',
      dataIndex: 'Phone',
      key: 'phone',
      className: "mobile-hidden"
    },
    {
      title: 'Статус',
      dataIndex: 'Status',
      key: 'status',
      className: "mobile-hidden"
    },
    {
      title: '',
      key: 'action',
      className: "mobile-hidden",
      render: (record) => (
        record.Status !== "Удален" &&
        <>
          <Button style={{marginLeft: "0px", width: "100%"}}
                  size="small"
                  className="small-button typical-btn-blue"
                  onClick={() => {
                    setShowUserChangeLoginModal(record.Id);
                  }}>
            Изменить логин
          </Button>
          <ModalChangeUserLogin
            id={record.UserId}
            isShow={showUserChangeLoginModal === record.Id}
            setIsShow={() => {
              setShowUserChangeLoginModal(null)
            }}
            setNeedUpdateList={setNeedUpdate}
          />
        </>
      )
    },
  ];

  return (
    <Modal
      title={`Список сотрудников`}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
      }}
      onOk={() => {
        setIsShow(false);
      }}
    >
      <Spin spinning={loading} tip="Загрузка...">
        <Table
          columns={columns}
          dataSource={employeesList}
          bordered={true}
          className={"employees-table"}
          rowKey={"Id"}
        />
      </Spin>
    </Modal>
  );
}
