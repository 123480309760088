import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, notification, Upload, Icon, Button } from 'antd';
import { formHelper } from '../../../helpers/form.helper';
import {companyService, consignorService} from '../../../services/';
import Spinner from '../../spinner';
import FieldBlock from "../../field-block";
import {approveOrderService} from "../../../services/approve-order.service";

export default function ModalConsignorUploadSignedContracts({ isShow, setIsShow, setNeedUpdate, orderId, orgFormId }) {

    const [guarantorDoc, setGuarantorDoc] = useState(null);
    const [orgDoc, setOrgDoc] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fieldsErrors, setFieldsErrors] = useState([]);
    const [docsList, setDocsList] = useState([]);

    useEffect(() => {
        setLoading(false);

        setGuarantorDoc(null);
        setOrgDoc(null);
    }, [isShow, orderId]);

    const getFieldErrors = (fieldName) => {
        return typeof fieldsErrors[fieldName] !== "undefined"
          ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
            return fieldErrors + "; " + error;
          })
          : null;
    };

  const handleUploadContracts = () => {
    setLoading(true);

    consignorService.agreeOrder(orderId, guarantorDoc, orgDoc)
      .then(resp => {
        if (resp.code === 200) {
          notification.success({
            message: 'Загрузка формы договора',
            description: resp.message
          });

          setIsShow(false);
          setNeedUpdate(true);

          setOrgDoc(null);
          setGuarantorDoc(null);
        } else {
          notification.error({
            message: 'Загрузка формы договора',
            description: resp.message
          });

          setLoading(false);
          setFieldsErrors(resp.errors);
        }
      }).catch(errMsg => notification.error({
        message: 'Загрузка формы договора',
        description: errMsg
      }));
  };

    const handleUploadDocs = () => {
        setLoading(true);
        approveOrderService.agreeContractOrder(orderId, docsList)
            .then(resp => {
                if (resp.code !== 400) {
                    notification.success({
                        message: "Загрузка договоров",
                        description: resp.message,
                    });
                    setLoading(false);
                    setIsShow(false);
                    setNeedUpdate(true);
                } else {
                    notification.error({
                        message: "Загрузка договоров",
                        description: resp.message,
                    });
                    setFieldsErrors(resp.errors);
                    setLoading(false);
                }
            })
            .catch(err => {
                notification.error({
                    message: "Отправка заявки",
                    description: err,
                });
            });
    };

    useEffect(() => {
        if (orderId) {
            setLoading(true);
            approveOrderService.getContractsListOnUpload(orderId)
                .then((resp) => {
                    setDocsList(resp);
                    setLoading(false);
                })
                .catch((err) => {
                    notification.error({
                        message: 'Получение списка договоров',
                        description: err
                    })
                });
        }
    }, [orderId]);

    const getUploaderClass = (index) => {
        return {
            multiple: false,
            showUploadList: true,
            onRemove: () => {
                let docs = docsList;
                docs[index].filledFile = null;
                setDocsList([...docs]);
            },
            beforeUpload: (file) => {
                if (file.type === 'application/pdf') {
                    file.thumbUrl = 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg';
                }

                let docs = docsList;
                docs[index].filledFile = file;
                setDocsList([...docs]);
                return false;
            },
            fileList: docsList[index].filledFile ? [docsList[index].filledFile] : null,
            listType: 'picture',
        };
    };

    const handleDownloadBlank = filePath => {
        window.open(filePath);
    };

    const renderDocsForm = () => {
        return docsList.map((value, index) => {
            return (
                <React.Fragment key={index}>
                    {value.uploadedFile && <Col sm={8} offset={8}>
                        <Button onClick={() => handleDownloadBlank(value.uploadedFile)} size="small"
                                className="small-button typical-btn-blue">
                            Скачать {value.alias}
                        </Button>
                        <br/>
                        <FieldBlock name={value.name} getErrors={getFieldErrors}>
                            <Upload
                                className="single-btn-upload"
                                name={value.name}
                                {...getUploaderClass(index)}
                            >
                                <Button htmlType="button">
                                    <svg width="17" height="22" viewBox="0 0 17 22" fill="none"
                                         xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
                                        <path
                                            d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z"
                                            fill="#1CAA53"></path>
                                        <path
                                            d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z"
                                            fill="#1CAA53"></path>
                                        <path
                                            d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z"
                                            fill="#1CAA53"></path>
                                    </svg>
                                    Прикрепить {value.alias}
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>}
                </React.Fragment>
            );
        });
    }

  const bigButtonStyle = { color: "white", backgroundColor: "#1CAA53", border: "none" };

  return (
    <Modal
      title={'Загрузить подписанный договор'}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
        setNeedUpdate(true);
        setLoading(false);
        setDocsList([]);
      }}
      footer={
        <>
          <Button onClick={handleUploadDocs} style={bigButtonStyle}>
            Загрузить и согласовать
          </Button>
        </>
      }
    >
      <Spinner loading={loading} tip="Загрузка">
        <Row gutter={16}>
          {/*<Col sm={8} offset={8}>*/}
          {/*  <FieldBlock name="organizationContract" getErrors={getFieldErrors}>*/}
          {/*    <Upload*/}
          {/*      className="single-btn-upload"*/}
          {/*      multiple={false}*/}
          {/*      showUploadList={true}*/}
          {/*      listType='picture'*/}
          {/*      onRemove={() => setOrgDoc(null)}*/}
          {/*      beforeUpload={file => {*/}
          {/*        if (file.type === 'application/pdf') {*/}
          {/*          file.thumbUrl = 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg';*/}
          {/*        }*/}

          {/*        setOrgDoc(file);*/}
          {/*        return false;*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <Button htmlType="button">*/}
          {/*        <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">*/}
          {/*          <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>*/}
          {/*          <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>*/}
          {/*          <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>*/}
          {/*        </svg>*/}
          {/*        Договор с организацией*/}
          {/*      </Button>*/}
          {/*    </Upload>*/}
          {/*  </FieldBlock>*/}
          {/*</Col>*/}
          {/*{orgFormId !== 1 &&*/}
          {/*  <Col sm={8} offset={8} style={{ marginTop: '20px' }}>*/}
          {/*    <FieldBlock name="guarantorContract" getErrors={getFieldErrors}>*/}
          {/*      <Upload*/}
          {/*        className="single-btn-upload"*/}
          {/*        multiple={false}*/}
          {/*        showUploadList={true}*/}
          {/*        listType='picture'*/}
          {/*        onRemove={() => setGuarantorDoc(null)}*/}
          {/*        beforeUpload={file => {*/}
          {/*          if (file.type === 'application/pdf') {*/}
          {/*            file.thumbUrl = 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg';*/}
          {/*          }*/}

          {/*          setGuarantorDoc(file);*/}
          {/*          return false;*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <Button htmlType="button">*/}
          {/*          <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">*/}
          {/*            <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>*/}
          {/*            <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>*/}
          {/*            <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>*/}
          {/*          </svg>*/}
          {/*          Договор поручителя*/}
          {/*        </Button>*/}
          {/*      </Upload>*/}
          {/*    </FieldBlock>*/}
          {/*  </Col>*/}
          {/*}*/}
            {renderDocsForm()}
        </Row>
      </Spinner>
    </Modal>
  );
}
