import React, {useEffect, useState} from "react";
import {Button, Icon, notification, Popconfirm} from "antd";
import ModalApproveOrderHistoryList from "../../../../modals/approve-order-history-list";
import ModalRefuseOrder from "../../../../modals/refuse-order";
import ModalConsignorUploadSignedContracts from "../../../../modals/consignor-upload-signed-contracts";
import {fileHelper} from "../../../../../helpers/file.helper";
import {approveOrderService} from "../../../../../services/approve-order.service";
import {
  CONSIGNOR_DIRECTOR_PERMISSION,
  LOGIST_LEGAL_ENTITY_APPROVE
} from "../../../../../helpers/user_permission.helper";
import {userServices} from "../../../../../services";
import ModalShowContractOrderInfo from "../../../../modals/contract-order-info";

const ContractsListItem = ({contract, consignorSettings, legalEntityID, setNeedUpdate}) => {
  const user = userServices.getUser();

  const [isShowInfo, setShowInfo] = useState(false);
  const [isShowUploadContractsModal, setIsShowUploadContractsModal] = useState(false);
  const [isShowContractOrderInfo, setIsShowContractOrderInfo] =useState(false);

  const handleDownloadDocsArchive = (id) => {
    approveOrderService.getDownloadContractOrderFiles(id)
      .then(resp => {
        fileHelper.downloadFileBase64(resp, 'contracts.zip')
      })
      .catch(err => {
        notification.error({
          message: "Загрузка архива",
          description: err
        });
      });
  };

  const handleArchiveContractOrder = (id) => {
    approveOrderService.getArchiveContractOrder(id)
      .then(resp => {
        setNeedUpdate(true);
        notification.error({
          message: "Расторжение договора",
          description: resp.message
        });
      })
      .catch(err => {
        notification.error({
          message: "Расторжение договора",
          description: err
        });
      });
  };

  const handleDeleteContractOrder = (id) => {
    approveOrderService.getDeleteContractOrder(id)
      .then(resp => {
        setNeedUpdate(true);
        notification.error({
          message: "Удаление договора",
          description: resp.message
        });
      })
      .catch(err => {
        notification.error({
          message: "Удаление договора",
          description: err
        });
      });
  };

  const handleAgreeContractOrder = (id) => {
    approveOrderService.agreeContractOrder(id, [], null)
      .then(resp => {
          setNeedUpdate(true);
          notification.error({
            message: "Согласование договора",
            description: resp.message
          });
        })
          .catch(err => {
            notification.error({
              message: "Согласование договора",
              description: err
            });
          });
  };

  return <div className="card__table-item">
    <div>{contract.shipperCompanyName}</div>
    <div className="card__contract-status-wrapper">
      <div className={`card__contract-status card__contract-status--${contract.status.id}`}
      >
        {contract.status.title}
      </div>
    </div>
    <div>{contract.date}</div>
    <div className="card__table-item-buttons">
      <div className={"card__approved-buttons"}>
        {(contract.status.id === 1 || contract.status.id === 2 || contract.status.id === 3) &&
          <Popconfirm
            title={`Скачать документы?`}
            okText="Да"
            cancelText="Нет"
            placement="topRight"
            onConfirm={() => handleDownloadDocsArchive(contract.id)}
            icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}
          >
            <Button className={"card__approved-button"} aria-label={"Скачать документы"}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18 2H10L4 8V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM12 17L8 13H11V9.02L13 9V13H16L12 17Z"
                  fill="#2B3F63"/>
              </svg>
            </Button>
          </Popconfirm>
          }
        {contract.status.id === 1 &&
          <Popconfirm
            title={`Согласовать?`}
            okText="Да"
            cancelText="Нет"
            placement="topRight"
            onConfirm={() => {
              if (!consignorSettings.isAutoAgreeContracts) {
                setIsShowUploadContractsModal(true)
              } else {
                handleAgreeContractOrder(contract.id);
              }
            }}
            icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}
          >
            <Button className={"card__approved-button"} aria-label={"Согласовать договор"}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M23 12L20.56 9.21998L20.9 5.53998L17.29 4.71998L15.4 1.53998L12 2.99998L8.6 1.53998L6.71 4.71998L3.1 5.52998L3.44 9.20998L1 12L3.44 14.78L3.1 18.47L6.71 19.29L8.6 22.47L12 21L15.4 22.46L17.29 19.28L20.9 18.46L20.56 14.78L23 12ZM10 17L6 13L7.41 11.59L10 14.17L16.59 7.57998L18 8.99998L10 17Z"
                  fill="#1CAA53"/>
              </svg>
            </Button>
          </Popconfirm>
          }
        {contract.status.id === 1 && (Boolean(user.isAccessTo(CONSIGNOR_DIRECTOR_PERMISSION)) || Boolean(user.isAccessTo(LOGIST_LEGAL_ENTITY_APPROVE))) &&
          <Popconfirm
            title={`Отклонить?`}
            okText="Да"
            cancelText="Нет"
            placement="topRight"
            onConfirm={() => setShowInfo(true)}
            icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}
          >
            <Button className={"card__approved-button"} aria-label={"Отклонить договор"}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.1 4.89998C15.2 0.999976 8.79998 0.999976 4.89998 4.89998C0.999976 8.79998 0.999976 15.2 4.89998 19.1C8.79998 23 15.1 23 19 19.1C22.9 15.2 23 8.79998 19.1 4.89998ZM14.8 16.2L12 13.4L9.19998 16.2L7.79998 14.8L10.6 12L7.79998 9.19998L9.19998 7.79998L12 10.6L14.8 7.79998L16.2 9.19998L13.4 12L16.2 14.8L14.8 16.2Z"
                  fill="#BE1C1C"/>
              </svg>
            </Button>
          </Popconfirm>
        }
        {(contract.status.id === 3) &&
          <Popconfirm
            title={`Посмотреть причину отказа`}
            okText="Да"
            cancelText="Нет"
            placement="topRight"
            onConfirm={() => setIsShowContractOrderInfo(true)}
            icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}
          >
            <Button className={"card__approved-button"} aria-label={"Посмотреть причину отказа"}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.07 9.25L12.17 10.17C11.45 10.89 11 11.5 11 13H9V12.5C9 11.39 9.45 10.39 10.17 9.67L11.41 8.41C11.78 8.05 12 7.55 12 7C12 6.46957 11.7893 5.96086 11.4142 5.58579C11.0391 5.21071 10.5304 5 10 5C9.46957 5 8.96086 5.21071 8.58579 5.58579C8.21071 5.96086 8 6.46957 8 7H6C6 5.93913 6.42143 4.92172 7.17157 4.17157C7.92172 3.42143 8.93913 3 10 3C11.0609 3 12.0783 3.42143 12.8284 4.17157C13.5786 4.92172 14 5.93913 14 7C13.9986 7.84324 13.6643 8.65183 13.07 9.25ZM11 17H9V15H11M10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 4.47 15.5 0 10 0Z"
                  fill="#2B3F63"/>
              </svg>
            </Button>
          </Popconfirm>
        }
        {(contract.status.id === 3 || contract.status.id === 1) && (user.isAccessTo(CONSIGNOR_DIRECTOR_PERMISSION) === true || user.isAccessTo(LOGIST_LEGAL_ENTITY_APPROVE) === true)  &&
          <Popconfirm
            title={`Удалить?`}
            okText="Да"
            cancelText="Нет"
            placement="topRight"
            onConfirm={() => handleDeleteContractOrder(contract.id)}
            icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}
          >
            <Button className={"card__approved-button"} aria-label={"Удалить договор"}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#BE1C1C"/>
              </svg>
            </Button>
          </Popconfirm>}
        {(contract.status.id === 2) && (user.isAccessTo(CONSIGNOR_DIRECTOR_PERMISSION) !== true) &&
          <Popconfirm
            title={`Расторгнуть?`}
            okText="Да"
            cancelText="Нет"
            placement="topRight"
            onConfirm={() => handleArchiveContractOrder(contract.id)}
            icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}
          >
            <Button className={"card__approved-button"} aria-label={"Расторгнуть договор"}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.2844 7.46564L15.0344 2.21564C14.8915 2.07635 14.6995 1.99889 14.5 2.00001H5.5C5.10218 2.00001 4.72064 2.15805 4.43934 2.43935C4.15804 2.72066 4 3.10219 4 3.50001V20C4 20.3978 4.15804 20.7794 4.43934 21.0607C4.72064 21.342 5.10218 21.5 5.5 21.5H19C19.3978 21.5 19.7794 21.342 20.0607 21.0607C20.342 20.7794 20.5 20.3978 20.5 20V8.00001C20.5011 7.80048 20.4237 7.60851 20.2844 7.46564ZM15.0344 15.7156C15.1752 15.8578 15.2542 16.0499 15.2542 16.25C15.2542 16.4501 15.1752 16.6422 15.0344 16.7844C14.891 16.923 14.6994 17.0005 14.5 17.0005C14.3006 17.0005 14.109 16.923 13.9656 16.7844L12.25 15.0594L10.5344 16.7844C10.391 16.923 10.1994 17.0005 10 17.0005C9.80059 17.0005 9.60898 16.923 9.46563 16.7844C9.32479 16.6422 9.24578 16.4501 9.24578 16.25C9.24578 16.0499 9.32479 15.8578 9.46563 15.7156L11.1906 14L9.46563 12.2844C9.34603 12.1387 9.28491 11.9537 9.29416 11.7654C9.3034 11.5771 9.38236 11.399 9.51566 11.2657C9.64896 11.1324 9.82708 11.0534 10.0154 11.0442C10.2037 11.0349 10.3887 11.096 10.5344 11.2156L12.25 12.9406L13.9656 11.2156C14.1113 11.096 14.2963 11.0349 14.4846 11.0442C14.6729 11.0534 14.851 11.1324 14.9843 11.2657C15.1176 11.399 15.1966 11.5771 15.2058 11.7654C15.2151 11.9537 15.154 12.1387 15.0344 12.2844L13.3094 14L15.0344 15.7156ZM14.5 8.00001V3.87501L18.625 8.00001H14.5Z"
                  fill="#BE1C1C"/>
              </svg>
            </Button>
          </Popconfirm>
        }
      </div>
    </div>
    <ModalRefuseOrder isShow={isShowInfo} setIsShow={setShowInfo} orderId={contract.id}
                      setIsNeedUpdate={setNeedUpdate}/>
    <ModalShowContractOrderInfo isShow={isShowContractOrderInfo} setIsShow={setIsShowContractOrderInfo} id={contract.id}/>
    <ModalConsignorUploadSignedContracts
      isShow={isShowUploadContractsModal}
      setIsShow={setIsShowUploadContractsModal}
      orgFormId={legalEntityID}
      orderId={contract.id}
      setNeedUpdate={setNeedUpdate}
    />
  </div>
}

export default ContractsListItem;
