import React, { useEffect, useState } from "react";
import { Button, Row, Col, Table } from "antd";
import Spinner from "../../../spinner";
import ModalCreateRole from "../../../modals/role/create";
import ModalEditRole from "../../../modals/role/edit";
import { roleService } from "../../../../services/role.services";
import { tableHelper } from "../../../../helpers";

export default function RolesList() {
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isNeedUpdateList, setIsNeedUpdateList] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const [showModalCreate, setShowModalCreate] = useState(false);

  const [rolesList, setRolesList] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  let smallButtonStyle = { width: '120px', height: '40px', background: '#1CAA53', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent" };
  let addRoleButton = { width: '100px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent" };

  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateList(true);
    }
  }, []);

  if (isNeedUpdateList) {
    roleService
      .getRoles()
      .then(responseData => {
        const rolesData = responseData.map(item => {
          return {
            name: item.Name,
            desc: item.Description,
            permissions: item.Permissions
          };
        });

        setIsNeedUpdateList(false);
        setLoading(false);
        setRolesList(rolesData);
      })
      .catch(errorMsg => {
        setIsNeedUpdateList(false);
        setLoading(false);
        setErrorMsg(errorMsg);
      });
  }

  if (errorMsg) {
    return <div className="content-error-block">{errorMsg}</div>;
  }

  const columns = [
    {
      title: "Название",
      dataIndex: "desc",
      key: "desc",
      width: "40%",
      ...tableHelper.getColumnSearchProps("desc", searchText, setSearchText)
    },
    {
      title: "Тех. имя",
      dataIndex: "name",
      key: "name",
      width: "40%",
      ...tableHelper.getColumnSearchProps("desc", searchText, setSearchText)
    },
    {
      title: null,
      key: "edit",
      width: "10%",
      render: data => {
        return (
          <React.Fragment>
            <Button type="primary" htmlType="button" onClick={() => setShowModalEdit(data.name)} size="small" style={smallButtonStyle}>
              Изменить
            </Button>
            <ModalEditRole
              isShow={showModalEdit === data.name}
              onCloseModal={() => {
                setShowModalEdit(null);
                setLoading(true);
                setIsNeedUpdateList(true);
              }}
              roleName={data.name}
              roleDesc={data.desc}
              rolePermissions={data.permissions.map(permissionData => permissionData.Name)}
            />
          </React.Fragment>
        );
      }
    }
  ];

  return (
    <React.Fragment>
      <h1 className="content-header" style={{ marginBottom: 5 }}>
        Управление ролями
      </h1>
      <Spinner loading={loading} label={null}>
        <Row type="flex" justify="end">
          <Col span={4} className="btn-create-entity-block">
            <Button htmlType="button" type="primary" style={addRoleButton} onClick={() => setShowModalCreate(true)}>
              Создать
            </Button>
            <ModalCreateRole
              onCloseModal={() => {
                setShowModalCreate(false);
                setLoading(true);
                setIsNeedUpdateList(true);
              }}
              isShow={showModalCreate}
            />
          </Col>
        </Row>
        <Col span={24}>
          <Table
            style={{ minWidth: "700px" }}
            columns={columns}
            dataSource={rolesList}
            locale={{ emptyText: "Роли отсутствуют" }}
            rowKey="name"
            size="small"
          />
        </Col>
      </Spinner>
    </React.Fragment>
  );
}
