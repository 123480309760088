import React, {useCallback, useEffect, useState} from "react";
import {Button, Col, Modal, Row, Spin, Form, notification, Steps, Icon} from "antd";
import {companyService, systemService} from "../../../services";
import EditRusLegalEntity from "./rus-legal-entity";
import EditLithuaniaLegalEntity from "./lithuania-legal-entity";
import EditBelarusLegalEntity from "./belarus-legal-entity";
import EditKazakhstanLegalEntity from "./kazakhstan-legal-entity";

export default function ModalEditLegalEntityNew({ legalEntityId, isShow, setIsShow, onSuccess }) {
  const [loading, setLoading] = useState(false);

  const [stepsList, setStepsList] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);

  const [changeStepEvent, setChangeStepEvent] = useState('');
  const [editLegalEntityEvent, setEditLegalEntityEvent] = useState(false);

  const [countryId, setCountryId] = useState(null);

  const [legalEntityData, setLegalEntityData] = useState({});

	let submitBtnStyle = {
    width: "200px",
    height: "43px",
    background: "#1CAA53",
    position: "static",
    borderRadius: "10px",
    color: "white",
    borderColor: "transparent",
  };
  useEffect(() => {
    if (isShow) {
      setLoading(true);

      companyService
          .getLegalEntityData(legalEntityId)
          .then((response) => {

            setLegalEntityData(response);
            setCountryId(response.CountryId);

            setLoading(false);

          })
          .catch((error) => {
            notification.error({
              message: "Получение списка организационных форм",
              description: error,
            });
          });
    }
  }, [isShow]);

  const renderStepsList = () => {
    if (currentStep === 0) {
      return null;
    }

    return stepsList.map((value, index) => {
      return (
          <Steps.Step title={value} />
      );
    });
  };

  const renderEditFrom = () => {
    switch (countryId) {
      case 1:
        return (
            <EditRusLegalEntity
              isActive={true}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              legalEntityData={legalEntityData}
              setLoading={setLoading}
              setStepsList={setStepsList}
              changeStepEvent={changeStepEvent}
              setChangeStepEvent={setChangeStepEvent}
              editLegalEntityEvent={editLegalEntityEvent}
              setEditLegalEntityEvent={setEditLegalEntityEvent}
              setIsShowParent={setIsShow}
              legalEntityId={legalEntityId}
            />
        );
      case 7:
        return (
            <EditLithuaniaLegalEntity
              isActive={true}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              legalEntityData={legalEntityData}
              setLoading={setLoading}
              setStepsList={setStepsList}
              changeStepEvent={changeStepEvent}
              setChangeStepEvent={setChangeStepEvent}
              editLegalEntityEvent={editLegalEntityEvent}
              setEditLegalEntityEvent={setEditLegalEntityEvent}
              setIsShowParent={setIsShow}
              legalEntityId={legalEntityId}
            />
        );
        case 3:
            return (
                <EditBelarusLegalEntity
                    isActive={true}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    legalEntityData={legalEntityData}
                    setLoading={setLoading}
                    setStepsList={setStepsList}
                    changeStepEvent={changeStepEvent}
                    setChangeStepEvent={setChangeStepEvent}
                    editLegalEntityEvent={editLegalEntityEvent}
                    setEditLegalEntityEvent={setEditLegalEntityEvent}
                    setIsShowParent={setIsShow}
                    legalEntityId={legalEntityId}
                />
            );
      case 6:
        return (
            <EditKazakhstanLegalEntity
                isActive={true}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                legalEntityData={legalEntityData}
                setLoading={setLoading}
                setStepsList={setStepsList}
                changeStepEvent={changeStepEvent}
                setChangeStepEvent={setChangeStepEvent}
                editLegalEntityEvent={editLegalEntityEvent}
                setEditLegalEntityEvent={setEditLegalEntityEvent}
                setIsShowParent={setIsShow}
                legalEntityId={legalEntityId}
            />
        );
      default:
        return null;
    }
  };

  const editLegalEntity = () => {
    setEditLegalEntityEvent(true);
  };

  const changeStep = (type) => {
    if (currentStep < 1) {
      setCurrentStep(1);
    } else {
      setChangeStepEvent(type);
    }
  };

  return (
    <Modal
      title={`Редактирование юр. лица`}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
      }}
      footer={[
        <div style={{marginTop: "10"}}>
          {!loading &&
          <div style={{marginTop: "10"}}>
            {currentStep !== 0 &&
              (
						  <Button htmlType="submit" key="submitPrev" style={submitBtnStyle} onClick={() => changeStep('previous')} type="danger" className="button-footer-previous">
					<Icon
						style={{
						position: "absolute",
						paddingTop: "3px",
						display: "flex",
						}}
						type="left"
					/>{" "}
					Назад
                  </Button>
              )
            }
            {(currentStep === 0) || (currentStep < stepsList.length) ? (
						  <Button htmlType="submit" key="submitNext" style={submitBtnStyle} onClick={() => changeStep('next')} type="primary" className="button-footer-next">
                   Далее{" "}
                  <Icon
                    style={{
                      position: "absolute",
                      paddingLeft: "39px",
                      paddingTop: "3px",
                    }}
                    type="right"
                  />
                </Button>
            ) : (
                <Button htmlType="submit" key="submit" style={submitBtnStyle} onClick={() => editLegalEntity()} type="primary">
                  Сохранить
                </Button>
            )}
          </div>
          }
        </div>
      ]}
    >
      <Spin spinning={loading} tip="Загрузка...">
        <Form>
          <Row gutter={16}>
			<Steps type="navigation" size="small" current={currentStep - 1} className="site-navigation-steps" style={{marginBottom: "14px"}}>
              {renderStepsList()}
            </Steps>
            <br />
            {renderEditFrom()}
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}
