import {Button, Checkbox, Col, Input, Radio, Row, Select} from "antd";
import InputField from "../../../../input-field";
import React, {useState} from "react";


const FiltersForConsignor = ({filterParams, setCurrentPage, counts, shipperCompanies, distributionCentersList, isNeedUpdate, setFilterParams, setIsNeedUpdate}) => {
  const {Option} = Select;
  const InputGroup = Input.Group;

  const DOC_STATUSES = [
    {id: 1, name: "На согласовании"},
    {id: 2, name: "Согласовано"},
    {id: 3, name: "Отменён"},
    {id: 4, name: "Ожидает скан подписанного договора"},
  ]

  const STATUSES = [
    {id: 1, name: "На согласовании"},
    {id: 2, name: "Согласовано"},
    {id: 3, name: "Отменён"},
  ]

  const COMPANY_TYPES = [
    {id:2, name:"Экспедитор"},
    {id:1, name:"Собственник автотранспорта"},
    {id:6, name:"Транспортная компания"},
    {id:4, name:"Грузовладелец"}
  ]

  const [radioValue, setRadioValue] = useState(3);

  const selectHandleChange = (value) => {
    setFilterParams({...filterParams, agreedContractByShipperCompanies: value})
  }

  const selectDCHandleChange = (value) => {
    setFilterParams({...filterParams, distributionCenterId: value})
  }

  const docSelectHandleChange = (value) => {
    setFilterParams({...filterParams, contractApproveStatuses: value})
  }

  const approveSelectHandleChange = (value) => {
    setFilterParams({...filterParams, legalEntityApproveStatuses: value})
  }

  const companyTypeSelectHandleChange = (value) => {
    setFilterParams({...filterParams, companyTypes: value})
  }

  const onRadioValueChange = (e) => {
    setRadioValue(e.target.value);
    if (e.target.value === 1) {
      setFilterParams({...filterParams, legalEntityApproveStatuses: [1], contractApproveStatuses: [], offset: 0})
    }
    if (e.target.value === 2) {
      setFilterParams({...filterParams, legalEntityApproveStatuses: [], contractApproveStatuses: [1], offset: 0})
    }
    if (e.target.value === 3) {
      setFilterParams({...filterParams, legalEntityApproveStatuses: [], contractApproveStatuses: [], offset: 0})
    }
    setIsNeedUpdate(true);
  };

  const handleFilterButtonClick = () => {
    setFilterParams({...filterParams, offset: 0})
    setCurrentPage(1);
    setIsNeedUpdate(true);
  }

  const handleClearButtonClick = () => {
    setFilterParams({
      legalNumber: null,
      username: null,
      name: null,
      webid: null,
      legalEntityApproveStatuses: [],
      contractApproveStatuses: [],
      agreedContractByShipperCompanies: [],
      distributionCenterId: [],
      haveDelivDelay: false,
      minDelivDelay: null,
      maxDelivDelay: null,
      haveAccreditation: false,
      consignorID: null,
      limit: 20,
      offset: 0,
    });
    setRadioValue(3);
    setCurrentPage(1);
    setIsNeedUpdate(true);
  }

  return <div className="filters">
    <Row gutter={16} style={{marginBottom: "20px"}} type="flex" justify="start" align="middle">
      <Col span={24}>
        <Radio.Group name="filter" onChange={onRadioValueChange} value={radioValue} defaultValue={3} size="small" buttonStyle="outline" optionType="button" className="filters__radio-buttons">
          <Radio.Button value={3}>Все</Radio.Button>
          <Radio.Button value={1}>Ожидают согласования ({counts.approveOrdersCount})</Radio.Button>
          <Radio.Button value={2}>Договоры на согласовании ({counts.contractOrdersCount})</Radio.Button>
        </Radio.Group>
      </Col>
    </Row>
    <Row gutter={16} style={{marginBottom: "12px"}} type="flex" justify="start" align="middle">
      <Col xs={24} sm={24} md={12} lg={12} xl={12} >
        {shipperCompanies && shipperCompanies.length && <>
          <span className="form-field-label">Организации</span>
          <Select
            mode="multiple"
            size="default"
            defaultValue={filterParams.agreedContractByShipperCompanies}
            value={filterParams.agreedContractByShipperCompanies}
            style={{width: "100%", marginTop: "-10px"}}
            showArrow={true}
            optionFilterProp="children"
            placeholder="Выберите организацию"
            onChange={selectHandleChange}
          >
            {shipperCompanies.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </>
        }
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} >
          {distributionCentersList && distributionCentersList.length && <>
            <span className="form-field-label">РЦ</span>
            <Select
              mode="multiple"
              size="default"
              defaultValue={filterParams.distributionCenterId}
              value={filterParams.distributionCenterId}
              style={{width: "100%", marginTop: "-10px"}}
              showArrow={true}
              optionFilterProp="children"
              placeholder="Выберите РЦ"
              onChange={selectDCHandleChange}
            >
              {distributionCentersList.map((item) => {
                return (
                  <Option key={item.Id} value={item.Id}>
                    {item.Name}
                  </Option>
                );
              })}
            </Select>
          </>
          }
        </Col>
    </Row>
    <Row gutter={16} style={{marginBottom: "12px"}} type="flex" justify="start" align="middle">
      <Col xs={24} sm={12} md={12} lg={12} xl={12} >
        <span className="form-field-label">Статус договора</span>
        <Select
          name={"contractApproveStatuses"}
          mode="multiple"
          size="default"
          optionFilterProp="children"
          value={filterParams.contractApproveStatuses}
          style={{width: "100%", marginTop: "-10px"}}
          showArrow={true}
          placeholder="Выберите статус договора"
          onChange={docSelectHandleChange}
        >
          {DOC_STATUSES.map((item) => {
            return (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12} >
        <span className="form-field-label">Статус согласований</span>
        <Select
          name={"legalEntityApproveStatuses"}
          mode="multiple"
          size="default"
          optionFilterProp="children"
          value={filterParams.legalEntityApproveStatuses}
          style={{width: "100%", marginTop: "-10px"}}
          showArrow={true}
          placeholder="Выберите статус согласований"
          onChange={approveSelectHandleChange}
        >
          {STATUSES.map((item) => {
            return (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </Col>
    </Row>
    <Row gutter={16} style={{marginBottom: "12px"}} type="flex" justify="start" align="middle">
      <Col xs={24} sm={12} md={8} lg={8} xl={8} >
        <InputField
          name="username"
          label="Логин"
          value={filterParams.username}
          setValue={(value) => {
            setFilterParams({...filterParams, username: value})
          }}
          style={{margin: "-10px 0 0"}}
          getFieldErrors={() => {
          }}
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8} >
        <InputField
          name="legalNumber"
          label="ИНН"
          value={filterParams.legalNumber}
          setValue={(value) => {
            setFilterParams({...filterParams, legalNumber: value})
          }}
          style={{margin: "-10px 0 0"}}
          getFieldErrors={() => {
          }}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8} >
        <InputField
          name="name"
          label="Название"
          value={filterParams.name}
          setValue={(value) => {
            setFilterParams({...filterParams, name: value})
          }}
          style={{margin: "-10px 0 0"}}
          getFieldErrors={() => {
          }}
        />
      </Col>
    </Row>
    <Row gutter={16} style={{marginBottom: "12px"}} type="flex" justify="start" align="middle">
      <Col xs={24} sm={12} md={8} lg={8} xl={8} >
        <InputField
            name="webid"
            label="Web ID"
            value={filterParams.webid}
            setValue={(value) => {
              setFilterParams({...filterParams, webid: value})
            }}
            style={{margin: "-10px 0 0"}}
            getFieldErrors={() => {
            }}
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8} >
      <span className="form-field-label">Тип компании</span>
        <Select
          name={"companyTypes"}
          mode="multiple"
          size="default"
          optionFilterProp="children"
          value={filterParams.companyTypes}
          style={{width: "100%", marginTop: "-10px"}}
          showArrow={true}
          placeholder="Выберите тип компании"
          onChange={companyTypeSelectHandleChange}
        >
          {COMPANY_TYPES.map((item) => {
            return (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
        </Col>
    </Row>
    <Row gutter={16} style={{marginBottom: "12px"}} type="flex" justify="start" align="middle">
      <Col xs={24} sm={24} md={8} lg={8} xl={8} >
        <span className="form-field-label">Время задержки от - до</span>
        <InputGroup size="large" style={{marginTop: "-10px"}}>
          <Row gutter={8} style={{margin: "0 0 10px"}}>
            <Col span={12} style={{padding: "0 4px 0 0"}}>
              <InputField
                name="minDelivDelay"
                value={filterParams.minDelivDelay}
                setValue={(value) => {
                  setFilterParams({...filterParams, minDelivDelay: value})
                }}
                getFieldErrors={() => {
                }}
              />
            </Col>
            <Col span={12} style={{padding: "0 0 0 4px"}}>
              <InputField
                name="maxDelivDelay"
                value={filterParams.maxDelivDelay}
                setValue={(value) => {
                  setFilterParams({...filterParams, maxDelivDelay: value})
                }}
                getFieldErrors={() => {
                }}
              />
            </Col>
          </Row>
        </InputGroup>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8} style={{margin: "24px 0 auto"}}>
        <Checkbox
          name="haveAccreditation"
          checked={filterParams.haveAccreditation}
          onChange={(e) => {
            setFilterParams({...filterParams, haveAccreditation: e.target.checked});
          }}
          style={{display: "flex"}}
        >
          Только с аккредитацией
        </Checkbox>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8} style={{margin: "24px 0 auto"}}>
        <Checkbox
          name="haveDelivDelay"
          checked={filterParams.haveDelivDelay}
          onChange={(e) => {
            setFilterParams({...filterParams, haveDelivDelay: e.target.checked});
          }}
          style={{display: "flex"}}
        >
          Только с задержкой показа рейсов
        </Checkbox>
      </Col>
    </Row>
    <Row gutter={8} type="flex" justify="start" align="middle">
      <Col xs={24} sm={9} md={6} lg={6} xl={6}>
        <Button type="primary"
                htmlType="button"
                onClick={handleFilterButtonClick}
                style={{backgroundColor: '#1CAA53', border: 'none', height: '40px', width: '100%', marginBottom: '10px'}}
        >
          Применить
        </Button>
      </Col>
      <Col xs={24} sm={3} md={3} lg={3} xl={3}>
        <Button type="primary"
                htmlType="button"
                icon="close"
                onClick={handleClearButtonClick}
                style={{backgroundColor: '#BE1C1C', border: 'none', height: '40px', width: '100%', marginBottom: '10px'}}
        />
      </Col>
    </Row>
  </div>
}

export default FiltersForConsignor;
