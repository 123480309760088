import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import {userServices} from "../../../../../services";
import {useFetchDeliveriesList} from "../../../../../hooks/use-fetch-deliveries-list";
import Error from "../../../../error";
import Spinner from "../../../../spinner";
import DeliveryItem from "../../../../delivery-item-block";
import CarrierContentItem from "../../../../delivery-item-content/carrier/content-item";
import DeliveryEmptyContent from "../../../../delivery-empty-content";
import {AppContext} from "../../../../../contexts/app-context";
import {VIEW_AGREED_DELIVERIES_CARRIER} from "../../../../../helpers/user_permission.helper";
import UserNotificationMessage from "../../../../user-message";
import NotificationMessage from "../../../../modals/notification-message";
import Price from "../../../../price";
import {Pagination} from "antd";
import {deliveriesConfig} from "../../../../../config";

function HeaderItem({delivery, nameRegionLastPointUnload, regionLoad}) {
  const dateShipmentRus = moment(delivery.DateShipment).format("D MMMM YYYY");

  const deliveryOrderPrice = delivery.Order.Price.toLocaleString("ru");

  return (
    <div className="application-topline">
      <div className="application-topline-carrier">
        <div className="application-topline-carrier-item">
          <div className="application-topline-carrier-item-col application-topline-carrier-item-col-date">
            <div className="typical-icon-field icon-field-calendar">{dateShipmentRus}</div>
          </div>
          <div className="application-topline-carrier-item-col application-topline-carrier-item-col-city">
            <p className="typical-icon-field icon-field-patch" title={regionLoad}>
              {regionLoad}
            </p>
            <p className="typical-icon-field icon-field-dispatch" title={nameRegionLastPointUnload}>
              {nameRegionLastPointUnload}
            </p>
          </div>
        </div>
        <div className="application-topline-carrier-item application-topline-carrier-item-with-price">
          <div className="application-topline-carrier-item-col application-topline-carrier-item-price">
            <Price delivery={delivery}/>
          </div>
          <div className="application-topline-carrier-item-col">
            <p className="typical-icon-field grey-txt">
              Моя цена: <span>{deliveryOrderPrice} </span>₽
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function CarrierArchivedDeliveries() {
  const user = userServices.getUser();
  const [step, setStep] = useState(deliveriesConfig.pageCount);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const appContext = useContext(AppContext);

  useEffect(() => {
    appContext.initFilter();
  }, []);

  const [{
    error,
    loading,
    data: deliveries,
    setStopListUpdate,
    setNeedUpdate,
    notifications,
    permissions,
    totalCount
  }] = useFetchDeliveriesList(
    "delivery/get-agreed",
    true,
    false,
    true,
    true,
    deliveriesConfig.secondForUpdate,
    offset,
    step
  );

  let onChange = (page, pageSize) => {
    if (page === currentPage) {
      return false;
    } else {
      setCurrentPage(page);
    }

    let val = 0;
    if (parseInt(page) === 1) {
      setOffset(0);
    } else {
      val = page * pageSize - pageSize;
      setOffset(val);
    }
    appContext.filter.offset = val;
    setNeedUpdate(true);
  };

  if (!user.isAccessTo(VIEW_AGREED_DELIVERIES_CARRIER)) {
    return <Error status={403} desc="Страница не доступна"/>;
  }

  if (error) {
    return <Error type={500} desc={error.message}/>;
  }


  let listItems = [];

  if (!loading) {
    listItems = deliveries.map((delivery) => {
      return <DeliveryItem key={delivery.Id} header={HeaderItem} content={CarrierContentItem} delivery={delivery}
                           permissions={permissions}/>;
    });
  }

  return (
    <Spinner loading={loading}>
      <UserNotificationMessage/>
      <NotificationMessage notifications={notifications} setNeedUpdate={setNeedUpdate}
                           setStopListUpdate={setStopListUpdate} loading={loading}/>
      {totalCount !== 0 ?
        <div className="application-block pagination pagination__up">
          <Pagination
            style={{textAlign: "center"}}
            current={currentPage}
            pageSize={step}
            total={totalCount}
            showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
            onChange={onChange}
          />
        </div>
        : null}
      {deliveries ? deliveries.length ? listItems : <DeliveryEmptyContent/> : null}
      {totalCount !== 0 ?
        <div className="application-block pagination pagination__down">
          <Pagination
            style={{textAlign: "center"}}
            current={currentPage}
            pageSize={step}
            total={totalCount}
            showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
            onChange={onChange}
          />
        </div>
        : null}
    </Spinner>
  );
}
