import {httpRequest} from "./http-request";

export const permissionService = {
    getPermissions
};


/**
 * Получить список разрешений
 */
async function getPermissions() {

    return await httpRequest.get('permission/list')
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}