import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { userServices } from "../../../../../services";
import { useFetchDeliveriesList } from "../../../../../hooks/use-fetch-deliveries-list";
import Error from "../../../../error";
import { notification, Pagination } from "antd";
import Spinner from "../../../../spinner";
import DeliveryItemBlock from "../../../../delivery-item-block";
import LogistBookedContentItem from "../../../../delivery-item-content/logist/booked-content-item";
import DeliveryEmptyContent from "../../../../delivery-empty-content";
import { AppContext } from "../../../../../contexts/app-context";
import { VIEW_AGREED_DELIVERIES_LOGIST } from "../../../../../helpers/user_permission.helper";
import UserNotificationMessage from "../../../../user-message";
import NotificationMessage from "../../../../modals/notification-message";
import DeliveryGroupList from "../../../../delivery-group-list";
import DeterminedPrice from "../../../../determined-price";
import { deliveriesConfig } from "../../../../../config";

function HeaderItem({ delivery, nameRegionLastPointUnload, regionLoad }) {
	const dateShipmentRus = moment(delivery.DateShipment).format("D MMMM YYYY");

	return (
		<div className="application-topline">
			<div className="application-topline-logist">
				<div className="application-topline-logist-item">
					<div className="application-topline-carrier-item-col application-topline-carrier-item-col-city">
						<p className="typical-icon-field icon-field-patch" title={regionLoad}>
							{regionLoad}
						</p>
						<p className="typical-icon-field icon-field-dispatch" title={nameRegionLastPointUnload}>
							{nameRegionLastPointUnload}
						</p>
					</div>
				</div>
				<div className="application-topline-logist-item">
					<div className="application-topline-logist-item application-topline-logist-item-determined">
						<DeterminedPrice delivery={delivery} />
						<div className="application-topline-logist-item-col application-topline-logist-item-col-date">
							<div className="typical-icon-field icon-field-calendar">{dateShipmentRus}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default function LogistArchivedDeliveries() {
	const user = userServices.getUser();

	const appContext = useContext(AppContext);
	const [offset, setOffset] = useState(0);
	const [step, setStep] = useState(deliveriesConfig.pageCount);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		appContext.initFilter();
	}, []);

	let onChange = (page, pageSize) => {
		if (page === currentPage) {
			return false;
		} else {
			setCurrentPage(page);
		}

		let val = 0;
		if (parseInt(page) === 1) {
			setOffset(0);
		} else {
			val = page * pageSize - pageSize;
			setOffset(val);
		}
		appContext.filter.offset = val;
		setNeedUpdate(true);
	};

	const [{ error, loading, data: deliveries, setStopListUpdate, setNeedUpdate, notifications, permissions, totalCount }] = useFetchDeliveriesList("delivery/get-agreed",
		true,
		false,
		true,
		true,
		deliveriesConfig.secondForUpdate,
		offset,
		step
	);

  if (!user.isAccessTo(VIEW_AGREED_DELIVERIES_LOGIST)) {
      return <Error status={403} desc="Страница не доступна" />;
  }

	if (error) {
		return <Error type={500} desc={error.message} />;
	}

	let listItems = [];

	if (!loading) {
		listItems = deliveries.map((delivery) => {
			return <DeliveryItemBlock
				key={delivery.Id}
				header={HeaderItem}
				content={LogistBookedContentItem}
				delivery={delivery}
				permissions={permissions}
				isBooked
			/>;
		});
	}

	return (
		<Spinner loading={loading}>
			<UserNotificationMessage />
			<NotificationMessage notifications={notifications} setNeedUpdate={false} setStopListUpdate={setStopListUpdate} loading={loading} />
			{totalCount != 0 ?
				<div className="application-block pagination pagination__up">
					<Pagination
						style={{ textAlign: "center" }}
						current={currentPage}
						pageSize={step}
						total={totalCount}
						showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
						onChange={onChange}
					/>
				</div>
				: null}
			{!loading ? deliveries.length ? <DeliveryGroupList deliveriesItems={listItems} /> : <DeliveryEmptyContent /> : null}
			{totalCount != 0 ?
				<div className="application-block pagination pagination__down">
					<Pagination
						style={{ textAlign: "center" }}
						current={currentPage}
						pageSize={step}
						total={totalCount}
						showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
						onChange={onChange}
					/>
				</div>
				: null}
		</Spinner>
	);
}
