import {httpRequest} from "./http-request";

export const distributionCenterService = {
  getCompanies,
  edit,
  getOrderTimeSums,
  deleteOrderTimeSum,
  addOrderTimeSum,
  create,
  getCompaniesOnApproveOrderCreate,
  getGates,
  addGate,
  editGate,
  getLoadingSchedule,
};


/**
 * Получить список компаний принадлежащих пользователю
 */
async function getCompanies(fullList = false, columnSort = false, consignorId = null, shipperCompanyId = null) {

  return await httpRequest.get('distribution-center/list?FullList='+ fullList +'&ColumnSort='+ columnSort +'&ConsignorId='+ consignorId +'&ShipperCompanyId='+ shipperCompanyId)
    .then((response) => {

      if (response.status === 200 && typeof response.data !== "undefined") {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Получить список компаний для создания заявки на подтверждение юр.лица
 */
async function getCompaniesOnApproveOrderCreate(legalEntityId, consignorId) {

  return await httpRequest.get('distribution-center/list-for-approve-order-create?LegalEntityId=' + legalEntityId + '&ConsignorId=' + consignorId)
      .then((response) => {

        if (response.status === 200 && typeof response.data !== "undefined") {
          return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
      });
}

/**
 * Изменить информацию о РЦ
 */
async function edit(
  id,
  delayDelivDispatcher,
  delayReserveDeliv,
  maxCountLoadAuto,
  startFillingOrder,
  fillingOrder,
  downloadDocument,
  closingTime,
  minOrdersToApprove,
  minHoursToRefuse,
  cargoCollectTime,
  importantDeliveryMaxTimeLoad,
  countSkipAvailableTimesNonImportantDelivery,
  maxInnerDistance,
) {

  return await httpRequest.patch(`distribution-center/${id}/edit`, {
    delayDelivDispatcher: delayDelivDispatcher,
    delayReserveDeliv: delayReserveDeliv,
    maxCountLoadAuto: maxCountLoadAuto,
    startFillingOrder: startFillingOrder,
    fillingOrder: fillingOrder,
    downloadDocument: downloadDocument,
    closingTime: closingTime,
    minOrdersToApprove: minOrdersToApprove,
    minHoursToRefuse: minHoursToRefuse,
    cargoCollectTime: cargoCollectTime,
    importantDeliveryMaxTimeLoad: importantDeliveryMaxTimeLoad,
    countSkipAvailableTimesNonImportantDelivery: countSkipAvailableTimesNonImportantDelivery,
      maxInnerDistance: maxInnerDistance,
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Список настроек соотношения надбавочной суммы и кол-ва прошедших часов
 * @param distCenterId
 * @returns {Promise<*>}
 */
async function getOrderTimeSums(distCenterId) {

  return await httpRequest.post('distribution-center/get-order-sum-time-list', {
    DistributionCenter: distCenterId
  })
    .then((response) => {

      if (response.status === 200 && typeof response.data !== "undefined") {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Удаляет запись по id
 * @param id
 * @returns {Promise<*>}
 */
async function deleteOrderTimeSum(id) {

  return await httpRequest.post('distribution-center/delete-order-sum-time', {
    id: id
  })
    .then((response) => {

      if (response.status === 200 && typeof response.data !== "undefined") {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Добавляет новую запись
 * @param distCenterId
 * @param time
 * @param sum
 * @returns {Promise<*>}
 */
async function addOrderTimeSum(distCenterId, time, sum) {

  return await httpRequest.post('distribution-center/add-order-sum-time', {
    DistributionCenterId: distCenterId,
    Time: time,
    Sum: sum,
  })
    .then((response) => {

      if (response.status === 200 && typeof response.data !== "undefined") {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}


async function create(
  name,
  shipperCompanyId,
  delayDelivDispatcher,
  delayReserveDeliv,
  maxCountLoadAuto,
  startFillingOrder,
  fillingOrder,
  downloadDocument,
  closingTime,
  minOrdersToApprove,
  minHoursToRefuse
) {

  return await httpRequest.patch(`distribution-center/create`, {
    name: name,
    shipperCompanyId: shipperCompanyId,
    delayDelivDispatcher: delayDelivDispatcher,
    delayReserveDeliv: delayReserveDeliv,
    maxCountLoadAuto: maxCountLoadAuto,
    startFillingOrder: startFillingOrder,
    fillingOrder: fillingOrder,
    downloadDocument: downloadDocument,
    closingTime: closingTime,
    minOrdersToApprove: minOrdersToApprove,
    minHoursToRefuse: minHoursToRefuse
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Получить список ворот РЦ
 */
async function getGates(id) {
  return await httpRequest.get('distribution-center/get-gates?id='+ id)
    .then((response) => {

      if (response.status === 200 && typeof response.data !== "undefined") {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}


/**
 * Добавляет новые ворота РЦ
 * @param id
 * @param isActive
 * @param name
 * @param serviceTime
 * @param workingDays
 * @returns {Promise<*>}
 */
async function addGate(id, isActive, name, serviceTime, workingDays = []) {
  return await httpRequest.post('distribution-center/create-gate?id=' + id, {
    name: name,
    isActive: isActive,
    serviceTime: serviceTime,
    workingDays: workingDays
  })
    .then((response) => {

      if (response.status === 200 && typeof response.data !== "undefined") {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function editGate(id, isActive, name, serviceTime, workingDays = []) {
  return await httpRequest.post('distribution-center/edit-gate?gateID=' + id, {
    name: name,
    isActive: isActive,
    serviceTime: serviceTime,
    workingDays: workingDays
  })
    .then((response) => {

      if (response.status === 200 && typeof response.data !== "undefined") {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}


async function getLoadingSchedule(distCenterId) {
  return await httpRequest.get('distribution-center/get-loading-schedule?id='+ distCenterId)
    .then((response) => {

      if (response.status === 200 && typeof response.data !== "undefined") {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}