import React, { useState, useEffect } from "react";
import {Button, Modal, Row, Spin, Form, Steps, notification } from "antd";
import {companyService} from "../../../../services";
import MainDataFields from "./steps/main-data-fields";
import DocsFields from "./steps/docs-fields";
import ModalListShipCompanies from "../../list-ship-companies";
import GuarantorFields from "./steps/guarantor-fields";

export default function CreateLithuaniaLegalEntity({
    isActive = false,

    currentStep,
    setCurrentStep,

    orgFormId,
    countryId,

    setLoading,

    setShowModal,

    setStepsList,

    changeStepEvent,
    setChangeStepEvent,

    createLegalEntityEvent,
    setCreateLegalEntityEvent,
}) {
    const [organisationFormsList, setOrganisationFormsList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [fieldsErrors, setFieldsErrors] = useState({});

    const [showModalListShipCompanies, setShowModalListShipCompanies] = useState(false);
    const [legalEntityId, setLegalEntityId] = useState(null);

    const [mainDataFields, setMainDataFields] = useState({
        orgFormId: orgFormId,
        country: countryId,

        name: '',
        fullName: '',
        firstName: '',
        secondName: '',
        patronymic: '',
        email: '',
        phone: '',
        bankName: '',
        accountNumber: '',
        swift: '',
        position: '',
        registerNumber: '',
        legalNumber: '',
        legalReason: '',
        address: '',
        mailingAddress: ''
    });

    const [guarantorFields, setGuarantorFields] = useState({
        guarantorFirstName: '',
        guarantorSecondName: '',
        guarantorPatronymic: '',
        guarantorPassportSerial: '',
        guarantorPassportNumber: '',
        guarantorAddressFact: '',
        guarantorCountry: '',
        guarantorPhone: '',
        guarantorEmail: '',
    });

    const [docsFields, setDocsFields] = useState({
        // Общие
        permissionForTheRightToCarryOutActivitiesInTheFieldOfInternationalRoadTransport: [],
        copyOfThePassport: [], // Разные названия поля у собственника и остальных
        extractFromTheTradeRegister: [],
        certificateOfExclusionOfDoubleTaxation: [],

        // Собственник
        documentConfirmingTheStateRegistrationOfAnIndividualEntrepreneur: [],

        // Не собственник
        documentConfirmingTheStateRegistrationOfALegalEntity: [],
        proofOfOwnership: [],
    });

    const getFieldErrors = (fieldName) => {
        return typeof fieldsErrors[fieldName] !== "undefined"
            ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
                return fieldErrors + "; " + error;
            })
            : null;
    };

    useEffect(() => {
        setDocsFields({
            // Общие
            permissionForTheRightToCarryOutActivitiesInTheFieldOfInternationalRoadTransport: [],
            copyOfThePassport: [], // Разные названия поля у собственника и остальных
            extractFromTheTradeRegister: [],
            certificateOfExclusionOfDoubleTaxation: [],

            // Собственник
            documentConfirmingTheStateRegistrationOfAnIndividualEntrepreneur: [],

            // Не собственник
            documentConfirmingTheStateRegistrationOfALegalEntity: [],
            proofOfOwnership: [],
        });
    }, [currentStep]);

    useEffect(() => {
        if (isActive) {
            if (orgFormId === 13) {
                setStepsList([
                    'Pagrindiniai duomenys',
                    'Dokumentacija'
                ]);
            } else {
                setStepsList([
                    'Pagrindiniai duomenys',
                    'Garantas',
                    'Dokumentacija'
                ]);
            }
        }
    }, [isActive]);

    useEffect(() => {
        if (changeStepEvent) {
            if (changeStepEvent === 'next') {
                nextStep();
            } else if (changeStepEvent === 'previous') {
                previousStep();
            }

            setChangeStepEvent('');
        }

        setDocsFields({
            // Общие
            permissionForTheRightToCarryOutActivitiesInTheFieldOfInternationalRoadTransport: [],
            copyOfThePassport: [], // Разные названия поля у собственника и остальных
            extractFromTheTradeRegister: [],
            certificateOfExclusionOfDoubleTaxation: [],

            // Собственник
            documentConfirmingTheStateRegistrationOfAnIndividualEntrepreneur: [],

            // Не собственник
            documentConfirmingTheStateRegistrationOfALegalEntity: [],
            proofOfOwnership: [],
        });
    }, [changeStepEvent]);

    useEffect(() => {
        if (createLegalEntityEvent) {
            setLoading(true);

            let data = Object.assign(mainDataFields, guarantorFields);
            companyService.createLithuaniaLegalEntity(data, docsFields)
                .then(response => {
                    if (response.code === 400) {
                        notification.error({
                            message: "Создание юридического лица",
                            description: response.message,
                        });
                        setFieldsErrors(response.errors);
                    } else {
                        notification.success({
                            message: "Создание юридического лица",
                            description: response.message,
                        });

                        setShowModalListShipCompanies(true);
                        setLegalEntityId(response.data);

                        // setIsNeedUpdate(true);

                    }

                    setCreateLegalEntityEvent(false);
                    setLoading(false);
                })
                .catch(errMsg => {
                    notification.error({
                        message: "Создание юридического лица",
                        description: errMsg,
                    });

                    setLoading(false);
                    setCreateLegalEntityEvent(false);
                });
        }
    }, [createLegalEntityEvent]);

    const nextStep = () => {
        let stepData = (currentStep) => {
            let stepFields = {
                1: mainDataFields,
                2: guarantorFields,
                3: docsFields,
            };

            // Баг. Без этого виесто stepFields undefiend
            console.log(stepFields, currentStep, orgFormId);

            return stepFields[currentStep];
        };

        setLoading(true);

        companyService
            .createForeignLegalEntityValidateStepData(stepData(currentStep), currentStep, orgFormId, countryId)
            .then(response => {
                setLoading(false);

                if (response.status === 'success') {
                    if (currentStep < 3) {
                        if ((currentStep + 1) === 2 && orgFormId === 13) {
                            setCurrentStep(currentStep + 2);
                        } else {
                            setCurrentStep(currentStep + 1);
                        }
                        setFieldsErrors([]);
                    }
                } else {
                    setFieldsErrors(response.errors);
                }
            })
            .catch(error => {
                setLoading(false);
                notification.error({
                    message: "Создание юр.лица",
                    description: error,
                });
            });
    };

    const previousStep = () => {
        if (currentStep > 0) {
            if (currentStep === 3 && orgFormId === 13) {
                setCurrentStep(currentStep - 2);
            } else {
                setCurrentStep(currentStep - 1);
            }
        } else {
            setCurrentStep(0);
        }
    };


    const renderCurrentStep = () => {
        switch (currentStep) {
        case 1:
            return (
                <MainDataFields
                  setFields={setMainDataFields}
                  stepFields={mainDataFields}
                  getFieldErrors={getFieldErrors}
                  organisationFormsList={organisationFormsList}
                  countryList={countryList}
                />
            );
        case 2:
            return (
                <GuarantorFields
                    setFields={setGuarantorFields}
                    stepFields={guarantorFields}
                    getFieldErrors={getFieldErrors}
                />
            );
            case 3:
                return (
                    <DocsFields
                        setFields={setDocsFields}
                        stepFields={docsFields}
                        getFieldErrors={getFieldErrors}
                        organizationalForm={mainDataFields.orgFormId}
                    />
                );
        default:
            return <div />;
        }

    };

    return (
        <div>
            {renderCurrentStep()}
            <ModalListShipCompanies isShow={showModalListShipCompanies} setIsShow={setShowModalListShipCompanies} legalEntityId={legalEntityId} setIsShowParent={setShowModal} />
        </div>
    );
}
