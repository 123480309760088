import React, { useState, useEffect } from "react";
import {Button, Modal, Row, Spin, Form, Steps, notification, Icon, Col } from "antd";
import SelectField from "../../select-field";
import {companyService, systemService} from "../../../services";
import RadioField from "../../radio-field";
import CreateLithuaniaLegalEntity from "./lithuania-legal-entity";
import CreateRusLegalEntity from "./rus-legal-entity";
import CreateBelarusLegalEntity from "./belarus-legal-entity";
import CreateKazakhstanLegalEntity from "./kazakhstan-legal-entity";

export default function ModalCreateNewLegalEntity({ isShow, setIsShow, onSuccess, setLoadingList, setStopListUpdate }) {
    const [loading, setLoading] = useState(false);

    const [stepsList, setStepsList] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);

    const [changeStepEvent, setChangeStepEvent] = useState('');
    const [createLegalEntityEvent, setCreateLegalEntityEvent] = useState(false);

    const [countryId, setCountryId] = useState(null);
    const [countryList, setCountryList] = useState([
        {
            id: 1,
            Name: 'Россия'
        },
        {
            Id: 3,
            Name: 'Беларусь'
        },
        {
            Id: 6,
            Name: 'Казахстан'
        }
    ]);

    const [purposeType, setPurposeType] = useState('default_purpose_type');
    const [purposeTypesList] = useState([
        {id: 'default_purpose_type', name: 'Перевозчик'},
        {id: 'consignor_purpose_type', name: 'Отправитель'},
    ]);

    const [orgFormId, setOrgFormId] = useState(null);
    const [isProprietor, setIsProprietor] = useState(true);
    const [orgFormList, setOrgFormList] = useState([]);

    const [workWithNds, setWorkWithNds] = useState(null);
    const [ndsList] = useState([
        {
            Id: 1,
            Name: 'Да'
        },
        {
            Id: 2,
            Name: 'Нет'
        }
    ]);

    const [fieldsErrors, setFieldsErrors] = useState({});

    let submitBtnStyle = {
        width: "200px",
        height: "43px",
        background: "#1CAA53",
        position: "static",
        borderRadius: "10px",
        color: "white",
        borderColor: "transparent",
    };

    // TODO убрал, пока доступны не все страны при создании юр.лиц
    // useEffect(() => {
    //   if (isShow) {
    //     setLoading(true);
    //
    //     systemService
    //         .getCountryList()
    //         .then(response => {
    //           setCountryList(response);
    //           setLoading(false);
    //         })
    //         .catch(errorMsg => {
    //           notification.error({
    //             message: "Получение списка стран",
    //             description: errorMsg,
    //           });
    //         });
    //   }
    // }, [isShow]);

    useEffect(() => {
        if (countryId) {
            setLoading(true);

            companyService
                .getOrganisationFormsList(countryId)
                .then((response) => {
                    setOrgFormList(response);
                    setLoading(false);
                })
                .catch((errorMsg) => {
                    notification.error({
                        message: "Получение списка организационных форм",
                        description: errorMsg,
                    });
                });
        }
    }, [countryId]);

    useEffect(() => {
        if (orgFormId) {
            let orgForm = orgFormList.find((value => {
                return value.Id === orgFormId;
            }));
            setIsProprietor(orgForm.IsProprietor);
        }
    }, [orgFormId]);

    const getFieldErrors = (fieldName) => {
        return typeof fieldsErrors[fieldName] !== "undefined"
            ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
                return fieldErrors + "; " + error;
            })
            : null;
    };

    const renderStepsList = () => {
        if (currentStep === 0) {
            return ( <Steps.Step title={"Выберите страну"} />);
        }

        return stepsList.map((value, index) => {
            return (
                <Steps.Step title={value} key={`step${index}`}/>
            );
        });
    };

    const renderCreateFrom = () => {
        switch (countryId) {
            case 1:
                return (
                    <CreateRusLegalEntity
                        isActive={true}
                        orgFormId={orgFormId}
                        isProprietor={isProprietor}
                        workWithNds={workWithNds}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        setStepsList={setStepsList}
                        changeStepEvent={changeStepEvent}
                        setChangeStepEvent={setChangeStepEvent}
                        createLegalEntityEvent={createLegalEntityEvent}
                        setCreateLegalEntityEvent={setCreateLegalEntityEvent}
                        setLoading={setLoading}
                        setShowModal={setIsShow}
                        countryId={countryId}
                        purposeType={purposeType}
                    />
                );
            case 7:
                return (
                    <CreateLithuaniaLegalEntity
                        isActive={true}
                        orgFormId={orgFormId}
                        workWithNds={workWithNds}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        setStepsList={setStepsList}
                        changeStepEvent={changeStepEvent}
                        setChangeStepEvent={setChangeStepEvent}
                        createLegalEntityEvent={createLegalEntityEvent}
                        setCreateLegalEntityEvent={setCreateLegalEntityEvent}
                        setLoading={setLoading}
                        countryId={countryId}
                        setIsShowParent={setIsShow}
                    />
                );
            case 3:
                return (
                    <CreateBelarusLegalEntity
                        isActive={true}
                        orgFormId={orgFormId}
                        workWithNds={workWithNds}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        setStepsList={setStepsList}
                        changeStepEvent={changeStepEvent}
                        setChangeStepEvent={setChangeStepEvent}
                        createLegalEntityEvent={createLegalEntityEvent}
                        setCreateLegalEntityEvent={setCreateLegalEntityEvent}
                        setLoading={setLoading}
                        countryId={countryId}
                        setIsShowParent={setIsShow}
                    />
                );
            case 6:
                return (
                    <CreateKazakhstanLegalEntity
                        isActive={true}
                        orgFormID={orgFormId}
                        workWithNds={workWithNds}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        setStepsList={setStepsList}
                        changeStepEvent={changeStepEvent}
                        setChangeStepEvent={setChangeStepEvent}
                        createLegalEntityEvent={createLegalEntityEvent}
                        setCreateLegalEntityEvent={setCreateLegalEntityEvent}
                        setLoading={setLoading}
                        setIsShowParent={setIsShow}
                    />
                );
            default:
                return null;
        }
    };

    const createLegalEntity = () => {
        setCreateLegalEntityEvent(true);
    };

    const changeStep = (type) => {
        if (currentStep === 0) {
            setCurrentStep(1);
        } else {
            setChangeStepEvent(type);
        }
    };

    const isNextStepButtonDisabled = () => {
        if (currentStep === 0) {
            if (countryId) {
                if (countryId === 1 || countryId === 3) {
                    return !(orgFormId && workWithNds);
                } else {
                    return !(orgFormId);
                }
            }
        }
    };

    return (
        <Modal
            title={`Создание юр. лица`}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
                setLoadingList(true);
                setStopListUpdate(false);
            }}
            footer={[
                <div style={{ marginTop: "10" }}>
                    {!loading && (
                        <div style={{ marginTop: "10" }}>
                            {currentStep !== 0 && (
                                <Button
                                    htmlType="submit"
                                    key="submitPrev"
                                    style={submitBtnStyle}
                                    onClick={() => changeStep("previous")}
                                    className="button-footer-previous"
                                    type="danger"
                                >
                                    <Icon
                                        style={{
                                            position: "absolute",
                                            paddingTop: "3px",
                                            display: "flex",
                                        }}
                                        type="left"
                                    />{" "}
                                    Назад
                                </Button>
                            )}
                            {currentStep === 0 || currentStep < stepsList.length ? (
                                <Button
                                    htmlType="submit"
                                    key="submitNext"
                                    style={submitBtnStyle}
                                    onClick={() => changeStep("next")}
                                    disabled={isNextStepButtonDisabled()}
                                    className="button-footer-next"
                                    type="primary"
                                >
                                    Далее{" "}
                                    <Icon
                                        style={{
                                            position: "absolute",
                                            paddingLeft: "39px",
                                            paddingTop: "3px",
                                        }}
                                        type="right"
                                    />
                                </Button>
                            ) : (
                                <Button
                                    htmlType="submit"
                                    key="submit"
                                    style={submitBtnStyle}
                                    onClick={() => createLegalEntity()}
                                    type="primary"
                                    className="button-footer-create"
                                >
                                    Создать
                                </Button>
                            )}
                        </div>
                    )}
                </div>,
            ]}
        >
            <Spin spinning={loading} tip="Загрузка...">
                <Form>
                    <Row>
                        <Steps
                            type="navigation"
                            size="small"
                            current={currentStep - 1}
                            className="site-navigation-steps"
                            style={{ marginBottom: "14px" }}
                        >
                            {renderStepsList()}
                        </Steps>
                        <br />
                        {currentStep === 0 ? (
                            <React.Fragment>
                                <Row>
                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        <SelectField
                                            title="Страна"
                                            name="country"
                                            selectedValue={countryId}
                                            values={countryList}
                                            getErrors={getFieldErrors}
                                            onChange={(value) => {
                                                setCountryId(value);

                                                setOrgFormId(null);
                                                setWorkWithNds(null);
                                            }}
                                            placeholder="Выберите страну"
                                            notFoundText="Отсутствуют стран"
                                            style={{ width: "100%" }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={24} xs={24}>
                                        <RadioField
                                            title="Организационная форма"
                                            values={orgFormList}
                                            onChange={(value) => {
                                                setOrgFormId(value.target.value);
                                            }}
                                            selectedValue={orgFormId}
                                            getErrors={getFieldErrors}
                                        />
                                    </Col>
                                    {(countryId === 1 || countryId === 3) && (
                                        <>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <RadioField
                                                    title="НДС"
                                                    values={ndsList}
                                                    onChange={(value) => setWorkWithNds(value.target.value)}
                                                    selectedValue={workWithNds}
                                                    getErrors={getFieldErrors}
                                                />
                                            </Col>
                                        </>
                                    )}
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={24} xs={24}>
                                        <RadioField
                                            title="Вид деятельности"
                                            values={purposeTypesList}
                                            onChange={(value) => setPurposeType(value.target.value)}
                                            selectedValue={purposeType}
                                            getErrors={getFieldErrors}
                                        />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        ) : (
                            renderCreateFrom()
                        )}
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}
