import React, {useState} from "react";
import ModalRefuseDelivery from "../modals/refuse-delivery";


export default function RefuseDeliveryButton({deliveryId, isCanRefuse, setLoadingDelivItem, setNeedUpdate, setStopListUpdate}) {
    const [showModal, setShowModal] = useState(false);

    if (!isCanRefuse)
        return null;

    const onCLickShowModal = () => {
        setStopListUpdate(true);
        setShowModal(true);
    };

    return (
        <>
            <button className="btn typical-btn typical-btn-red" onClick={onCLickShowModal}>
                Отменить рейс
            </button>
            <ModalRefuseDelivery
                isShow={showModal}
                setIsShow={setShowModal}
                deliveryId={deliveryId}
                setLoadingDelivItem={setLoadingDelivItem}
                setNeedUpdate={setNeedUpdate}
                setStopListUpdate={setStopListUpdate}
            />
        </>
    );
}