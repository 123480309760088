import {httpRequest} from "./http-request";

export const roleService = {
  getRoles,
  create,
  edit,
  getRolesForStaffUser,
  getRolesForFilters
};


async function getRoles() {

  return await httpRequest.get('role/list-staff-roles')
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getRolesForFilters() {
  return await httpRequest.get(`role/list-roles`)
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function create(name, title) {

  return await httpRequest.post('role/create-staff-role', {
    name: name,
    title: title
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function edit(oldName, newName, title, permissions) {

  return await httpRequest.patch(`role/${oldName}/edit`, {
    newName: newName,
    title: title,
    permissions: permissions,
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getRolesForStaffUser() {

  return await httpRequest.get(`role/get-staff-roles-list`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}
