import React, {useEffect, useState} from "react";
import moment from "moment";
import {distributionCenterService} from "../../../../services";
import {Button, Col, DatePicker, Icon, notification, Row, Table} from "antd";
import {reportService} from "../../../../services/report.services";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import SelectField from "../../../select-field";
import Spinner from "../../../spinner";

export default function ReportStatsTelegramSetups() {
  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [fieldsErrors, setFieldsErrors] = useState([]);
  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
      ? fieldsErrors[nameField][0]
      : null;
  };

  // параметры для построения отчета
  const [distributionCenterList, setDistributionCenterList] = useState([]);
  const [distributionCenterListLoaded, setDistributionCenterListLoaded] = useState(false);
  const [dateStart, setDateStart] = useState(moment().startOf('month'));
  const [dateEnd, setDateEnd] = useState(moment().endOf('month'));
  const [data, setData] = useState([]);
  const [distributionCenterId, setDistributionCenterId] = useState(0);

  const [totalSetupsCount, setTotalSetupsCount] = useState(0);
  const [totalDriversSetupsCount, setTotalDriversSetupsCount] = useState(0);
  const [totalSetupsCountByPeriod, setTotalSetupsCountByPeriod] = useState(0);
  const [totalDriversSetupsCountByPeriod, setTotalDriversSetupsCountByPeriod] = useState(0);

  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateReport(true);
    }
  }, []);

  useEffect(() => {
    if (!distributionCenterListLoaded) {
      distributionCenterService.getCompanies()
        .then(res => {
          res.unshift({id: 0, Name: "Все РЦ"});
          setDistributionCenterListLoaded(true);
          setDistributionCenterList(res);
        })
        .catch(errMsg => {
          setDistributionCenterListLoaded(true);
          notification.error(
            {
              message: "Получение списка распределительных центров",
              description: errMsg
            });
        });
    }
  }, []);

  useEffect(() => {
    if (isNeedUpdateReport) {
      reportService
        .getStatsTelegramSetups(dateStart.format('YYYY-MM-DD'), dateEnd.format('YYYY-MM-DD'), distributionCenterId)
        .then((response) => {
          setData(response.List);
          setTotalSetupsCountByPeriod(response.TotalInfo.TotalByPeriod);
          setTotalDriversSetupsCountByPeriod(response.TotalInfo.TotalDriversByPeriod);
          setTotalSetupsCount(response.TotalInfo.Total);
          setTotalDriversSetupsCount(response.TotalInfo.TotalDrivers);

          setIsNeedUpdateReport(false);
          setLoading(false);
        })
        .catch((errorMsg) => {
          setIsNeedUpdateReport(false);
          setLoading(false);
          setErrorMsg(errorMsg);
      });
    }
  }, [isNeedUpdateReport]);

  // список столбцов таблицы
  const columns = [
    {
      title: 'Имя пользователя',
      dataIndex: 'NameUser',
      key: 'NameUser',
      width: '20%',
    },
    {
      title: 'Дата установки',
      dataIndex: 'DateSetup',
      key: 'DateSetup',
      width: '15%',
    },
    {
      title: 'Общее кол-во установок',
      dataIndex: 'CountAllSetups',
      key: 'CountAllSetups',
      width: '15%',
    },
    {
      title: 'Водитель',
      dataIndex: 'IsDriver',
      key: 'IsDriver',
      width: '15%',
      render: isDriver => {
        return isDriver ? 'Да' : 'Нет';
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="reports__header">
        <h1 className="content-header">Отчет «Статистика по установкам телеграм»</h1>
        <Icon type='filter'
              theme={isFilterOpen ? '' : "filled"}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen)
              }}
              className="reports__filter-toggle"/>
        {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
          <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start">
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateStart}
                format="DD.MM.YYYY"
                size="normal"
                name="dateStart"
                placeholder="Выберите дату начала периода"
                locale={localeDatePicker}
                onChange={(date) => {
                  setDateStart(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateEnd}
                format="DD.MM.YYYY"
                size="normal"
                name="dateEnd"
                placeholder="Выберите дату окончания периода"
                locale={localeDatePicker}
                onChange={(date) => {
                  setDateEnd(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <SelectField
                placeholder="Подразделение"
                name="distributionCenterId"
                selectedValue={distributionCenterId}
                values={distributionCenterList}
                onChange={value => {
                  setDistributionCenterId(value);
                }}
                getErrors={getFieldErrors}
                style={{width: "100%"}}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{textAlign: 'right', height: '40px'}}>
              <Button type="primary"
                      htmlType="button"
                      onClick={() => {
                        setLoading(true);
                        setIsNeedUpdateReport(true);
                      }}
                      style={{backgroundColor: '#1CAA53', border: 'none', height: '40px'}}
              >
                Применить
              </Button>
            </Col>
          </Row>
        </div>}
        <Row gutter={12} style={{padding: "0", rowGap: "10px", marginTop: '10px'}} type="flex" justify="start">
          <Col lg={12} md={12} sm={24} xs={24} style={{textAlign: 'left', marginBottom: '5px'}}>Всего пользователей за период: {totalSetupsCountByPeriod}</Col>
          <Col lg={12} md={12} sm={24} xs={24} style={{textAlign: 'left', marginBottom: '5px'}}>Всего установлено: {totalSetupsCount}</Col>
          <Col lg={12} md={12} sm={24} xs={24} style={{textAlign: 'left', marginBottom: '5px'}}>Всего водителей за период: {totalDriversSetupsCountByPeriod}</Col>
          <Col lg={12} md={12} sm={24} xs={24} style={{textAlign: 'left', marginBottom: '5px'}}>Всего установлено у водителей: {totalDriversSetupsCount}</Col>
        </Row>
      </div>
      <div className="reports__table">
        <div className="reports__table-wrapper">
          <Col span={24}>
            <Table
              style={{minWidth: '700px'}}
              columns={columns}
              dataSource={data}
              locale={{emptyText: "Данные отсутствуют"}}
              rowKey="key"
              size="small"
              loading={loading}
              pagination={{
                pageSize: 50
              }}
            />
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
}
