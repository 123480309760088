import {httpRequest} from "./http-request";

export const notificationServices = {
    getNotifications,
    readNotification,
    switchNotification,
	  getNotificationList,
    getDistributionCenterNotificationSettings,
    saveDistCentersNotifications,
}

async function getNotifications() {
    return await httpRequest.get('notification/get-unread-notification')
    .then((response) => {

        if (response.status === 200) {
            return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
    });
}


async function readNotification(notificationId) {
    return await httpRequest.post(`notification/read-notification/${notificationId}`)
    .then((response) => {

        if (response.status === 200) {
            return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
    });
}


async function switchNotification(status, type, notifyType) {
    return await httpRequest.post(`notification/switch-notification`, {
        Status: status,
        Type: type,
        NotifyType: notifyType
    }).then((response) => {

        if (response.status === 200) {
          return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
      });
}

async function getNotificationList(offset, limit) {
    return await httpRequest.get(`notification/get-notifications-list`, {
    }).then((response) => {

        if (response.status === 200) {
          return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
      });
}

async function getDistributionCenterNotificationSettings() {
    return await httpRequest.get(`notification/get-distribution-center-notification-settings`, {
    }).then((response) => {

        if (response.status === 200) {
            return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function saveDistCentersNotifications(data) {
    return await httpRequest.post(`notification/save-distribution-center-notification-settings`, {
        settings: data,
    }).then((response) => {

        if (response.status === 200) {
            return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
    });
}



