import React from "react";

export default function MainFooter({mod}) {
  return <footer className={`footer ${mod? 'footer--dark' : ''}`}>
    <div className="container">
      <div className="footer__inner">
        <div className="footer__col">
          <div className="footer__title display5">
            Документация
          </div>
          <div className="footer__links">
            <a href="/privacy/docs/Договор перевозки грузов.docx" download className="footer__link">Договор на перевозку грузов автомобильным транспортом</a>
            <a href="/privacy/docs/Договор поручительства.doc" download className="footer__link">Договор поручительства</a>
            <a href="/privacy/docs/Пользовательское_соглашение.docx" download className="footer__link">Пользовательское соглашение</a>
            <a href="/privacy/docs/Политика_перс_данные.docx" download className="footer__link">Политика в отношении обработки персональных данных</a>
            <a href="/privacy/docs/Публичная_оферта_о_согласии_с_обработкой.docx" download className="footer__link">Публичная оферта о согласии с обработкой персональных данных</a>
          </div>
        </div>
        <div className="footer__apps">
          <div className="footer__app">
            <div className="footer__app-info">
              <img src="/img/app.svg" alt="" className="footer__app-img"/>
              <div className="footer__app-title text14 gray-text">
                Мобильное приложение
              </div>
            </div>
            <div className="footer__app-qr">
              <img src="/img/qr.webp" alt=""/>
            </div>
          </div>
          <div className="footer__app">
            <div className="footer__app-info">
              <img src="/img/app2.svg" alt="" className="footer__app-img"/>
              <div className="footer__app-title text14 gray-text">
                Чат-бот для поиска груза
              </div>
            </div>
            <div className="footer__app-qr">
              <img src="/img/qr2.webp" alt=""/>
            </div>
          </div>
        </div>
        <div className="footer__col">
          <div className="footer__title display5">
            Контакты
          </div>
          <div className="footer__groups">
            <div className="footer__group">
              <div className="footer__group-title">
                Техническая поддержка
              </div>
              <div className="footer__group-desc display5">
                <a className="green-text" href="mailto:support@svetofore.ru">
                  support@svetofore.ru
                </a>
              </div>
            </div>
            <div className="footer__group">
              <div className="footer__group-title">
                © Любое использование либо копирование материалов или подборки материалов сайта, элементов дизайна и
                оформления допускается только с разрешения правообладателя и только со ссылкой на источник:
                www.svetofore.com.
              </div>
            </div>
          </div>
        </div>
        <div className="footer__apps2">
          <a href="" className="footer__market m-btn m-btn-primary m-btn-triangle-right">
            <img src="/img/app3.png" alt=""/>
          </a>
          <a href="" className="footer__telegram m-btn m-btn-primary m-btn-triangle-right">
            <img src="/img/app4.svg" alt=""/>
            <span className="text13">
              Чат-бот для поиска груза
            </span>
          </a>
        </div>
      </div>
    </div>
  </footer>
}
