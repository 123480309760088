import {Placemark, Polyline} from "react-yandex-maps";
import React from "react";

export const mapHelper = {
  placemarkersFactory
};


function placemarkersFactory(drivers) {
  const polyline = React.createRef(null);

  let Line = <Polyline
    geometry={[
      [0, 0],
    ]}
    options={{
      balloonCloseButton: false,
      strokeColor: "#f00",
      strokeWidth: 5,
      strokeOpacity: 0.6,
      strokeStyle: 'longdash',
      visible: 1,
    }}
    instanceRef={polyline}
  />;


  return [Line].concat(drivers.map((item, index) => {
    if (item.driver.location) {
      const location = item.driver.location;
      const driverName = item.driver.name;

      const image = `/img/svg/32.svg`;

      const balloonInfo = `<div>          
          <p><span>Дата отправки: </span><b>${item.delivery.dateLoading}</b></p>
          <p><span>Водитель: </span><b>${item.driver.name}</b></p>
          <p><span>Телефон водителя: </span>
            <a class="link" href="tel:+7${item.driver.phone}"><b>${item.driver.phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, '+7 ($1) $2 $3 $4')}
            </b></a>
          </p>
          <p><span>Номер автомобиля: </span><b>${item.driver.truck ? item.driver.truck.number : '-'}</b></p>
          <p><span>Номер прицепа: </span><b>${item.driver.trail ? item.driver.trail.number : '-'}</b></p>
        </div>`;

      let Place = <Placemark
        key={index}
        defaultGeometry={[location.lat, location.lon]}
        properties={{
          delivery: item.delivery.number,
          balloonContentHeader: `<p><span>Номер рейса: </span>${item.delivery.number}</p>`,
          balloonContent: balloonInfo,
          balloonContentFooter: `<p style="text-align: right"><sub >обновлено ${item.driver.location.dateUpdated}</sub></p>`
        }}
        onClick={() => {
          let coords = item.delivery.locationHistory;
          polyline.current.geometry.setCoordinates(coords);
        }}
        options={{
          iconLayout: "default#image",
          iconImageSize: [50, 50],
          iconImageHref: image,
          balloonOffset: [-10, -30],
          hintContent: `Рейс #${item.delivery.number}`,
          hideIconOnBalloonOpen: false
        }}
      />

      return (
        Place
      );
    } else {
      return '';
    }
  }));
}
