import {Modal} from "antd";
import React from "react";

export default function ShowOffer({  isShow, setIsShow, setIsOfferAgreed }) {
  return (
    <Modal
      title={`Публичная оферта о согласии с обработкой персональных данных`}
      cancelText="Закрыть"
      okText={`Принять`}
      className="modal-window modal-window--terms"
      apgn={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
      }}
      onOk={() => {
        setIsOfferAgreed(true);
        setIsShow(false);
      }}
    >
      <div>
        <p>Пользователь (дееспособное физическое лицо, достигшее 18 лет и&nbsp;пользующееся сайтом <a href="https://svetofore.com/.">www.svetofore.com/.</a>) выражает Обществу с&nbsp;ограниченной ответственностью &laquo;Витал&raquo; (далее по&nbsp;тексту&nbsp;&mdash; Оператор) согласие с&nbsp;обработкой предоставляемых им&nbsp;персональных данных Оператору при соблюдении следующих условий:</p>
        <p>1. Пользователь предоставляет следующие персональные данные:</p>
        <p>&mdash;&nbsp;Фамилия, имя, отчество.</p>
        <p>&mdash;&nbsp;Адрес электронной почты.</p>
        <p>&mdash;&nbsp;Номера телефонов.</p>
        <p>&mdash;&nbsp;Год, месяц, дата и&nbsp;место рождения.</p>
        <p>&mdash;&nbsp;Адрес фактического места проживания и&nbsp;регистрации по&nbsp;месту жительства и&nbsp;(или) по&nbsp;месту пребывания.</p>
        <p>&mdash;&nbsp;Фотографии.</p>
        <p>&mdash;&nbsp;Реквизиты документов, удостоверяющие личность.</p>
        <p>&mdash;&nbsp;Идентификационный номер налогоплательщика, дата постановки его на&nbsp;учет, реквизиты свидетельства постановки на&nbsp;учет в&nbsp;налоговом органе.</p>
        <p>&mdash;&nbsp;Сведения о&nbsp;гражданстве Российской Федерации и&nbsp;гражданстве (подданстве) иностранных государств.</p>
        <p>&mdash;&nbsp;Содержание отправленных Оператору сообщений.</p>
        <p>&mdash;&nbsp;Данные о&nbsp;лицах, от&nbsp;имени которых действует Пользователь.</p>
        <p>2. Оператор получает персональные данные Пользователя непосредственно от&nbsp;Пользователя при регистрации/авторизации на&nbsp;сайте, в&nbsp;целях использования имеющего функционала на&nbsp;сайте Оператора, а&nbsp;также доступа к&nbsp;сервисам, информации и/или материалам размещённым на&nbsp;сайте.</p>
        <p>3. Предоставляя свои персональные данные при регистрации/авторизации на&nbsp;сайте Оператора, Пользователь даёт Оператору согласие на&nbsp;сбор, систематизацию, накопление, хранение, уточнение (обновление или изменение), использование, распространение, передачу третьим лицам, обезличивание, блокирование и&nbsp;уничтожение персональных данных.</p>
        <p>4. Оператор использует персональные данные в&nbsp;следующих целях:</p>
        <p>&mdash;&nbsp;информирование Пользователя посредством отправки электронных писем;</p>
        <p>&mdash;&nbsp;заключение, исполнение и&nbsp;прекращение <nobr>гражданско-правовых</nobr> договоров, согласно учредительным документам Оператора;</p>
        <p>&mdash;&nbsp;ведения бухгалтерского учёта;</p>
        <p>&mdash;&nbsp;осуществления функции, полномочия и&nbsp;обязанности, возложенные законодательством РФ&nbsp;на&nbsp;Оператора, в&nbsp;том числе по&nbsp;предоставлению персональных данных в&nbsp;органы государственной власти, в&nbsp;Пенсионный фонд РФ, в&nbsp;Фонд социального страхования РФ, в&nbsp;Федеральный фонд обязательного медицинского страхования, а&nbsp;также в&nbsp;иные государственные органы;</p>
        <p>&mdash;&nbsp;предоставление доступа Пользователю к&nbsp;сервисам, информации и/или материалам, содержащимся на&nbsp;<nobr>веб-сайте</nobr> <a href="https://svetofore.com/">https://svetofore.com/</a>;</p>
        <p>&mdash;&nbsp;осуществления технической поддержки и&nbsp;сопровождения;</p>
        <p>&mdash;&nbsp;проведения рекламных и&nbsp;новостных рассылок;</p>
        <p>&mdash;&nbsp;исполнения обязательств по&nbsp;договорам с&nbsp;Пользователями.</p>
        <p>Персональные данные не&nbsp;могут быть переданы или раскрыты третьим лицам, за&nbsp;исключением компаний, входящих в&nbsp;одну группу лиц с&nbsp;Оператором согласно действующему законодательству РФ, без предварительного письменного согласия Пользователя, передавшей такую информацию.</p>
        <p>Персональные данные могут быть доступны только тем работникам Оператора, а&nbsp;также компаний, входящим в&nbsp;одну группу с&nbsp;Оператором согласно действующему законодательству РФ, которым персональные данные необходимы для выполнения их&nbsp;служебных (трудовых) обязанностей. При этом Оператор обеспечивает соблюдение своими работниками режима конфиденциальности в&nbsp;соответствии с&nbsp;настоящим пунктом.</p>
        <p>5. Согласие действительно в&nbsp;течение:</p>
        <p>&mdash;&nbsp;15 (пятнадцати) лет с&nbsp;момента регистрации на&nbsp;сайте или с&nbsp;момента последнего посещения сайта, в&nbsp;зависимости от&nbsp;того, какое событие наступило позднее,</p>
        <p>&mdash;&nbsp;если пользователь не&nbsp;отозвал согласие на&nbsp;обработку его персональных данных в&nbsp;письменном виде (заявление на&nbsp;отзыв согласия). При получении такого заявления Оператор обязан будет прекратить обрабатывать персональные данные Пользователя, что повлечёт (или может повлечь) за&nbsp;собой полное закрытие аккаунта Пользователя на&nbsp;сайте Оператора и&nbsp;аннулирует регистрацию неисключительных прав Пользователя на&nbsp;продукт Оператора в&nbsp;системе Оператора. Оператор не&nbsp;несёт ответственности за&nbsp;финансовые потери, в&nbsp;результате подобного заявления Пользователя и&nbsp;в&nbsp;данном случае не&nbsp;компенсирует понесённые Пользователем затраты на&nbsp;приобретение неисключительных прав на&nbsp;продукт Оператора.</p>
        <p>6. Оператор обязуется не&nbsp;разглашать полученные от&nbsp;Пользователя персональные данные. Не&nbsp;считается нарушением данного обязательства предоставление Оператором персональных данных <nobr>партнёрам-дистрибьюторам</nobr>, для исполнения обязательств перед Пользователями. Не&nbsp;считается нарушением обязательств разглашение персональных данных в&nbsp;соответствии с&nbsp;обоснованными и&nbsp;применимыми требованиями закона.</p>
        <p>7. Оператор вправе использовать технологию &laquo;cookies&raquo; и&nbsp;получать информацию об&nbsp;<nobr>ip-адресе</nobr> Пользователя. &laquo;Cookies&raquo; не&nbsp;содержат конфиденциальную информацию и&nbsp;не&nbsp;используются для установления личности Пользователя.</p>
        <p>8. В&nbsp;случае отзыва согласия на&nbsp;обработку персональных данных Пользователем, Оператор гарантирует, что вся полученная от&nbsp;Пользователя информация, в&nbsp;том числе логин и&nbsp;пароль, автоматически удаляется из&nbsp;баз данных Оператора, после чего Пользователь не&nbsp;будет иметь доступ к&nbsp;сайту Оператора и&nbsp;к&nbsp;своей пользовательской панели на&nbsp;сайте Оператора с&nbsp;их&nbsp;помощью. С&nbsp;целью отзыва согласия на&nbsp;обработку персональных данных Пользователю необходимо предоставить Оператору заявление на&nbsp;отзыв настоящего согласия с&nbsp;указанием адреса сайта и&nbsp;названия Оператора, с&nbsp;подписью Пользователя и&nbsp;датой заявления.</p>
        <p>Используя сайт я&nbsp;принимаю публичную оферту, размещённую на&nbsp;сайте, и&nbsp;даю своё согласие на&nbsp;обработку <nobr>ООО &laquo;Витал&raquo;</nobr> моих персональных данных, относящихся исключительно к&nbsp;перечисленным ниже категориям персональных данных:</p>
        <p>&mdash;&nbsp;Фамилия, имя, отчество.</p>
        <p>&mdash;&nbsp;Адрес электронной почты.</p>
        <p>&mdash;&nbsp;Номера телефонов.</p>
        <p>&mdash;&nbsp;Год, месяц, дата и&nbsp;место рождения.</p>
        <p>&mdash;&nbsp;Адрес фактического места проживания и&nbsp;регистрации по&nbsp;месту жительства и&nbsp;(или) по&nbsp;месту пребывания.</p>
        <p>&mdash;&nbsp;Фотографии.</p>
        <p>&mdash;&nbsp;Реквизиты документов, удостоверяющие личность.</p>
        <p>&mdash;&nbsp;Идентификационный номер налогоплательщика, дата постановки его на&nbsp;учет, реквизиты свидетельства постановки на&nbsp;учет в&nbsp;налоговом органе.</p>
        <p>&mdash;&nbsp;Сведения о&nbsp;гражданстве Российской Федерации и&nbsp;гражданстве (подданстве) иностранных государств.</p>
        <p>&mdash;&nbsp;Содержание отправленных Оператору сообщений.</p>
        <p>&mdash;&nbsp;Данные о&nbsp;лицах, от&nbsp;имени которых действует Пользователь.</p>
        <p>Я&nbsp;даю согласие на&nbsp;использование персональных данных исключительно в&nbsp;целях их&nbsp;обработки, а&nbsp;также на&nbsp;хранение данных об&nbsp;этих результатах на&nbsp;электронных носителях.</p>
        <p>Настоящее согласие предоставляется мной на&nbsp;осуществление действий в&nbsp;отношении моих персональных данных, которые необходимы для достижения указанных выше целей, включая (без ограничения) сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, передачу третьим лицам для осуществления действий по&nbsp;обмену информацией, обезличивание, блокирование персональных данных, а&nbsp;также осуществление любых иных действий, предусмотренных действующим законодательством Российской Федерации.</p>
        <p>Я&nbsp;проинформирован, что <nobr>ООО &laquo;Витал&raquo;</nobr> гарантирует обработку моих персональных данных в&nbsp;соответствии с&nbsp;действующим законодательством Российской Федерации как неавтоматизированным, так и&nbsp;автоматизированным способами.</p>
        <p>Данное согласие действует до&nbsp;достижения целей обработки персональных данных или в&nbsp;течение срока хранения информации.</p>
        <p>Данное согласие может быть отозвано в&nbsp;любой момент по&nbsp;моему письменному заявлению.</p>
        <p>Я&nbsp;подтверждаю, что, давая такое согласие, я&nbsp;действую по&nbsp;собственной воле и&nbsp;в&nbsp;своих интересах.</p>
      </div>
    </Modal>
  );
}
