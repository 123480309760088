import React, {useState} from 'react';
import {Col, Row, DatePicker, Checkbox} from 'antd';
import InputField from '../../../../input-field';
import DividerBlock from "../../../../divider-block";

export default function AddressesFields({ stepFields, setFields, getFieldErrors, edit = false }) {
    let dividerStyle = {
        margin: "15px 0px 45px 0px",
        display: "block",
        clear: "both",
        width: "100%",
        minWidth: "100%",
        height: "1px",
    };
    return (
        <>
            <span className="divider-label">Юридический адрес</span>
            <DividerBlock style={dividerStyle} />
            <Row gutter={[12, 24]}>
                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="legalRegion"
                        label="Регион"
                        value={stepFields.legalRegion}
                        setValue={(value) =>
                            setFields({ ...stepFields, legalRegion: value })
                        }
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="legalLocality"
                        label="Населённый пункт"
                        value={stepFields.legalLocality}
                        setValue={(value) =>
                            setFields({ ...stepFields, legalLocality: value })
                        }
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="legalStreet"
                        label="Улица"
                        value={stepFields.legalStreet}
                        setValue={(value) =>
                            setFields({ ...stepFields, legalStreet: value })
                        }
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="legalHouseNumber"
                        label="Номер дома"
                        value={stepFields.legalHouseNumber}
                        setValue={(value) =>
                            setFields({ ...stepFields, legalHouseNumber: value })
                        }
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="legalPremiseNumber"
                        label="Номер помещения"
                        value={stepFields.legalPremiseNumber}
                        setValue={(value) =>
                            setFields({ ...stepFields, legalPremiseNumber: value })
                        }
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="legalPostalCode"
                        label="Почтовый индекс"
                        value={stepFields.legalPostalCode}
                        setValue={(value) =>
                            setFields({ ...stepFields, legalPostalCode: value })
                        }
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
            </Row>

            <span className="divider-label">Фактический адрес</span>
            <DividerBlock style={dividerStyle} />
            <Row gutter={[12, 24]}>
                <Col lg={16} md={24} sm={24} xs={24} ></Col>
                <Col lg={8} md={24} sm={24} xs={24} style={{marginBottom: "30px", display: "inline", textAlign: "right"}}>
                    <span className="checkbox-label">Совпадает с юридическим адресом</span>
                    <Checkbox
                        name="isRegEqualsLegal"
                        checked={stepFields.isRegEqualsLegal}
                        onChange={(e) => {
                            setFields({
                                ...stepFields,
                                isRegEqualsLegal: e.target.checked,
                                regRegion: '',
                                regLocality: '',
                                regStreet: '',
                                regHouseNumber: '',
                                regPremiseNumber: '',
                                regAddressPostalCode: '',
                            });
                        }}

                    >
                    </Checkbox>
                </Col>
                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="regRegion"
                        label="Регион"
                        value={stepFields.regRegion}
                        isDisabled={stepFields.isRegEqualsLegal}
                        setValue={(value) =>
                            setFields({ ...stepFields, regRegion: value })
                        }
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="regLocality"
                        label="Населённый пункт"
                        value={stepFields.regLocality}
                        isDisabled={stepFields.isRegEqualsLegal}
                        setValue={(value) =>
                            setFields({ ...stepFields, regLocality: value })
                        }
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="regStreet"
                        label="Улица"
                        value={stepFields.regStreet}
                        isDisabled={stepFields.isRegEqualsLegal}
                        setValue={(value) =>
                            setFields({ ...stepFields, regStreet: value })
                        }
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="regHouseNumber"
                        label="Номер дома"
                        value={stepFields.regHouseNumber}
                        isDisabled={stepFields.isRegEqualsLegal}
                        setValue={(value) =>
                            setFields({ ...stepFields, regHouseNumber: value })
                        }
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="regPremiseNumber"
                        label="Номер помещения"
                        value={stepFields.regPremiseNumber}
                        isDisabled={stepFields.isRegEqualsLegal}
                        setValue={(value) =>
                            setFields({ ...stepFields, regPremiseNumber: value })
                        }
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="regPostalCode"
                        label="Почтовый индекс"
                        value={stepFields.regPostalCode}
                        isDisabled={stepFields.isRegEqualsLegal}
                        setValue={(value) =>
                            setFields({ ...stepFields, regPostalCode: value })
                        }
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
            </Row>
        </>
    );
}
