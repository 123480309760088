import React, {useState} from 'react';
import {Row,Col} from 'antd';
import InputField from '../../../../input-field';
import DividerBlock from '../../../../divider-block';

export default function SecondStep({ setFields, stepFields, getFieldErrors, organizationalForm, country }) {

    return (
        <>
          <DividerBlock label="Юридический адрес" />
          
          <Row>
              <Col lg={18} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                  <InputField name="legalAddressCity" label="Населенный пункт" value={stepFields.legalAddressCity} setValue={value => setFields({...stepFields, legalAddressCity: value})} getFieldErrors={getFieldErrors} />
              </Col>
              <Col lg={18} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                  <InputField name="legalAddress" label="Адрес" value={stepFields.legalAddress} setValue={value => setFields({...stepFields, legalAddress: value})} getFieldErrors={getFieldErrors} />
              </Col>
              <Col lg={6} sm={24} style={{ margin: "0 0 20px 0" }}>
                  <InputField name="legalAddressPostalCode" label="Почтовый индекс" value={stepFields.legalAddressPostalCode} setValue={value => setFields({...stepFields, legalAddressPostalCode: value})} getFieldErrors={getFieldErrors} />
              </Col>
          </Row>

          <DividerBlock label="Фактический адрес" />
            <Row>
                <Col lg={18} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField name="regAddress" label="Адрес" value={stepFields.regAddress} setValue={value => setFields({...stepFields, regAddress: value})} getFieldErrors={getFieldErrors} />
                </Col>
                <Col lg={6} sm={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField name="regAddressPostalCode" label="Почтовый индекс" value={stepFields.regAddressPostalCode} setValue={value => setFields({...stepFields, regAddressPostalCode: value})} getFieldErrors={getFieldErrors} />
                </Col>
            </Row>

          <Row>
            { organizationalForm !== 1 &&
              <>
                  <DividerBlock label="Почтовый адрес" />
                  <Col lg={18} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                      <InputField name="mailingAddress" label="Адрес" value={stepFields.mailingAddress} setValue={value => setFields({...stepFields, mailingAddress: value})} getFieldErrors={getFieldErrors} />
                  </Col>
                  <Col lg={6} sm={24} style={{ margin: "0 0 20px 0" }}>
                      <InputField name="mailingAddressPostalCode" label="Почтовый индекс" value={stepFields.mailingAddressPostalCode} setValue={value => setFields({...stepFields, mailingAddressPostalCode: value})} getFieldErrors={getFieldErrors} />
                  </Col>
                </>
            }
          </Row>
        </>
    );
}
