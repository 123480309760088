import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Table, Row, Icon, Tooltip} from "antd";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import {reportService} from "../../../../services/report.services";
import moment from "moment";
import {tableHelper} from "../../../../helpers";
import SelectField from "../../../select-field";
import {distributionCenterService} from "../../../../services";


export default function ReportStatsDriversLocation() {

  const [loading, setLoading] = useState(false);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(0);

  const [distCenters, setDistCenters] = useState([]);
  const [selectedDistCenters, setSelectedDistCenters] = useState([]);

  // параметры для построения отчета
  const [dateStart, setDateStart] = useState(moment().startOf('month'));
  const [dateEnd, setDateEnd] = useState(moment().endOf('month'));
  // полученные данные отчета
  const [reportData, setReportData] = useState([]);

  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateReport(true);
      distributionCenterService
          .getCompanies()
          .then((response) => {
            setDistCenters(response);
          })
          .catch((errorMsg) => {
            setErrorMsg(errorMsg);
          });
    }
  }, []);

  if (isNeedUpdateReport) {

    // запрос на получение данных отчета
    reportService
        .getStatsDriversLocation(
            dateStart.format('YYYY-MM-D'), dateEnd.format('YYYY-MM-D'), selectedDistCenters, selectedStatus
        )
        .then((reportData) => {

          const reportDataParsed = reportData.map(item => {
            return {
              deliveryID: item.deliveryID,
              deliveryNumber: item.deliveryNumber,
              deliveryStatus: item.deliveryStatus,
              legalEntityName: item.legalEntityName,
              driverName: item.driverName,
              logistFIO: item.logistFIO,
              dateStartSendLocation: item.dateStartSendLocation,
              dateEndSendLocation: item.dateEndSendLocation,
              deliveryArchivedByLocation: item.deliveryArchivedByLocation,
              countLocationTranslations: item.countLocationTranslations,
            };
          });

          setIsNeedUpdateReport(false);
          setLoading(false);
          setReportData(reportDataParsed);

        })
        .catch((errorMsg) => {
          setIsNeedUpdateReport(false);
          setLoading(false);
          setErrorMsg(errorMsg);
        });
  }

  // отображение фатальной ошибки
  if (errorMsg) {
    return (<div className="content-error-block">{errorMsg}</div>);
  }

  // список столбцов таблицы
  const columns = [
    {
      title: 'Номер рейса',
      dataIndex: 'deliveryNumber',
      key: 'deliveryNumber',
      width: '10%',
      ellipsis: true,
    },
    {
      title: <Tooltip title='Текущее состояние рейса'>Статус</Tooltip>,
      dataIndex: 'deliveryStatus',
      key: 'deliveryStatus',
      width: '10%',
      ellipsis: true,
    },
    {
      title: 'Перевозчик',
      dataIndex: 'legalEntityName',
      key: 'legalEntityName',
      ellipsis: true,
    },
    {
      title: 'Водитель',
      dataIndex: 'driverName',
      key: 'driverName',
      ellipsis: true,
    },
    {
      title: 'Логист',
      dataIndex: 'logistFIO',
      key: 'logistFIO',
      ellipsis: true,
    },
    {
      title: 'Дата передачи координат',
      key: 'locationDate',
      ellipsis: true,
      render: (record) => {
        if (!record.dateStartSendLocation) {
          return '-';
        }

        return (
            <>
              Старт: <b>{record.dateStartSendLocation}</b> <br/>
              Конец: <b>{record.dateEndSendLocation}</b> <br/>
              Кол-во трансляций : <b>{record.countLocationTranslations}</b>
            </>
        );
      },
    },
    {
      title: 'Рейс завершен автоматически',
      key: 'locationDate',
      ellipsis: true,
      render: (record) => {
        if (record.deliveryStatus !== 'Согласован') {
          return '-';
        }

        return record.deliveryArchivedByLocation ? 'Да' : 'Нет';
      },
    },
  ];

  return (
      <React.Fragment>
        <div className="reports__header">
          <h1 className="content-header">Отчет «Статистика по заявкам»</h1>
          <Icon type='filter'
                theme={isFilterOpen ? '' : "filled"}
                onClick={() => {
                  setIsFilterOpen(!isFilterOpen)
                }}
                className="reports__filter-toggle"/>
          {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
            <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start">
              <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
                <DatePicker
                    style={{width: "100%"}}
                    defaultValue={dateStart}
                    format="DD.MM.YYYY"
                    size="normal"
                    name="dateStart"
                    placeholder="Выберите дату начала периода"
                    locale={localeDatePicker}
                    allowClear={false}
                    onChange={(date) => {
                      setDateStart(date);
                    }}
                />
              </Col>
              <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
                <DatePicker
                    style={{width: "100%"}}
                    defaultValue={dateEnd}
                    format="DD.MM.YYYY"
                    size="normal"
                    name="dateEnd"
                    placeholder="Выберите дату окончания периода"
                    locale={localeDatePicker}
                    allowClear={false}
                    onChange={(date) => {
                      setDateEnd(date);
                    }}
                />
              </Col>
              <Col lg={6} md={6} sm={12} xs={24} style={{textAlign: 'right', height: '40px'}}>
                <Button type="primary"
                        htmlType="button"
                        onClick={() => {
                          setLoading(true);
                          setIsNeedUpdateReport(true);
                        }}
                        style={{backgroundColor: '#1CAA53', border: 'none', height: '100%', width: '100%'}}
                >
                  Применить
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
                <SelectField
                    name="distributionCenters"
                    selectedValue={selectedDistCenters}
                    values={distCenters}
                    onChange={(value) => {
                      setSelectedDistCenters(value);
                    }}
                    notFoundText="РЦ отсутствуют"
                    getErrors={() => {
                    }}
                    style={{width: "100%"}}
                    mode={'multiple'}
                />
              </Col>
              <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
                <SelectField
                    name="deliveryStatus"
                    selectedValue={selectedStatus}
                    values={[
                      {id: 0, name: 'Все'},
                      {id: 13, name: 'В пути'},
                      {id: 15, name: 'Архивные'},
                    ]}
                    onChange={(value) => {
                      setSelectedStatus(value);
                    }}
                    getErrors={() => {
                    }}
                    style={{width: "100%"}}
                />
              </Col>
            </Row>
          </div>}
        </div>
        <div className="reports__table">
          <div className="reports__table-wrapper">
            <Col span={24}>
              <Table
                  style={{minWidth: '100%'}}
                  columns={columns}
                  dataSource={reportData}
                  locale={{emptyText: "Данные отсутствуют"}}
                  rowKey={"userId"}
                  loading={loading}
                  size="small"
                  pagination={{pageSize: 50}}
              />

            </Col>
          </div>
        </div>
      </React.Fragment>
  );
}
