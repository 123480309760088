import React from "react";
import {Button} from "antd";
import Cookies from "js-cookie";

export default function CookiesMessage({setCookiesApproved}) {
  const handleButtonClick = () => {
    Cookies.set(`cookies`, 'approved');
    setCookiesApproved(true);
  }

  return (
    <div className="cookies-message">
      <p>Используя данный сайт, вы даете согласие на использование файлов cookie, помогающих нам сделать его удобнее для вас.</p>
      <Button className=""
              onClick={handleButtonClick}
              >Принять и закрыть</Button>
    </div>
  )
}
