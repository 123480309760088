import React, { useEffect, useState } from 'react';
import {Button, Row, Col, Modal, Spin, notification, Select} from 'antd';
import { formHelper } from "../../../helpers";
import InputField from "../../input-field";
import {roleService, distributionCenterService, userServices, consignorService} from "../../../services";
import SelectField from "../../select-field";
import '../modals.css';

const defaultUserData = {
    fio: '',
    login: '',
    countryId: 1,
    password: null,
    email: '',
    phone: '',
    role: null,
    consignorId: null,
    distributionsCentersIds: [],
};

export default function ModalCreateStaffUser({ isShow, onCloseModal }) {

    const [loadingContentModal, setLoadingContentModal] = useState(false);
    const [needUpdateContent, setNeedUpdateContent] = useState(false);

    const [listStaffRoles, setListStaffRoles] = useState([]);
    const [listDistCenters, setListDistCenters] = useState([]);

    const [userData, setUserData] = useState(defaultUserData);

    const [fieldsErrors, setFieldsErrors] = useState(null);

    const [countryList, setCountryList] = useState([
        {
            Id: 1,
            Name: 'Россия'
        },
        {
            Id: 3,
            Name: 'Беларусь'
        }
    ]);

    const [consignorsList, setConsignorsList] = useState([]);

    const getFieldErrors = (nameField) => formHelper.getFieldErrors(nameField, fieldsErrors);

    let bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent" };

    useEffect(() => {
        if (isShow && !loadingContentModal) {
            setLoadingContentModal(true);
            setNeedUpdateContent(true);
        }
    }, [isShow]);

    useEffect(() => {
        if (isShow) {
            consignorService
                .getConsignorsList()
                .then((resp) => {
                    setConsignorsList(resp);
                })
                .catch((err) => {
                    notification.error({
                        message: "Получение грузоотправителей",
                        description: err,
                    });
                });
        }
    }, [isShow]);

    useEffect(() => {
        if (userData.consignorId) {
            setUserData({...userData, distributionsCentersIds: []});

            distributionCenterService.getCompanies(null, null, userData.consignorId)
                .then((response) => {
                    setNeedUpdateContent(false);
                    setLoadingContentModal(false);

                    const distCentersData = response.map((distCenterInfo) => {
                        return {
                            Id: distCenterInfo.Id,
                            Name: distCenterInfo.Name,
                        }
                    });

                    setListDistCenters(distCentersData);
                })
                .catch((error) => {
                    setNeedUpdateContent(false);
                    setLoadingContentModal(false);
                    notification.error({
                        message: "Получение информации о РЦ",
                        description: error,
                    });
                });
        }
    }, [userData.consignorId]);

    if (needUpdateContent) {

        roleService.getRoles()
            .then((response) => {
                setNeedUpdateContent(false);
                setLoadingContentModal(false);

                const staffRolesData = response.map((roleData) => {
                    return {
                        Id: roleData.Name,
                        Name: roleData.Description,
                    }
                });

                setListStaffRoles(staffRolesData);
            })
            .catch((error) => {
                setNeedUpdateContent(false);
                setLoadingContentModal(false);
                notification.error({
                    message: "Получение ролей",
                    description: error,
                });
            });
    }


    const onClickCreate = () => {
        setLoadingContentModal(true);


        userServices
            .createStaffUser(userData.fio, userData.login, userData.password, userData.email, userData.phone, userData.role, userData.distributionsCentersIds, userData.consignorId, userData.countryId)
            .then((response) => {
                setLoadingContentModal(false);

                if (response.status === 'success') {

                    notification.success({
                        message: "Создание пользователя",
                        description: response.message,
                    });

                    setUserData(defaultUserData);
                    onCloseModal();

                } else if (response.status === 'error') {

                    setFieldsErrors(response.errors);
                }
            })
            .catch((error) => {
                setLoadingContentModal(false);
                notification.error({
                    message: "Создание пользователя",
                    description: error,
                });
            })
    };

    return (
        <Modal
            title={<span>Создание штатного пользователя</span>}
            cancelText="Закрыть"
            style={{ top: 20, minWidth: "700px" }}
            align={null}
            visible={isShow}
            onCancel={onCloseModal}
            footer={[
                <Button htmlType="submit" key="submit" style={bigButtonStyle} type="primary" onClick={onClickCreate}>Создать</Button>,
            ]}
        >
            <Spin spinning={loadingContentModal} tip="Загрузка...">
                <Row gutter={[16, 24]} >
                    <Col span={24} >
                        <InputField name="fio" label="ФИО"
                            value={userData.fio}
                            setValue={(fio) => setUserData({ ...userData, fio: fio })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <SelectField
                            title="Страна"
                            name="country"
                            selectedValue={userData.countryId}
                            values={countryList}
                            getErrors={getFieldErrors}
                            onChange={(value) => {
                                setUserData({ ...userData, countryId: value })
                            }}
                            placeholder="Выберите страну"
                            notFoundText="Отсутствуют стран"
                            style={{ width: "100%" }}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 24]} >
                    <Col lg={12} md={24} sm={24} xs={24} >
                        <InputField style={{width: "86% !important"}} name="login" label="Логин"
                            value={userData.login}
                            setValue={(value) => setUserData({ ...userData, login: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} >
                        <InputField name="password" label="Пароль"
                            value={userData.password}
                            setValue={(value) => setUserData({ ...userData, password: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 24]} >
                    <Col lg={12} md={24} sm={24} xs={24} >
                        <InputField name="email" label="Email"
                            value={userData.email}
                            setValue={(value) => setUserData({ ...userData, email: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} >
                        <InputField name="phone" label="Телефон (Формат: +79991112233)"
                            value={userData.phone}
                            setValue={(value) => setUserData({ ...userData, phone: value })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 24]} >
                    <Col span={24} >
                        <SelectField
                            title="Роль"
                            name="role"
                            selectedValue={userData.role}
                            values={listStaffRoles}
                            onChange={(value) => {
                                setUserData({ ...userData, role: value });
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Выберите роль"
                            notFoundText="Отсутствуют роль"
                            style={{ width: "100%" }}
                        />
                    </Col>
                </Row>

                <Row gutter={[16, 24]} >
                    <Col span={24} >
                        <SelectField
                            title="Грузоотправитель"
                            name="consignorId"
                            selectedValue={userData.consignorId}
                            values={consignorsList}
                            onChange={(value) => {
                                setUserData({ ...userData, consignorId: value });
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Выберите Грузоотправителя"
                            notFoundText="Отсутствуют Грузоотправители"
                            style={{ width: "100%" }}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 24]} >
                    <Col span={24} >
                        <SelectField
                            mode="multiple"
                            title="РЦ"
                            name="distributionsCentersIds"
                            selectedValue={userData.distributionsCentersIds}
                            values={listDistCenters}
                            onChange={(value) => {
                                setUserData({ ...userData, distributionsCentersIds: value });
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Выберите РЦ"
                            notFoundText="Отсутствуют РЦ"
                            style={{ width: "100%" }}
                        />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}