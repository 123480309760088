import React, {useEffect, useState} from 'react';
import Spinner from "../../../spinner";
import {Button, Col, DatePicker, Table, Row, Icon, Tooltip} from "antd";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import {reportService} from "../../../../services/report.services";
import moment from "moment";
import SelectField from "../../../select-field";
import {distributionCenterService} from "../../../../services";


export default function ReportStatsCargo() {

  const [loading, setLoading] = useState(false);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  // параметры для построения отчета
  const [dateStart, setDateStart] = useState(moment().startOf('month'));
  const [dateEnd, setDateEnd] = useState(moment().endOf('month'));
  // полученные данные отчета
  const [reportData, setReportData] = useState([]);

  const [distCenters, setDistCenters] = useState([]);
  const [selectedDistCetners, setSelectedDistCenters] = useState([]);

  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateReport(true);
      distributionCenterService.getCompanies()
        .then((response) => {
          setDistCenters(response);
          setCompaniesLoaded(true);
        }).catch((errorMsg) => {
        setErrorMsg(errorMsg);
        setCompaniesLoaded(true);
      });
    }

    if (isNeedUpdateReport) {
      // запрос на получение данных отчета
      reportService.getStatsCargos(dateStart.format('YYYY-MM-D'), dateEnd.format('YYYY-MM-D'), selectedDistCetners)
        .then((response) => {
          setReportData([]);
          let tableData = [];
          response.map((item) => {
            if (!item.child) {
              tableData[item.distributionCenter] = {
                number: item.number,
                factSum: item.factSum + ' ₽',
                planSum: item.planSum + ' ₽',
                siteSum: item.siteSum + ' ₽',
                timeFromCreateToApprove: item.timeFromCreateToApprove + ' ч.',
                creatorName: item.creatorName,
                filledInName: item.filledInName,
                mobileApp: item.mobileApp,
                webApp: item.webApp,
                telegramApp: item.telegramApp,
                children: []
              };
            } else {
              tableData[item.distributionCenter].children.push({
                key: item.number,
                number: '№ ' + item.number,
                factSum: item.factSum + ' ₽',
                planSum: item.planSum + ' ₽',
                siteSum: item.siteSum + ' ₽',
                timeFromCreateToApprove: item.timeFromCreateToApprove + ' ч.',
                creatorName: item.creatorName,
                filledInName: item.filledInName,
                mobileApp: item.mobileApp ? '✔' : '',
                webApp: item.webApp ? '✔' : '',
                telegramApp: item.telegramApp ? '✔' : '',
              });
            }
            return null;
          });

          let reindexedTableData = [];
          for (let key in tableData) {
            reindexedTableData.push(tableData[key]);
          }
          console.log(reindexedTableData);
          setIsNeedUpdateReport(false);
          setLoading(false);
          setReportData(reindexedTableData);
        }).catch((errorMsg) => {
        setIsNeedUpdateReport(false);
        setLoading(false);
        setErrorMsg(errorMsg);
      });
    }
  }, [isNeedUpdateReport]);

  // Получаем список всех РЦ
  if (!companiesLoaded) {

  }

  const getFieldErrors = (nameField) => {

    return null;
  };

  // отображение фатальной ошибки
  if (errorMsg) {
    return (<div className="content-error-block">{errorMsg}</div>);
  }

  const columnTitle = <Icon type="mobile"/>;

  // список столбцов таблицы
  const columns = [
    {
      title: 'Номер груза',
      dataIndex: 'number',
      key: 'number',
      width: '25%',
    },
    {
      title: 'Ставка сайт',
      dataIndex: 'siteSum',
      key: 'siteSum',
      width: '10%'
    },
    {
      title: 'Ставка план',
      dataIndex: 'planSum',
      key: 'planSum',
      width: '10%',
    },
    {
      title: 'Ставка факт',
      dataIndex: 'factSum',
      key: 'factSum',
      width: '10%'
    },
    {
      title: <Tooltip title='Время ожидания'><Icon type="clock-circle"/></Tooltip>,
      dataIndex: 'timeFromCreateToApprove',
      key: 'timeFromCreateToApprove',
      width: '5%'
    },
    {
      title: 'Создавший',
      dataIndex: 'creatorName',
      key: 'creatorName',
      width: '15%'
    },
    {
      title: 'Заполнивший',
      dataIndex: 'filledInName',
      key: 'filledInName',
      width: '15%'
    },
    {
      title: <Tooltip title="Мобильное приложение"><Icon type="mobile"/></Tooltip>,
      dataIndex: 'mobileApp',
      key: 'mobileApp',
      width: '5%',
      className: 'center'
    },
    {
      title: <Tooltip title="Сайт"><Icon type="global"/></Tooltip>,
      dataIndex: 'webApp',
      key: 'webApp',
      width: '5%',
      className: 'center'
    },
    {
      title: <Tooltip title="Телеграм"><Icon type="arrow-right"/></Tooltip>,
      dataIndex: 'telegramApp',
      key: 'telegramApp',
      width: '5%',
      className: 'center'
    }
  ];

  return (
    <React.Fragment>
      <div className="reports__header">
        <h1 className="content-header">Отчет «Статистика по грузам»</h1>
        <Icon type='filter'
              theme={isFilterOpen ? '' : "filled"}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen)
              }}
              className="reports__filter-toggle"/>
        {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
          <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start">
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateStart}
                format="DD.MM.YYYY"
                size="normal"
                name="dateStart"
                placeholder="Выберите дату начала периода"
                locale={localeDatePicker}
                allowClear={false}
                onChange={(date) => {
                  setDateStart(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateEnd}
                format="DD.MM.YYYY"
                size="normal"
                name="dateEnd"
                placeholder="Выберите дату окончания периода"
                locale={localeDatePicker}
                allowClear={false}
                onChange={(date) => {
                  setDateEnd(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <SelectField
                name="distributionCenters"
                selectedValue={selectedDistCetners}
                values={distCenters}
                onChange={(value) => {
                  setSelectedDistCenters(value);
                }}
                notFoundText="РЦ отсутствуют"
                getErrors={getFieldErrors}
                style={{width: "100%"}}
                mode={'multiple'}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{textAlign: 'right', height: '40px'}}>
              <Button type="primary"
                      htmlType="button"
                      onClick={() => {
                        setLoading(true);
                        setIsNeedUpdateReport(true);
                      }}
                      style={{backgroundColor: '#1CAA53', border: 'none', height: '100%', width: '100%'}}
              >
                Применить
              </Button>
            </Col>
          </Row>
        </div>}
      </div>
      <div className="reports__table">
        <div className="reports__table-wrapper">
          <Col span={24}>
            <Table
              style={{minWidth: '100%'}}
              columns={columns}
              dataSource={reportData}
              locale={{emptyText: "Данные отсутствуют"}}
              rowKey="number"
              size="small"
              loading={loading}
              pagination={{
                pageSize: 50
              }}
            />
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
}
