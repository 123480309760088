import React, { useEffect, useState } from "react";
import { Button, Col, Modal, notification, Row, Checkbox, Icon, Collapse } from "antd";
import { permissionService, roleService } from "../../../../services";
import InputField from "../../../input-field";

export default function ModalEditRole({ isShow, onCloseModal, roleName: currentRoleName, roleDesc: currentRoleDesc, rolePermissions = [] }) {
  const [loadingContentModal, setLoadingContentModal] = useState(false);
  const [listPermissions, setListPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState(rolePermissions);
  const [roleName, setRoleName] = useState(currentRoleName);
  const [roleDesc, setRoleDesc] = useState(currentRoleDesc);

  const [fieldsErrors, setFieldsErrors] = useState(null);

  let bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px' };

  useEffect(() => {
    if (isShow && !loadingContentModal) {
      setLoadingContentModal(true);
    }
  }, [isShow]);

  const getFieldErrors = nameField => {
    return fieldsErrors && typeof fieldsErrors[nameField] !== "undefined" ? fieldsErrors[nameField][0] : null;
  };

  if (loadingContentModal) {
    permissionService
      .getPermissions()
      .then(responseData => {
        setLoadingContentModal(false);

        const permissionsData = responseData.map(permissionData => {
          return { name: permissionData.Name, desc: permissionData.Description };
        });

        setListPermissions(permissionsData);
      })
      .catch(error => {
        setLoadingContentModal(false);
        notification.error({
          message: "Получение разрешений",
          description: error
        });
      });
  }

  const onClickEdit = e => {
    e.preventDefault();
    setLoadingContentModal(true);

    roleService
      .edit(currentRoleName, roleName, roleDesc, selectedPermissions)
      .then(response => {
        setLoadingContentModal(false);

        if (response.status === "success") {
          notification.success({ message: "Редактирование роли", description: response.message });
          onCloseModal();
        } else if (response.status === "error") {
          setFieldsErrors(response.errors);
        }
      })
      .catch(error => {
        notification.error({ message: "Редактирование роли", description: error });
        setLoadingContentModal(false);
      });
  };

  function onChangePermission(e) {
    if (e.target.checked === true) {
      setSelectedPermissions([...selectedPermissions, e.target.value]);
    } else {
      setSelectedPermissions([...selectedPermissions.filter(name => name !== e.target.value)]);
    }
  }

  return (
    <Modal
      title={
        <span>
          Редактирование роли «{currentRoleDesc}»
        </span>
      }
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={onCloseModal}
      footer={[
        <Button htmlType="submit" key="submit" type="primary" onClick={onClickEdit} style={bigButtonStyle}>
          Сохранить
        </Button>
      ]}
    >
      <Row lg={24} md={24} sm={24} xs={24}>
        <Col span={24}>
          <InputField name="title" label="Название" value={roleDesc} setValue={setRoleDesc} getFieldErrors={getFieldErrors} />
        </Col>
      </Row>
      <Row lg={24} md={24} sm={24} xs={24}>
        <Col span={24}>
          <InputField name="newName" label="Тех. имя" value={roleName} setValue={setRoleName} getFieldErrors={getFieldErrors} />
        </Col>
      </Row>
      <Row lg={24} md={24} sm={24} xs={24}>
        <Col span={24}>
          <Collapse bordered={false} defaultActiveKey={['1']} expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}>
            <Collapse.Panel header="Список разрешений" key="1" className="b-permissions-panel">
              {listPermissions.map(permissionData => {
                return (
                  <Col span={24} key={permissionData.name}>
                    <Checkbox
                      value={permissionData.name}
                      onChange={onChangePermission}
                      defaultChecked={selectedPermissions.indexOf(permissionData.name) !== -1}
                    >
                      {permissionData.desc}
                    </Checkbox>
                  </Col>
                );
              })}
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    </Modal>
  );
}
