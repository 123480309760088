import {Redirect, Route} from "react-router-dom";
import React from "react";
import {userServices} from "../../services";
import Content from "../content";

export default function PrivateRoute({path, component, aside= false}) {
    const user = userServices.getUser();

    if (!user.loggedIn) {
        return (
            <Redirect to={'/login'} />
        );
    }

    if (aside) {
        return (
          <Route path={path} render={()=><>{component}</>} />
        );
    }

    return (
      <Route path={path} render={()=><Content>{component}</Content>} />
    );
}
