import React, {useEffect, useState} from 'react';
import {Col, Modal, notification, Row, Spin} from "antd";
import '../modals.css';
import {companyService} from "../../../services";
import {FullDate} from "../../tools/delivery-tools";

export default function ModalShowRefuseReasonForConsignor({isShow, setIsShow, id}) {

  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({});

  const textStyle = {
    textAlign: 'center',
    color: 'black'
  };

  useEffect(() => {
    setLoading(true);
    if (id && isShow) {
      companyService
        .getRefuseReasonInfo(id)
        .then(resp => {
          setInfo(resp);
          setLoading(false);
        })
        .catch(errMsg => notification.error(
          {
            message: "Получение причины отказа",
            description: errMsg
          }));
    }
  }, [id, isShow]);

  return (
    <Modal
      title={<span className="ant-modal-title">Причины отмены</span>}
      cancelText="Закрыть"
      className="modal-window"
      style={{maxWidth: '450px'}}
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
      }}
      footer={null}
    >
      <Spin spinning={loading} tip="Загрузка...">
        <Row className="add-padding-10 -centered" style={{fontSize: "14px"}}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}><b>Перевозчик:</b> {info.legalEntityName}</Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}><b>Грузоотправитель:</b> {info.consignorName}</Col>
        </Row>
        <Row className="add-padding-10 -centered" style={{fontSize: "14px", paddingBottom: 40}}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>Статус: {info.status}</Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>Дата заявки: {info.dateCreated}</Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>Дата отказа: {info.refuseDate}</Col>
          <Col span={24}><b>Причина отказа: </b>
            <p>{info.refuseReason}</p>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}
