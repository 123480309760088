import React, {useEffect,useState} from 'react';
import {notification,Row,Col,Button,Upload,Icon} from 'antd';
import {companyService} from '../../../../services';
import FieldBlock from '../../../field-block';
import DividerBlock from "../../../divider-block";

export default function EditBelarusLegalEntityDocs({legalEntityData, docFields, setDocFields, fieldsErrors}) {
    const getFieldErrors = (fieldName) => {
        return typeof fieldsErrors[fieldName] !== "undefined"
            ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
                return fieldErrors + "; " + error;
            })
            : null;
    };    

    const getUploaderClass = (name, multiple = true) => {
        return {
            multiple: multiple,
            showUploadList: true,
            onRemove: (file) => {
                setDocFields({...docFields, [name]: null});
            },
            beforeUpload: (file, fileList) => {
                if (multiple) {
                    if (docFields[name]) {
                        setDocFields({...docFields, [name]: docFields[name].concat(fileList)});
                    } else {
                        setDocFields({...docFields, [name]: fileList});
                    }
                } else {
                    setDocFields({...docFields, [name]: file});
                }
                return false;
            },
            fileList: docFields[name],
            listType: 'picture',
        };
    };

    return (
        <Row gutter={20}>
            <DividerBlock label="Загрузите сканы документов"/>
            {legalEntityData.OrgFormId !== 16 && (
                <>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="articlesOfAssociation" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.articlesOfAssociation} fileList={docFields.articlesOfAssociation} className="single-btn-upload" {...getUploaderClass('articlesOfAssociation', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Устав в актуальной редакции
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="documentsConfirmingOwnershipOfOfficePremises" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.documentsConfirmingOwnershipOfOfficePremises} fileList={docFields.documentsConfirmingOwnershipOfOfficePremises} className="single-btn-upload" {...getUploaderClass('documentsConfirmingOwnershipOfOfficePremises', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Документы, подтверждающие право собственности или иные права на офисные помещения, в которых контрагент осуществляет деятельность (свидетельства о государственной регистрации, договоры аренды)
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="copyOfBankCard" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.copyOfBankCard} fileList={docFields.copyOfBankCard} className="single-btn-upload" {...getUploaderClass('copyOfBankCard', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Копия банковской карточки с образцами подписи и оттиска печати с которой можно сличить подпись и печать, проставленную в договоре
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                </>
            )}

            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="certificateOfStateRegistration" getErrors={getFieldErrors}>
                    <Upload defaultFileList={docFields.certificateOfStateRegistration} fileList={docFields.certificateOfStateRegistration} className="single-btn-upload" {...getUploaderClass('certificateOfStateRegistration', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Свидетельство о государственной регистрации юридического лица
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="passport" getErrors={getFieldErrors}>
                    <Upload defaultFileList={docFields.passport} fileList={docFields.passport} className="single-btn-upload" {...getUploaderClass('passport', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Копия паспорта (с пропиской)
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="specialPermitForTheRightToCarryOutActivities" getErrors={getFieldErrors}>
                    <Upload defaultFileList={docFields.specialPermitForTheRightToCarryOutActivities} fileList={docFields.specialPermitForTheRightToCarryOutActivities} className="single-btn-upload" {...getUploaderClass('specialPermitForTheRightToCarryOutActivities', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Специальное разрешение (лицензия) на право осуществления деятельности в области автомобильного транспорта
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="partnerCard" getErrors={getFieldErrors}>
                    <Upload defaultFileList={docFields.partnerCard} fileList={docFields.partnerCard} className="single-btn-upload" {...getUploaderClass('partnerCard', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Карточка контрагента – сведения о банковских реквизитах, номера телефонов, адреса офисов
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="residenceCertificate" getErrors={getFieldErrors}>
                    <Upload defaultFileList={docFields.residenceCertificate} fileList={docFields.residenceCertificate} className="single-btn-upload" {...getUploaderClass('residenceCertificate', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Справка о постоянном местонахождении Residence Certificate (в целях избежания двойного налогообложения) (по форме согласно Приложение 11 к постановлению МНС Республики Беларусь от 26.04.2013 № 14)
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
        </Row>
    );
}
