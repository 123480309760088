const config = {
  403: {
    img: '/exceptions/403.svg',
    title: '403'
  },
  404: {
    img: '/exceptions/404.svg',
    title: '404'
  },
  500: {
    img: '/exceptions/404.svg',
    title: '500'
  },
};

export default config;
