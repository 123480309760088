import {Button, Col, Form, Modal, notification, Row, Spin} from "antd";
import InputField from "../../input-field";
import React, {useEffect, useState} from "react";
import {userServices} from "../../../services";

export default function AttachTelegramModal({  isShow, setIsShow }) {
    const [loading, setLoading] = useState(true);
    const [verifyCode, setVerifyCode] = useState(null);

    useEffect(() => {
        if (isShow) {
            setLoading(false);
            userServices.getTelegramVerifyCode()
                .then((resp) => {
                    setVerifyCode(resp);

                    setLoading(false);
                });
        }
    }, [isShow]);

    return (
        <Modal
            title={`Telegram`}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
            }}
            footer={null}
        >
            <Spin spinning={loading} tip="Загрузка...">
                <Form>
                    <b>Для подключения бота в телеграм выполните следующие действия:</b>
                    <ol>
                        <li>Перейдите по ссылке <a target="_blank" href="https://t.me/VitalLogisticBot">https://t.me/VitalLogisticBot</a> Либо в поиске Telegram напишите VitalLogisticBot. Большие и маленькие буквы значения не имеют.</li>
                        <li>Для начала работы в боте нужно нажать Старт</li>
                        <li>Для подключения бота введите ваш персональный код:</li>
                    </ol>

                    <h1 className="center">
                        {verifyCode}
                    </h1>
                    <div><br /><br /><br /><br /><br /><br /></div>
                </Form>
            </Spin>
        </Modal>
    );
}