import { httpRequest } from "./http-request";

export const trailService = {
  create,
  checkVerifiedInfo,
  saveEditingInfo,
  getTrailTypes,
  edit,
  getListByName,
  getDocsArchive,
  changeVINStatus,
  hide,
};

/**
 * Создать прицеп
 */
async function create(trailData) {
  const formData = new FormData();

  for (let fieldName in trailData) {
    if (trailData.hasOwnProperty(fieldName)) {
      formData.append(fieldName, trailData[fieldName]);
    }
  }

  return await httpRequest
    .post("trail/create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Проверить актуальность данных
 */
async function checkVerifiedInfo(id, driverId) {
  return await httpRequest.get(`trail/${id}/${driverId}/check-verified-info`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

/**
 * Сохранить измененные данные
 */
async function saveEditingInfo(id, data) {
  const formData = new FormData();

  for (let fieldName in data) {
    if (data.hasOwnProperty(fieldName)) {
      formData.append(fieldName, data[fieldName]);
    }
  }
  return await httpRequest
    .post(`trail/${id}/edit`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Получить список типов кузова
 */

async function getTrailTypes() {
  return await httpRequest.get(`trail/get-types`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

/**
 * Сохранить измененные данные без валидации
 */
async function edit(id, data) {
  const formData = new FormData();

  for (let fieldName in data) {
    if (data.hasOwnProperty(fieldName)) {
      formData.append(fieldName, data[fieldName]);
    }
  }
  return await httpRequest
    .post(`trail/${id}/edit-not-actual`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Получить список прицепов с поиском по имени
 */
async function getListByName(name) {

    return await httpRequest.get(`trail/get-list-by-name?Name=${name}`)
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getDocsArchive({id}) {
  return await httpRequest.get(`trail/download-files-archive?id=${id}`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function changeVINStatus(id, vinStatus) {
  return await httpRequest.get(`trail/change-vin?id=${id}&vin=${vinStatus ? true : ''}`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function hide(id) {
  return await httpRequest.get(`trail/hide?id=${id}`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}