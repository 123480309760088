import React, {useEffect, useState} from "react";
import { Button, Modal, notification, Row, Col, Spin, Checkbox } from "antd";
import SelectField from "../../select-field";
import InputField from "../../input-field";
import { userServices } from "../../../services";
import { formHelper } from "../../../helpers";
import '../modals.css';

export default function ModalEditCarrierUser({ isShow, userId, userFio, userStatus, userAccreditedIntDeliveries, delivDisplayDelay, onCloseModal, roleName }) {

    const [loadingContentModal, setLoadingContentModal] = useState(false);
    const [status, setStatus] = useState(userStatus);
    const [role, setRole] = useState(roleName);
    const [password, setPassword] = useState(null);
    const [accreditedIntDeliveries, setAccreditedIntDeliveries] = useState(userAccreditedIntDeliveries);
    const [deliveryDisplayDelay, setDeliveryDisplayDelay] = useState(delivDisplayDelay);

    const [fieldsErrors, setFieldsErrors] = useState(null);
    let bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent" };

    const getFieldErrors = (nameField) => formHelper.getFieldErrors(nameField, fieldsErrors);

    const onClickEdit = (e) => {
        e.preventDefault();
        setLoadingContentModal(true);

        userServices.editCarrierUser(userId, password, status, accreditedIntDeliveries, deliveryDisplayDelay, role)
            .then((response) => {

                setLoadingContentModal(false);

                if (response.status === "success") {
                    notification.success({ message: "Редактирование пользователя", description: response.message });
                    onCloseModal();

                } else if (response.status === "error") {
                    setFieldsErrors(response.errors);
                }
            })
            .catch((error) => {
                notification.error({ message: "Редактирование пользователя", description: error });
                setLoadingContentModal(false);
            });
    };

    useEffect(() => {
        setStatus(userStatus);
        setRole(roleName);
        setAccreditedIntDeliveries(userAccreditedIntDeliveries);
        setDeliveryDisplayDelay(delivDisplayDelay);
    }, [isShow])

    return (
        <Modal
            title={<span>Редактирование пользователя<br />«{userFio}»</span>}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={onCloseModal}
            footer={[
                <Button htmlType="submit" key="submit" type="primary" style={bigButtonStyle} onClick={onClickEdit}>Сохранить</Button>,
            ]}
        >
            <Spin spinning={loadingContentModal} tip="Загрузка...">
                <Row gutter={[16, 24]}>
                    <Col lg={12} md={24} sm={24} xs={24} >
                        <SelectField
                            title="Статус"
                            name="status"
                            selectedValue={status}
                            values={[
                                { Id: 'active', Name: 'Активен' },
                                { Id: 'deleted', Name: 'Удален' }
                            ]}
                            onChange={(value) => {
                                setStatus(value);
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Выберите статус"
                            notFoundText="Отсутствуют статус"
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} >
                        <InputField name="password" label="Пароль"
                            value={password} setValue={(value) => setPassword(value)}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 24]}>
                    {/*<Col lg={12} md={24} sm={24} xs={24} >*/}
                    {/*    <InputField name="deliveryDisplayDelay" label="Задержка показа новых рейсов (в минутах)"*/}
                    {/*        value={deliveryDisplayDelay} setValue={(value) => setDeliveryDisplayDelay(value)}*/}
                    {/*        getFieldErrors={getFieldErrors}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                    {/*<Col lg={12} md={24} sm={24} xs={24} >*/}
                    {/*    <SelectField*/}
                    {/*        title="Роль"*/}
                    {/*        name="role"*/}
                    {/*        selectedValue={role}*/}
                    {/*        values={[*/}
                    {/*            { Id: 'carrier_owner', Name: 'Собственник' },*/}
                    {/*            { Id: 'carrier_dispatcher', Name: 'Диспетчер' }*/}
                    {/*        ]}*/}
                    {/*        onChange={(value) => {*/}
                    {/*            setRole(value);*/}
                    {/*        }}*/}
                    {/*        getErrors={getFieldErrors}*/}
                    {/*        placeholder="Выберите роль"*/}
                    {/*        notFoundText="Отсутствуют роль"*/}
                    {/*        style={{ width: "100%" }}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                    {/*<Col lg={24} md={24} sm={24} xs={24} style={{textAlign: "right"}} >*/}
                    {/*    <span className="checkbox-label">Акредитован на международные рейсы</span>*/}
                    {/*    <Checkbox*/}
                    {/*        name="accreditedIntDeliveries"*/}
                    {/*        checked={accreditedIntDeliveries}*/}
                    {/*        onChange={(e) => {*/}
                    {/*            setAccreditedIntDeliveries(e.target.checked);*/}
                    {/*        }}*/}
                    {/*        style={{ fontSize: "10pt", paddingTop: "15px" }}*/}
                    {/*    >*/}
                    {/*    </Checkbox>*/}
                    {/*</Col>*/}
                </Row>
            </Spin>
        </Modal>
    );
}
