import React, {useState} from "react";
import {Button, Row, Col, Modal, notification, Input, Typography} from "antd";
import '../modals.css';
import {deliveriesService} from "../../../services";

export default function ModalReturnToEnRoute({
                                                 isShow, id, onCloseModal
                                               }) {
  let bigButtonStyle = {
    width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px'
  };
  const {Text} = Typography;
  const {TextArea} = Input;
  const [reason, setReason] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const onSignUp = () => {
    setIsDisabled(true);
    deliveriesService.returnToEnRoute(id, reason)
      .then((response) => {
        notification.success({
          message: "Возврат рейса из архива", description: response.message,
        });
        onCloseModal();
      })
      .catch((err) => {
        notification.error({
          message: "Возврат рейса из архива", description: err,
        });
      })
      .finally(() => {
        setIsDisabled(false);
      })
  }

  return (<Modal
    title={<div>Вернуть рейс из архива</div>}
    cancelText="Закрыть"
    className="modal-window"
    align={null}
    visible={isShow}
    onCancel={() => {
      onCloseModal();
    }}
    forceRender={true}
    footer={
      <Button htmlType="submit" key="submit" type="primary"
              style={bigButtonStyle}
              onClick={() => onSignUp()}
              disabled={reason === "" || isDisabled}
      >Отправить</Button>
    }
  >
    <Row gutter={[16, 24]} style={{marginBottom: "34px"}}>
      <Col span={24}>
        <Text strong style={{fontSize: "20px"}}>Укажите причину</Text>
        <TextArea
          placeholder="Введите текст"
          value={reason}
          onChange={(e) => {
            setReason(e.target.value)
          }}
          rows={4}
          autoSize={{minRows: 4, maxRows: 8}}
          style={{marginTop: "20px"}}
        />
      </Col>
    </Row>
  </Modal>);
}
