import React, {useContext, useEffect, useState} from "react";
import {notification} from "antd";
import {userServices, deliveriesService} from "../../../../../services";
import Error from "../../../../error";
import Spinner from "../../../../spinner";
import DeliveryItem from "../../../../delivery-item-block";
import DeliveryGroupList from "../../../../delivery-group-list";
import {AppContext} from "../../../../../contexts/app-context";
import {VIEW_FREE_DELIVERIES_CARRIER} from "../../../../../helpers/user_permission.helper";
import UserNotificationMessage from "../../../../user-message";
import NotificationMessage from "../../../../modals/notification-message";
import {useParams} from "react-router-dom";
import ModalCreateOrderReturnDelivery from "../../../../modals/create-order-return-delivery";

export default function ReturnDeliveries() {
  const [user, setUser] = useState(userServices.getUser());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [needUpdate, setNeedUpdate] = useState(false);
  const [deliveries, setDeliveries] = useState([]);
  const [permissions, setPermissions] = useState(false);
  const [chosenDeliveries, setChosenDeliveries] = useState([]);
  const [showModalCreateOrder, setShowModalCreateOrder] = useState(false);
  const [choisenDeliveryId, setChoisenDeliveryId] = useState('');
  const [paymentType, setPaymentType] = useState(null);
  const [paymentTypeReturnDelivery, setPaymentTypeReturnDelivery] = useState(null);
  const {id} = useParams();
  let delivery = [];

  useEffect(() => {
    setLoading(true);
    deliveriesService.getInfoDelivery(id)
      .then(res => {
        delivery.unshift(res);
        setPermissions(res.Permissions);
      })
      .catch(errMsg => {
        setError(true);
        notification.error(
          {
            message: "Получение информации о рейсе",
            description: errMsg
          });
      })
    ;
    deliveriesService.getListReturnDeliveries(id)
      .then(res => {
        delivery = delivery.concat(res.Deliveries)
        setDeliveries(delivery);
        setPermissions(res.Permissions);
        setLoading(false);
        setNeedUpdate(false);
      })
      .catch(errMsg => {
        setError(true);
        notification.error(
          {
            message: "Получение информации об обратных рейсах",
            description: errMsg
          });
      })
    ;
  }, [needUpdate]);

  useEffect(() => {
    if (deliveries && deliveries.length) {
      if (deliveries[0].Prices) {
        const price = deliveries[0].Prices.find(e => e.Price !== null);
        if (price) {
          setPaymentType(price.PaymentType.Id);
        }
      }
      if (deliveries[1].Prices) {
        const price = deliveries[1].Prices.find(e => e.Price !== null);
        if (price) {
          setPaymentTypeReturnDelivery(price.PaymentType.Id);
        }
      }
    }
  }, [deliveries]);

  const setReturnDelivery = (arr) => {
    setChoisenDeliveryId(arr.Id);
    const del = [].concat(deliveries[0]).concat(arr);
    setChosenDeliveries(del);
  }

  const onClickCreateOrderDelivery = (e) => {
    e.preventDefault();
    setShowModalCreateOrder(true);
  };

  const appContext = useContext(AppContext);

  if (appContext.filter.enable) {
    appContext.disableFilter();
  }

  if (!user.isAccessTo(VIEW_FREE_DELIVERIES_CARRIER)) {
    return <Error status={403} desc="Страница не доступна"/>;
  }

  if (error) {
    return <Error type={500} desc={error.message}/>;
  }

  let listItems;

  if (!loading) {
    listItems = deliveries.map((delivery, index) => {
      return (
        <DeliveryItem
          key={delivery.Id}
          delivery={delivery}
          permissions={permissions}
          isReturnDeliveries={true}
          deliveryItemIndex={index}
          deliveriesLength={deliveries.length}
          setReturnDelivery={setReturnDelivery}
          color={index > 0 ? 'dark-blue' : ''}
          choisenDeliveryId={chosenDeliveries.length ? chosenDeliveries[1].Id : ''}
          setChoisenDeliveryId={setChoisenDeliveryId}
          onClickCreateOrderDelivery={onClickCreateOrderDelivery}
        />
      );
    });
  }

  return (
    <>
      <Spinner loading={loading}>
        <UserNotificationMessage/>
        <NotificationMessage setNeedUpdate={false} loading={loading}/>
        {!loading && deliveries.length > 1 ? <DeliveryGroupList deliveriesItems={listItems}/> : null}
      </Spinner>
      <ModalCreateOrderReturnDelivery
        isShow={showModalCreateOrder}
        setIsShow={setShowModalCreateOrder}
        deliveries={chosenDeliveries}
        paymentType={paymentType}
        setPaymentType={setPaymentType}
        paymentTypeReturnDelivery={paymentTypeReturnDelivery}
        setPaymentTypeReturnDelivery={setPaymentTypeReturnDelivery}
        setNeedUpdate={setNeedUpdate}
      />
    </>
  );
}
