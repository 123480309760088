import React, {useState, useEffect, useContext} from 'react';


export default function LogistFaq() {
    return (
        <>
            <p className={"content-header"}>FAQ для логистов</p>
            <p><strong>Как обращаться по проблеме в скайпе, чтобы максимально быстро получить ответ?</strong></p>
            <ol>
                <li><span>Сформулируйте проблему полно и чётко, в которой целиком и конкретна описана проблемная ситуация.</span></li>
                <li><span>Если проблема связана с перевозчиком, укажите его Web ID.</span></li>
                <li><span>Если проблема с пользователем, скажите его логин.</span></li>
                <li><span>Если речь про рейс, укажите номер рейса.</span></li>
            </ol>
            <p><span>Важно: обязательно укажите, какие именно действия вы ожидаете от поддержки.&nbsp;</span></p>
            <p><strong><strong><br /><br /></strong></strong></p>
            <p><strong>В 1С рейс, заявка или ещё что-то не того цвета, какого хотелось бы</strong></p>
            <p><span>Эта проблема связана с задержками обмена между сайтом и 1С. От сайта эти задержки не зависят. Если цвет не меняется дольше 15 минут, обратитесь к программистам 1С. Мы со стороны сайта на это повлиять не можем.</span></p>
            <p><strong><strong><br /><br /></strong></strong></p>
            <p><strong>Как можно отредактировать юридическое лицо?</strong></p>
            <p><span>Юридическое лицо можно редактировать только в следующих случаях:</span></p>
            <ol>
                <li><span>Сразу после добавления, до того как его отправили на согласование.</span></li>
                <li><span>После получения отказа, до повторной отправки на согласование.</span></li>
            </ol>
            <p><span>Если юридическое лицо отправлено на согласование, то до отказа редактировать его нельзя.</span></p>
            <p><span>Если согласование полностью прошло всеми ответственными, то редактирование юридического лица полностью блокируется. И возможно только через обращение в техническую поддержку.</span></p>
            <p><strong><strong><br /><br /></strong></strong></p>
            <p><strong>Как можно проверить поручителя при согласовании?</strong></p>
            <p><span>В 1С не видно данные на поручителя до тех пор, пока не загрузили на согласование договор о поручительстве. Но все эти данные можно посмотреть на сайте:</span></p>
            <ol>
                <li><a target="_blank" href="http://joxi.ru/Q2KyBqtLYNNlrj"><span>http://joxi.ru/Q2KyBqtLYNNlrj</span></a></li>
                <li><a target="_blank" href="http://joxi.ru/KAxK7MHZy33J28"><span>http://joxi.ru/KAxK7MHZy33J28</span></a><span>&nbsp;</span></li>
            </ol>
            <p><span>Если согласовать юридическое лицо до проверки, откатить это согласование можно только через техническую поддержку. Поэтому следует проверить все данные.</span></p>
            <p><span>ВАЖНО: на сайте можно согласовать юридическое лицо и договор. Но делать этого НЕЛЬЗЯ! До распоряжения от руководства Витал.</span></p>
            <p><strong><strong><br /><br /></strong></strong></p>
            <p><strong>Перевозчик получил отказ, но редактировать всё равно не может.</strong></p>
            <p><span>В 99% случаев это связано с тем, что перевозчик по ошибке до редактирования отправляет на согласование повторно.&nbsp;</span></p>
            <p><span>Если вы отказали перевозчику в согласовании, и через некоторое время видите, что он снова отправил вообще ничего не поменяв - знайте, что он это сделал случайно. И больше ничего редактировать не может. Сделайте ему повторный отказ.</span></p>
            <p><strong><strong><br /><br /></strong></strong></p>
            <p><strong>Перевозчик отредактировал юридическое лицо, а в 1С ничего не поменялось.</strong></p>
            <p><span>После редактирования на сайте информация сохраняется только на сайте. Что бы она обновилась в 1С, перевозчик должен нажать кнопку &ldquo;Отправить на согласование&rdquo;.&nbsp;</span></p>
            <p><span>Часто они забывают это сделать, и информация остаётся только на сайте.</span></p>
            <p><strong><strong><br /><br /></strong></strong></p>
            <p><strong>Перевозчик заполнил заявку на рейс, а кнопка скачать документы серая.</strong></p>
            <p><span>Эти документы приходят из 1С. Иногда случаются задержки. Если кнопка серая дольше 15 минут, обратитесь к программистам 1С. Со стороны сайта на этот процесс повлиять нельзя.</span></p>
            <p><strong><strong><br /><br /></strong></strong></p>
            <p><strong>Как можно отредактировать водителя?</strong></p>
            <p><span>Если водитель не делал рейсов, то редактировать его можно свободно через автопарк у перевозчиков.&nbsp;</span></p>
            <p><span>Если водитель делает первый рейс, и по приезду вы обнаружили ошибку в данных, то для редактирования этого водителя нужно открепить от рейса, потом отредактировать, и повторно заполнить заявку.</span></p>
            <p><span>Если водитель уже сделал хотя бы один рейс, который был загружен и уехал из РЦ, редактировать этого водителя больше нельзя. Редактирование делается через обращение в техподдержку.</span></p>
            <p><strong><strong><br /><br /></strong></strong></p>
            <p><strong>Водителю не приходит СМС с логином и паролем.</strong></p>
            <ol>
                <li><span>СМС с логином и паролем приходит в момент нажатия на сайте &ldquo;На погрузку&rdquo;. Ни одно другое действие в 1С или на сайте к отправке СМС не приведёт.</span></li>
                <li><span>Если водитель ездил хотя бы один раз, то на сайте у него уже есть логин и пароль. И повторная СМС не приходит.</span></li>
            </ol>
            <p><span>Если есть сомнения, водитель клянётся, что ездит первый раз, кнопку &ldquo;На погрузку&rdquo; нажали, а СМС нет - проверьте, есть-ли логин у этого водителя.</span></p>
            <p><strong><strong><br /><br /></strong></strong></p>
            <p><strong>Как проверить, есть-ли логин у водителя?</strong></p>
            <p><span>Сделать это можно запросив восстановление пароля на сайте. Эта функция доступна только для неавторизованных пользователей. Чтобы не выходить из своего аккаунта на сайте, есть три варианта:</span></p>
            <ol>
                <li><span>Откройте новое окно в режиме &ldquo;Инкогнито&rdquo; в браузере. В Google Chrome можно нажать Ctrl+Shift+N, в Mozilla Firefox Ctrl+Shift+P. Там вы будете неавторизованы.</span></li>
                <li><span>Запустите другой браузер на компьютере, которым не пользуетесь</span></li>
                <li><span>Откройте сайт на телефоне. Можно своём, если не пользуетесь сайтом с телефона. Можете на телефоне водителя.</span></li>
            </ol>
            <p><span>Далее жмите &ldquo;Войти&rdquo; и потом восстановить пароль </span><a target="_blank" href="http://joxi.ru/gmvd9VFqkpRZAa"><span>http://joxi.ru/gmvd9VFqkpRZAa</span></a><span>&nbsp;</span></p>
            <p><span>Если логин есть, на него придёт СМС с паролем. Если логина нет, будет такая ошибка: </span><a target="_blank" href="http://joxi.ru/EA4qZbCo3dyGrb"><span>http://joxi.ru/EA4qZbCo3dyGrb</span></a><span>&nbsp;</span></p>
            <p><strong><strong><br /><br /></strong></strong></p>
            <p><strong>У водителя поменялся паспорт, что делать?</strong></p>
            <p><span>После замены паспорта (по возрасту, утере или любой другой причине) у него появляется новая серия и номер. Именно по ним и сайт и 1С определяют водителя.&nbsp;</span></p>
            <p><span>Поэтому при смене паспорта нужно добавить нового водителя, заполнив его анкету и загрузив сканы документов.</span></p>
            <p><strong><br /><br /><br /><br /><br /></strong></p>
        </>
    )
}