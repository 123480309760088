import React, {useEffect} from "react";
import {FormatPrice, FullDate, ShortDate} from "../tools/delivery-tools";
import {
  DELIVERY_AWAITING_LOADING,
  DELIVERY_ENROUTE,
  DELIVERY_LOADING,
  DELIVERY_AGREED,
  DELIVERY_BOOKED
} from "../../helpers/delivery_status.helper";
import {userServices} from "../../services";
import moment from "moment/moment";
import {
  UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY, VIEW_FREE_DELIVERIES_LOGIST
} from "../../helpers/user_permission.helper";

export function DeliveryOrderInfo({delivery}) {
  const user = userServices.getUser();

  if (delivery.Status === DELIVERY_AWAITING_LOADING || delivery.Status === DELIVERY_ENROUTE || delivery.Status === DELIVERY_LOADING || delivery.Status === DELIVERY_AGREED
    || (user.info.userType === 'inner' && delivery.Status === DELIVERY_BOOKED && delivery.Order != null)) {

    return (
      <div className="informer-item pricer">
        <div className="h-informer">Информация о заявке</div>
        <div className="b-informer">
          <div className="price-line clrfx">
            <div className="ls">{delivery.Order.PaymentType}</div>
            <FormatPrice price={delivery.Order.Price} showPrice={true} currencySymbol={delivery.Order.PaymentSymbol}/>
          </div>
          <div className="price-line clrfx">
            <div className="ls">Дата загрузки</div>
            <div className="rs">
              <ShortDate date={delivery.Order.Date}/>
              {delivery.hasOwnProperty("LoadingSign") && delivery.LoadingSign !== null && (user.info.userType === 'carrier' || user.isAccessTo(UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY)) ?
                  (<><br/>{delivery.LoadingSign.DateTime.split(' ')[1]}</>) : null
              }
            </div>
          </div>
          {delivery.DriverArrivedTime !== null && user.isAccessTo(VIEW_FREE_DELIVERIES_LOGIST) ?
              <div className="price-line clrfx">
                <div className="ls">Время прибытия водителя</div>
                <div className="rs">
                  {delivery.DriverArrivedTime}
                </div>
              </div> : null
          }
          {delivery.ActualLoadTime !== null && user.isAccessTo(VIEW_FREE_DELIVERIES_LOGIST) ?
              <div className="price-line clrfx">
                <div className="ls">Время прибытия</div>
                <div className="rs">
                  {delivery.ActualLoadTime}
                </div>
              </div> : null
          }
        </div>
      </div>
    );
  }

  return null;
}
