import {httpRequest} from "./http-request";

export const systemService = {
  getCarryingCapacity,
  getCountryList,
  getRegionsList,
  getLocalitiesList,
  getStreetsList,
  getHousesList,
  getBanksList,
  getPassportDivisionsList,
  uploadFile,
  getCurrencyListOnContractCreate,
  getTermsOfUse,
  getCompanyTypesList,
  getPhoneCode,
  getCompanyTypeList
};

async function getCarryingCapacity() {

  return await httpRequest.get(`system/get-carrying-capacity-full-list`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getCountryList() {

  return await httpRequest.get(`system/get-country-list`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getRegionsList(region) {

  return await httpRequest.post(`system/get-regions`, {
    region: region
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getLocalitiesList(locality, parentGuid) {
  return await httpRequest.post(`system/get-localities`, {
    locality: locality,
    parentGuid: parentGuid
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getStreetsList(street, parentGuid) {
  return await httpRequest.post(`system/get-streets`, {
    street: street,
    parentGuid: parentGuid
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getHousesList(houseNumber, parentGuid) {
  return await httpRequest.post(`system/get-houses`, {
    house: houseNumber,
    parentGuid: parentGuid
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getBanksList(bic) {
  return await httpRequest.post(`system/get-banks`, {
    Bic: bic,
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getPassportDivisionsList(divisionCode) {
  return await httpRequest.post(`system/get-passport-divisions`, {
    DivisionCode: divisionCode,
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function uploadFile(url, file, uid, name, sessionId) {
  return await httpRequest.post(url, {
    File: file,
    Uid: uid,
    Name: name,
    SessionId: sessionId
  }, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getCurrencyListOnContractCreate(legalEntityId, shipperCompanyId) {
  return await httpRequest.get(`system/get-currency-types-on-contract-create?LegalEntityId=` + legalEntityId + '&ShipperCompanyId=' + shipperCompanyId)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getTermsOfUse(type, countryId) {
  return await httpRequest.get(`system/get-terms-of-use?type=` + type + '&countryId=' + countryId)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getCompanyTypesList(approveType) {
  return await httpRequest.get(`system/get-company-types?approveType=${approveType}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getCompanyTypeList() {
  return await httpRequest.get(`system/get-company-types`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getPhoneCode(phone, countryID) {
  return await httpRequest.get(`system/get-phone-code?phone=${phone ? phone : 0}&countryID=${countryID ? countryID : 0}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}
