import React, {useEffect, useState} from "react";
import NewsCard from "../news-card";
import {questService} from "../../../services/guest.services";
import {notification} from "antd";
import ModalNews from "../news-modal";

export default function NewsList({news}) {
  let newsItem =[];
  let events = null;

  if (news.length > 0) {
    newsItem = news.filter((item) => item.category === 'news');
    events = news.find((item) => item.category === 'event');
  }

  if (news.length > 0) {
    return <>
      <section className="blog">
        <img src="./img/news-decor.svg" alt="" className="blog__decor blog__decor1"/>
        <img src="./img/news-decor2.svg" alt="" className="blog__decor blog__decor2"/>
        <div className="container">
          <div className="blog__inner">
            <div className="news">
              <div className="news__inner">
                <h2 className="news__title display2">
                  Новости
                </h2>
                <div className="news__grid">
                  {newsItem.map((el) => {
                    return <NewsCard el={el} key={`news-${el.id}`}/>
                  })}
                </div>
                <div className="blog__nav">
                  <a href="" className="blog__btn m-btn m-btn-primary m-btn-triangle-right">
                      <span className="text16 bold-weight">
                          Все новости
                      </span>
                  </a>
                </div>
                <img src="./img/news-decor3.svg" alt="" className="blog__decor blog__decor3"/>
              </div>
            </div>
            <div className="events">
              <div className="events__inner">
                <h2 className="events__title display2">
                  Происшествия
                </h2>
                <div className="events__grid">
                  {(events !== null) && <NewsCard el={events}/>}
                </div>
                <div className="blog__nav">
                  <a href="" className="blog__btn m-btn m-btn-primary m-btn-triangle-right">
                      <span className="text16 bold-weight">
                          Все проишествия
                      </span>
                  </a>
                </div>
                <img src="./img/news-decor4.svg" alt="" className="blog__decor blog__decor3"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      {newsItem && newsItem.length > 0 && newsItem.map((el) => {
        return <ModalNews newsData={el} key={`news-detail-${el.id}`}/>
      })}
      {events !== null &&
        <ModalNews newsData={events} key={`events-detail`}/>
      }
    </>
  } else {
    return null;
  }
}
