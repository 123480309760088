import React, {useState} from "react";
import {Button, Row, Col, Modal, notification} from "antd";
import NumberInputField from "../../number-input-field";
import Spinner from "../../spinner";
import {distributionCenterService} from "../../../services";
import InputField from "../../input-field";
import '../modals.css'


export default function ModalEditDistributionCenter({
                                                      isShow,
                                                      onCloseModal,
                                                      id,
                                                      name,
                                                      delayDelivDispatcher: currentDelayDelivDispatcher,
                                                      delayReserveDeliv: currentDelayReserveDeliv,
                                                      maxCountLoadAuto: currentMaxCountLoadAuto,
                                                      startFillingOrder: currentStartFillingOrder,
                                                      fillingOrder: currentFillingOrder,
                                                      downloadDocument: currentDownloadDocument,
                                                      closingTime: currentClosingTime,
                                                      minOrdersToApprove: currentMinOrdersToApprove,
                                                      minHoursToRefuse: currentMinHoursToRefuse,
                                                      cargoCollectTime: currentCargoCollectTime,
                                                      importantDeliveryMaxTimeLoad: impDeliveryMaxTimeLoad,
                                                      countSkipAvailableTimesNonImportantDelivery: countSkipAvailableTimesNonImpDelivery,
                                                      maxInnerDistance: currentMaxInnerDistance,
                                                    }) {

  const [loadingContentModal, setLoadingContentModal] = useState(false);
  const [fieldsErrors, setFieldsErrors] = useState(null);

  const [delayDelivDispatcher, setDelayDelivDispatcher] = useState(currentDelayDelivDispatcher);
  const [delayReserveDeliv, setDelayReserveDeliv] = useState(currentDelayReserveDeliv);
  const [maxCountLoadAuto, setMaxCountLoadAuto] = useState(currentMaxCountLoadAuto);
  const [startFillingOrder, setStartFillingOrder] = useState(currentStartFillingOrder);
  const [fillingOrder, setFillingOrder] = useState(currentFillingOrder);
  const [downloadDocument, setDownloadDocument] = useState(currentDownloadDocument);
  const [closingTime, setClosingTime] = useState(currentClosingTime);
  const [minOrdersToApprove, setMinOrdersToApprove] = useState(currentMinOrdersToApprove);
  const [minHoursToRefuse, setMinHoursToRefuse] = useState(currentMinHoursToRefuse);
  const [cargoCollectTime, setCargoCollectTime] = useState(currentCargoCollectTime);
  const [maxInnerDistance, setMaxInnerDistance] = useState(currentMaxInnerDistance);
  const [importantDeliveryMaxTimeLoad, setImportantDeliveryMaxTimeLoad] = useState(impDeliveryMaxTimeLoad);
  const [countSkipAvailableTimesNonImportantDelivery, setCountSkipAvailableTimesNonImportantDelivery] = useState(countSkipAvailableTimesNonImpDelivery);

  let bigButtonStyle = {
    width: '200px',
    height: '43px',
    background: '#1CAA53',
    position: 'static',
    borderRadius: '10px'
  };

  // сохранение новых данных о РЦ
  const onClickEdit = (e) => {
    e.preventDefault();
    setLoadingContentModal(true);

    distributionCenterService.edit(
        id,
        delayDelivDispatcher,
        delayReserveDeliv,
        maxCountLoadAuto,
        startFillingOrder,
        fillingOrder,
        downloadDocument,
        closingTime,
        minOrdersToApprove,
        minHoursToRefuse,
        cargoCollectTime,
        importantDeliveryMaxTimeLoad,
        countSkipAvailableTimesNonImportantDelivery,
        maxInnerDistance,
    )
        .then((response) => {

          setLoadingContentModal(false);

          if (response.status === "success") {
            notification.success({message: "Редактирование РЦ", description: response.message});
            onCloseModal();

          } else if (response.status === "error") {
            setFieldsErrors(response.errors);
          }
        })
        .catch((error) => {
          notification.error({message: "Редактирование РЦ", description: error});
          setLoadingContentModal(false);
        });
  };

  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
        ? fieldsErrors[nameField][0]
        : null;
  };

  return (
      <Modal
          title={<span>Редактирование РЦ «{name}»</span>}
          cancelText="Закрыть"
          className="modal-window"
          align={null}
          visible={isShow}
          onCancel={onCloseModal}
          footer={[
            <Button htmlType="submit" key="submit" type="primary" style={bigButtonStyle}
                    onClick={onClickEdit}>Сохранить</Button>,
          ]}
      >
        <Spinner loading={loadingContentModal} label={null}>
          <Row gutter={[16, 24]}>
            <Col lg={12} md={24} sm={24} xs={24}>
              <NumberInputField
                  name="delayDelivDispatcher" label="Задержка отображения рейсов для диспетчера, в минутах"
                  value={delayDelivDispatcher} setValue={setDelayDelivDispatcher}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <NumberInputField
                  name="delayReserveDeliv" label="Отсрочка резервирование рейса, в минутах"
                  value={delayReserveDeliv} setValue={setDelayReserveDeliv}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col lg={12} md={24} sm={24} xs={24}>
              <NumberInputField
                  name="maxCountLoadAuto" label="Максимальное количество погрузок авто"
                  value={maxCountLoadAuto} setValue={setMaxCountLoadAuto}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <NumberInputField
                  name="startFillingOrder" label="Начало заполнения заявки, мин"
                  value={startFillingOrder} setValue={setStartFillingOrder}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col lg={12} md={24} sm={24} xs={24}>
              <NumberInputField
                  name="fillingOrder" label="Заполнение заявки, мин"
                  value={fillingOrder} setValue={setFillingOrder}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <NumberInputField
                  name="downloadDocument" label="Загрузка документов, мин"
                  value={downloadDocument} setValue={setDownloadDocument}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col lg={12} md={24} sm={24} xs={24}>
              <NumberInputField
                  name="minOrdersToApprove" label="Минимальное кол-во заказов, для утверждения без специальных прав"
                  value={minOrdersToApprove} setValue={setMinOrdersToApprove}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <NumberInputField
                  name="minHoursToRefuse" label="Минимальное кол-во часов, по прошестивю которых можно отменить заказ"
                  value={minHoursToRefuse} setValue={setMinHoursToRefuse}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col lg={12} md={24} sm={24} xs={24}>
              <InputField
                  name="closingTime" label="Время закрытия(по МСК) Формат - 16:00:00"
                  value={closingTime} setValue={setClosingTime}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <NumberInputField
                  name="cargoCollectTime" label="Время сбора заказа (минуты)"
                  value={cargoCollectTime} setValue={setCargoCollectTime}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col lg={12} md={24} sm={24} xs={24}>
              <InputField
                  name="ImportantDeliveryMaxTimeLoad" label="Максимальное время приоритетных рейсов. Формат - 16:00"
                  value={importantDeliveryMaxTimeLoad} setValue={setImportantDeliveryMaxTimeLoad}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <NumberInputField
                  name="countSkipAvailableTimesNonImportantDelivery" label="Кол-во пропусков времени на запись не приоритетных рейсов"
                  value={countSkipAvailableTimesNonImportantDelivery} setValue={setCountSkipAvailableTimesNonImportantDelivery}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col lg={12} md={24} sm={24} xs={24}>
              <NumberInputField
                  name="maxInnerDistance" label="Расстояние для внутренних рейсов"
                  value={maxInnerDistance} setValue={setMaxInnerDistance}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
        </Spinner>
      </Modal>
  );
}
