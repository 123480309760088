import React, {useEffect, useState} from 'react';
import Spinner from "../../../spinner";
import {Button, Col, DatePicker, Table, Row, Icon} from "antd";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import {reportService} from "../../../../services/report.services";
import moment from "moment";
import SelectField from "../../../select-field";
import {distributionCenterService} from "../../../../services";


export default function ReportStatsLogistsResponseToOrders() {

  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  // параметры для построения отчета
  const [dateStart, setDateStart] = useState(moment().startOf('month'));
  const [dateEnd, setDateEnd] = useState(moment().endOf('month'));
  // полученные данные отчета
  const [reportData, setReportData] = useState([]);

  const [distCenters, setDistCenters] = useState([]);
  const [selectedDistCetners, setSelectedDistCenters] = useState([]);

  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateReport(true);
    }

    // Получаем список всех РЦ
    if (!companiesLoaded) {
      distributionCenterService
        .getCompanies()
        .then((response) => {
          setDistCenters(response);
          setCompaniesLoaded(true);
        })
        .catch((errorMsg) => {
          setErrorMsg(errorMsg);
          setCompaniesLoaded(true);
        });
    }

    if (isNeedUpdateReport) {
      // запрос на получение данных отчета
      reportService
        .getStatsLogistsResponseToOrders(
          dateStart.format("YYYY-MM-D"),
          dateEnd.format("YYYY-MM-D"),
          selectedDistCetners
        )
        .then((response) => {
          setReportData([]);
          let tableData = [];
          // response.map((item) => {
          //     if (!tableData[item.DistributionCenter]) {
          //         tableData[item.DistributionCenter] = {
          //             distributionCenter: item.DistributionCenter,
          //             children: [{
          //                 id: item.DeliveryNumber,
          //                 distributionCenter: item.DeliveryNumber,
          //                 children: []
          //             }]
          //         };
          //     } else {
          //         tableData[item.DistributionCenter].children.push({
          //             id: item.DeliveryNumber,
          //             distributionCenter: item.DeliveryNumber,
          //             children: [item.Orders]
          //         });
          //     }
          //     return null;
          // });

          response.map((item) => {
            tableData.push(item);
            return null;
          });

          let reindexedTableData = [];
          for (let key in tableData) {
            reindexedTableData.push(tableData[key]);
          }

          setIsNeedUpdateReport(false);
          setLoading(false);
          setReportData(reindexedTableData);
        })
        .catch((errorMsg) => {
          setIsNeedUpdateReport(false);
          setLoading(false);
          setErrorMsg(errorMsg);
        });
    }
  }, [isNeedUpdateReport]);

  const getFieldErrors = (nameField) => {

    return null;
  };

  // отображение фатальной ошибки
  if (errorMsg) {
    return (<div className="content-error-block">{errorMsg}</div>);
  }

  // список столбцов таблицы
  const columns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      width: '35%',
    },
    {
      title: '',
      dataIndex: 'column1',
      key: 'column1',
      width: '10%',
    },
    {
      title: '',
      dataIndex: 'column2',
      key: 'column2',
      width: '10%'
    },
    {
      title: '',
      dataIndex: 'column3',
      key: 'column3',
      width: '10%'
    },
    {
      title: '',
      dataIndex: 'column4',
      key: 'column4',
      width: '10%'
    },
    {
      title: '',
      dataIndex: 'column5',
      key: 'column5',
      width: '10%'
    }
  ];

  return (
    <React.Fragment>
      <div className="reports__header">
        <h1 className="content-header">Отчет «Статистика по времени отклика логистов на заявки»</h1>
        <Icon type='filter'
              theme={isFilterOpen ? '' : "filled"}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen)
              }}
              className="reports__filter-toggle"/>
        {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
        <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start">
          <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
            <DatePicker
              style={{width: "100%"}}
              defaultValue={dateStart}
              format="DD.MM.YYYY"
              size="normal"
              name="dateStart"
              placeholder="Выберите дату начала периода"
              locale={localeDatePicker}
              allowClear={false}
              onChange={(date) => {
                setDateStart(date);
              }}
            />
          </Col>
          <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
            <DatePicker
              style={{width: "100%"}}
              defaultValue={dateEnd}
              format="DD.MM.YYYY"
              size="normal"
              name="dateEnd"
              placeholder="Выберите дату окончания периода"
              locale={localeDatePicker}
              allowClear={false}
              onChange={(date) => {
                setDateEnd(date);
              }}
            />
          </Col>
          <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
            <SelectField
              name="distributionCenters"
              selectedValue={selectedDistCetners}
              values={distCenters}
              onChange={(value) => {
                setSelectedDistCenters(value);
              }}
              notFoundText="РЦ отсутствуют"
              getErrors={getFieldErrors}
              style={{width: "100%"}}
              mode={'multiple'}
            />
          </Col>
          <Col lg={6} md={6} sm={12} xs={24} style={{textAlign: 'right', height: '40px'}}>
            <Button type="primary"
                    htmlType="button"
                    onClick={() => {
                      setLoading(true);
                      setIsNeedUpdateReport(true);
                    }}
                    style={{backgroundColor: '#1CAA53', border: 'none', height: '100%'}}
            >
              Применить
            </Button>
          </Col>
        </Row>
        </div>}
      </div>
      <div className="reports__table">
        <div className="reports__table-wrapper">
          <Col span={24}>
            <Table
              style={{minWidth: '700px'}}
              columns={columns}
              dataSource={reportData}
              locale={{emptyText: "Данные отсутствуют"}}
              rowKey="key"
              size="small"
              loading={loading}
              pagination={{
                pageSize: 50
              }}
            />
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
}
