import React, {useState, useEffect} from 'react';
import {notification, Button, Table, Icon, Popconfirm, Col, Row, Select} from 'antd';
import Spinner from '../../../spinner';
import {companyService, consignorService} from '../../../../services';
import {approveOrderService} from "../../../../services/approve-order.service";
import ModalGetOrderInfo from '../../../modals/get-order-info-consignor';
import './style.css';
import ModalRefuseOrder from "../../../modals/refuse-order";
import ModalConsignorUploadSignedContracts from "../../../modals/consignor-upload-signed-contracts";
import ModalLegalEntityFullInfo from "../../../modals/legal-entity-full-info";
import InputField from "../../../input-field";
import ModalDeleteContractOrder from "../../../modals/delete-contract-order";


export default function Orders({consignorSettings}) {
  const {Option} = Select;
  const [needUpdate, setNeedUpdate] = useState(true);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState(null);

  const [isShowInfo, setShowInfo] = useState(false);

  const [isShowUploadContractsModal, setIsShowUploadContractsModal] = useState(false);

  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [currentLegalEntityOrgFormId, setCurrentLegalEntityOrgFormId] = useState(null);
  const [currentLegalEntityId, setCurrentLegalEntityId] = useState(null);
  const [showLegalEntityFullInfoModal, setShowLegalEntityFullInfoModal] = useState(false);
  const [showDeleteContractOrderModal, setShowDeleteContractOrderModal] = useState(null);
  const [pagination, setPagination] = useState({
    defaultPageSize: 10,
    pageSize: 10,
    position: "both"
  });

  const [fieldsErrors, setFieldsErrors] = useState({});

  const [listTypes, setListTypes] = useState([
    {
      Id: 1,
      Name: "Новые",
    },
    {
      Id: 2,
      Name: "Согласованные",
    },
  ]);
  const [isFilterParamsSet, setIsFilterParamsSet] = useState(false);
  const [shipperCompanies, setShipperCompanies] = useState([]);
  const [filterParams, setFilterParams] = useState({
    listType: 1,
    legalNumber: '',
    legalEntityName: '',
    webid: '',
    limit: 10,
    offset: 0,
    agreedContractByShipperCompanies: []
  });

  const getShipperCompanyList = () => {
    companyService.getShipperCompanyList()
      .then(resp => {
        setShipperCompanies(resp);
      })
      .catch((err) => {
        notification.error({
          message: "Получение компаний грузоотправителя",
          description: err,
        });
      });
  };

  const getOrdersList = (filterParams) => {
    setLoading(true);

    consignorService
      .getOrdersList(filterParams.listType, filterParams.legalNumber, filterParams.legalEntityName, filterParams.webid, filterParams.limit, filterParams.offset, filterParams.agreedContractByShipperCompanies)
      .then(resp => {
        const usersData = resp.ordersList.map((item) => {
          return {
            id: item.Id,
            status: item.Status,
            dateCreated: item.DateCreated,
            shipperCompanyId: item.ShipperCompany.Id,
            shipperCompanyName: item.ShipperCompany.Name,
            legalEntityId: item.LegalEntity.Id,
            legalEntityName: item.LegalEntity.Name,
            legalEntityOrgFormId: item.LegalEntity.OrgFormId,
            contracts: item.Contracts
          }
        })

        setData(usersData);

        setPagination({
          ...pagination,
          total: resp.totalCount
        });

        setLoading(false);
        setNeedUpdate(false);
      })
      .catch(errMsg => notification.error(
        {
          message: "Получение списка заявок",
          description: errMsg
        }));
  }

  useEffect(() => {

    if (needUpdate) {
      getShipperCompanyList();

      if (!isFilterParamsSet) {
        getOrdersList({...filterParams, limit: pagination.pageSize, offset: 0});
      }
      if (isFilterParamsSet) {
        getOrdersList({...filterParams});
      }
    }
  }, [needUpdate]);

  useEffect(() => {
    if (!isFilterParamsSet) {
      setIsFilterParamsSet(true)
    }
  }, [filterParams])

  const handleDeleteContractOrder = (record) => {
    approveOrderService.getDeleteContractOrder(record.id)
      .then(resp => {
        setNeedUpdate(true);
        notification.error({
          message: "Удаление договора",
          description: resp.message
        });
      })
      .catch(err => {
        notification.error({
          message: "Удаление договора",
          description: err
        });
      });
  };

  const handleAgree = record => {
    if (consignorSettings.isAutoAgreeContracts) {
      setLoading(true);

      consignorService.agreeOrder(record.id, null, null, 0)
        .then(resp => {
          if (resp.code === 200) {
            notification.success({
              message: 'Согласование договора',
              description: resp.message
            });

            setNeedUpdate(true);
          } else {
            notification.error({
              message: 'Согласование договора',
              description: resp.message
            });

            setLoading(false);
          }
        })
        .catch(errMsg => {
          notification.error({
            message: 'Согласование договора',
            description: errMsg
          });

          setLoading(false);
        });
    } else {
      setCurrentOrderId(record.id);
      setIsShowUploadContractsModal(true);
      setCurrentLegalEntityOrgFormId(record.legalEntityOrgFormId);
    }
  };

  const handleRefuse = record => {
    setCurrentOrderId(record.id);
    setShowInfo(true);
  };

  const getFieldErrors = (fieldName) => {
    return typeof fieldsErrors[fieldName] !== "undefined"
      ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
        return fieldErrors + "; " + error;
      })
      : null;
  };

  const handleDownloadDoc = fileUrl => {
    window.open(fileUrl);
  };

  const renderButtonsAgree = record => {
    let buttons = [];

    if (record.status.Id === 1) {
      buttons.push(
        <>
          <Popconfirm
            title={`Вы уверены?`}
            okText="Да"
            cancelText="Нет"
            placement="topRight"
            onConfirm={() => handleAgree(record)}
            icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}
          >
            <Button size="small" style={{marginLeft: "0px", width: "100%"}} className="small-button typical-btn-green">
              Согласовать
            </Button>
          </Popconfirm>
          <Button style={{marginLeft: "0px", width: "100%"}} key={`button2-${record.Id}`}
                  onClick={() => handleRefuse(record)} size="small"
                  className="small-button typical-btn-red">
            Отклонить
          </Button>
          <Popconfirm
            title={`Вы уверены?`}
            okText="Да"
            cancelText="Нет"
            placement="topRight"
            onConfirm={() => handleDeleteContractOrder(record)}
            icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}
          >
            <Button size="small" style={{marginLeft: "0px", width: "100%"}} className="small-button typical-btn-red">
              Удалить
            </Button>
          </Popconfirm>
        </>
      );
    }

    return (
      <>
        {buttons}
      </>
    );
  };

  const renderButtonsDownload = record => {
    let buttons = [];

    if (record.status.Id === 2 || record.status.Id === 1) {
      Object.keys(record.contracts).forEach(value => {
        value = record.contracts[value];
        buttons.push(
          <>
            <Button style={{marginLeft: "0px", width: "100%"}} onClick={() => handleDownloadDoc(value.FilledFile)}
                    size="small" className="small-button typical-btn-green">
              Скачать "{value.Alias}"
            </Button>
            <Button style={{marginLeft: "0px", width: "100%"}} onClick={() => handleDownloadDoc(value.BlankFile)}
                    size="small" className="small-button typical-btn-blue">
              Скачать оригинал "{value.Alias}"
            </Button>
          </>
        );
      });
    }
    if (record.status.Id === 2) {
      buttons.push(
        <>
          <Button style={{marginLeft: "0px", width: "100%"}}
                  size="small"
                  className="small-button typical-btn-blue"
                  onClick={() => {
                    setShowDeleteContractOrderModal(record.id);
                  }}>
            Удалить договор
          </Button>
          <ModalDeleteContractOrder
            id={record.id}
            isShow={record.id === showDeleteContractOrderModal}
            setIsShow={() => {setShowDeleteContractOrderModal(null);}}
            setNeedUpdate={setNeedUpdate}
          />
        </>
      )
    }

    return (
      <>
        {buttons}
      </>
    );
  };

  const showLegalEntityInfoModalAction = (legalEntityId) => {
    setCurrentLegalEntityId(legalEntityId);
    setShowLegalEntityFullInfoModal(true);
  };

  const onTableChange = (pagination, filters, sorter) => {
    setPagination({
      ...pagination
    });

    const params = {
      ...filterParams,
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
    };

    setFilterParams(params);
    setNeedUpdate(true);
  }

  const handleClearButtonClick = () => {
    setFilterParams({
      limit: 10,
      offset: 0,
      listType: 1,
      legalNumber: '',
      legalEntityName: '',
      webid: '',
      agreedContractByShipperCompanies: [],
    });
    setNeedUpdate(true);
  }

  const columns = [
    {
      title: "WebID",
      key: "id",
      dataIndex: "id",
    },
    {
      title: "Юр. лицо перевозчика",
      key: "legalEntityName",
      dataIndex: "legalEntityName",
    },
    {
      title: "Юр. лицо",
      key: "shipperCompanyName",
      dataIndex: "shipperCompanyName",
    },
    {
      title: "Создана",
      dataIndex: "dateCreated",
      key: "dateCreated",
    },
    {
      title: null,
      key: "actions",
      render: (record) => {
        return renderButtonsAgree(record)
      }
    },
    {
      title: null,
      key: "actions2",
      render: (record) => {
        return renderButtonsDownload(record)
      }
    },
    {
      title: null, key: "info", render: (record) => {
        return (
          <React.Fragment>
            <Button style={{marginLeft: "0px"}} onClick={() => showLegalEntityInfoModalAction(record.legalEntityId)}
                    size="small" className="small-button typical-btn-blue">Подробнее</Button>
          </React.Fragment>
        );
      }
    },
  ];

  return (
    <Spinner loading={loading} tip="Загрузка...">
      <Row gutter={16}>
        <Col lg={8} sm={24} style={{paddingTop: "10px"}}>
          <>
            <span className="form-field-label">Статус</span>
            <Select
              size="default"
              defaultValue={filterParams.listType}
              value={filterParams.listType}
              showArrow={true}
              style={{width: "100%", marginTop: "-10px"}}
              optionFilterProp="children"
              selectedValue={filterParams.listType}
              values={listTypes}
              onChange={(value) => {
                setFilterParams({...filterParams, listType: value});
              }}
            >
              {listTypes.map((item) => {
                return (
                  <Option key={item.Id} value={item.Id}>
                    {item.Name}
                  </Option>
                );
              })}
            </Select>
          </>
        </Col>
        <Col sm={24} lg={8} style={{paddingTop: "10px"}}>
          {shipperCompanies && shipperCompanies.length && <>
            <span className="form-field-label">Организации</span>
            <Select
              mode="multiple"
              size="default"
              defaultValue={filterParams.agreedContractByShipperCompanies}
              value={filterParams.agreedContractByShipperCompanies}
              style={{width: "100%", marginTop: "-10px"}}
              showArrow={true}
              optionFilterProp="children"
              placeholder="Выберите организацию"
              onChange={(value) => {
                setFilterParams({...filterParams, agreedContractByShipperCompanies: value});
              }}
            >
              {shipperCompanies.map((item) => {
                return (
                  <Option key={item.id} value={item.name}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </>
          }
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={8} md={24} sm={24} xs={24} style={{paddingTop: "10px"}}>
          <InputField
            name="legalEntityName"
            label="Наименование юр.лица"
            value={filterParams.legalEntityName}
            setValue={(value) => {
              setFilterParams({...filterParams, legalEntityName: value})
            }}
            getFieldErrors={() => {
            }}
          />
        </Col>
        <Col lg={8} md={24} sm={24} xs={24} style={{paddingTop: "10px"}}>
          <InputField
            name="legalNumber"
            label="ИНН"
            value={filterParams.legalNumber}
            setValue={(value) => {
              setFilterParams({...filterParams, legalNumber: value})
            }}
            getFieldErrors={() => {
            }}
          />
        </Col>
        <Col lg={8} md={24} sm={24} xs={24} style={{paddingTop: "10px"}}>
          <InputField
            name="webid"
            label="id в базе данных"
            value={filterParams.webid}
            setValue={(value) => {
              setFilterParams({...filterParams, webid: value})
            }}
            getFieldErrors={() => {
            }}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{paddingTop: "10px"}}>
          <Button type="primary"
                  htmlType="button"
                  onClick={() => {
                    setPagination({
                      ...pagination,
                      current: 1,
                    })
                    setFilterParams(
                      {
                        ...filterParams,
                        offset: 0
                      }
                    )
                    setNeedUpdate(true);
                  }}
                  style={{backgroundColor: '#1CAA53', border: 'none', height: '40px', width: '100%'}}
          >
            Применить
          </Button>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={3} style={{paddingTop: "10px"}}>
          <Button type="primary"
                  htmlType="button"
                  icon="close"
                  onClick={handleClearButtonClick}
                  style={{backgroundColor: '#BE1C1C', border: 'none', height: '40px', width: '100%'}}
          />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={data}
        locale={{emptyText: 'Данные отсутствуют'}}
        rowKey="id"
        size="small"
        pagination={pagination}
        onChange={onTableChange}
      />
      <ModalRefuseOrder isShow={isShowInfo} setIsShow={setShowInfo} orderId={currentOrderId}
                        setIsNeedUpdate={setNeedUpdate}/>
      <ModalConsignorUploadSignedContracts
        isShow={isShowUploadContractsModal}
        setIsShow={setIsShowUploadContractsModal}
        orgFormId={currentLegalEntityOrgFormId}
        orderId={currentOrderId}
        setNeedUpdate={setNeedUpdate}
      />
      <ModalLegalEntityFullInfo isShow={showLegalEntityFullInfoModal} setIsShow={setShowLegalEntityFullInfoModal}
                                legalEntityId={currentLegalEntityId}/>
    </Spinner>
  );
}
