import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import {notification, Pagination} from "antd";
import {userServices, deliveriesService, driverService} from "../../../../../services";
import {useFetchDeliveriesList} from "../../../../../hooks/use-fetch-deliveries-list";
import Error from "../../../../error";
import Spinner from "../../../../spinner";
import DeliveryItem from "../../../../delivery-item-block";
import LogistFreeContentItem from "../../../../delivery-item-content/logist/free-content-item";
import DeliveryEmptyContent from "../../../../delivery-empty-content";
import {AppContext} from "../../../../../contexts/app-context";
import {VIEW_BOOKED_DELIVERIES_LOGIST} from "../../../../../helpers/user_permission.helper";
import UserNotificationMessage from "../../../../user-message";
import NotificationMessage from "../../../../modals/notification-message";
import Price from "../../../../price";
import {Map, YMaps} from "react-yandex-maps";
import {mapHelper} from "../../../../../helpers";
import DeliveryGroupList from "../../../../delivery-group-list";
import {deliveriesConfig} from "../../../../../config";

function HeaderEnRoute({
                         delivery,
                         dateShipmentRus,
                         setLoadingDelivItem,
                         setNeedUpdate,
                         nameRegionLastPointUnload,
                         regionLoad
                       }) {
  const onClickAddToArchive = (e) => {
    e.preventDefault();
    setLoadingDelivItem(true);

    deliveriesService
      .archive(delivery.Id)
      .then((response) => {
        notification.success({
          message: "Добавление в архив",
          description: response.message,
        });

        setLoadingDelivItem(false);
        setNeedUpdate(true);
      })
      .catch((error) => {
        notification.error({
          message: "Добавление в архив",
          description: error,
        });

        setLoadingDelivItem(false);
        setNeedUpdate(true);
      });
  };

  return (
    <div className="application-topline">
      <div className="application-topline-logist">
        <div className="application-topline-logist-item">
          <div className="application-topline-carrier-item-col application-topline-carrier-item-col-city">
            <p className="typical-icon-field icon-field-patch" title={regionLoad}>
              {regionLoad}
            </p>
            <p className="typical-icon-field icon-field-dispatch" title={nameRegionLastPointUnload}>
              {nameRegionLastPointUnload}
            </p>
          </div>
          <div className="application-topline-logist-item-col application-topline-logist-item-col-date">
            <div className="typical-icon-field icon-field-calendar">{dateShipmentRus}</div>
          </div>
        </div>
        <div className="application-topline-logist-item">
          <div className="application-topline-logist-item-col application-topline-logist-item-col-price">
            <Price delivery={delivery}/>
          </div>
          <div className="application-topline-logist-item-col">
            <button className="btn typical-btn typical-btn-blue typical-btn-l" onClick={onClickAddToArchive}>
              В архив
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function LogistEnRouteDeliveries() {
  const user = userServices.getUser();
  const [step, setStep] = useState(deliveriesConfig.pageCount);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [mapDataLoaded, setMapDataLoaded] = useState(false);
  const [mapData, setMapData] = useState([]);

  const appContext = useContext(AppContext);

  useEffect(() => {
    appContext.initFilter();
  }, []);

  const [{
    error,
    loading,
    data: deliveries,
    setStopListUpdate,
    setNeedUpdate,
    notifications,
    permissions,
    totalCount
  }] = useFetchDeliveriesList(
    "delivery/get-en-route",
    true,
    false,
    true,
    true,
    deliveriesConfig.secondForUpdate,
    offset,
    step
  );


  useEffect(() => {
    if (!loading) {
      driverService
        .getActiveDrivers({
          load: appContext.filter.selectedRegionsLoading,
          unLoad: appContext.filter.selectedRegionsUnloading
        })
        .then((response) => {
          setMapData(response);

          setMapDataLoaded(true);
        })
        .catch((err) => {
          notification.error({
            message: "Получение информации о водителях",
            description: err,
          });
        });
    }
  }, [loading]);

  let onChange = (page, pageSize) => {
    if (page === currentPage) {
      return false;
    } else {
      setCurrentPage(page);
    }

    let val = 0;
    if (parseInt(page) === 1) {
      setOffset(0);
    } else {
      val = page * pageSize - pageSize;
      setOffset(val);
    }
    appContext.filter.offset = val;
    setNeedUpdate(true);
  };

  if (!user.isAccessTo(VIEW_BOOKED_DELIVERIES_LOGIST)) {
    return <Error status={403} desc="Страница не доступна"/>;
  }

  if (error) {
    return <Error type={500} desc={error.message}/>;
  }

  let listItems = [];

  if (!loading) {
    listItems = deliveries.map((delivery) => {
      const dateShipmentRus = moment(delivery.DateShipment).format("D MMMM YYYY");

      return (
        <DeliveryItem
          key={`${delivery.Id}_${delivery.Order.Status}`}
          header={HeaderEnRoute}
          content={LogistFreeContentItem}
          delivery={delivery}
          dateShipmentRus={dateShipmentRus}
          setNeedUpdate={setNeedUpdate}
          setStopListUpdate={setStopListUpdate}
          permissions={permissions}
        />
      );
    });
  }

  let placeMarkers = [];
  if (mapDataLoaded) {
    placeMarkers = mapHelper.placemarkersFactory(mapData || []);
  }

  return (
    <>
      <YMaps
        query={{
          ns: "use-load-option",
          load: "Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon",
          style: {
            width: "1000",
          },
        }}
      >
        <div style={{margin: "0 auto 20px auto", maxWidth: "100%"}}>
          <Map
            defaultState={{
              center: [63.791765, 98.121231],
              zoom: 3,
              controls: ["zoomControl", "fullscreenControl"],
            }}
            width={"100%"}
            height={"290px"}
          >
            {mapDataLoaded && placeMarkers}
          </Map>
        </div>
      </YMaps>
      <Spinner loading={loading}>
        <UserNotificationMessage/>
        <NotificationMessage notifications={notifications} setNeedUpdate={false} setStopListUpdate={setStopListUpdate}
                             loading={loading}/>
        <div className="application-block pagination pagination__up">
          <Pagination
            style={{textAlign: "center"}}
            current={currentPage}
            pageSize={step}
            total={totalCount}
            showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
            onChange={onChange}
          />
        </div>
        {!loading ? deliveries.length ? <DeliveryGroupList deliveriesItems={listItems}/> :
          <DeliveryEmptyContent/> : null}
        {totalCount !== 0 ?
          <div className="application-block pagination pagination__down">
            <Pagination
              style={{textAlign: "center"}}
              current={currentPage}
              pageSize={step}
              total={totalCount}
              showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
              onChange={onChange}
            />
          </div>
          : null}
      </Spinner>
    </>
  );
}
