import React, {useEffect, useState} from "react";
import ModalNeedApprovedLegalEntity from "../modals/need-approved-legal-entity";
import ModalCreateOrder from "../modals/create-order-delivery";
import {deliveriesService, orderDeliveryService, userServices} from "../../services";
import {Button, notification, Popconfirm, Upload} from "antd";
import {FormatPrice, FullDate, ShortDate} from "./delivery-tools";
import ModalAttachDriverOrderDelivery from "../modals/attach-driver-order-delivery";
import moment from "moment";
import {
  DELIVERY_AWAITING_LOADING,
  DELIVERY_BOOKED,
  DELIVERY_ENROUTE,
  DELIVERY_ORDER_FILLED,
  DELIVERY_LOADING, DELIVERY_AGREED, DELIVERY_ORDER_AWAITING_AGREED, DELIVERY_ORDER_APPROVED, DELIVERY_ORDER_AGREED
} from "../../helpers/delivery_status.helper";
import ModalChooseLoadingGate from "../modals/choose-time-loading";
import ModalShowOrderDocs from "../modals/order-docs";
import ModalChangeLoadingDate from "../modals/change-loading-date";
import ModalReturnToEnRoute from "../modals/return-to-en-route";
import ModalReturnLoadingDelivery from "../modals/return-loading-delivery";


export function CarrierHeaderButton({
                                      delivery,
                                      permissions,
                                      setNeedUpdate,
                                      setStopListUpdate,
                                      setLoadingDelivItem,
                                      timerEnabled
                                    }) {
  const user = userServices.getUser();

  // if(user.info.userType != "carrier") {
  //     return null;
  // }
  //
  if (typeof (delivery.Permissions) == "undefined") {
    return null;
  }
  if (delivery.Permissions.IsAvailableManageDelivery === true) {
    return null;
  }
  const [isModalLoadingSignUpShow, setIsModalLoadingSignUpShow] = useState(null);
  const [isModalReturnToEnRouteShow, setIsModalReturnToEnRouteShow] = useState(null);
  const [isModalReturnLoadingShow, setIsModalReturnLoadingShow] = useState(null);
  const [isModalOrderDocsShow, setIsModalOrderDocsShow] = useState(null);
  const [showChangeLoadingDateModal, setShowChangeLoadingDateModal] = useState(false);

  const onClickLoadingSingOut = () => {
    deliveriesService.cancelLoadingSignUp(delivery.Id)
      .then((response) => {
        if (response.code === 400) {
          notification.error({
            message: "Ошибка отмены записи",
            description: response.message,
          });
        } else {
          notification.success({
            message: "Запись на погрузку",
            description: response.message,
          });
          setNeedUpdate(true);
        }
      })
      .catch((errMsg) => notification.error(
        {
          message: "Отмена записи на погрузку",
          description: errMsg
        }));
  }

  if (delivery.Status === DELIVERY_AWAITING_LOADING || delivery.Status === DELIVERY_ENROUTE || delivery.Status === DELIVERY_LOADING || delivery.Status === DELIVERY_AGREED) {
    let buttons = [];

    if (delivery.Status === DELIVERY_ENROUTE) {
      buttons.push((
        <React.Fragment key={`return-loading-button-${delivery.Id}`}>
          <button className="action-button"
                  onClick={() => {
                    setIsModalReturnLoadingShow(delivery.Id);
                  }}
          >Вернуть на погрузку
          </button>
          <ModalReturnLoadingDelivery
            onCloseModal={() => {
              setIsModalReturnLoadingShow(null);
            }}
            isShow={isModalReturnLoadingShow === delivery.Id}
            id={delivery.Id}/>
        </React.Fragment>
      ));
    }

    if (delivery.Status === DELIVERY_LOADING || delivery.Status === DELIVERY_AWAITING_LOADING) {
      if (delivery.Order.Docs.Procuration) {
        const procurationFile = delivery.Order.Docs.Procuration.File ? delivery.Order.Docs.Procuration.File : delivery.Order.Docs.Procuration.Blank;

        const onClickDownloadProcurationBlank = (url) => {
          window.open(url);
        };

        buttons.push((
          <button
            className={`action-button`}
            // style={{ fontSize: "11px" }}
            onClick={() => onClickDownloadProcurationBlank(procurationFile)}
          >
            Скачать доверенность
          </button>
        ));
      }

      if (delivery.Order.Docs.Agreement) {
        const agreementFile = delivery.Order.Docs.Agreement.File ? delivery.Order.Docs.Agreement.File : delivery.Order.Docs.Agreement.Template;

        const onClickDownloadAgreementBlank = (url) => {
          window.open(url);
        };

        if (agreementFile) {
          buttons.push((
            <button
              className={`action-button`}
              // style={{ fontSize: "11px" }}
              onClick={() => onClickDownloadAgreementBlank(agreementFile)}
            >
              Скачать заявку
            </button>
          ));
        }
      }

      if (delivery.Permissions.IsCanSignUpForLoading && delivery.LoadingSign === null) {

        buttons.push((
          <>
            <button
              className={`action-button`}
              onClick={() => {
                setIsModalLoadingSignUpShow(delivery.Id);
              }}
            >
              Записаться на погрузку
            </button>
            <ModalChooseLoadingGate id={delivery.Id}
                                    isShow={isModalLoadingSignUpShow === delivery.Id}
                                    onCloseModal={() => {
                                      setIsModalLoadingSignUpShow(null);
                                    }}
                                    setNeedUpdate={setNeedUpdate}
                                    date={delivery.Order.Date}
            />
          </>
        ));
      }

      if (delivery.LoadingSign !== null) {
        buttons.push((
          <Popconfirm title={"Отменить запись?"} okText={"да"} cancelText={"нет"}
                      onConfirm={() => onClickLoadingSingOut()}>
            <button
              className={`action-button typical-btn-red`}
            >
              Отменить запись на погрузку на {delivery.LoadingSign.DateTime.split(' ')[1]}
            </button>
          </Popconfirm>
        ));
      }
    }

    if (delivery.Status === DELIVERY_AGREED) {
      if (delivery.ExternalDateCreated) {
        buttons.push(
          <React.Fragment key={`invoice-button-${delivery.Id}`}>
            <button className="action-button"
                    onClick={() => {
                      setIsModalOrderDocsShow(delivery.Id);
                    }}
            >Счет и акт
            </button>
            <button className="action-button"
                    onClick={() => {
                      setIsModalReturnToEnRouteShow(delivery.Id);
                    }}
            >Вернуть из архива
            </button>
            <ModalShowOrderDocs
              isShow={isModalOrderDocsShow === delivery.Id}
              onCloseModal={() => {
                setIsModalOrderDocsShow(null);
              }}
              invoice={delivery.Order.Docs.Invoce || null}
              act={delivery.Order.Docs.Act || null}
              number={delivery.NumberDelivery}
              id={delivery.Id}
            />
            <ModalReturnToEnRoute
              onCloseModal={() => {
                setIsModalReturnToEnRouteShow(null);
              }}
              isShow={isModalReturnToEnRouteShow === delivery.Id}
              id={delivery.Id}/>
          </React.Fragment>
        );
      }
    }

    if (delivery.Status === DELIVERY_LOADING || delivery.Status === DELIVERY_AWAITING_LOADING) {
      buttons.push(
        <>
          <button className={`action-button typical-btn-red`} onClick={() => setShowChangeLoadingDateModal(true)}
          >
            Изменить дату погрузки
          </button>
          <ModalChangeLoadingDate id={delivery.Order.Id} isShow={showChangeLoadingDateModal} 
                                  onCloseModal={setShowChangeLoadingDateModal} 
                                  date={delivery.LoadingSign? delivery.LoadingSign.DateTime : moment(delivery.Order.Date).format('DD.MM.YYYY')}/>
        </>
      )
    }
    return buttons;
  }

  const [showModalAttachDriver, setShowModalAttachDriver] = useState(false);
  const [showModalCreateOrder, setShowModalCreateOrder] = useState(false);
  const [paymentType, setPaymentType] = useState(null);

  useEffect(() => {
    const price = delivery.Prices.find(e => e.Price !== null);
    if (price) {
      setPaymentType(price.PaymentType.Id);
    }
  }, [delivery]);

  const isForeignDelivery = delivery.IsForeignDelivery;

  const nameRegionLastPointUnload = delivery.PlacesUnload.length
    ? delivery.PlacesUnload[delivery.PlacesUnload.length - 1].City
    : "-";

  const regionLoad = delivery.PlaceLoad.City ? delivery.PlaceLoad.City : "-";

  const onClickShowModalAttachDriver = (e) => {
    e.preventDefault();

    setStopListUpdate(true);
    setShowModalAttachDriver(true);

    orderDeliveryService
      .start(delivery.Order.Id)
      .then((response) => {
        /*             notification.success({
                message: "Начало заполнения заявки",
                description: response.message
            }) */
      })
      .catch((error) => {
        /*             notification.error({
                message: "Начало заполнения заявки",
                description:error
            }) */
      });
  };

  const onClickRefuseOrder = (e) => {
    e.preventDefault();
    setLoadingDelivItem(true);

    orderDeliveryService
      .refuseOrder(delivery.Order.Id)
      .then((response) => {
        notification.success({
          message: "Отмена заявки",
          description: response.message,
        });
        setLoadingDelivItem(false);
        setNeedUpdate(true);
      })
      .catch((error) => {
        notification.error({
          message: "Отмена заявки",
          description: error,
        });
        setNeedUpdate(true);
      });
  };

  const onClickRefuseCounteroffer = (e) => {
    e.preventDefault();
    setLoadingDelivItem(true);

    orderDeliveryService
      .refuseCounteroffer(delivery.Order.Id)
      .then((response) => {
        notification.success({
          message: "Отмена встречного предложения",
          description: response.message,
        });
        setLoadingDelivItem(false);
        setNeedUpdate(true);
      })
      .catch((error) => {
        notification.error({
          message: "Отмена встречного предложения",
          description: error,
        });
        setNeedUpdate(true);
      });
  };

  const onClickAgreeCounteroffer = (e) => {
    e.preventDefault();
    setLoadingDelivItem(true);

    orderDeliveryService
      .agreeCounteroffer(delivery.Order.Id)
      .then((response) => {
        notification.success({
          message: "Подтверждение встречного предложения",
          description: response.message,
        });
        setLoadingDelivItem(false);
        setNeedUpdate(true);
      })
      .catch((error) => {
        notification.error({
          message: "Подтверждение встречного предложения",
          description: error,
        });
        setNeedUpdate(true);
      });
  };

  let btnFillOrder;
  if (delivery.Status === DELIVERY_BOOKED) {
    btnFillOrder = (
      <a
        href="#"
        className="action-button"
        onClick={onClickShowModalAttachDriver}
      >
        <span className="speedometer">Заполнить заявку</span>
      </a>
    );
  }

  if (delivery.Order) {
    let buttonsLoadingSign = [];
    let buttonsChangeDate = [];
    if (delivery.LoadingSign) {
      buttonsLoadingSign.push((
        <>
          <Popconfirm title={"Отменить запись?"} okText={"да"} cancelText={"нет"}
                      onConfirm={() => onClickLoadingSingOut()}>
            <button
              className={`action-button typical-btn-red`}
            >
              Отменить запись на погрузку на {delivery.LoadingSign.DateTime.split(' ')[1]}
            </button>
          </Popconfirm>
        </>
      ));
    }
    if (delivery.LoadingSign === null) {
      buttonsLoadingSign.push((
        <>
          <button
            style={{marginTop: "5px"}}
            className={`action-button`}
            onClick={() => {
              setIsModalLoadingSignUpShow(delivery.Id);
            }}
          >
            Записаться на погрузку
          </button>
          <ModalChooseLoadingGate id={delivery.Id}
                                  isShow={isModalLoadingSignUpShow === delivery.Id}
                                  onCloseModal={() => {
                                    setIsModalLoadingSignUpShow(null);
                                  }}
                                  setNeedUpdate={setNeedUpdate}
                                  date={delivery.Order.Date}
          />
        </>
      ))
    }
    if (delivery.Order.Status === DELIVERY_ORDER_APPROVED || delivery.Order.Status === DELIVERY_ORDER_FILLED || delivery.Order.Status === DELIVERY_ORDER_AGREED) {
      buttonsChangeDate.push(
        <>
          <button className={`action-button typical-btn-red`} onClick={() => setShowChangeLoadingDateModal(true)}
          >
            Изменить дату погрузки
          </button>
          <ModalChangeLoadingDate id={delivery.Order.Id} isShow={showChangeLoadingDateModal} 
                                  onCloseModal={setShowChangeLoadingDateModal} 
                                  date={delivery.LoadingSign? delivery.LoadingSign.DateTime : moment(delivery.Order.Date).format('DD.MM.YYYY')}/>
        </>
      )
    }
    if (delivery.Order.Status === DELIVERY_ORDER_FILLED) {
      return (
        <>
          <UploadDocsButtons
            delivery={delivery}
            setLoadingDelivItem={setLoadingDelivItem}
            setNeedUpdate={setNeedUpdate}
            setStopListUpdate={setStopListUpdate}
          />
          {buttonsLoadingSign}
          {buttonsChangeDate}
        </>
      );
    } else if (delivery.Order.Counteroffer) {
      return (
        <div>
          <div className="offer-data">
            Предложение: {delivery.Order.Counteroffer.Price.toLocaleString("ru")} {delivery.Order.PaymentSymbol} на <u><ShortDate
            date={delivery.Order.Counteroffer.DateLoading}/></u>
          </div>
          <a onClick={onClickAgreeCounteroffer}
             href="#"
             className="action-button counter-offer-accept">Принять</a>
          <a onClick={onClickRefuseCounteroffer}
             href="#"
             className="action-button counter-offer-decline">Отклонить</a>
        </div>
      );
    } else if (delivery.Order.Status === DELIVERY_ORDER_AWAITING_AGREED) {
      return (
        <>
          {buttonsLoadingSign}
        </>
      );
    } else {
      const dateShipmentRus = moment(delivery.Order.Date).format("D MMMM YYYY");

      return (
        <div>
          <div className="offer-data">
            Заявка: {delivery.Order.Price.toLocaleString("ru")} {delivery.Order.PaymentSymbol} на <u><ShortDate
            date={delivery.Order.Date}/></u>
          </div>
          {btnFillOrder}
          <Popconfirm
            title='Вы уверены?'
            okText='Да'
            cancelText='Нет'
            onConfirm={onClickRefuseOrder}
          >
            <a className="action-button typical-btn-red">
              Отменить заявку
            </a>
          </Popconfirm>
          <ModalAttachDriverOrderDelivery
            key={`modal_${delivery.Id}`}
            isShow={showModalAttachDriver}
            setIsShow={setShowModalAttachDriver}
            orderId={delivery.Order.Id}
            companyId={delivery.Order.Company.Id}
            setStopListUpdate={setStopListUpdate}
            setNeedUpdateDeliveryList={setNeedUpdate}
            deliveryDateShipmentRus={dateShipmentRus}
            deliveryPrice={delivery.Order.Price}
            deliveryRegionLoad={regionLoad}
            deliveryRegionUnload={nameRegionLastPointUnload}
            isForeignDelivery={isForeignDelivery}
            deliveryContractType={delivery.ContractType}
            deliveryId={delivery.Id}
            date={delivery.Order.Date}
          />
          {buttonsChangeDate}
        </div>
      );
    }
  } else {
    if (typeof (permissions.IsCanCreateOrder) != "undefined" && permissions.IsCanCreateOrder === false) {
      return (
        <div>
          <a
            className="action-button"
            onClick={() => {
              setStopListUpdate(true);
              setShowModalCreateOrder(true);
            }}
          >
            <span className="speedometer">Подать заявку</span>
          </a>
          <ModalNeedApprovedLegalEntity
            isShow={showModalCreateOrder}
            setIsShow={setShowModalCreateOrder}
            text={'Для подачи заявки на рейс Вам необходимо добавить и согласовать юридическое лицо, и заключить договор'}
            title={'Подача заявки'}
          />
        </div>
      );
    }
    if (!timerEnabled) {
      return (
        <div>
          <a
            className="action-button"
            onClick={() => {
              setStopListUpdate(true);
              setShowModalCreateOrder(true);
            }}
          >
            <span className="speedometer">Подать заявку</span>
          </a>
          <ModalCreateOrder
            isShow={showModalCreateOrder}
            setIsShow={setShowModalCreateOrder}
            delivery={delivery}
            setNeedUpdateDeliveryList={setNeedUpdate}
            setStopListUpdate={setStopListUpdate}
            paymentType={paymentType}
            setPaymentType={setPaymentType}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

function UploadDocsButtons({delivery, setLoadingDelivItem, setNeedUpdate, setStopListUpdate}) {
  const [scanAgreement, setScanAgreement] = useState(null);
  const [scanProcuration, setScanProcuration] = useState(null);
  const [loading, setLoading] = useState(false);

  const templateAgreement = delivery.Order.Docs.Agreement.Template;
  const isDisabledUpload = templateAgreement === null;

  const onClickDownloadTemplateAgreement = () => {
    if (!isDisabledUpload) {
      window.open(delivery.Order.Docs.Agreement.Template);
    }
  };

  const onClickDownloadProcurationBlank = () => {
    window.open(delivery.Order.Docs.Procuration.Blank);
  };

  const uploadPropsAgreement = {
    multiple: false,
    disabled: isDisabledUpload || scanAgreement !== null,
    showUploadList: false,
    onRemove: () => {
      setScanAgreement(null);
    },
    beforeUpload: (file) => {
      setStopListUpdate(true);
      setScanAgreement(file);
      return false;
    },
  };
  const uploadPropsProcuration = {
    multiple: false,
    disabled: isDisabledUpload || scanProcuration !== null,
    showUploadList: false,
    onRemove: () => {
      setScanProcuration(null);
    },
    beforeUpload: (file) => {
      setStopListUpdate(true);
      setScanProcuration(file);
      return false;
    },
  };

  const needScanProcuration = delivery.Order.Docs.Procuration !== null;

  if (((needScanProcuration && scanAgreement !== null && scanProcuration !== null) || (!needScanProcuration && scanAgreement !== null)) && !loading) {
    setLoading(true);
    setLoadingDelivItem(true);

    orderDeliveryService.agreeWithDocs(delivery.Order.Id, scanAgreement, scanProcuration)
      .then((response) => {
        setScanAgreement(null);
        setScanProcuration(null);

        setLoading(false);
        setLoadingDelivItem(false);
        setStopListUpdate(false);

        if (response.status === "success") {
          setNeedUpdate(true);
          notification.success({
            message: "Прикрепление документов",
            description: response.message,
          });
        } else if (response.status === "error") {
          for (let fieldName in response.errors) {
            if (response.errors.hasOwnProperty(fieldName)) {
              const errorMsg = response.errors[fieldName][0];
              notification.error({
                message: "Прикрепление документов",
                description: errorMsg,
              });
            }
          }
        }
      })
      .catch((error) => {
        notification.error({
          message: "Прикрепление документов",
          description: error,
        });

        setScanAgreement(null);
        setScanProcuration(null);

        setLoading(false);
        setLoadingDelivItem(false);
        setStopListUpdate(false);
      });
  }

  const buttonExtClassName = isDisabledUpload ? "disabled" : "";
  const buttonAgreementColorClassName = scanAgreement === null ? "typical-btn-blue" : "typical-btn-green";
  const buttonProcurationColorClassName = scanProcuration === null ? "typical-btn-blue" : "typical-btn-green";

  if (needScanProcuration) {
    return (
      <div>
        <button
          className={`action-button ${buttonExtClassName}`}
          // style={{ fontSize: "11px" }}
          onClick={onClickDownloadTemplateAgreement}
        >
          Скачать заявку
        </button>
        <Upload {...uploadPropsAgreement}>
          <Button
            className={`action-button ${buttonAgreementColorClassName} ${buttonExtClassName}`}
            // style={{ marginBottom: '5px' }}
            htmlType="button"
          >
            Загрузить заявку
          </Button>
        </Upload>

        <br/>

        {delivery.Order.Docs.Procuration.Blank &&
          (<button
            className={`action-button`}
            // style={{ fontSize: "11px" }}
            onClick={onClickDownloadProcurationBlank}
          >
            Скачать доверенность
          </button>)
        }
        <Upload {...uploadPropsProcuration}>
          <Button
            className={`action-button ${buttonProcurationColorClassName} ${buttonExtClassName}`}
            // style={{ fontSize: "11px", padding: 0 }}
            htmlType="button"
          >
            Загрузить доверенность
          </Button>
        </Upload>
      </div>
    );
  } else {
    return (
      <div>
        <button className={`action-button ${buttonExtClassName}`} onClick={onClickDownloadTemplateAgreement}>
          Скачать заявку
        </button>
        <Upload className={`action-button-upload`}{...uploadPropsAgreement}>
          <Button className={`${buttonAgreementColorClassName} ${buttonExtClassName}`} htmlType="button">
            Загрузить заявку
          </Button>
        </Upload>
      </div>
    );
  }
}
