import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Pagination } from "antd";
import { userServices } from "../../../../../services";
import { useFetchDeliveriesList } from "../../../../../hooks/use-fetch-deliveries-list";
import Error from "../../../../error";
import Spinner from "../../../../spinner";
import DeliveryItem from "../../../../delivery-item-block";
import LogistFreeContentItem from "../../../../delivery-item-content/logist/free-content-item";
import DeliveryEmptyContent from "../../../../delivery-empty-content";
import UserNotificationMessage from "../../../../user-message";
import NotificationMessage from "../../../../modals/notification-message";
import { AppContext } from "../../../../../contexts/app-context";
import { VIEW_BOOKED_DELIVERIES_CARRIER } from "../../../../../helpers/user_permission.helper";
import { DELIVERY_AWAITING_LOADING } from "../../../../../helpers/delivery_status.helper";
import DeterminedPrice from "../../../../determined-price";
import DeliveryGroupList from "../../../../delivery-group-list";
import { deliveriesConfig } from "../../../../../config";

function HeaderItemLoading({ delivery, dateShipmentRus, nameRegionLastPointUnload, regionLoad }) {
	return (
		<div className="application-topline">
			<div className="application-topline-logist">
				<div className="application-topline-logist-item">
					{/* <div className="application-topline-logist-item-col">
            <p className="typical-icon-field icon-field-waiting blue-txt">На погрузке</p>
          </div> */}
					<div className="application-topline-carrier-item-col application-topline-carrier-item-col-city">
						<p className="typical-icon-field icon-field-patch" title={regionLoad}>
							{regionLoad}
						</p>
						<p className="typical-icon-field icon-field-dispatch" title={nameRegionLastPointUnload}>
							{nameRegionLastPointUnload}
						</p>
					</div>
				</div>
				<div className="application-topline-carrier-item">
					<div className="application-topline-carrier-item application-topline-carrier-item-determined">
						<DeterminedPrice delivery={delivery} />
						<div className="application-topline-carrier-item-col application-topline-carrier-item-col-date">
							<div className="typical-icon-field icon-field-calendar">
								<h4>№</h4>
								<p>{delivery.NumberDelivery}</p>
								{dateShipmentRus}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function HeaderItemAwaitingLoading({ delivery, dateShipmentRus, nameRegionLastPointUnload, regionLoad }) {
	return (
		<div className="application-topline">
			<div className="application-topline-logist">
				<div className="application-topline-logist-item">
					{/* <div className="application-topline-logist-item-col">
            <p className="typical-icon-field icon-field-waiting blue-txt">Ожидание погрузки</p>
          </div> */}
					<div className="application-topline-carrier-item-col application-topline-carrier-item-col-city">
						<p className="typical-icon-field icon-field-patch" title={regionLoad}>
							{regionLoad}
						</p>
						<p className="typical-icon-field icon-field-dispatch" title={nameRegionLastPointUnload}>
							{nameRegionLastPointUnload}
						</p>
					</div>
				</div>
				<div className="application-topline-carrier-item">
					<div className="application-topline-carrier-item application-topline-carrier-item-determined">
						<DeterminedPrice delivery={delivery} />
						<div className="application-topline-carrier-item-col application-topline-carrier-item-col-date">
							<div className="typical-icon-field icon-field-calendar">
								<h4>№</h4>
								<p>{delivery.NumberDelivery}</p>
								{dateShipmentRus}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default function CarrierOnLoadingDeliveries() {
	const user = userServices.getUser();

	const appContext = useContext(AppContext);
	const [step, setStep] = useState(deliveriesConfig.pageCount);
	const [offset, setOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	useEffect(() => {
		appContext.initFilter();
	}, []);

	let onChange = (page, pageSize) => {
		if (page === currentPage) {
			return false;
		} else {
			setCurrentPage(page);
		}

		let val = 0;
		if (parseInt(page) === 1) {
			setOffset(0);
		} else {
			val = page * pageSize - pageSize;
			setOffset(val);
		}
		appContext.filter.offset = val;
		setNeedUpdate(true);
	};

	const [{ error, loading, data: deliveries, setStopListUpdate, setNeedUpdate, notifications, permissions, totalCount }] = useFetchDeliveriesList(
		"delivery/get-loading",
		true,
		false,
		true,
		true,
		deliveriesConfig.secondForUpdate,
		offset,
		step
	);

    if (!user.isAccessTo(VIEW_BOOKED_DELIVERIES_CARRIER)) {
        return <Error status={403} desc="Страница не доступна" />;
    }

	if (error) {
		return <Error type={500} desc={error.message} />;
	}

	let listItems = [];

	if (!loading) {
		listItems = deliveries.map((delivery) => {
			const dateShipmentRus = moment(delivery.DateShipment).format("D MMMM YYYY");

			const header = delivery.Status === DELIVERY_AWAITING_LOADING ? HeaderItemAwaitingLoading : HeaderItemLoading;

			const color = delivery.Status === "loading" ? "" : "red";

			return (
				<DeliveryItem
					key={`${delivery.Id}_${delivery.Order.Status}`}
					header={header}
					content={LogistFreeContentItem}
					delivery={delivery}
					dateShipmentRus={dateShipmentRus}
					setNeedUpdate={setNeedUpdate}
					setStopListUpdate={setStopListUpdate}
					color={color}
					permissions={permissions}
				/>
			);
		});
	}

	return (
		<Spinner loading={loading}>
			<UserNotificationMessage />
			<NotificationMessage notifications={notifications} setNeedUpdate={false} setStopListUpdate={setStopListUpdate} loading={loading} />
			{totalCount !== 0 ?
				<div className="application-block pagination pagination__up">
					<Pagination
						style={{ textAlign: "center" }}
						current={currentPage}
						pageSize={step}
						total={totalCount}
						showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
						onChange={onChange}
					/>
				</div>
				: null}
			{!loading ? deliveries.length ? <DeliveryGroupList deliveriesItems={listItems} /> : <DeliveryEmptyContent /> : null}
			{totalCount !== 0 ?
				<div className="application-block pagination pagination__down">
					<Pagination
						style={{ textAlign: "center" }}
						current={currentPage}
						pageSize={step}
						total={totalCount}
						showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
						onChange={onChange}
					/>
				</div>
				: null}
		</Spinner>
	);
}
