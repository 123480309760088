import React, {useEffect, useState} from "react";
import {Button, Row, Col, Modal, notification, Icon, TimePicker, Checkbox, Form} from "antd";
import {distributionCenterService} from "../../../services";
import InputField from "../../input-field";
import '../modals.css';
import moment from "moment";
import FieldBlock from "../../field-block";


export default function ModalCreateGateForDistributionCenter({
                                                               isShow, id,
                                                               onCloseModal, nameD,
                                                               gateData, setIsNeedUpdateList
                                                             }) {

  const [fieldsErrors, setFieldsErrors] = useState(null);

  const [name, setName] = useState('');
  const [serviceTime, setServiceTime] = useState('');
  const [workingDays, setWorkingDays] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isValidated, setIsValidated] = useState(true);

  useEffect(() => {
    if (gateData && !isUpdated) {
      setIsActive(gateData.isActive);
      setName(gateData.name);
      setServiceTime(gateData.serviceTime);
      setWorkingDays(gateData.workingDays);
      setIsUpdated(true);
    }
  }, [isUpdated])

  useEffect(() => {
    if (name !== '' && serviceTime !== '' && workingDays.length !== 0)
      validateFormData();
  }, [name, serviceTime, workingDays]);

  let bigButtonStyle = {
    width: '200px',
    height: '43px',
    background: '#1CAA53',
    position: 'static',
    borderRadius: '10px'
  };

  const namesDay = [
    "понедельник",
    "вторник",
    "среда",
    "четверг",
    "пятница",
    "суббота",
    "воскресенье"
  ]

  const clearForm = () => {
    if (!gateData) {
      setIsActive(true);
      setName('');
      setServiceTime('');
      setWorkingDays([]);
    }
  }

  // сохранение новых данных
  const onClickAdd = () => {
    if (!gateData) {
      distributionCenterService.addGate(id, isActive, name, serviceTime, workingDays)
        .then((response) => {
          console.log(response);
          if (response.status === 'success') {
            notification.success({
              message: "Добавление",
              description: response.message,
            });
            setFieldsErrors(null);
            onCloseModal();
            setIsNeedUpdateList(true);
            clearForm();
          }
          if (response.status === 'error') {
            console.log(response);
            setFieldsErrors(response.errors);
            notification.error({
              message: "Добавление",
              description: response.message,
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: "Добавление",
            description: error,
          });
        });
    }

    if (gateData) {
      distributionCenterService.editGate(gateData.id, isActive, name, serviceTime, workingDays)
        .then((response) => {
          if (response.status === 'success') {
            notification.success({
              message: "Редактирование",
              description: response.message,
            });
            setFieldsErrors(null);
            onCloseModal();
            setIsNeedUpdateList(true);
            clearForm();
          } else {
            console.log(response);
            setFieldsErrors(response.errors);
            notification.error({
              message: "Редактирование",
              description: response.message,
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: "Редактирование",
            description: error,
          });
        });
    }
  };

  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
      ? fieldsErrors[nameField][0]
      : null;
  };

  const getDefaultValue = (index, nameValue) => {
    if (workingDays.length === 0 || !workingDays.some((item) => item.dayNum === (index + 1))
      || workingDays.find((item) => item.dayNum === (index + 1))[nameValue] === undefined
      || workingDays.find((item) => item.dayNum === (index + 1))[nameValue] === '') {
      return null
    }

    return moment(workingDays.find((item) => item.dayNum === (index + 1))[nameValue], "HH:mm")
  }

  const onChangeTimeValue = (time, timeString, nameValue, index) => {
    if (workingDays.some((item) => item.dayNum === index + 1)) {
      setWorkingDays(workingDays.map((day) => {
          if (day.dayNum === index + 1) {
            return {
              ...day,
              [nameValue]: timeString
            }
          } else {
            return day
          }
        }
      ));
    }
  }

  const validateFormData = () => {
    let errors = {};

    if (name.length < 2) {
      errors = {
        ...errors,
        name: ["Введите не менее 2 символов"]
      }
    }

    workingDays.map((item) => {
      if (item.startWorkingTime === '' || item.endWorkingTime === '') {
        errors = {
          ...errors,
          [`workingDay${item.dayNum}`]: [`${namesDay[item.dayNum - 1].toUpperCase()}: укажите рабочее время`]
        }
      }

      if (item.startWorkingTime > item.endWorkingTime || item.startWorkingTime === item.endWorkingTime) {
        errors = {
          ...errors,
          [`workingDay${item.dayNum}`]: [`${namesDay[item.dayNum - 1].toUpperCase()}: укажите корректное рабочее время`]
        }
      }

      if ((item.startWorkingTime > item.startBreakTime || item.endBreakTime > item.endWorkingTime || item.endBreakTime < item.startBreakTime) && item.endBreakTime !== "" && item.startBreakTime !== "") {
        errors = {
          ...errors,
          [`workingDay${item.dayNum}`]: [`${namesDay[item.dayNum - 1].toUpperCase()}: укажите корректное время перерыва`]
        }
      }
    })

    if (Object.keys(errors).length !== 0) {
      setFieldsErrors(errors);
      setIsValidated(false);
    } else {
      setFieldsErrors(null);
      setIsValidated(true);
    }
  };

  return (
    <Modal
      title={gateData ? <span>Ворота {gateData.name} РЦ «{nameD}»</span> :
        <span>Новые ворота погрузки РЦ «{nameD}»</span>}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        onCloseModal();
      }}
      forceRender={true}
      footer={[
        <Button htmlType="submit" key="submit" type="primary" onClick={onClickAdd}
                style={bigButtonStyle}
                disabled={!isValidated}
        >Сохранить</Button>,
      ]}
    >
      <Row gutter={[8, 16]} align={"bottom"} type="flex" style={{marginBottom: "20px"}}>
        <Col lg={12} md={18} sm={24} xs={24}>
          <InputField
            name="name" label="Название"
            value={name} setValue={setName}
            getFieldErrors={getFieldErrors}
          />
        </Col>
        <Col lg={12} md={6} sm={24} xs={24}>
          <InputField
            name="serviceTime" label="Длительность погрузки (минуты)"
            value={serviceTime} setValue={setServiceTime}
            getFieldErrors={getFieldErrors}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Checkbox
            defaultChecked={isActive}
            onChange={() => setIsActive(!isActive)}
            style={{height: "100%"}}
          >
            Ворота активны для записи
          </Checkbox>
        </Col>
      </Row>
      <Row gutter={[8, 16]} className="only-desktop">
        <Col xs={24} sm={24} md={6} lg={6} xl={6}></Col>
        <Col xs={24} sm={24} md={9} lg={9} xl={9}><span style={{fontSize: "12px"}}><Icon type="clock-circle"/> часы работы</span></Col>
        <Col xs={24} sm={24} md={9} lg={9} xl={9}><span style={{fontSize: "12px"}}><Icon type="coffee"/> перерыв</span></Col>
      </Row>
      {
        namesDay.map((day, index) => {
          return <FieldBlock name={`workingDay${index+1}`} title="" getErrors={getFieldErrors} key={index + 1} style={{margin: "0"}}>
            <Row gutter={[0, 16]}>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Checkbox
                  checked={workingDays.length === 0 ? false : workingDays.some((item) => item.dayNum === (index + 1))}
                  value={index + 1}
                  onChange={(e) => {
                    if (workingDays.length === 0 || !workingDays.some((item) => item.dayNum === e.target.value)) {
                      setWorkingDays(workingDays.concat([{
                        dayNum: e.target.value,
                        startWorkingTime: '',
                        endWorkingTime: '',
                        startBreakTime: '',
                        endBreakTime: ''
                      }]));
                    }

                    if (workingDays.some((item) => item.dayNum === e.target.value)) {
                      setWorkingDays(workingDays.filter((item) => !(item.dayNum === e.target.value)));
                    }
                  }}
                  name={"dayNum"}>
                  {day}
                </Checkbox>
              </Col>
              <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                <TimePicker name="startWorkingTime" format="HH:mm" minuteStep={10} placeholder=""
                            style={{margin: "0 5px"}}
                            defaultOpenValue={moment('08:00', 'HH:mm')}
                            value={getDefaultValue(index, "startWorkingTime")}
                            onChange={(time, timeString) => onChangeTimeValue(time, timeString, "startWorkingTime", index)}
                            disabled={!workingDays.some((item) => item.dayNum === (index + 1))}
                />
                <TimePicker name="endWorkingTime" format="HH:mm" minuteStep={10} placeholder=""
                            style={{margin: "5px"}}
                            defaultOpenValue={moment('18:00', 'HH:mm')}
                            value={getDefaultValue(index, "endWorkingTime")}
                            onChange={(time, timeString) => onChangeTimeValue(time, timeString, "endWorkingTime", index)}
                            disabled={!workingDays.some((item) => item.dayNum === (index + 1))}/>
              </Col>
              <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                <TimePicker name="startBreakTime" format="HH:mm" minuteStep={10} suffixIcon={<Icon type="coffee"/>}
                            placeholder=""
                            style={{margin: "0 5px"}}
                            defaultOpenValue={moment('12:00', 'HH:mm')}
                            value={getDefaultValue(index, "startBreakTime")}
                            onChange={(time, timeString) => onChangeTimeValue(time, timeString, "startBreakTime", index)}
                            disabled={!workingDays.some((item) => item.dayNum === (index + 1))}/>
                <TimePicker name="endBreakTime" format="HH:mm" minuteStep={10} suffixIcon={<Icon type="coffee"/>}
                            placeholder="" style={{margin: "5px"}}
                            defaultOpenValue={moment('14:00', 'HH:mm')}
                            value={getDefaultValue(index, "endBreakTime")}
                            onChange={(time, timeString) => onChangeTimeValue(time, timeString, "endBreakTime", index)}
                            disabled={!workingDays.some((item) => item.dayNum === (index + 1))}/>

              </Col>
            </Row>
          </FieldBlock>
        })
      }
    </Modal>
  );
}
