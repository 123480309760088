import React from "react";
import { Form, InputNumber } from "antd";

export default function NumberInputField({ name, label, value, setValue, min = 0, max = undefined, getFieldErrors, style = { width: "100%" }, keyNum }) {
  const fieldErrors = (typeof keyNum !== "undefined") ? getFieldErrors(name, keyNum) :  getFieldErrors(name);

  const onChange = (value) => {
    setValue(value);
  };

  if (fieldErrors) {
    return (
      <React.Fragment>
        <span className="form-field-label" htmlFor={name}>
          {label}
        </span>
        <Form.Item validateStatus="error" help={fieldErrors}>
          <InputNumber
            min={min}
            max={max}
            size="large"
            name={name}
            id={name}
            placeholder={label}
            defaultValue={value}
            value={value}
            onChange={onChange}
            style={style}
          />
        </Form.Item>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <span className="form-field-label" htmlFor={name}>
          {label}
        </span>
        <InputNumber
          min={min}
          max={max}
          size="large"
          name={name}
          id={name}
          placeholder={label}
          defaultValue={value}
          value={value}
          onChange={onChange}
          style={style}
        />
      </React.Fragment>
    );
  }
}
