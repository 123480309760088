import React, {useEffect, useState} from 'react';
import Spinner from "../../../spinner";
import {Button, Col, DatePicker, Icon, Row, Table} from "antd";
import {reportService} from "../../../../services/report.services";
import moment from "moment";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import {tableHelper} from "../../../../helpers";


export default function ReportStatsLegalEntities() {

  const [loading, setLoading] = useState(true);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  // параметры для построения отчета
  const [dateStart, setDateStart] = useState(moment().startOf('month'));
  const [dateEnd, setDateEnd] = useState(moment().endOf('month'));
  // полученные данные отчета
  const [reportData, setReportData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState('');
  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (isNeedUpdateReport) {
      // запрос на получение данных отчета
      reportService.getStatsLegalEntities(dateStart.format('YYYY-MM-DD'), dateEnd.format('YYYY-MM-DD'))
        .then((response) => {
          setReportData([]);
          let tableData = [];
          setTotalCount(response.Params.totalCount);
          response.Rows.map((item, index) => {
            if (item.child === null) {
              tableData[item.distributionCenter] = {
                number: index,
                distributionCenter: item.distributionCenter,
                legalEntitiesCount: item.legalEntitiesCount,
                children: []
              };
            } else {
              tableData[item.distributionCenter].children.push({
                key: index,
                distributionCenter: item.legalEntitiesName,
                legalEntitiesCount: moment(item.legalEntitiesDateCreated).format("D.MM.YYYY HH:mm")
              });
            }
            return null;
          });
          let reindexedTableData = [];
          for (let key in tableData) {
            reindexedTableData.push(tableData[key]);
          }
          reindexedTableData.sort(function (a, b) {
            return Number(b.legalEntitiesCount) - Number(a.legalEntitiesCount);
          });
          setIsNeedUpdateReport(false);
          setLoading(false);
          setReportData(reindexedTableData);
        }).catch((errorMsg) => {
        setIsNeedUpdateReport(false);
        setLoading(false);
        setErrorMsg(errorMsg);
      });
    }
  }, [isNeedUpdateReport]);


  // список столбцов таблицы
  const columns = [
    {
      title: 'РЦ',
      dataIndex: 'distributionCenter',
      key: 'distributionCenter',
      width: '60%',
    },
    {
      title: 'Кол-во',
      dataIndex: 'legalEntitiesCount',
      key: 'legalEntitiesCount',
      width: '40%',
    },
  ];

  return (
    <React.Fragment>
      <div className="reports__header">
        <h1 className="content-header">Отчет «Статистика по зарегистрированным юридическим лицам»</h1>
        <Icon type='filter'
              theme={isFilterOpen ? '' : "filled"}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen)
              }}
              className="reports__filter-toggle"/>
        {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
          <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start">
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateStart}
                format="DD.MM.YYYY"
                size="normal"
                name="dateStart"
                placeholder="Выберите дату начала периода"
                locale={localeDatePicker}
                onChange={(date) => {
                  setDateStart(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateEnd}
                format="DD.MM.YYYY"
                size="normal"
                name="dateEnd"
                placeholder="Выберите дату окончания периода"
                locale={localeDatePicker}
                onChange={(date) => {
                  setDateEnd(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{textAlign: 'right', height: '40px'}}>
              <Button type="primary"
                      htmlType="button"
                      onClick={() => {
                        setLoading(true);
                        setIsNeedUpdateReport(true);
                      }}
                      style={{backgroundColor: '#1CAA53', border: 'none', height: '100%', width: '100%'}}
              >
                Применить
              </Button>
            </Col>
          </Row>
        </div>}
        <Row gutter={12} style={{padding: "0", rowGap: "10px", marginTop: '10px', width: '100%'}} type="flex" justify="start">
          <Col lg={12} md={12} sm={24} xs={24} style={{textAlign: 'left', marginBottom: '5px'}}>Регистраций за
            период: {totalCount}</Col>
        </Row>
      </div>
      <div className="reports__table">
        <div className="reports__table-wrapper">
          <Col span={24}>
            <Table
              style={{minWidth: '700px'}}
              columns={columns}
              dataSource={reportData}
              locale={{emptyText: "Данные отсутствуют"}}
              rowKey="key"
              size="small"
              loading={loading}
              pagination={{
                pageSize: 50
              }}
            />
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
}
