import React, {useState} from "react";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import {Button, Row, Col, Modal, notification, Input, Typography, DatePicker} from "antd";
import '../modals.css';
import {orderDeliveryService} from "../../../services";
import moment, {now} from "moment";
import FieldBlock from "../../field-block";

export default function ModalChangeLoadingDate({
                                                 isShow, id, onCloseModal, date
                                               }) {
  let bigButtonStyle = {
    width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px'
  };
  const {Text} = Typography;
  const {TextArea} = Input;
  const [reason, setReason] = useState("");
  const [newDate, setNewDate] = useState(moment(now()));
  const [isDisabled, setIsDisabled] = useState(false);

  const [fieldsErrors, setFieldsErrors] = useState(null);
  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined") ? fieldsErrors[nameField][0] : null;
  };

  const onSignUp = () => {
    setIsDisabled(true);
    orderDeliveryService.changeLoadingDate(id, reason, moment(newDate, 'YYYY-MM-DD'))
      .then((response) => {
        notification.success({
          message: "Изменение даты погрузки", description: response.message,
        });
        onCloseModal();
      })
      .catch((err) => {
        notification.error({
          message: "Изменение даты погрузки", description: err,
        });
      })
      .finally(() => {
        setIsDisabled(false);
      })
  }

  return (<Modal
    title={<div>Изменить дату погрузки</div>}
    cancelText="Закрыть"
    className="modal-window"
    align={null}
    visible={isShow}
    onCancel={() => {
      onCloseModal();
    }}
    forceRender={true}
    footer={
      <Button htmlType="submit" key="submit" type="primary"
              style={bigButtonStyle}
              onClick={() => onSignUp()}
              disabled={reason === "" || isDisabled}
      >Отправить</Button>
    }
  >
    <Row gutter={[16, 24]} style={{marginBottom: "34px"}}>
      <Col span={24}>
        <Text strong style={{fontSize: "20px"}}>Дата погрузки: {date}</Text>
      </Col>
      <Col span={24}>
        <FieldBlock name="newDate" title={<Text strong style={{fontSize: "20px"}}>Новая дата погрузки</Text>}
                    getErrors={getFieldErrors}>
          <DatePicker
            style={{width: "100%"}}
            value={newDate}
            defaultValue={newDate}
            format="DD.MM.YYYY"
            size="large"
            name="new_date"
            locale={localeDatePicker}
            onChange={(date) => {
              if (date != null) {
                setNewDate(date);
              }
            }}
          />
        </FieldBlock>
      </Col>
      <Col span={24}>
        <Text strong style={{fontSize: "20px"}}>Укажите причину</Text>
        <TextArea
          placeholder="Введите текст"
          value={reason}
          onChange={(e) => {
            setReason(e.target.value)
          }}
          rows={4}
          autoSize={{minRows: 4, maxRows: 8}}
        />
      </Col>
    </Row>
  </Modal>);
}
