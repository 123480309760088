import React from 'react';

import './banner.less';

export default function Banner(prop) {
    return (
        <>
            {prop.banner === 1 && <div className="banner banner--left">
                <div className="banner__content">
                    <p>Мы запустили новое мобильное приложение для собственников автотранспорта!</p>
                    <p className="banner__text-accent"><span>"Светофор Логистика"</span> теперь и в вашем мобильном!</p>
                </div>
                <div className="banner__image banner__image--bg">
                    <img src="/img/app-link-image.svg" width="325" height="300" alt="app-link"/>
                    <a className="banner__app-link" href='https://play.google.com/store/apps/details?id=com.uvezem&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                       target="_blank" rel="noopener noreferrer">
                        <span>Скачайте<br/>сейчас:</span>
                        <img alt='Доступно в Google Play' src='/img/googleplaystore.png' width={173}/>
                    </a>
                </div>
            </div>}
            {prop.banner === 2 && <div className="banner banner--reverse  banner--right">
                <div className="banner__content banner__content--reverse">
                    <p>Получайте уведомления о всех интересующих рейсах прямо в Telegram</p>
                    <p className="banner__text-accent">Установите чат-бот от<span>"Светофор Логистика"</span></p>
                </div>
                <div className="banner__image">
                    <img src="/img/qr.svg" alt="app-link"/>
                </div>
            </div>}
        </>
    );
}
