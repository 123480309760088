import {httpRequest} from "./http-request";

export const driverService = {
  create,
  checkVerifiedInfo,
  saveEditingInfo,
  getListByName,
  getDocsArchive,
  getActiveDrivers,
  hide,
};

export const OWNERSHIP_TYPES = [
    {id: 'own', name: 'Собственность'},
    {id: 'joint_property_of_spouses', name: 'Совместная собственность супругов'},
    {id: 'rent', name: 'Аренда'},
    {id: 'leasing', name: 'Лизинг'},
    {id: 'gratuitous', name: 'Безвозмездное пользование'},
];

/**
 * Создать водителя
 */
async function create(driverData) {

  const formData = new FormData();

  for (let fieldName in driverData) {
    if (driverData.hasOwnProperty(fieldName)) {
      formData.append(fieldName, driverData[fieldName]);
    }
  }

  return await httpRequest.post('driver/create', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Проверить актуальность данных
 */
async function checkVerifiedInfo(driverId) {

  return await httpRequest.get(`driver/${driverId}/check-verified-info`)
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Сохранить измененные данные
 */
async function saveEditingInfo(driverId, driverData) {

  const formData = new FormData();

  for (let fieldName in driverData) {
    if (driverData.hasOwnProperty(fieldName)) {
      formData.append(fieldName, driverData[fieldName]);
    }
  }

  return await httpRequest.post(`driver/${driverId}/edit`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Получить список водителей с поиском по имени
 */
async function getListByName(name) {

  return await httpRequest.get(`driver/get-list-by-name?Name=${name}`)
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getDocsArchive({id}) {
  return await httpRequest.get(`driver/download-files-archive?id=${id}`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}


/**
 * Проверить актуальна ли информация водителя
 */
async function getActiveDrivers(filter) {
  return await httpRequest.post(`driver/get-active-drivers`,
    {
      "from": filter.load,
      "to": filter.unLoad,
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function hide(id) {
    return await httpRequest.get(`driver/hide?id=${id}`).then((response) => {
        if (response.status === 200) {
            return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
    });
}