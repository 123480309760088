import React from "react";

function CommentsBlock({ comments }) {
  if (comments) {
    return (
      <div className="comments-block">
        <h4>Комментарии</h4>
        <p className="comments-block__text">{comments}</p>
      </div>
    );
  } else {
    return <div />;
  }
}

export default function CarrierContentItem({ delivery, contentVisible }) {
  // const logistName = delivery.Logist ? delivery.Logist.Fio : "-";
  //
  // const logistPhone =
  //   delivery.Logist && delivery.Logist.Phone ? (
  //     <React.Fragment>
  //       <h4>Телефон</h4>
  //       <p>
  //         <span>{delivery.Logist.Phone}</span>
  //       </p>
  //     </React.Fragment>
  //   ) : (
  //     "-"
  //   );
  //
  // const placesUnload = delivery.PlacesUnload.map((placeUnload, idx) => {
  //   return <p key={idx + 1}>{placeUnload.Address}</p>;
  // });
  //
  return ( ""
  );
}
