import React, { useState, useEffect } from 'react';
import { Button, Col, Icon, Modal, notification, Row, Spin, Upload } from "antd";
import {OWNERSHIP_TYPES, trailService} from "../../../services";
import DividerBlock from "../../divider-block";
import FieldBlock from '../../field-block';
import InputField from "../../input-field";
import NumberInputField from "../../number-input-field";
import SelectField from "../../select-field";
import '../modals.css';
import {formHelper} from "../../../helpers";
import {FILE_TYPES_ONLY_IMG} from "../../../helpers/form-inputs-limits.helper";

export default function ModalCreateTrail({ companyId, driverId, isShow, setIsShow, setShowAttachDriverOrderDelivery, carryingCapacityValues, setIsNeedUpdateList = false }) {

    const [loadingTypes, setLoadingTypes] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fieldsErrors, setFieldsErrors] = useState([]);
    const [countryReg, setCountryReg] = useState('Россия');
    const [number, setNumber] = useState('');
    const [trailTypes, setTrailTypes] = useState(null);
    const [trailType, setTrailType] = useState(null);
    const [carrying, setCarrying] = useState(0);
    const [carryingValue, setCarryingValue] = useState(0);
    const [capacity, setCapacity] = useState(null);
    const [capacityValue, setCapacityValue] = useState(0);
    const [carryingCapacity, setCarryingCapacity] = useState(null);
    const [pallets, setPallets] = useState(0);
    const [ownershipType, setOwnershipType] = useState('');
    const [scanSts1, setScanSts1] = useState(null);
    const [scanSts2, setScanSts2] = useState(null);

    useEffect(() => {
        if (!loadingTypes) {
            setLoading(true);
            trailService.getTrailTypes()
                .then((response) => {
                    setTrailType(response[0].id);
                    setTrailTypes(response);
                    setLoadingTypes(false);
                    setLoading(false);
                })
                .catch((error) => {
                    notification.error({
                        message: "Создание прицепа",
                        description: error,
                    });
                    setLoadingTypes(false);
                    setLoading(false);
                });
        }
    }, [isShow]);


    let bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px' };
    let dividerStyle = { margin: '15px 0px 45px 0px', display: "block", clear: "both", width: "100%", minWidth: "100%", height: "1px" }

    const compare = (a, b) => {
        return parseInt(a) - parseInt(b);
    }

    if (!carryingCapacity && carryingCapacityValues) {
        let carryingArr = Object.keys(carryingCapacityValues).sort(compare),
            capacityArr = carryingCapacityValues[carryingArr[0]];
        setCarrying(carryingArr);
        setCarryingValue(carryingArr[0]);
        setCapacity(capacityArr);
        setCapacityValue(capacityArr[0]);
        setCarryingCapacity(carryingCapacityValues);
    }

    const onClickCreateTrail = () => {
        setLoading(true);
        if (scanSts1 == null) {
            notification.error({
                message: "Отсутствует скан СТС1",
                description: "",
            });
            setLoading(false);
            return false;
        }
        if (scanSts2 == null) {
            notification.error({
                message: "Отсутствует скан СТС2",
                description: "",
            });
            setLoading(false);
            return false;
        }
        if (trailType === "Без прицепа") {
            setTrailType(1);
        }

        const trailData = {
            companyId: companyId,
            driverId: driverId,
            countryReg: countryReg,
            number: number,
            carrying: carryingValue,
            capacity: capacityValue,
            type: trailType,
            pallets: pallets,
            scanSts1: scanSts1,
            scanSts2: scanSts2,
            ownershipType: ownershipType,
        };


        trailService.create(trailData)
            .then((response) => {
                setLoading(false);

                if (response.status === 'success') {
                    notification.success({
                        message: "Создание прицепа",
                        description: response.message,
                    });

                    setCountryReg('Россия');
                    setNumber('');
                    setTrailType(0);
                    setCarryingValue(0);
                    setCapacityValue(0);
                    setPallets(0);
                    setScanSts1(null);
                    setScanSts2(null);
                    setIsShow(false);
                    setShowAttachDriverOrderDelivery(true);
                    setIsNeedUpdateList(true);

                } else if (response.status === 'error') {
                    setFieldsErrors(response.errors);
                }
            })
            .catch((error) => {
                notification.error({
                    message: "Создание прицепа",
                    description: error,
                });

                setLoading(false);
            });
    };

    const getFieldErrors = (nameField) => {
        return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
            ? fieldsErrors[nameField][0]
            : null;
    };

    let handleChange = info => {
        let fileList = [];
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
        });
    };

    const uploaderPropsScanSts1 = {
        multiple: false,
        showUploadList: true,
        onRemove: () => {
            setScanSts1(null);
        },
        beforeUpload: (file) => {
            file.url = URL.createObjectURL(
              file instanceof File
                ? file
                : formHelper.b64toBlob(file, "image/png")
            );

            let isCorrectType = FILE_TYPES_ONLY_IMG.includes(file.type.split('/')[1]);

            if (isCorrectType) {
                setScanSts1(file);
            } else {
                notification.error({
                    message: "Загрузка файла",
                    description: `Недопустимый формат`,
                });
            }
            return false;
        },
        listType: 'picture',
        fileList: scanSts1 ?
          [{
              uid: '-3',
              name: scanSts1.name,
              url: !(scanSts1 instanceof File) ? scanSts1 : URL.createObjectURL(scanSts1),
              status: 'done'
          }] :
          [],
    };
    const uploaderPropsScanSts2 = {
        multiple: false,
        showUploadList:  true,
        onRemove: () => {
            setScanSts2(null);
        },
        beforeUpload: (file) => {
            file.url = URL.createObjectURL(
              file instanceof File
                ? file
                : formHelper.b64toBlob(file, "image/png")
            );

            let isCorrectType = FILE_TYPES_ONLY_IMG.includes(file.type.split('/')[1]);

            if (isCorrectType) {
                setScanSts2(file);
            } else {
                notification.error({
                    message: "Загрузка файла",
                    description: `Недопустимый формат`,
                });
            }
            return false;
        },
        listType: 'picture',
        fileList: scanSts2 ?
          [{
              uid: '-3',
              name: scanSts2.name,
              url: !(scanSts2 instanceof File) ? scanSts2 : URL.createObjectURL(scanSts2),
              status: 'done'
          }] :
          [],
    };

    return (
        <Modal
            title={"Добавление прицепа"}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
                setShowAttachDriverOrderDelivery(true);
            }}
            destroyOnClose={true}
            footer={[
                <Button htmlType="submit" key="submit" type="primary" style={bigButtonStyle} onClick={onClickCreateTrail}>Добавить</Button>,
            ]}
        >
            <Spin spinning={loading} tip="Загрузка...">
                <Row gutter={[16, 24]}>
                    <Col lg={12} md={24} sm={24} sx={24}>
                        <SelectField
                            title="Страна регистрации"
                            name="countryReg"
                            selectedValue={countryReg}
                            values={[
                                { Id: 'Россия', Name: 'Россия' },
                                { Id: 'Беларусь', Name: 'Беларусь' },
                                { Id: 'Казахстан', Name: 'Казахстан' },
                                { Id: 'Другая страна', Name: 'Другая страна' }
                            ]}
                            onChange={(value) => {
                                setCountryReg(value);
                            }}
                            getErrors={getFieldErrors}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} sx={24}>
                        <InputField
                            name="number" label="Госномер"
                            value={number} setValue={setNumber}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 24]}>
                    <Col lg={12} md={24} sm={24} sx={24}>
                        <SelectField
                            showSearch={false}
                            title="Грузоподъемность (тонны)"
                            name="carrying"
                            selectedValue={carryingValue}
                            values={carrying}
                            onChange={(value) => {
                                setCapacity(carryingCapacity[value]);
                                setCapacityValue(carryingCapacity[value][0]);
                                setCarryingValue(value);
                            }}
                            getErrors={getFieldErrors}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col lg={6} md={12} sm={12} sx={12}>
                        <SelectField
                            showSearch={false}
                            title="Вместимость (в м3)"
                            name="capacity"
                            selectedValue={capacityValue}
                            values={capacity}
                            onChange={(value) => {
                                setCapacityValue(value);
                            }}
                            getErrors={getFieldErrors}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col lg={6} md={12} sm={12} sx={12}>
                        <NumberInputField
                            name="pallets" label="Количество паллет"
                            value={pallets} setValue={setPallets}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={12} xs={12}>
                        <SelectField
                            name="ownershipType"
                            title="Тип владения"
                            values={OWNERSHIP_TYPES}
                            selectedValue={ownershipType}
                            onChange={(value) => {
                                setOwnershipType(value);
                            }}
                            getErrors={getFieldErrors}
                            style={{width: "100%"}}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 24]}>
                    <Col lg={24} md={24} sm={24} sx={24}>
                        <SelectField
                            name="type"
                            title="Тип кузова"
                            values={trailTypes}
                            selectedValue={trailType}
                            onChange={(value) => {
                                setTrailType(value);
                            }}
                            getErrors={getFieldErrors}
                            style={{ width: "100%" }}
                        />
                    </Col>
                </Row>

                <span className="divider-label">Загрузите сканы документов</span>
                <DividerBlock style={dividerStyle} />
                <Row gutter={[16, 24]}>
                    <Col lg={12} md={24} sm={24} sx={24}>
                        <FieldBlock name="scanSts1" getErrors={getFieldErrors}>
                            <Upload className="single-btn-upload" {...uploaderPropsScanSts1} onChange={handleChange} defaultFileList={null}>
                                <Button htmlType="button">
                                    <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
                                        <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
                                        <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
                                        <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
                                    </svg> Скан СТС (1 сторона)
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} sx={24}>
                        <FieldBlock name="scanSts2" getErrors={getFieldErrors}>
                            <Upload className="single-btn-upload" {...uploaderPropsScanSts2}>
                                <Button htmlType="button">
                                    <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
                                        <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
                                        <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
                                        <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
                                    </svg> Скан СТС (2 сторона)
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}
