import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { notification, Pagination } from "antd";
import { orderDeliveryService, userServices } from "../../../../../services";
import { useFetchDeliveriesList } from "../../../../../hooks/use-fetch-deliveries-list";
import Error from "../../../../error";
import Spinner from "../../../../spinner";
import DeliveryItem from "../../../../delivery-item-block";
import LogistBookedContentItem from "../../../../delivery-item-content/logist/booked-content-item";
import DeliveryEmptyContent from "../../../../delivery-empty-content";
import ModalAttachDriverOrderDelivery from "../../../../modals/attach-driver-order-delivery";
import { AppContext } from "../../../../../contexts/app-context";
import { VIEW_BOOKED_DELIVERIES_LOGIST } from "../../../../../helpers/user_permission.helper";
import UserNotificationMessage from "../../../../user-message";
import NotificationMessage from "../../../../modals/notification-message";
import Price from "../../../../price";
import { DELIVERY_ORDER_APPROVED, DELIVERY_ORDER_STARTED } from "../../../../../helpers/delivery_status.helper";
import DeliveryGroupList from "../../../../delivery-group-list";
import DeterminedPrice from "../../../../determined-price";
import { deliveriesConfig } from "../../../../../config";

function ButtonsApprovedOrder({ delivery, dateShipmentRus, setNeedUpdate, setStopListUpdate, isForeignDelivery }) {
	const [showModalAttachDriver, setShowModalAttachDriver] = useState(false);

	if (delivery.Permissions.IsAccessCreatedOrder) {
		const deliveryRegionLastPointUnload = delivery.PlacesUnload.length ? delivery.PlacesUnload[delivery.PlacesUnload.length - 1].Region : "-";

		const deliveryRegionLoad = delivery.PlaceLoad.Region ? delivery.PlaceLoad.Region : "-";

		return (
			<React.Fragment>
				<button
					className="btn typical-btn typical-btn-green"
					onClick={(e) => {
						e.preventDefault();
						setStopListUpdate(true);
						setShowModalAttachDriver(true);
						orderDeliveryService
							.start(delivery.Order.Id)
							.catch((error) => {
								notification.error({
									message: "Начало заполнения заявки",
									description: error
								})
								setShowModalAttachDriver(false);
							});
					}}
				>
					Заполнить заявку
				</button>
				<ModalAttachDriverOrderDelivery
					key={`modal_${delivery.Id}`}
					isShow={showModalAttachDriver}
					setIsShow={setShowModalAttachDriver}
					setNeedUpdateDeliveryList={setNeedUpdate}
					orderId={delivery.Order.Id}
					setStopListUpdate={setStopListUpdate}
					companyId={delivery.Order.Company.Id}
					deliveryDateShipmentRus={dateShipmentRus}
					deliveryPrice={delivery.Order.Price}
					deliveryRegionLoad={deliveryRegionLoad}
					deliveryRegionUnload={deliveryRegionLastPointUnload}
					isForeignDelivery={isForeignDelivery}
          deliveryContractType={delivery.ContractType}
				/>
			</React.Fragment>
		);
	} else {
		return <div />;
	}
}

function HeaderItemApprovedOrder({
	delivery,
	dateShipmentRus,
	setLoadingDelivItem,
	setNeedUpdate,
	setStopListUpdate,
	nameRegionLastPointUnload,
	regionLoad,
}) {
	const isForeignDelivery = delivery.IsForeignDelivery;

	return (
		<div className="application-topline">
			<div className="application-topline-logist">
				<div className="application-topline-logist-item">
					<div className="application-topline-carrier-item-col application-topline-carrier-item-col-city">
						<p className="typical-icon-field icon-field-patch" title={regionLoad}>
							{regionLoad}
						</p>
						<p className="typical-icon-field icon-field-dispatch" title={nameRegionLastPointUnload}>
							{nameRegionLastPointUnload}
						</p>
					</div>
				</div>
				<div className="application-topline-logist-item">
					<div className="application-topline-logist-item application-topline-logist-item-determined">
						<DeterminedPrice delivery={delivery} />
						<div className="application-topline-logist-item-col application-topline-logist-item-col-date">
							<div className="typical-icon-field icon-field-calendar">{dateShipmentRus}</div>
						</div>
					</div>
					<div className="application-topline-logist-item-col application-topline-logist-item-col-button">
						<ButtonsApprovedOrder
							key={`${delivery.Id}_${delivery.Order.Status}`}
							delivery={delivery}
							dateShipmentRus={dateShipmentRus}
							setLoadingDelivItem={setLoadingDelivItem}
							setNeedUpdate={setNeedUpdate}
							setStopListUpdate={setStopListUpdate}
							isForeignDelivery={isForeignDelivery}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

function HeaderItemFilledOrder({ delivery, dateShipmentRus, setLoadingDelivItem, setNeedUpdate, nameRegionLastPointUnload, regionLoad }) {
	const onClickAgreeWithoutDocs = (e) => {
		e.preventDefault();
		setLoadingDelivItem(true);

		orderDeliveryService
			.agreeWithoutDocs(delivery.Order.Id)
			.then((response) => {
				notification.success({
					message: "Утверждение заявки без документов",
					description: response.message,
				});

				setLoadingDelivItem(false);
				setNeedUpdate(true);
			})
			.catch((error) => {
				notification.error({
					message: "Утверждение заявки без документов",
					description: error,
				});

				setLoadingDelivItem(false);
				setNeedUpdate(true);
			});
	};

	let btnAgreeWithoutDocs;

	if (delivery.Permissions.IsAvailableAgreeWithoutDocs) {
		btnAgreeWithoutDocs = (
			<div className="application-topline-logist-item-col">
				<button className="btn typical-btn typical-btn-blue typical-btn-l" onClick={onClickAgreeWithoutDocs}>
					Утвердить без документов
				</button>
			</div>
		);
	}

	return (
		<div className="application-topline">
			<div className="application-topline-logist">
				<div className="application-topline-logist-item">
					<div className="application-topline-carrier-item-col application-topline-carrier-item-col-city">
						<p className="typical-icon-field icon-field-patch" title={regionLoad}>
							{regionLoad}
						</p>
						<p className="typical-icon-field icon-field-dispatch" title={nameRegionLastPointUnload}>
							{nameRegionLastPointUnload}
						</p>
					</div>
					<div className="application-topline-logist-item-col application-topline-logist-item-col-date">
						<div className="typical-icon-field icon-field-calendar">{dateShipmentRus}</div>
					</div>
				</div>
				<div className="application-topline-logist-item">
					<div className="application-topline-logist-item-col application-topline-logist-item-col-price">
						<Price delivery={delivery} />
					</div>
					<div className="application-topline-logist-item-col application-topline-logist-item-col-button">
						{btnAgreeWithoutDocs}
					</div>
				</div>
			</div>
		</div>
	);
}

export default function LogistBookedDeliveries() {
	const [wasOpenedBeforeUpdate, setWasOpenedBeforeUpdate] = useState({});

	const user = userServices.getUser();
	const [offset, setOffset] = useState(0);
	const [step, setStep] = useState(deliveriesConfig.pageCount);
	const [currentPage, setCurrentPage] = useState(1);

	const appContext = useContext(AppContext);

	useEffect(() => {
		appContext.initFilter();
	}, []);

	const [{ error, loading, data: deliveries, setStopListUpdate, setNeedUpdate, notifications, permissions, totalCount }] = useFetchDeliveriesList(
		"delivery/get-booked",
		true,
		true,
		true,
		true,
		deliveriesConfig.secondForUpdate,
		offset,
		step
	);

  if (!user.isAccessTo(VIEW_BOOKED_DELIVERIES_LOGIST)) {
      return <Error status={403} desc="Страница не доступна" />;
  }

	if (error) {
		return <Error type={500} desc={error.message} />;
	}

	let onChange = (page, pageSize) => {
		if (page == currentPage) {
			return false;
		} else {
			setCurrentPage(page);
		}

		let val = 0;
		if (parseInt(page) == 1) {
			setOffset(0);
		} else {
			val = page * pageSize - pageSize;
			setOffset(val);
		}
		appContext.filter.offset = val;
		setNeedUpdate(true);
	};

	let listItems = [];

	if (!loading) {
		listItems = deliveries.map((delivery) => {
			const dateShipmentRus = moment(delivery.DateShipment).format("D MMMM YYYY");

			const header =
				delivery.Order.Status === DELIVERY_ORDER_APPROVED || delivery.Order.Status === DELIVERY_ORDER_STARTED
					? HeaderItemApprovedOrder
					: HeaderItemFilledOrder;

			return (
				<DeliveryItem
					key={`${delivery.Id}_${delivery.Order.Status}`}
					header={header}
					content={LogistBookedContentItem}
					delivery={delivery}
					dateShipmentRus={dateShipmentRus}
					setNeedUpdate={setNeedUpdate}
					setStopListUpdate={setStopListUpdate}
					isBooked
					wasOpenedBeforeUpdate={wasOpenedBeforeUpdate}
					setWasOpenedBeforeUpdate={setWasOpenedBeforeUpdate}
					permissions={permissions}
				/>
			);
		});
	}

	return (
		<Spinner loading={loading}>
			<UserNotificationMessage />
			<NotificationMessage notifications={notifications} setNeedUpdate={false} setStopListUpdate={setStopListUpdate} loading={loading} />
			{totalCount != 0 ?
				<div className="application-block pagination pagination__up">
					<Pagination
						style={{ textAlign: "center" }}
						current={currentPage}
						pageSize={step}
						total={totalCount}
						showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
						onChange={onChange}
					/>
				</div>
				: null}
			{!loading ? deliveries.length ? <DeliveryGroupList deliveriesItems={listItems} /> : <DeliveryEmptyContent /> : null}
			{totalCount != 0 ?
				<div className="application-block pagination pagination__down">
					<Pagination
						style={{ textAlign: "center" }}
						current={currentPage}
						pageSize={step}
						total={totalCount}
						showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
						onChange={onChange}
					/>
				</div>
				: null}
		</Spinner>
	);
}
