import React, { useEffect, useState } from 'react';
import Spinner from '../../../spinner';
import { Button, Col, Table, Row, Tabs } from 'antd';
import NotificationMessage from "../../../modals/notification-message";
import { useFetchNotification } from "../../../../hooks/use-fetch-notification";
import Drivers from "./drivers"
import Trailers from "./trailers"
import Trucks from "./trucks"
import { companyService } from "../../../../services";

export default function CarPark() {
	const [loading, setLoading] = useState(false);
	const [stopListUpdate, setStopListUpdate] = useState(false);

	const [{ notifications }] = useFetchNotification(true);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [companyId, setCompanyId] = useState(null);

	useEffect(() => {
		if (!dataLoaded) {
			setLoading(true);
			companyService.getCompanyId().then((response) => {
					setCompanyId(response);
					setDataLoaded(true);
					setLoading(false);
				})
		}
	}, [dataLoaded]);

	const { TabPane } = Tabs;
	function callback(key) {
		console.log(key);
	}

	return (
		<React.Fragment>
			<NotificationMessage notifications={notifications} setStopListUpdate={setStopListUpdate} loading={false} />
			<Col span={24}>
				<Spinner loading={loading} tip="Загрузка...">
					<Tabs defaultActiveKey="1" onChange={callback}>
						<TabPane tab="Водители" key="1">
							<Drivers companyId={companyId} />
						</TabPane>
						<TabPane tab="Автомобили" key="2">
							<Trucks companyId={companyId} />
						</TabPane>
						<TabPane tab="Прицепы" key="3">
							<Trailers companyId={companyId} />
						</TabPane>
					</Tabs>
				</Spinner>
			</Col>
		</React.Fragment>
	);
}
