import React, {useEffect, useState} from "react";
import {Button, Col, notification, Spin} from "antd";
import {orderDeliveryService} from "../../../services";
import ModalChooseLoadingGate from "../choose-time-loading";

export default function Step5SendingInfo({
                                           orderId,
                                           filledData,
                                           setButtonsModal,
                                           currentModalStep,
                                           setModalStep,
                                           onCloseModal,
                                           deliveryId,
                                           date
                                         }) {

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModalLoadingSignUpShow, setIsModalLoadingSignUpShow] = useState(false);

  let bigButtonStyleEmpty = {
    width: '200px',
    height: '43px',
    background: '#BE1C1C',
    position: 'static',
    borderRadius: '10px',
    color: "white",
    borderColor: "transparent"
  };

  useEffect(() => {

    setButtonsModal(null);
    setLoading(true);

  }, []);

  if (errorMessage) {
    return (
      <Col span={24} className="steps-checking-verified-info__error-response">
        <span>{errorMessage}</span>
      </Col>
    );
  }

  if (loading) {

    const cancelBtn = [
      <Button htmlType="button" key="close"
              onClick={() => {
                onCloseModal();
              }}
              style={bigButtonStyleEmpty}
      >
        Закрыть
      </Button>
    ];

    /**
     * Заполнение заказа (прикрепление водителя к заказу на рейс)
     */

    orderDeliveryService
      .attachDriver(
        orderId,
        filledData.selectedDriver,
        filledData.selectedTruck,
        filledData.selectedTrail,
        (filledData.withoutProcuration === false) ? filledData.dateProcuration : null,
        (filledData.withoutProcuration === false) ? filledData.numberProcuration : null,
        filledData.selectedForeignPartner,
        filledData.containerNumber
      ).then((response) => {

      setLoading(false);
      setButtonsModal(cancelBtn);

      if (response.status === 'success') {
        notification.success({
          message: "Заполнение заявки",
          description: response.message,
        });

        setIsModalLoadingSignUpShow(true);

      } else if (response.status === 'error') {
        setErrorMessage(response.message);
      }

    }).catch((error) => {
      setLoading(false);
      setButtonsModal(cancelBtn);
      setErrorMessage(error);
    });
  }

  return (
    <>
      <ModalChooseLoadingGate id={deliveryId}
                              isShow={isModalLoadingSignUpShow}
                              onCloseModal={() => {
                                setIsModalLoadingSignUpShow(false);
                                setModalStep(currentModalStep + 1);
                              }}
                              setNeedUpdate={() => {}}
                              date={date}
      />
      <div style={{textAlign: "center", padding: "20px"}}>
        <Spin spinning={loading} size="large" tip='Подождите, происходит отправка данных'/>
      </div>
    </>
  );
}
