import React from "react";
import {Select, Form, Radio} from "antd";
import RadioGroup from "antd/lib/radio/group";
import './custom-radio-group.less';

export default function RadioField({
  title,
  name,
  selectedValue,
  values = [],
  onChange,
  getErrors,
  notFoundText = "Отсутствуют значения",
  style = { height: "50px" },
  getFieldDisabled,
  isDisabled = false,
  keyNum = null
}) {
  const fieldErrors = keyNum !== null ? getErrors(name, keyNum) : getErrors(name);

  const fieldTitle = title ? (
    <div className="form-field-label">{title}</div>
  ) : null;

  if (!values || values.length < 1) return null;

  if (fieldErrors) {
    return (
      <React.Fragment>
        {fieldTitle}
        <Form.Item validateStatus="error" help={fieldErrors}>
          <RadioGroup disabled={isDisabled} onChange={onChange} value={selectedValue} className={'custom-radio-group'}>
            {values.map((itemValue, index) => {
              if (typeof itemValue === "number" || typeof itemValue === "string")
                return (
                    <Radio value={itemValue}>index</Radio>
                );
              return (
                <Radio
                    value={itemValue.Id ? itemValue.Id : itemValue.id}
                >
                  {itemValue.Name || itemValue.title || itemValue.name || itemValue.Fio}
                </Radio>
              );
            })}
          </RadioGroup>
        </Form.Item>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {fieldTitle}
      <Form.Item>
        <RadioGroup
          disabled={isDisabled}
          onChange={onChange}
          value={selectedValue}
          className={'custom-radio-group'}
        >
          {values.map((itemValue, index) => {
            if (typeof itemValue === "number" || typeof itemValue === "string")
              return (
                <Radio.Button
                  value={itemValue}
                  key={index}
                ></Radio.Button>
              );
            return (
              <Radio.Button
                key={index}
                value={itemValue.Id ? itemValue.Id : itemValue.id}
              >
                {itemValue.Name ||
                  itemValue.title ||
                  itemValue.name ||
                  itemValue.Fio}
              </Radio.Button>
            );
          })}
        </RadioGroup>
      </Form.Item>
    </React.Fragment>
  );
}
