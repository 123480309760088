import React from "react";
import moment from "moment";

function CommentsBlock({ comments }) {
  if (comments) {
    return (
      <div className="comments-block">
        <h4>Комментарии</h4>
        <p className="comments-block__text">{comments}</p>
      </div>
    );
  } else {
    return <div />;
  }
}

export default function LogistBookedContentItem({ delivery, setLoadingDelivItem, setNeedUpdate, setStopListUpdate, contentVisible }) {
  const carrierCompanyPersonName = typeof delivery.Order.Company.Contact.Fio !== "undefined" ? delivery.Order.Company.Contact.Fio : "-";

  const carrierCompanyPersonPhone =
    typeof delivery.Order.Company.Contact.Phone !== "undefined" && delivery.Order.Company.Contact.Phone ? delivery.Order.Company.Contact.Phone : "-";

  const carrierCompany = delivery.Order.LegalEntity.Name !== null && delivery.Order.LegalEntity.Name ? delivery.Order.LegalEntity.Name : "-";

  const carrierDriverFio =
    delivery.Order.Company.Driver !== null && typeof delivery.Order.Company.Driver.Name !== "undefined" ? delivery.Order.Company.Driver.Name : "-";

  const placesUnload = delivery.PlacesUnload.map((placeUnload, idx) => {
    return <p key={idx + 1}>{placeUnload.Address}</p>;
  });

  const dateCreatedRus = moment(delivery.DateCreated).format("D MMMM YYYY");
  return (
    <div className={`application-info ${contentVisible ? "application-info-visible" : ""}`}>
      <div className="application-info-row">
        <div className="application-info-col">
          <div className="application-info-col-item">
            <h4>Номер рейса</h4>
            <p>{delivery.NumberDelivery}</p>
          </div>
          <div className="application-info-col-item">
            <h4>Дата создания рейса</h4>
            <p>{dateCreatedRus}</p>
          </div>
          <div className="application-info-col-item">
            <h4>Юр. лицо</h4>
            <p>{delivery.CustomerCompany}</p>
          </div>
          <div className="application-info-col-item">
            <h4>Водитель</h4>
            <p>{carrierDriverFio}</p>
          </div>
        </div>
        <div className="application-info-col">
          <div className="application-info-col-item">
            <h4>Отвественный</h4>
            <p>{carrierCompanyPersonName}</p>
          </div>
          <div className="application-info-col-item">
            <h4>Телефон</h4>
            <p>{carrierCompanyPersonPhone}</p>
          </div>
          <div className="application-info-col-item">
            <h4>Загрузка</h4>
            <p>{delivery.PlaceLoad.Address}</p>
          </div>
          <div className="application-info-col-item">
            <h4>Выгрузка</h4>
            {placesUnload}
          </div>
        </div>
        <div className="application-info-col">
          <div className="application-info-col-item">
            <h4>Юр.лицо перевозчика</h4>
            <p>{carrierCompany}</p>
          </div>
          <div className="application-info-col-item application-info-col-item-columned">
            <div className="application-info-col-item">
              <h4>Тип груза</h4>
              <p>{delivery.CargoType}</p>
            </div>
            <div className="application-info-col-item">
              <h4>Вес, т</h4>
              <p>{(delivery.Weight / 1000).toFixed(2)}</p>
            </div>
          </div>
          <div className="application-info-col-item application-info-col-item-columned">
            <div className="application-info-col-item">
              <h4>Режим</h4>
              <p>{delivery.CargoMode}</p>
            </div>
            <div className="application-info-col-item">
              <h4>
                Обьем, м<sup>3</sup>
              </h4>
              <p>{delivery.Volume}</p>
            </div>
          </div>
          <CommentsBlock comments={delivery.Comments} />
        </div>
      </div>
    </div>
  );
}
