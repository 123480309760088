import React, {useCallback, useEffect, useState} from "react";
import {Button, Col, Modal, Row, Spin, Form, notification, Steps, Icon} from "antd";
import {companyService, systemService} from "../../../services";
import InputField from "../../input-field";
import DividerBlock from "../../divider-block";
import SelectField from "../../select-field";
import LegalEntityFormFields from "./form-fields";
import {legalEntityService} from "../../../services/legal-entity.service";

export default function ModalEditCompany({legalEntityID, countryId, isShow, setIsShow}) {
  const [loading, setLoading] = useState(false);
  const [legalEntityData, setLegalEntityData] = useState({});
  const [formFieldsValues, setFormFieldsValues] = useState({});
  const [fieldsErrors, setFieldsErrors] = useState({});

  let submitBtnStyle = {
    width: "200px",
    height: "43px",
    background: "#1CAA53",
    position: "static",
    borderRadius: "10px",
    color: "white",
    borderColor: "transparent",
  };

  useEffect(() => {
    if (isShow) {
      setLoading(true);

      companyService
        .getLegalEntityData(legalEntityID)
        .then((response) => {

          setLegalEntityData(response);
          let newData
          fields.map((item) => {
            const val = (response[item.key] === null || response[item.key] === undefined) ? '' : response[item.key];
            newData = {...newData, [item.key]: val}
          });
          setFormFieldsValues({...newData});

          setLoading(false);

        })
        .catch((error) => {
          notification.error({
            message: "Получение списка организационных форм",
            description: error,
          });
        });
    }
  }, [isShow]);

  function getBanks(bic, setBanksList) {
    systemService.getBanksList(bic)
      .then(data => {
        setBanksList(data);
      })
      .catch();
  }

  let editLegalEntity;

  let fields;
  const fieldsRus = [
    {key:"Name", title: "Название"},
    {key:"FullName", title: "Полное название"},
    {key:"FirstName", title: "Имя"},
    {key:"Patronymic", title: "Отчество"},
    {key:"SecondName", title: "Фамилия"},
    {key:"Bic", title: "БИК банка"},
    {key:"Rs", title: "Номер счёта"},
    {key:"RegAddress", title: "Адрес"},
    {key:"LegalAddress", title: "Юридический адрес"},
    {key:"MailingAddress", title: "Почтовый адрес"}
  ]
  const fieldsKaz = [
    {key:"Name", title: "Название"},
    {key:"FullName", title: "Полное название"},
    {key:"FirstName", title: "Имя"},
    {key:"Patronymic", title: "Отчество"},
    {key:"SecondName", title: "Фамилия"},
    {key:"Bic", title: "БИК банка"},
    {key:"BankName", title: "Наименование банка"},
    {key:"Rs", title: "Номер счёта"},
    {key:"RegAddressRegion", title: "Регион"},
    {key:"RegAddressLocality", title: "Населенный пункт"},
    {key:"RegAddressStreet", title: "Улица"},
    {key:"RegAddressHouseNumber", title: "Номер дома"},
    {key:"RegAddressPremiseNumber", title: "Номер помещения"},
    {key:"RegAddressPostalCode", title: "Почтовый индекс"},
    {key:"LegalAddressRegion", title: "Регион"},
    {key:"LegalAddressStreet", title: "Улица"},
    {key:"LegalAddressLocality", title: "Населенный пункт"},
    {key:"LegalAddressHouseNumber", title: "Номер дома"},
    {key:"LegalAddressPremiseNumber", title: "Номер помещения"},
    {key:"LegalAddressPostalCode", title: "Почтовый индекс"}
  ]
  const fieldsBel = [
    {key:"Name", title: "Название"},
    {key:"FullName", title: "Полное название"},
    {key:"FirstName", title: "Имя"},
    {key:"Patronymic", title: "Отчество"},
    {key:"SecondName", title: "Фамилия"},
    {key:"Bic", title: "БИК банка"},
    {key:"BankName", title: "Наименование банка"},
    {key:"AccountNumber", title: "Номер счёта"},
    {key:"AddressCity", title: "Город"},
    {key:"Address", title: "Полный адрес"},
    {key:"MailingAddress", title: "Почтовый адрес"}
  ]

  switch (countryId) {
    default:
    case 1:
      fields = fieldsRus;
      editLegalEntity = legalEntityService.editRusLegalEntityData;
      break;
    case 6:
      fields = fieldsKaz;
      editLegalEntity = legalEntityService.editKazLegalEntityData;
      break;
    case 3:
      fields = fieldsBel;
      editLegalEntity = legalEntityService.editBelLegalEntityData;
      break;
  }

  const submitHandler = () => {
    editLegalEntity(legalEntityID, formFieldsValues)
      .then(response => {
        if (response.code === 400) {
          notification.error({
            message: "Редактирование юридического лица",
            description: response.message,
          });
          setFieldsErrors(response.errors);
        } else {
          notification.success({
            message: "Редактирование юридического лица",
            description: response.message,
          });

          setIsShow(false);
        }

        setLoading(false);
      })
      .catch(errMsg => {
        notification.error({
          message: "Редактирование юридического лица",
          description: errMsg,
        });

        setLoading(false);
      });
  }

  return (
    <Modal
      title={`Редактирование юр. лица`}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
      }}
      footer={
        <div style={{marginTop: "10"}}>
          <Button htmlType="submit" key="submit" style={submitBtnStyle} onClick={() => submitHandler()} type="primary">
            Сохранить
          </Button>
        </div>
      }
    >
      <Spin spinning={loading} tip="Загрузка...">
        <Form>
          <Row gutter={[16, 24]}>
            <LegalEntityFormFields
              data={legalEntityData}
              setFormFieldsValues={setFormFieldsValues}
              formFieldsValues={formFieldsValues}
              fields={fields}
            />
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}
