import React, {useEffect, useState} from 'react';
import {companyService, consignorService} from '../../../services';
import {Modal, Input, notification, Button, Table, Row, Col} from 'antd';
import Spinner from "../../spinner";
import {legalEntityDataAliases} from "../../../helpers/doc_type.helper";
import {legalEntityService} from "../../../services/legal-entity.service";
import axios from "axios";
import {urlHelper} from "../../../helpers/url.helper";
import {fileHelper} from "../../../helpers/file.helper";

export default function ModalLegalEntityFullInfo({ legalEntityId, isShow, setIsShow }) {

  const [loading, setLoading] = useState(true);

  const [legalEntityInfo, setLegalEntityInfo] = useState([]);

  const [columns, setColumns] = useState([
      {
          title: '',
          dataIndex: 'name',
          key: 'name',
      },
      {
          title: '',
          key: 'data',
          render: (record) => {
              function isUrl(string) {
                  let regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
                  return regexp.test(string);
              }

              if (isUrl(record.data)) {
                  return (
                      <React.Fragment>
                          <a href={record.data} target="_blank" rel="noopener noreferrer" className="active">Открыть</a>
                      </React.Fragment>
                  );
              } else {
                  if (record.data === 0 || record.data === 1) {
                      return record.data ? 'Да' : 'Нет';
                  }
                  return record.data
              }
          }
      },
  ]);

  useEffect(() => {
        if (isShow) {
            setLoading(true);

            companyService.getLegalEntityFullInfo(legalEntityId)
                .then((response) => {
                    let data = [];
                    Object.keys(response).forEach((element) => {
                        if (response[element] !== null && response[element] !== '') {
                            if (typeof legalEntityDataAliases[element] !== 'undefined')
                            data.push(
                                {
                                    name: legalEntityDataAliases[element],
                                    data: response[element]
                                }
                            );
                        }
                    });

                    Object.keys(response.Docs).forEach((element) => {
                        if (response.Docs[element] !== null) {
                            let document = response.Docs[element];
                            if (typeof legalEntityDataAliases[document.Name] !== 'undefined') {
                                data.push(
                                    {
                                        name: legalEntityDataAliases[document.Name],
                                        data: document.Url
                                    }
                                );
                            } else if (document.ReadableName) {
                                data.push(
                                    {
                                        name: document.ReadableName,
                                        data: document.Url
                                    }
                                );
                            }
                        }
                    });

                    setLegalEntityInfo(data);
                    setLoading(false);
                })
                .catch((err) => {
                    notification.error({
                        message: "Получение списка заявок",
                        description: err
                    });
                    setLoading(false);
                    setIsShow(false);
                });
        }

  }, [isShow]);

  const handleDownloadDocsArchive = (id) => {
      legalEntityService.getLegalEntityDocsArchive({id})
          .then(resp => {
              fileHelper.downloadFileBase64(resp, 'Документы юр.лица.zip')
          })
          .catch(err => {
              notification.error({
                  message: "Загрузка архива",
                  description: err
              });
          });
  };

  return (
      <Modal
          title={'Информация о юр.лице перевозчика'}
          cancelText="Закрыть"
          className="modal-window"
          align={null}
          visible={isShow}
          onCancel={() => {
              setIsShow(false);
          }}
          footer={null}
      >
          <Spinner loading={loading} tip="Загрузка...">
              <Table
                  columns={columns}
                  dataSource={legalEntityInfo}
                  rowKey="name"
                  pagination={false}
                  size="small"
                  showHeader={false}
              />
          </Spinner>
          <Row gutter={[10, 10]}>
              <Col offset={8} span={8}>
                  <Button
                      htmlType="button"
                      key="submitPrev"
                      onClick={() => handleDownloadDocsArchive(legalEntityId)}
                      type="primary"
                      size="large"
                      style={{width: '100%'}}
                  >
                      Скачать документы в архиве
                  </Button>
              </Col>
          </Row>
      </Modal>
  );

}
