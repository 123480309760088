import React, { useState, useEffect } from "react";
import {Button, Col, Modal, notification, Popconfirm, Row, Spin} from "antd";
import '../../../components/modals/modals.css';
import {companyService, driverService, trailService, truckService} from "../../../services";
import moment from "moment";

export default function ModalShowCarParkInfo({
												 isShow,
												 onCloseModal,
												 driverId = null,
												 truckId = null,
												 trailId = null,
												 info,
}) {

	const [loadingContentModal, setLoadingContentModal] = useState(true);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [data, setData] = useState([]);
	const [images, setImages] = useState([]);

	const bigButtonStyle = { width: '116px', height: '40px', background: '#1CAA53', borderRadius: '10px', borderColor: "#1CAA53" };
	const imgStyle = { width: "115px", height: "125px", border: "1px solid #1CAA53", borderRadius: "10px", display: "block", marginBottom: "15px" }

	useEffect(() => {
		if (!dataLoaded && isShow) {
			if (driverId !== null) {
				companyService.getCompanyDriversQuestionnaire(driverId).then((response) => {
					setDataLoaded(true);
					setData(response);
					setImages([
						{ link: response.scanDriverLicense1 },
						{ link: response.scanDriverLicense2 },
						{ link: response.scanPassportHomePage },
						{ link: response.scanPassportRegPage }
					]);
					setLoadingContentModal(false);
				})
			} else if (truckId !== null) {
				companyService.getCompanyTrucksQuestionnaire(truckId).then((response) => {
					setImages([
						{ link: response.scanSts1 },
						{ link: response.scanSts2 }
					]);
					setDataLoaded(true);
					setData(response);
					setLoadingContentModal(false);
				})
			} else if (trailId !== null) {
				companyService.getCompanyTrailsQuestionnaire(trailId).then((response) => {
					setImages([
						{ link: response.scanSts1, download: response.scanSts1 },
						{ link: response.scanSts2, download: response.scanSts2 }
					]);
					setDataLoaded(true);
					setData(response);
					setLoadingContentModal(false);
				})
			}
		}
	}, [dataLoaded, isShow]);

	const archiveObject = (type, id) => {
		setLoadingContentModal(true);
		if (type === 'driver') {
			driverService
				.hide(id)
				.then(resp => {
					onCloseModal(true);
					notification.success({
						message: "Архивация",
						description: resp.message,
					});
				})
				.catch(err => {
					notification.error({
						message: "Архивация",
						description: err,
					});
				})
				.finally(() => setLoadingContentModal(false));
		} else if (type === 'truck') {
			truckService
				.hide(id)
				.then(resp => {
					onCloseModal(true);
					notification.success({
						message: "Архивация",
						description: resp.message,
					});
				})
				.catch(err => {
					notification.error({
						message: "Архивация",
						description: err,
					});
				})
				.finally(() => setLoadingContentModal(false));
		} else if (type === 'trail') {
			trailService
				.hide(id)
				.then(resp => {
					onCloseModal(true);
					notification.success({
						message: "Архивация",
						description: resp.message,
					});
				})
				.catch(err => {
					notification.error({
						message: "Архивация",
						description: err,
					});
				})
				.finally(() => setLoadingContentModal(false));
		} else {
			notification.error({
				message: "Архивация",
				description: 'Неизвестный тип',
			});
			setLoadingContentModal(true);
		}
	}

	if (driverId !== null) {
		return (
			<Modal
				title={`Анкета (Просмотр)`}
				cancelText="Закрыть"
				className="modal-window"
				align={null}
				visible={isShow}
				onCancel={onCloseModal}
				footer={
					<Popconfirm
						title={'Убрать в архив?'}
						okText={'Да'}
						cancelText={'Нет'}
						onConfirm={() => {
							archiveObject('driver', driverId)
						}}
					>
						<Button
							type="danger"
							htmlType="button"
							size="small"
							onClick={() => {}}
						>
							Архивировать
						</Button>
					</Popconfirm>
				}
			>
				<Spin spinning={loadingContentModal} tip="Загрузка...">
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							ФИО:
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							{data.fio}
						</Col>
					</Row>
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							Паспортные данные:
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							Серия: {data.passportSerial},  №: {data.passportNumber},  Выдан: {moment(data.passportDate).format("D.M.YYYY")}
						</Col>
					</Row>
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							Страна регистрации:
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							{data.regCountry}
						</Col>
					</Row>
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							Телефон:
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							+7{data.phone}
						</Col>
					</Row>
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							Данные ВУ:
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							Серия: {data.driverLicenseSerial},  №: {data.driverLicenseNumber}, Выдан: { moment(data.driverLicenseDate).format("D.M.YYYY")}
						</Col>
					</Row>
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							Прописки:
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							{data.registration}
						</Col>
					</Row>
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							Текущий автомобиль и прицеп с возможностью выбрать:
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							{info.truckAndTrail}
						</Col>
					</Row>
					<Row gutter={8} style={{ paddingBottom: "40px" }} type="flex" justify="center" align="middle">
						{images.map((img) => {
							return (<Col lg={3} md={3} sm={3} xs={12} className="label dispatch" style={{ marginBottom: '10px' }}>
								<a download href={img.link} target='_blank' >
									<img src={img.link} alt="img" style={imgStyle}></img>
								</a>
								<a href={img.link} download>
									<Button
										type="primary"
										htmlType="button"
										size="small"
										style={bigButtonStyle}
										onClick={() => {

										}}
									>
										Скачать
									</Button>
								</a>
							</Col>);
						})}
					</Row>
				</Spin>
			</Modal>
		);
	} else if (truckId !== null) {
		return (
			<Modal
				title={`Анкета (Просмотр)`}
				cancelText="Закрыть"
				className="modal-window"
				align={null}
				visible={isShow}
				onCancel={onCloseModal}
				footer={
					<Popconfirm
						title={'Убрать в архив?'}
						okText={'Да'}
						cancelText={'Нет'}
						onConfirm={() => {
							archiveObject('truck', truckId)
						}}
					>
						<Button
							type="danger"
							htmlType="button"
							size="small"
							onClick={() => {}}
						>
							Архивировать
						</Button>
					</Popconfirm>
				}
			>
				<Spin spinning={loadingContentModal} tip="Загрузка...">
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							Марка:
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							{info.brand}
						</Col>
					</Row>
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							Грузо-подьемность (т)
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							{info.carrying}
						</Col>
					</Row>
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							Страна:
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							{info.regCountry}
						</Col>
					</Row>
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							Гос.номер:
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							{info.number}
						</Col>
					</Row>
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							Количество паллет:
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							{data.pallets}
						</Col>
					</Row>

					<Row gutter={8} style={{ paddingBottom: "40px" }} type="flex" justify="center" align="middle">
						{images.map((img) => {
							return (<Col lg={3} md={3} sm={3} xs={12} className="label dispatch" style={{ marginBottom: '10px' }}>
								<a download href={img.link} target='_blank' >
									<img src={img.link} alt="img" style={imgStyle}></img>
								</a>
								<a href={img.link} download>
									<Button
										type="primary"
										htmlType="button"
										size="small"
										style={bigButtonStyle}
										onClick={() => {

										}}
									>
										Скачать
									</Button>
								</a>
							</Col>);
						})}
					</Row>
				</Spin>
			</Modal>
		);
	}
	else if (trailId !== null) {
		return (
			<Modal
				title={`Анкета (Просмотр)`}
				cancelText="Закрыть"
				className="modal-window"
				align={null}
				visible={isShow}
				onCancel={onCloseModal}
				footer={
					<Popconfirm
						title={'Убрать в архив?'}
						okText={'Да'}
						cancelText={'Нет'}
						onConfirm={() => {
							archiveObject('trail', trailId)
						}}
					>
						<Button
							type="danger"
							htmlType="button"
							size="small"
							onClick={() => {}}
						>
							Архивировать
						</Button>
					</Popconfirm>
				}
			>
				<Spin spinning={loadingContentModal} tip="Загрузка...">
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							{info.brand}
						</Col>
					</Row>
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							Грузо-подьемность (т)
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							{info.carrying}
						</Col>
					</Row>
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							Страна:
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							{info.regCountry}
						</Col>
					</Row>
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							Гос.номер:
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							{info.number}
						</Col>
					</Row>
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
							Количество паллет:
						</Col>
						<Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							{data.pallets}
						</Col>
					</Row>
					<Row gutter={8} style={{ paddingBottom: "40px" }} type="flex" justify="center" align="middle">
						{images.map((img) => {
							return (<Col lg={3} md={3} sm={3} xs={12} className="label dispatch" style={{ marginBottom: '10px' }}>
								<a download href={img.link} target='_blank' >
									<img src={img.link} alt="img" style={imgStyle}></img>
								</a>
								<a href={img.link} download>
									<Button
										type="primary"
										htmlType="button"
										size="small"
										style={bigButtonStyle}
										onClick={() => {
										}}
									>
										Скачать
									</Button>
								</a>
							</Col>);
						})}
					</Row>
				</Spin>
			</Modal>
		);
	}
}