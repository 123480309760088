import React from "react";
import {FullDate} from "../../tools/delivery-tools";
import Fancybox from "../fancybox";

export default function NewsCard({el}) {
  return (
    <Fancybox
      options={{
        Carousel: {
          infinite: false,
        },
        Toolbar: {
          display: {
            left: [],
            middle: [],
            right: ["close"],
          },
        },
      }}
    >
      <a data-fancybox="" data-src={`#${el.category}-${el.id}`} className="news-card">
        <div className="news-card__img">
          <img src={el.media} alt=""/>
        </div>
        <div className="news-card__content">
          <div className="news-card__date text14">
            {<FullDate date={el.dateCreated.date}/>}
          </div>
          <div className="news-card__title display4">
            {el.header}
          </div>
        </div>
      </a>
    </Fancybox>
  )
}
