import React, {useState} from 'react';
import {Col, Row, DatePicker, Checkbox} from 'antd';
import localeDatePicker from 'antd/lib/date-picker/locale/ru_RU';
import InputField from '../../../../input-field';
import NumberInputField from '../../../../number-input-field';
import SelectField from '../../../../select-field';
import FieldBlock from '../../../../field-block';
import * as moment from 'moment';
import DividerBlock from "../../../../divider-block";

export default function AddressesFields({ stepFields, setFields, getFieldErrors, edit = false }) {
	    let dividerStyle = {
        margin: "15px 0px 45px 0px",
        display: "block",
        clear: "both",
        width: "100%",
        minWidth: "100%",
        height: "1px",
      };
    return (
      <>
        <span className="divider-label">Юридический адрес</span>
        <DividerBlock style={dividerStyle} />
        <Row gutter={[12, 24]}>
          <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
            <InputField
              name="regRegion"
              label="Регион"
              value={stepFields.regRegion}
              setValue={(value) =>
                setFields({ ...stepFields, regRegion: value })
              }
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
            <InputField
              name="regLocality"
              label="Населённый пункт"
              value={stepFields.regLocality}
              setValue={(value) =>
                setFields({ ...stepFields, regLocality: value })
              }
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
            <InputField
              name="regStreet"
              label="Улица"
              value={stepFields.regStreet}
              setValue={(value) =>
                setFields({ ...stepFields, regStreet: value })
              }
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
            <InputField
              name="regHouseNumber"
              label="Номер дома"
              value={stepFields.regHouseNumber}
              setValue={(value) =>
                setFields({ ...stepFields, regHouseNumber: value })
              }
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
            <InputField
              name="regPremiseNumber"
              label="Номер помещения"
              value={stepFields.regPremiseNumber}
              setValue={(value) =>
                setFields({ ...stepFields, regPremiseNumber: value })
              }
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
            <InputField
              name="regPostalCode"
              label="Почт. Почтовый индекс"
              value={stepFields.regPostalCode}
              setValue={(value) =>
                setFields({ ...stepFields, regPostalCode: value })
              }
              getFieldErrors={getFieldErrors}
            />
          </Col>
        </Row>

        <span className="divider-label">Почтовый адрес</span>
        <DividerBlock style={dividerStyle} />
        <Row gutter={[12, 24]}>
          <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
            <InputField
              name="mailingRegion"
              label="Регион"
              value={stepFields.mailingRegion}
              setValue={(value) =>
                setFields({ ...stepFields, mailingRegion: value })
              }
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
            <InputField
              name="mailingLocality"
              label="Населённый пункт"
              value={stepFields.mailingLocality}
              setValue={(value) =>
                setFields({ ...stepFields, mailingLocality: value })
              }
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
            <InputField
              name="mailingStreet"
              label="Улица"
              value={stepFields.mailingStreet}
              setValue={(value) =>
                setFields({ ...stepFields, mailingStreet: value })
              }
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
            <InputField
              name="mailingHouseNumber"
              label="Номер дома"
              value={stepFields.mailingHouseNumber}
              setValue={(value) =>
                setFields({ ...stepFields, mailingHouseNumber: value })
              }
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
            <InputField
              name="mailingPremiseNumber"
              label="Номер помещения"
              value={stepFields.mailingPremiseNumber}
              setValue={(value) =>
                setFields({ ...stepFields, mailingPremiseNumber: value })
              }
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
            <InputField
              name="mailingPostalCode"
              label="Почт. Почтовый индекс"
              value={stepFields.mailingPostalCode}
              setValue={(value) =>
                setFields({ ...stepFields, mailingPostalCode: value })
              }
              getFieldErrors={getFieldErrors}
            />
          </Col>
        </Row>
      </>
    );
}
