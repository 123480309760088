import React, {useEffect, useState} from 'react';
import {Button, Col, Modal, notification, Row, Spin} from "antd";
import '../modals.css';
import {companyService, distributionCenterService} from "../../../services";

export default function ModalCreateOrderToConsignor({isShow, setNeedUpdateOrdersList, setIsShow, legalEntityId, consignorId}) {

    const [loading, setLoading] = useState(true);
    const [needUpdateDistList, setNeedUpdateDistList] = useState(true);
    const [distributionCentersList, setDistributionCentersList] = useState([]);

    const buttonStyle = {
        width: '290px'
    };

    useEffect(() => {
        if (isShow && needUpdateDistList && legalEntityId && consignorId) {
            distributionCenterService.getCompaniesOnApproveOrderCreate(legalEntityId, consignorId)
                .then((response) => {
                    setDistributionCentersList(response);

                    setLoading(false);
                    setNeedUpdateDistList(false);
                })
                .catch((err) => {
                    notification.error({
                        message: "Получение списка РЦ",
                        description: err,
                    });

                    setLoading(false);
                    setNeedUpdateDistList(false);
                });
        }
    });

    const handleSendOrderToConsignor = (legalEntityId, consignorId, distCenterId) => {
        setLoading(true);

        companyService
            .createOrderToConsignor(legalEntityId, consignorId, distCenterId)
            .then(resp => {
                if (resp.code !== 400) {
                    notification.success({
                        message: "Отправка заявки",
                        description: resp.message,
                    });

                    window.location.reload();

                    setIsShow(false);
                    setNeedUpdateOrdersList(true);
                    setLoading(false);
                } else {
                    notification.error({
                        message: "Отправка заявки",
                        description: resp.message,
                    });
                }
            })
            .catch(errMsg => {
                notification.error({
                    message: "Отправка заявки",
                    description: errMsg,
                });
            });
    };

    const ordersButtonsRender = () => {
        return distributionCentersList.map((distCenter) => {
            if (distCenter.IsActive) {
                return (
                    <Col lg={8} sm={24}>
                        <Button style={buttonStyle} onClick={() => handleSendOrderToConsignor(legalEntityId, consignorId, distCenter.Id)} className="small-button typical-btn-green">
                            {distCenter.VisibleName}
                        </Button>
                    </Col>
                );
            }
        })
    };


    return (
        <Modal
            title={<span className="ant-modal-title">Выберите регион</span>}
            cancelText="Закрыть"
            className="modal-window"
            style={{maxWidth: '450px'}}
            align={null}
            visible={isShow}
            footer={null}
            onCancel={() => {
                setIsShow(false);
                setLoading(true);
                setNeedUpdateDistList(true);
            }}
        >
            <Spin spinning={loading} tip="Загрузка...">
                <Row className="add-padding-10 -centered">
                    {ordersButtonsRender()}
                </Row>
            </Spin>
        </Modal>
    );
}