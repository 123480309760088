import React, {useEffect, useState} from "react";
import Spinner from "../../../spinner";
import {Button, Col, DatePicker, Table, Row, notification, Icon} from "antd";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import {reportService} from "../../../../services/report.services";
import moment from "moment";

export default function ReportStatsRefusedDeliveries() {
  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  // const [companiesLoaded, setCompaniesLoaded] = useState(false);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(false);
  // параметры для построения отчета
  const [dateStart, setDateStart] = useState(moment().startOf("month"));
  const [dateEnd, setDateEnd] = useState(moment().endOf("month"));
  // полученные данные отчета
  const [reportData, setReportData] = useState([]);
  const [reportDataParsed, setReportDataParsed] = useState([]);

  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateReport(true);
    }

    if (isNeedUpdateReport) {
      // запрос на получение данных отчета
      reportService
        .getStatsRefusedDeliveries(
          dateStart.format("YYYY-MM-D"),
          dateEnd.format("YYYY-MM-D")
        )
        .then((response) => {
          setReportData([]);
          let tableData = [];
          response[0]['data'].map((item) => {
            tableData.push(item);
            return null;
          });

          let reindexedTableData = [];
          for (let key in tableData) {
            reindexedTableData.push(tableData[key]);
          }

          setIsNeedUpdateReport(false);
          setLoading(false);
          setReportData(reindexedTableData);

          setIsNeedUpdateReport(false);
          setLoading(false);
        })
        .catch((errorMsg) => {
          setIsNeedUpdateReport(false);
          setLoading(false);
          notification.error({
            message: "Получение отчета",
            description: errorMsg,
          });
        });
    }
  }, [isNeedUpdateReport]);

  const columns = [
    {
      title: "Откуда",
      dataIndex: "from",
      key: "from",
      width: "30%",
    },
    {
      title: "Куда",
      dataIndex: "to",
      key: "to",
      width: "20%",
    },
    {
      title: "Причина",
      dataIndex: "reason",
      key: "reason",
      width: "15%",
    },
    {
      title: "Дата создания",
      dataIndex: "delivDateCreated",
      key: "delivDateCreated",
      width: "10%",
      sorter: (a, b) => {
        return (
          moment(a.delivDateCreatedObj).unix() -
          moment(b.delivDateCreatedObj).unix()
        );
      },
    },
    {
      title: "Дата отмены",
      dataIndex: "dateRefuse",
      key: "dateRefuse",
      width: "15%",
      sorter: (a, b) => {
        return moment(a.dateRefuseObj).unix() - moment(b.dateRefuseObj).unix();
      },
    },
    {
      title: "Отменивший",
      dataIndex: "whoCancel",
      key: "whoCancel",
      width: "15%",
    },
  ];

  return (
    <React.Fragment>
      <div className="reports__header">
        <h1 className="content-header">
          Отчет «Статистика по отменённым доставкам»
        </h1>
        <Icon type='filter'
              theme={isFilterOpen ? '' : "filled"}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen)
              }}
              className="reports__filter-toggle"/>
        {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
          <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start">
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateStart}
                format="DD.MM.YYYY"
                size="normal"
                name="dateStart"
                placeholder="Выберите дату начала периода"
                locale={localeDatePicker}
                allowClear={false}
                onChange={(date) => {
                  setDateStart(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateEnd}
                format="DD.MM.YYYY"
                size="normal"
                name="dateEnd"
                placeholder="Выберите дату окончания периода"
                locale={localeDatePicker}
                allowClear={false}
                onChange={(date) => {
                  setDateEnd(date);
                }}
              />
            </Col>

            <Col lg={6} md={6} sm={12} xs={24} style={{textAlign: "right", height: "40px"}}>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  setLoading(true);
                  setIsNeedUpdateReport(true);
                }}
                style={{
                  backgroundColor: "#1CAA53",
                  border: "none",
                  height: "100%",
                  width: '100%'
                }}
              >
                Применить
              </Button>
            </Col>
          </Row>
        </div>}
      </div>
      <div className="reports__table">
        <div className="reports__table-wrapper">
          <Spinner loading={loading}>
            <Col span={24}>
              <Table
                style={{minWidth: "700px"}}
                columns={columns}
                dataSource={reportData}
                locale={{emptyText: "Данные отсутствуют"}}
                rowKey="id"
                size="small"
                pagination={{
                  pageSize: 50,
                }}
              />
            </Col>
          </Spinner>
        </div>
      </div>
    </React.Fragment>
  );
}
