import React, { useEffect } from "react";
import { Row, Col } from "antd";

export default function Price({ delivery, setPaymentType, setPaymentPrice, canSetPayment = false }) {
  const paymentTypes = delivery.AllowedPaymentTypes.length ? delivery.AllowedPaymentTypes : null;
  const initialPaymentType = delivery.AllowedPaymentTypes.length ? delivery.AllowedPaymentTypes[0] : null;

  useEffect(() => {
    if (canSetPayment) {
      setPaymentPrice(determinePrice(initialPaymentType));
    }
  }, [setPaymentPrice]);

  const determinePrice = (type) => {
    if (initialPaymentType) {
      switch (type.Name) {
        case "НДС":
          return  delivery.NdsPrice;
        case "Без НДС":
          return delivery.NoNdsPrice;
        case "Наличными":
          return delivery.CashPrice;

        case "Доллары":
          return delivery.DollarPrice;
        case "Евро":
          return delivery.EuroPrice;
        default:
          return "-";
      }
    } else {
      return "-";
    }
  };

  const determineTypeName = (type) => {
    if (initialPaymentType) {
      return type.Name === "НДС" ? "c НДС" : type.Name === "Без НДС" ? "без НДС" : "нал";

      switch (type.Name) {
        case "НДС":
          return  "c НДС";
        case "Без НДС":
          return "без НДС";
        case "Наличными":
          return "нал";

        case "Доллары":
          return "Dollars";
        case "Евро":
          return "Euro";
        default:
          return "-";
      }

    } else {
      return "-";
    }
  };

  const onClickSetPayment = (e, type) => {
    if (canSetPayment) {
      let price = determinePrice(type);
      setPaymentType(type.Id);
      setPaymentPrice(price);
    }

    for (let item of e.currentTarget.parentElement.children) {
      item.classList.remove("menu-item-selected");
    }

    e.currentTarget.classList.add("menu-item-selected");
  };

  if (canSetPayment) {
    return (
      <Row gutter={16} type="flex" style={{ alignItems: "center", justifyContent: "center" }}>
        <div className="payment-types-inline">
          {paymentTypes.map((type) => {
            return (
              <div
                key={type.Id}
                onClick={(e) => onClickSetPayment(e, type)}
                className={`menu-item ${initialPaymentType.Name === type.Name ? "menu-item-selected" : ""}`}
                data-id={type.Id}
              >
                {type.Name}
              </div>
            );
          })}
        </div>
      </Row>
    );
  } else {
    return (
      <Row gutter={16} className="price-container">
        {paymentTypes.map((type) => {
          let price = determinePrice(type).toLocaleString("ru"),
            typeName = determineTypeName(type);

          return (
            <Col span={24} key={type.Id} className="price-item">
              <p className="typical-icon-field typical-icon-without-padding">
                <span className="price-name">{typeName}</span> {price}
                <span className="price-sign"> ₽</span>
              </p>
            </Col>
          );
        })}
      </Row>
    );
  }
}
