import React from "react";
import {Button, Col, Modal, Row} from "antd";

export default function ModalCreateOrder({isShow, setIsShow, delivery, setNeedUpdateDeliveryList, setStopListUpdate}) {

    let phoneNumber;
    let bigButtonStyle = {width: '290px', height: '75px', fontSize:'24px', color: 'white', backgroundColor: '#1CAA53', border:'none'};

    if(delivery.Logist && delivery.Logist.Phone && delivery.Logist.Phone.length){
        phoneNumber = delivery.Logist.Phone;
    }else{
        phoneNumber = '-';
    }

    return (
        <Modal
            title="Подача заявки"
            style={{top: 20, minWidth: "1150px"}}
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
                setNeedUpdateDeliveryList(true);
                setStopListUpdate(false);
            }}
            footer={[
                (<Button htmlType="button" key="submit" type="primary" style={bigButtonStyle} onClick={() => {
                        setIsShow(false);
                        setNeedUpdateDeliveryList(true);
                        setStopListUpdate(false);
                    }}>Ок</Button>),
            ]}
        >
            <Row gutter={16} className="add-padding-10">
                <Col>Для бронирования позвоните по номеру: {phoneNumber}</Col>
            </Row>
        </Modal>
    );
}
