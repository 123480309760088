import React, {useState, useEffect} from 'react';
import {Checkbox, notification, Row, Col, Button} from 'antd';
import {userServices} from "../../../../services";
import {useFetchNotification} from "../../../../hooks/use-fetch-notification";
import NotificationMessage from "../../../modals/notification-message";
import Spinner from "../../../spinner";
import DividerBlock from "../../../divider-block";
import {notificationServices} from "../../../../services/notification.services";
import AttachTelegramModal from "../../../modals/attach-telegram";


export default function UserNotificationSettings() {

  const [needUpdate, setNeedUpdate] = useState(true);
  const [loading, setLoading] = useState(true);
  const [stopListUpdate, setStopListUpdate] = useState(false);

  const [notificationsSettings, setNotificationSettings] = useState(null);

  const [deliveriesDirections, setDeliveriesDirections] = useState([]);

  const [{notifications}] = useFetchNotification(true);

  const [isShowModalAttachTelegram, setIsShowModalAttachTelegram] = useState(false);

  // Плолучить список регионов
  useEffect(() => {
    if (!stopListUpdate) {
      setLoading(true);

      userServices
        .getDeliveriesDirections()
        .then((resp) => {
          setDeliveriesDirections(resp);

          setLoading(false);
          setNeedUpdate(false);
        })
        .catch((err) => {
          notification.error({
            message: "Получение списка регионов",
            description: err,
          });
        })
    }
  }, [needUpdate, stopListUpdate]);

  // Сохранить избранные регионы
  const saveDeliveriesDirections = () => {
    setLoading(true);

    let deliveryDirectionsIDs = [];
    // Отправляем на бэк список из id регионов, которые быле отмечены
    deliveriesDirections.forEach(((value) => {
      if (value.selected) {
        deliveryDirectionsIDs.push(value.id);
      }
    }));

    userServices
      .saveDeliveriesDirections(deliveryDirectionsIDs)
      .then(response => {
        notification.success({
          message: "Сохранение избранных направлений",
          description: response,
        });

        setLoading(false);
      })
      .catch(err => {
        notification.error({
          message: "Получение списка регионов",
          description: err,
        });

        setLoading(false);
      });
  };

  // Получить настройки уведомлений пользователя
  useEffect(() => {
    let user = userServices.getUser();

    userServices
      .getInfo(user.getAuthToken())
      .then((resp) => {
        setNotificationSettings(resp.notificationsSettings);
      })
      .catch((err) => {
        notification.error({
          message: "Получение информации о пользователе",
          description: err,
        });
      });
  }, []);

  const saveNotificationSetting = (status, type, notifyType) => {
    let notifySettings = notificationsSettings;

    notificationServices
      .switchNotification(status, type, notifyType)
      .then((resp) => {
        if (resp.code === 200) {
          notification.success({
            message: "Сохранение",
            description: resp.message,
          });

          if (!notifySettings[type]) {
            notifySettings[type] = [];
          }
          notifySettings[type][notifyType] = status;
          setNotificationSettings({...notifySettings});
        } else {
          notification.error({
            message: "Сохранение",
            description: resp.message,
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: "Сохранение",
          description: err,
        });
      });
  };


  const renderDeliveriesDirections = () => {
    if (deliveriesDirections) {
      return deliveriesDirections.map((value, index) => {
        return (
          <Col lg={8} sm={24} key={index}>
            <Checkbox
              name={value.title}
              checked={(value.selected)}
              onChange={(e) => {
                let arr = [...deliveriesDirections];
                arr[index] = {title: value.title, selected: e.target.checked, id: value.id};

                setDeliveriesDirections(arr);
              }}
              style={{fontSize: "10pt", paddingTop: "15px"}}
            >
              {value.title}
            </Checkbox>
          </Col>
        );
      });
    }
  }


  const isNotificationSettingChecked = (channelType, notifyType) => {
    if (notificationsSettings) {
      if (notificationsSettings[channelType]) {
        return notificationsSettings[channelType][notifyType];
      }
    }

    return false;
  };

  let bigButtonStyle = {
    height: '40px',
    background: '#1CAA53',
    position: 'static',
    borderRadius: '10px',
    borderColor: "#1CAA53",
    marginTop: "20px"
  };
  let telegramButtonStyle = {
    height: '40px',
    background: '#1CAA53',
    position: 'static',
    borderRadius: '10px',
    borderColor: "#1CAA53",
    marginBottom: "20px",
    float: 'right'
  };
  return (
    <>
      <NotificationMessage notifications={notifications} setStopListUpdate={setStopListUpdate} loading={false}/>

      <Spinner loading={loading}>
        <Row style={{marginBottom: "25"}}>
          <Col className={"content-header"} style={{marginBottom: "0px"}} span={12}>Настройка уведомлений</Col>
          <Button
            type="primary"
            htmlType="button"
            style={telegramButtonStyle}
            onClick={() => {
              setIsShowModalAttachTelegram(true);
            }}
          >
            Прикрепить телеграм
          </Button>
        </Row>
        <AttachTelegramModal setIsShow={setIsShowModalAttachTelegram} isShow={isShowModalAttachTelegram}/>
        <DividerBlock label="Получение уведомлений"/>
        <Row>
          <Col lg={8} sm={24}>
            <Checkbox
              name='pushNewDeliveries'
              checked={isNotificationSettingChecked('push', 'new_deliveries')}
              onChange={(e) => {
                let checked = e.target.checked;
                saveNotificationSetting(checked, 'push', 'new_deliveries');
              }}
              style={{fontSize: "10pt", paddingTop: "15px"}}
            >
              Приложение - Новые рейсы
            </Checkbox>
          </Col>
          <Col lg={8} sm={24}>
            <Checkbox
              name='telegramNewDeliveries'
              checked={isNotificationSettingChecked('telegram', 'new_deliveries')}
              onChange={(e) => {
                let checked = e.target.checked;
                saveNotificationSetting(checked, 'telegram', 'new_deliveries');
              }}
              style={{fontSize: "10pt", paddingTop: "15px"}}
            >
              Телеграм - Новые рейсы
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col lg={8} sm={24}>
            <Checkbox
              name='pushNewActions'
              checked={isNotificationSettingChecked('push', 'new_actions')}
              onChange={(e) => {
                let checked = e.target.checked;
                saveNotificationSetting(checked, 'push', 'new_actions');
              }}
              style={{fontSize: "10pt", paddingTop: "15px"}}
            >
              Приложение - Остальные уведомления
            </Checkbox>
          </Col>
          <Col lg={8} sm={24}>
            <Checkbox
              name='telegramNewActions'
              checked={isNotificationSettingChecked('telegram', 'new_actions')}
              onChange={(e) => {
                let checked = e.target.checked;
                saveNotificationSetting(checked, 'telegram', 'new_actions');
              }}
              style={{fontSize: "10pt", paddingTop: "15px"}}
            >
              Телеграм - Остальные уведомления
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col lg={8} sm={24}>
            <Checkbox
              name='pushChangeLoadingDate'
              checked={isNotificationSettingChecked('push', 'Change_approved_delivery_date')}
              onChange={(e) => {
                let checked = e.target.checked;
                saveNotificationSetting(checked, 'push', 'Change_approved_delivery_date');
              }}
              style={{fontSize: "10pt", paddingTop: "15px"}}
            >
              Приложение - Изменение даты утверждённой заявки
            </Checkbox>
          </Col>
          <Col lg={8} sm={24}>
            <Checkbox
              name='telegramChangeLoadingDate'
              checked={isNotificationSettingChecked('telegram', 'Change_approved_delivery_date')}
              onChange={(e) => {
                let checked = e.target.checked;
                saveNotificationSetting(checked, 'telegram', 'Change_approved_delivery_date');
              }}
              style={{fontSize: "10pt", paddingTop: "15px"}}
            >
              Телеграм - Изменение даты утверждённой заявки
            </Checkbox>
          </Col>
        </Row>

        <DividerBlock label="Избранные регионы для новых рейсов"/>
        <Row>
          {renderDeliveriesDirections()}
          <div className="center">
            <button
              htmlType="button"
              className="ant-btn ant-btn-primary"
              style={bigButtonStyle}
              onClick={saveDeliveriesDirections}>Сохранить избранные регионы
            </button>
          </div>
        </Row>
      </Spinner>
    </>
  );
};


