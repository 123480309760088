import {
  SERVICE_INFORMATION_MANAGEMENT,
  VIEW_FREE_DELIVERIES_LOGIST,
  VIEW_RESERVED_DELIVERIES_LOGIST,
  VIEW_BOOKED_DELIVERIES_LOGIST,
  VIEW_AGREED_DELIVERIES_LOGIST,
  VIEW_REPORT_STATS_ORDER_DELIVERIES,
  VIEW_FREE_DELIVERIES_CARRIER,
  VIEW_BOOKED_DELIVERIES_CARRIER,
  VIEW_AGREED_DELIVERIES_CARRIER,
  VIEW_NEW_DELIVERIES_DRIVER,
 //COMPANY_DIRECTOR_PERMISSION,
  LOGIST_LEGAL_ENTITY_APPROVE,
  LAWYER_LEGAL_ENTITY_APPROVE,
  ACCOUNTANT_LEGAL_ENTITY_APPROVE,
  VIEW_COMPANY_PAGE,
  UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY,
} from "../helpers/user_permission.helper";
import {WAREHOUSE_WORKER} from "../helpers/user_role.helper";

export const configMenuLinks = {
  guest: [
    {
      links: ["/"],
      label: "Главная",
      homePage: true,
    },
    {
      links: ["/deliveries/dashboard-deliveries"],
      label: "Доступные рейсы",
      homePage: false,
    },
  ],
  auth: [
    {
      links: ["/settings"],
      label: "Настройки",
      homePage: true,
      accessTo: SERVICE_INFORMATION_MANAGEMENT,
    },
    {
      links: ["/consignor"],
      label: "Кабинет",
      homePage: true,
      accessTo: ACCOUNTANT_LEGAL_ENTITY_APPROVE
    },
    {
      links: ["/consignor"],
      label: "Кабинет",
      homePage: true,
      accessTo: LAWYER_LEGAL_ENTITY_APPROVE
    },
    {
      links: ["/start"],
      label: "Главная",
      homePage: true,
      accessTo: VIEW_FREE_DELIVERIES_CARRIER,
    },
    {
      links: ["/deliveries/list-new"],
      label: "В поиске",
      homePage: false,
      accessTo: VIEW_FREE_DELIVERIES_CARRIER,
    },
    {
      links: ["/deliveries/list-my"],
      label: "Мои рейсы",
      homePage: false,
      accessTo: VIEW_BOOKED_DELIVERIES_CARRIER,
    },
    {
      links: ["/deliveries/list-onload-carrier"],
      label: "На погрузке",
      homePage: false,
      accessTo: VIEW_BOOKED_DELIVERIES_CARRIER,
    },
    {
      links: ["/deliveries/list-enroute-carrier"],
      label: "В пути",
      homePage: false,
      accessTo: VIEW_BOOKED_DELIVERIES_CARRIER,
    },
    {
      links: ["/deliveries/list-archived-carrier"],
      label: "Архив",
      homePage: false,
      accessTo: VIEW_AGREED_DELIVERIES_CARRIER,
    },
    {
      links: ["/start"],
      label: "Главная",
      homePage: true,
      accessTo: VIEW_FREE_DELIVERIES_LOGIST,
    },
    {
      links: ["/deliveries/list-free"],
      label: "В поиске",
      homePage: false,
      accessTo: VIEW_FREE_DELIVERIES_LOGIST,
    },
    {
      links: ["/deliveries/list-reserve"],
      label: "В резерве",
      homePage: false,
      accessTo: VIEW_RESERVED_DELIVERIES_LOGIST,
    },
    {
      links: ["/deliveries/list-booked"],
      label: "На оформлении",
      homePage: false,
      accessTo: VIEW_BOOKED_DELIVERIES_LOGIST,
    },
    {
      links: ["/deliveries/list-onload-logist"],
      label: "На погрузке",
      homePage: true,
      accessTo: [VIEW_AGREED_DELIVERIES_LOGIST, UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY],
    },
    {
      links: ["/deliveries/list-enroute-logist"],
      label: "В пути",
      homePage: false,
      accessTo: VIEW_BOOKED_DELIVERIES_LOGIST,
    },
    {
      links: ["/deliveries/list-archived-logist"],
      label: "Архив",
      homePage: false,
      accessTo: VIEW_AGREED_DELIVERIES_LOGIST,
    },
    {
      links: ["/reports/stats-orders-delivery", "/reports/stats-cargo", "/reports/stats-refused-deliveries"],
      label: "Отчеты",
      homePage: false,
      accessTo: VIEW_REPORT_STATS_ORDER_DELIVERIES,
    },
    {
      links: ["/company/legal-entities", "/company/employee", "/company/orders-to-consignor", "/company/orders-to-shipper-company"],
      label: "Моя компания",
      homePage: false,
      accessTo: VIEW_COMPANY_PAGE,
    },
    {
      links: ["/deliveries/list-new-driver"],
      label: "Новые рейсы",
      homePage: false,
      accessTo: VIEW_NEW_DELIVERIES_DRIVER,
    },
    {
      links: [
          "/consignor",
          "/consignor/staff-users",
          "/consignor/shippers-companies",
          "/consignor/distribution-centres",
          "/consignor/create-delivery",
          "/consignor/orders-to-consignor",
          "/consignor/orders"
      ],
      label: "Кабинет",
      homePage: false,
      accessTo: [VIEW_BOOKED_DELIVERIES_LOGIST, LOGIST_LEGAL_ENTITY_APPROVE, LAWYER_LEGAL_ENTITY_APPROVE, ACCOUNTANT_LEGAL_ENTITY_APPROVE]
    },
  ],
  [WAREHOUSE_WORKER]: [
    {
      links: ["/deliveries/list-onload-logist"],
      label: "На погрузке",
      homePage: true,
      accessTo: UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY,
    },
    {
      links: ["/deliveries/list-free"],
      label: "В поиске",
      homePage: false,
      accessTo: VIEW_FREE_DELIVERIES_LOGIST,
    },
  ]
};
