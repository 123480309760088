import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Modal, notification, Row, Spin} from "antd";
import {companyService} from "../../../services";
import InputField from "../../input-field";

export default function ModalCompanySettingsByConsignor({ isShow, setIsShow, companyID, companyType = '' }) {
    const [loading, setLoading] = useState(true);
    const [fieldsErrors, setFieldsErrors] = useState([]);
    const [settingsData, setSettingsData] = useState({});

    useEffect(() => {
        if (companyID && isShow) {
            setLoading(true);
            companyService.getCompanySettingsByConsignor(companyID)
                .then(resp => {
                    setSettingsData(resp);
                    setLoading(false);
                })
                .catch((err) => {
                    notification.error({
                        message: "Получение настроек компании",
                        description: err,
                    });
                });
        }
    }, [companyID, isShow]);

    const getFieldErrors = (nameField) => {
        return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
            ? fieldsErrors[nameField][0]
            : null;
    };

    const handleSave = () => {
        if (settingsData && companyID) {
            setLoading(true);
            companyService.saveCompanySettingsByConsignor(settingsData)
                .then(resp => {
                    notification.success({
                        message: "Обновление настроек компании",
                        description: resp.message,
                    });
                    setLoading(false);
                })
                .catch(err => {
                    notification.error({
                        message: "Обновление настроек компании",
                        description: err,
                    });
                    setLoading(false);
                });
        }
    };

    let bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px' };
    return (
        <Modal
            title={`Настройки компании`}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
            }}
            footer={<Button htmlType="submit" key="submit" type="primary" style={bigButtonStyle} onClick={() => handleSave()}>Сохранить</Button>}
        >
            <Spin spinning={loading} tip="Загрузка...">
                <Row gutter={[48, 24]}>
                    <Col span={24}>
                        <p>Тип: {companyType ? (<b>{companyType}</b>) : 'Ошибка типа'}</p>
                    </Col>
                    <Col span={8}>
                        <InputField
                            name="name"
                            label="Задержка показа новых рейсов"
                            required={true}
                            value={settingsData.newDeliveryDelay}
                            setValue={(value) => {
                                setSettingsData({...settingsData, newDeliveryDelay: value});
                            }}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col span={8}>
                        <Checkbox
                            name="accreditedIntDeliveries"
                            checked={settingsData.accreditationInternationalDeliveries}
                            onChange={(e) => {
                                setSettingsData({
                                    ...settingsData,
                                    accreditationInternationalDeliveries: e.target.checked
                                });
                            }}
                            style={{fontSize: "10pt", paddingTop: "15px"}}
                        >
                            Аккредитация на международные рейсы
                        </Checkbox>
                    </Col>
                    {settingsData.accessToSwitchToConsignor &&
                        <Col span={8}>
                            <Checkbox
                                name="isCanSwitchToConsignor"
                                checked={settingsData.isCanSwitchToConsignor}
                                onChange={(e) => {
                                    setSettingsData({
                                        ...settingsData,
                                        isCanSwitchToConsignor: e.target.checked
                                    });
                                }}
                                style={{fontSize: "10pt", paddingTop: "15px"}}
                            >
                                Может стать грузоотправителем
                            </Checkbox>
                        </Col>
                    }
                </Row>
            </Spin>
        </Modal>
    );
}