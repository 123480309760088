import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Icon, Modal, notification, Row, Upload} from "antd";
import Spinner from "../../spinner";
import FieldBlock from "../../field-block";
import {deliveriesService, trailService, truckService} from "../../../services";
import {formHelper} from "../../../helpers";
import ModalSetNoNeedAddParams from "../set-no-need-add-params";

export default function ModalAddDriversPhotosOnLoadingDelivery({isShow, setIsShow, deliveryID, setNeedUpdate, setStopListUpdate}) {
    const [loading, setLoading] = useState(true);
    const [addDocs, setAddDocs] = useState([]);
    const [fieldsErrors, setFieldsErrors] = useState([]);
    const [isTruckHaveVIN, setIsTruckHaveVIN] = useState(null);
    const [isTrailHaveVIN, setIsTrailHaveVIN] = useState(null);
    const [deliveryInfo, setDeliveryInfo] = useState({});
    const [needUpdateData, setNeedUpdateData] = useState(false);
    const [isModalSetNoNeedAddParamsShow, setIsModalSetNoNeedAddParamsShow] = useState(null);

    const getFieldErrors = (fieldName) => {
        return typeof fieldsErrors[fieldName] !== "undefined"
            ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
                return fieldErrors + "; " + error;
            })
            : null;
    };

    useEffect(() => {
        if (isShow && deliveryID) {
            setLoading(true);
            deliveriesService
                .getAddParamOnLoadingDelivery(deliveryID)
                .then((resp) => {
                    let docs = [];
                    for (const value of resp) {
                        if (value.type === "doc") {
                            if (value.value) {
                                value.value = formHelper.createFile(value.value, 'image', value.name, value.alias);
                            }
                            docs.push(value);
                        }
                    }
                    setAddDocs(docs);
                })
                .catch(err => {
                    notification.error({
                        message: 'Загрузка данных',
                        description: err,
                    });
                })
                .finally(() => {
                    setLoading(false);
                });

            deliveriesService
                .getInfoDelivery(deliveryID)
                .then(resp => {
                    setDeliveryInfo(resp);

                    setIsTruckHaveVIN(!!resp.Order.Company.Driver.Truck.Vin);

                    if (resp.Order.Company.Driver.Trail) {
                        setIsTrailHaveVIN(!!resp.Order.Company.Driver.Trail.Vin);
                    } else {
                        setIsTrailHaveVIN(null);
                    }
                })
                .catch(err => {
                    notification.error({
                        message: 'Получение информации по рейсу',
                        description: err,
                    });
                });
        }
    }, [isShow, deliveryID, needUpdateData]);

    const submitBtnStyle = {
        width: "200px",
        height: "43px",
        background: "#1CAA53",
        position: "static",
        borderRadius: "10px",
        color: "white",
        borderColor: "transparent",
    };

    const setNoNeedAddParamsBtnStyle = {
        height: "43px",
        background: "#ac1919",
        position: "static",
        borderRadius: "10px",
        color: "white",
        borderColor: "transparent",
    }

    const btnSetNoNeedAddParams = (
        <React.Fragment key={`set-no-need-params-button-${deliveryID}`}>
            <button class="ant-btn" style={setNoNeedAddParamsBtnStyle}
                    onClick={() => {
                        setIsModalSetNoNeedAddParamsShow(deliveryID);
                    }}
            >Убрать требование загрузки фото
            </button>
            <ModalSetNoNeedAddParams
                onCloseModal={() => {
                    setIsModalSetNoNeedAddParamsShow(null);
                }}
                isShow={isModalSetNoNeedAddParamsShow === deliveryID}
                id={deliveryID}
                setNeedUpdate={setNeedUpdate}/>
        </React.Fragment>
    );

    const getUploaderClass = (index) => {
        return {
            multiple: false,
            showUploadList: true,
            onRemove: () => {
                let docs = addDocs;
                docs[index].value = null;

                setAddDocs([...docs]);
            },
            beforeUpload: (file) => {
                let docs = addDocs;

                file.url = URL.createObjectURL(
                    file instanceof File
                        ? file
                        : formHelper.b64toBlob(file, "image/png")
                );

                docs[index].value = file;
                setAddDocs([...docs]);
                return false;
            },
            fileList: addDocs[index].value ? [addDocs[index].value] : null,
            listType: 'picture',
        };
    };

    const renderAddParamsForm = () => {
        if (!addDocs || addDocs.length === 0) {
            return null;
        }

        let docsFields = addDocs.map((file, index) => {
            return <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name={file.name} getErrors={getFieldErrors}>
                    <Upload
                        className="single-btn-upload"
                        name={file.name}
                        {...getUploaderClass(index)}
                    >
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> {file.alias}
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
        });

        return (
            <Row gutter={[16, 24]}>
                {docsFields}
            </Row>
        );
    };

    const handleChangeTruckVINStatus = (vinStatus) => {
        setLoading(true);
        truckService
            .changeVINStatus(deliveryInfo.Order.Company.Driver.Truck.Id, vinStatus)
            .then(resp => {
                setNeedUpdateData(!needUpdateData);
            })
            .catch(err => {
                notification.error({
                    message: 'Изменение статуса VIN у авто',
                    description: err,
                });
            });
    };

    const handleChangeTrailVINStatus = (vinStatus) => {
        setLoading(true);
        trailService
            .changeVINStatus(deliveryInfo.Order.Company.Driver.Trail.Id, vinStatus)
            .then(resp => {
                setNeedUpdateData(!needUpdateData);
            })
            .catch(err => {
                notification.error({
                    message: 'Изменение статуса VIN у авто',
                    description: err,
                });
            });
    };

    const handleUpload = () => {
        setLoading(true);
        deliveriesService
            .uploadParamsOnLoadingDelivery(deliveryID, addDocs)
            .then(resp => {
                if (resp.code !== 200) {
                    setFieldsErrors(resp.errors);
                    notification.error({
                        message: 'Загрузка данных',
                        description: resp.message,
                    });
                    return;
                }

                notification.success({
                    message: 'Загрузка данных',
                    description: resp.message,
                });
                setIsShow(false);
                setNeedUpdate(true);
                setStopListUpdate(false);
            })
            .catch(err => {
                notification.error({
                    message: 'Загрузка данных',
                    description: err,
                });
            })
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            title={'Загрузка фото'}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
            }}
            footer={[
                <Button
                    htmlType="submit"
                    key="submit"
                    style={submitBtnStyle}
                    onClick={handleUpload}
                    type="primary"
                    className="button-footer-create"
                >
                    Прикрепить
                </Button>,
                btnSetNoNeedAddParams
                ]
            }
        >
            <Spinner loading={loading} tip="Загрузка...">
                {renderAddParamsForm()}
                <Row>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8} style={{margin: "24px 0 auto"}}>
                        <Checkbox
                            name="isTruckHaveNoVIN"
                            checked={!isTruckHaveVIN}
                            onChange={(e) => {
                                handleChangeTruckVINStatus(!e.target.checked)
                            }}
                            style={{display: "flex"}}
                        >
                            У автомобиля нет VIN номера
                        </Checkbox>
                    </Col>
                    {isTrailHaveVIN !== null &&
                        <Col xs={24} sm={12} md={8} lg={8} xl={8} style={{margin: "24px 0 auto"}}>
                            <Checkbox
                                name="isTrailHaveNoVIN"
                                checked={!isTrailHaveVIN}
                                onChange={(e) => {
                                    handleChangeTrailVINStatus(!e.target.checked)
                                }}
                                style={{display: "flex"}}
                            >
                                У прицепа нет VIN номера
                            </Checkbox>
                        </Col>
                    }
                </Row>
            </Spinner>
        </Modal>
    );
}
