import {deliveriesService} from "../../../services";
import InputField from "../../input-field";
import React, {useState} from 'react';
import {Button, Col, Modal, notification, Row, Spin} from "antd";
import SelectField from "../../select-field";
import '../modals.css';

export default function ModalCreateForeignPartner({isShow, setIsShow, setShowAttachDriverOrderDelivery, setUpdateForeignPartnerList}) {

    const [loading, setLoading] = useState(true);
    const [fieldsErrors, setFieldsErrors] = useState([]);

    const [countryList, setCountryList] = useState([]);

    const [country, setCountry] = useState('');
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [street, setStreet] = useState('');
    const [apartment, setApartment] = useState('');
    const [identityNumber, setIdentityNumber] = useState('');
    const [house, setHouse] = useState('');

    let bigButtonStyle = {width: '290px', height: '75px', fontSize:'24px', color: 'white', backgroundColor: '#1CAA53', border:'none'};

    if (loading) {
        deliveriesService
            .getCountryList()
            .then((response) => {
                setCountryList(response);
                setLoading(false);
            })
            .catch((error) => {
                setCountryList([]);
                notification.error({
                    message: "Получение списка стран",
                    description: error,
                });
                setLoading(false);
            });
    }

    const onClickCreateForeignPartner = () => {
        setLoading(true);

        const foreignPartnerData = {
            name: name,
            country: country,
            identityNumber: identityNumber,
            apartment: apartment,
            street: street,
            house: house,
            city: city,
        };

        deliveriesService.createForeignPartner(foreignPartnerData)
            .then((response) => {
                setLoading(false);

                if (response.status === 'success') {
                    notification.success({
                        message: "Создание второго перевозчика",
                        description: response.message,
                    });

                    setIsShow(false);
                    setShowAttachDriverOrderDelivery(true);
                    setUpdateForeignPartnerList(false);

                } else if (response.status === 'error') {
                    setFieldsErrors(response.errors);
                }
            })
            .catch((error) => {
                notification.error({
                    message: "Создание второго перевозчика",
                    description: error,
                });

                setLoading(false);
            });
    };

    const getFieldErrors = (nameField) => {
        return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
            ? fieldsErrors[nameField][0]
            : null;
    };

    return (
        <Modal
            title={<span className="ant-modal-title">Добавление второго перевозчика</span>}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
                setShowAttachDriverOrderDelivery(true);
            }}
            footer={[
                <Button htmlType="submit" key="submit" type="primary" style={bigButtonStyle} onClick={onClickCreateForeignPartner}>Добавить</Button>,
            ]}
        >
            <Spin spinning={loading} tip="Загрузка...">
                <Row gutter={16} className="add-padding-10">
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 20px 0'}}>
                        <InputField
                            name="name" label="Название"
                            value={name} setValue={setName}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 20px 0'}}>
                        <SelectField
                            title="Страна регистрации"
                            name="country"
                            selectedValue={country}
                            values={countryList}
                            onChange={(value) => {
                                setCountry(value);
                            }}
                            getErrors={getFieldErrors}
                            notFoundText="Список стран пуст"
                            style={{width: "100%"}}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <InputField
                            name="identity_number" label="ИНН/..."
                            value={identityNumber} setValue={setIdentityNumber}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                </Row>
                <Row gutter={16} className="add-padding-10">
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 20px 0'}}>
                        <InputField
                            name="city" label="Город"
                            value={city} setValue={setCity}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 20px 0'}}>
                        <InputField
                            name="street" label="Улица"
                            value={street} setValue={setStreet}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 20px 0'}}>
                        <InputField
                            name="house" label="Номер дома"
                            value={house} setValue={setHouse}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 20px 0'}}>
                        <InputField
                            name="apartment" label="Номер квартиры/офиса"
                            value={apartment} setValue={setApartment}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}