import React, {useEffect, useState} from "react";
import {TreeSelect} from 'antd';

export default function RegionSelect({regions, prefix, placeholder, onChange, defaultValue}) {
  const {SHOW_PARENT} = TreeSelect;
  const {TreeNode} = TreeSelect;
  const [value, setValue] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    const newValue = [];
    if(Object.keys(defaultValue).length > 0) {
      for (const [key, value] of Object.entries(defaultValue)) {
        if(value.length > 0) {
          value.map((item) => {
            newValue.push(`region:${key},city:${item}`);
          })
        } else {
          newValue.push(`region:${key}`);
        }
      }
    }
    setValue(newValue);
  }, [defaultValue]);

  const onChangeHandler = (newValue) => {
    const regionsObj = {};

    newValue.map((item) => {
      const el = item.split(',');
      const cities = newValue.filter((item) => item.includes(el[0])).map((item) => item.replace(`${el[0]},city:`, ''));
      if (el.length === 1) {
        Object.assign(regionsObj,{[el[0].replace('region:', '')]: []})
      } else if (el.length === 2) {
        Object.assign(regionsObj,{[el[0].replace('region:', '')]: cities})
      }
    });
    setValue(newValue);
    onChange(regionsObj);
  };

  const renderTreeNodes = (data, regionId = '') =>
    data.map(item => {
      if (item.cities && item.cities.length > 0) {
        return (
          <TreeNode title={item.title} key={`${prefix}-region-${item.id}`} value={`region:${item.id}`}>
            {renderTreeNodes(item.cities , item.id.toString())}
          </TreeNode>
        );
      }
      return <TreeNode title={item.title} key={`${prefix}-city-${regionId}-${item.id}`} value={`region:${regionId},city:${item.id}`} isLeaf={true} />;
    });

  return (
    <TreeSelect
      showSearch
      value={value}
      multiple={true}
      onChange={onChangeHandler}
      allowClear={true}
      autoClearSearchValue={true}
      treeCheckable={true}
      showCheckedStrategy={SHOW_PARENT}
      searchPlaceholder={placeholder}
      style={{width: '100%', height: '100%'}}
      dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
      getPopupContainer={() => document.getElementById(`${prefix}`)}
      treeNodeFilterProp={"title"}
    >
      {renderTreeNodes(regions)}
    </TreeSelect>
  );
}
