import React from 'react'

export default function DeterminedPrice({delivery}) {
    let deliveryOrderPrice = (delivery.Order.Price).toLocaleString('ru');
    let deliveryOrderPaymentType = delivery.Order.PaymentType;

    return (
        <div className="application-topline-logist-item-col determined-price" title="Стоимость утвержденной заявки" data-title="Стоимость утвержденной заявки">
            <span className="determined-price-type">{deliveryOrderPaymentType}</span>
            <p className="typical-icon-field icon-field-cash icon-field-approved">
                {deliveryOrderPrice} <span>₽</span>
            </p>
        </div>
    )
}
