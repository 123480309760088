import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { notification, Pagination } from "antd";
import { userServices, deliveriesService } from "../../../../../services";
import { useFetchDeliveriesList } from "../../../../../hooks/use-fetch-deliveries-list";
import Error from "../../../../error";
import Spinner from "../../../../spinner";
import DeliveryItem from "../../../../delivery-item-block";
import LogistFreeContentItem from "../../../../delivery-item-content/logist/free-content-item";
import DeliveryEmptyContent from "../../../../delivery-empty-content";
import { AppContext } from "../../../../../contexts/app-context";
import {
	VIEW_BOOKED_DELIVERIES_LOGIST,
	VIEW_AGREED_DELIVERIES_LOGIST,
	UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY
} from "../../../../../helpers/user_permission.helper";
import UserNotificationMessage from "../../../../user-message";
import NotificationMessage from "../../../../modals/notification-message";
import { DELIVERY_AWAITING_LOADING } from "../../../../../helpers/delivery_status.helper";
import DeliveryGroupList from "../../../../delivery-group-list";
import DeterminedPrice from "../../../../determined-price";
import { deliveriesConfig } from "../../../../../config";

function HeaderItemLoading({ delivery, dateShipmentRus, regionLoad, nameRegionLastPointUnload }) {
	return (
		<div className="application-topline">
			<div className="application-topline-logist">
				<div className="application-topline-logist-item">
					{/* <div className="application-topline-logist-item-col" style={{ width: "100%" }}>
            <p className="typical-icon-field icon-field-waiting blue-txt">На погрузке</p>
          </div> */}
					<div className="application-topline-carrier-item-col application-topline-carrier-item-col-city">
						<p className="typical-icon-field icon-field-patch" title={regionLoad}>
							{regionLoad}
						</p>
						<p className="typical-icon-field icon-field-dispatch" title={nameRegionLastPointUnload}>
							{nameRegionLastPointUnload}
						</p>
					</div>
				</div>
				<div className="application-topline-logist-item">
					<div className="application-topline-logist-item application-topline-logist-item-determined">
						<DeterminedPrice delivery={delivery} />
						<div className="application-topline-logist-item-col application-topline-logist-item-col-date">
							<div className="typical-icon-field icon-field-calendar">{dateShipmentRus}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function HeaderItemAwaitingLoading({ delivery, dateShipmentRus, setLoadingDelivItem, setNeedUpdate, regionLoad, nameRegionLastPointUnload }) {
	const accessToSetOnLoading = userServices.getUser().isAccessTo(VIEW_AGREED_DELIVERIES_LOGIST);

	const onClickChangeToLoading = (e) => {
		e.preventDefault();
		setLoadingDelivItem(true);

		deliveriesService
			.changeToLoading(delivery.Id)
			.then((response) => {
				notification.success({
					message: 'Изменение статуса на "На погрузке"',
					description: response.message,
				});

				setLoadingDelivItem(false);
				setNeedUpdate(true);
			})
			.catch((error) => {
				notification.error({
					message: 'Изменение статуса на "На погрузке"',
					description: error,
				});

				setLoadingDelivItem(false);
				setNeedUpdate(true);
			});
	};

	return (
		<div className="application-topline">
			<div className="application-topline-logist">
				<div className="application-topline-logist-item">
					<div className="application-topline-carrier-item-col application-topline-carrier-item-col-city">
						<p className="typical-icon-field icon-field-patch" title={regionLoad}>
							{regionLoad}
						</p>
						<p className="typical-icon-field icon-field-dispatch" title={nameRegionLastPointUnload}>
							{nameRegionLastPointUnload}
						</p>
					</div>
				</div>
				<div className="application-topline-logist-item">
					<div className="application-topline-logist-item application-topline-logist-item-determined">
						<DeterminedPrice delivery={delivery} />
						<div className="application-topline-logist-item-col application-topline-logist-item-col-date">
							<div className="typical-icon-field icon-field-calendar">{dateShipmentRus}</div>
						</div>
					</div>
					{accessToSetOnLoading ? (
						<div className="application-topline-logist-item-col application-topline-logist-item-col-button">
							<button className="btn typical-btn typical-btn-blue typical-btn-l" onClick={onClickChangeToLoading}>
								Погрузка <div className="typical-btn-icon typical-btn-green-icon" />
							</button>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}

export default function LogistOnLoadingDeliveries() {
	const user = userServices.getUser();
	const [step, setStep] = useState(deliveriesConfig.pageCount);
	const [offset, setOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);

	let onChange = (page, pageSize) => {
		if (page === currentPage) {
			return false;
		} else {
			setCurrentPage(page);
		}

		let val = 0;
		if (parseInt(page) === 1) {
			setOffset(0);
		} else {
			val = page * pageSize - pageSize;
			setOffset(val);
		}
		appContext.filter.offset = val;
		setNeedUpdate(true);
	};

	const appContext = useContext(AppContext);

	useEffect(() => {
		appContext.initFilter();
	}, []);

	const [{ error, loading, data: deliveries, setStopListUpdate, setNeedUpdate, notifications, permissions, totalCount }] = useFetchDeliveriesList(
		"delivery/get-loading",
		true,
		false,
		true,
		true,
		deliveriesConfig.secondForUpdate,
		offset,
		step
	);

  if (!user.isAccessTo(VIEW_AGREED_DELIVERIES_LOGIST) && !user.isAccessTo(UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY)) {
      return <Error status={403} desc="Страница не доступна" />;
  }

	if (error) {
		return <Error type={500} desc={error.message} />;
	}

	let listItems = [];

	if (!loading) {
		listItems = deliveries.map((delivery) => {
			const dateShipmentRus = moment(delivery.DateShipment).format("D MMMM YYYY");

			const header = delivery.Status === DELIVERY_AWAITING_LOADING ? HeaderItemAwaitingLoading : HeaderItemLoading;
			const color = delivery.Status === "loading" ? "" : "red";

			return (
				<DeliveryItem
					key={`${delivery.Id}_${delivery.Order.Status}`}
					header={header}
					content={LogistFreeContentItem}
					delivery={delivery}
					dateShipmentRus={dateShipmentRus}
					setNeedUpdate={setNeedUpdate}
					setStopListUpdate={setStopListUpdate}
					color={color}
					permissions={permissions}
				/>
			);
		});
	}

	return (
		<Spinner loading={loading}>
			<UserNotificationMessage />
			<NotificationMessage notifications={notifications} setNeedUpdate={false} setStopListUpdate={setStopListUpdate} loading={loading} />
			{totalCount != 0 ?
				<div className="application-block pagination pagination__up">
					<Pagination
						style={{ textAlign: "center" }}
						current={currentPage}
						pageSize={step}
						total={totalCount}
						showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
						onChange={onChange}
					/>
				</div>
				: null}
			{!loading ? deliveries.length ? <DeliveryGroupList deliveriesItems={listItems} /> : <DeliveryEmptyContent /> : null}
			{totalCount != 0 ?
				<div className="application-block pagination pagination__down">
					<Pagination
						style={{ textAlign: "center" }}
						current={currentPage}
						pageSize={step}
						total={totalCount}
						showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
						onChange={onChange}
					/>
				</div>
				: null}
		</Spinner>
	);
}
