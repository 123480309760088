import React from "react";
import { Select, Form } from "antd";

export default function SelectField({
  title,
  name,
  selectedValue,
  values,
  onChange,
  getErrors,
  notFoundText = "Отсутствуют значения",
  placeholder = null,
  style = { height: "50px" },
  mode = "default",
  showSearch = true,
  getFieldDisabled,
  onSearch,
  showArrow = true,
  isDisabled = false,
  keyNum = null,
  className
}) {
  const fieldErrors = keyNum !== null ? getErrors(name, keyNum) : getErrors(name);

  const fieldTitle = title ? (
    <div className="form-field-label">{title}</div>
  ) : null;
  let disabled = false;

  if (getFieldDisabled) {
    disabled = getFieldDisabled(name);
  }

  if (notFoundText !== null) {
    notFoundText = <p className="b-not-fount-text">{notFoundText}</p>;
  }

  // if (!values) return null;
  if (fieldErrors) {
    return (
      <React.Fragment>
        {fieldTitle}
        <Form.Item validateStatus="error" help={fieldErrors}>
          <Select
            showSearch={showSearch}
            mode={mode}
            notFoundContent={notFoundText}
            style={style}
            placeholder={placeholder}
            value={selectedValue}
            onChange={onChange}
            optionFilterProp="children"
            size={"large"}
            onSearch={onSearch}
            showArrow={showArrow}
            disabled={isDisabled}
            className={className}
          >
            {values && values.map((itemValue, index) => {
              if (typeof itemValue === "number" || typeof itemValue === "string")
                return (
                  <Select.Option value={itemValue} key={index}>
                    {itemValue}
                  </Select.Option>
                );
              return (
                <Select.Option
                  value={itemValue.Id ? itemValue.Id : itemValue.id}
                  key={
                    itemValue.Id
                      ? itemValue.Id.toString()
                      : itemValue.id.toString()
                  }
                >
                  {itemValue.Name || itemValue.title || itemValue.name || itemValue.Fio}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {fieldTitle}
      <Form.Item>
        <Select
          showSearch={showSearch}
          mode={mode}
          notFoundContent={notFoundText}
          style={style}
          placeholder={placeholder}
          value={selectedValue}
          onChange={onChange}
          optionFilterProp="children"
          size={"large"}
          onSearch={onSearch}
          showArrow={showArrow}
          disabled={isDisabled}
          className={className}
        >
          {values && values.map((itemValue, index) => {
            if (typeof itemValue === "number" || typeof itemValue === "string")
              return (
                <Select.Option value={itemValue} key={index}>
                  {itemValue}
                </Select.Option>
              );
            return (
              <Select.Option
                value={itemValue.Id ? itemValue.Id : itemValue.id}
                key={
                  itemValue.Id
                    ? itemValue.Id.toString()
                    : itemValue.id.toString()
                }
              >
                {itemValue.Name || itemValue.title || itemValue.name || itemValue.Fio}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </React.Fragment>
  );
}
