import React, {useEffect, useState} from "react";
import {companyService} from "../../../services";
import {Divider, Modal, notification, Row, Table} from "antd";
import Spinner from "../../spinner";

export default function ModalApproveOrderHistoryList ({id, isShow, setIsShow}) {
  const [listApproveOrderHistory, setListApproveOrderHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id && isShow) {
      setLoading(true);
      companyService.getApproveOrderHistory(id)
          .then(resp => {
            setListApproveOrderHistory(resp);
            setLoading(false);
          })
          .catch((err) => {
            notification.error({
              message: "Получение истории согласования",
              description: err,
            });
          });
    }
  }, [id, isShow])

  const columns = [
    {
      title: "Статус",
      render: (record) => (
        <>
          <b>{record.action}</b>
          {record.user.name}
          {record.date}
        </>
      ),
      className: "desktop-hidden"
    },
    {
      title: "Статус",
      dataIndex: 'action',
      className: "mobile-hidden"
    },
    {
      title: 'ФИО',
      render: (record) => (
        <React.Fragment>
          {record.user.name}
        </React.Fragment>
      ),
      key: 'user.id',
      className: "mobile-hidden"
    },
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      className: "mobile-hidden"
    },
  ];


  return (
    <Modal
      title={'История согласования'}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
      }}
      onOk={() => {
        setIsShow(false);
      }}
    >
      <Spinner loading={loading} tip="Загрузка...">
        <Table
          columns={columns}
          dataSource={listApproveOrderHistory}
          bordered={true}
          locale={{ emptyText: "Данные отсутствуют" }}
          className={"employees-table"}
          rowKey={"id"}
        />
      </Spinner>
    </Modal>
  );
}
