import React, {useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";

import {Row, Col, Layout, Button, notification, Typography, DatePicker} from "antd";
import {AppContext} from "@contexts/app-context";
import {userServices, deliveriesService} from "@services";
import Error from "@components/error";
import Spinner from "@components/spinner";
import UserNotificationMessage from "@components/user-message";
import Event from "./event";
import ItemBlock from "./item-block";
import {notificationServices} from "@services/notification.services";
import SelectField from "../../select-field";
import {distributionCenterService} from "../../../services";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import moment from "moment";

export default function StartPage() {
  const user = userServices.getUser();

  const pageTitle = user.info.isStaff ? "Моя работа" : "Мои рейсы"
  const [dataLoaded, setDataLoaded] = useState(false);
  const [eventDataLoaded, setEventDataLoaded] = useState(false);
  const [overviewData, setOverviewData] = useState({});
  const [eventData, setEventData] = useState([]);
  const [needUpdate, setNeedUpdate] = useState(false);
  const [distCenters, setDistCenters] = useState([]);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);

  const [filters, setFilters] = useState({
    from: [],
    to: [],
    dateLoadingStart: '',
    dateLoadingEnd: '',
    distributionCenters: []
  })

  if (user.isGuest()) {
    return <Error status={403} desc="Страница не доступна"/>;
  }

  const appContext = useContext(AppContext);

  if (appContext.filter.enable) {
    appContext.disableFilter();
  }

  useEffect(() => {
    if (!dataLoaded || needUpdate) {
      user.info.isStaff ?
        deliveriesService
          .getLogistOverviewDeliveries(filters)
          .then((response) => {
            setOverviewData(response);

            setDataLoaded(true);
            setNeedUpdate(false);
          })
          .catch((err) => {
            notification.error({
              message: "Получение информации",
              description: err,
            });
            setNeedUpdate(false);
          }) :
        deliveriesService
          .getCarrierOverviewDeliveries()
          .then((response) => {
            setOverviewData(response);

            setDataLoaded(true);
            setNeedUpdate(false);
          })
          .catch((err) => {
            notification.error({
              message: "Получение информации",
              description: err,
            });
            setNeedUpdate(false);
          });
    }
  }, [dataLoaded, needUpdate]);

  useEffect(() => {
    if (!eventDataLoaded) {
      notificationServices
        .getNotificationList()
        .then((response) => {
          setEventData(response.filter((item) => item.DateRead === "Не прочитано").slice(0, 3));

          setEventDataLoaded(true);
        })
        .catch((err) => {
          notification.error({
            message: "Получение информации о событиях",
            description: err,
          });
        });
    }
  }, [eventDataLoaded]);

  useEffect(() => {
    if (!companiesLoaded && user.info.role != 'company_person') {
      distributionCenterService.getCompanies()
        .then((response) => {
          setDistCenters(response);
          setCompaniesLoaded(true);
        }).catch((err) => {
        notification.error({
          message: "Получение информации о событиях",
          description: err,
        });
        setCompaniesLoaded(true);
      });
    }
  }, [])

  return (
    <Layout style={{marginBottom: "100px"}}>
      <Layout.Content>
        <Spinner loading={!dataLoaded} label="Загрузка...">
          <UserNotificationMessage/>
          <h2 className="title title--light">{pageTitle}</h2>
          <div className="content-wrapper">
            {user.info.isStaff &&
              <div className='content-filter'>
                <div className="content-filter__item">
                  <SelectField
                    name="distributionCenters"
                    selectedValue={filters.distributionCenters}
                    values={distCenters}
                    onChange={(value) => {
                      setFilters({
                        ...filters,
                        distributionCenters: value
                      });
                    }}
                    notFoundText="РЦ отсутствуют"
                    placeholder="Выберите РЦ"
                    getErrors={() => {
                    }}
                    style={{width: "100%"}}
                    mode={'multiple'}
                  />
                </div>
                <div className="content-filter__item content-filter__item--buttons">
                  <Button className="content-filter__button"
                          type="primary"
                          size="default"
                          icon="search"
                          htmlType="button"
                          title="Применить параметры фильтра"
                          onClick={() => setNeedUpdate(true)}
                  />
                  <Button className="content-filter__button"
                          type="secondary"
                          size="default"
                          icon="close"
                          htmlType="button"
                          title="Очистить параметры фильтра"
                          onClick={() => {
                            setFilters({
                              from: [],
                              to: [],
                              dateLoadingStart: '',
                              dateLoadingEnd: '',
                              distributionCenters: []
                            });
                            setNeedUpdate(true);
                          }}
                  />
                </div>
              </div>
            }
            <div className="content-item">
              <h3 className="content-item__title">{user.info.isStaff ? "Утверждение ставок" : "Заявки на рейсы"}</h3>
              {overviewData && !!overviewData.free &&
                overviewData.free.map((item, index) => <ItemBlock data={item} key={item.id} type={"free"}
                                                                  user={user}
                                                                  setNeedUpdateDeliveryList={setNeedUpdate}/>)}
            </div>
            <div className="content-item">
              <h3 className="content-item__title">{user.info.isStaff ? "Заполнение заявок" : "На заполнении"}</h3>
              {overviewData && !!overviewData.onFilling &&
                overviewData.onFilling.map((item, index) => <ItemBlock data={item} key={item.id} type={"onFilling"}
                                                                       user={user}
                                                                       setNeedUpdateDeliveryList={setNeedUpdate}/>)}
            </div>
            <div className="content-item">
              <h3 className="content-item__title">{user.info.isStaff ? "Ожидают документы" : "Ожидают на погрузку"}</h3>
              {user.info.isStaff ?
                overviewData && !!overviewData.awaitingDocs &&
                overviewData.awaitingDocs.map((item, index) => <ItemBlock data={item} key={item.id}
                                                                          type={"awaitingDocs"}
                                                                          user={user}
                                                                          setNeedUpdateDeliveryList={setNeedUpdate}/>) :
                overviewData && !!overviewData.onLoading &&
                overviewData.onLoading.map((item, index) => <ItemBlock data={item} key={item.id} type={"onLoading"}
                                                                       user={user}
                                                                       setNeedUpdateDeliveryList={setNeedUpdate}/>)
              }
            </div>
            <div className="content-item">
              <h3 className="content-item__title">{user.info.isStaff ? "Ожидаются на погрузку" : "В пути"}</h3>
              {user.info.isStaff &&
                <div className="content-filter-item">
                  <DatePicker
                    style={{width: "100%"}}
                    defaultValue={filters.dateLoadingStart !== '' ? moment(filters.dateLoadingStart, 'DD.MM.YYYY') : null}
                    format="DD.MM.YYYY"
                    size="normal"
                    name="dateLoadingStart"
                    placeholder="Выберите дату загрузки"
                    locale={localeDatePicker}
                    allowClear={false}
                    onChange={(value) => {
                      setFilters({
                        ...filters,
                        dateLoadingStart: value ? moment(value).format("DD.MM.YYYY") : null,
                        dateLoadingEnd: value ? moment(value).format("DD.MM.YYYY") : null,
                      });
                      setNeedUpdate(true);
                    }}
                  />
                </div>
              }
              {user.info.isStaff ?
                overviewData && !!overviewData.onLoading &&
                overviewData.onLoading.map((item, index) => <ItemBlock data={item} key={item.id} type={"onLoading"}
                                                                       user={user}
                                                                       setNeedUpdateDeliveryList={setNeedUpdate}/>) :
                overviewData && !!overviewData.enRoute &&
                overviewData.enRoute.map((item, index) => <ItemBlock data={item} key={item.id} type={"enRoute"}
                                                                     user={user}
                                                                     setNeedUpdateDeliveryList={setNeedUpdate}/>)}
            </div>
          </div>
        </Spinner>
        <Spinner loading={!eventDataLoaded} label="Загрузка событий...">
          <h2 className="title title--light">События</h2>
          <div className="content-wrapper content-wrapper--mono">
            {!!eventData.length && eventData.map((event) => {
              return <div className="content-item" key={event.Id}>
                <Event date={event.DateCreated} text={event.Message}/>
              </div>
            })}
            {!eventData.length &&
              <div className="content-item">
                <p className="content-item__title">Новых событий нет</p>
              </div>
            }
            <Link className="btn typical-btn typical-btn-green"
                  to={user.info.isStaff ? `/consignor/user-notification/` : `/company/user-notification/`}
                  style={{width: "auto", height: "auto", padding: "0 8px"}}>Посмотреть
              все события</Link>
          </div>
        </Spinner>
      </Layout.Content>
    </Layout>
  )
}
