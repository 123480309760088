import React from "react";
import {Tabs} from 'antd';

export default function PrivacyPage() {

  const {TabPane} = Tabs;

  return (
    <div className="privacy container">
    <Tabs defaultActiveKey="1" type="card">
      <TabPane tab={<p className="privacy__tab-title">Политика в&nbsp;отношении<br />обработки персональных данных</p>} key="1">
        <div>
          <h2><strong>Политика в&nbsp;отношении обработки персональных данных</strong></h2><br/>
          <p><b>1. Общие положения</b></p><br/>
          <p>Настоящая политика обработки персональных данных составлена в&nbsp;соответствии с&nbsp;требованиями
            Федерального закона от&nbsp;
            <nobr>27.07.2006</nobr>
            . &#8470;&nbsp;
            <nobr>152-ФЗ</nobr>
            &laquo;О&nbsp;персональных данных&raquo; (далее&nbsp;&mdash; Закон о&nbsp;персональных данных)
            и&nbsp;определяет порядок обработки персональных данных и&nbsp;меры по&nbsp;обеспечению безопасности
            персональных данных, предпринимаемые <nobr>ООО &laquo;Витал&raquo;</nobr> (далее&nbsp;&mdash; Оператор).
          </p>
          <p>1.1. Оператор ставит своей важнейшей целью и&nbsp;условием осуществления своей деятельности соблюдение прав
            и&nbsp;свобод человека и&nbsp;гражданина при обработке его персональных данных, в&nbsp;том числе защиты прав
            на&nbsp;неприкосновенность частной жизни, личную и&nbsp;семейную тайну.</p>
          <p>1.2. Настоящая политика Оператора в&nbsp;отношении обработки персональных данных
            (далее&nbsp;&mdash; Политика) применяется ко&nbsp;всей информации, которую Оператор может получить
            о&nbsp;посетителях <nobr>веб-сайта</nobr> <a href="https://svetofore.com/.">https://svetofore.com/.</a></p><br/>
          <p><b>2. Основные понятия, используемые в&nbsp;Политике</b></p><br/>
          <p>2.1. Автоматизированная обработка персональных данных&nbsp;&mdash; обработка персональных данных
            с&nbsp;помощью средств вычислительной техники.</p>
          <p>2.2. Блокирование персональных данных&nbsp;&mdash; временное прекращение обработки персональных данных
            (за&nbsp;исключением случаев, если обработка необходима для уточнения персональных данных).</p>
          <p>2.3. Сайт&nbsp;&mdash; совокупность графических и&nbsp;информационных материалов, а&nbsp;также программ для
            ЭВМ и&nbsp;баз данных, обеспечивающих их&nbsp;доступность в&nbsp;сети интернет по&nbsp;сетевому адресу <a
              href="https://svetofore.com/.">https://svetofore.com/.</a></p>
          <p>2.4. Информационная система персональных данных&nbsp;&mdash; совокупность содержащихся в&nbsp;базах данных
            персональных данных, и&nbsp;обеспечивающих их&nbsp;обработку информационных технологий и&nbsp;технических
            средств.</p>
          <p>2.5. Обезличивание персональных данных&nbsp;&mdash; действия, в&nbsp;результате которых невозможно
            определить без использования дополнительной информации принадлежность персональных данных конкретному
            Пользователю или иному субъекту персональных данных.</p>
          <p>2.6. Обработка персональных данных&nbsp;&mdash; любое действие (операция) или совокупность действий
            (операций), совершаемых с&nbsp;использованием средств автоматизации или без использования таких средств
            с&nbsp;персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение
            (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
          <p>2.7. Оператор&nbsp;&mdash; государственный орган, муниципальный орган, юридическое или физическое лицо,
            самостоятельно или совместно с&nbsp;другими лицами организующие и&nbsp;(или) осуществляющие обработку
            персональных данных, а&nbsp;также определяющие цели обработки персональных данных, состав персональных
            данных, подлежащих обработке, действия (операции), совершаемые с&nbsp;персональными данными.</p>
          <p>2.8. Персональные данные&nbsp;&mdash; любая информация, относящаяся прямо или косвенно к&nbsp;определенному
            или определяемому Пользователю сайта <a href="https://svetofore.com/.">https://svetofore.com/.</a></p>
          <p>2.9. Персональные данные, разрешенные субъектом персональных данных для
            распространения,&nbsp;&mdash; персональные данные, доступ неограниченного круга лиц к&nbsp;которым
            предоставлен субъектом персональных данных путем дачи согласия на&nbsp;обработку персональных данных,
            разрешенных субъектом персональных данных для распространения в&nbsp;порядке, предусмотренном Законом
            о&nbsp;персональных данных (далее&nbsp;&mdash; персональные данные, разрешенные для распространения).</p>
          <p>2.10. Пользователь&nbsp;&mdash; любой посетитель сайта <a
            href="https://svetofore.com/.">https://svetofore.com/.</a></p>
          <p>2.11. Предоставление персональных данных&nbsp;&mdash; действия, направленные на&nbsp;раскрытие персональных
            данных определенному лицу или определенному кругу лиц.</p>
          <p>2.12. Распространение персональных данных&nbsp;&mdash; любые действия, направленные на&nbsp;раскрытие
            персональных данных неопределенному кругу лиц (передача персональных данных) или на&nbsp;ознакомление
            с&nbsp;персональными данными неограниченного круга лиц, в&nbsp;том числе обнародование персональных данных
            в&nbsp;средствах массовой информации, размещение в&nbsp;
            <nobr>информационно-телекоммуникационных</nobr>
            сетях или предоставление доступа к&nbsp;персональным данным <nobr>каким-либо</nobr> иным способом.
          </p>
          <p>2.13. Уничтожение персональных данных&nbsp;&mdash; любые действия, в&nbsp;результате которых персональные
            данные уничтожаются безвозвратно с&nbsp;невозможностью дальнейшего восстановления содержания персональных
            данных в&nbsp;информационной системе персональных данных и&nbsp;(или) уничтожаются материальные носители
            персональных данных.</p><br/>
          <p><b>3. Основные права и&nbsp;обязанности Оператора</b></p><br/>
          <p>3.1. Оператор имеет право:</p>
          <p>&mdash;&nbsp;получать от&nbsp;субъекта персональных данных достоверные информацию и/или документы,
            содержащие персональные данные;</p>
          <p>&mdash;&nbsp;в&nbsp;случае отзыва субъектом персональных данных согласия на&nbsp;обработку персональных
            данных Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных
            при наличии оснований, указанных в&nbsp;Законе о&nbsp;персональных данных;</p>
          <p>&mdash;&nbsp;самостоятельно определять состав и&nbsp;перечень мер, необходимых и&nbsp;достаточных для
            обеспечения выполнения обязанностей, предусмотренных Законом о&nbsp;персональных данных и&nbsp;принятыми
            в&nbsp;соответствии с&nbsp;ним нормативными правовыми актами, если иное не&nbsp;предусмотрено Законом
            о&nbsp;персональных данных или&nbsp;другими федеральными законами.</p>
          <p>3.2. Оператор обязан:</p>
          <p>&mdash;&nbsp;предоставлять субъекту персональных данных по&nbsp;его просьбе информацию, касающуюся
            обработки его персональных данных;</p>
          <p>&mdash;&nbsp;организовывать обработку персональных данных в&nbsp;порядке, установленном действующим
            законодательством РФ;</p>
          <p>&mdash;&nbsp;отвечать на&nbsp;обращения и&nbsp;запросы субъектов персональных данных
            и&nbsp;их&nbsp;законных представителей в&nbsp;соответствии с&nbsp;требованиями Закона о&nbsp;персональных
            данных;</p>
          <p>&mdash;&nbsp;сообщать в&nbsp;уполномоченный орган по&nbsp;защите прав субъектов персональных данных
            по&nbsp;запросу этого органа необходимую информацию в&nbsp;течение 30 дней с&nbsp;даты получения такого
            запроса;</p>
          <p>&mdash;&nbsp;публиковать или иным образом обеспечивать неограниченный доступ к&nbsp;настоящей Политике
            в&nbsp;отношении обработки персональных данных;</p>
          <p>&mdash;&nbsp;принимать правовые, организационные и&nbsp;технические меры для защиты персональных данных
            от&nbsp;неправомерного или случайного доступа к&nbsp;ним, уничтожения, изменения, блокирования, копирования,
            предоставления, распространения персональных данных, а&nbsp;также от&nbsp;иных неправомерных действий
            в&nbsp;отношении персональных данных;</p>
          <p>&mdash;&nbsp;прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить
            обработку и&nbsp;уничтожить персональные данные в&nbsp;порядке и&nbsp;случаях, предусмотренных Законом
            о&nbsp;персональных данных;</p>
          <p>&mdash;&nbsp;исполнять иные обязанности, предусмотренные Законом о&nbsp;персональных данных.</p><br/>
          <p><b>4. Основные права и&nbsp;обязанности субъектов персональных данных</b></p><br/>
          <p>4.1. Субъекты персональных данных имеют право:</p>
          <p>&mdash;&nbsp;получать информацию, касающуюся обработки его персональных данных, за&nbsp;исключением
            случаев, предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных
            Оператором в&nbsp;доступной форме, и&nbsp;в&nbsp;них не&nbsp;должны содержаться персональные данные,
            относящиеся к&nbsp;другим субъектам персональных данных, за&nbsp;исключением случаев, когда имеются законные
            основания для раскрытия таких персональных данных. Перечень информации и&nbsp;порядок ее&nbsp;получения
            установлен Законом о&nbsp;персональных данных;</p>
          <p>&mdash;&nbsp;требовать от&nbsp;оператора уточнения его персональных данных, их&nbsp;блокирования или
            уничтожения в&nbsp;случае, если персональные данные являются неполными, устаревшими, неточными, незаконно
            полученными или не&nbsp;являются необходимыми для заявленной цели обработки, а&nbsp;также принимать
            предусмотренные законом меры по&nbsp;защите своих прав;</p>
          <p>&mdash;&nbsp;выдвигать условие предварительного согласия при обработке персональных данных в&nbsp;целях
            продвижения на&nbsp;рынке товаров, работ и&nbsp;услуг;</p>
          <p>&mdash;&nbsp;на&nbsp;отзыв согласия на&nbsp;обработку персональных данных;</p>
          <p>&mdash;&nbsp;обжаловать в&nbsp;уполномоченный орган по&nbsp;защите прав субъектов персональных данных или
            в&nbsp;судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных
            данных;</p>
          <p>&mdash;&nbsp;на&nbsp;осуществление иных прав, предусмотренных законодательством РФ.</p>
          <p>4.2. Субъекты персональных данных обязаны:</p>
          <p>&mdash;&nbsp;предоставлять Оператору достоверные данные о&nbsp;себе;</p>
          <p>&mdash;&nbsp;сообщать Оператору об&nbsp;уточнении (обновлении, изменении) своих персональных данных.</p>
          <p>4.3. Лица, передавшие Оператору недостоверные сведения о&nbsp;себе, либо сведения о&nbsp;другом субъекте
            персональных данных без согласия последнего, несут ответственность в&nbsp;соответствии
            с&nbsp;законодательством РФ.</p><br/>
          <p><b>5. Состав персональных данных</b></p><br/>
          <p>5.1. Оператор может обрабатывать следующие персональные данные Пользователя:</p>
          <p>&mdash;&nbsp;Фамилия, имя, отчество.</p>
          <p>&mdash;&nbsp;Адрес электронной почты.</p>
          <p>&mdash;&nbsp;Номера телефонов.</p>
          <p>&mdash;&nbsp;Год, месяц, дата и&nbsp;место рождения.</p>
          <p>&mdash;&nbsp;Адрес фактического места проживания и&nbsp;регистрации по&nbsp;месту жительства и&nbsp;(или)
            по&nbsp;месту пребывания.</p>
          <p>&mdash;&nbsp;Фотографии.</p>
          <p>&mdash;&nbsp;Реквизиты документов, удостоверяющие личность.</p>
          <p>&mdash;&nbsp;Идентификационный номер налогоплательщика, дата постановки его на&nbsp;учет, реквизиты
            свидетельства постановки на&nbsp;учет в&nbsp;налоговом органе.</p>
          <p>&mdash;&nbsp;Сведения о&nbsp;гражданстве Российской Федерации и&nbsp;гражданстве (подданстве) иностранных
            государств.</p>
          <p>&mdash;&nbsp;Содержание отправленных Оператору сообщений.</p>
          <p>&mdash;&nbsp;Данные о&nbsp;лицах, от&nbsp;имени которых действует Пользователь.</p>
          <p>5.2. Также на&nbsp;сайте происходит сбор и&nbsp;обработка обезличенных данных о&nbsp;посетителях (
            <nobr>в т. ч.</nobr>
            файлов &laquo;cookie&raquo;) с&nbsp;помощью сервисов <nobr>интернет-статистики</nobr> (Яндекс Метрика
            и&nbsp;Гугл Аналитика и&nbsp;других), посредством получения обезличенных персональных данных Пользователя
            программным путём:
          </p>
          <p>&mdash;&nbsp;Сведения о&nbsp;местоположении;</p>
          <p>&mdash;&nbsp;Тип и&nbsp;версия операционной системы устройства;</p>
          <p>&mdash;&nbsp;Тип и&nbsp;версия браузера;</p>
          <p>&mdash;&nbsp;Тип устройства пользователя и&nbsp;разрешение его экрана;</p>
          <p>&mdash;&nbsp;Источник, откуда пришёл на&nbsp;сайт Пользователь (с&nbsp;какого сайта или по&nbsp;какой
            рекламной ссылке);</p>
          <p>&mdash;&nbsp;язык операционной системы устройства и&nbsp;браузера;</p>
          <p>&mdash;&nbsp;<nobr>ip-адрес</nobr> устройства;</p>
          <p>&mdash;&nbsp;информация о&nbsp;том, какие страницы посещает Пользователь, какие кнопки на&nbsp;сайте
            Оператора нажимает.</p>
          <p>5.3. Вышеперечисленные данные далее по&nbsp;тексту Политики объединены общим понятием Персональные
            данные.</p>
          <p>5.4. Обработка специальных категорий персональных данных, касающихся расовой, национальной принадлежности,
            политических взглядов, религиозных или философских убеждений, интимной жизни, Оператором
            не&nbsp;осуществляется.</p>
          <p>5.5. Обработка персональных данных, разрешенных для распространения, из&nbsp;числа специальных категорий
            персональных данных, указанных в&nbsp;ч. 1 ст. 10 Закона о&nbsp;персональных данных, допускается, если
            соблюдаются запреты и&nbsp;условия, предусмотренные ст. 10.1 Закона о&nbsp;персональных данных.</p>
          <p>5.6. Согласие Пользователя на&nbsp;обработку персональных данных, разрешенных для распространения,
            оформляется отдельно от&nbsp;других согласий на&nbsp;обработку его персональных данных. При этом соблюдаются
            условия, предусмотренные, в&nbsp;частности, ст. 10.1 Закона о&nbsp;персональных данных. Требования
            к&nbsp;содержанию такого согласия устанавливаются уполномоченным органом по&nbsp;защите прав субъектов
            персональных данных.</p>
          <p>5.7.1 Согласие на&nbsp;обработку персональных данных, разрешенных для распространения, Пользователь
            предоставляет Оператору непосредственно.</p>
          <p>5.7.2 Оператор обязан в&nbsp;срок не&nbsp;позднее трех рабочих дней с&nbsp;момента получения указанного
            согласия Пользователя опубликовать информацию об&nbsp;условиях обработки, о&nbsp;наличии запретов
            и&nbsp;условий на&nbsp;обработку неограниченным кругом лиц персональных данных, разрешенных для
            распространения.</p>
          <p>5.7.3 Передача (распространение, предоставление, доступ) персональных данных, разрешенных субъектом
            персональных данных для распространения, должна быть прекращена в&nbsp;любое время по&nbsp;требованию
            субъекта персональных данных. Данное требование должно включать в&nbsp;себя фамилию, имя, отчество (при
            наличии), контактную информацию (номер телефона, адрес электронной почты или почтовый адрес) субъекта
            персональных данных, а&nbsp;также перечень персональных данных, обработка которых подлежит прекращению.
            Указанные в&nbsp;данном требовании персональные данные могут обрабатываться только Оператором, которому оно
            направлено.</p>
          <p>5.7.4 Согласие на&nbsp;обработку персональных данных, разрешенных для распространения, прекращает свое
            действие с&nbsp;момента поступления Оператору требования, указанного в&nbsp;п. 5.7.3 настоящей Политики
            в&nbsp;отношении обработки персональных данных.</p><br/>
          <p><b>6. Принципы обработки персональных данных</b></p><br/>
          <p>6.1. Обработка персональных данных осуществляется на&nbsp;законной и&nbsp;справедливой основе.</p>
          <p>6.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных
            и&nbsp;законных целей. Не&nbsp;допускается обработка персональных данных, несовместимая с&nbsp;целями сбора
            персональных данных.</p>
          <p>6.3. Не&nbsp;допускается объединение баз данных, содержащих персональные данные, обработка которых
            осуществляется в&nbsp;целях, несовместимых между собой.</p>
          <p>6.4. Обработке подлежат только персональные данные, которые отвечают целям их&nbsp;обработки.</p>
          <p>6.5. Содержание и&nbsp;объем обрабатываемых персональных данных соответствуют заявленным целям обработки.
            Не&nbsp;допускается избыточность обрабатываемых персональных данных по&nbsp;отношению к&nbsp;заявленным
            целям их&nbsp;обработки.</p>
          <p>6.6. При обработке персональных данных обеспечивается точность персональных данных, их&nbsp;достаточность,
            а&nbsp;в&nbsp;необходимых случаях и&nbsp;актуальность по&nbsp;отношению к&nbsp;целям обработки персональных
            данных. Оператор принимает необходимые меры и/или обеспечивает их&nbsp;принятие по&nbsp;удалению или
            уточнению неполных или неточных данных.</p>
          <p>6.7. Хранение персональных данных осуществляется в&nbsp;форме, позволяющей определить субъекта персональных
            данных, не&nbsp;дольше, чем этого требуют цели обработки персональных данных, если срок хранения
            персональных данных не&nbsp;установлен федеральным законом, договором, стороной которого,
            выгодоприобретателем или поручителем по&nbsp;которому является субъект персональных данных. Обрабатываемые
            персональные данные уничтожаются либо обезличиваются по&nbsp;достижении целей обработки или в&nbsp;случае
            утраты необходимости в&nbsp;достижении этих целей, если иное не&nbsp;предусмотрено федеральным законом.</p><br/>
          <p><b>7. Цели обработки персональных данных</b></p><br/>
          <p>7.1. Цель обработки персональных данных Пользователя:</p>
          <p>&mdash;&nbsp;информирование Пользователя посредством отправки электронных писем;</p>
          <p>&mdash;&nbsp;заключение, исполнение и&nbsp;прекращение <nobr>гражданско-правовых</nobr> договоров, согласно
            учредительным документам Оператора;
          </p>
          <p>&mdash;&nbsp;ведения бухгалтерского учёта;</p>
          <p>&mdash;&nbsp;осуществления функции, полномочия и&nbsp;обязанности, возложенные законодательством
            РФ&nbsp;на&nbsp;Оператора, в&nbsp;том числе по&nbsp;предоставлению персональных данных в&nbsp;органы
            государственной власти, в&nbsp;Пенсионный фонд РФ, в&nbsp;Фонд социального страхования РФ,
            в&nbsp;Федеральный фонд обязательного медицинского страхования, а&nbsp;также в&nbsp;иные государственные
            органы;</p>
          <p>&mdash;&nbsp;предоставление доступа Пользователю к&nbsp;сервисам, информации и/или материалам, содержащимся
            на&nbsp;
            <nobr>веб-сайте</nobr>
            <a href="https://svetofore.com/">https://svetofore.com/</a>;
          </p>
          <p>&mdash;&nbsp;осуществления технической поддержки и&nbsp;сопровождения;</p>
          <p>&mdash;&nbsp;проведения рекламных и&nbsp;новостных рассылок;</p>
          <p>&mdash;&nbsp;исполнения обязательств по&nbsp;договорам с&nbsp;Пользователями.</p>
          <p>7.2. Также Оператор имеет право направлять Пользователю уведомления о&nbsp;новых продуктах и&nbsp;услугах,
            специальных предложениях и&nbsp;различных событиях. Пользователь всегда может отказаться от&nbsp;получения
            информационных сообщений, направив Оператору письмо на&nbsp;адрес электронной почты <a
              href="mailto:support@svetofore.ru">support@svetofore.ru</a> с&nbsp;пометкой &laquo;Отказ
            от&nbsp;уведомлений о&nbsp;новых продуктах и&nbsp;услугах и&nbsp;специальных предложениях&raquo;.</p>
          <p>7.3. Обезличенные данные Пользователей, собираемые с&nbsp;помощью
            сервисов <nobr>интернет-статистики</nobr>, служат для сбора информации о&nbsp;действиях Пользователей
            на&nbsp;сайте, улучшения качества сайта и&nbsp;его содержания.
          </p><br/>
          <p><b>8. Правовые основания обработки персональных данных</b></p><br/>
          <p>8.1. Правовыми основаниями обработки персональных данных Оператором являются:</p>
          <p>&mdash;&nbsp;договоры, заключаемые между оператором и&nbsp;субъектом персональных данных;</p>
          <p>&mdash;&nbsp;федеральные законы, иные <nobr>нормативно-правовые</nobr> акты в&nbsp;сфере защиты
            персональных данных;
          </p>
          <p>&mdash;&nbsp;согласия Пользователей на&nbsp;обработку их&nbsp;персональных данных, на&nbsp;обработку
            персональных данных, разрешенных для распространения.</p>
          <p>8.2. Оператор обрабатывает персональные данные Пользователя только в&nbsp;случае их&nbsp;заполнения и/или
            отправки Пользователем самостоятельно через специальные формы, расположенные на&nbsp;сайте <a
              href="https://svetofore.com/">https://svetofore.com/</a> или направленные Оператору посредством
            электронной почты. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору,
            Пользователь выражает свое согласие с&nbsp;данной Политикой.</p>
          <p>8.3. Оператор обрабатывает обезличенные данные о&nbsp;Пользователе в&nbsp;случае, если это разрешено
            в&nbsp;настройках браузера Пользователя (включено сохранение
            файлов &laquo;cookie&raquo; и&nbsp;использование технологии JavaScript).</p>
          <p>8.4. Субъект персональных данных самостоятельно принимает решение о&nbsp;предоставлении его персональных
            данных и&nbsp;дает согласие свободно, своей волей и&nbsp;в&nbsp;своем интересе.</p>
          <p>8.5. Субъект персональных данных имеет право:</p>
          <p>&mdash;&nbsp;на&nbsp;получение персональных данных, относящихся к&nbsp;данному субъекту, и&nbsp;информации,
            касающейся их&nbsp;обработки;</p>
          <p>&mdash;&nbsp;на&nbsp;уточнение, блокирование или уничтожение его персональных данных в&nbsp;случае, если
            они являются неполными, устаревшими, неточными, незаконно полученными или не&nbsp;являются необходимыми для
            заявленной цели обработки;</p>
          <p>&mdash;&nbsp;на&nbsp;отзыв данного им&nbsp;согласия на&nbsp;обработку персональных данных;</p>
          <p>&mdash;&nbsp;на&nbsp;защиту своих прав и&nbsp;законных интересов, в&nbsp;том числе на&nbsp;возмещение
            убытков и&nbsp;компенсацию морального вреда в&nbsp;судебном порядке;</p>
          <p>&mdash;&nbsp;на&nbsp;обжалование действий или бездействия Оператора в&nbsp;уполномоченный орган
            по&nbsp;защите прав субъектов персональных данных или в&nbsp;судебном порядке.</p>
          <p>8.6. Для реализации своих прав и&nbsp;законных интересов субъекты персональных данных имеют право
            обратиться к&nbsp;Оператору в&nbsp;письменном виде, направить запрос лично или с&nbsp;помощью законного
            представителя. Запрос должен содержать сведения, указанные в&nbsp;ч. 3 ст. 14
            ФЗ&nbsp;&laquo;О&nbsp;персональных данных&raquo;.</p><br/>
          <p><b>9. Условия обработки персональных данных</b></p><br/>
          <p>9.1. Обработка персональных данных осуществляется с&nbsp;согласия субъекта персональных данных
            на&nbsp;обработку его персональных данных.</p>
          <p>9.2. Обработка персональных данных необходима для достижения целей, предусмотренных международным договором
            Российской Федерации или законом, для осуществления возложенных законодательством Российской Федерации
            на&nbsp;Оператора функций, полномочий и&nbsp;обязанностей.</p>
          <p>9.3. Обработка персональных данных необходима для осуществления правосудия, исполнения судебного акта, акта
            другого органа или должностного лица, подлежащих исполнению в&nbsp;соответствии с&nbsp;законодательством
            Российской Федерации об&nbsp;исполнительном производстве.</p>
          <p>9.4. Обработка персональных данных необходима для исполнения договора, стороной которого либо
            выгодоприобретателем или поручителем по&nbsp;которому является субъект персональных данных, а&nbsp;также для
            заключения договора по&nbsp;инициативе субъекта персональных данных или договора, по&nbsp;которому субъект
            персональных данных будет являться выгодоприобретателем или поручителем.</p>
          <p>9.5. Обработка персональных данных необходима для осуществления прав и&nbsp;законных интересов оператора
            или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не&nbsp;нарушаются
            права и&nbsp;свободы субъекта персональных данных.</p>
          <p>9.6. Осуществляется обработка персональных данных, доступ неограниченного круга лиц к&nbsp;которым
            предоставлен субъектом персональных данных либо по&nbsp;его просьбе (далее&nbsp;&mdash; общедоступные
            персональные данные).</p>
          <p>9.7. Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию
            в&nbsp;соответствии с&nbsp;федеральным законом.</p><br/>
          <p><b>10. Порядок сбора, хранения, передачи и&nbsp;других видов обработки персональных данных</b></p><br/>
          <p>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации
            правовых, организационных и&nbsp;технических мер, необходимых для выполнения в&nbsp;полном объеме требований
            действующего законодательства в&nbsp;области защиты персональных данных.</p>
          <p>10.1. Оператор принимает комплекс правовых, организационных и&nbsp;технических мер по&nbsp;обеспечению
            безопасности персональных данных, для обеспечения конфиденциальности персональных данных
            и&nbsp;их&nbsp;защиты от&nbsp;неправомерных действий:</p>
          <p>&mdash;&nbsp;обеспечивает неограниченный доступ к&nbsp;Политике, копия которой размещена по&nbsp;адресу
            нахождения Оператора, а&nbsp;также на&nbsp;сайте Оператора;</p>
          <p>&mdash;&nbsp;во&nbsp;исполнение Политики утверждает и&nbsp;приводит в&nbsp;действие
            документ &laquo;Положение об&nbsp;обработке персональных данных&raquo; и&nbsp;иные локальные акты;</p>
          <p>&mdash;&nbsp;производит ознакомление работников с&nbsp;положениями законодательства о&nbsp;персональных
            данных, а&nbsp;также с&nbsp;Политикой и&nbsp;Положением;</p>
          <p>&mdash;&nbsp;осуществляет допуск работников к&nbsp;персональным данным, обрабатываемым
            в&nbsp;информационной системе Оператора, а&nbsp;также к&nbsp;их&nbsp;материальным носителям только для
            выполнения трудовых обязанностей;</p>
          <p>&mdash;&nbsp;устанавливает правила доступа к&nbsp;персональным данным, обрабатываемым в&nbsp;информационной
            системе Оператора, а&nbsp;также обеспечивает регистрацию и&nbsp;учёт всех действий с&nbsp;ними;</p>
          <p>&mdash;&nbsp;производит оценку вреда, который может быть причинен субъектам персональных данных
            в&nbsp;случае нарушения ФЗ&nbsp;&laquo;О&nbsp;персональных данных&raquo;;</p>
          <p>&mdash;&nbsp;производит определение угроз безопасности персональных данных при их&nbsp;обработке
            в&nbsp;информационной системе Оператора;</p>
          <p>&mdash;&nbsp;применяет организационные и&nbsp;технические меры и&nbsp;использует средства защиты
            информации, необходимые для достижения установленного уровня защищенности персональных данных;</p>
          <p>&mdash;&nbsp;осуществляет обнаружение фактов несанкционированного доступа к&nbsp;персональным данным
            и&nbsp;принимает меры по&nbsp;реагированию, включая восстановление персональных данных, модифицированных или
            уничтоженных вследствие несанкционированного доступа к&nbsp;ним;</p>
          <p>&mdash;&nbsp;производит оценку эффективности принимаемых мер по&nbsp;обеспечению безопасности персональных
            данных до&nbsp;ввода в&nbsp;эксплуатацию информационной системы Оператора;</p>
          <p>&mdash;&nbsp;осуществляет внутренний контроль соответствия обработки персональных данных
            ФЗ&nbsp;&laquo;О&nbsp;персональных данных&raquo;, принятым в&nbsp;соответствии с&nbsp;ним нормативным
            правовым актам, требованиям к&nbsp;защите персональных данных, Политике, Положению и&nbsp;иным локальным
            актам, включающий контроль за&nbsp;принимаемыми мерами по&nbsp;обеспечению безопасности персональных данных
            и&nbsp;их&nbsp;уровня защищенности при обработке в&nbsp;информационной системе Оператора.</p>
          <p>10.2. Персональные данные Пользователя никогда, ни&nbsp;при каких условиях не&nbsp;будут переданы третьим
            лицам, за&nbsp;исключением случаев, связанных с&nbsp;исполнением действующего законодательства либо
            в&nbsp;случае, если субъектом персональных данных дано согласие Оператору на&nbsp;передачу данных третьему
            лицу для исполнения обязательств по&nbsp;
            <nobr>гражданско-правовому</nobr>
            договору.
          </p>
          <p>Персональные данные не&nbsp;могут быть переданы или раскрыты третьим лицам, за&nbsp;исключением компаний,
            входящих в&nbsp;одну группу лиц с&nbsp;Оператором согласно действующему законодательству РФ, без
            предварительного письменного согласия Пользователя, передавшей такую информацию.</p>
          <p>Положения настоящего пункта не&nbsp;распространяются на&nbsp;случаи обязательного в&nbsp;соответствии
            с&nbsp;применимым законодательством раскрытия информации, в&nbsp;частности, по&nbsp;требованию
            уполномоченных государственных органов.</p>
          <p>Персональные данные могут быть доступны только тем работникам Оператора, а&nbsp;также компаний, входящим
            в&nbsp;одну группу с&nbsp;Оператором согласно действующему законодательству РФ, которым персональные данные
            необходимы для выполнения их&nbsp;служебных (трудовых) обязанностей. При этом Оператор обеспечивает
            соблюдение своими работниками режима конфиденциальности в&nbsp;соответствии с&nbsp;настоящим пунктом.</p>
          <p>10.3. В&nbsp;случае выявления неточностей в&nbsp;персональных данных, Пользователь может актуализировать
            их&nbsp;самостоятельно, путем направления Оператору уведомление на&nbsp;адрес электронной почты Оператора <a
              href="mailto:support@svetofore.ru">support@svetofore.ru</a> с&nbsp;пометкой &laquo;Актуализация
            персональных данных&raquo;.</p>
          <p>10.4. Срок обработки персональных данных определяется достижением целей, для которых были собраны
            персональные данные, если иной срок не&nbsp;предусмотрен договором или действующим законодательством.</p>
          <p>Пользователь может в&nbsp;любой момент отозвать свое согласие на&nbsp;обработку персональных данных,
            направив Оператору уведомление посредством электронной почты на&nbsp;электронный адрес Оператора <a
              href="mailto:support@svetofore.ru">support@svetofore.ru</a> с&nbsp;пометкой &laquo;Отзыв согласия
            на&nbsp;обработку персональных данных&raquo;.</p>
          <p>10.5. Вся информация, которая собирается сторонними сервисами, в&nbsp;том числе платежными системами,
            средствами связи и&nbsp;другими поставщиками услуг, хранится и&nbsp;обрабатывается указанными лицами
            (Операторами) в&nbsp;соответствии с&nbsp;их&nbsp;Пользовательским соглашением и&nbsp;Политикой
            конфиденциальности. Субъект персональных данных и/или Пользователь обязан самостоятельно своевременно
            ознакомиться с&nbsp;указанными документами. Оператор не&nbsp;несет ответственность за&nbsp;действия третьих
            лиц, в&nbsp;том числе указанных в&nbsp;настоящем пункте поставщиков услуг.</p>
          <p>10.6. Установленные субъектом персональных данных запреты на&nbsp;передачу (кроме предоставления доступа),
            а&nbsp;также на&nbsp;обработку или условия обработки (кроме получения доступа) персональных данных,
            разрешенных для распространения, не&nbsp;действуют в&nbsp;случаях обработки персональных данных
            в&nbsp;государственных, общественных и&nbsp;иных публичных интересах, определенных законодательством РФ.</p>
          <p>10.7. Оператор при обработке персональных данных обеспечивает конфиденциальность персональных данных.</p>
          <p>10.8. Оператор осуществляет хранение персональных данных в&nbsp;форме, позволяющей определить субъекта
            персональных данных, не&nbsp;дольше, чем этого требуют цели обработки персональных данных, если срок
            хранения персональных данных не&nbsp;установлен федеральным законом, договором, стороной которого,
            выгодоприобретателем или поручителем по&nbsp;которому является субъект персональных данных.</p>
          <p>10.9. Условием прекращения обработки персональных данных может являться достижение целей обработки
            персональных данных, истечение срока действия согласия субъекта персональных данных или отзыв согласия
            субъектом персональных данных, а&nbsp;также выявление неправомерной обработки персональных данных.</p><br/>
          <p><b>11. Перечень действий, производимых Оператором с&nbsp;полученными персональными данными</b></p><br/>
          <p>11.1. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
            изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
            блокирование, удаление и&nbsp;уничтожение персональных данных.</p>
          <p>11.2. Оператор осуществляет автоматизированную обработку персональных данных с&nbsp;получением и/или
            передачей полученной информации по&nbsp;
            <nobr>информационно-телекоммуникационным</nobr>
            сетям или без таковой.
          </p>
          <p>11.3. Базы данных информации, содержащей персональные данные граждан Российской Федерации, находятся
            на&nbsp;территории Российской Федерации.</p><br/>
          <p><b>12. Трансграничная передача персональных данных</b></p><br/>
          <p>12.1. Оператор до&nbsp;начала осуществления трансграничной передачи персональных данных обязан убедиться
            в&nbsp;том, что иностранным государством, на&nbsp;территорию которого предполагается осуществлять передачу
            персональных данных, обеспечивается надежная защита прав субъектов персональных данных.</p>
          <p>12.2. Трансграничная передача персональных данных на&nbsp;территории иностранных государств,
            не&nbsp;отвечающих вышеуказанным требованиям, может осуществляться только в&nbsp;случае наличия согласия
            в&nbsp;письменной форме субъекта персональных данных на&nbsp;трансграничную передачу его персональных данных
            и/или исполнения договора, стороной которого является субъект персональных данных.</p><br/>
          <p><b>13. Конфиденциальность персональных данных</b></p><br/>
          <p>Оператор и&nbsp;иные лица, получившие доступ к&nbsp;персональным данным, обязаны не&nbsp;раскрывать третьим
            лицам и&nbsp;не&nbsp;распространять персональные данные без согласия субъекта персональных данных, если иное
            не&nbsp;предусмотрено федеральным законом.</p><br/>
          <p><b>14. Заключительные положения</b></p><br/>
          <p>14.1. Пользователь может получить любые разъяснения по&nbsp;интересующим вопросам, касающимся обработки его
            персональных данных, обратившись к&nbsp;Оператору с&nbsp;помощью электронной почты <a
              href="mailto:support@svetofore.ru">support@svetofore.ru</a>.</p>
          <p>14.2. В&nbsp;данном документе будут отражены любые изменения политики обработки персональных данных
            Оператором. Политика действует бессрочно до&nbsp;замены ее&nbsp;новой версией.</p>
          <p>14.3. Актуальная версия Политики в&nbsp;свободном доступе расположена в&nbsp;сети Интернет
            по&nbsp;адресу <a href="https://svetofore.com/privacy">https://svetofore.com/privacy</a>.</p>
        </div>
      </TabPane>
      <TabPane tab={<p className="privacy__tab-title">Публичная оферта о&nbsp;согласии<br />с&nbsp;обработкой персональных данных</p>} key="2">
        <div>
          <h2><strong>Публичная оферта о&nbsp;согласии с&nbsp;обработкой персональных данных</strong></h2><br/>
          <p>Пользователь (дееспособное физическое лицо, достигшее 18 лет и&nbsp;пользующееся сайтом <a
            href="https://svetofore.com/.">www.svetofore.com/.</a>) выражает Обществу с&nbsp;ограниченной
            ответственностью &laquo;Витал&raquo; (далее по&nbsp;тексту&nbsp;&mdash; Оператор) согласие с&nbsp;обработкой
            предоставляемых им&nbsp;персональных данных Оператору при соблюдении следующих условий:</p><br/>
          <p>1. Пользователь предоставляет следующие персональные данные:</p>
          <p>&mdash;&nbsp;Фамилия, имя, отчество.</p>
          <p>&mdash;&nbsp;Адрес электронной почты.</p>
          <p>&mdash;&nbsp;Номера телефонов.</p>
          <p>&mdash;&nbsp;Год, месяц, дата и&nbsp;место рождения.</p>
          <p>&mdash;&nbsp;Адрес фактического места проживания и&nbsp;регистрации по&nbsp;месту жительства и&nbsp;(или)
            по&nbsp;месту пребывания.</p>
          <p>&mdash;&nbsp;Фотографии.</p>
          <p>&mdash;&nbsp;Реквизиты документов, удостоверяющие личность.</p>
          <p>&mdash;&nbsp;Идентификационный номер налогоплательщика, дата постановки его на&nbsp;учет, реквизиты
            свидетельства постановки на&nbsp;учет в&nbsp;налоговом органе.</p>
          <p>&mdash;&nbsp;Сведения о&nbsp;гражданстве Российской Федерации и&nbsp;гражданстве (подданстве) иностранных
            государств.</p>
          <p>&mdash;&nbsp;Содержание отправленных Оператору сообщений.</p>
          <p>&mdash;&nbsp;Данные о&nbsp;лицах, от&nbsp;имени которых действует Пользователь.</p><br/>
          <p>2. Оператор получает персональные данные Пользователя непосредственно от&nbsp;Пользователя при
            регистрации/авторизации на&nbsp;сайте, в&nbsp;целях использования имеющего функционала на&nbsp;сайте
            Оператора, а&nbsp;также доступа к&nbsp;сервисам, информации и/или материалам размещённым на&nbsp;сайте.</p><br/>
          <p>3. Предоставляя свои персональные данные при регистрации/авторизации на&nbsp;сайте Оператора, Пользователь
            даёт Оператору согласие на&nbsp;сбор, систематизацию, накопление, хранение, уточнение (обновление или
            изменение), использование, распространение, передачу третьим лицам, обезличивание, блокирование
            и&nbsp;уничтожение персональных данных.</p><br/>
          <p>4. Оператор использует персональные данные в&nbsp;следующих целях:</p>
          <p>&mdash;&nbsp;информирование Пользователя посредством отправки электронных писем;</p>
          <p>&mdash;&nbsp;заключение, исполнение и&nbsp;прекращение <nobr>гражданско-правовых</nobr> договоров, согласно
            учредительным документам Оператора;
          </p>
          <p>&mdash;&nbsp;ведения бухгалтерского учёта;</p>
          <p>&mdash;&nbsp;осуществления функции, полномочия и&nbsp;обязанности, возложенные законодательством
            РФ&nbsp;на&nbsp;Оператора, в&nbsp;том числе по&nbsp;предоставлению персональных данных в&nbsp;органы
            государственной власти, в&nbsp;Пенсионный фонд РФ, в&nbsp;Фонд социального страхования РФ,
            в&nbsp;Федеральный фонд обязательного медицинского страхования, а&nbsp;также в&nbsp;иные государственные
            органы;</p>
          <p>&mdash;&nbsp;предоставление доступа Пользователю к&nbsp;сервисам, информации и/или материалам, содержащимся
            на&nbsp;
            <nobr>веб-сайте</nobr>
            <a href="https://svetofore.com/">https://svetofore.com/</a>;
          </p>
          <p>&mdash;&nbsp;осуществления технической поддержки и&nbsp;сопровождения;</p>
          <p>&mdash;&nbsp;проведения рекламных и&nbsp;новостных рассылок;</p>
          <p>&mdash;&nbsp;исполнения обязательств по&nbsp;договорам с&nbsp;Пользователями.</p>
          <p>Персональные данные не&nbsp;могут быть переданы или раскрыты третьим лицам, за&nbsp;исключением компаний,
            входящих в&nbsp;одну группу лиц с&nbsp;Оператором согласно действующему законодательству РФ, без
            предварительного письменного согласия Пользователя, передавшей такую информацию.</p>
          <p>Персональные данные могут быть доступны только тем работникам Оператора, а&nbsp;также компаний, входящим
            в&nbsp;одну группу с&nbsp;Оператором согласно действующему законодательству РФ, которым персональные данные
            необходимы для выполнения их&nbsp;служебных (трудовых) обязанностей. При этом Оператор обеспечивает
            соблюдение своими работниками режима конфиденциальности в&nbsp;соответствии с&nbsp;настоящим пунктом.</p><br/>
          <p>5. Согласие действительно в&nbsp;течение:</p>
          <p>&mdash;&nbsp;15 (пятнадцати) лет с&nbsp;момента регистрации на&nbsp;сайте или с&nbsp;момента последнего
            посещения сайта, в&nbsp;зависимости от&nbsp;того, какое событие наступило позднее,</p>
          <p>&mdash;&nbsp;если пользователь не&nbsp;отозвал согласие на&nbsp;обработку его персональных данных
            в&nbsp;письменном виде (заявление на&nbsp;отзыв согласия). При получении такого заявления Оператор обязан
            будет прекратить обрабатывать персональные данные Пользователя, что повлечёт (или может повлечь)
            за&nbsp;собой полное закрытие аккаунта Пользователя на&nbsp;сайте Оператора и&nbsp;аннулирует регистрацию
            неисключительных прав Пользователя на&nbsp;продукт Оператора в&nbsp;системе Оператора. Оператор
            не&nbsp;несёт ответственности за&nbsp;финансовые потери, в&nbsp;результате подобного заявления Пользователя
            и&nbsp;в&nbsp;данном случае не&nbsp;компенсирует понесённые Пользователем затраты на&nbsp;приобретение
            неисключительных прав на&nbsp;продукт Оператора.</p><br/>
          <p>6. Оператор обязуется не&nbsp;разглашать полученные от&nbsp;Пользователя персональные данные.
            Не&nbsp;считается нарушением данного обязательства предоставление Оператором персональных
            данных <nobr>партнёрам-дистрибьюторам</nobr>, для исполнения обязательств перед Пользователями.
            Не&nbsp;считается нарушением обязательств разглашение персональных данных в&nbsp;соответствии
            с&nbsp;обоснованными и&nbsp;применимыми требованиями закона.
          </p><br/>
          <p>7. Оператор вправе использовать технологию &laquo;cookies&raquo; и&nbsp;получать информацию об&nbsp;
            <nobr>ip-адресе</nobr>
            Пользователя. &laquo;Cookies&raquo; не&nbsp;содержат конфиденциальную информацию и&nbsp;не&nbsp;используются
            для установления личности Пользователя.
          </p><br/>
          <p>8. В&nbsp;случае отзыва согласия на&nbsp;обработку персональных данных Пользователем, Оператор гарантирует,
            что вся полученная от&nbsp;Пользователя информация, в&nbsp;том числе логин и&nbsp;пароль, автоматически
            удаляется из&nbsp;баз данных Оператора, после чего Пользователь не&nbsp;будет иметь доступ к&nbsp;сайту
            Оператора и&nbsp;к&nbsp;своей пользовательской панели на&nbsp;сайте Оператора с&nbsp;их&nbsp;помощью.
            С&nbsp;целью отзыва согласия на&nbsp;обработку персональных данных Пользователю необходимо предоставить
            Оператору заявление на&nbsp;отзыв настоящего согласия с&nbsp;указанием адреса сайта и&nbsp;названия
            Оператора, с&nbsp;подписью Пользователя и&nbsp;датой заявления.</p>
          <p>Используя сайт я&nbsp;принимаю публичную оферту, размещённую на&nbsp;сайте, и&nbsp;даю своё согласие
            на&nbsp;обработку <nobr>ООО &laquo;Витал&raquo;</nobr> моих персональных данных, относящихся исключительно
            к&nbsp;перечисленным ниже категориям персональных данных:
          </p>
          <p>&mdash;&nbsp;Фамилия, имя, отчество.</p>
          <p>&mdash;&nbsp;Адрес электронной почты.</p>
          <p>&mdash;&nbsp;Номера телефонов.</p>
          <p>&mdash;&nbsp;Год, месяц, дата и&nbsp;место рождения.</p>
          <p>&mdash;&nbsp;Адрес фактического места проживания и&nbsp;регистрации по&nbsp;месту жительства и&nbsp;(или)
            по&nbsp;месту пребывания.</p>
          <p>&mdash;&nbsp;Фотографии.</p>
          <p>&mdash;&nbsp;Реквизиты документов, удостоверяющие личность.</p>
          <p>&mdash;&nbsp;Идентификационный номер налогоплательщика, дата постановки его на&nbsp;учет, реквизиты
            свидетельства постановки на&nbsp;учет в&nbsp;налоговом органе.</p>
          <p>&mdash;&nbsp;Сведения о&nbsp;гражданстве Российской Федерации и&nbsp;гражданстве (подданстве) иностранных
            государств.</p>
          <p>&mdash;&nbsp;Содержание отправленных Оператору сообщений.</p>
          <p>&mdash;&nbsp;Данные о&nbsp;лицах, от&nbsp;имени которых действует Пользователь.</p><br/>
          <p>Я&nbsp;даю согласие на&nbsp;использование персональных данных исключительно в&nbsp;целях их&nbsp;обработки,
            а&nbsp;также на&nbsp;хранение данных об&nbsp;этих результатах на&nbsp;электронных носителях.</p>
          <p>Настоящее согласие предоставляется мной на&nbsp;осуществление действий в&nbsp;отношении моих персональных
            данных, которые необходимы для достижения указанных выше целей, включая (без ограничения) сбор,
            систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, передачу третьим
            лицам для осуществления действий по&nbsp;обмену информацией, обезличивание, блокирование персональных
            данных, а&nbsp;также осуществление любых иных действий, предусмотренных действующим законодательством
            Российской Федерации.</p>
          <p>Я&nbsp;проинформирован, что <nobr>ООО &laquo;Витал&raquo;</nobr> гарантирует обработку моих персональных
            данных в&nbsp;соответствии с&nbsp;действующим законодательством Российской Федерации как
            неавтоматизированным, так и&nbsp;автоматизированным способами.
          </p>
          <p>Данное согласие действует до&nbsp;достижения целей обработки персональных данных или в&nbsp;течение срока
            хранения информации.</p>
          <p>Данное согласие может быть отозвано в&nbsp;любой момент по&nbsp;моему письменному заявлению.</p>
          <p>Я&nbsp;подтверждаю, что, давая такое согласие, я&nbsp;действую по&nbsp;собственной воле и&nbsp;в&nbsp;своих
            интересах.</p>
        </div>
      </TabPane>
      <TabPane tab={<p className="privacy__tab-title">Пользовательское соглашение</p>} key="3">
        <div>
          <h2><strong>Пользовательское соглашение</strong></h2><br/>
          <p style={{marginLeft: "auto"}}>Дата вступления в&nbsp;силу <nobr>02.03.2023 г.</nobr></p><br/>
          <p>Настоящее Пользовательское соглашение (именуемое в&nbsp;дальнейшем &laquo;Соглашение&raquo;) регулирует
            отношения между <nobr>ООО &laquo;Витал&raquo;</nobr> ИНН 2463257198 (именуемое
            в&nbsp;дальнейшем &laquo;Компания&raquo;), а&nbsp;также являющимся правообладателем Сайта, расположенного
            в&nbsp;сети Интернет по&nbsp;адресу &laquo;<a
              href="https://svetofore.com">https://svetofore.com</a>&raquo; (далее&nbsp;&mdash; &laquo;Сайт&raquo;),
            и&nbsp;физическим или юридическим лицом (именуемым в&nbsp;дальнейшем &laquo;Пользователь&raquo;),
            использующим
            Сайт.
          </p><br/>
          <p><b>1. Общие положения</b><br/><br/>
            1.1. Компания сохраняет за&nbsp;собой право изменять настоящее Соглашение в&nbsp;любое время
            без <nobr>какого-либо</nobr> специального уведомления. Если Пользователь продолжает пользоваться Сайтом
            после
            публикации изменений в&nbsp;Соглашении, считается, что Пользователь тем самым принимает изменения условий
            Соглашения.<br/>
            1.2. Функциональность Сайта определяется Компанией по&nbsp;собственному усмотрению и&nbsp;включает
            в&nbsp;себя, но&nbsp;не&nbsp;ограничивается: предоставлением Пользователю информации, размещаемой
            на&nbsp;Сайте, предоставлением Пользователю возможности размещать свою информацию на&nbsp;Сайте
            и&nbsp;передавать информацию Компании. В&nbsp;отношении отдельной функциональности Сайта Компания вправе
            устанавливать дополнительные условия её предоставления. Для обработки данных Пользователя Компания вправе
            привлекать третьих лиц, оставаясь ответственной за&nbsp;их&nbsp;действия.<br/>
            1.3. Если Пользователь использует функциональность Сайта по&nbsp;приглашению третьего лица, уполномоченного
            Компанией, использование Сайта осуществляется на&nbsp;основании договора между Пользователем и&nbsp;таким
            третьим лицом.
          </p><br/>
          <p><b>2. Осуществление регистрации на&nbsp;Сайте</b><br/><br/>
            2.1. При осуществлении регистрации на&nbsp;Сайте Пользователь обязан предоставить Компании информацию,
            запрашиваемую в&nbsp;соответствующих формах на&nbsp;Сайте. Пользователь несет ответственность
            за&nbsp;достоверность, актуальность и&nbsp;соответствие законодательству Российской Федерации информации,
            предоставленной им&nbsp;при осуществлении регистрации и&nbsp;использовании Сайта.<br/>
            2.2. Пользователь не&nbsp;вправе осуществлять регистрацию на&nbsp;Сайте от&nbsp;имени&nbsp;другого лица,
            а&nbsp;также использовать учетные записи третьих лиц.<br/>
            2.3. Регистрируясь на&nbsp;сайте, Пользователь подтверждает, что все указанные им&nbsp;персональные данные
            принадлежат лично ему.<br/>
            2.4. Пользователь обязуется сохранять конфиденциальность сведений, используемых для доступа к&nbsp;Сайту.
            Все
            действия на&nbsp;Сайте, совершенные с&nbsp;использованием регистрационных сведений Пользователя, считаются
            совершенными Пользователем.</p><br/>
          <p><b>3. Пользование Сайтом</b><br/><br/>
            3.1. Пользователь вправе пользоваться функциональностью Сайта, открытой Компаний для конкретного
            Пользователя.<br/>
            3.2. При пользовании Сайта Пользователь обязан соблюдать положения законодательства Российской Федерации,
            данного Соглашения и&nbsp;иных договоров с&nbsp;Компанией.<br/>
            3.3. При пользовании Сайтом Пользователь не&nbsp;вправе загружать, распространять, публиковать,
            предоставлять
            доступ или иным образом использовать:<br/>
            <ul>
              <li>&mdash;&nbsp;информацию, содержащую бранные слова, непристойные и&nbsp;оскорбительные образы, сравнения
                и&nbsp;выражения;
              </li>
              <li>&mdash;&nbsp;информацию, призывающую к&nbsp;насилию и&nbsp;жестокости, побуждающую к&nbsp;совершению противоправных
                действий, содержащую угрозы в&nbsp;отношении третьих лиц, порочащую честь, достоинство или деловую
                репутацию третьих лиц, содержащую недостоверную информацию о&nbsp;третьих лицах;
              </li>
              <li>&mdash;&nbsp;вульгарную или непристойную информацию, информацию порнографического характера;</li>
              <li>&mdash;&nbsp;информацию с&nbsp;ограниченным доступом (в&nbsp;том числе информацию, содержащую государственную,
                коммерческую, или иную тайну, конфиденциальную информацию, информацию о&nbsp;частной жизни третьих лиц);
              </li>
              <li>&mdash;&nbsp;рекламную информацию, нарушающую требования применимых положений российского законодательства;</li>
              <li>&mdash;&nbsp;информацию о&nbsp;наркотических и&nbsp;психотропных веществах;</li>
              <li>&mdash;&nbsp;информацию, направленную на&nbsp;пропаганду войны, разжигание национальной, расовой или религиозной
                ненависти и&nbsp;вражды, а&nbsp;также иную информацию, за&nbsp;распространение которой предусмотрена
                уголовная или административная ответственность согласно законодательству Российской Федерации;
              </li>
              <li>&mdash;&nbsp;специальные категории персональных данных.</li>
            </ul>
            3.4. При использовании Сайта Пользователь не&nbsp;вправе:<br/>
            &mdash;&nbsp;незаконно использовать результаты интеллектуальной деятельности Компании и&nbsp;третьих
            лиц;<br/>
            &mdash;&nbsp;нарушать нормальное функционирование Сайта;<br/>
            &mdash;&nbsp;осуществлять <nobr>какие-либо</nobr> действия, которые по&nbsp;мнению Компании являются
            нежелательными для функционирования Сайта;<br/>
            &mdash;&nbsp;нарушать права и&nbsp;интересы третьих лиц;<br/>
            &mdash;&nbsp;осуществлять распространение рекламы на&nbsp;Сайте;<br/>
            &mdash;&nbsp;
            <nobr>каким-либо</nobr>
            иным образом нарушать положения законодательства Российской Федерации.<br/>
            3.5. Размещая на&nbsp;Сайте <nobr>какие-либо</nobr> материалы, Пользователь безвозмездно предоставляет
            Компании простую (неисключительную) лицензию на&nbsp;использование Компанией таких материалов с&nbsp;правом
            сублицензирования на&nbsp;территории всего мира в&nbsp;течение всего срока действия исключительного права
            на&nbsp;такие материалы путём воспроизведения и&nbsp;доведения до&nbsp;всеобщего сведения.<br/>
            3.6. Компания вправе по&nbsp;собственному усмотрению, без объяснения причин и&nbsp;без уведомления
            Пользователя:<br/>
            <ul>
              <li>&mdash;&nbsp;удалить или заблокировать доступ Пользователя к&nbsp;Сайту;</li>
              <li>&mdash;&nbsp;удалить и/или обезличить любую информацию, размещенную Пользователем на&nbsp;Сайте;</li>
              <li>&mdash;&nbsp;ограничить Пользователю возможность размещения информации на&nbsp;Сайте.</li>
            </ul>
          </p><br/>
          <p><b>4. Ответственность</b><br/><br/>
            4.1. Пользователь несет ответственность перед Компанией и/или третьими лицами за&nbsp;размещение,
            распространение, публикацию им&nbsp;на&nbsp;Сайте <nobr>какой-либо</nobr> информации, если эти действия
            приведут к&nbsp;нарушению прав и&nbsp;законных интересов Компании и/или третьих лиц.<br/>
            4.2. На&nbsp;Сайте могут размещаться ссылки на&nbsp;сайты в&nbsp;сети Интернет, принадлежащие третьим лицам.
            Компания не&nbsp;несет ответственность за&nbsp;содержание таких сайтов, на&nbsp;которые Пользователь
            переходит по&nbsp;таким ссылкам.<br/>
            4.3. Компания не&nbsp;несет ответственности за&nbsp;любой ущерб, возникший вследствие использования Сайта,
            включая ущерб, возникший <nobr>из-за</nobr> предоставления <nobr>каких-либо</nobr> функций Сайта
            Пользователю, или вследствие удаления <nobr>какой-либо</nobr> информации Пользователя.<br/>
            4.4. Компания не&nbsp;несет ответственности и&nbsp;не&nbsp;имеет прямых или косвенных обязательств перед
            Пользователем в&nbsp;связи с&nbsp;любыми возможными или возникшими потерями или убытками, связанными
            с&nbsp;использованием Сайта, в&nbsp;связи с&nbsp;рекламой, которая может быть размещена на&nbsp;Сайте, или
            в&nbsp;связи с&nbsp;техническими неполадками на&nbsp;Сайте.
          </p><br/>
          <p><b>5. Заключительные положения</b><br/><br/>
            5.1. Компания вправе в&nbsp;одностороннем порядке и&nbsp;без уведомления Пользователя расторгнуть данное
            Соглашение.<br/>
            5.2. Действующая редакция Соглашения находится на&nbsp;Сайте на&nbsp;странице по&nbsp;адресу <a
              href="https://svetofore.com/privacy/">https://svetofore.com/privacy/</a><br/>
            5.3. Компания вправе передать свои права и/или обязанности по&nbsp;настоящему Соглашению третьей стороне как
            полностью, так и&nbsp;частично.<br/>
            5.4. К&nbsp;настоящему Соглашению и&nbsp;отношениям между Пользователем и&nbsp;Компанией, возникающим
            в&nbsp;связи с&nbsp;исполнением Соглашения, подлежит применению законодательство Российской Федерации.<br/>
            5.5. Признание одного или нескольких положений настоящего Соглашения недействительными или не&nbsp;имеющими
            юридической силы не&nbsp;влияет на&nbsp;действительность или применимость остальных положений настоящего
            Соглашения.</p><br/>
        </div>
      </TabPane>
    </Tabs>
    </div>
  );
}
