import React, { useEffect, useState } from "react";
import Spinner from "../../../spinner";
import { Button, Col, Table, Row, notification } from "antd";
import { tableHelper } from "../../../../helpers";
import ModalListShipCompanies from "../../../modals/list-ship-companies";
import ModalEditLegalEntityDocs from '../../../modals/edit-legal-entity-docs';
import { useFetchNotification } from "../../../../hooks/use-fetch-notification";
import NotificationMessage from "../../../modals/notification-message";
import ModalCreateNewLegalEntity from "../../../modals/create-legal-entity";
import ModalEditLegalEntityNew from "../../../modals/edit-legal-entity-new";
import {companyService, userServices} from "../../../../services";
import { COMPANY_DIRECTOR_PERMISSION } from "../../../../helpers/user_permission.helper";
import { Link, Route, Redirect } from "react-router-dom";

export default function LegalEntities() {
  const [loading, setLoading] = useState(false);
  const [isNeedUpdateEntities, setIsNeedUpdateEntities] = useState(false);
  const [stopListUpdate, setStopListUpdate] = useState(false);

  const [showModalCreateEntity, setShowModalCreateEntity] = useState(false);
  const [showModalCreateEntityTest, setShowModalCreateEntityTest] = useState(false);

  const [showModalShipCompanies, setShowModalShipCompanies] = useState(false);
  const [currentEntityId, setCurrentEntityId] = useState(null);
  const [currentOrgFormName, setCurrentOrgFormId] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [entitiesData, setEntitiesData] = useState(null);

  const [isShowEditEntity, setShowEditEntity] = useState(false);
  const [isShowEditEntityDocs, setShowEditEntityDocs] = useState(false);

  const [{ notifications }] = useFetchNotification(true);

  const user = userServices.getUser();

  let bigButtonStyle = { width: '190px', height: '40px', background: '#1CAA53', position: 'static', borderRadius: '10px', float: 'right', borderColor: "#1CAA53" };

  useEffect(() => {
    if (loading === false && stopListUpdate !== true) {
      setLoading(true);
      setIsNeedUpdateEntities(true);
    }
  }, [isNeedUpdateEntities, stopListUpdate]);

  useEffect(() => {
      if (isNeedUpdateEntities) {
          companyService
              .getEntitiesList()
              .then((response) => {
                  let reindexedTableData = [];
                  response.forEach((item) => {
                      reindexedTableData.push({
                          name: item.Name,
                          orgForm: item.OrgForm,
                          orgFormId: item.OrgFormId,
                          id: item.Id,
                          confirmation: item.VitalAgreed ? 'Да' : 'Нет',
                          permissions: item.Permissions
                      });
                  });

                  setEntitiesData(reindexedTableData);
                  setIsNeedUpdateEntities(false);
                  setLoading(false);
              })
              .catch((errorMsg) => {
                  setIsNeedUpdateEntities(false);
                  setErrorMsg(errorMsg);
                  setLoading(false);
              });
      }
  }, [isNeedUpdateEntities]);

  const handleEditLegalEntity = entityId => {
    setCurrentEntityId(entityId);
    setShowEditEntity(true);
  };

  const handleDeleteLegalEntity = entityId => {
    setLoading(true);

    companyService
      .deleteLegalEntity(entityId)
      .then((response) => {
        notification.success({
          message: "Удаление юр.лица",
          description: response.message,
        });

        setLoading(false);
        setIsNeedUpdateEntities(true);
      })
      .catch((error) => {
        notification.error({
          message: "Удаление юр.лица",
          description: error,
        });

        setLoading(false);
      });
  };

  const handleEditLegalEntityDocs = entityId => {
    setCurrentEntityId(entityId);
    setCurrentOrgFormId(entitiesData.find(x => x.id === entityId).orgFormId);
    setShowEditEntityDocs(true);
  };

  if (errorMsg) {
    return <div className="content-error-block">{errorMsg}</div>;
  }

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      width: "35%",
      ...tableHelper.getColumnSearchProps("name", searchText, setSearchText),
    },
    {
      title: "Орг. форма",
      dataIndex: "orgForm",
      width: "10%",
    },
    {
      title: "Подтверждение с Виталом",
      dataIndex: "confirmation",
      width: "20%",
    },
    {
      title: "",
      key: "ship-companies",
      width: "20%",
      render: (data) => {
        return (
          <React.Fragment>
            <Button
              type="primary"
              htmlType="button"
              style={{
                width: "170px",
                height: "50px",
                background: "#1CAA53",
                position: "static",
                borderRadius: "10px",
                color: "white",
                borderColor: "transparent",
              }}
              size="small"
              className="small-button typical-btn-green"
              onClick={() => {
                setCurrentEntityId(data.id);
                setShowModalShipCompanies(true);
              }}
            >
              {!data.permissions.IsLegalEntityEditable
                ? "Заключить договоры"
                : "Отправить на согласование"}
            </Button>
          </React.Fragment>
        );
      },
    },
    {
      title: "",
      key: "actions",
      width: "30%",
      render: (data) => {
        if (data.permissions.IsLegalEntityEditable) {
          return (
            <React.Fragment>
              <Button title="Редактировать" type="primary" icon="edit" htmlType="button" size="small" className="small-button typical-btn-green" onClick={() => handleEditLegalEntity(data.id)} />
              <Button title="Редактировать документы" type="primary" icon="file-word" htmlType="button" size="small" className="small-button typical-btn-green" onClick={() => handleEditLegalEntityDocs(data.id)} />
            </React.Fragment>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: "",
      key: "delete",
      width: "10%",
      render: (data) => {
        if (data.permissions.IsLegalEntityDeletable) {
          return (
            <React.Fragment>
              <Button title="Удалить" type="primary" icon="delete" htmlType="button" size="small" className="small-button typical-btn-red" onClick={() => handleDeleteLegalEntity(data.id)} />
            </React.Fragment>
          );
        } else {
          return null;
        }
      },
    },
  ];


  if (!user.isAccessTo(COMPANY_DIRECTOR_PERMISSION)) {
    return <Redirect to="/company/car-park" />;
  }

  return (
    <React.Fragment>
      <NotificationMessage
        notifications={notifications}
        setStopListUpdate={setStopListUpdate}
        loading={false}
      />

      <Row>
        <Col
          className={"content-header"}
          style={{ marginBottom: "0px" }}
          span={12}
        >
          Юридические лица
        </Col>
        <Col span={8} style={{ marginBottom: "0px" }} offset={4}>
          <Button
            type="primary"
            htmlType="button"
            onClick={() => {
              setShowModalCreateEntityTest(true);
            }}
            style={bigButtonStyle}
          >
            Добавить юр. лицо
          </Button>
          <ModalCreateNewLegalEntity
            isShow={showModalCreateEntityTest}
            setIsShow={setShowModalCreateEntityTest}
            setLoadingList={setIsNeedUpdateEntities}
            setStopListUpdate={setStopListUpdate}
          />
        </Col>
      </Row>

      <Col span={24}>
        <Spinner loading={loading} tip="Загрузка...">
          <Table
            style={{ minWidth: "700px" }}
            columns={columns}
            dataSource={entitiesData}
            locale={{ emptyText: "Данные отсутствуют" }}
            rowKey="id"
            size="small"
            pagination={{
              pageSize: 50,
            }}
            className="car-park"
            rowClassName={(record, index) => "car-park-row"}
          />
        </Spinner>
      </Col>
      <ModalListShipCompanies
        isShow={showModalShipCompanies}
        setIsShow={setShowModalShipCompanies}
        legalEntityId={currentEntityId}
      />

      <ModalEditLegalEntityNew
        isShow={isShowEditEntity}
        setIsShow={setShowEditEntity}
        legalEntityId={currentEntityId}
        setLoadingList={setLoading}
        setStopListUpdate={setStopListUpdate}
      />
      <ModalEditLegalEntityDocs
        isShow={isShowEditEntityDocs}
        setIsShow={setShowEditEntityDocs}
        legalEntityId={currentEntityId}
        orgForm={currentOrgFormName}
      />
    </React.Fragment>
  );
}
