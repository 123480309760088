import React, {useEffect, useState} from 'react';
import {companyService, driverService} from '../../../services';
import {Modal, notification, Button, Table, Col, Row} from 'antd';
import {fileHelper} from "../../../helpers/file.helper";

const driverInfoAliases = {
  fio: 'Водитель',
  phone: 'Моб. номер',
  passportSerial: 'Паспорт серия',
  passportNumber: 'Паспорт номер',
  passportDate: 'Паспорт выдан',
  scanPassportHomePage: 'Скан главной страницы паспорта',
  scanPassportRegPage: 'Скан страницы прописки',
  License: 'Водительская лицензия',
  scanDriverLicense1: 'Скан лицевой стороны водительской лицензии',
  scanDriverLicense2: 'Скан обратной стороны водительской лицензии',
  regCountry: 'Страна регистрации',
};

const truckInfoAliases = {
  name: 'Авто',
  carrying: 'Грузоподъемность',
  capacity: 'Вместимость',
  regCountry: 'Страна регистрации',
  scanSts1: 'Скан СТС 1',
  scanSts2: 'Скан СТС 2',
};

const trailInfoAliases = {
  name: 'Прицеп',
  carrying: 'Грузоподъемность',
  capacity: 'Вместимость',
  scanSts1: 'Скан СТС 1',
  scanSts2: 'Скан СТС 2',
};

export default function ModalDriverInfo({driverId, truckId, trailId, isShow, setIsShow}) {

  const [tableData, setTableData] = useState([]);
  const [table1Data, setTable1Data] = useState([]);
  const [truckTableData, setTruckTableData] = useState([]);
  const [trailTableData, setTrailTableData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [truckDataLoaded, setDataTruckLoaded] = useState(false);
  const [trailDataLoaded, setDataTrailLoaded] = useState(false);

  const [columns, setColumns] = useState([
    {
      title: '',
      key: 'name',
      render: record => {
        if (record.isBoldText) {
          return (<b>{record.name}</b>);
        }
        return record.name;
      }
    },
    {
      title: '',
      key: 'data',
      render: record => {
        function isUrl(string) {
          let regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
          return regexp.test(string);
        }

        if (isUrl(record.data)) {
          return (
            <React.Fragment>
              <a href={record.data} target="_blank" rel="noopener noreferrer" className="active">
                <img alt={record.data} src={record.data} Style="width: 300px;"/>
              </a>
            </React.Fragment>
          );
        } else if (record.name === "Моб. номер") {
          return (
            <React.Fragment>
              <a href={`tel:+7${record.data}`}
                 className="active">{record.data.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, `+7 ($1) $2 $3 $4`)}</a>
            </React.Fragment>
          )
        } else {
          if (record.data === 0 || record.data === 1) {
            return record.data ? 'Да' : 'Нет';
          }
          return record.data
        }
      }
    },
  ]);

  useEffect(() => {
    if (isShow) {
      if (!dataLoaded) {
        companyService.getCompanyDriversQuestionnaire(driverId)
          .then((response) => {
            setTableData(response);

            setDataLoaded(true);
          })
          .catch((err) => {
            notification.error({
              message: "Получение информации о водителе",
              description: err,
            });
            setDataLoaded(false);
          })
      }

      if (truckId !== null && !truckDataLoaded) {
        companyService.getCompanyTrucksQuestionnaire(truckId)
          .then((response) => {
            setTruckTableData(response);

            setDataTruckLoaded(true);
          })
          .catch((err) => {
            notification.error({
              message: "Получение информации об автомобиле",
              description: err,
            });
            setDataTruckLoaded(false);
          })
      }

      if (trailId !== null && !trailDataLoaded) {
        companyService.getCompanyTrailsQuestionnaire(trailId)
          .then((response) => {
            setTrailTableData(response);

            setDataTrailLoaded(true);
          })
          .catch((err) => {
            notification.error({
              message: "Получение информации о прицепе",
              description: err,
            });
            setDataTrailLoaded(false);
          })
      }
    }
  }, [dataLoaded, trailDataLoaded, truckDataLoaded, isShow]);

  useEffect(() => {
    if (isShow && tableData) {
      let data = [];

      Object.keys(tableData).forEach((element) => {
        if (tableData[element] !== null && tableData[element] !== '') {
          if (typeof driverInfoAliases[element] !== 'undefined')
            data.push(
              {
                name: driverInfoAliases[element],
                data: tableData[element],
                isBoldText: element === 'fio'
              }
            );
        }
      });

      Object.keys(truckTableData).forEach((element) => {
        if (truckTableData[element] !== null && truckTableData[element] !== '') {
          if (typeof truckInfoAliases[element] !== 'undefined')
            data.push(
                {
                  name: truckInfoAliases[element],
                  data: truckTableData[element],
                  isBoldText: element === 'name'
                }
            );
        }
      });

      if (trailTableData) {
        Object.keys(trailTableData).forEach((element) => {
          if (trailTableData[element] !== null && trailTableData[element] !== '') {
            if (typeof trailInfoAliases[element] !== 'undefined')
              data.push(
                  {
                    name: trailInfoAliases[element],
                    data: trailTableData[element],
                    isBoldText: element === 'name'
                  }
              );
          }
        });
      }
      setTable1Data(data);
    }
  }, [isShow, tableData]);

  const handleDownloadDocsArchive = (driverId) => {
    driverService.getDocsArchive({id: driverId})
      .then(resp => {
        fileHelper.downloadFileBase64(resp, `Документы водителя ${tableData ? tableData.fio : driverId}.zip`);
      })
      .catch(err => {
        notification.error({
          message: "Загрузка архива водителя",
          description: err
        });
      });
  };

  return (
    <Modal
      title={'Информация о водителе'}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
      }}
      footer={<Row gutter={[10, 10]}>
        <Col offset={8} span={8}>
          <Button
            htmlType="button"
            key="submitPrev"
            onClick={() => handleDownloadDocsArchive(driverId)}
            type="primary"
            size="large"
            style={{width: '100%'}}
          >
            Скачать документы в архиве
          </Button>
        </Col>
      </Row>}
    >
      <Table
        showHeader={false}
        columns={columns}
        dataSource={table1Data}
        rowKey="name"
        pagination={false}
        size="small"
      />
    </Modal>
  );

}
