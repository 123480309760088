import React, { useEffect, useState } from "react";
import Spinner from "../../../spinner";
import { Button, Col, Table, Row } from "antd";
import { tableHelper } from "../../../../helpers";
import {consignorService} from "../../../../services/consignor.services";
import ModalCreateConsignor from "../../../modals/create-consignor";

export default function Consignors() {
  const [loading, setLoading] = useState(false);
  const [isNeedUpdateConsignors, setIsNeedUpdateConsignors] = useState(false);
  const [showModalCreateConsignor, setShowModalCreateConsignor] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [consignorsData, setConsignorsData] = useState(null);

  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateConsignors(true);
    }
  }, [isNeedUpdateConsignors]);

  if (isNeedUpdateConsignors) {
    consignorService
      .getConsignorsList()
      .then((response) => {
        let reindexedTableData = [];
        response.forEach((item) => {
          reindexedTableData.push({
            name: item.Name,
            id: item.Id,
            dateCreated: item.DateCreated,
            isVital: item.IsVital ? 'Да' : 'Нет',
          });
        });

        setConsignorsData(reindexedTableData);
        setIsNeedUpdateConsignors(false);
        setLoading(false);
      })
      .catch((errorMsg) => {
        setIsNeedUpdateConsignors(false);
        setErrorMsg(errorMsg);
        setLoading(false);
      });
  }

  if (errorMsg) {
    return <div className="content-error-block">{errorMsg}</div>;
  }

  const columns = [
      {
          title: "Название",
          dataIndex: "name",
          key: "name",
          width: "30%",
          ...tableHelper.getColumnSearchProps("name", searchText, setSearchText),
      },
      {
          title: "Витал",
          dataIndex: "isVital",
          key: "isVital",
          width: "30%",
          ...tableHelper.getColumnSearchProps("isVital", searchText, setSearchText),
      },
      {
          title: "Дата создания",
          dataIndex: "dateCreated",
          key: "dateCreated",
          width: "30%",
          ...tableHelper.getColumnSearchProps("dateCreated", searchText, setSearchText),
      },
  ];
  return (
    <React.Fragment>
      <h1 className="content-header">Грузоотправители</h1>
      {/*<Row gutter={48} style={{ paddingRight: "0" }}>*/}
      {/*  <Col span={24} style={{ textAlign: "right", height: "40px", marginBottom: "24px" }}>*/}
      {/*    <Button*/}
      {/*      type="primary"*/}
      {/*      htmlType="button"*/}
      {/*      onClick={() => {*/}
      {/*        setShowModalCreateConsignor(true);*/}
      {/*      }}*/}
      {/*      style={{ backgroundColor: "#1CAA53", border: "none", height: "100%" }}*/}
      {/*    >*/}
      {/*      Добавить Грузоотправителя*/}
      {/*    </Button>*/}
      {/*    <ModalCreateConsignor isShow={showModalCreateConsignor} setIsShow={setShowModalCreateConsignor} />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Col span={24}>
        <Spinner loading={loading} tip="Загрузка...">
          <Table
            style={{ minWidth: "700px" }}
            columns={columns}
            dataSource={consignorsData}
            locale={{ emptyText: "Данные отсутствуют" }}
            rowKey="name"
            size="small"
            pagination={{
              pageSize: 50,
            }}
          />
        </Spinner>
      </Col>
    </React.Fragment>
  );
}
