import React, { useEffect } from "react";
import { Row, Col } from "antd";
import './percent.css';
export default function Percent({ percentValue, setPrepaymentPercent }) {

	const percentValues = [
		{ id: 1, value: 10 },
		{ id: 2, value: 20 },
		{ id: 3, value: 30 },
		{ id: 4, value: 40 },
		{ id: 5, value: 50 }
	]

	const onClickSetPercent = (e, value) => {
		setPrepaymentPercent(value);
		for (let item of e.currentTarget.parentElement.children) {
			item.classList.remove("menu-item-selected");
		}

		e.currentTarget.classList.add("menu-item-selected");
	};


	return (
		<Row gutter={16} type="flex" style={{ alignItems: "center", justifyContent: "center" }}>
			<div className="percent-types-inline">
				{percentValues.map((type) => {
					return (
						<div
							key={type.id}
							onClick={(e) => onClickSetPercent(e, type.value)}
							className={`menu-item`}
							data-id={type.id}
						>	
							{type.value}%
						</div>
					);
				})}
			</div>
		</Row>
	);
	
}
