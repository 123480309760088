import React, { useEffect, useState, useContext } from "react";
import { httpRequest } from "../../services/http-request";
import ModalDeliveryInfo from '../modals/delivery-info';
import { Button, } from 'antd';
import { notification } from "antd";
import { AppContext } from "../../contexts/app-context";
import {VIEW_AGREED_DELIVERIES_LOGIST} from "../../helpers/user_permission.helper";

export function DeliveryHistoryButton({ delivery }) {

	const [showModal, setShowModal] = useState(false);
	const [response, getResponse] = useState(0);
	const [tableData, setTableData] = useState(0);
	const [showButton, setShowButton] = useState(false);
	const appContext = useContext(AppContext);
	const user = appContext.user;

	useEffect(() => {
		if (user.info.role === "logistics_director" || user.info.role === "logistics_director_plus" || user.info.role === "st_logist"
			|| user.info.role === "logist") {
			setShowButton(true);
		} else if (user.isAccessTo(VIEW_AGREED_DELIVERIES_LOGIST) && delivery.Status === 'agreed') {
		    setShowButton(true);
        }

		if (response !== 0) {
			let url = "delivery/get-delivery-history?deliveryId=" + response;
			httpRequest.get(url).then(function (res) {
				setTableData(res);
				getResponse(0);
			}).catch((e) => {
				notification.error({
					message: "У Вас нет доступа!",
					description: e,
				});
				return false;
			})
		}

	}, [showModal]);

	const closeModalHandler = () => {
		setShowModal(false);
		getResponse(0);
	}

	const renderModal = () => {
		if (showModal === true && tableData.data !== undefined) {
			return (
				<ModalDeliveryInfo
					deliveryInfo={tableData.data}
					deliveryId={response}
					isShow={showModal}
					setIsShow={closeModalHandler}
				/>
			);
		} else {
			return null;
		}
	};

	const handleButtonClick = () => {
		setShowModal(true);
		getResponse(delivery.Id);
	}

	if (showButton) {
		return (
			<>
				<Button
					size="default"
					htmlType="button"
					title=""
					className={' action-button typical-btn-blue'}
					style={{right: "0px"}}
					onClick={handleButtonClick}
				>
					История рейса
				</Button>
				{renderModal()}
			</>
		);
	} else {
		return null;
	}
}
