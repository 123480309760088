import React, {useState} from "react";
import {Button, Row, Col, Modal, notification} from "antd";
import '../modals.css';
import PhoneField from "../../phone-field";
import {userServices} from "../../../services";

export default function ModalChangeUserLogin({
                                               isShow, id, setIsShow, setNeedUpdateList
                                             }) {
  let bigButtonStyle = {
    width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px'
  };
  const [newLoginCountryID, setNewLoginCountryID] = useState("");
  const [newLogin, setNewLogin] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [fieldsErrors, setFieldsErrors] = useState({});

  const onSignUp = () => {
    setIsDisabled(true);
    userServices.changeUserLogin(id, newLogin, newLoginCountryID)
      .then((response) => {
        if(response.code !== 400) {
          notification.success({
            message: "Изменение логина пользователя", description: response.message,
          });
          setIsShow(false);
          setNeedUpdateList(true);
        } else {
          setFieldsErrors(response.errors)
        }

      })
      .catch((err) => {
        notification.error({
          message: "Изменение логина пользователя", description: err,
        });
      })
      .finally(() => {
        setIsDisabled(false);
      })
  }

  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
      ? fieldsErrors[nameField]
      : null;
  };

  return (<Modal
    title={<div>Изменение логина пользователя</div>}
    cancelText="Закрыть"
    className="modal-window"
    align={null}
    visible={isShow}
    onCancel={() => {
      setIsShow(false);
    }}
    forceRender={true}
    footer={
      <Button htmlType="submit" key="submit" type="primary"
              style={bigButtonStyle}
              onClick={() => onSignUp()}
              disabled={newLoginCountryID === "" || newLogin === "" || isDisabled}
      >Отправить</Button>
    }
  >
    <Row>
      <Col>
        <PhoneField
          getErrors={getFieldErrors}
          countryFieldName="newLoginCountryID"
          countryFieldWidth="104px"
          phoneFieldName="newLogin"
          phoneFieldWidth="200px"
          title="Новый логин"
          className="phone"
          selectedCountryID={newLoginCountryID}
          onChangeCountry={(value) => {
            setNewLoginCountryID(value);
          }}
          phoneNumber={newLogin}
          onChangePhone={(e) => {
            setNewLogin(e.target.value);
          }}
        />
      </Col>
    </Row>
  </Modal>);
}
