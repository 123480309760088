import React from 'react';
import {
    Form, Icon, Input, Button, Row, Col, notification
} from 'antd';

import './reset-page.css';
import 'antd/lib/button/style/index.less';
import {Redirect} from "react-router-dom";
import {AppContext} from "../../../contexts/app-context";
import Spinner from "../../spinner";
import GoogleAndroidAppLink from "../../google-android-app-link";
import MaskedInput from "antd-mask-input";

class ResetPage extends React.Component{

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    onSubmitFormLogin = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {

                this.setState({
                    loading: true
                });

                this.context
                    .reset(values.login)
                    .then((data) => {
                        this.props.history.push({
                            pathname: '/login',
                            state: {
                                reset: {
                                    username: data
                                }
                            }
                        });
                    })
                    .catch((errorMsg) => {
                        notification.warning({
                            message: "Восстановление пароля",
                            description: errorMsg,
                        });
                    }).then(() => {
                    this.setState({
                        loading: false
                    });
                });
            }
        });
    };

    render() {

        if (this.context.user.loggedIn) {
            return (<Redirect to="/"/>);
        }

        if (this.context.filter.enable){
            this.context.disableFilter();
        }

        const {getFieldDecorator} = this.props.form;

        return (
            <Spinner loading={this.state.loading} label="Подождите">
                <Row>
                    <Col span={12} offset={6} className="login-page-content">
                        <h1>Восстановление пароля</h1>
                        <Form onSubmit={this.onSubmitFormLogin} className="login-form">
                            <Form.Item>
                                {getFieldDecorator('login', {
                                    rules: [{required: true, message: 'Заполните логин'}],
                                })(
                                    <Input size="large" prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                           placeholder="Логин"/>
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    Восстановить
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={12} offset={6}>
                        <GoogleAndroidAppLink/>
                    </Col>
                </Row>
            </Spinner>
        );
    }
}

export default Form.create({ name: 'normal_login' })(ResetPage);