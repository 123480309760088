import {useState, useEffect, useContext} from "react";
import {AppContext} from "../contexts/app-context";
import {deliveriesConfig} from "../config";
import axios from "axios";
import {deliveriesService, Filters} from "../services";
import {notificationServices} from "../services/notification.services"


export function useFetchDeliveriesList(
    urlPath = '',
    showFilter = true,
    updateEnabled = false,
    needDeliveries = true,
    needNotifications = true,
    intervalUpdatedSec = deliveriesConfig.secondForUpdate,
    deliveriesListOffset = 0,
    deliveriesListLimit = 2000,
    requestTimeout = 100000
) {
    const appContext = useContext(AppContext);

    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [extData, setExtData] = useState([]);
    const [needUpdate, setNeedUpdate] = useState(true);
    const [stopListUpdate, setStopListUpdate] = useState(false);
    const [notifications, setNotifications] = useState(null);
    let loading = ((data === null && needDeliveries) || needUpdate || (notifications === null && needNotifications));
    const [permissions, setPermissions] = useState([]);
    let isComponentUnmounted = false;
    let sourceHttpRequest = null;
    let autoUpdateInterval = null;

	function getFilter(urlPath) {
		if (urlPath === "delivery/get-free-for-guests") {
			Filters.getFiltersForGuest().then(
			(response) => {
			    if (response.From.length || response.To.length) {
                    return appContext.enableFilter(Object.values(response.From),Object.values(response.To));
                }
			});
		} else {
			Filters.getFilters().then(
			(response) => {
                if (response.From.length || response.To.length) {
                    return appContext.enableFilter(Object.values(response.From),Object.values(response.To));
                }
			});
		}
	}

  useEffect(() => {
      if (needUpdate) {
          setData(null);
          sourceHttpRequest = axios.CancelToken.source();
          if(needDeliveries){
              deliveriesService.getDeliveries(
                  urlPath,
                  sourceHttpRequest.token,
                  appContext.filter.selectedRegionsLoading,
                  appContext.filter.selectedRegionsUnloading,
                  appContext.filter.selectedSorting,
                  deliveriesListOffset,
                  deliveriesListLimit,
                  requestTimeout
              ).then(
                  (response) => {
                      if (isComponentUnmounted === false) {
                          setNeedUpdate(false);
                          setTotalCount(response.TotalCount);
                          setExtData(response.extData);
                          setData(response.deliveries);
                          setPermissions(response.permissions);
                          sourceHttpRequest = null;

                          if (showFilter){
                              getFilter(urlPath);
                          }
                      }
                  },
                  (errorMsg) => {
                      if (isComponentUnmounted === false){
                          setNeedUpdate(false);
                          setError(errorMsg);
                          sourceHttpRequest = null;
                      }
                  }
              ).catch((errorMsg) => {
                  if (isComponentUnmounted === false){
                      setNeedUpdate(false);
                      setError(errorMsg);
                      sourceHttpRequest = null;
                  }
              });
          }

          if(needNotifications){
              notificationServices.getNotifications()
                  .then((response) => {
                      setNeedUpdate(false);
                      setNotifications(response);
                  })
                  .catch((errorMsg) => {
                      if (isComponentUnmounted === false){
                          setNeedUpdate(false);
                          setError(errorMsg);
                          sourceHttpRequest = null;
                      }
                  })
          }
      }
  }, [needUpdate]);


    // эффект при создании и удалении компонента
    useEffect(() => {
        return () => {
            isComponentUnmounted = true;

            if (sourceHttpRequest !== null) {
                sourceHttpRequest.cancel("Cancel HTTP request");
            }
            if (autoUpdateInterval !== null) {
                clearInterval(autoUpdateInterval);
                autoUpdateInterval = null;
            }
        }
    },[]);


    // эффект для автоматического обновления списка
    useEffect(() => {
        if(updateEnabled){
            autoUpdateInterval = setInterval(() => {
                if (needUpdate === false && stopListUpdate === false){
                    setNeedUpdate(true);
                }
            }, intervalUpdatedSec);


            return () => {
                if (autoUpdateInterval !== null){
                    clearInterval(autoUpdateInterval);
                    autoUpdateInterval = null;
                }
            }
        }
    }, [needUpdate, stopListUpdate]);


    // эффект для применения параметров фильтра
    useEffect(() => {
        if (showFilter && needUpdate === false) {
            setNeedUpdate(true);
        }
    }, [
        appContext.filter.selectedRegionsLoading,
        appContext.filter.selectedRegionsUnloading,
        appContext.filter.selectedSorting,
    ]);

    return [{
        loading,
        data,
        error,
        setStopListUpdate,
        setNeedUpdate,
        notifications,
        permissions,
		    totalCount,
        extData
    }];
}
