import React from "react";
import {Form} from "antd";

export default function FieldBlock({children, name, title, getErrors, keyNum = null, style = {}}) {

    const fieldErrors = keyNum !== null ? getErrors(name, keyNum) : getErrors(name);
    const validateStatus = fieldErrors ? 'error' : 'success';

    const titleComponent = (title) ? <span className="form-field-label">{title}</span> : null;

    return (
        <React.Fragment>
            {titleComponent}
            <Form.Item validateStatus={validateStatus} help={fieldErrors} style={style}>
                {children}
            </Form.Item>
        </React.Fragment>
    );


    // if (fieldErrors){
    //
    //     const titleComponent = (title) ? <span className="form-field-label">{title}</span> : null;
    //
    //     return (
    //         <React.Fragment>
    //             {titleComponent}
    //             <Form.Item validateStatus="error" help={fieldErrors}>
    //                 {children}
    //             </Form.Item>
    //         </React.Fragment>
    //     );
    // }
    //
    // const titleComponent = (title) ? <span className="input-title">{title}</span> : null;
    //
    // return (
    //     <React.Fragment>
    //         {titleComponent}
    //         {children}
    //     </React.Fragment>
    // );
}
