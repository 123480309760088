import React, {useEffect, useState} from 'react';
import Spinner from "../../../spinner";
import {Button, Col, DatePicker, Icon, Row, Table} from "antd";
import {reportService} from "../../../../services/report.services";
import moment from "moment";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import SelectField from "../../../select-field";


export default function ReportStatsMobileAppUsers() {

  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);

  // параметры для построения отчета
  const [dateStart, setDateStart] = useState(moment().startOf('month'));
  const [dateEnd, setDateEnd] = useState(moment().endOf('month'));
  const [totalMobileUsers, setTotalMobileUsers] = useState(0);
  const [totalCallsCount, setTotalCallsCount] = useState(0);

  const [totalMobileDrivers, setTotalMobileDrivers] = useState(0);
  const [totalCallsCountDrivers, setTotalCallsCountDrivers] = useState(0);

  const [tableData, setTableData] = useState([]);

  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateReport(true);
    }

    if (isNeedUpdateReport) {
      // запрос на получение данных отчета
      reportService
        .getStatsMobileAppUsers(
          dateStart.format("YYYY-MM-DD"),
          dateEnd.format("YYYY-MM-DD")
        )
        .then((response) => {
          setTableData(response.Rows);
          setTotalCallsCount(response.Params.totalCallsCount);
          setTotalMobileUsers(response.Params.totalMobileUsers);
          setTotalMobileDrivers(response.Params.totalMobileDrivers);
          setTotalCallsCountDrivers(response.Params.totalCallsCountDrivers);

          setIsNeedUpdateReport(false);
          setLoading(false);
        })
        .catch((errorMsg) => {
          setIsNeedUpdateReport(false);
          setLoading(false);
          setErrorMsg(errorMsg);
        });
    }
  }, [isNeedUpdateReport]);


  // список столбцов таблицы
  const columns = [
    {
      title: 'ФИО Водителя',
      dataIndex: 'Name',
      key: 'name',
      width: '27%',
      sorter: (a, b) => {
        return a.Name.localeCompare(b.Name);
      },
    },
    {
      title: 'Дата первого запуска',
      dataIndex: 'DateFirstLaunch',
      key: 'column1',
      width: '12%',
      sorter: (a, b) => {
        return moment(a.DateFirstLaunch).unix() - moment(b.DateFirstLaunch).unix();
      },
    },
    {
      title: 'Дата последнего запуска',
      dataIndex: 'DateLastLaunch',
      key: 'column2',
      width: '12%',
      sorter: (a, b) => {
        return moment(a.DateLastLaunch).unix() - moment(b.DateLastLaunch).unix();
      },
    },
    {
      title: 'Кол-во запусков',
      dataIndex: 'LaunchCount',
      key: 'column3',
      width: '10%',
      sorter: (a, b) => {
        return Number(a.LaunchCount) - Number(b.LaunchCount);
      },
    },
    {
      title: 'Кол-во звонков',
      dataIndex: 'CallsCount',
      key: 'column4',
      width: '10%',
      sorter: (a, b) => {
        return Number(a.CallsCount) - Number(b.CallsCount);
      },
    },
    {
      title: 'Кол-во заявок',
      dataIndex: 'OrdersCount',
      key: 'column5',
      width: '10%',
      sorter: (a, b) => {
        return Number(a.OrdersCount) - Number(b.OrdersCount);
      },
    }
  ];

  return (
    <React.Fragment>
      <div className="reports__header">
        <h1 className="content-header">Отчет «Статистика использования мобильного приложения»</h1>
        <Icon type='filter'
              theme={isFilterOpen ? '' : "filled"}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen)
              }}
              className="reports__filter-toggle"/>
        {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
          <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start">
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateStart}
                format="DD.MM.YYYY"
                size="normal"
                name="dateStart"
                placeholder="Выберите дату начала периода"
                locale={localeDatePicker}
                allowClear={false}
                onChange={(date) => {
                  setDateStart(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateEnd}
                format="DD.MM.YYYY"
                size="normal"
                name="dateEnd"
                placeholder="Выберите дату окончания периода"
                locale={localeDatePicker}
                allowClear={false}
                onChange={(date) => {
                  setDateEnd(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{textAlign: 'right', height: '40px'}}>
              <Button type="primary"
                      htmlType="button"
                      onClick={() => {
                        setLoading(true);
                        setIsNeedUpdateReport(true);
                      }}
                      style={{backgroundColor: '#1CAA53', border: 'none', height: '100%', width: '100%'}}
              >
                Применить
              </Button>
            </Col>
          </Row>
        </div>}
        <Row gutter={12} style={{padding: "0", rowGap: "10px", marginTop: '10px'}} type="flex" justify="start">
          <Col lg={12} md={12} sm={24} xs={24} style={{textAlign: 'left', marginBottom: '5px'}}>Всего водителей
            мобильного приложения: {totalMobileDrivers}</Col>
          <Col lg={12} md={12} sm={24} xs={24} style={{textAlign: 'left', marginBottom: '5px'}}>Всего пользователей
            мобильного
            приложения: {totalMobileUsers}</Col>
          <Col lg={12} md={12} sm={24} xs={24} style={{textAlign: 'left', marginBottom: '5px'}}>Количество активных
            водителей за
            период: {totalCallsCountDrivers}</Col>
          <Col lg={12} md={12} sm={24} xs={24} style={{textAlign: 'left', marginBottom: '5px'}}>Всего активных
            пользователей за период: {totalCallsCount}</Col>
        </Row>
      </div>
      <div className="reports__table">
        <div className="reports__table-wrapper">
          <Col span={24}>
            <Table
              style={{minWidth: '700px'}}
              columns={columns}
              dataSource={tableData}
              locale={{emptyText: "Данные отсутствуют"}}
              rowKey="key"
              size="small"
              loading={loading}
              pagination={{
                pageSize: 50
              }}
            />
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
}
