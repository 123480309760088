import React, {useContext} from "react";
import {Button} from "antd";
import {useLocation} from "react-router-dom";
import {AppContext} from "../../contexts/app-context";
import Cookies from "js-cookie";
import moment from "moment";

export default function DeliveryEmptyContent({extData = []}) {
    const appContext = useContext(AppContext);
    const isInnerUserType = appContext.user.info.userType === 'inner';
    const isTelegramAttached = appContext.user.info.isTelegramAttached;
    const path = useLocation().pathname.split("/").slice(-1);
    const isFreeDelivery = Boolean(path[0] === 'list-new' || path[0] === 'list-free');
    const title = isFreeDelivery  && !isInnerUserType ? 'Рейсы не найдены' : 'Рейсы отсутствуют';
    const lastDate = !!extData.length ? moment(extData.LastDeliveryDate).format("D MMMM") : '';

    const clearFilter = () => {
        appContext.applyFilterParams([],[], 'default');
        Cookies.remove(`regionsLoading-${path}`);
        Cookies.remove(`regionsUnloading-${path}`);
    }

    const Info = () => {
        return <>
            {isTelegramAttached && <>
                <p className="empty-delivery__text">По данному направлению рейсы отсутствуют.
                    Как только они появятся, вы сразу получите уведомление.</p>

                <Button
                    type="button"
                    htmlType="button"
                    className="empty-delivery__button"
                    onClick={clearFilter}
                >
                    Показать все рейсы
                </Button>
            </>
            }
            {!isTelegramAttached && <>
                <p className="empty-delivery__text">По данному направлению рейсы отсутствуют.
                    {!!extData.length && <span className="empty-delivery__text-underline">Но ещё {lastDate} они были!</span>}
                </p>
                <div className="empty-delivery__link-container">
                    <p className="empty-delivery__text">
                        <span className="empty-delivery__text-bold">Установите чат-бот от</span>
                        <span className="empty-delivery__text-bold empty-delivery__text-accent">"Светофор Логистика"</span>
                        и вы никогда не пропустите новых рейсов
                    </p>
                    <div className="empty-delivery__image">
                        <img src="/img/qr.svg" alt="app-link" width={187} height={177}/>
                    </div>
                </div>
                <Button
                    type="link"
                    htmlType="button"
                    className="empty-delivery__button"
                    href="https://t.me/VitalLogisticBot"
                    target="_blank"

                >
                    <span>Перейти</span>
                    <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.216 0.55162C3.9009 1.26169 10.5926 8.72266 10.5926 8.72266C10.958 9.1038 11.1415 9.59981 11.1415 10.0958C11.1415 10.5918 10.958 11.0878 10.5926 11.4655C10.5926 11.4655 3.9009 18.9299 3.216 19.6365C2.5311 20.3466 1.29893 20.3953 0.569793 19.6365C-0.160987 18.8812 -0.218335 17.8248 0.569793 16.8972L6.70932 10.0958L0.569793 3.29444C-0.218335 2.36683 -0.160987 1.30868 0.569793 0.55162C1.29893 -0.207181 2.5311 -0.160191 3.216 0.55162Z" fill="currentcolor"/>
                    </svg>
                </Button>
            </>
            }
        </>
    }

    return (
        <div className={`empty-delivery`}>
            <span className="empty-delivery__title">{title}</span>
            {(isFreeDelivery && !isInnerUserType) && <Info/>}
        </div>
    );
}
