import React, {useState, useEffect} from "react";
import {Button, Modal, notification, Row, Col, Spin, Checkbox} from "antd";
import {distributionCenterService} from "../../../services";
import {roleService, userServices} from "../../../services";
import InputField from "../../input-field";
import SelectField from "../../select-field";
import {formHelper} from "../../../helpers";
import '../modals.css';

export default function ModalEditStaffUser({isShow, userId, currentUserData, onCloseModal}) {

    const [loadingContentModal, setLoadingContentModal] = useState(false);
    const [needUpdateContent, setNeedUpdateContent] = useState(false);

    const [listStaffRoles, setListStaffRoles] = useState([]);
    const [listDistCenters, setListDistCenters] = useState([]);

    const [userData, setUserData] = useState(currentUserData);

    const [fieldsErrors, setFieldsErrors] = useState(null);

    const getFieldErrors = (nameField) => formHelper.getFieldErrors(nameField, fieldsErrors);
    let bigButtonStyle = {
        width: '290px',
        height: '75px',
        fontSize: '24px',
        color: 'white',
        backgroundColor: '#BE1C1C',
        borderRadius: '7px',
        border: 'none'
    };

    useEffect(() => {
        if (isShow && !loadingContentModal){
            setLoadingContentModal(true);
            setNeedUpdateContent(true);
            setUserData(currentUserData);
        }
    }, [isShow]);

    if (needUpdateContent) {

        // запрашиваем список штатных
        roleService.getRoles()
            .then((response) => {
                setNeedUpdateContent(false);
                setLoadingContentModal(false);

                const staffRolesData = response.map((roleData)  => {
                    return {
                        Id : roleData.Name,
                        Name : roleData.Description,
                    }
                });

                setListStaffRoles(staffRolesData);
            })
            .catch((error) => {
                setNeedUpdateContent(false);
                setLoadingContentModal(false);
                notification.error({
                    message: "Получение ролей",
                    description: error,
                });
            });

        // запрашиваем список РЦ
        distributionCenterService.getCompanies()
            .then((response) => {
                setNeedUpdateContent(false);
                setLoadingContentModal(false);

                const distCentersData = response.map((distCenterInfo)  => {
                    return {
                        Id : distCenterInfo.Id,
                        Name : distCenterInfo.Name,
                    }
                });

                setListDistCenters(distCentersData);
            })
            .catch((error) => {
                setNeedUpdateContent(false);
                setLoadingContentModal(false);
                notification.error({
                    message: "Получение информации о РЦ",
                    description: error,
                });
            });
    }

    // сохранение информации
    const onClickBtnSave = (e) => {
        e.preventDefault();
        setLoadingContentModal(true);

        userServices.editStaffUser(
            userId, userData.fio, userData.login, userData.password, userData.email, userData.phone,
            userData.role, userData.status, userData.distributionsCentersIds
        )
            .then((response) => {

                setLoadingContentModal(false);

                if (response.status === "success") {
                    notification.success({message: "Редактирование пользователя", description: response.message});
                    onCloseModal();

                } else if (response.status === "error"){
                    setFieldsErrors(response.errors);
                }
            })
            .catch((error) => {
                notification.error({message: "Редактирование пользователя", description: error});
                setLoadingContentModal(false);
            });
    };

    return (
        <Modal
            title={<span>Редактирование пользователя<br/>«{currentUserData.fio}»</span>}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={onCloseModal}
            footer={[
                <Button htmlType="submit" key="submit" style={bigButtonStyle} type="primary" onClick={onClickBtnSave}>Сохранить</Button>,
            ]}
        >
            <Spin spinning={loadingContentModal} tip="Загрузка...">
                <Row gutter={16} className="modal-row-field">
                    <Col span={24} className="add-padding-10">
                        <InputField name="fio" label="Название"
                                    value={userData.fio}
                                    setValue={(fio) => setUserData({...userData, fio: fio})}
                                    getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} className="add-padding-10">
                        <InputField name="login" label="Логин"
                                    value={userData.login}
                                    setValue={(value) => setUserData({...userData, login: value})}
                                    getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} className="add-padding-10">
                        <InputField name="password" label="Пароль"
                                    value={userData.password}
                                    setValue={(value) => setUserData({...userData, password: value})}
                                    getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} className="add-padding-10">
                        <InputField name="email" label="Email"
                                    value={userData.email}
                                    setValue={(value) => setUserData({...userData, email: value})}
                                    getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} className="add-padding-10">
                        <InputField name="phone" label="Телефон (Формат: +79991112233)"
                                    value={userData.phone}
                                    setValue={(value) => setUserData({...userData, phone: value})}
                                    getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} className="add-padding-10">
                        <SelectField
                            title="Роль"
                            name="role"
                            selectedValue={userData.role}
                            values={listStaffRoles}
                            onChange={(value) => {
                                setUserData({...userData, role: value});
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Выберите роль"
                            notFoundText="Отсутствуют роль"
                            style={{width: "100%"}}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} className="add-padding-10">
                        <SelectField
                            title="Статус"
                            name="status"
                            selectedValue={userData.status}
                            values={[
                                {Id: 'active', Name: 'Активен'},
                                {Id: 'deleted', Name: 'Удален'}
                            ]}
                            onChange={(value) => {
                                setUserData({...userData, status: value});
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Выберите статус"
                            notFoundText="Отсутствуют статус"
                            style={{width: "100%"}}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} className="add-padding-10">
                        <SelectField
                            mode="multiple"
                            title="РЦ"
                            name="distributionsCentersIds"
                            selectedValue={userData.distributionsCentersIds}
                            values={listDistCenters}
                            onChange={(value) => {
                                setUserData({...userData, distributionsCentersIds: value});
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Выберите РЦ"
                            notFoundText="Отсутствуют РЦ"
                            style={{width: "100%"}}
                        />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}
