

export const stringsHelper = {
    ucFirst
};

function ucFirst(string) {
    if (string && string.length >= 1) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return "";
}