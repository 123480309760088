import React, { useEffect, useState } from "react";
import {Button, Col, Modal, Row, Spin, Form, notification, Checkbox} from "antd";
import {consignorService, distributionCenterService, roleService} from "../../../services/";
import InputField from "../../input-field";
import SelectField from "../../select-field";
import ModalDeactiveUser from "../../modals/user/deactive-user.js";

export default function ModalFormStaffUser({ isShow, setIsShow, onSuccess, userID = null }) {
  const [loading, setLoading] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const [rolesList, setRolesList] = useState([]);
  const [distributionCentersList, setDistributionCentersList] = useState([]);

  const [fio, setFio] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState(null);
  const [distributionCenters, setDistributionCenters] = useState([]);
  const [notifyDistributionCenters, setNotifyDistributionCenters] = useState([]);
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [fieldsErrors, setFieldsErrors] = useState({});
  const [blocked, setBlocked] = useState(false);
  const [approvePhoneVisible, setApprovePhoneVisible] = useState(false);
  const [showDeactiveUserModal, setShowDeactiveUserModal] = useState(false);

  const bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px' };
  const approveNumberButton = {height: '43px', background: '#2b3f63', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent", fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '500', lineHeight: '20px' };

  useEffect(() => {
    //setLoading(true);
    if (isShow) {
      //Получить список ролей
      roleService
        .getRolesForStaffUser()
        .then(response => {
          setRolesList(response);
        })
        .catch(errorMsg => {
          // setErrorMsg(errorMsg);
        });

        distributionCenterService.getCompanies(true)
            .then((response) => {
                const distCentersData = response.map((distCenterInfo) => {
                    return {
                        Id: distCenterInfo.Id,
                        Name: distCenterInfo.Name,
                    }
                });
                setDistributionCentersList(distCentersData);
            })
            .catch((error) => {
                notification.error({
                    message: "Получение информации о РЦ",
                    description: error,
                });
            });
    }
  }, [isShow]);

  useEffect(() => {
      setIsEditing(!!userID);

      if (isShow && userID) {
          setLoading(true);
          consignorService
              .getStaffUserInfo(userID)
              .then((resp) => {
                  setFio(resp.FIO);
                  setPhone(resp.Phone);
                  setEmail(resp.Email);
                  setRole(resp.Role);
                  setDistributionCenters(resp.DistributionCentersIDs.map((value) => {
                      return Number(value);
                  }));
                  setNotifyDistributionCenters(resp.NotifyDistributionCentersIDs.map((value) => {
                      return Number(value);
                  }));
                  setBlocked(resp.Blocked);

                  setLoading(false);
              })
              .catch((err) => {
                  notification.error({
                      message: "Получение информации о пользователе",
                      description: err,
                  });

                  setLoading(false);
              });
      }
  }, [isShow, userID]);

  const onSubmitFormCreate = e => {
    e.preventDefault();

    setLoading(true);

    consignorService
      .createStaffUser({ fio, phone, email, password, passwordRepeat, role, distributionCenters, notifyDistributionCenters })
      .then(responseData => {
        if (responseData.status === "success") {
          notification.success({
            message: "Создание сотрудника",
            description: responseData.message
          });
          setLoading(false);
          setIsShow(false);
          onSuccess();
        } else if (responseData.status === "error") {
          if (responseData.message) {
            notification.error({
              message: "Создание сотрудника",
              description: responseData.message
            });
          }
          setLoading(false);
          if (responseData.errors) {
            setFieldsErrors(responseData.errors);
          }

          if (responseData.errors && responseData.errors.phone_used) {
            setApprovePhoneVisible(true);
          }
        }
      })
      .catch(errorMsg => {
        notification.error({
          message: "Создание сотрудника",
          description: errorMsg
        });
        setLoading(false);
      });
  };

  const onSubmitFromEdit = () => {
      setLoading(true);

      consignorService
          .editStaffUser({ userID, fio, phone, email, role, distributionCenters, notifyDistributionCenters, blocked })
          .then(responseData => {
              if (responseData.status === "success") {
                  notification.success({
                      message: "Редактирование сотрудника",
                      description: responseData.message
                  });
                  setLoading(false);
                  setIsShow(false);
                  onSuccess();
              } else if (responseData.status === "error") {
                  if (responseData.message) {
                      notification.error({
                          message: "Редактирование сотрудника",
                          description: responseData.message
                      });
                  }
                  setLoading(false);
                  if (responseData.errors) {
                      setFieldsErrors(responseData.errors);
                  }
              }
          })
          .catch(errorMsg => {
              notification.error({
                  message: "Создание сотрудника",
                  description: errorMsg
              });
              setLoading(false);
          });
  };

  const getFieldErrors = fieldName => {
    return typeof fieldsErrors[fieldName] !== "undefined"
      ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
        return fieldErrors + "; " + error;
      })
      : null;
  };

  const renderFooterButton = () => {
      if (isEditing) {
          return (
              <Button htmlType="submit" key="submit" style={bigButtonStyle} type="primary" onClick={onSubmitFromEdit}>
                  Сохранить
              </Button>
          );
      }
      return (
          <Button htmlType="submit" key="submit" style={bigButtonStyle} type="primary" onClick={onSubmitFormCreate}>
              Создать
          </Button>
      );
  };

  return (
    <Modal
      title={`Создание сотрудника`}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
      }}
      footer={renderFooterButton()}
    >
      <Spin spinning={loading} tip="Загрузка...">
        <Form>
          <Row gutter={[16, 24]}>
            <Col lg={12} md={24} sm={24} xs={24} >
              <InputField name="fio" label="ФИО" value={fio} setValue={setFio} getFieldErrors={getFieldErrors} />
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} >
              <InputField name="phone" label="Моб.телефон" value={phone} setValue={setPhone} getFieldErrors={getFieldErrors} />
            </Col>
          </Row>
          {approvePhoneVisible ? (
          <Row gutter={[16, 24]}>
            <Col lg={12} md={24} sm={24} xs={24}>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Button
                style={approveNumberButton}
                htmlType="submit"
                type="primary"
                onClick={setShowDeactiveUserModal}
                key="get-codes"
              >
                Подтвердить доступ к номеру
              </Button>
              <ModalDeactiveUser isShow={showDeactiveUserModal} onCloseModal={setShowDeactiveUserModal} phone={phone} />
            </Col>
          </Row>) : null}
          <Row gutter={[16, 24]}>
            <Col lg={12} md={24} sm={24} xs={24} >
              <InputField name="email" label="E-Mail" value={email} setValue={setEmail} getFieldErrors={getFieldErrors} />
            </Col>
          </Row>
            {!isEditing &&
            <Row gutter={[16, 24]}>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <InputField name="password" label="Пароль" value={password} setValue={setPassword} getFieldErrors={getFieldErrors} type="password"/>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <InputField
                        name="passwordRepeat"
                        type="password"
                        label="Повторение пароля"
                        value={passwordRepeat}
                        setValue={setPasswordRepeat}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
            </Row>}
            <Row gutter={[16, 24]}>
                <Col lg={12} md={24} sm={24} xs={24} >
                    <SelectField
                        title="Роль"
                        name="role"
                        selectedValue={role}
                        values={rolesList}
                        onChange={(value) => {
                            setRole(value);
                        }}
                        getErrors={getFieldErrors}
                        placeholder="Выберите роль"
                        notFoundText="Роли отсутствуют"
                        style={{ width: "100%" }}
                    />
                </Col>
                <Col span={24} >
                    <SelectField
                        mode="multiple"
                        title="РЦ"
                        name="distributionsCentersIds"
                        selectedValue={distributionCenters}
                        values={distributionCentersList}
                        onChange={(value) => {
                            setDistributionCenters([...value]);
                        }}
                        getErrors={getFieldErrors}
                        placeholder="Выберите РЦ"
                        notFoundText="Отсутствуют РЦ"
                        style={{ width: "100%" }}
                    />
                </Col>
                <Col span={24} >
                    <SelectField
                        mode="multiple"
                        title="РЦ для уведомлений"
                        name="notifyDistributionsCentersIds"
                        selectedValue={notifyDistributionCenters}
                        values={distributionCentersList}
                        onChange={(value) => {
                            setNotifyDistributionCenters([...value]);
                        }}
                        getErrors={getFieldErrors}
                        placeholder="Выберите РЦ"
                        notFoundText="Отсутствуют РЦ"
                        style={{ width: "100%" }}
                    />
                </Col>
            </Row>
            {isEditing &&
            <Row gutter={[16, 24]}>
                <Checkbox
                    name="blocked"
                    checked={blocked}
                    onChange={(e) => {
                        setBlocked(e.target.checked);
                    }}
                    style={{ paddingTop: "15px" }}
                >
                    Заблокирован
                </Checkbox>
            </Row>}
        </Form>
      </Spin>
    </Modal>
  );
}
