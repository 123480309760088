import React, {useState} from 'react';
import {consignorService} from '../../../services';
import {Modal, Input, notification, Button, Spin} from 'antd';

export default function ModalRefuseOrder({isShow, setIsShow, setIsNeedUpdate, orderId}) {

  const {TextArea} = Input;
  const [loading, setLoading] = useState(false);
  const [refuseReason, setRefuseReason] = useState('');

  const handleRefuse = () => {
    setLoading(true);
    consignorService
      .refuseOrder(orderId, refuseReason)
      .then(resp => {
        if (resp.code !== 400) {
          notification.success(
            { message: "Отмена заявки",
              description: resp.message });
          setIsShow(false);
          setIsNeedUpdate(true);
        } else {
          notification.error(
            { message: "Отмена заявки",
              description: resp.message });
        }
      })
      .catch(errMsg => notification.error(
          { message: "Отмена заявки",
            description: errMsg })
      )
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={'Отмена заявки'}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
      }}
      footer={[
          <Button disabled={!refuseReason} onClick={handleRefuse} className="small-button typical-btn-red">Отклонить</Button>
      ]}
    >
        <Spin spinning={loading} tip="Загрузка...">
            <TextArea rows={4} onChange={e => setRefuseReason(e.target.value)} value={refuseReason} description="Причина отмены" />
        </Spin>
    </Modal>
  );
  
}
