import React, {useEffect, useState} from "react";
import {Button, Col, Icon, Modal, notification, Row, Table, Upload} from "antd";
import Spinner from "../../spinner";
import {consignorService, systemService} from "../../../services";
import SelectField from "../../select-field";
import {approveOrderService} from "../../../services/approve-order.service";
import FieldBlock from "../../field-block";
import DividerBlock from "../../divider-block";
import {formHelper} from "../../../helpers";
import {fileHelper, mimeTypes} from "../../../helpers/file.helper";

export default function ModalApproveLegalEntityByLogist({isShow, setIsShow, orderInfo, setNeedUpdate, setStopListUpdate}) {
    const [loading, setLoading] = useState(true);

    const [companyTypesList, setCompanyTypesList] = useState([]);
    const [companyType, setCompanyType] = useState(null);

    const [addDocs, setAddDocs] = useState([]);
    const [customAddDocs, setCustomAddDocs] = useState([]);

    const [fieldsErrors, setFieldsErrors] = useState([]);
    const getFieldErrors = (fieldName) => {
        return typeof fieldsErrors[fieldName] !== "undefined"
            ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
                return fieldErrors + "; " + error;
            })
            : null;
    };

    useEffect(() => {
        if (isShow && orderInfo) {
            systemService.getCompanyTypesList(orderInfo.orderType)
                .then(res => {
                    setCompanyTypesList(res);
                    setLoading(false);
                });
        }
    }, [isShow]);

    useEffect(() => {
        if (isShow && orderInfo.approveType) {
            approveOrderService.getAddParamsOnApproveLegalEntity(orderInfo.orderId, orderInfo.approveType)
                .then(res => {
                    let docs = [];
                    for (const [key, value] of Object.entries(res.params)) {
                        if (value.type === "doc") {
                            if (value.value) {
                                const contentType = fileHelper.getMimeTypeByExtension(value.extension);
                                const thumbURL = contentType === mimeTypes.pdf ? 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg' : null;
                                value.value = formHelper.createFile(value.value, contentType, value.name, value.alias, thumbURL, value.extension);
                            }
                            docs.push(value);
                        }
                    }
                    setAddDocs(docs);

                    let customDocs = [];
                    for (const [key, value] of Object.entries(res.customPrams)) {
                        const contentType = fileHelper.getMimeTypeByExtension(value.extension);
                        const thumbURL = contentType === mimeTypes.pdf ? 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg' : null;
                        customDocs.push(
                            formHelper.createFile(value.value, contentType, value.name, value.alias, thumbURL)
                        );
                    }
                    setCustomAddDocs(customDocs);
                })
                .catch(err => {
                    notification.error({
                        message: 'Доп. параметры',
                        description: err,
                    });
                });
        }
    }, [isShow, orderInfo]);

    const handleAgreeOrder = () => {
        setLoading(true);

        switch (orderInfo.orderType) {
            case "default":
                consignorService
                    .approveOrder(orderInfo.orderId, orderInfo.approveType, addDocs, customAddDocs, companyType)
                    .then((response) => {
                        if (response.code === 200) {
                            notification.success({
                                    message: 'Подтверждение заявки',
                                    description: response.message
                            });
                            setStopListUpdate(false);
                            setNeedUpdate(true);
                            setIsShow(false);
                        } else {
                            setFieldsErrors(response.errors);
                            notification.error({
                                message: 'Подтверждение заявки',
                                description: response.message
                            });
                        }
                    })
                    .catch(err => {
                        notification.error({
                            message: 'Подтверждение заявки',
                            description: err
                        });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            case "become_carrier":
                consignorService
                    .approveOrderBecomeCarrier(orderInfo.orderId, companyType)
                    .then((response) => {
                        if (response.code === 200) {
                            notification.success({
                                message: 'Подтверждение заявки',
                                description: response.message
                            });
                            setStopListUpdate(false);
                        } else {
                            notification.error({
                                message: 'Подтверждение заявки',
                                description: response.message
                            });
                        }
                        setIsShow(false);
                        setLoading(false);
                    })
                    .catch(err => {
                        notification.error({
                            message: 'Подтверждение заявки',
                            description: err
                        });

                        setLoading(false);
                    });
                break;
        }
    };

    const submitBtnStyle = {
        width: "200px",
        height: "43px",
        background: "#1CAA53",
        position: "static",
        borderRadius: "10px",
        color: "white",
        borderColor: "transparent",
    };

    const getUploaderClass = (index) => {
        return {
            multiple: false,
            showUploadList: true,
            onRemove: () => {
                let docs = addDocs;
                docs[index].value = null;

                setAddDocs([...docs]);
            },
            beforeUpload: (file) => {
                let docs = addDocs;
                docs[index].value = file;
                setAddDocs([...docs]);
                return false;
            },
            fileList: addDocs[index].value ? [addDocs[index].value] : null,
            listType: 'picture',
        };
    };

    const renderAddParamsForm = () => {
        if (!addDocs || addDocs.length === 0) {
            return null;
        }

        let docsFields = addDocs.map((file, index) => {
            return <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name={file.name} getErrors={getFieldErrors}>
                    <Upload
                        className="single-btn-upload"
                        name={file.name}
                        {...getUploaderClass(index)}
                    >
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> {file.alias}
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
        });

        return (
            <Row gutter={[16, 24]}>
                {docsFields}
            </Row>
        );
    };

    return (
        <Modal
            title={'Согласование юр.лица'}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
            }}
            footer={
                <Button
                    htmlType="submit"
                    key="submit"
                    style={submitBtnStyle}
                    onClick={() => handleAgreeOrder()}
                    type="primary"
                    className="button-footer-create"
                >
                    Согласовать
                </Button>
            }
        >
            <Spinner loading={loading} tip="Загрузка...">
                {orderInfo && orderInfo.approveType === 'logist_type' &&
                <Row>
                    <Col lg={8} sm={24}>
                        <SelectField
                            title="Тип компании"
                            name="type"
                            selectedValue={companyType}
                            values={companyTypesList}
                            onChange={(value) => {
                                setCompanyType(value);
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Укажите тип компании"
                            notFoundText={null}
                            showArrow={true}
                            style={{width: "100%"}}
                        />
                    </Col>
                </Row>}
                <DividerBlock label="Дополнительные документы" />
                {renderAddParamsForm()}
                <Row gutter={[16, 24]}>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="customDocs" getErrors={getFieldErrors}>
                            <Upload
                                className="single-btn-upload"
                                name="customDocs"
                                multiple={true}
                                showUploadList={true}
                                onRemove={(file) => {
                                    customAddDocs.forEach((stepFile, index) => {
                                        if (stepFile.uid === file.uid) {
                                            customAddDocs.splice(index, 1);
                                        }
                                        setCustomAddDocs([...customAddDocs]);
                                    });
                                }}
                                beforeUpload={(file) => {
                                        let docs = customAddDocs;
                                        docs.push(file);
                                        setCustomAddDocs([...docs]);
                                        return false;
                                    }
                                }
                                fileList={customAddDocs}
                                listType={'picture'}
                            >
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Спец. документы
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                </Row>
            </Spinner>
        </Modal>
    );
}
