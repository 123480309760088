import React, { useState, useEffect } from 'react';
import { notification, Button, Table } from 'antd';
import Spinner from '../../../../spinner';
import { companyService } from '../../../../../services';
import ModalUploadLegalEntityDocs from '../../../../modals/upload-legal-entity-docs';
import NotificationMessage from "../../../../modals/notification-message";
import { useFetchNotification } from "../../../../../hooks/use-fetch-notification";


export default function OrdersToShipperCompany() {

  const [needUpdate, setNeedUpdate] = useState(true);
  const [loading, setLoading] = useState(true);
  const [stopListUpdate, setStopListUpdate] = useState(false);

  const [data, setData] = useState(null);

  const [isShowUploads, setShowUploads] = useState(false);

  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [currentLegalEntityId, setCurrentLegalEntityId] = useState(null);
  const [currentLegalEntityOrgFormId, setCurrentLegalEntityOrgFormId] = useState(null);

  const [{ notifications }] = useFetchNotification(true);

  useEffect(() => {
    if (!stopListUpdate) {
      setLoading(true);

      companyService
        .getOrdersToShipperCompanyList()
        .then(resp => {
          setData(resp.ordersList);
          setLoading(false);

          setNeedUpdate(false);
          setStopListUpdate(true);
        })
        .catch(errMsg => notification.error(
          {
            message: "Получение списка заявок",
            description: errMsg
          }));
    }
  }, [needUpdate, stopListUpdate]);

  const handleDownloadBlank = filePath => {
    window.open(filePath);
  };

  const handleShowUpload = record => {
    setCurrentOrderId(record.Id);
    setCurrentLegalEntityId(record.LegalEntity.Id);
    setCurrentLegalEntityOrgFormId(record.LegalEntity.OrgFormId);
    setShowUploads(true);
  };

  const renderButtons = record => {
    if (record.Status.Id === 1) {
      return null;
    }

    if (record.Status.Id === 2) {
        return Object.keys(record.Contracts).map(value => {
            value = record.Contracts[value];
            return (
                <>
                    <Button style={{ marginLeft: "0px", width: "100%" }} onClick={() => handleDownloadBlank(value.FilledFile)} size="small" className="small-button typical-btn-green">
                        Скачать "{value.Alias}"
                    </Button>
                    <Button style={{ marginLeft: "0px", width: "100%" }} onClick={() => handleDownloadBlank(value.BlankFile)} size="small" className="small-button typical-btn-blue">
                        Скачать оригинал "{value.Alias}"
                    </Button>
                </>
            );
        });
    }

      if (record.Status.Id === 4 || record.Status.Id === 3) {
          return (
              <Button style={{ marginLeft: "0px", width: "100%" }} onClick={() => handleShowUpload(record)} size="small" className="small-button typical-btn-green">Прикрепить договоры</Button>
          );
      }

      return null;
  };

  const columns = [
    { title: "Юр. лицо", key: "legalEntity", render: (record) => record.LegalEntity.Name },
    { title: "Юр.лицо грузоотправителя", key: "shipperCompany", render: (record) => record.ShipperCompany.Name },
    { title: "Статус", key: "status", render: (record) => record.Status.Title },
    { title: "Создана", key: "dateCreated", render: (record) => record.DateCreated },
    { title: "", key: "actions", render: renderButtons }
  ];

  return (
    <Spinner loading={loading} tip="Загрузка...">
      <NotificationMessage notifications={notifications} setStopListUpdate={setStopListUpdate} loading={false} />

      <Table
        columns={columns}
        dataSource={data}
        locale={{ emptyText: 'Данные отсутствуют' }}
        rowKey="id"
        size="small"
        pagination={{
          pageSize: 50
        }}
        className="car-park"
        rowClassName={(record, index) => ("car-park-row")
        }
      />
      <ModalUploadLegalEntityDocs isShow={isShowUploads} setIsShow={setShowUploads} orderId={currentOrderId} setStopListUpdate={setStopListUpdate} orgFormId={currentLegalEntityOrgFormId} />
    </Spinner>
  );
}
