import React, {useState} from 'react';
import {Col, Row, Checkbox, Button} from 'antd';
import InputField from '../../../../input-field';

export default function FillInnStep({ stepFields, setFields, getFieldErrors, innInfoError, setInnInfoErr, nextStepFunc }) {
    const submitBtnStyle = {
        background: "rgba(195,5,5,0.74)",
        position: "static",
        borderRadius: "10px",
        color: "white",
        width: "100%",
        borderColor: "transparent",
    };


    const renderInnInfoErr = () => {
        if (innInfoError) {
            return (
                <Row gutter={[16, 24]}>
                    <Col offset={8} span={8}>
                        <Button
                            htmlType="submit"
                            key="submitPrev"
                            style={submitBtnStyle}
                            onClick={() => nextStepFunc()}
                            type="danger"
                        >
                            Продолжить с ИНН '{stepFields.inn}'
                        </Button>
                    </Col>

                </Row>
            );
        }
        return null;
    };

    return (
        <>
           <Row gutter={[16, 24]}>
              <Col offset={8} span={8}>
                  <InputField
                      name="inn"
                      label="ИНН"
                      value={stepFields.inn}
                      setValue={value => {
                          setFields({...stepFields, inn: value.replace(/[^0-9]/g, '')});
                          setInnInfoErr(null);
                      }}
                      getFieldErrors={getFieldErrors}
                  />
              </Col>
           </Row>
            {renderInnInfoErr()}
           <Row gutter={[16, 24]}>
            <Col offset={8} span={8}>
              <InputField
                name="atiCode"
                label="Код АТИ"
                value={stepFields.atiCode}
                isDisabled={stepFields.noAtiCode}
                setValue={value => setFields({...stepFields, atiCode: value})}
                getFieldErrors={getFieldErrors}
              />
              <Checkbox
                  name="notRegisteredATI"
                  checked={stepFields.noAtiCode}
                  onChange={(e) => {
                    setFields({
                      ...stepFields,
                      atiCode: e.target.checked ? 'Без АТИ' : '',
                      noAtiCode: e.target.checked,
                    });
                  }}
                  style={{fontSize: "10pt", paddingTop: "15px"}}
              >
                Не зарегистрирован на АТИ
              </Checkbox>
            </Col>
          </Row>
        </>
    );
}
