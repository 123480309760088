import React, {useContext} from 'react';
import {Redirect} from 'react-router-dom';
import {userServices} from "../../../services";
import {configMenuLinks} from '../../../config';
import {AppContext} from "../../../contexts/app-context";
import MainPage from "../main-page";

export default function HomePage() {
  const user = userServices.getUser();

  const userLinks = (user.info.role in configMenuLinks)
    ? configMenuLinks[user.info.role]
    : [];

  let homePage;

  if (user.loggedIn && !userLinks.length) {
    homePage = configMenuLinks.auth.find(
      (link) => {
        return link.homePage && user.info.permissions.find((item) => {
          if (Array.isArray(link.accessTo)) {
            return link.accessTo.find(accessItem => {
              return item.Name === accessItem;
            });
          } else {
            return item.Name === link.accessTo;
          }
        });
      }
    );
  } else {
    homePage = userLinks.find((link) => (link.homePage === true));
  }

  if (homePage && user.loggedIn) {
    return (<Redirect to={homePage.links[0]}/>);
  }

  if (!user.loggedIn) {
    return (<MainPage/>);
  }

  if (user.loggedIn && user.info.role === "driver") {
    return (<Redirect to={"/driver"}/>);
  }

  const appContext = useContext(AppContext);

  if (appContext.filter.enable) {
    appContext.disableFilter();
  }

  return (<div/>);
}
