import React, {useState, useEffect} from "react";
import {Button, Row, Col, Modal, notification} from "antd";
import NumberInputField from "../../number-input-field";
import Spinner from "../../spinner";
import {distributionCenterService} from "../../../services/distribution-center.services";
import {consignorService} from "../../../services/consignor.services";
import InputField from "../../input-field";
import SelectField from "../../select-field";
import '../modals.css';


export default function ModalCreateDistributionCenter({
  isShow,
  onCloseModal,
  id,
  name,
  delayDelivDispatcher:currentDelayDelivDispatcher = 60,
  delayReserveDeliv:currentDelayReserveDeliv = 60,
  maxCountLoadAuto:currentMaxCountLoadAuto = 10,
  startFillingOrder:currentStartFillingOrder = 20,
  fillingOrder:currentFillingOrder = 60,
  downloadDocument:currentDownloadDocument = 30,
  closingTime:currentClosingTime = '12:00',
  minOrdersToApprove:currentMinOrdersToApprove = 10,
  minHoursToRefuse: currentMinHoursToRefuse = 12
}) {

  const [shipperCompanyList, setShipperCompanyList] = useState(false);
  const [ shipperCompanyListLoaded, setShipperCompanyListLoaded ] = useState(false);

  const [loadingContentModal, setLoadingContentModal] = useState(false);
  const [fieldsErrors, setFieldsErrors] = useState(null);

  const [currentName, setName] = useState();
  const [delayDelivDispatcher, setDelayDelivDispatcher] = useState(currentDelayDelivDispatcher);
  const [delayReserveDeliv, setDelayReserveDeliv] = useState(currentDelayReserveDeliv);
  const [maxCountLoadAuto, setMaxCountLoadAuto] = useState(currentMaxCountLoadAuto);
  const [startFillingOrder, setStartFillingOrder] = useState(currentStartFillingOrder);
  const [fillingOrder, setFillingOrder] = useState(currentFillingOrder);
  const [downloadDocument, setDownloadDocument] = useState(currentDownloadDocument);
  const [closingTime, setClosingTime] = useState(currentClosingTime);
  const [minOrdersToApprove, setMinOrdersToApprove] = useState(currentMinOrdersToApprove);
  const [minHoursToRefuse, setMinHoursToRefuse] = useState(currentMinHoursToRefuse);
  const [ shipperCompanyId, setShipperCompanyId ] = useState(null);

  let bigButtonStyle = {width: '290px', height: '75px', fontSize:'24px', color: 'white', backgroundColor: '#1CAA53', border:'none'};

  useEffect(() => {
    if (!shipperCompanyListLoaded && isShow) {
      consignorService.getShipperCompaniesList()
        .then(res => {
          setShipperCompanyListLoaded(true);
          setShipperCompanyList(res);
        })
        .catch(errMsg => {
          setShipperCompanyListLoaded(true);
          notification.error(
          { message: "Получение списка юр. лиц",
            description: errMsg });
        })
      ;
    }
  }, []);

  // сохранение новых данных о РЦ
  const onClickEdit = (e) => {
    e.preventDefault();
    setLoadingContentModal(true);

    distributionCenterService.create(
      currentName,
      shipperCompanyId,
      delayDelivDispatcher,
      delayReserveDeliv,
      maxCountLoadAuto,
      startFillingOrder,
      fillingOrder,
      downloadDocument,
      closingTime,
      minOrdersToApprove,
      minHoursToRefuse
    )
      .then((response) => {

        setLoadingContentModal(false);

        if (response.code === 400) {

           setFieldsErrors(response.errors);

        } else {

          notification.success({message: "Редактирование РЦ", description: response.message});
          onCloseModal();

        }

      })
      .catch((error) => {
        notification.error({message: "Редактирование РЦ", description: error});
        setLoadingContentModal(false);
      });
  };

  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
      ? fieldsErrors[nameField][0]
      : null;
  };

  return (
    <Modal
      title="Создание РЦ"
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={onCloseModal}
      footer={[
        <Button htmlType="submit" key="submit" type="primary" style={bigButtonStyle} onClick={onClickEdit}>Сохранить</Button>,
      ]}
    >
      <Spinner loading={loadingContentModal} label={null}>
        <Row className="add-padding-10">
          <Col span={24} className="add-padding-10">
            <InputField
              name="currentName" label="Название"
              value={currentName} setValue={setName}
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col lg={24} md={24} sm={24} sx={24} className="add-padding-10">
            <SelectField
              title="Юридическое лицо"
              name="shipperCompanyId"
              values={shipperCompanyList}
              selectedValue={shipperCompanyId}
              onChange={value => {
                setShipperCompanyId(value);
              }}
              getErrors={getFieldErrors}
              style={{width: "100%"}}
            />
          </Col>
          <Col span={24} className="add-padding-10">
            <NumberInputField
              name="delayDelivDispatcher" label="Задержка отображения рейсов для диспетчера, в минутах"
              value={delayDelivDispatcher} setValue={setDelayDelivDispatcher}
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col span={24} className="add-padding-10">
            <NumberInputField
              name="delayReserveDeliv" label="Отсрочка резервирование рейса, в минутах"
              value={delayReserveDeliv} setValue={setDelayReserveDeliv}
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col span={24} className="add-padding-10">
            <NumberInputField
              name="maxCountLoadAuto" label="Максимальное количество погрузок авто"
              value={maxCountLoadAuto} setValue={setMaxCountLoadAuto}
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col span={24} className="add-padding-10">
            <NumberInputField
              name="startFillingOrder" label="Начало заполнения заявки, мин"
              value={startFillingOrder} setValue={setStartFillingOrder}
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col span={24} className="add-padding-10">
            <NumberInputField
              name="fillingOrder" label="Заполнение заявки, мин"
              value={fillingOrder} setValue={setFillingOrder}
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col span={24} className="add-padding-10">
            <NumberInputField
              name="downloadDocument" label="Загрузка документов, мин"
              value={downloadDocument} setValue={setDownloadDocument}
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col span={24} className="add-padding-10">
            <NumberInputField
              name="minOrdersToApprove" label="Минимальное кол-во заказов, для утверждения без специальных прав"
              value={minOrdersToApprove} setValue={setMinOrdersToApprove}
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col span={24} className="add-padding-10">
            <NumberInputField
              name="minHoursToRefuse" label="Минимальное кол-во часов, по прошестивю которых можно отменить заказ"
              value={minHoursToRefuse} setValue={setMinHoursToRefuse}
              getFieldErrors={getFieldErrors}
            />
          </Col>
          <Col span={24} className="add-padding-10">
            <InputField
              name="closingTime" label="Время закрытия(по МСК) Формат - 16:00"
              value={closingTime} setValue={setClosingTime}
              getFieldErrors={getFieldErrors}
            />
          </Col>
        </Row>
      </Spinner>
    </Modal>
  );
}
