import React, {useState,useEffect} from 'react';
import {Checkbox, notification, Row, Col, Button} from 'antd';
import {permissionService, userServices} from "../../../../services";
import {useFetchNotification} from "../../../../hooks/use-fetch-notification";
import NotificationMessage from "../../../modals/notification-message";
import Spinner from "../../../spinner";
import DividerBlock from "../../../divider-block";
import {notificationServices} from "../../../../services/notification.services";
import AttachTelegramModal from "../../../modals/attach-telegram";
import {PERFORMANCE_DISCIPLINE_CONTROL, REFUSE_DELIVERY} from "../../../../helpers/user_permission.helper";

function NotificationType(type, name, permissions = null) {
    this.type = type;
    this.name = name;
    this.permissions = permissions;

    this.isUserHaveAccess = (user) => {
        if (!this.permissions) {
            return true;
        }

        for (let permission of this.permissions) {
            if (user.isAccessTo(permission)) {
                return true
            }
        }

        return false;
    }
}

export default function UserNotificationSettings() {
    const user = userServices.getUser();

    const channelTypes = [
        {type: 'web', name: "Сайт"},
        {type: 'telegram', name: "Телеграм"},
        {type: 'email', name: "Email"},
        {type: 'push', name: "Приложение"},
    ];

    const notifyTypes = [
        new NotificationType('new_legal_entity_approve_order', 'Заявка на согласование юр. лиц'),
        new NotificationType('agree_legal_entity_approve_order', 'Юр. лицо согласовано'),
        new NotificationType('new_contract_order', 'Заявка на заключение договора'),
        new NotificationType('agree_contract_order', 'Договор согласован'),
        new NotificationType('new_order_delivery', 'Новая заявка по рейсу'),
        new NotificationType('new_order_delivery_counteroffer', 'Встречное предложение по рейсу'),
        new NotificationType('agree_order_delivery_counteroffer', 'Принятие встречного предложения'),
        new NotificationType('refuse_order_delivery_counteroffer', 'Отклонение встречного предложения'),
        new NotificationType('new_delivery_logist', 'Новые рейсы'),
        new NotificationType('overdue_approve_legal_entity', 'Просроченные согласования', [PERFORMANCE_DISCIPLINE_CONTROL]),
        new NotificationType('refuse_delivery_action_to_approve', 'Запросы на отмену рейса', [REFUSE_DELIVERY]),
        new NotificationType('Change_approved_delivery_date', 'Перенос даты погрузки')
    ];

    const [needUpdate, setNeedUpdate] = useState(true);
    const [loading, setLoading] = useState(true);
    const [stopListUpdate, setStopListUpdate] = useState(false);

    const [notificationsSettings, setNotificationSettings] = useState(null);
    const [distributionCentersNotificationSettings, setDistributionCentersNotificationSettings] = useState([]);

    const [{ notifications }] = useFetchNotification(true);

    const [isShowModalAttachTelegram, setIsShowModalAttachTelegram] = useState(false);

    useEffect(() => {
        if (!stopListUpdate) {
            setLoading(false);
            setNeedUpdate(false);
        }
    }, [needUpdate, stopListUpdate]);

    // Получить настройки уведомлений пользователя
    useEffect(() => {
    let user = userServices.getUser();

    userServices
        .getInfo(user.getAuthToken())
        .then((resp) => {
          setNotificationSettings(resp.notificationsSettings);
        })
        .catch((err) => {
          notification.error({
            message: "Получение информации о пользователе",
            description: err,
          });
        });
    }, []);

    // Получить список
    useEffect(() => {
        notificationServices
            .getDistributionCenterNotificationSettings()
            .then((resp) => {
                setDistributionCentersNotificationSettings(resp);
            })
            .catch((err) => {
                notification.error({
                    message: "Получение настроек уведомлений по РЦ",
                    description: err,
                });
            });
    }, []);

  const saveNotificationSetting = (status, type, notifyType) => {
      let notifySettings = notificationsSettings;

      notificationServices
          .switchNotification(status, type, notifyType)
          .then((resp) => {
              if (resp.code === 200) {
                  notification.success({
                      message: "Сохранение",
                      description: resp.message,
                  });

                  if (!notifySettings[type]) {
                      notifySettings[type] = [];
                  }
                  notifySettings[type][notifyType] = status;
                  setNotificationSettings({...notifySettings});
              } else {
                  notification.error({
                      message: "Сохранение",
                      description: resp.message,
                  });
              }
          })
          .catch((err) => {
              notification.error({
                  message: "Сохранение",
                  description: err,
              });
          });
  };

  const renderNotificationSettingsCheckboxes = () => {
      return notifyTypes
          .filter(notifyValue => {
              return notifyValue.isUserHaveAccess(user);
          })
          .map((notifyValue => {
              return channelTypes.map(channelValue => {
                  return (
                      <Col lg={6} sm={24}>
                          <Checkbox
                              name={channelValue.type +'_'+ notifyValue.type}
                              checked={isNotificationSettingChecked(channelValue.type, notifyValue.type)}
                              onChange={(e) => {
                                  let checked = e.target.checked;
                                  saveNotificationSetting(checked, channelValue.type, notifyValue.type);
                              }}
                              style={{fontSize: "10pt", paddingTop: "15px"}}
                          >
                              {notifyValue.name}
                          </Checkbox>
                      </Col>
                  );
              });
          }));
  };

  const renderNotificationSettingsHeader = () => {
              return (
                  <>
                      <Col lg={6} sm={24}>
                          <b>Сайт</b>
                      </Col>
                      <Col lg={6} sm={24}>
                          <b>Telegram</b>
                      </Col>
                      <Col lg={6} sm={24}>
                          <b>Email</b>
                      </Col>
                      <Col lg={6} sm={24}>
                          <b>Push</b>
                      </Col>
                  </>
              );
  };

  const isNotificationSettingChecked = (channelType, notifyType) => {
      if (notificationsSettings) {
          if (notificationsSettings[channelType]) {
              return notificationsSettings[channelType][notifyType];
          }
      }

      return false;
  };

  const renderDistCentersCheckboxes = () => {
      return distributionCentersNotificationSettings.map((val, index) => {
          return (
              <Col lg={6} sm={24}>
                  <Checkbox
                      name={val.distributionCenter.id}
                      checked={isDistCenterSettingChecked(val.distributionCenter.id)}
                      onChange={(e) => {
                          let checked = e.target.checked;
                          let settings = distributionCentersNotificationSettings;
                          settings[index].isActive = checked

                          saveDistCenterSettings(settings);
                      }}
                      style={{fontSize: "10pt", paddingTop: "15px"}}
                  >
                      {val.distributionCenter.name}
                  </Checkbox>
              </Col>
          );
      });
  }

  const isDistCenterSettingChecked = (distCenterID) => {
      if (distributionCentersNotificationSettings) {
          let val = distributionCentersNotificationSettings.find((value => {
              return value.distributionCenter.id === distCenterID;
          }));

          if (val) {
              return val.isActive;
          }
      }

      return false;
  }

  const saveDistCenterSettings = (settings) => {
      let settingsArr = settings.map((val) => {
          return {
              distributionCenterID: Number(val.distributionCenter.id),
              isActive: val.isActive
          };
      });

      notificationServices
          .saveDistCentersNotifications(settingsArr)
          .then((resp) => {
              notification.success({
                  message: "Сохранение",
                  description: resp.message,
              });

              setDistributionCentersNotificationSettings([...settings]);
          })
          .catch((err) => {
              notification.error({
                  message: "Сохранение",
                  description: err,
              });
          });
  }


  let bigButtonStyle = { height: '40px', background: '#1CAA53', position: 'static', borderRadius: '10px', borderColor: "#1CAA53", marginTop: "20px" };
  let telegramButtonStyle = { height: '40px', background: '#1CAA53', position: 'static', borderRadius: '10px', borderColor: "#1CAA53", marginBottom: "20px", float: 'right' };
  return (
      <>
        <NotificationMessage notifications={notifications} setStopListUpdate={setStopListUpdate} loading={false} />

        <Spinner loading={loading}>
            <Row style={{ marginBottom: "25" }}>
                <Col className={"content-header"} style={{ marginBottom: "0px" }} span={12}>Настройка уведомлений</Col>
                <Button
                    type="primary"
                    htmlType="button"
                    style={telegramButtonStyle}
                    onClick={() => {
                        setIsShowModalAttachTelegram(true);
                    }}
                >
                    Прикрепить телеграм
                </Button>
            </Row>
            <AttachTelegramModal setIsShow={setIsShowModalAttachTelegram} isShow={isShowModalAttachTelegram} />
            <DividerBlock label="Получение уведомлений" />
            <Row>
                {renderNotificationSettingsHeader()}
                {renderNotificationSettingsCheckboxes()}
            </Row>
            <DividerBlock label="Распределительные центры" />
            <Row>
                {renderDistCentersCheckboxes()}
            </Row>
        </Spinner>
      </>
  );
};


