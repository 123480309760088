import React, {useContext, useState} from "react";
import ModalNeedAuth from "../modals/need-auth";
import {AppContext} from "../../contexts/app-context";


export function GuestHeaderButton({delivery, setStopListUpdate}) {
  const appContext = useContext(AppContext);
  const [showModalCreateOrder, setShowModalCreateOrder] = useState(false);
  const user = appContext.user;

  const text = delivery.Logist.Phone ?
    `Для подачи заявки позвоните по телефону ${delivery.Logist.Phone} или зарегистрируйтесь на сайте` :
    `Для подачи заявки зарегистрируйтесь на сайте`;

  if (user.isGuest()) {
    return (
      <div>
        <a
          href="#"
          className="action-button"
          onClick={(e) => {
            e.preventDefault();
            setStopListUpdate(true);
            setShowModalCreateOrder(true);
          }}
        >
          <span className="speedometer">Подать заявку</span>
        </a>
        <ModalNeedAuth
          isShow={showModalCreateOrder}
          setIsShow={setShowModalCreateOrder}
          setStopListUpdate={setStopListUpdate}
          text={text}
          title={'Подача заявки'}
        />
      </div>
    );
  }

  return null;
}
