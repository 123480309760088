import React, {useEffect, useState} from 'react';
import {Button, Col, Table} from "antd";
import Spinner from "../../../spinner";
import './distributions-centers.css';
import {distributionCenterService} from "../../../../services/distribution-center.services";
import ModalEditDistributionCenter from "../../../modals/edit-distribution-center";
import ModalEditDistributionCenterOrderTimeSums from "../../../modals/edit-distribution-center-order-time-sums";

export default function DistributionCentersPage() {

    const [loading, setLoading] = useState(false);
    const [isNeedUpdateList, setIsNeedUpdateList] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [distributionsCentersList, setDistributionsCentersList] = useState([]);
    const [showModalEditDistCenter, setShowModalEditDistCenter] = useState(null);
    const [showModalEditOrderTimeSums, setShowModalEditOrderTimeSums] = useState(null);

    let bigButtonStyle = {color: 'white', backgroundColor: '#1CAA53', borderRadius: '7px', height: '35px', border: 'none'};

    useEffect(() => {
        if (loading === false){
            setLoading(true);
            setIsNeedUpdateList(true);
        }
    }, []);

    if (errorMsg) {
        return (<div className="content-error-block">{errorMsg}</div>);
    }

    if (isNeedUpdateList) {
        distributionCenterService.getCompanies()
            .then((response) => {

                const distributionsCentersData = response.map((item) => {
                    return {
                        id: item.Id,
                        name: item.Name,
                        delayDelivDispatcher: item.DelayDelivDispatcher,
                        delayReserveDeliv: item.DelayReserveDeliv,
                        maxCountLoadAuto: item.MaxCountLoadAuto,
                        startFillingOrder: item.StartFillingOrder,
                        fillingOrder: item.FillingOrder,
                        downloadDocument: item.DownloadDocument,
                        closingTime: item.ClosingTime,
                        minOrdersToApprove: item.MinOrdersToApprove,
                        minHoursToRefuse: item.MinHoursToRefuse
                    };
                });

                setIsNeedUpdateList(false);
                setLoading(false);
                setDistributionsCentersList(distributionsCentersData);

            }).catch((errorMsg) => {
            setIsNeedUpdateList(false);
            setLoading(false);
            setErrorMsg(errorMsg);
        });
    }

    const columns = [
        {
            title: 'Название',
            key: 'name',
            width: '25%',
            sorter: (a, b) => a.id - b.id,
            render: (data) => {
                return (
                    <React.Fragment>
                        <p className="column-title">{data.name}</p>
                        <Button type="primary" htmlType="button" onClick={() => setShowModalEditDistCenter(data.id)}
                                size="small" style={{margin: '0 5px 10px 0', ...bigButtonStyle}}>
                            Изменить
                        </Button>
                        <ModalEditDistributionCenter
                                isShow={(showModalEditDistCenter === data.id)}
                                onCloseModal={() => {
                                    setShowModalEditDistCenter(null);
                                    setLoading(true);
                                    setIsNeedUpdateList(true);
                                }}
                                {...data}
                            />
                        <Button type="primary" htmlType="button" onClick={() => {
                            setShowModalEditOrderTimeSums(data.id);

                        }}
                                size="small" style={bigButtonStyle}>
                            Повышение
                        </Button>
                        <ModalEditDistributionCenterOrderTimeSums
                            isShow={(showModalEditOrderTimeSums === data.id)}
                            distCenterId={data.id}
                            onCloseModal={() => {
                                setShowModalEditOrderTimeSums(null);
                                setLoading(true);
                                setIsNeedUpdateList(true);
                            }}
                            {...data}
                        />
                    </React.Fragment>
                );
            },
        }, 
        {
            title: 'Задержка отображения рейсов для диспетчера, в мин',
            dataIndex: 'delayDelivDispatcher',
            key: 'delayDelivDispatcher',
            width: '10%',
        },
        {
            title: 'Отсрочка резервирование рейса, в мин',
            dataIndex: 'delayReserveDeliv',
            key: 'delayReserveDeliv',
            width: '10%',
        },
        {
            title: 'Максимальное количество погрузок авто',
            dataIndex: 'maxCountLoadAuto',
            key: 'maxCountLoadAuto',
            width: '10%',
        },
        {
            title: 'Начало заполнения заявки, мин',
            dataIndex: 'startFillingOrder',
            key: 'startFillingOrder',
            width: '10%',
        },
        {
            title: 'Заполнение заявки, мин',
            dataIndex: 'fillingOrder',
            key: 'fillingOrder',
            width: '10%',
        },
        {
            title: 'Загрузка документов, мин',
            dataIndex: 'downloadDocument',
            key: 'downloadDocument',
            width: '10%',
        }
    ];

    return (
        <React.Fragment>
            <h1 className="content-header">Настройка РЦ</h1>
            <Col span={24}>
                <Spinner loading={loading} label={null}>
                    <Table
                        style={{minWidth: '700px'}}
                        columns={columns}
                        dataSource={distributionsCentersList}
                        locale={{emptyText:"РЦ отсутствуют"}}
                        rowKey="id"
                        size="small"
                    />
                </Spinner>
            </Col>
        </React.Fragment>
    );
}