import { httpRequest } from "./http-request";

export const Filters = {
  getFilters,
  getFiltersForGuest
};

/**
 * Получить список фильтров
 */
async function getFilters() {
  return await httpRequest.get(`delivery/get-filters`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

/**
 * Получить список фильтров
 */
async function getFiltersForGuest() {
  return await httpRequest.get(`delivery/get-filters-for-guest`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}