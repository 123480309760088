import {Button, Col, Form, Modal, notification, Row, Spin} from "antd";
import InputField from "../../input-field";
import React, {useState} from "react";
import {companyService, consignorService} from "../../../services";

export default function AddParamOnContractCreate({ shipperCompanyID, isShow, setIsShow, setNeedUpdate }) {
    const [loading, setLoading] = useState(false);
    const [fieldsErrors, setFieldsErrors] = useState({});

    const [paramName, setParamName] = useState('');

    const getFieldErrors = fieldName => {
        return typeof fieldsErrors[fieldName] !== "undefined"
            ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
                return fieldErrors + "; " + error;
            })
            : null;
    };

    const onSubmit = e => {
        e.preventDefault();

        setLoading(true);

        consignorService
            .createShipperCompanyAddParam(shipperCompanyID, paramName)
            .then(responseData => {
                if (responseData.status === "success") {
                    notification.success({
                        message: "Добавление параметра",
                        description: responseData.message
                    });
                    setLoading(false);
                    setIsShow(false);
                    setNeedUpdate(true);
                } else if (responseData.status === "error") {
                    if (responseData.message) {
                        notification.error({
                            message: "Добавление параметра",
                            description: responseData.message
                        });
                    }
                    setLoading(false);
                    if (responseData.errors) {
                        setFieldsErrors(responseData.errors);
                    }
                }
            })
            .catch(errorMsg => {
                notification.error({
                    message: "Создание сотрудника",
                    description: errorMsg
                });
                setLoading(false);
            });
    };

    let bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent" };

    return (
        <Modal
            title={`Добавление параметра`}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
                setNeedUpdate(true);
            }}
            footer={[
                <Button htmlType="submit" key="submit" style={bigButtonStyle} type="primary" onClick={onSubmit}>
                    Создать
                </Button>
            ]}
        >
            <Spin spinning={loading} tip="Загрузка...">
                <Form>
                    <Row gutter={[16, 24]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <InputField name="firstName" label="Название параметра" value={paramName} setValue={setParamName} getFieldErrors={getFieldErrors} />
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}