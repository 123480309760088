import React, {useState} from 'react';
import {consignorService} from '../../../services';
import {Modal, Input, notification, Button} from 'antd';

export default function ModalRefuseOrderToConsignor({isShow, setIsShow, setNeedUpdate, orderId, setStopListUpdate}) {

  const {TextArea} = Input;

  const [refuseReason, setRefuseReason] = useState('');

  const handleRefuse = () => {
    consignorService
      .refuseOrderToConsignor(orderId, refuseReason)
      .then(resp => {
        if (resp.code !== 400) {
          notification.success(
            { message: "Отмена заявки",
              description: resp.message });

          setIsShow(false);
          setNeedUpdate(true);
          setStopListUpdate(false);
        } else {
          notification.error(
            { message: "Отмена заявки",
              description: resp.message });
        }
      })
      .catch(errMsg => notification.error(
          { message: "Отмена заявки",
            description: errMsg }));
  };

  return (
    <Modal
      title={'Отмена заявки'}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
      }}
      footer={[
          <Button disabled={!refuseReason} onClick={handleRefuse} className="small-button typical-btn-red">Отклонить</Button>
      ]}
    >
      <TextArea rows={20} onChange={e => setRefuseReason(e.target.value)} value={refuseReason} description="Причина отмены" />
    </Modal>
  );

}
