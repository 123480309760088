import React, {useState} from 'react';
import {Button, Col, Modal, notification, Row, Spin} from "antd";
import '../modals.css';
import {Redirect} from "react-router-dom";

export default function ModalNeedAuth({isShow, setIsShow, text, title = 'Уведомление', setStopListUpdate}) {

  const [loading, setLoading] = useState(false);
  const [redirectToAuth, setRedirectToAuth] = useState(false);

  const bigButtonStyle = {
    width: '200px',
    height: '43px',
    background: '#1CAA53',
    position: 'static',
    borderRadius: '10px'
  };

  const textStyle = {
    textAlign: 'center',
    color: 'black'
  };

  const redirect = () => {
    setRedirectToAuth(true);
  };

  if (redirectToAuth) {
    return (<Redirect to="/register" push={true}/>)
  }

  return (
    <Modal
      title={<span className="ant-modal-title">{title}</span>}
      cancelText="Закрыть"
      className="modal-window"
      style={{maxWidth: '450px'}}
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
        setStopListUpdate(false);
      }}
      footer={[
        <Button htmlType="submit" style={bigButtonStyle} key="submit" type="primary" onClick={() => {redirect()}}>Зарегистрироваться</Button>,
      ]}
    >
      <Spin spinning={loading} tip="Загрузка...">
        <Row className="add-padding-10 -centered">
          <h2 style={textStyle}>{text}</h2>
        </Row>
      </Spin>
    </Modal>
  );
}
