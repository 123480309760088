import React from 'react';

import './google-android-app-link.css';

export default function GoogleAndroidAppLink() {
    return (
        <div className="b-android-app-link">
            <div className="android-app-link__title">
                <p>Мы запустили новое мобильное приложение для собственников автотранспорта!</p>
                <p className="android-app-link__text"><span>"Светофор Логистика"</span> теперь и в вашем мобильном!</p>
            </div>
            <div className="android-app-link__image">
                <img src="/img/app-link-image.png" alt="app-link"/>
            </div>
            <div className="android-app-link__link">
                <div className="android-app-link__link-text">
                    Просто скачайте на свой смартфон:
                </div>
                <a href='https://play.google.com/store/apps/details?id=com.uvezem&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noopener noreferrer">
                    <img alt='Доступно в Google Play' src='/img/googleplaystore.png' width={200}/>
                </a>
            </div>
        </div>
    );
}
