import React, {useEffect, useState} from "react";
import {Badge, Button, Card} from "antd";
import './style.less';
import {userServices} from "../../../../../services";
import ModalEditCompanyByConsignor from "../../../../modals/edit-company-by-consignor";
import ModalCompanySettingsByConsignor from "../../../../modals/company-settings-by-consignor";
import {CONSIGNOR_DIRECTOR_PERMISSION, EDIT_COMPANY_INFO} from "../../../../../helpers/user_permission.helper";
import ModalEmployeeListForConsignor from "../../../../modals/employee-list-for-consignor";
import ModalRefuseOrderToConsignor from "../../../../modals/refuse-order-to-consignor";
import ModalLegalEntityFullInfo from "../../../../modals/legal-entity-full-info";
import ModalApproveLegalEntity from "../../../../modals/approve-legal-entity";
import ModalShowRefuseReasonForConsignor from "../../../../modals/show-refuse-reason-for-consignor";
import ContractsListItem from "../contracts-list";
import {FullDate} from "../../../../tools/delivery-tools";
import ModalApproveOrderHistoryList from "../../../../modals/approve-order-history-list";
import ModalFullRefuseApproveOrder from "../../../../modals/full-refuse-approve-order";
import ModalChangeCompanyType from "../../../../modals/change-company-type-by-order-id";
import ModalEditCompany from "../../../../modals/edit-legal-entity-via-approve";
import ModalChangeUserLogin from "../../../../modals/change-user-login";


const CarrierCompanyCard = ({company, consignorSettings, setIsNeedUpdate, setStopListUpdate}) => {
  const user = userServices.getUser();
  const [status, setStatus] = useState(company.approveOrder.status.id);
  const [contractsCount, setContactsCount] = useState(0);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEmployeeList, setShowEmployeeList] = useState(false);
  const [showRefuseModal, setShowRefuseModal] = useState(null);
  const [showLegalEntityFullInfoModal, setShowLegalEntityFullInfoModal] = useState(false);
  const [showLogistApproveModal, setShowLogistApproveModal] = useState(false);
  const [orderInfo, setOrderInfo] = useState({orderType: '', orderId: null, approveType: 'default'});
  const [showRefuseReasonModal, setShowRefuseReasonModal] = useState(false);
  const [showContractsList, setShowContractsList] = useState(false);
  const [showApproveOrderHistory, setShowApproveOrderHistory] = useState(false);
  const [showFullRefuseApproveOrder, setShowFullRefuseApproveOrder] = useState(false);
  const [isModalChangeCompanyTypeShow, setIsModalChangeCompanyTypeShow] = useState(false);
  const [isModalEditCompanyShow, setIsModalEditCompanyShow] = useState(false);
  const [showUserChangeLoginModal, setShowUserChangeLoginModal] = useState(null);

  useEffect(() => {
    let count = 0;
    if (company.contracts && company.contracts.length) {
      company.contracts.map((item) => {
        if (item.status.id === 1) {
          count = count + 1;
        }
      })
    }
    setContactsCount(count);
  }, [company.contracts])

  useEffect(() => {
    setStatus(company.approveOrder.status.id);
  }, [company.legalEntityID])


  const handleShowSettingsModal = () => {
    setShowSettingsModal(true);
  };

  const handleShowEditModal = () => {
    setShowEditModal(true);
  };

  const handleShowEmployeeListModal = () => {
    setShowEmployeeList(true);
  };

  const handleApproveModal = (type, id) => {
    setOrderInfo({orderType: 'default', orderId: id, approveType: type});
    setShowLogistApproveModal(true);
  };

  const handleShowContactsList = () => {
    setShowContractsList(!showContractsList);
    document.activeElement.blur();
  }

  return <>
    <Card
      style={{marginBottom: 20}}
      className="card"
    >
      <div className="card__wrapper">
        {company.contracts.length > 0 &&
          <Button className={`card__link ${showContractsList ? 'card__link--show' : ''}`}
                 onClick={handleShowContactsList}
        >
          <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2.025L1.775 0.25L10 8.475L18.225 0.25L20 2.025L10 12.025L0 2.025Z" fill="currentcolor"
                  fillOpacity="0.8"/>
          </svg>
          <span>{showContractsList ? 'Скрыть' : 'Подробнее'}
            {contractsCount > 0 && <b className="card__link-badge">{contractsCount}</b>}
          </span>
          <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2.025L1.775 0.25L10 8.475L18.225 0.25L20 2.025L10 12.025L0 2.025Z" fill="currentcolor"
                  fillOpacity="0.8"/>
          </svg>
        </Button>}
        <div className="card__block">
          <div className="card__block-item">
            <span className="card__text">WebID </span>
            <span className="card__text card__text--accent">#</span>
            <span>{company.approveOrder.id}</span>
          </div>
          <div className="card__block-item">
            <span className="card__text">ID юр.лица </span>
            <span className="card__text card__text--accent">#</span>
            <span>{company.legalEntityID}</span>
          </div>
          <div className="card__block-item">
            <span className="card__param">Название: </span>
            <span className="card__text">{company.legalEntityName}</span>
          </div>
          <div className="card__block-item">
            <span className="card__param">Орг. форма: </span>
            <span className="card__text">{company.orgForm.name}</span>
          </div>
          <div className="card__block-item">
            <span className="card__param">Тип: </span>
            <span className="card__text">{company.company.typeName}</span>
          </div>
          <div className="card__block-item">
            <span className="card__param">Страна: </span>
            <span className="card__text">{company.country.name}</span>
          </div>
          <div className="card__block-item">
            <span className="card__param">ИНН: </span>
            <span className="card__text">{company.legalNumber}</span>
          </div>
          <Button htmlType="button" onClick={() => setShowLegalEntityFullInfoModal(true)} type="primary"
                  className="card__button typical-btn typical-btn-blue">Данные юр.лица
          </Button>
          <Button type="primary" htmlType="button"
                  className="card__button typical-btn typical-btn-blue"
                  style={{marginTop: "4px"}}
                  onClick={() => {
                    setIsModalChangeCompanyTypeShow(true);
                  }}>Изменить тип юр.лица
          </Button>
          {company.approveOrder.status.id === 2  && <Button type="primary" htmlType="button"
                  className="card__button typical-btn typical-btn-blue"
                  style={{marginTop: "4px"}}
                  onClick={() => {
                    setIsModalEditCompanyShow(true);
                  }}>Редактировать юр.лицо
          </Button>}
        </div>
        <div className="card__block">
          <div className="card__block-item card__block-item--column">
            <span className="card__param">Ответственный:</span>
            <span className="card__text card__text--centered">{company.mainUser.name}</span>
            <span className="card__text">{company.mainUser.login}</span>
            {company.mainUser.UserId &&
              <>
              <Button style={{marginLeft: "0px", width: "100%"}}
                      size="small"
                      className="small-button typical-btn-blue"
                      onClick={() => {
                        setShowUserChangeLoginModal(company.mainUser.UserId);
                      }}>
                Изменить логин
              </Button>
              <ModalChangeUserLogin
                id={company.mainUser.UserId}
                isShow={showUserChangeLoginModal === company.mainUser.UserId}
                setIsShow={() => {
                  setShowUserChangeLoginModal(null)
                }}
                setNeedUpdateList={setIsNeedUpdate}
              />
            </>}
          </div>
        </div>
        <div className="card__block">
          <Button className={`card__status card__status--${status}`}
                  onClick={() => setShowApproveOrderHistory(true)}
          >
            <span>{company.approveOrder.status.title}</span>
          </Button>
          {company.approveOrder.status.id === 2  &&
            <Button className={`card__status`}
                    onClick={() => setShowFullRefuseApproveOrder(true)}
            >
              <span>Отменить согласования</span>
            </Button>
          }
          <div className="card__block-item">
            <span className="card__param card__param--xs">Дата подачи: </span>
            <span className="card__text card__text--xs"><FullDate date={company.approveOrder.dateLastSubmissionApprove ? company.approveOrder.dateLastSubmissionApprove : company.approveOrder.date_created}/></span>
          </div>
          <div className="card__block-item">
            <span className="card__param card__param--xs">РЦ: </span>
            <span className="card__text card__text--xs">{company.approveOrder.distributionCenter.name}</span>
          </div>
          <div className="card__buttons">
            {status === 1 && company.approveOrder.approves && company.approveOrder.approves.length &&
              <>
                {
                  company.approveOrder.approves.map((item) => {
                    const isHaveAccess = user.info.permissions.some((el) => el.Name === item.permissionName);
                    if (!item.isApproved && isHaveAccess) {
                      return <div key={`buttons-${item.id}`}>
                        <Button htmlType="button" onClick={()=>handleApproveModal(item.type, company.approveOrder.id)} type="primary"
                                className="card__button typical-btn typical-btn-green">{`Согласовать ${item.name}`}</Button>
                        <Button htmlType="button" onClick={() => setShowRefuseModal(true)} type="primary"
                                className="card__button typical-btn typical-btn-red">Отклонить</Button>
                      </div>
                    }
                    // if (item.isApproved && isHaveAccess) {
                    //   return <Button htmlType="button" type="primary" key={`refuse-button-${item.id}`}
                    //                  className="card__button typical-btn typical-btn-blue">Отмена согласования</Button>
                    // }
                  })
                }
              </>
            }
            {status === 3 &&
              <Button htmlType="button" onClick={()=> setShowRefuseReasonModal(true)} type="primary"
                      className="card__button typical-btn typical-btn-blue">Причина
                отмены</Button>
            }
            {status === 2 && <>
              {user.isAccessTo(EDIT_COMPANY_INFO) ?
                <Button htmlType="button" onClick={handleShowEditModal} type="primary"
                        className="card__button typical-btn typical-btn-blue">Редактировать ответственного
                </Button> : null}
              {user.isAccessTo(CONSIGNOR_DIRECTOR_PERMISSION) ?
                <Button htmlType="button" onClick={handleShowSettingsModal} type="primary"
                        className="card__button typical-btn typical-btn-blue">Настройки</Button> : null}
              <Button htmlType="button" onClick={handleShowEmployeeListModal} type="primary"
                      className="card__button typical-btn typical-btn-blue">Сотрудники</Button>
            </>}
          </div>
        </div>
        {showContractsList && company.contracts.length > 0 &&
          <div className="card__table">
            {
              company.contracts.map((item) => <ContractsListItem contract={item} legalEntityID={company.legalEntityID} consignorSettings={consignorSettings} setNeedUpdate={setIsNeedUpdate} key={`contract-${item.id}`}/>)
            }
          </div>
          }
      </div>
    </Card>
    <ModalFullRefuseApproveOrder id={company.approveOrder.id} isShow={showFullRefuseApproveOrder}
                                  setIsShow={setShowFullRefuseApproveOrder} setNeedUpdateList={setIsNeedUpdate}/>
    <ModalApproveOrderHistoryList id={company.approveOrder.id} isShow={showApproveOrderHistory}
                                  setIsShow={setShowApproveOrderHistory}/>
    <ModalEditCompanyByConsignor isShow={showEditModal} setIsShow={setShowEditModal} companyID={company.legalEntityID}
                                 setNeedUpdateList={setIsNeedUpdate}/>
    <ModalCompanySettingsByConsignor isShow={showSettingsModal} setIsShow={setShowSettingsModal}
                                     companyID={company.company.id} companyType={company.company.type} id={company.approveOrder.id}/>
    <ModalChangeCompanyType isShow={isModalChangeCompanyTypeShow} setIsShow={setIsModalChangeCompanyTypeShow}
                                     orderId={company.approveOrder.id} setIsNeedUpdate={setIsNeedUpdate}/>
    <ModalEditCompany isShow={isModalEditCompanyShow} setIsShow={setIsModalEditCompanyShow}
                      legalEntityID={company.legalEntityID} countryId={company.country.id} setIsNeedUpdate={setIsNeedUpdate}/>
    <ModalEmployeeListForConsignor isShow={showEmployeeList} setIsShow={setShowEmployeeList}
                                   companyID={company.company.id}/>
    <ModalRefuseOrderToConsignor isShow={showRefuseModal} setIsShow={setShowRefuseModal} setNeedUpdate={setIsNeedUpdate}
                                 orderId={company.approveOrder.id} setStopListUpdate={setStopListUpdate}/>
    <ModalLegalEntityFullInfo isShow={showLegalEntityFullInfoModal} setIsShow={setShowLegalEntityFullInfoModal}
                              legalEntityId={company.legalEntityID}/>
    <ModalApproveLegalEntity isShow={showLogistApproveModal} setIsShow={setShowLogistApproveModal}
                             orderInfo={orderInfo} setNeedUpdate={setIsNeedUpdate} setStopListUpdate={setStopListUpdate}/>
    <ModalShowRefuseReasonForConsignor isShow={showRefuseReasonModal} setIsShow={setShowRefuseReasonModal} id={company.approveOrder.id}/>
  </>
}

export default CarrierCompanyCard
