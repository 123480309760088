import React, {useState} from "react";
import { Form, Input } from "antd";
import MaskedInput from 'antd-mask-input'

export default function InputField({
  name,
  label,
  title = '',
  value,
  setValue,
  getFieldErrors,
  keyNum,
  type = "text",
  getFieldDisabled,
  isDisabled = false,
  getDefaultData,
  onInputChange,
  style = {},
  mask = ""
}) {
  const fieldErrors = (typeof keyNum !== "undefined") ? getFieldErrors(name, keyNum) :  getFieldErrors(name);

  const Component = mask.length ? MaskedInput : Input;

  // style.borderRadius = "7px";

  let disabled = isDisabled;
  if (getFieldDisabled) {
      disabled = getFieldDisabled(name);
  }

  if (getDefaultData) {
    setValue(getDefaultData(name));
  }

  const onChange = e => {
    setValue(e.target.value);
    if (onInputChange) onInputChange(e);
  };

  if (fieldErrors) {
    return (
      <React.Fragment>
        <span title={title} className="form-field-label">{label}</span>
        <Form.Item validateStatus="error" help={fieldErrors}>
          <Component type={type} defaultValue={value} value={value} mask={mask} name={name} disabled={disabled} onChange={onChange} style={style} size="large" />
        </Form.Item>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <span title={title} className="form-field-label">{label}</span>
        <Component type={type} defaultValue={value} value={value} mask={mask} name={name} disabled={disabled} onChange={onChange} style={style} size="large" />
      </React.Fragment>
    );
  }
}
