import React, {useContext, useState, useMemo, useEffect, useCallback, useRef} from "react";

import TopMenu from "../top-menu";
import TopFilter from "../top-filter";
import {configMenuLinks} from "../../config";
import {AppContext} from "../../contexts/app-context";
import {WAREHOUSE_WORKER} from "../../helpers/user_role.helper";
import AdsBanner from "../ads-banner";


function Header({user, setNeedUpdateUser}) {
  const [isSticky, setIsSticky] = useState(false);
  const [position, setPosition] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isMobile, setMobile] = useState(false);
  const [isDownScroll, setIsDownScroll] = useState(false);
  const [haveScroll, setHaveScroll] = useState(false);

  const headerRef = useRef();

  useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    setMobile(mobile);
  });

  useEffect(() => {
    if (isMobile) {
      if (isDownScroll) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    }
  }, [isDownScroll, isMobile]);

  useEffect(() => {
    if (haveScroll) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  }, [haveScroll]);

  const handleScroll = useCallback(() => {
    let moving = window.scrollY;

    const offset = moving - position

    if (moving - headerRef.current.clientHeight > 0) {
      setHaveScroll(true);
    } else {
      setHaveScroll(false);
    }

    if (offset > 30) {
      setIsDownScroll(true);
    } else if (offset < -30) {
      setIsDownScroll(false);
    }

    setPosition(moving);
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return (() => {
      window.removeEventListener("scroll", handleScroll);
    })
  })

  const appContext = useContext(AppContext);

  const topFilter = appContext.filter.enable ? <TopFilter/> : null;

  const userLinks = (user && user.isGuest()) ?
    configMenuLinks["guest"] :
    (user && user.info.role === WAREHOUSE_WORKER) ? configMenuLinks[WAREHOUSE_WORKER] : configMenuLinks["auth"];

  const topMenuUserLinks = userLinks.filter((linkData) => {
    if (Array.isArray(linkData.accessTo)) {
      let isUserHaveAccess = false;
      linkData.accessTo.forEach(element => {
        if (user && user.isAccessTo(element)) {
          isUserHaveAccess = true;
        }
      });
      return isUserHaveAccess;
    } else {
      if (user) {
        return user.isAccessTo(linkData.accessTo);
      }
    }
  });

  const getClass = (a, b, c) => {
    return a ? `header--sticky ${b ? "visible" : "hidden"} ${c ? "mobile" : ""}` : ""
  }

  const headerClass = useMemo(() => getClass(isSticky, visible, isMobile), [isSticky, visible, isMobile]);

  return (
    <>
      <header className={`header ${headerClass}`} ref={headerRef}>
        <TopMenu items={topMenuUserLinks} user={user} setNeedUpdateUser={setNeedUpdateUser}/>
        {/* {user.info.role === "guest" ? <GoogleAndroidAppLink /> : null} */}
      </header>

      <div className="container">
        {/*<AdsBanner type={"3"}/>*/}
      </div>
      {topFilter}
    </>
  );
}

export default React.memo(Header);
