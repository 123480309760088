import React from "react";
import {Button, Icon, Input} from "antd";
import Highlighter from "react-highlight-words";

export const tableHelper = {
    getColumnSearchProps
};


function getColumnSearchProps (dataIndex, searchText, setSearchText) {
    return ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{ padding: 8 }}>
              <div>
                <Input
                  placeholder={`Введите значение`}
                  value={selectedKeys[0]}
                  onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => handleSearch(selectedKeys, confirm, setSearchText)}
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
              </div>
                <Button
                    htmlType="button"
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, setSearchText)}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    <Icon type="search" style={{fontSize: "8pt"}}/> Поиск
                </Button>
                <Button
                    htmlType="button"
                    onClick={() => handleReset(clearFilters, setSearchText)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Сброс
                </Button>
            </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        render: (text) => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });
}

const handleSearch = (selectedKeys, confirm, setSearchText) => {
    confirm();
    setSearchText(selectedKeys[0]);
};

const handleReset = (clearFilters, setSearchText) => {
    clearFilters();
    setSearchText('');
};
