import React, {useEffect, useState} from "react";
import moment from "moment";
import {consignorService, distributionCenterService, systemService} from "../../../../services";
import {Button, Checkbox, Col, DatePicker, Icon, notification, Row, Table} from "antd";
import {reportService} from "../../../../services/report.services";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import SelectField from "../../../select-field";
import Spinner from "../../../spinner";
import InputField from "../../../input-field";

export default function ReportStatsOrdersDeliveryOverpayment() {
  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [fieldsErrors, setFieldsErrors] = useState([]);
  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
      ? fieldsErrors[nameField][0]
      : null;
  };

  // параметры для построения отчета
  const [distributionCenterList, setDistributionCenterList] = useState([]);
  const [distributionCenterListLoaded, setDistributionCenterListLoaded] = useState(false);
  const [dateStart, setDateStart] = useState(moment().startOf('month'));
  const [dateEnd, setDateEnd] = useState(moment().endOf('month'));
  const [data, setData] = useState([]);
  const [distributionCenterId, setDistributionCenterId] = useState([]);
  const [overpaymentFrom, setOverpaymentFrom] = useState(null)
  const [overpaymentTo, setOverpaymentTo] = useState(null)

  const [countryId, setCountryId] = useState([]);
  const [countriesList, setCountriesList] = useState([]);

  const [approvingEmployeeId, setApprovingEmployeeId] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalOverprice, setTotalOverprice] = useState(0);

  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateReport(true);
    }
  }, []);

  useEffect(() => {
    if (!distributionCenterListLoaded) {
      distributionCenterService.getCompanies()
        .then(res => {
          setDistributionCenterListLoaded(true);
          setDistributionCenterList(res);
        })
        .catch(errMsg => {
          setDistributionCenterListLoaded(true);
          notification.error(
            {
              message: "Получение списка распределительных центров",
              description: errMsg
            });
        });
    }
  }, []);

  useEffect(() => {
    systemService.getCountryList()
      .then((res) => {
        setCountriesList(res);
      })
      .catch(errMsg => {
        setDistributionCenterListLoaded(true);
        notification.error({
          message: "Получение списка стран",
          description: errMsg
        });
      });
  }, []);

  useEffect(() => {
    consignorService.getStaffUsersList()
      .then((res) => {
        const list = res.users.map(userData => {
          return {
            id: userData.id,
            name: userData.fio,
          }
        });
        setEmployeesList(list);
      })
      .catch(errMsg => {
        setDistributionCenterListLoaded(true);
        notification.error({
          message: "Получение списка стран",
          description: errMsg
        });
      });
  }, []);

  useEffect(() => {
    if (isNeedUpdateReport) {
      setData([]);
      reportService.getStatsOrdersDeliveryOverpayment(
        dateStart.format('YYYY-MM-DD'), dateEnd.format('YYYY-MM-DD'),
        distributionCenterId,
        countryId,
        approvingEmployeeId,
        overpaymentFrom,
        overpaymentTo
      )
        .then((response) => {
          let tblData = [];

          let tPrice = 0;
          let tOverprice = 0;
          for (let item of response) {
            tblData.push({
              legalEntityId: item.id,
              column1: item.name,
              column2: item.totalDeliverySitePrice,
              column3: item.totalSiteOverprice,
              column4: item.totalDeliveryPlanPrice,
              column5: item.totalPlanOverprice,
              children: item.ordersDelivery.sort((a, b) => {
                return b.column3 - a.column3;
              })
            });

            tPrice += item.totalDeliverySitePrice;
            tOverprice += item.totalSiteOverprice;
          }

          setTotalPrice(tPrice);
          setTotalOverprice(tOverprice);

          setData(tblData.sort((a, b) => {
            return b.column3 - a.column3;
          }));
          setLoading(false);
          setIsNeedUpdateReport(false);
        }).catch((errorMsg) => {
        setIsNeedUpdateReport(false);
        setLoading(false);
        setErrorMsg(errorMsg);
      });
    }
  }, [isNeedUpdateReport]);

  // список столбцов таблицы
  const columns = [
    {
      title: 'Название юр.лица',
      dataIndex: 'column1',
      key: 'legalEntityId',
      width: '7%',
    },
    {
      title: 'Ставка на рейс сайт',
      dataIndex: 'column2',
      width: '7%',
    },
    {
      title: 'Сумма переплат сайт',
      dataIndex: 'column3',
      width: '7%',
    },
    {
      title: 'Ставка на рейс план',
      dataIndex: 'column4',
      width: '7%',
    },
    {
      title: 'Сумма переплат план',
      dataIndex: 'column5',
      width: '7%',
    },
    {
      title: 'Откуда',
      dataIndex: 'distributionCenterName',
      width: '7%',
    },
    {
      title: 'Куда',
      dataIndex: 'destinationPlace',
      width: '7%',
    },
    {
      title: 'Время согласования',
      dataIndex: 'timeBetweenOrderAndDelivery',
      width: '7%',
    },
    {
      title: 'Время согласования от создания рейса',
      dataIndex: 'intervalDeliveryCreateToApproveOrder',
      width: '7%',
    },
  ];

  return (
    <React.Fragment>
      <div className="reports__header">
        <h1 className="content-header">Отчет «Статистика по переплатам»</h1>
        <Icon type='filter'
              theme={isFilterOpen ? '' : "filled"}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen)
              }}
              className="reports__filter-toggle"/>
        {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
          <Row gutter={12} style={{padding: "0", rowGap: "10px", marginBottom: "12px"}} type="flex" justify="start">
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateStart}
                format="DD.MM.YYYY"
                size="normal"
                name="dateStart"
                placeholder="Выберите дату начала периода"
                locale={localeDatePicker}
                onChange={(date) => {
                  setDateStart(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateEnd}
                format="DD.MM.YYYY"
                size="normal"
                name="dateEnd"
                placeholder="Выберите дату окончания периода"
                locale={localeDatePicker}
                onChange={(date) => {
                  setDateEnd(date);
                }}
              />
            </Col>
          </Row>
          <Row gutter={12} style={{padding: "0", rowGap: "10px", marginBottom: "12px"}} type="flex" justify="start">
            <Col lg={8} md={24} sm={24} xs={24} style={{paddingRight: "10px"}}>
              <SelectField
                placeholder="Страна"
                name="countryId"
                mode="multiple"
                selectedValue={countryId}
                values={countriesList}
                onChange={value => {
                  setCountryId(value);
                }}
                getErrors={getFieldErrors}
                style={{width: "100%"}}
              />
            </Col>
            <Col lg={8} md={24} sm={24} xs={24} style={{paddingRight: "10px"}}>
              <SelectField
                placeholder="Подразделение"
                name="distributionCenterId"
                mode="multiple"
                selectedValue={distributionCenterId}
                values={distributionCenterList}
                onChange={value => {
                  setDistributionCenterId(value);
                }}
                getErrors={getFieldErrors}
                style={{width: "100%"}}
              />
            </Col>
            <Col lg={8} md={24} sm={24} xs={24} style={{paddingRight: "10px"}}>
              <SelectField
                placeholder="Сотрудник"
                name="employeeId"
                mode="multiple"
                selectedValue={approvingEmployeeId}
                values={employeesList}
                onChange={value => {
                  setApprovingEmployeeId(value);
                }}
                getErrors={getFieldErrors}
                style={{width: "100%"}}
              />
            </Col>
          </Row>
          <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start" align="bottom">
            <Col lg={6} md={12} sm={24} xs={24} style={{paddingRight: "10px"}}>
              <InputField
                name="overpaymentFrom"
                label="Переплата от"
                value={overpaymentFrom}
                setValue={setOverpaymentFrom}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={6} md={12} sm={24} xs={24} style={{paddingRight: "10px"}}>
              <InputField
                name="overpaymentTo"
                label="Переплата до"
                value={overpaymentTo}
                setValue={setOverpaymentTo}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Button type="primary"
                      htmlType="button"
                      onClick={() => {
                        setLoading(true);
                        setIsNeedUpdateReport(true);
                      }}
                      style={{backgroundColor: '#1CAA53', border: 'none', height: '40px', width: '100%'}}
              >
                Применить
              </Button>
            </Col>
          </Row>
        </div>}
        <Row gutter={12} style={{padding: "0", rowGap: "10px", marginTop: '10px', width: '100%'}} type="flex"
             justify="start">
          <Col lg={12} md={12} sm={24} xs={24} style={{textAlign: 'left', marginBottom: '5px'}}>Сумма ставок
            рейсов: {totalPrice}</Col>
          <Col lg={12} md={12} sm={24} xs={24} style={{textAlign: 'left', marginBottom: '5px'}}>Сумма
            переплат: {totalOverprice}</Col>
        </Row>
      </div>
      <div className="reports__table">
        <div className="reports__table-wrapper">
          <Col span={24}>
            <Table
              style={{minWidth: '700px'}}
              columns={columns}
              dataSource={data}
              locale={{emptyText: "Данные отсутствуют"}}
              rowKey="key"
              size="small"
              loading={loading}
              pagination={{
                pageSize: 10000
              }}
            />
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
}
