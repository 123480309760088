import {httpRequest} from "./http-request";

export const carrierService = {
    getCompany,
    getCompanies,
    createCompanyPerson
};

/**
 * Получить компанию
 */
async function getCompany(companyId) {

    return await httpRequest.get(`carrier/get-company/${companyId}`)
        .then((response) => {

            if (response.status === 200 && typeof response.data !== "undefined") {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

/**
 * Получить список компаний принадлежащих пользователю
 */
async function getCompanies() {

    return await httpRequest.get('carrier/get-companies')
        .then((response) => {

            if (response.status === 200 && typeof response.data !== "undefined") {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

/**
 * Создать ответственного для компании
 */
async function createCompanyPerson(companyId, fio, phone, email, viber, whatsapp, telegram, skype) {

    return await httpRequest.post('carrier/create-company-person', {
            companyId: companyId,
            fio: fio,
            phone: phone,
            email: email,
            viber: viber,
            whatsapp: whatsapp,
            telegram: telegram,
            skype: skype
        })
        .then((response) => {

            if (response.status === 200 &&
                (typeof response.data.status !== "undefined" || typeof response.data.errors !== "undefined")
            ) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}