import React, { useEffect, useState } from 'react';
import { Link, Route, Redirect } from "react-router-dom";
import { Button, Col, Table, Row, Modal} from 'antd';
import Spinner from "../../../spinner";
import { notificationServices } from "../../../../services/notification.services";
import moment from "moment";

export default function UserNotification() {

	const [isShowModal, setIsShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [stopListUpdate, setStopListUpdate] = useState(false);
	const [isNeedUpdateList, setIsNeedUpdateList] = useState(true);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [data, setData] = useState([]);
	const [message, setMessage] = useState(null);

	let buttonStyle = { fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '600', fontSize: '10px', lineHeight: '12px', color: '#2B3F63', borderRadius: '0px', borderColor: 'transparent', background: "transparent", boxShadow: 'none', padding: "0px" };
	 let bigButtonStyle = { width: '190px', height: '40px', background: '#1CAA53', position: 'static', borderRadius: '10px', borderColor: "#1CAA53" };

	useEffect(() => {
		if (isNeedUpdateList) {
			setLoading(true);
			setIsNeedUpdateList(false);
			notificationServices.getNotificationList().then((response) => {
				response.forEach(function(part, index) {
					if (part.DateRead === null) {
						this[index].DateRead = "Не прочитано";
					}
				}, response);
				setData(response);
				setDataLoaded(true);
				setLoading(false);
			})
		}
	}, [isNeedUpdateList]);

	function reedMessage(id) {
		notificationServices.readNotification(id).then((response) => {
			console.log(response);
			setIsNeedUpdateList(true);
		})
	}

	const columns = [
		{
			title: 'Сообщение',
			dataIndex: 'Message',
			key: 'Message',
			width: '40%',
            render: (data) => {
			    return (<p style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: data}} />);
            },
			sorter: (a, b) => {
				return a.Message.localeCompare(b.Message);
			},
		},
		{
			title: 'Дата создания',
			dataIndex: 'DateCreated',
			key: 'DateCreated',
			width: '20%',
			sorter: (a, b) => {
				return moment(a.DateCreated).unix() - moment(b.DateCreated).unix();
			},
		},
		{
			title: 'Дата прочтения',
			dataIndex: 'DateRead',
			key: 'DateRead',
			width: '20%',
		},
		{
			title: '',
			key: 'check',
			width: '10%',
			render: (data) => {
				return (
					<React.Fragment>
						<Button
							htmlType="button"
							onClick={() => {
								setIsShowModal(true);
								setMessage(data.Message);
							}}
							size="small"
							style={buttonStyle}>
							Просмотреть
						</Button>
					</React.Fragment >
				);
			}
		},
		{
			title: '',
			key: 'reed',
			width: '10%',
			render: (data) => {
				if (data.DateRead == "Не прочитано") {
					return (
						<React.Fragment>
							<Button
								htmlType="button"
								onClick={() => reedMessage(data.Id)}
								size="small"
								style={buttonStyle}>
								Прочитать
							</Button>
						</React.Fragment >
					);
				}
			}
		},
	];


	let telegramButtonStyle = {
		height: '40px', background: '#1CAA53', position: 'static',
		borderRadius: '10px', borderColor: "#1CAA53",
		marginBottom: "20px", float: 'right', color: "#fff",
		lineHeight: "40px", verticalAlign: "center", padding: "0 20px"};
	return (
		<React.Fragment>
			<Spinner loading={loading} tip="Загрузка...">
				<Row style={{ marginBottom: "25" }}>
					<Col className={"content-header"} style={{ marginBottom: "0px" }} span={12}>Уведомления</Col>
					<Link
						type="primary"
						htmlType="button"
						style={telegramButtonStyle}
						to="/company/user-notification-settings"
					>
						Настройки уведомлений
					</Link>
				</Row>
				<Col span={24}>
					<Table
						style={{ minWidth: "700px" }}
						columns={columns}
						dataSource={data}
						locale={{ emptyText: "Данные отсутствуют" }}
						rowKey="myid"
						size="small"
						pagination={{
							pageSize: 50,
						}}
					/>

				</Col>
				<Modal
					title="Уведомление"
					visible={isShowModal}
					footer={null}
					onCancel={() => {
						setIsShowModal(false);
					}}
				>
				<div style={{textAlign: "center"}}>
					<p style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: message}} />
				</div>
				<div className="center"  style={{padding: "30px"}}>
					<Button type="primary"onClick={() => setIsShowModal(false)} style={bigButtonStyle}>OK</Button>
				</div>
				</Modal>
			</Spinner>
		</React.Fragment >
	);
};

