import React, {useState, useEffect, useContext} from 'react';
import {Layout, Menu, Icon} from 'antd';
import {Link, Route} from 'react-router-dom';

import {AppContext} from "../../../contexts/app-context";
import {userServices} from "../../../services";
import Error from "../../error";
import LogUsersActions from './log-users-actions';
// import LogExchange1C from "./log-exchange-1c";
import UserList from './user-list';
import DistributionCentersPage from './distributions-centers';
import RolesList from './roles-list';

import './settings-page.css';
import {SERVICE_INFORMATION_MANAGEMENT} from "../../../helpers/user_permission.helper";
import UserMessage from "./user-message";
import Consignors from "./consignors";
import CarrierLegalEntities from "./carrier-legal-entities";


const menuElements = [
  {
    parentKey: 'group1',
    link: "/settings/distribution-centers/edit",
    label: <span><Icon type="apartment"/><span>Настройка РЦ</span></span>,
    subMenu: false,
    items: []
  },
  {
    parentKey: 'group2',
    link: null,
    label: <span><Icon type="user"/><span>Пользователи</span></span>,
    subMenu: true,
    items: [
      {
        key: '21',
        link: '/settings/user/list',
        label: 'Управление пользователями',
      },
      {
        key: '22',
        link: '/settings/roles/list',
        label: 'Управление ролями',
      },
      {
        key: '23',
        link: '/settings/user/message',
        label: 'Сообщение для пользователей',
      }
    ]
  },
  {
    parentKey: 'group3',
    link: null,
    label: <span><Icon type="file-exclamation"/><span>Логирование</span></span>,
    subMenu: true,
    items: [
      {
        key: '31',
        link: '/settings/logs/user-actions',
        label: 'Действия пользователей',
      },
      // {
      //     key: '32',
      //     link: '/settings/logs/exchange-1c',
      //     label: 'Обмен с 1С',
      // }
    ]
  },
  {
    parentKey: 'group4',
    link: "/settings/consignors",
    label: <span><Icon type="apartment"/><span>Грузоотправители</span></span>,
    subMenu: false,
    items: []
  },
  {
    parentKey: 'group5',
    link: "/settings/carrier-legal-entities",
    label: <span><Icon type="edit"/><span>Юр.лица перевозчиков</span></span>,
    subMenu: false,
    items: []
  },
];

function LeftMenu({collapsed, menuElementsData}) {

  const menuElements = menuElementsData.map((menuItemData) => {

    if (menuItemData.subMenu) {

      const groupItems = menuItemData.items.map((item) => {
        return (<Menu.Item key={item.key}><Link to={item.link}>{item.label}</Link></Menu.Item>);
      });

      return (
        <Menu.SubMenu key={menuItemData.parentKey} title={menuItemData.label}>
          {groupItems}
        </Menu.SubMenu>
      );
    } else {
      return (
        <Menu.Item key={menuItemData.parentKey}><Link to={menuItemData.link}>{menuItemData.label}</Link></Menu.Item>);
    }

  });

  const menuSelectedItem = menuElementsData
    .filter((data) => {
      if (data.subMenu) {
        const itemsLinks = data.items.filter((dataItem) => {
          return (dataItem.link === window.location.pathname);
        });

        return (itemsLinks.length);
      } else {
        return (data.link === window.location.pathname);
      }
    })
    .map((item) => {
      if (item.subMenu) {
        const itemsLinks = item.items.filter((dataItem) => {
          return (dataItem.link === window.location.pathname);
        });

        return {parentKey: item.parentKey, itemKey: itemsLinks[0].key};
      } else {
        return {parentKey: item.parentKey, itemKey: item.parentKey};
      }
    })
    .shift();

  return (
    <Menu
      defaultSelectedKeys={(typeof menuSelectedItem !== "undefined") ? [menuSelectedItem.itemKey] : []}
      defaultOpenKeys={(typeof menuSelectedItem !== "undefined") ? [menuSelectedItem.parentKey] : []}
      mode="inline"
      theme="light"
      inlineCollapsed={collapsed}
    >
      {menuElements}
    </Menu>
  );

}

export default function SettingsPage() {

  const [collapsed, setCollapsed] = useState(false);
  const appContext = useContext(AppContext);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleResize = () => {
    if (collapsed === false && window.innerWidth < 1000) {
      setCollapsed(true);
    } else if (collapsed === true && window.innerWidth > 1000) {
      setCollapsed(false);
    }
  };

  handleResize();

  if (appContext.filter.enable) {
    appContext.disableFilter();
  }

  const user = userServices.getUser();

  if (!user.isAccessTo(SERVICE_INFORMATION_MANAGEMENT)) {
    return (<Error status={403} desc="Страница не доступна"/>);
  }

  return (
    <Layout tagName="section" style={{padding: '25px 0', background: '#fff'}}>
      <Layout.Sider
        collapsed={collapsed}
        theme="light"
        width={256}
        className={"app-aside"}
      >
        <LeftMenu collapsed={collapsed} menuElementsData={menuElements}/>
      </Layout.Sider>
      <Layout.Content className="app-content app-content--table">
        <Route path="/settings/user/list" exact component={UserList}/>
        <Route path="/settings/user/message" exact component={UserMessage}/>
        <Route path="/settings/logs/user-actions" exact component={LogUsersActions}/>
        {/*<Route path="/settings/logs/exchange-1c" exact component={LogExchange1C}/>*/}
        <Route path="/settings/distribution-centers/edit" exact component={DistributionCentersPage}/>
        <Route path="/settings/roles/list" exact component={RolesList}/>
        <Route path="/settings/consignors" exact component={Consignors}/>
        <Route path="/settings/carrier-legal-entities" exact component={CarrierLegalEntities}/>
      </Layout.Content>
    </Layout>
  );
}
