import React, { useState , useEffect} from "react";

export default function CountDownTimer({secondsLeft, initMoment, setTimerEnabled, isBooked}){
    const calculateTimeLeft = () => {
        const difference = (secondsLeft * 1000 + initMoment) - +new Date() ;
        let timeLeft = {
          minutes: '0',
          seconds: '0'
        };
    
        if (difference > 0) {
          timeLeft = {
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
          };
        }
        return timeLeft;
      };


    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
        if(Object.keys(timeLeft).length){
          setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
          }, 1000);
        }
      });

    let minutes = timeLeft.minutes < 10 ? '0' + timeLeft.minutes : timeLeft.minutes;
    let seconds = timeLeft.seconds < 10 ? '0' + timeLeft.seconds : timeLeft.seconds;
    
    return (
          <div className="timer-wrapper">
            <div className="timer-wrapper__header">Рейс оформляется.<br/>Может стать доступным через:</div>
            <div className={`timer ${isBooked ? 'timer-booked' : ''}`}>
                <div>{minutes}</div>
                <div>{seconds}</div>
            </div>
          </div>);
}