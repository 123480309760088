import React, {useState} from 'react';
import {Button, Col, Modal, notification, Row, Spin} from "antd";
import '../modals.css';
import {Redirect} from "react-router-dom";

export default function ModalShowRefuseReason({isShow, setIsShow, text, title = 'Причина отмены'}) {

    const [loading, setLoading] = useState(false);


    const bigButtonStyle = {
        width: '290px', height: '75px',
        fontSize:'18px', color: 'white',
        backgroundColor: '#1CAA53', border:'none',
        wordWrap: 'break-word'
    };

    const textStyle = {
        textAlign: 'center',
        color: 'black'
    };


    return (
        <Modal
            title={<span className="ant-modal-title">{title}</span>}
            cancelText="Закрыть"
            className="modal-window"
            style={{maxWidth: '450px'}}
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
            }}
            footer={null}
        >
            <Spin spinning={loading} tip="Загрузка...">
                <Row className="add-padding-10 -centered">
                    <h2 style={textStyle}>{text}</h2>
                </Row>
            </Spin>
        </Modal>
    );
}