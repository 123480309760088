import React, {useContext, useEffect, useState} from "react";
import {notification, Pagination} from "antd";
import moment from "moment";
import {deliveriesService, userServices} from "../../../../../services";
import {useFetchDeliveriesList} from "../../../../../hooks/use-fetch-deliveries-list";
import Error from "../../../../error";
import Spinner from "../../../../spinner";
import DeliveryItemBlock from "../../../../delivery-item-block";
import LogistFreeContentItem from "../../../../delivery-item-content/logist/free-content-item";
import ModalCreateOrder from "../../../../modals/create-order-delivery";
import ModalListOrdersDelivery from "../../../../modals/list-orders-delivery";
import DeliveryEmptyContent from "../../../../delivery-empty-content";
import DeliveryGroupList from "../../../../delivery-group-list";
import {AppContext} from "../../../../../contexts/app-context";
import {VIEW_FREE_DELIVERIES_LOGIST} from "../../../../../helpers/user_permission.helper";
import UserNotificationMessage from "../../../../user-message";
import NotificationMessage from "../../../../modals/notification-message";
import Price from "../../../../price";
import {DELIVERY_FREE} from "../../../../../helpers/delivery_status.helper";
import {deliveriesConfig} from "../../../../../config";
import AdsBanner from "../../../../ads-banner";

function HeaderItem({
                        delivery,
                        timerEnabled,
                        setNeedUpdate,
                        setStopListUpdate,
                        setLoadingDelivItem,
                        nameRegionLastPointUnload,
                        regionLoad
                    }) {
    const dateShipmentRus = moment(delivery.DateShipment).format("D MMMM YYYY");

    const [showModalOrdersDelivery, setShowModalOrdersDelivery] = useState(false);
    const [showModalCreateOrder, setShowModalCreateOrder] = useState(false);
    const [paymentType, setPaymentType] = useState(delivery.AllowedPaymentTypes[0].Id);

    const onClickShowOrders = (e) => {
        e.preventDefault();
        setStopListUpdate(true);
        setShowModalOrdersDelivery(true);
    };

    const onClickReservedDelivery = (e) => {
        e.preventDefault();
        setLoadingDelivItem(true);

        deliveriesService
            .reserveDelivery(delivery.Id)
            .then((response) => {
                notification.success({
                    message: "Резервирование рейса",
                    description: response.message,
                });

                setLoadingDelivItem(false);
                setNeedUpdate(true);
            })
            .catch((error) => {
                notification.error({
                    message: "Резервирование рейса",
                    description: error,
                });

                setLoadingDelivItem(false);
            });
    };

    const onClickCreateOrderDelivery = (e) => {
        e.preventDefault();

        setStopListUpdate(true);
        setShowModalCreateOrder(true);
    };

    let btnViewOrders;
    let btnReservation;
    let btnCreateOrder;

    if (delivery.CountOrders && delivery.Permissions.IsAvailableViewListOrders && delivery.Status === "free") {
        btnViewOrders = (
            <div className="application-topline-logist-item-col">
                <button className="btn typical-btn typical-btn-red" onClick={onClickShowOrders}>
                    Посмотреть заявки ({delivery.CountOrders})
                </button>
                <ModalListOrdersDelivery
                    isShow={showModalOrdersDelivery}
                    setIsShow={setShowModalOrdersDelivery}
                    deliveryId={delivery.Id}
                    setNeedUpdateDeliveryList={setNeedUpdate}
                    setStopListUpdate={setStopListUpdate}
                />
            </div>
        );
    }

    if (delivery.Permissions.IsAvailableReservation) {
        btnReservation = (
            <button className="btn typical-btn typical-btn-blue" onClick={onClickReservedDelivery}>
                В резерв <div className="typical-btn-icon typical-btn-green-icon"/>
            </button>
        );
    }

    if (delivery.Permissions.IsAvailableCreate && !timerEnabled && delivery.Status === "free") {
        btnCreateOrder = (
            <React.Fragment>
                <button className="btn typical-btn typical-btn-green" onClick={onClickCreateOrderDelivery}>
                    Подать заявку <div className="typical-btn-icon typical-btn-green-icon"/>
                </button>
                <ModalCreateOrder
                    isShow={showModalCreateOrder}
                    setIsShow={setShowModalCreateOrder}
                    delivery={delivery}
                    setNeedUpdateDeliveryList={setNeedUpdate}
                    setStopListUpdate={setStopListUpdate}
                    paymentType={paymentType}
                    setPaymentType={setPaymentType}
                />
            </React.Fragment>
        );
    }
    return (
        <div className="application-topline">
            <div className="application-topline-logist">
                <div className="application-topline-logist-item">
                    <div className="application-topline-carrier-item-col application-topline-carrier-item-col-city">
                        <p className="typical-icon-field icon-field-patch" title={regionLoad}>
                            {regionLoad}
                        </p>
                        <p className="typical-icon-field icon-field-dispatch" title={nameRegionLastPointUnload}>
                            {nameRegionLastPointUnload}
                        </p>
                    </div>
                    <div className="application-topline-logist-item-col application-topline-logist-item-col-date">
                        <div className="typical-icon-field icon-field-calendar">{dateShipmentRus}</div>
                    </div>
                    {btnViewOrders}
                </div>
                <div className="application-topline-logist-item">
                    <div className="application-topline-logist-item-col application-topline-logist-item-col-price">
                        <Price delivery={delivery}/>
                    </div>
                    <div className="application-topline-logist-item-col application-topline-logist-item-col-button">
                        {btnCreateOrder}
                        {btnReservation}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function LogistFreeDeliveries() {
    const [wasOpenedBeforeUpdate, setWasOpenedBeforeUpdate] = useState({});

    const [user, setUser] = useState(userServices.getUser());
    const [step, setStep] = useState(deliveriesConfig.pageCount);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const appContext = useContext(AppContext);

    useEffect(() => {
        appContext.initFilter();
        setUser(userServices.getUser());
    }, []);

    const [{
        error,
        loading,
        data: deliveries,
        setStopListUpdate,
        setNeedUpdate,
        notifications,
        permissions,
        totalCount,
        extData
    }] = useFetchDeliveriesList(
        "delivery/get-free",
        true,
        true,
        true,
        true,
        deliveriesConfig.secondForUpdate,
        offset,
        step
    );

    let onChange = (page, pageSize) => {
        if (page === currentPage) {
            return false;
        } else {
            setCurrentPage(page);
        }

        let val = 0;
        if (parseInt(page) === 1) {
            setOffset(0);
        } else {
            val = page * pageSize - pageSize;
            setOffset(val);
        }
        appContext.filter.offset = val;
        setNeedUpdate(true);
    };

    if (!user.isAccessTo(VIEW_FREE_DELIVERIES_LOGIST)) {
        return <Error status={403} desc="Страница недоступна"/>;
    }

    if (error) {
        return <Error type={500} desc={error.message}/>;
    }

    let listItems = [];
    if (!loading) {
        listItems = deliveries.map((delivery, index) => {
            return (
              <React.Fragment key={delivery.Id}>
                <DeliveryItemBlock
                    key={`${delivery.Id}_${delivery.CountOrders}`}
                    header={HeaderItem}
                    content={LogistFreeContentItem}
                    delivery={delivery}
                    setStopListUpdate={setStopListUpdate}
                    setNeedUpdate={setNeedUpdate}
                    secondsToAutoRefuse={delivery.secondsToAutoRefuse}
                    wasOpenedBeforeUpdate={wasOpenedBeforeUpdate}
                    setWasOpenedBeforeUpdate={setWasOpenedBeforeUpdate}
                    permissions={permissions}
                />
                  {index === 1 && <AdsBanner type={"2"}/>}
              </React.Fragment>
            );
        });
    }

    return (
        <Spinner loading={loading}>
            <UserNotificationMessage/>
            <NotificationMessage notifications={notifications} setNeedUpdate={false}
                                 setStopListUpdate={setStopListUpdate} loading={loading}/>
            {totalCount !== 0 ?
                <div className="application-block pagination pagination__up">
                    <Pagination
                        style={{textAlign: "center"}}
                        current={currentPage}
                        pageSize={step}
                        total={totalCount}
                        showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
                        onChange={onChange}
                    />
                </div>
                : null}
            {!loading ?
				deliveries.length ? <DeliveryGroupList deliveriesItems={listItems}/> : <DeliveryEmptyContent /> :
				null}
            {totalCount !== 0 ?
                <div className="application-block pagination pagination__down">
                    <Pagination
                        style={{textAlign: "center"}}
                        current={currentPage}
                        pageSize={step}
                        total={totalCount}
                        showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
                        onChange={onChange}
                    />
                </div>
                : null}
        </Spinner>
    );
}
