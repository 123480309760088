import React, {useState, useEffect, useContext} from "react";
import {Button, Modal} from "antd";

const Event = ({date, text}) => {
  const dateNew = new Date(date);
  const timeNew = date.split(' ')[1];
  const dateEvent = dateNew.toLocaleDateString();
  const [message, setMessage] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);

  let bigButtonStyle = {
    width: '190px',
    height: '40px',
    background: '#1CAA53',
    position: 'static',
    borderRadius: '10px',
    borderColor: "#1CAA53"
  };

  return <>
    <a className="event" onClick={() => {
      setIsShowModal(true);
      setMessage(text);
    }}>
      <div className="event__date">
        <div>{timeNew}</div>
        <div>{dateEvent}</div>
      </div>
      <p className="event__text">{text}</p>
    </a>
    <Modal
      title="Уведомление"
      visible={isShowModal}
      footer={null}
      onCancel={() => {
        setIsShowModal(false);
      }}
    >
      <div style={{textAlign: "center"}}>
        <p style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: message}}/>
      </div>
      <div className="center" style={{padding: "30px"}}>
        <Button type="primary" onClick={() => setIsShowModal(false)} style={bigButtonStyle}>OK</Button>
      </div>
    </Modal>
  </>
}
export default Event;
