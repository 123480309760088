
import React, { useState } from 'react';
import { Row, Col, Checkbox, DatePicker } from 'antd';
import { systemService } from "../../../../../services";
import InputField from "../../../../input-field";
import SelectField from "../../../../select-field";
import DividerBlock from '../../../../divider-block';
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import FieldBlock from "../../../../field-block";


export default function FourthStep({ stepFields, setFields, getFieldErrors, country, isProprietor }) {

    let dividerStyle = { margin: '15px 0px 45px 0px', display: "block", clear: "both", width: "100%", minWidth: "100%", height: "1px" }

    const firstToUpper = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    function getRegions(regionName, setRegion) {
        systemService.getRegionsList(regionName)
            .then(data => {
                setRegion(data);
            })
            .catch();
    }

    function getLocalities(localityName, parentGuid, setLocalities) {
        systemService.getLocalitiesList(localityName, parentGuid)
            .then(data => {
                setLocalities(data);
            })
            .catch();
    }

    function getStreets(streetName, parentGuid, setStreets) {
        systemService.getStreetsList(streetName, parentGuid)
            .then(data => {
                setStreets(data);
            })
            .catch();
    }

    function getHouses(houseNumber, parentGuid, setHouses) {
        systemService.getHousesList(houseNumber.replace(/\D.*/, ''), parentGuid)
            .then(data => {
                setHouses(data);
            })
            .catch();
    }

    function getPassportDivisionsList(divisionCode, setDivisions) {
        systemService.getPassportDivisionsList(divisionCode)
            .then(data => {
                setDivisions(data);
            })
            .catch();
    }

    return (
        < Row gutter={[12, 24]}>
            <>
                <span className="divider-label">Поручитель</span>
                <DividerBlock style={dividerStyle} />
                < Row gutter={[12, 24]}>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="guarantorSecondName"
                            label="Фамилия"
                            value={stepFields.guarantorSecondName}
                            setValue={value => setFields({ ...stepFields, guarantorSecondName: firstToUpper(value) })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="guarantorFirstName"
                            label="Имя"
                            value={stepFields.guarantorFirstName}
                            setValue={value => setFields({ ...stepFields, guarantorFirstName: firstToUpper(value) })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="guarantorPatronymic"
                            label="Отчество"
                            value={stepFields.guarantorPatronymic}
                            setValue={value => setFields({ ...stepFields, guarantorPatronymic: firstToUpper(value) })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="guarantorPassportSerial"
                            label="Серия паспорта"
                            value={stepFields.guarantorPassportSerial}
                            setValue={value => setFields({ ...stepFields, guarantorPassportSerial: value.replace(/[^0-9]/g, '') })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="guarantorPassportNumber"
                            label="Номер паспорта"
                            value={stepFields.guarantorPassportNumber}
                            setValue={value => setFields({ ...stepFields, guarantorPassportNumber: value.replace(/[^0-9]/g, '') })}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <FieldBlock name="guarantorPassportDate" title="Дата выдачи паспорта" getErrors={getFieldErrors}>
                            <DatePicker
                                style={{ width: "100%" }}
                                defaultValue={stepFields.guarantorPassportDate}
                                format="DD.MM.YYYY"
                                size="large"
                                name="guarantorPassportDate"
                                locale={localeDatePicker}
                                allowClear={false}
                                onChange={value => setFields({ ...stepFields, guarantorPassportDate: value })}
                            />
                        </FieldBlock>
                    </Col>
                    {country === 1 ? (
                        <Col lg={24} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                            <SelectField
                                title="Код подразделения паспорта"
                                name="guarantorPassportDivision"
                                selectedValue={stepFields.guarantorPassportDivision}
                                values={stepFields.guarantorPassportDivisionsList}
                                onChange={(value) => {
                                    setFields({ ...stepFields, guarantorPassportDivision: value });
                                }}
                                onSearch={(value) => {
                                    if (value.length > 2) {
                                        getPassportDivisionsList(value, (data) => {
                                            setFields({
                                                ...stepFields,
                                                guarantorPassportDivisionsList: data
                                            });
                                        });
                                    }
                                }}
                                getErrors={getFieldErrors}
                                placeholder="Укажите код подразделения"
                                notFoundText={null}
                                showArrow={false}
                                style={{ width: "100%" }}
                            />
                        </Col>
                    ) : (
                        <>
                            <Col lg={12} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorPassportDivision"
                                    label="Код подразделения паспорта"
                                    value={stepFields.guarantorPassportDivision}
                                    setValue={value => setFields({ ...stepFields, guarantorPassportDivision: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                            <Col lg={12} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorPassportRegistration"
                                    label="Где выдан паспорт"
                                    value={stepFields.guarantorPassportRegistration}
                                    setValue={value => setFields({ ...stepFields, guarantorPassportRegistration: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                        </>
                    )
                    }
                    <span className="divider-label">Адрес регистрации</span>
                    <DividerBlock style={dividerStyle} />
                    {country === 1 ? (
                        < Row gutter={[12, 24]}>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <SelectField
                                    title="Регион"
                                    name="guarantorRegRegion"
                                    selectedValue={stepFields.guarantorRegRegion}
                                    values={stepFields.guarantorRegRegionsList}
                                    onChange={(value) => {
                                        setFields({
                                            ...stepFields,
                                            guarantorRegRegion: value,
                                            guarantorRegLocality: null,
                                            guarantorRegStreet: null,

                                            guarantorRegLocalitiesList: [],
                                            guarantorRegStreetsList: [],
                                            guarantorRegHousesList: []
                                        });
                                    }}
                                    onSearch={(value) => {
                                        if (value.length > 2) {
                                            getRegions(value, (data) => {
                                                setFields({
                                                    ...stepFields,
                                                    guarantorRegRegionsList: data
                                                });
                                            });
                                        }
                                    }}
                                    getErrors={getFieldErrors}
                                    placeholder="Укажите регион"
                                    notFoundText={null}
                                    showArrow={false}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <SelectField
                                    title="Населенный пункт"
                                    name="guarantorRegLocality"
                                    selectedValue={stepFields.guarantorRegLocality}
                                    values={stepFields.guarantorRegLocalitiesList}
                                    isDisabled={(!stepFields.guarantorRegRegion)}
                                    onChange={(value) => {
                                        setFields({
                                            ...stepFields,
                                            guarantorRegLocality: value,
                                            guarantorRegStreet: null,

                                            guarantorRegStreetsList: [],
                                            guarantorRegHousesList: []
                                        });
                                    }}
                                    onSearch={(value) => {
                                        if (value.length >= 1) {
                                            getLocalities(value, stepFields.guarantorRegRegion, (data) => {
                                                setFields({
                                                    ...stepFields,
                                                    guarantorRegLocalitiesList: data
                                                });
                                            });
                                        }
                                    }}
                                    getErrors={getFieldErrors}
                                    placeholder="Укажите населенный"
                                    notFoundText={null}
                                    showArrow={false}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <SelectField
                                    title="Улица"
                                    name="guarantorFactStreet"
                                    selectedValue={stepFields.guarantorRegStreet}
                                    values={stepFields.guarantorRegStreetsList}
                                    isDisabled={(!stepFields.guarantorRegLocality)}
                                    onChange={(value) => {
                                        setFields({
                                            ...stepFields, guarantorRegStreet: value,

                                            guarantorRegHousesList: []
                                        });
                                    }}
                                    onSearch={(value) => {
                                        if (value.length >= 2) {
                                            getStreets(value, stepFields.guarantorRegLocality, (data) => {
                                                setFields({
                                                    ...stepFields,
                                                    guarantorRegStreetsList: data
                                                });
                                            });
                                        }
                                    }}
                                    getErrors={getFieldErrors}
                                    placeholder="Укажите улицу"
                                    notFoundText={null}
                                    showArrow={false}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <SelectField
                                    title="Номер дома"
                                    name="guarantorRegHouseNumber"
                                    selectedValue={stepFields.guarantorRegHouseNumber}
                                    values={stepFields.guarantorRegHousesList}
                                    isDisabled={(!stepFields.guarantorRegStreet && !stepFields.guarantorRegLocality)}
                                    onChange={(value) => {
                                        setFields({ ...stepFields, guarantorRegHouseNumber: value });
                                    }}
                                    onSearch={(value) => {
                                        if (value.length > 0) {
                                            getHouses(value, stepFields.guarantorRegStreet ? stepFields.guarantorRegStreet : stepFields.guarantorRegLocality, (data) => {
                                                setFields({
                                                    ...stepFields,
                                                    guarantorRegHousesList: data
                                                });
                                            });
                                        }
                                    }}
                                    getErrors={getFieldErrors}
                                    placeholder="Укажите улицу"
                                    notFoundText={null}
                                    showArrow={false}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorFactPremiseNumber"
                                    label="Номер помещения"
                                    value={stepFields.guarantorRegPremiseNumber}
                                    setValue={value => setFields({ ...stepFields, guarantorRegPremiseNumber: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                        </Row>
                    ) : (
                        < Row gutter={[12, 24]}>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorFactRegion"
                                    label="Регион"
                                    value={stepFields.guarantorRegRegion}
                                    setValue={value => setFields({ ...stepFields, guarantorRegRegion: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorFactLocality"
                                    label="Населённый пункт"
                                    value={stepFields.guarantorRegLocality}
                                    setValue={value => setFields({ ...stepFields, guarantorRegLocality: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorFactStreet"
                                    label="Улица"
                                    value={stepFields.guarantorRegStreet}
                                    setValue={value => setFields({ ...stepFields, guarantorRegStreet: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorFactHouseNumber"
                                    label="Номер дома"
                                    value={stepFields.guarantorRegHouseNumber}
                                    setValue={value => setFields({ ...stepFields, guarantorRegHouseNumber: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorFactPremiseNumber"
                                    label="Номер помещения"
                                    value={stepFields.guarantorRegPremiseNumber}
                                    setValue={value => setFields({ ...stepFields, guarantorRegPremiseNumber: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorFactAddressPostalCode"
                                    label="Почтовый индекс"
                                    value={stepFields.guarantorRegAddressPostalCode}
                                    setValue={value => setFields({ ...stepFields, guarantorRegAddressPostalCode: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                        </Row>)
                    }
                    <span className="divider-label">Фактический адрес проживания</span>
                    <DividerBlock style={dividerStyle} />
                    {country === 1 ? (
                        < Row gutter={[12, 24]}>
                            <Col lg={10} md={24} sm={24} xs={24} ></Col>
                            <Col lg={14} md={24} sm={24} xs={24} style={{ marginBottom: "30px", display: "inline", textAlign: "right"}}>
                                <span className="checkbox-label">Фактический адрес поручителя совпадает с адресом регистрации</span>
                                <Checkbox
                                    name="guarantorFactAddressCheckBox"
                                    checked={stepFields.guarantorFactAddressCheckBox}
                                    onChange={(e) => {
                                        setFields({
                                            ...stepFields,
                                            guarantorFactAddressCheckBox: e.target.checked,
                                            guarantorFactRegion: '',
                                            guarantorFactLocality: '',
                                            guarantorFactStreet: '',
                                            guarantorFactHouseNumber: '',
                                            guarantorFactPremiseNumber: '',
                                            guarantorFactAddressPostalCode: '',
                                        });
                                    }}
                                    
                                >
                                </Checkbox>
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <SelectField
                                    title="Регион"
                                    name="guarantorFactRegion"
                                    selectedValue={stepFields.guarantorFactRegion}
                                    values={stepFields.guarantorFactRegionsList}
                                    isDisabled={stepFields.guarantorFactAddressCheckBox}
                                    onChange={(value) => {
                                        setFields({
                                            ...stepFields,
                                            guarantorFactRegion: value,
                                            guarantorFactLocality: null,
                                            guarantorFactStreet: null,

                                            guarantorFactLocalitiesList: [],
                                            guarantorFactStreetsList: [],
                                            guarantorFactHousesList: []
                                        });
                                    }}
                                    onSearch={(value) => {
                                        if (value.length > 2) {
                                            getRegions(value, (data) => {
                                                setFields({
                                                    ...stepFields,
                                                    guarantorFactRegionsList: data
                                                });
                                            });
                                        }
                                    }}
                                    getErrors={getFieldErrors}
                                    placeholder="Укажите регион"
                                    notFoundText={null}
                                    showArrow={false}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <SelectField
                                    title="Населенный пункт"
                                    name="guarantorFactLocality"
                                    selectedValue={stepFields.guarantorFactLocality}
                                    values={stepFields.guarantorFactLocalitiesList}
                                    isDisabled={(!stepFields.guarantorFactRegion || stepFields.guarantorFactAddressCheckBox)}
                                    onChange={(value) => {
                                        setFields({
                                            ...stepFields,
                                            guarantorFactLocality: value,
                                            guarantorFactStreet: null,

                                            guarantorFactStreetsList: [],
                                            guarantorFactHousesList: []
                                        });
                                    }}
                                    onSearch={(value) => {
                                        if (value.length >= 1) {
                                            getLocalities(value, stepFields.guarantorFactRegion, (data) => {
                                                setFields({
                                                    ...stepFields,
                                                    guarantorFactLocalitiesList: data
                                                });
                                            });
                                        }
                                    }}
                                    getErrors={getFieldErrors}
                                    placeholder="Укажите населенный"
                                    notFoundText={null}
                                    showArrow={false}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <SelectField
                                    title="Улица"
                                    name="guarantorFactStreet"
                                    selectedValue={stepFields.guarantorFactStreet}
                                    values={stepFields.guarantorFactStreetsList}
                                    isDisabled={(!stepFields.guarantorFactLocality || stepFields.guarantorFactAddressCheckBox)}
                                    onChange={(value) => {
                                        setFields({
                                            ...stepFields, guarantorFactStreet: value,

                                            guarantorFactHousesList: []
                                        });
                                    }}
                                    onSearch={(value) => {
                                        if (value.length >= 2) {
                                            getStreets(value, stepFields.guarantorFactLocality, (data) => {
                                                setFields({
                                                    ...stepFields,
                                                    guarantorFactStreetsList: data
                                                });
                                            });
                                        }
                                    }}
                                    getErrors={getFieldErrors}
                                    placeholder="Укажите улицу"
                                    notFoundText={null}
                                    showArrow={false}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <SelectField
                                    title="Номер дома"
                                    name="guarantorFactHouseNumber"
                                    selectedValue={stepFields.guarantorFactHouseNumber}
                                    values={stepFields.guarantorFactHousesList}
                                    isDisabled={((!stepFields.guarantorFactStreet && !stepFields.guarantorFactLocality) || stepFields.guarantorFactAddressCheckBox)}
                                    onChange={(value) => {
                                        setFields({ ...stepFields, guarantorFactHouseNumber: value });
                                    }}
                                    onSearch={(value) => {
                                        if (value.length > 0) {
                                            getHouses(value, stepFields.guarantorFactStreet ? stepFields.guarantorFactStreet : stepFields.guarantorFactLocality, (data) => {
                                                setFields({
                                                    ...stepFields,
                                                    guarantorFactHousesList: data
                                                });
                                            });
                                        }
                                    }}
                                    getErrors={getFieldErrors}
                                    placeholder="Укажите улицу"
                                    notFoundText={null}
                                    showArrow={false}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorFactPremiseNumber"
                                    label="Номер помещения"
                                    isDisabled={stepFields.guarantorFactAddressCheckBox}
                                    value={stepFields.guarantorFactPremiseNumber}
                                    setValue={value => setFields({ ...stepFields, guarantorFactPremiseNumber: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                        </Row>
                    ) : (
                        < Row gutter={[12, 24]}>
                            <Col lg={10} md={24} sm={24} xs={24} ></Col>
                            <Col lg={14} md={24} sm={24} xs={24} style={{ marginBottom: "30px", display: "inline", textAlign: "right" }}>
                                <span className="checkbox-label">Фактический адрес поручителя совпадает с адресом регистрации</span>
                                <Checkbox
                                    name="guarantorFactAddressCheckBox"
                                    checked={stepFields.guarantorFactAddressCheckBox}
                                    onChange={(e) => {
                                        setFields({
                                            ...stepFields,
                                            guarantorFactAddressCheckBox: e.target.checked,
                                            guarantorFactRegion: '',
                                            guarantorFactLocality: '',
                                            guarantorFactStreet: '',
                                            guarantorFactHouseNumber: '',
                                            guarantorFactPremiseNumber: '',
                                            guarantorFactAddressPostalCode: '',
                                        });
                                    }}
                                    
                                >
                                </Checkbox>
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorFactRegion"
                                    label="Регион"
                                    isDisabled={stepFields.guarantorFactAddressCheckBox}
                                    value={stepFields.guarantorFactRegion}
                                    setValue={value => setFields({ ...stepFields, guarantorFactRegion: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorFactLocality"
                                    label="Населённый пункт"
                                    isDisabled={stepFields.guarantorFactAddressCheckBox}
                                    value={stepFields.guarantorFactLocality}
                                    setValue={value => setFields({ ...stepFields, guarantorFactLocality: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorFactStreet"
                                    label="Улица"
                                    value={stepFields.guarantorFactStreet}
                                    isDisabled={stepFields.guarantorFactAddressCheckBox}
                                    setValue={value => setFields({ ...stepFields, guarantorFactStreet: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorFactHouseNumber"
                                    label="Номер дома"
                                    isDisabled={stepFields.guarantorFactAddressCheckBox}
                                    value={stepFields.guarantorFactHouseNumber}
                                    setValue={value => setFields({ ...stepFields, guarantorFactHouseNumber: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorFactPremiseNumber"
                                    label="Номер помещения"
                                    isDisabled={stepFields.guarantorFactAddressCheckBox}
                                    value={stepFields.guarantorFactPremiseNumber}
                                    setValue={value => setFields({ ...stepFields, guarantorFactPremiseNumber: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorFactAddressPostalCode"
                                    label="Почтовый индекс"
                                    isDisabled={stepFields.guarantorFactAddressCheckBox}
                                    value={stepFields.guarantorFactAddressPostalCode}
                                    setValue={value => setFields({ ...stepFields, guarantorFactAddressPostalCode: value })}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                        </Row>)
                    }
                </Row>
            </>
        </Row>
    );
}
