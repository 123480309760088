import React, {useEffect, useState} from "react";
import {Button, Row, Col, Modal, Radio, notification, Table, TimePicker, Icon, Popconfirm} from "antd";
import '../modals.css';
import {distributionCenterService} from "../../../services";
import Spinner from "../../spinner";
import moment from "moment/moment";
import ModalCreateGateForDistributionCenter from "../create-gate";
import SelectField from "../../select-field";


export default function ModalListLoadingSchedule({
                                                   isShow, distCenterId = null,
                                                   onCloseModal,
                                                   name = ""
                                                 }) {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [distCenters, setDistCenters] = useState([]);
  const [distCenter, setDistCenter] = useState(distCenterId);
  const [distCenterName, setDistCenterName] = useState(name)


  useEffect(() => {
    if (distCenterId === null && isShow) {
      distributionCenterService.getCompanies()
        .then((response) => {

          const distributionsCentersData = response.map((item) => {
            return {
              id: item.Id,
              name: item.Name,
            };
          });

          setDistCenters(distributionsCentersData);
          setDistCenter(distributionsCentersData[0].id)
          setDistCenterName(distributionsCentersData[0].name)

        }).catch((errMsg) => notification.error(
        {
          message: "Получение списка распределительных центров",
          description: errMsg
        }));
    }
  }, [isShow])

  useEffect(() => {
    if (isShow && loading && distCenter !== null) {
      distributionCenterService.getLoadingSchedule(distCenter)
        .then((response) => {
          setData(response);
          setLoading(false);
        })
        .catch((error) => {
          notification.error({
            message: "Записи на погрузку",
            description: error,
          });
          setLoading(false);
        });
    }
  }, [distCenter, loading, isShow]);

  const columns = [
    {
      title: 'Время записи',
      dataIndex: 'dataTime',
      key: 'dataTime',
    },
    {
      title: 'Ворота',
      key: 'gate',
      render: (record) => {
        return <span>{record.gate.name}</span>
      }
    },
    {
      title: 'Рейс',
      key: 'delivery',
      render: (record) => {
        return <>
          <span>№ {record.delivery.number}</span>
          <div>
            {record.carrierLegalTitle}
          </div>
        </>
      }
    },
    {
      title: 'Водитель',
      key: 'driver',
      render: (record) => {
        return <>
          <div>{record.driver.fio}</div>
          <a className="link"
             href={`tel:+7${record.driver.phone}`}><b>{record.driver.phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, '+7 ($1) $2 $3 $4')}
          </b></a>
        </>
      }
    },
    {
      title: 'Время прибытия',
      render: (record) => {
        if (record.actualTime) {
          return record.actualTime;
        }
        return null;
      }
    },
  ];


  return (
    <Modal
      title={<span>РЦ «{distCenterName}»<br/>Список записей на погрузку</span>}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        onCloseModal();
      }}
      forceRender={true}
      footer={<div></div>}
    >
      <Spinner loading={loading}>
        {!distCenterId &&
          <Row gutter={[16, 24]} style={{marginBottom: "34px"}}>
            <Col span={24}>
              <SelectField name="distCenters" title="Распределительные центры"
                           placeholder="выберите РЦ"
                           getErrors={() => {
                           }}
                           onChange={(value) => {
                             const newName = distCenters.find((item) => item.id === value).name;
                             setDistCenterName(newName);
                             setDistCenter(value);
                             setLoading(true);
                           }}
                           style={{width: "100%"}}
                           selectedValue={distCenter}
                           values={distCenters}/>
            </Col>
          </Row>
        }
        <Row gutter={[16, 24]} style={{marginBottom: "34px"}}>
          <Col span={24}>
            <Table
              style={{minWidth: '100%'}}
              columns={columns}
              dataSource={data}
              locale={{emptyText: "Данные отсутствуют"}}
              rowKey="id"
              size="small"
              scroll={{x: "max-content"}}
            />
          </Col>
        </Row>
      </Spinner>
    </Modal>
  );
}
