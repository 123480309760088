export const fileHelper = {
    downloadFileBase64,
    getMimeTypeByExtension
};

export const mimeTypes = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    bmp: 'image/bmp',
    pdf: 'application/pdf',
};

function downloadFileBase64(b64, fileName) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;base64,' + b64);
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

function getMimeTypeByExtension(extension) {
    return mimeTypes[extension] || 'application/octet-stream';
}