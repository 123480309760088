import React, {useEffect, useState} from "react";
import {Modal, Row, Steps, notification} from "antd";
import Step1FilledData from './step-1-filled-data';
import StepCheckingAndEditVerifiedInfo from './step-checking-and-edit-verified-info';
import Step5SendingInfo from './step-5-sending-info';
import './attach-driver-order-delivery.css';
import '../modals.css';
import {driverService, trailService, truckService, systemService} from "../../../services";

export default function ModalAttachDriverOrderDelivery({
    orderId, companyId, isShow, setIsShow, setNeedUpdateDeliveryList,
    setStopListUpdate,
    deliveryDateShipmentRus,
    deliveryPrice,
    deliveryRegionLoad,
    deliveryRegionUnload,
    isForeignDelivery,
    deliveryContractType,
    deliveryId,
    date
}) {

    const [currentStep, setCurrentStep] = useState(1);
    const [contentStep, setContentStep] = useState(null);
    const [buttonsModal, setButtonsModal] = useState([]);
    const [carryingCapacityValues, setCarryingCapacityValues] = useState(null);
    const [loading, setLoading] = useState(false);

    const [filledData, setFilledData] = useState({
        selectedDriver: null,
        selectedTruck: null,
        selectedTrail: null,
        isTrailDisabled: true,
        dateProcuration: null,
        numberProcuration: null,
        withoutProcuration: false,
        selectedForeignPartner: null,
        containerNumber: null,
    });

    useEffect(() => {
        if (isShow && loading) {
            systemService.getCarryingCapacity()
                .then((response) => {
                    setLoading(false);
                    setCarryingCapacityValues(response);
                })
                .catch((error) => {
                    setLoading(false);
                    notification.error({
                        message: "Получение грузоподъемностей и вместимостей",
                        description: error,
                    });
                });
        }
    }, [isShow, loading]);


    useEffect(() => {
        if (isShow && !loading){
            setLoading(true);
        }
    }, [isShow]);


    const onCloseModal = () => {
        setIsShow(false);
        setNeedUpdateDeliveryList(true);
        setStopListUpdate(false);
    };

    useEffect(() => {

        switch (currentStep) {
            default:
            case 1:
                setContentStep(
                    <Step1FilledData
                        companyId={companyId}
                        filledData={filledData}
                        setFilledData={setFilledData}
                        setIsShowModal={setIsShow}
                        showModal={isShow}
                        setButtonsModal={setButtonsModal}
                        currentModalStep={currentStep}
                        setModalStep={setCurrentStep}
                        deliveryDateShipmentRus={deliveryDateShipmentRus}
                        deliveryPrice={deliveryPrice}
                        deliveryRegionLoad={deliveryRegionLoad}
                        deliveryRegionUnload={deliveryRegionUnload}
                        isForeignDelivery={isForeignDelivery}
                        carryingCapacityValues={carryingCapacityValues}
                        onCloseModal={onCloseModal}
                        deliveryContractType={deliveryContractType}
                    />
                );
                break;

            case 2:
                setContentStep(
                    <StepCheckingAndEditVerifiedInfo
                        key={currentStep}
                        id={filledData.selectedDriver}
                        setButtonsModal={setButtonsModal}
                        currentModalStep={currentStep}
                        setModalStep={setCurrentStep}
                        onCheckingRequest={driverService.checkVerifiedInfo}
                        onSaveEditingInfoRequest={driverService.saveEditingInfo}
                        onCloseModal={onCloseModal}
                        loadingMessage="Подождите, происходит проверка данных водителя"
                    />
                );
                break;

            case 3:
                setContentStep(
                    <StepCheckingAndEditVerifiedInfo
                        key={currentStep}
                        id={filledData.selectedTruck}
                        driverId={filledData.selectedDriver}
                        setButtonsModal={setButtonsModal}
                        currentModalStep={currentStep}
                        setModalStep={setCurrentStep}
                        onCheckingRequest={truckService.checkVerifiedInfo}
                        onSaveEditingInfoRequest={truckService.saveEditingInfo}
                        loadingMessage="Подождите, происходит проверка данных авто"
                        carryingCapacityValues={carryingCapacityValues}
                        onCloseModal={onCloseModal}
                    />
                );
                break;

            case 4:
                setContentStep(
                    <StepCheckingAndEditVerifiedInfo
                        key={currentStep}
                        id={filledData.selectedTrail}
                        driverId={filledData.selectedDriver}
                        setButtonsModal={setButtonsModal}
                        currentModalStep={currentStep}
                        setModalStep={setCurrentStep}
                        onCheckingRequest={trailService.checkVerifiedInfo}
                        onSaveEditingInfoRequest={trailService.saveEditingInfo}
                        loadingMessage="Подождите, происходит проверка данных прицепа"
                        carryingCapacityValues={carryingCapacityValues}
                        isNeedChecking={(!filledData.isTrailDisabled)}
                        onCloseModal={onCloseModal}
                    />
                );
                break;

            case 5:
                setContentStep(
                    <Step5SendingInfo
                        key={currentStep}
                        orderId={orderId}
                        filledData={filledData}
                        setButtonsModal={setButtonsModal}
                        currentModalStep={currentStep}
                        setModalStep={setCurrentStep}
                        onCloseModal={onCloseModal}
                        deliveryId={deliveryId}
                        date={date}
                    />
                );
                break;

            case 6:
                setContentStep(<div/>);
                break;
        }

    }, [isShow, currentStep, filledData]);

    if (currentStep === 6){
        onCloseModal();
    }

    return (
        <Modal
            title="Заполнение заявки"
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={onCloseModal}
            footer={buttonsModal}
        >
            <Row>
                <Steps type="navigation" size="small" current={currentStep - 1} className="site-navigation-steps" style={{marginBottom: "14px"}}>
                    <Steps.Step title="Заполнение данных"/>
                    <Steps.Step title="Проверка водителя"/>
                    <Steps.Step title="Проверка авто"/>
                    <Steps.Step title="Проверка прицепа"/>
                    <Steps.Step title="Отправка данных"/>
                </Steps>
            </Row>
            <Row className="modal-attach-driver__steps-content">
                {contentStep}
            </Row>
        </Modal>
    );
}
