import React, {useEffect, useState} from 'react';
import {companyService, consignorService} from '../../../services';
import {Modal, Input, notification, Button, Table} from 'antd';
import Spinner from "../../spinner";

const shipperCompanyDataAliases = {
    OrgForm: 'Организационная форма',
    DateRegistration: 'Дата регистрации',
    Name: 'Название',
    WorkWithNds: 'НДС',
    LegalReason: 'Основание действий',
    FirstName: 'Имя',
    SecondName: 'Фамилия',
    Patronymic: 'Отчестов',
    Inn: 'ИНН',
    Kpp: 'КПП',
    Ogrn: 'ОГРН',
    Ogrnip: 'ОГРНИП',
    Okpo: 'ОКПО',
    Rs: 'Расчётный счёт',
    Bic: 'БИК',
    BankName: 'Название банка',
    Country: 'Страна',
    RegAddress: 'Фактический адрес',
    MailingAddress: 'Почтовый адрес',
    LegalAddressPostalCode: 'Юридический адрес',
    AtiCode: 'Код АТИ',
    PositionStr: 'Должность',
};

export default function ModalShipperCompanyFullInfo({ shipperCompanyId, isShow, setIsShow }) {

  const [loading, setLoading] = useState(true);

  const [legalEntityInfo, setLegalEntityInfo] = useState([]);

  const [columns, setColumns] = useState([
      {
          title: '',
          dataIndex: 'name',
          key: 'name',
      },
      {
          title: '',
          key: 'data',
          render: (record) => {
              function isUrl(string) {
                  let regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
                  return regexp.test(string);
              }

              if (isUrl(record.data)) {
                  return (
                      <React.Fragment>
                          <a href={record.data} target="_blank" rel="noopener noreferrer" className="active">Открыть</a>
                      </React.Fragment>
                  );
              } else {
                  if (record.data === 0 || record.data === 1) {
                      return record.data ? 'Да' : 'Нет';
                  }
                  return record.data
              }
          }
      },
  ]);

  useEffect(() => {
        if (isShow) {
            consignorService.geShipperCompanyInfo(shipperCompanyId)
                .then((response) => {
                    setLoading(false);

                    let data = [];
                    Object.keys(response).forEach((element) => {
                        if (response[element] !== null && response[element] !== '') {
                            if (typeof shipperCompanyDataAliases[element] !== 'undefined') {
                                data.push(
                                    {
                                        name: shipperCompanyDataAliases[element],
                                        data: response[element]
                                    }
                                );
                            }
                        }
                    });

                    if (response.Director) {
                        Object.keys(response.Director).forEach((element) => {
                            data.push(
                                {
                                    name: shipperCompanyDataAliases[element],
                                    data: response.Director[element]
                                }
                            );
                        });
                    }

                    Object.keys(response.Docs).forEach((element) => {
                        if (response.Docs[element] !== null) {
                            let document = response.Docs[element];
                            data.push(
                                {
                                    name: document.Name,
                                    data: document.Url
                                }
                            );
                        }
                    });

                    setLegalEntityInfo(data);
                })
                .catch((err) => {
                    notification.error({
                        message: "Получение списка заявок",
                        description: err
                    });
                    setLoading(false);
                    setIsShow(false);
                });
        }

  }, [isShow]);

  return (
      <Modal
          title={'Информация о юр.лице перевозчика'}
          cancelText="Закрыть"
          className="modal-window"
          align={null}
          visible={isShow}
          onCancel={() => {
              setIsShow(false);
          }}
          footer={null}
      >
          <Spinner loading={loading} tip="Загрузка...">
              <Table
                  columns={columns}
                  dataSource={legalEntityInfo}
                  rowKey="name"
                  pagination={false}
                  size="small"
              />
          </Spinner>
      </Modal>
  );
  
}
