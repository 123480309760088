import React, { useEffect, useState } from "react";
import { Form, InputNumber, Select, Input } from "antd";
// import PaymentTypeField from "../payment-type";

export default function BitInputField({ name, label, priceDelivery, setPrice, setPaymentType, min = 0, max = undefined, getFieldErrors, keyNum, delivery }) {
	const fieldErrors = (typeof keyNum !== "undefined") ? getFieldErrors(name, keyNum) : getFieldErrors(name);
	const { Option } = Select;

	const [selectedPaymentType, setSelectedPaymentType] = useState(1);
	const [currentPrice, setCurrentPrice] = useState(1);

	const types = [
		{ key: 1, class: 'bit-1', title: "NdsPrice", value: delivery.NdsPrice, name: 'RUB с НДС' },
		{ key: 2, class: 'bit-2', title: 'NoNdsPrice', value: delivery.NoNdsPrice, name: 'RUB без НДС' },
		{ key: 3, class: 'bit-3', title: "CashPrice", value: delivery.CashPrice, name: 'RUB нал.' },

		{ key: 4, class: 'bit-4', title: "EuroPrice", value: delivery.EuroPrice, name: 'Доллары' },
		{ key: 5, class: 'bit-5', title: "DollarPrice", value: delivery.DollarPrice, name: 'Евро' },

		{ key: 6, class: 'bit-6', title: "BelarusRubNdsPrice", value: delivery.BelarusRubNdsPrice, name: 'BYN с НДС' },
		{ key: 7, class: 'bit-7', title: "BelarusRubNoNdsPrice", value: delivery.BelarusRubNoNdsPrice, name: 'BYN без НДС' },
	];

	// types.sort(function (a, b) {
	// 	return a.value - b.value;
	// });

	// types.forEach((element, index) => {
	// 	if (element.value == 0) {
	// 		types[index].value = '-';
	// 	} else {
	// 		inputDeefaultValue = element.value;
	// 		selectDefaultValue = element.key
	// 	}
	// })

	// types.sort(function (a, b) {
	// 	a.value = a.value == '-' ? 0 : a.value;
	// 	b.value = b.value == '-' ? 0 : b.value;
	// 	return b.value - a.value;
	// });

    useEffect(() => {
        const price = delivery.Prices.find(e => e.Price !== null);
        if (price) {
            setPrice(price.Price);
            setSelectedPaymentType(price.PaymentType.Id);
            setCurrentPrice(price.Price);
        }
    }, [delivery])

	const onChange = (price) => {
		if (price == null) {
			price = 0;
		}
		setPrice(price);
    setCurrentPrice(price);
		priceDelivery = price;
	};

	const onClickSetPayment = (value) => {
		setPaymentType(value);
	}

	if (fieldErrors) {
		return (
			<React.Fragment>
				<span className="form-field-label" htmlFor={name}>
					{label}
				</span>
				<Form.Item validateStatus="error" help={fieldErrors}>
					<Input.Group compact>
						<InputNumber
							min={0}
							max={max}
							size="large"
							name={name}
							id={name}
							onChange={onChange}
							placeholder={label}
							value={currentPrice}
							style={{ width: "60%" }}
							//formatter={value => `₽ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
							//parser={value => value.replace(/\₽\s?|(,*)/g, '')}
						/>
						<Select onChange={onClickSetPayment} value={selectedPaymentType} className="bit-item" size="large" style={{width: "40%", fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "normal", fontSize: "13px", lineHeight: "12px" }}>
                            {delivery.Prices.map((type) => {
                                return (<Option key={type.PaymentType.Id} value={type.PaymentType.Id}>{type.PaymentType.Title}</Option>);
                            })}
						</Select>

						<p className="bit-label">
                            {delivery.Prices.map((type) => {
                                return (
                                    <span className="bit-1">{type.PaymentType.Title}: <b className="bit-value">{type.Price}</b></span>
                                );
                            })}
						</p>
					</Input.Group>
				</Form.Item>
			</React.Fragment>
		);
	} else {
		return (
			<React.Fragment>
				<span className="form-field-label" htmlFor={name}>
					{label}
				</span>
				<Input.Group compact>
					<InputNumber
						min={min}
						max={max}
						size="large"
						name={name}
						id={name}
						onChange={onChange}
						placeholder={label}
            value={currentPrice}
						style={{ width: "60%" }}
						//formatter={value => `₽ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						//parser={value => value.replace(/\₽\s?|(,*)/g, '')}
					/>

					<Select
              onChange={onClickSetPayment}
              value={selectedPaymentType}
              className="bit-item"
              size="large"
              onSelect={(value => setSelectedPaymentType(value))}
              style={{ width: "40%", fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "normal", fontSize: "13px", lineHeight: "12px" }}
          >
						{delivery.Prices.map((type) => {
						    return (<Option key={type.PaymentType.Id} value={type.PaymentType.Id}>{type.PaymentType.Title}</Option>);
						})}
					</Select>

					<p className="bit-label">
                        {delivery.Prices.map((type,index) => {
                            return (
                                <span className="bit-1" key={`payment-type-${index}`}>{type.PaymentType.Title}: <b className="bit-value">{type.Price}</b></span>
                            );
                        })}
					</p>
				</Input.Group>
			</React.Fragment>
		);
	}
}
