import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {mainConfig} from "../../config";
import Cookies from 'js-cookie';

import {AppContext} from "../../contexts/app-context";
// import RadioGroup from "antd/es/radio/group";
// import {Radio} from "antd";
import {userServices} from "../../services";
import {SWITCH_TO_DIFFERENT_COMPANY_TYPE} from "../../helpers/user_permission.helper";
import SubMenu from "./SubMenu";

const ItemMenu = ({item}) => {
  const activeClass = item.links.indexOf(window.location.pathname) !== -1 ? "active" : null;
  return (
    <li className={activeClass}>
      <Link to={item.links[0]}>{item.label}</Link>
    </li>
  );
};

export default function TopMenu({items, user, setNeedUpdateUser}) {
  const appContext = useContext(AppContext);
  const [isMenuOpen, setMenuStatus] = useState(false);
  const [isDarkTheme, setDarkTheme] = (Cookies.get('theme') === 'dark') ? useState(true) : useState(false);

  const changeTheme = () => {
    if (!isDarkTheme) {
      document.querySelector(".theme").classList.add("theme--dark")
      Cookies.set('theme', 'dark', {expires: 7000})
    } else {
      document.querySelector(".theme").classList.remove("theme--dark")
      Cookies.set('theme', 'light', {expires: 7000})
    }
    setDarkTheme(!isDarkTheme);
  }

  const ProfileBlock = ({onLogout}) => {
    const renderSwitchUserType = () => {
      let userInfo = user.info;

      const handleSwitchUserType = () => {
        userServices.switchType()
          .then(() => {
            setNeedUpdateUser(true);
          });
        userInfo.isStaff = !(userInfo.isStaff);
      }

      if (!user.isAccessTo(SWITCH_TO_DIFFERENT_COMPANY_TYPE) || !userInfo.rules.IsCanSwitchType) {
        return null;
      }

      return (
        <div className="head-login-role">
          <div className="form-switch form-switch--role">
            <input value={true} className="form-switch-input" type="radio" name="role" id="switchRoleOwner"
                   checked={userInfo.isStaff} onChange={handleSwitchUserType}/>
            <label className="form-switch-label" htmlFor="switchRoleOwner">
              <span className="only-desktop">Грузовладелец</span>
              <span className="only-mobile">Отпр.</span>
            </label>
            <input value={false} className="form-switch-input" type="radio" name="role" id="switchRoleСarrier"
                   checked={!userInfo.isStaff} onChange={handleSwitchUserType}/>
            <label className="form-switch-label" htmlFor="switchRoleСarrier">
              <span className="only-desktop">Перевозчик</span>
              <span className="only-mobile">Пер.</span>
            </label>
          </div>
        </div>
      );

      // return (
      //     <RadioGroup onChange={handleSwitchUserType} value={userInfo.isStaff}>
      //         <Radio value={true}>Отправитель</Radio>
      //         <Radio value={false}>Перевозчик</Radio>
      //     </RadioGroup>
      // );
    };

    if (!user || user.isGuest()) {
      return (
        <Link to="/login" className="head-login__link-login">
          Войти
        </Link>
      );
    } else {
      return (
        <React.Fragment>
          <span className="head-login__fio">{user.info.fio}</span>
          {renderSwitchUserType()}
          <Link to="/login" onClick={onLogout} className="btn btn-link head-login__link-logout"/>
        </React.Fragment>
      );
    }
  };

  return (
    <div className="container">
      <div className="header__block header__block--top">
        <a href="/" className="logo">
          <img src="/img/svg/logo.svg" alt={mainConfig.companyName}/>
        </a>
        <div className="head-nav">
          <ul>
            {items.map((item, index) => (
              <ItemMenu key={index} item={item}/>
            ))}
          </ul>
        </div>
        <div className="head-login">
          <ProfileBlock user={user} onLogout={appContext.logout}/>
        </div>
      </div>
      <div className="header__block header__block--bottom">
        <button className="header__mobile-button" type="button" onClick={() => setMenuStatus(!isMenuOpen)}>
          {!isMenuOpen &&
            <svg width="19" height="13" viewBox="0 0 19 13" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0H19V1H0V0ZM0 6H19V7H0V6ZM19 12H0V13H19V12Z" fill="currentcolor"/>
            </svg>}
          {isMenuOpen &&
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="currentcolor"/>
            </svg>}
        </button>
        <div className="head-nav bottom-menu">
          <SubMenu/>
        </div>
        <div className="form-switch form-switch--theme">
          <input className="form-switch-input" type="radio" name="theme" value="0" id="themeDark"
                 defaultChecked={isDarkTheme} onClick={changeTheme}/>
          <label className="form-switch-label" htmlFor="themeDark" aria-label="Темная тема">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.93005 0.119995C4.86062 0.119995 0.75 4.23059 0.75 9.29999C0.75 14.3694 4.86062 18.48 9.93005 18.48C14.9995 18.48 19.1101 14.3694 19.1101 9.29999C19.1101 8.83079 19.0693 8.36159 19.0081 7.91279C18.0085 9.31019 16.3765 10.218 14.5201 10.218C11.4805 10.218 9.01205 7.74959 9.01205 4.70999C9.01205 2.86379 9.91985 1.22159 11.3173 0.221995C10.8685 0.160795 10.3993 0.119995 9.93005 0.119995Z"
                fill="currentcolor"/>
            </svg>
          </label>
          <input className="form-switch-input" type="radio" name="theme" value="1" id="themeLight"
                 defaultChecked={!isDarkTheme} onClick={changeTheme}/>
          <label className="form-switch-label" htmlFor="themeLight" aria-label="Светлая тема">
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11 6.16962C8.24 6.16962 6 8.40962 6 11.1696C6 13.9296 8.24 16.1696 11 16.1696C13.76 16.1696 16 13.9296 16 11.1696C16 8.40962 13.76 6.16962 11 6.16962ZM1 12.1696H3C3.55 12.1696 4 11.7196 4 11.1696C4 10.6196 3.55 10.1696 3 10.1696H1C0.45 10.1696 0 10.6196 0 11.1696C0 11.7196 0.45 12.1696 1 12.1696ZM19 12.1696H21C21.55 12.1696 22 11.7196 22 11.1696C22 10.6196 21.55 10.1696 21 10.1696H19C18.45 10.1696 18 10.6196 18 11.1696C18 11.7196 18.45 12.1696 19 12.1696ZM10 1.16962V3.16962C10 3.71962 10.45 4.16962 11 4.16962C11.55 4.16962 12 3.71962 12 3.16962V1.16962C12 0.619617 11.55 0.169617 11 0.169617C10.45 0.169617 10 0.619617 10 1.16962ZM10 19.1696V21.1696C10 21.7196 10.45 22.1696 11 22.1696C11.55 22.1696 12 21.7196 12 21.1696V19.1696C12 18.6196 11.55 18.1696 11 18.1696C10.45 18.1696 10 18.6196 10 19.1696ZM4.99 3.74962C4.6 3.35962 3.96 3.35962 3.58 3.74962C3.19 4.13962 3.19 4.77962 3.58 5.15962L4.64 6.21962C5.03 6.60962 5.67 6.60962 6.05 6.21962C6.43 5.82962 6.44 5.18962 6.05 4.80962L4.99 3.74962ZM17.36 16.1196C16.97 15.7296 16.33 15.7296 15.95 16.1196C15.56 16.5096 15.56 17.1496 15.95 17.5296L17.01 18.5896C17.4 18.9796 18.04 18.9796 18.42 18.5896C18.81 18.1996 18.81 17.5596 18.42 17.1796L17.36 16.1196ZM18.42 5.15962C18.81 4.76962 18.81 4.12962 18.42 3.74962C18.03 3.35962 17.39 3.35962 17.01 3.74962L15.95 4.80962C15.56 5.19962 15.56 5.83962 15.95 6.21962C16.34 6.59962 16.98 6.60962 17.36 6.21962L18.42 5.15962ZM6.05 17.5296C6.44 17.1396 6.44 16.4996 6.05 16.1196C5.66 15.7296 5.02 15.7296 4.64 16.1196L3.58 17.1796C3.19 17.5696 3.19 18.2096 3.58 18.5896C3.97 18.9696 4.61 18.9796 4.99 18.5896L6.05 17.5296Z"
                fill="currentcolor"/>
            </svg>
          </label>
        </div>
      </div>
      {isMenuOpen &&
        <div className="header__block header__block--mobile">
          <div className="header__mobile-menu container">
            <div className="head-nav">
              <ul>
                {items.map((item, index) => (
                  <ItemMenu key={index} item={item}/>
                ))}
              </ul>
              <SubMenu/>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
