import {httpRequest} from "./http-request";

export const truckService = {
    create,
    getListBrands,
    checkVerifiedInfo,
    saveEditingInfo,
    edit,
    getListByName,
    getDocsArchive,
    changeVINStatus,
    hide,
};

/**
 * Создать авто
 */
async function create(truckData) {

    const formData = new FormData();

    for (let fieldName in truckData) {
        if (truckData.hasOwnProperty(fieldName)) {
            formData.append(fieldName, truckData[fieldName]);
        }
    }

    return await httpRequest.post('truck/create', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

/**
 * Получить список марок авто
 */
async function getListBrands() {

    return await httpRequest.get('truck/list-brands')
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

/**
 * Проверить актуальность данных
 */
async function checkVerifiedInfo(id, driverId) {

    return await httpRequest.get(`truck/${id}/${driverId}/check-verified-info`)
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

/**
 * Сохранить отредактированные данные
 */
async function saveEditingInfo(id, data) {

    const formData = new FormData();

    for (let fieldName in data) {
        if (data.hasOwnProperty(fieldName)) {
            formData.append(fieldName, data[fieldName]);
        }
    }

    return await httpRequest.post(`truck/${id}/edit`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

/**
 * Сохранить отредактированные данные без проверок актуальности
 */
async function edit(id, data) {

    const formData = new FormData();

    for (let fieldName in data) {
        if (data.hasOwnProperty(fieldName)) {
            formData.append(fieldName, data[fieldName]);
        }
    }

    return await httpRequest.post(`truck/${id}/edit-not-actual`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

/**
 * Получить список авто с поиском по имени
 */
async function getListByName(name) {

    return await httpRequest.get(`truck/get-list-by-name?Name=${name}`)
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getDocsArchive({id}) {
    return await httpRequest.get(`truck/download-files-archive?id=${id}`).then((response) => {
        if (response.status === 200) {
            return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function changeVINStatus(id, vinStatus) {
    return await httpRequest.get(`truck/change-vin?id=${id}&vin=${vinStatus ? true : ''}`).then((response) => {
        if (response.status === 200) {
            return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function hide(id) {
    return await httpRequest.get(`truck/hide?id=${id}`).then((response) => {
        if (response.status === 200) {
            return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
    });
}