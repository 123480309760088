import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import {BrowserRouter, Router} from "react-router-dom";

const AppWrapper = () => {
    return (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    )
}

ReactDOM.render(<AppWrapper />, document.getElementById('root'));
