import React, {useEffect,useState} from 'react';
import {notification,Row,Col,Button,Upload,Icon} from 'antd';
import {companyService} from '../../../../services';
import FieldBlock from '../../../field-block';
import DividerBlock from "../../../divider-block";

export default function EditKazakhstanLegalEntityDocs({
                                                          docFields,
                                                          setDocFields,
                                                          fieldsErrors,
                                                          isProprietor,
                                                          isNeedPowerOfAttorneyForAPerson
}) {
    const getFieldErrors = (fieldName) => {
        return typeof fieldsErrors[fieldName] !== "undefined"
            ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
                return fieldErrors + "; " + error;
            })
            : null;
    };    

    const getUploaderClass = (name, multiple = true) => {
        return {
            multiple: multiple,
            showUploadList: true,
            onRemove: (file) => {
                setDocFields({...docFields, [name]: null});
            },
            beforeUpload: (file, fileList) => {
                if (multiple) {
                    if (docFields[name]) {
                        setDocFields({...docFields, [name]: docFields[name].concat(fileList)});
                    } else {
                        setDocFields({...docFields, [name]: fileList});
                    }
                } else {
                    setDocFields({...docFields, [name]: file});
                }
                return false;
            },
            fileList: docFields[name],
            listType: 'picture',
        };
    };

    return (
        <Row gutter={20}>
            <DividerBlock label="Загрузите сканы документов"/>
            {isProprietor ? (
               null
            ) : (
                <>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="protocolOfTheGeneralMeetingOfParticipants" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.protocolOfTheGeneralMeetingOfParticipants} fileList={docFields.protocolOfTheGeneralMeetingOfParticipants} className="single-btn-upload" {...getUploaderClass('protocolOfTheGeneralMeetingOfParticipants', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Протокол общего собрания участников юридического лица с решением об избрании руководителя
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="proofOfOwnership" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.proofOfOwnership} fileList={docFields.proofOfOwnership} className="single-btn-upload" {...getUploaderClass('proofOfOwnership', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Документы, подтверждающие право собственности или иные права на офисные помещения, в которых контрагент осуществляет деятельность
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="charterWithAllAmendments" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.charterWithAllAmendments} fileList={docFields.charterWithAllAmendments} className="single-btn-upload" {...getUploaderClass('charterWithAllAmendments', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Устав
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                </>
            )}

            {isNeedPowerOfAttorneyForAPerson ? (
                <>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="powerOfAttorneyForAPerson" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.powerOfAttorneyForAPerson} fileList={docFields.powerOfAttorneyForAPerson} className="single-btn-upload" {...getUploaderClass('powerOfAttorneyForAPerson', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Доверенность на лицо, подтверждающая полномочия лица, подписывающего договор
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                </>
            ) : null}

            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="certificateOfStateRegistration" getErrors={getFieldErrors}>
                    <Upload defaultFileList={docFields.certificateOfStateRegistration} fileList={docFields.certificateOfStateRegistration} className="single-btn-upload" {...getUploaderClass('certificateOfStateRegistration', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Свидетельство о государственной регистрации юридического лица
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="partnerCard" getErrors={getFieldErrors}>
                    <Upload defaultFileList={docFields.partnerCard} fileList={docFields.partnerCard} className="single-btn-upload" {...getUploaderClass('partnerCard', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Карточка контрагента – сведения о банковских реквизитах, номера телефонов, адреса офисов
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="residenceCertificate" getErrors={getFieldErrors}>
                    <Upload defaultFileList={docFields.residenceCertificate} fileList={docFields.residenceCertificate} className="single-btn-upload" {...getUploaderClass('residenceCertificate', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Справка о постоянном местонахождении Residence Certificate (в целях избежания двойного налогообложения)
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="copyOfThePassport" getErrors={getFieldErrors}>
                    <Upload defaultFileList={docFields.copyOfThePassport} fileList={docFields.copyOfThePassport} className="single-btn-upload" {...getUploaderClass('copyOfThePassport', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Удостоверение личности (две стороны)
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
        </Row>
    );
}
