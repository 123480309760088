import React, {useState, useEffect} from 'react';
import {Table, Col} from 'antd';

import './log-users-actions.css';
import Spinner from "../../../spinner";
import {logsServices} from "../../../../services/logs.services";
import moment from "moment";
import {tableHelper} from "../../../../helpers";

export default function LogUsersActions() {

    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [logsData, setLogsData] = useState([]);
    const [errorMsg, setErrorMsg] = useState(null);


    useEffect(() => {

        if (loading === false){
            setLoading(true);
        }

    }, []);

    const columns = [
        {
            title: 'Дата и время',
            dataIndex: 'datetime',
            key: 'datetime',
            width: '20%',
            ...tableHelper.getColumnSearchProps('datetime', searchText, setSearchText),
            sorter: (a, b) => {
                return a.datetime_obj.unix() - b.datetime_obj.unix();
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Пользователь',
            dataIndex: 'user_fio',
            key: 'user_fio',
            width: '16%',
            ...tableHelper.getColumnSearchProps('user_fio', searchText, setSearchText),
        },
        {
            title: 'Роль',
            dataIndex: 'user_role',
            key: 'user_role',
            width: '16%',
            ...tableHelper.getColumnSearchProps('user_role', searchText, setSearchText),
        },
        {
            title: 'IP',
            dataIndex: 'ip_address',
            key: 'ip_address',
            width: '16%',
            ...tableHelper.getColumnSearchProps('ip_address', searchText, setSearchText),
        },
        {
            title: 'Действие',
            dataIndex: 'message',
            key: 'message',
            width: '16%',
            ...tableHelper.getColumnSearchProps('message', searchText, setSearchText),
        },
        {
            title: 'Ключевые данные действия',
            dataIndex: 'actions_details',
            key: 'actions_details',
            width: '16%',
            ...tableHelper.getColumnSearchProps('actions_details', searchText, setSearchText),
        }
    ];

    if (loading) {
        logsServices.getLogsUserActions()
            .then((resLogsData) => {

                const logsData = resLogsData.map((item, idx) => {
                    return {
                        id: idx + 1,
                        datetime: moment(item.dateTime).format("D.MM.YYYY HH:mm:ss"),
                        datetime_obj: moment(item.dateTime),
                        message: item.message,
                        ip_address: (item.ipAddress !== null) ? item.ipAddress : '-',
                        user: (typeof item.user !== "undefined") ? `${item.user.fio} (${item.user.role})` : null,
                        user_fio: (typeof item.user !== "undefined") ? item.user.fio : null,
                        user_role: (typeof item.user !== "undefined") ? item.user.role : null,
                        actions_details: JSON.stringify(item.ids)
                    };
                });

                setLoading(false);
                setLogsData(logsData);

            }).catch((errorMsg) => {
                setLoading(false);
                setErrorMsg(errorMsg);
            });
    }

    if (errorMsg) {
        return (<div className="content-error-block">{errorMsg}</div>);
    }

    return (
        <React.Fragment>
            <h1 className="content-header">Логирование действий пользователей</h1>
            <Col span={24}>
                <Spinner loading={loading} label={null}>
                    <Table
                        style={{minWidth: '700px'}}
                        columns={columns}
                        dataSource={logsData}
                        locale={{emptyText:"Логи отсутствуют"}}
                        rowKey="id"
                        size="small"
                    />
                </Spinner>
            </Col>
        </React.Fragment>
    );
}