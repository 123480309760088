import React, { useEffect, useState } from "react";
import Spinner from "../../../spinner";
import {Button, Col, Table, Row, Popconfirm} from "antd";
import { tableHelper } from "../../../../helpers";
import {consignorService} from "../../../../services/consignor.services";
import ModalCreateShipperCompany from "../../../modals/create-shipper-company";
import {useHistory} from "react-router-dom";

export default function ShippersCompanies({ consignorSettings }) {
  const [loading, setLoading] = useState(false);
  const [isNeedShipperCompanies, setIsNeedUpdateEntities] = useState(false);
  const [showModalCreateShipperCompany, setShowModalCreateShipperCompany] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [shipperCompaniesData, setShipperCompaniesData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateEntities(true);
    }
  }, [isNeedShipperCompanies]);

  if (isNeedShipperCompanies) {
    consignorService
      .getShipperCompaniesList()
      .then((response) => {
        let reindexedTableData = [];
        response.forEach((item) => {
          reindexedTableData.push({
            name: item.name,
            id: item.id,
          });
        });

        setShipperCompaniesData(reindexedTableData);
        setIsNeedUpdateEntities(false);
        setLoading(false);
      })
      .catch((errorMsg) => {
        setIsNeedUpdateEntities(false);
        setErrorMsg(errorMsg);
        setLoading(false);
      });
  }

  if (errorMsg) {
    return <div className="content-error-block">{errorMsg}</div>;
  }

  const handleRedirectToToShipCompSettings = (shipperCompanyID) => {
      history.push('/consignor/shipper-company-settings?shipperCompanyID='+ shipperCompanyID);
  };

  const columns = [
        {
            title: "Название",
            dataIndex: "name",
            key: "name",
            width: "30%",
            ...tableHelper.getColumnSearchProps("name", searchText, setSearchText),
        },
  ];
  return (
    <React.Fragment>
      <h1 className="content-header">Юридические лица</h1>
      <Row gutter={48} style={{ paddingRight: "0" }}>
        {/*<Col span={24} style={{ textAlign: "right", height: "40px", marginBottom: "24px" }}>*/}
        {/*  {!consignorSettings.isVital && <Button*/}
        {/*      type="primary"*/}
        {/*      htmlType="button"*/}
        {/*      onClick={() => {*/}
        {/*        setShowModalCreateShipperCompany(true);*/}
        {/*      }}*/}
        {/*      style={{backgroundColor: "#1CAA53", border: "none", height: "100%"}}*/}
        {/*  >*/}
        {/*    Добавить юр. лицо*/}
        {/*  </Button>}*/}
        {/*  <ModalCreateShipperCompany isShow={showModalCreateShipperCompany} setIsShow={setShowModalCreateShipperCompany} setIsNeedUpdateList={setIsNeedUpdateEntities} />*/}
        {/*</Col>*/}
      </Row>
      <Col span={24}>
        <Spinner loading={loading} tip="Загрузка...">
          <Table
            style={{ minWidth: "700px" }}
            columns={columns}
            dataSource={shipperCompaniesData}
            locale={{ emptyText: "Данные отсутствуют" }}
            rowKey="name"
            size="small"
            pagination={{
              pageSize: 50,
            }}
          />
        </Spinner>
      </Col>
    </React.Fragment>
  );
}
