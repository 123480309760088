import React, {useEffect, useState} from "react";
import {companyService, systemService} from "../../../services";
import {Button, Col, Modal, notification, Row} from "antd";
import SelectField from "../../select-field";
import Spinner from "../../spinner";


export default function ModalChangeCompanyType({isShow, setIsShow, orderId, setIsNeedUpdate}) {
  const [loading, setLoading] = useState(false);
  const [companyTypesList, setCompanyTypesList] = useState([]);
  const [companyType, setCompanyType] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (isShow) {
      setLoading(true);
      systemService.getCompanyTypeList()
        .then(res => {
          setCompanyTypesList(res);
          setLoading(false);
        });
    }
  }, [isShow]);

  const submitBtnStyle = {
    width: "200px",
    height: "43px",
    background: "#1CAA53",
    position: "static",
    borderRadius: "10px",
    color: "white",
    borderColor: "transparent",
  };

  const onSignUp = () => {
    setIsDisabled(true);
    const typeName = companyTypesList.find((item) => item.Id === companyType);
    companyService.changeTypeByOrderId(orderId, typeName.Name)
      .then((response) => {
        notification.success({
          message: "Изменение типа компании", description: response.message,
        });
        setIsShow(false);
        setIsNeedUpdate(true);
      })
      .catch((err) => {
        notification.error({
          message: "Изменение типа компании", description: err,
        });
      })
      .finally(() => {
        setIsDisabled(false);
      })
  }

  return (
    <Modal
      title={<div>Изменить тип компании</div>}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
      }}
      forceRender={true}
      footer={
        <Button htmlType="submit" key="submit" type="primary"
                style={submitBtnStyle}
                onClick={() => onSignUp()}
                disabled={companyType === "" || isDisabled}
        >Отправить</Button>
      }
    >
      <Spinner loading={loading} tip="Загрузка...">
        <Row>
          <Col lg={8} sm={24}>
            <SelectField
              title="Тип компании"
              name="type"
              selectedValue={companyType}
              values={companyTypesList}
              onChange={(name) => {
                setCompanyType(name);
              }}
              getErrors={() => {
              }}
              placeholder="Укажите тип компании"
              notFoundText={null}
              showArrow={true}
              style={{width: "100%"}}
            />
          </Col>
        </Row>
      </Spinner>
    </Modal>
  )
}
