import React, {useEffect, useState} from "react";
import {Button, Col, notification, Popconfirm, Row, Table} from "antd";
import Spinner from "../../../../spinner";
import {consignorService} from "../../../../../services";
import ModalCreateDriver from "../../../../modals/create-driver";
import AddParamOnContractCreate from "../../../../modals/add-param-on-contract-create";

export default function AddParamsOnContract({ shipperCompanyID }) {
    const [loading, setLoading] = useState(true);
    const [needUpdate, setNeedUpdate] = useState(true);

    const [isShowAddParamModal, setIsShowAddParamModal] = useState(false);

    const [addParamsList, setAddParamsList] = useState([]);

    const paramTypes = {
        doc: "Документ",
        param: "Значение",
    };

    useEffect(() => {
        if (needUpdate && shipperCompanyID) {
            setLoading(true);

            consignorService
                .getShipperCompanyAddPramsOnContractList(shipperCompanyID)
                .then((response) => {
                    setAddParamsList(response);
                    setLoading(false);
                    setNeedUpdate(false);
                });
        }
    }, [needUpdate]);

    const handleDeleteParam = (paramName) => {
        setLoading(true);

        consignorService
            .removeShipperCompanyAddParam(shipperCompanyID, paramName)
            .then((result) => {
                notification.success({
                    message: "Удаление параметра",
                    description: result.message,
                });

                setNeedUpdate(true);
            })
            .catch((err) => {
                notification.error({
                    message: "Удаление параметра",
                    description: err,
                });

                setLoading(false)
            });
    }

    const columns = [
        {
            title: 'Название',
            dataIndex: 'Alias',
            key: 'alias',
            width: '35%',
            sorter: (a, b) => {
                return a.surname.localeCompare(b.surname);
            },
        },
        {
            title: 'Тип',
            dataIndex: 'Type',
            key: 'type',
            width: '12%',
            render: (data) => {
                return paramTypes[data];
            },
            sorter: (a, b) => {
                return a.firstName.localeCompare(b.firstName);
            },
        },
        {
            title: '',
            key: 'questionnaire',
            render: (text, record) => {
                return (
                    <React.Fragment>
                        <Popconfirm
                            title='Вы уверены?'
                            okText='Да'
                            cancelText='Нет'
                            onConfirm={() => {handleDeleteParam(record.Name)}}
                        >
                            <Button
                                type="primary"
                                htmlType="button"
                                size="small"
                                className="small-button typical-btn-red"
                            >
                                удалить
                            </Button>
                        </Popconfirm>
                    </React.Fragment >
                );
            }
        },
    ];

    let bigButtonStyle = { width: '190px', height: '40px', background: '#1CAA53', position: 'static', borderRadius: '10px', float: 'right', borderColor: "#1CAA53" };

    return (
        <React.Fragment>
            <Row style={{ marginBottom: "25" }}>
                <Col className={"content-header"} style={{ marginBottom: "0px" }} span={12}>Дополнительные документы для перевозчиков</Col>
                <Col span={8} style={{ marginBottom: "0px" }} offset={4}>
                    <Button type="primary"
                            htmlType="button"
                            style={bigButtonStyle}
                            onClick={() => {
                                setIsShowAddParamModal(true);
                            }}>

                        Добавить документ
                    </Button>
                    <AddParamOnContractCreate isShow={isShowAddParamModal} setIsShow={setIsShowAddParamModal} setNeedUpdate={setNeedUpdate} shipperCompanyID={shipperCompanyID} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Spinner loading={loading} tip="Загрузка...">
                        <Table
                            style={{ minWidth: "700px" }}
                            columns={columns}
                            dataSource={addParamsList}
                            locale={{ emptyText: "Данные отсутствуют" }}
                            rowKey="name"
                            size="small"
                            pagination={{
                                pageSize: 50,
                            }}
                        />
                    </Spinner>
                </Col>
            </Row>
        </React.Fragment>
    );
}