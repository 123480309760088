import React, { useEffect, useState } from "react";
import { Button, Col, Modal, notification, Row } from "antd";
import InputField from "../../input-field";
import { companyService } from "../../../services";

export default function ModalEditEmployee({ isShow, onCloseModal, employee }) {
  const [loadingContentModal, setLoadingContentModal] = useState(false);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [oldPhone, setOldPhone] = useState(employee.phone);
  const [oldPhoneCode, setOldPhoneCode] = useState(null);
  const [newPhone, setNewPhone] = useState(null);
  const [newPhoneCode, setNewPhoneCode] = useState(null);
  const [firstName, setFirstName] = useState(employee.firstName);
  const [secondName, setSecondName] = useState(employee.secondName);
  const [codeFieldVisible, setCodeFieldVisible] = useState(false);
  const [tokensData, setTokensData] = useState(null);

  let editNumberButton = {height: '43px', background: '#2b3f63', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent", fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '500', lineHeight: '20px' };
  let blueButton = { width: '200px', height: '43px', background: '#2b3f63', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent", fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '500', lineHeight: '20px' };
  let activeButton = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent", fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '500', lineHeight: '20px' };
  let redButton = { width: '200px', height: '43px', background: 'red', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent", fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '500', lineHeight: '20px' };

  useEffect(() => {
    if (isShow && !loadingContentModal) {
      setLoadingContentModal(true);
    }
  }, [isShow]);

  const getFieldErrors = (nameField) => {
    return fieldsErrors && typeof fieldsErrors[nameField] !== "undefined" ? fieldsErrors[nameField][0] : null;
  };

  const handleRecieveCodes = (e) => {
    companyService
      .getSmsVerification({ employeeId: employee.id, newPhone })
      .then((data) => {
        if (data.status === "error") {
          notification.error({
            message: "Получение кода подтверждения",
            description: data.message,
          });
        } else {
          setCodeFieldVisible(true);
          setTokensData(data);
        }
        if (data.errors) {
          setFieldsErrors(data.errors);
        }
      })
      .catch((error) => {
        notification.error({
          message: "Получение кода подтверждения",
          description: error,
        });
      });
  };

  const handleSendCodes = (e) => {
    companyService
      .sendCodes({
        employeeId: employee.id,
        oldPhoneToken: tokensData.oldPhoneToken,
        oldPhoneCode,
        newPhoneToken: tokensData.newPhoneToken,
        newPhoneCode,
        newPhone,
      })
      .then((data) => {
        if (data.status === "error") {
          notification.error({
            message: "Смена номера",
            description: data.message,
          });
        } else {
          notification.success({
            message: "Смена номера",
            description: data.message,
          });
          onCloseModal();
        }
        if (data.errors) {
          setFieldsErrors(data.errors);
        }
      })
      .catch((error) => {
        notification.error({
          message: "Смена номера",
          description: error,
        });
      });
  };

  const handleChangeName = (e) => {
    companyService
      .changeEmployeeName({
        employeeId: employee.id,
        firstName,
        secondName,
      })
      .then((data) => {
        if (data.status === "error") {
          notification.error({
            message: "Смена имени сотрудника",
            description: data.message,
          });
        } else {
          notification.success({
            message: "Смена имени сотрудника",
            description: data.message,
          });
          onCloseModal();
        }

        if (data.errors) {
          setFieldsErrors(data.errors);
        }
      })
      .catch((error) => {
        notification.error({
          message: "Смена имени сотрудника",
          description: error,
        });
      });
  };

  const handleBlockEmployee = (e) => {
    companyService
      .blockEmployee({
        employeeId: employee.id,
      })
      .then((data) => {
        if (data.status === "error") {
          notification.error({
            message: "Блокировка сотрудника",
            description: data.message,
          });
        } else {
          notification.success({
            message: "Блокировка сотрудника",
            description: data.message,
          });
          onCloseModal();
        }
      })
      .catch((error) => {
        notification.error({
          message: "Блокировка сотрудника",
          description: error,
        });
      });
  };

  const handleUnblockEmployee = (e) => {
    companyService
      .unblockEmployee({
        employeeId: employee.id,
      })
      .then((data) => {
        if (data.status === "error") {
          notification.error({
            message: "Разблокировка сотрудника",
            description: data.message,
          });
        } else {
          notification.success({
            message: "Разблокировка сотрудника",
            description: data.message,
          });
          onCloseModal();
        }
      })
      .catch((error) => {
        notification.error({
          message: "Разблокировка сотрудника",
          description: error,
        });
      });
  };

  const handleArchiveEmployee = (e) => {
    companyService
      .archiveEmployee({
        employeeId: employee.id,
      })
      .then((data) => {
        if (data.status === "error") {
          notification.error({
            message: "Архивация сотрудника",
            description: data.message,
          });
        } else {
          notification.success({
            message: "Архивация сотрудника",
            description: data.message,
          });
          onCloseModal();
        }
      })
      .catch((error) => {
        notification.error({
          message: "Архивация сотрудника ",
          description: error,
        });
      });
  };

  return (
    <Modal
      title={employee.name}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={onCloseModal}
      footer={[
        <Button style={blueButton} htmlType="submit" key="archieve" type="primary" onClick={handleArchiveEmployee}>
          Архивировать
        </Button>,
        employee.status === "Активен" ? (
          <Button style={redButton} htmlType="submit" key="block" type="primary" onClick={handleBlockEmployee}>
            Блокировать
          </Button>
        ) : (
          <Button style={activeButton} htmlType="submit" key="block" type="primary" onClick={handleUnblockEmployee}>
            Разблокировать
          </Button>
        ),
      ]}
    >
      <Row gutter={[12, 12]}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <InputField
            name="oldPhone"
            mask="+7(111)111-11-11"
            label="Текущий номер"
            value={oldPhone}
            setValue={setOldPhone}
            getFieldErrors={getFieldErrors}
          />
        </Col>
        <Col>
          {codeFieldVisible ? (
            <InputField name="oldPhoneCode" label="Код" value={oldPhoneCode} setValue={setOldPhoneCode} getFieldErrors={getFieldErrors} />
          ) : null}
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <InputField
            name="newPhone"
            mask="+7(111)111-11-11"
            label="Новый номер"
            value={newPhone}
            setValue={setNewPhone}
            getFieldErrors={getFieldErrors}
          />
        </Col>
        <Col>
          {codeFieldVisible ? (
            <InputField name="newPhoneCode" label="Код" value={newPhoneCode} setValue={setNewPhoneCode} getFieldErrors={getFieldErrors} />
          ) : null}
        </Col>
        <Col lg={12} md={24} sm={24} xs={24} style={{ marginTop: '15px', marginBottom: '15px' }}>
          <Button
            style={editNumberButton}
            htmlType="submit"
            type="primary"
            onClick={handleRecieveCodes}
            key="get-codes"
          >
            Получить коды на смену номера
          </Button>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24} style={{ marginTop: '15px', marginBottom: '15px' }}>
          <Button style={activeButton} htmlType="submit" type="primary" key="change-number" onClick={handleSendCodes}>
            Изменить номер
          </Button>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
          <InputField name="firstName" label="Имя" value={firstName} setValue={setFirstName} getFieldErrors={getFieldErrors} />
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
          <InputField name="secondName" label="Фамилия" value={secondName} setValue={setSecondName} getFieldErrors={getFieldErrors} />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: '15px', marginBottom: '15px' }}>
          <Button style={activeButton} htmlType="submit" key="submit" type="primary" onClick={handleChangeName}>
            Сохранить
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
