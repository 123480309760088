import React, {useEffect, useState} from "react";
import {Button, Col, Modal, notification, Popconfirm, Row} from "antd";
import Spinner from "../../spinner";
import {deliveriesService, userServices} from "../../../services";
import {UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY} from "../../../helpers/user_permission.helper";

export default function ModalShowDriversPhotosByDistCenter({isShow, setIsShow, deliveryID, setNeedUpdate, setStopListUpdate}) {
    const [loading, setLoading] = useState(true);
    const [photos, setPhotos] = useState([]);
    const user = userServices.getUser()

    useEffect(() => {
        if (isShow && deliveryID) {
            deliveriesService
                .getDriverPhotos(deliveryID)
                .then(resp => {
                    setPhotos(resp);
                })
                .catch(err => {
                    notification.error({
                        message: 'Загрузка данных',
                        description: err,
                    });
                })
                .finally(() => setLoading(false));
        }
    }, [isShow, deliveryID]);

    const handleDeletePhotos = () => {
        setLoading(true);
        deliveriesService
            .deleteDriverPhotos(deliveryID)
            .then(resp => {
                notification.success({
                    message: 'Удаление фото',
                    description: resp.message,
                });

                setIsShow(false);
                setNeedUpdate(true);
                setStopListUpdate(false);
            })
            .catch(err => {
                notification.error({
                    message: 'Удаление фото',
                    description: err,
                });
            })
            .finally(() => setLoading(false));
    }

    const bigButtonStyle = { width: '116px', height: '40px', background: '#1CAA53', borderRadius: '10px', borderColor: "#1CAA53" };
    const imgStyle = { width: "115px", height: "125px", border: "1px solid #1CAA53", borderRadius: "10px", display: "block", marginBottom: "15px" }

    return (
        <Modal
            title={'Фото водителя от РЦ'}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
            }}
            footer={
                user.isAccessTo(UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY) ?
                <Popconfirm
                    title="Вы уверены?"
                    cancelText="Нет"
                    okText="Да"
                    onConfirm={() => {handleDeletePhotos()}}
                >
                    <Button
                        htmlType="submit" key="submit" className="action-button typical-btn-red" onClick={() => {}}
                    >
                        Удалить фотографии
                    </Button>
                </Popconfirm> : null
            }
        >
            <Spinner loading={loading} tip="Загрузка...">
                <Row>
                    {photos.map((img) => {
                        return (
                        <Col lg={3} md={3} sm={3} xs={12} className="label dispatch" style={{ marginBottom: '10px' }}>
                            <a download href={img.link} target='_blank' >
                                <img src={img.link} alt="img" style={imgStyle}></img>
                            </a>
                            <a href={img.link} target="_blank" download>
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    size="small"
                                    style={bigButtonStyle}
                                    onClick={() => {}}
                                >
                                    Показать оригинал
                                </Button>
                            </a>
                        </Col>);
                    })}
                </Row>
            </Spinner>
        </Modal>
    );
}
