import React, {useState} from 'react';
import {Col, Row, DatePicker, Checkbox} from 'antd';
import InputField from '../../../../input-field';
import DividerBlock from "../../../../divider-block";
import SelectField from "../../../../select-field";
import FieldBlock from "../../../../field-block";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import RadioField from "../../../../radio-field";

export default function MainDataFields({
                                           stepFields, setFields, getFieldErrors, isProprietor,
                                           edit = false, defAdditionalRequisitesType = 'fill_registration_number'
}) {
    const [notRegisteredATI, setNotRegisteredATI] = useState(false);
    const [additionalRequisitesType, setAdditionalRequisitesType] = useState(defAdditionalRequisitesType);

    let dividerStyle = {
        margin: "15px 0px 45px 0px",
        display: "block",
        clear: "both",
        width: "100%",
        minWidth: "100%",
        height: "1px",
    };

    const firstToUpper = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const renderAdditionalRequisites = () => {
        if (!isProprietor) {
            return null;
        }

        const addParamsTypes = [
            {id: 'fill_registration_number', name: 'Уведомление о начале деятельности'},
            {id: 'fill_certificate', name: 'Свидетельство о гос регистрации'},
        ];

        const radio = (
            <RadioField
                values={addParamsTypes}
                onChange={(value) => setAdditionalRequisitesType(value.target.value)}
                selectedValue={additionalRequisitesType}
                getErrors={getFieldErrors}
            />
        );

        let additionalRequisitesForm = null;
        if (additionalRequisitesType === 'fill_registration_number') {
            additionalRequisitesForm = (
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="registrationNumber"
                        title="Номер"
                        label="Номер"
                        required={true}
                        value={stepFields.registrationNumber}
                        style={{marginLeft: '0px'}}
                        setValue={(value) => {
                            setFields({ ...stepFields, registrationNumber: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
            );
        } else if (additionalRequisitesType === 'fill_certificate') {
            additionalRequisitesForm = (
               <>
                   <Col lg={6} md={6} sm={24} xs={24}>
                       <InputField
                           name="certificateSerial"
                           title="Серия"
                           label="Серия"
                           required={true}
                           value={stepFields.certificateSerial}
                           setValue={(value) => {
                               setFields({ ...stepFields, certificateSerial: value });
                           }}
                           getFieldErrors={getFieldErrors}
                       />
                   </Col>
                   <Col lg={8} md={8} sm={24} xs={24}>
                       <InputField
                           name="certificateNumber"
                           title="Номер"
                           label="Номер"
                           required={true}
                           value={stepFields.certificateNumber}
                           setValue={(value) => {
                               setFields({ ...stepFields, certificateNumber: value });
                           }}
                           getFieldErrors={getFieldErrors}
                       />
                   </Col>
               </>
            );
        }

        return (
            <Row gutter={[{ xs: 0, sm: 0, md: 16},16]}>
                <Col lg={24} md={24} sm={24} xs={24}>
                    {radio}
                </Col>
                {additionalRequisitesForm}
            </Row>
        );
    };

    return (
        <>
            <Row gutter={[{ xs: 0, sm: 0, md: 16},16]}>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="name"
                        title="Название"
                        label="Название"
                        required={true}
                        value={stepFields.name}
                        setValue={(value) => {
                            setFields({ ...stepFields, name: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
            </Row>
            <Row gutter={[{ xs: 0, sm: 0, md: 16},16]}>
                {isProprietor ? (
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <InputField
                            name="iin"
                            title="ИИН"
                            label="ИИН"
                            required={true}
                            value={stepFields.iin}
                            setValue={(value) => {
                                setFields({ ...stepFields, iin: value });
                            }}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                ) : (
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <InputField
                            name="bin"
                            title="БИН"
                            label="БИН"
                            required={true}
                            value={stepFields.bin}
                            setValue={(value) => {
                                setFields({ ...stepFields, bin: value });
                            }}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                )}
                <Col lg={8} sm={24} xs={24}>
                    <FieldBlock name="dateRegistration" title="Дата регистрации юр. лица" getErrors={getFieldErrors}>
                        <DatePicker
                            style={{ width: "100%" }}
                            value={stepFields.dateRegistration}
                            defaultValue={stepFields.dateRegistration}
                            format="DD.MM.YYYY"
                            size="large"
                            name="dateRegistration"
                            locale={localeDatePicker}
                            onChange={value => setFields({...stepFields, dateRegistration:  value})}
                        />
                    </FieldBlock>
                </Col>
            </Row>

            <span className="divider-label">Контактные данные подписанта</span>
            <DividerBlock style={dividerStyle} />

            <Row gutter={[{ xs: 0, sm: 0, md: 16}, 24]}>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="firstName"
                        title="Имя"
                        label="Имя"
                        required={true}
                        value={stepFields.firstName}
                        setValue={(value) => {
                            setFields({ ...stepFields, firstName: firstToUpper(value) });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="secondName"
                        title="Фамилия"
                        label="Фамилия"
                        required={true}
                        value={stepFields.secondName}
                        setValue={(value) => {
                            setFields({ ...stepFields, secondName: firstToUpper(value) });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="patronymic"
                        title="Отчество"
                        label="Отчество"
                        required={true}
                        value={stepFields.patronymic}
                        setValue={(value) => {
                            setFields({ ...stepFields, patronymic: firstToUpper(value) });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
            </Row>
            <Row gutter={[{ xs: 0, sm: 0, md: 16}, 24]}>
                {isProprietor ? (
                    <>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <InputField
                                name="legalReason"
                                isDisabled={true}
                                label="Основание действий"
                                value={" о регистрации ИП"}
                                setValue={(value) =>
                                    setFields({ ...stepFields, legalReason: value })
                                }
                                getFieldErrors={getFieldErrors}
                            />
                        </Col>
                    </>
                ) : (
                    <>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <InputField
                                name="position"
                                label="Должность"
                                value={stepFields.position}
                                setValue={(value) =>
                                    setFields({ ...stepFields, position: firstToUpper(value) })
                                }
                                getFieldErrors={getFieldErrors}
                            />
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <SelectField
                                title="Основание действий"
                                name="legalReason"
                                selectedValue={stepFields.legalReason}
                                values={stepFields.legalReasonsList}
                                onChange={(value) => {
                                    setFields({
                                        ...stepFields,
                                        legalReason: value,
                                    });
                                }}
                                getErrors={getFieldErrors}
                                placeholder="Укажите основание действий"
                                notFoundText={null}
                                showArrow={true}
                                style={{ width: "100%" }}
                            />
                        </Col>
                    </>
                )}
            </Row>

            <span className="divider-label">Банк</span>
            <DividerBlock style={dividerStyle} />

            <Row gutter={[{ xs: 0, sm: 0, md: 16}, 24]}>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="bankName"
                        title="Название банка"
                        label="Название банка"
                        required={true}
                        value={stepFields.bankName}
                        setValue={(value) => {
                            setFields({ ...stepFields, bankName: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="rs"
                        title="Номер счёта"
                        label="Номер счёта"
                        required={true}
                        value={stepFields.rs}
                        setValue={(value) => {
                            setFields({ ...stepFields, rs: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="bic"
                        label="БИК"
                        title="БИК"
                        required={true}
                        value={stepFields.bic}
                        setValue={(value) => {
                            setFields({ ...stepFields, bic: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="iik"
                        label="ИИК"
                        title="ИИК"
                        required={true}
                        value={stepFields.iik}
                        setValue={(value) => {
                            setFields({ ...stepFields, iik: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>
                    <InputField
                        name="bankLegalAddress"
                        label="Юридический адрес"
                        title="Юридический адрес"
                        required={true}
                        value={stepFields.bankLegalAddress}
                        setValue={(value) => {
                            setFields({ ...stepFields, bankLegalAddress: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
            </Row>

            <span className="divider-label">Банк-корреспондент</span>
            <DividerBlock style={dividerStyle} />

            <Row gutter={[{ xs: 0, sm: 0, md: 16}, 24]}>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="correspondentBankName"
                        title="Название банка"
                        label="Название банка"
                        required={true}
                        value={stepFields.correspondentBankName}
                        setValue={(value) => {
                            setFields({ ...stepFields, correspondentBankName: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="correspondentBankInn"
                        title="ИНН"
                        label="ИНН"
                        required={true}
                        value={stepFields.correspondentBankInn}
                        setValue={(value) => {
                            setFields({ ...stepFields, correspondentBankInn: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="correspondentBankBic"
                        label="БИК"
                        title="БИК"
                        required={true}
                        value={stepFields.correspondentBankBic}
                        setValue={(value) => {
                            setFields({ ...stepFields, correspondentBankBic: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="correspondentBankAccount"
                        label="Кор/сч"
                        title="Кор/сч"
                        required={true}
                        value={stepFields.correspondentBankAccount}
                        setValue={(value) => {
                            setFields({ ...stepFields, correspondentBankAccount: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="correspondentBankCheckingAccount"
                        label="Р/с"
                        title="Р/с"
                        required={true}
                        value={stepFields.correspondentBankCheckingAccount}
                        setValue={(value) => {
                            setFields({ ...stepFields, correspondentBankCheckingAccount: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>
                    <InputField
                        name="correspondentBankLegalAddress"
                        label="Юр. адрес"
                        title="Юр. адрес"
                        required={true}
                        value={stepFields.correspondentBankLegalAddress}
                        setValue={(value) => {
                            setFields({ ...stepFields, correspondentBankLegalAddress: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
            </Row>

            <span className="divider-label">Реквизиты</span>
            <DividerBlock style={dividerStyle} />

            <Row gutter={[{ xs: 0, sm: 0, md: 16}, 24]}>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="phone"
                        title="Моб. номер"
                        label="Моб. номер"
                        required={true}
                        value={stepFields.phone}
                        setValue={(value) => {
                            setFields({ ...stepFields, phone: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="email"
                        label="Email"
                        required={true}
                        value={stepFields.email}
                        setValue={(value) => {
                            setFields({ ...stepFields, email: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <InputField
                        name="atiCode"
                        label="Код АТИ"
                        isDisabled={notRegisteredATI}
                        required={true}
                        value={stepFields.atiCode}
                        setValue={(value) => {
                            setFields({ ...stepFields, atiCode: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                    <Checkbox
                        name="notRegisteredATI"
                        checked={notRegisteredATI}
                        onChange={(e) => {
                            setFields({
                                ...stepFields,
                                atiCode: e.target.checked ? "Без АТИ" : "",
                            });
                            setNotRegisteredATI(e.target.checked);
                        }}
                        style={{ fontSize: "10pt", paddingTop: "15px" }}
                    >
                        Не зарегистрирован на АТИ
                    </Checkbox>
                </Col>
            </Row>
          {renderAdditionalRequisites()}
        </>
    );
}
