import React from "react";
import {Select, Form, Input} from "antd";
import MaskedInput from "antd-mask-input";

export default function PhoneField({
  title,
  countryFieldName,
  selectedCountryID,
  phoneFieldName,
  phoneNumber = '',
  onChangePhone,
  onChangeCountry,
  getErrors,
  notFoundText = "Отсутствуют значения",
  style = { height: "50px" },
  mode = "default",
  showSearch = true,
  getFieldDisabled,
  showArrow = true,
  isDisabled = false,
  keyNum = null,
  className,
  phoneFieldWidth = "80%",
  countryFieldWidth = "20%",
}) {
  const countryFieldErrors = keyNum !== null ? getErrors(countryFieldName, keyNum) : getErrors(countryFieldName);
  const phoneFieldErrors = keyNum !== null ? getErrors(phoneFieldName, keyNum) : getErrors(phoneFieldName);
  const Component = Input;

  const fieldTitle = title ? (
    <div className="form-field-label">{title}</div>
  ) : null;

  if (notFoundText !== null) {
    notFoundText = <p className="b-not-fount-text">{notFoundText}</p>;
  }

  return (
    <React.Fragment>
      {fieldTitle}<br />
      <Select
          showSearch={showSearch}
          mode={mode}
          notFoundContent={notFoundText}
          value={selectedCountryID}
          onChange={onChangeCountry}
          optionFilterProp="children"
          size={"large"}
          showArrow={showArrow}
          disabled={isDisabled}
          className={className}
          style={{width: countryFieldWidth, float:"left", fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "normal", fontSize: "13px", lineHeight: "12px", color: "black" }}
      >
        <Select.Option key={1} value={1}>{'+7'} <img src="https://dota2.ru/img/flags/ru.png" alt="РФ" /></Select.Option>
        <Select.Option key={3} value={3}>{'+375'} <img src="https://dota2.ru/img/flags/by.png" alt="РБ" /></Select.Option>
        <Select.Option key={6} value={6}>{'+7'} <img src="https://dota2.ru/img/flags/kz.png" alt="КЗ" /></Select.Option>
      </Select>
      <Form.Item
          style={{width: phoneFieldWidth, float: "left"}}
          help={[countryFieldErrors, ' ', phoneFieldErrors]}
          validateStatus={(countryFieldErrors || phoneFieldErrors) ? 'error' : null}
      >
        <Component type="text" defaultValue={phoneNumber} value={phoneNumber} name={'name'} disabled={isDisabled} onChange={onChangePhone} style={style} size="large" />
      </Form.Item>
    </React.Fragment>
  );
}
