import React, {useEffect, useState} from "react";
import {systemService, userServices} from "../../../services";
import {notification} from "antd";
import Spinner from "../../spinner";
import {text} from "@fortawesome/fontawesome-svg-core";

export default function TermsOfUse() {
    const user = userServices.getUser();

    const [termsOfUse, setTermsOfUse] = useState({});
    const [type, setType] = useState(null)
    const [readTermsOfUse, setReadTermsOfUse] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const type = queryParams.get('type');
        const countryID = queryParams.get('countryId');

        setType(type);

        if (type && countryID) {
            systemService.getTermsOfUse(type, countryID)
                .then((resp) => {
                    setTermsOfUse(resp);
                })
                .catch((err) => {
                    notification.error({
                        description: "Ошибка",
                        message: err
                    });
                })
        }
    }, []);

    const handleAgree = () => {
        userServices.readTermsOfUse()
            .then(() => {
                userServices.updateUserInfo()
                    .then(() => {
                        window.location.replace("/");
                    })
            })
            .catch((err) => {
                notification.error({
                    message: err,
                    description: "Ошибка"
                })
            })
    };

    return (
        <Spinner loading={(!termsOfUse.Text)}>
            {termsOfUse.Text}
            {(user.loggedIn && type === "user") && (<button onClick={handleAgree} className="btn typical-btn typical-btn-green">
                Принять
            </button>)}
        </Spinner>
    );
}