import {useState, useEffect, useContext} from "react";
import {AppContext} from "../contexts/app-context";
import {deliveriesConfig} from "../config";
import axios from "axios";
import {deliveriesService} from "../services";
import {notificationServices} from "../services/notification.services"


export function useFetchNotification(
    updateEnabled = false,
    intervalUpdatedSec = deliveriesConfig.secondForUpdate,
    requestTimeout = 100000
) {
    const appContext = useContext(AppContext);

    const [error, setError] = useState(null);

    const [needUpdate, setNeedUpdate] = useState(true);
    const [notifications, setNotifications] = useState(null);
    let sourceHttpRequest = null;
    let autoUpdateInterval = null;

    if (needUpdate) {

        sourceHttpRequest = axios.CancelToken.source();

        notificationServices.getNotifications()
            .then((response) => {
                setNeedUpdate(false);
                setNotifications(response);
            })
            .catch((errorMsg) => {
                setNeedUpdate(false);
                setError(errorMsg);
                sourceHttpRequest = null;
            })
    }


    // эффект для автоматического обновления списка
    useEffect(() => {
        if(updateEnabled){
            autoUpdateInterval = setInterval(() => {
                if (needUpdate === false){
                    setNeedUpdate(true);
                }
            }, intervalUpdatedSec);


            return () => {
                if (autoUpdateInterval !== null){
                    clearInterval(autoUpdateInterval);
                    autoUpdateInterval = null;
                }
            }
        }
    }, [needUpdate]);

    return [{
        notifications
    }];
}