import React, {useState} from "react";
import ModalNeedApprovedLegalEntity from "../modals/need-approved-legal-entity";
import ModalCreateOrder from "../modals/create-order-delivery";
import {deliveriesService, orderDeliveryService, userServices} from "../../services";
import {Button, notification, Upload} from "antd";
import {FormatPrice, FullDate, ShortDate} from "../tools/delivery-tools";
import ModalAttachDriverOrderDelivery from "../modals/attach-driver-order-delivery";
import moment from "moment";
import {
  DELIVERY_AWAITING_LOADING,
  DELIVERY_BOOKED,
  DELIVERY_ENROUTE,
  DELIVERY_ORDER_FILLED,
  DELIVERY_LOADING, DELIVERY_AGREED, DELIVERY_RESERVED, DELIVERY_ORDER_APPROVED
} from "../../helpers/delivery_status.helper";
import ModalListOrdersDelivery from "../modals/list-orders-delivery";
import ModalRefuseDelivery from "../modals/refuse-delivery";
import {DeliveryHistoryButton} from "./delivery-history-button";
import {CREATE_DELIVERY} from "../../helpers/user_permission.helper";
import ModalChangeLoadingDate from "../modals/change-loading-date";


export function LogistFullButton({
                                   delivery,
                                   permissions,
                                   setNeedUpdate,
                                   setStopListUpdate,
                                   setLoadingDelivItem,
                                   timerEnabled
                                 }) {
  const user = userServices.getUser();

  // if(user.info.userType != "inner") {
  //     return null;
  // }
  if (typeof (delivery.Permissions) == "undefined") {
    return null;
  }
  if (delivery.Permissions.IsAvailableManageDelivery === false) {
    return null;
  }

  const [showRefuseDeliveryModal, setShowRefuseDeliveryModal] = useState(false);
  const [showChangeLoadingDateModal, setShowChangeLoadingDateModal] = useState(false);

  const isCanRefuse = (delivery.Permissions) ? delivery.Permissions.IsAvailableRefuseDelivery && !delivery.Permissions.IsAccessCreatedOrder && !delivery.Permissions.IsAvailableAgreeWithoutDocs : false;

  const onCLickShowRefuseModal = (e) => {
    e.preventDefault();
    setStopListUpdate(true);
    setShowRefuseDeliveryModal(true);
  };

  let btnReservation;
  let btnRefuseDelivery;
  let btnCancelReserve;
  let btnChangeDateLoading;
  let btnDeliveryHistory = (
    <DeliveryHistoryButton delivery={delivery}/>
  );
  let btnCreateAnalogueDelivery = null;

  if (user.isAccessTo(CREATE_DELIVERY)) {
    btnCreateAnalogueDelivery = (
      <a className="action-button typical-btn-blue" href={`/consignor/create-delivery?deliveryID=${delivery.Id}`}>
        Создать подобный рейс
      </a>
    );
  }

  if (isCanRefuse) {
    btnRefuseDelivery = (
      <>
        <a className="action-button typical-btn-red" onClick={onCLickShowRefuseModal}>
          Отменить рейс
        </a>
        <ModalRefuseDelivery
          isShow={showRefuseDeliveryModal}
          setIsShow={setShowRefuseDeliveryModal}
          deliveryId={delivery.Id}
          setLoadingDelivItem={setLoadingDelivItem}
          setNeedUpdate={setNeedUpdate}
          setStopListUpdate={setStopListUpdate}
        />
      </>
    );
  }

  const onClickCancelReservedDelivery = (e) => {
    e.preventDefault();
    setLoadingDelivItem(true);

    deliveriesService
      .cancelReservationDelivery(delivery.Id)
      .then((response) => {
        notification.success({
          message: "Отмена резервирования рейса",
          description: response.message,
        });

        setLoadingDelivItem(false);
        setNeedUpdate(true);
      })
      .catch((error) => {
        notification.error({
          message: "Отмена резервирования рейса",
          description: error,
        });

        setLoadingDelivItem(false);
      });
  };

  const onClickReservedDelivery = (e) => {
    e.preventDefault();
    setLoadingDelivItem(true);

    deliveriesService
      .reserveDelivery(delivery.Id)
      .then((response) => {
        notification.success({
          message: "Резервирование рейса",
          description: response.message,
        });

        setLoadingDelivItem(false);
        setNeedUpdate(true);
      })
      .catch((error) => {
        notification.error({
          message: "Резервирование рейса",
          description: error,
        });

        setLoadingDelivItem(false);
      });
  };

  if (delivery.Permissions.IsAvailableReservation) {
    btnReservation = (
      <a className="action-button typical-btn-blue" onClick={onClickReservedDelivery}>
        <span className="speedometer">В резерв</span>
      </a>
    );
  }

  if (delivery.Status === DELIVERY_RESERVED) {
    btnCancelReserve = (
      <a className="action-button typical-btn-blue" onClick={onClickCancelReservedDelivery}>
        Снять резерв
      </a>
    );
  }

  if ((delivery.Order && (delivery.Order.Status === DELIVERY_ORDER_APPROVED || delivery.Order.Status === DELIVERY_ORDER_FILLED) && delivery.Status === DELIVERY_BOOKED) || (delivery.Status === DELIVERY_LOADING || delivery.Status === DELIVERY_AWAITING_LOADING)) {
    btnChangeDateLoading = (<>
        <button className={`action-button typical-btn-red`} onClick={() => setShowChangeLoadingDateModal(true)}
        >
          Изменить дату погрузки
        </button>
        <ModalChangeLoadingDate id={delivery.Order.Id} isShow={showChangeLoadingDateModal} onCloseModal={setShowChangeLoadingDateModal} date={delivery.LoadingSign? delivery.LoadingSign.DateTime : delivery.Order? moment(delivery.Order.Date).format('DD.MM.YYYY') : ""}/>
      </>
    )
  }

  return (
    <div className="ens">
      {btnReservation}
      {btnCancelReserve}
      {btnDeliveryHistory}
      {btnCreateAnalogueDelivery}
      {btnRefuseDelivery}
      {btnChangeDateLoading}
    </div>
  );
}
