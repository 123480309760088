import {deliveriesService} from "../../../services";
import InputField from "../../input-field";
import React, {useState} from 'react';
import {Button, Col, Modal, notification, Row, Spin} from "antd";
import '../modals.css';

export default function ModalRefuseDelivery({isShow, setIsShow, deliveryId, setLoadingDelivItem, setNeedUpdate, setStopListUpdate}) {

    const [loading, setLoading] = useState(false);
    const [fieldsErrors, setFieldsErrors] = useState([]);

    const [reason, setReason] = useState('');

    let bigButtonStyle = {width: '290px', height: '75px', fontSize:'24px', color: 'white', backgroundColor: '#BE1C1C', border:'none'};

    const onClickRefuseDelivery = () => {
        setLoading(true);

        deliveriesService.refuse(deliveryId, reason)
            .then((response) => {
                if (response.status === 'success') {
                    notification.success({
                        message: "Отмена доставки",
                        description: response.message,
                    });
                    setLoadingDelivItem(false);
                    setNeedUpdate(true);
                    setStopListUpdate(false);
                } else {
                    setFieldsErrors(response.errors);
                }
                setLoading(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Отмена доставки",
                    description: err,
                });
                setLoadingDelivItem(false);
                setLoading(false);
            });
    };

    const getFieldErrors = (nameField) => {
        return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
            ? fieldsErrors[nameField][0]
            : null;
    };

    return (
        <Modal
            title={<span className="ant-modal-title">Отмена рейса</span>}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
                setStopListUpdate(false);
            }}
            footer={[
                <Button htmlType="submit" key="submit" type="primary" onClick={onClickRefuseDelivery} style={bigButtonStyle}>Отменить доставку</Button>,
            ]}
        >
            <Spin spinning={loading} tip="Загрузка...">
                <Row gutter={16} className="add-padding-10 -centered">
                    <Col span={24}>
                        <InputField
                            name="reason" label="Причина"
                            value={reason} setValue={setReason}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}