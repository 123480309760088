import {userServices} from "../../services";
import {Link} from "react-router-dom";
import CreateDeliveryModal from "../modals/create-delivery";
import React, {useState} from "react";
import {
  ACCOUNTANT_LEGAL_ENTITY_APPROVE,
  CREATE_DELIVERY,
  LAWYER_LEGAL_ENTITY_APPROVE, UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY
} from "../../helpers/user_permission.helper";
import ModalListLoadingSchedule from "../modals/list-loading-shedule";

const CreateDeliveryLink = ({user, showModalCreateDelivery, setShowModalCreateDelivery}) => {
  if (user.info.userType !== 'inner') {
    return null;
  }

  if (!user.isAccessTo(CREATE_DELIVERY)) {
    return null;
  }

  return (
    <li>
      <Link
        to={"#"}
        onClick={(e) => {
          e.preventDefault()
          setShowModalCreateDelivery(true);
        }}
      >Создать рейс
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.2923 5.54134H8.70899V8.70801H5.54232V10.2913H8.70899V13.458H10.2923V10.2913H13.459V8.70801H10.2923V5.54134ZM9.50065 1.58301C5.13065 1.58301 1.58398 5.12967 1.58398 9.49968C1.58398 13.8697 5.13065 17.4163 9.50065 17.4163C13.8707 17.4163 17.4173 13.8697 17.4173 9.49968C17.4173 5.12967 13.8707 1.58301 9.50065 1.58301ZM9.50065 15.833C6.0094 15.833 3.16732 12.9909 3.16732 9.49968C3.16732 6.00843 6.0094 3.16634 9.50065 3.16634C12.9919 3.16634 15.834 6.00843 15.834 9.49968C15.834 12.9909 12.9919 15.833 9.50065 15.833Z"
            fill="#1CAA53"/>
        </svg>

      </Link>
      <CreateDeliveryModal
        isShow={showModalCreateDelivery}
        setIsShow={setShowModalCreateDelivery}
      />
    </li>
  )
}

const AutoParkLink = ({user}) => {
  if (user.info.userType !== 'carrier') {
    return null;
  }

  if (user.info.company.type === 'Новая компания') {
    return null;
  }

  return (
    <li>
      <Link to="/company/car-park">Автопарк</Link>
    </li>
  )
}

const LoadingListLink = ({showModalLoadingList, setShowModalLoadingList}) => {
  return (
    <li>
      <Link to={"#"}
            onClick={(e) => {
              e.preventDefault();
              setShowModalLoadingList(true);
            }}>Расписание погрузки</Link>
      <ModalListLoadingSchedule
        onCloseModal={setShowModalLoadingList}
        isShow={showModalLoadingList}
      />
    </li>
  )
}

export default function SubMenu() {
  const [showModalCreateDelivery, setShowModalCreateDelivery] = useState(false);
  const [showModalLoadingList, setShowModalLoadingList] = useState(false)
  const user = userServices.getUser();

  if (user.isGuest() || user.isAccessTo(LAWYER_LEGAL_ENTITY_APPROVE) || user.isAccessTo(ACCOUNTANT_LEGAL_ENTITY_APPROVE)) {
    return null;
  }


  return (
    <ul>
      {Boolean(user.info.role === 'warehouse_worker' || user.isAccessTo(UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY)) &&
        <LoadingListLink user={user} setShowModalLoadingList={setShowModalLoadingList}
                         showModalLoadingList={showModalLoadingList}/>
      }
      <CreateDeliveryLink user={user} setShowModalCreateDelivery={setShowModalCreateDelivery}
                          showModalCreateDelivery={showModalCreateDelivery}/>
      <AutoParkLink user={user}/>

      <li><Link to="/service/instruction">Инструкции и поддержка</Link></li>
    </ul>
  );
}

