import React, {useEffect, useState} from "react";
import {YMaps, Map, ObjectManager, ZoomControl} from "react-yandex-maps";
import {questService} from "../../../services/guest.services";
import {notification} from "antd";

export default function MainMap() {
  const [points, setPoints] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  let markers = [];

  points.forEach((el) => {
    let coord = [el.coordinateLat, el.coordinateLon];

    let point = {
      type: "Feature",
      id: "markerID",
      geometry: {
        type: "Point",
        coordinates: coord
      },
      options: {"iconColor": "#FFDB06", "preset": "islands#blueCircleIcon"}
    }
    markers.push(point)
  })

  const mapState = {
    center: [52.25, 92.15],
    zoom: 4,
    controls: [],
    behaviors: ["disable('scrollZoom')", "drag"]
  };

  useEffect(() => {
    if (!dataLoaded) {
      questService
        .getCoordinates()
        .then((response) => {
          setPoints(response);
        })
        .catch((err) => {
          notification.error({
            message: "Получение статистики",
            description: err,
          });
        });
      setDataLoaded(true);
    }
  }, [dataLoaded]);

  return (
    <YMaps query={{lang: 'ru_RU', apikey: '9133eb49-3a97-4b2d-90be-6b80a97a5036'}}>
      <Map state={mapState} className="map">
        {(markers.length !== 0) &&
          <ObjectManager
            options={{
              clusterize: true,
            }}
            objects={{
              preset: 'islands#yellowDotIcon',
              iconShape: {
                type: 'Rectangle',
                coordinates: [
                  [-70, -60], [70, 0]
                ]
              },
            }}
            clusters={{
              iconColor: "#FFDB06",
              preset: 'islands#yellowClusterIcons',
            }}
            features={markers}
            modules={[
              "objectManager.addon.objectsBalloon",
              "objectManager.addon.clustersBalloon"
            ]}
          />
        }
        <ZoomControl options={{float: "right"}}/>
      </Map>
    </YMaps>
  )
}
