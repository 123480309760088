import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import {Pagination} from "antd";
import {userServices} from "../../../../../services";
import {useFetchDeliveriesList} from "../../../../../hooks/use-fetch-deliveries-list";
import Error from "../../../../error";
import Spinner from "../../../../spinner";
import DeliveryItem from "../../../../delivery-item-block";
import CarrierContentItem from "../../../../delivery-item-content/carrier/content-item";
import DeliveryEmptyContent from "../../../../delivery-empty-content";
import {AppContext} from "../../../../../contexts/app-context";
import UserNotificationMessage from "../../../../user-message";
import DeliveryGroupList from "../../../../delivery-group-list";
import {
  FullDate,
  CommentsBlock,
  FullPlacePoints,
  LogistName,
  LogistPhone,
  ShortPlaceUnload
} from "../../../../tools/delivery-tools";
import {Scrollbars} from 'react-custom-scrollbars';
import PriceElement from "../../../../price/PriceElement";
import {GuestHeaderButton} from "../../../../tools/guest-header-buttons";
import {deliveriesConfig} from "../../../../../config";

function HeaderItem({
                      delivery,
                      nameRegionLastPointUnload,
                      regionLoad,
                      contentVisible,
                      setContentVisible,
                      wasOpenedBeforeUpdate,
                      setWasOpenedBeforeUpdate,
                      ...props
                    }) {

  /*  <div className="application-topline">
    <div className="application-topline-carrier">
      <div className="application-topline-carrier-item">
      <div className="application-topline-carrier-item-col application-topline-carrier-item-col-city">
        <p className="typical-icon-field icon-field-patch">{regionLoad}</p>
        <p className="typical-icon-field icon-field-dispatch">{nameRegionLastPointUnload}</p>
      </div>
      </div>
      <div className="application-topline-carrier-item">
      <div className="application-topline-carrier-item-col">
        <div className="application-topline-carrier-item-col application-topline-carrier-item-col-date">
        <p className="typical-icon-field icon-field-calendar">{dateShipmentRus}</p>
        </div>
      </div>
      </div>
    </div>
    </div>*/

  useEffect(() => {
    if (wasOpenedBeforeUpdate && wasOpenedBeforeUpdate[delivery.Id]) {
      setContentVisible(false);
    }
  }, [wasOpenedBeforeUpdate]);

  const toggleContentVisible = (e) => {
    e.preventDefault();
    if (wasOpenedBeforeUpdate) {
      setWasOpenedBeforeUpdate({...wasOpenedBeforeUpdate, [delivery.Id]: contentVisible});
    }
    setContentVisible(!contentVisible);
  };

  const wasOpened = wasOpenedBeforeUpdate ? wasOpenedBeforeUpdate[delivery.Id] : false;

  return (
    <div className="item-view  non-bot-bord clrfx">
      <div className="col s1">
        <div className="top-view">
          <div className="citi-info">
            <div className="citi start">{regionLoad}</div>
            <div className="citi-list">
              <div className="inner-ls">
                <Scrollbars>
                  <ul>
                    <ShortPlaceUnload delivery={delivery}/>
                  </ul>
                </Scrollbars>
              </div>
            </div>
            <div title={nameRegionLastPointUnload} className="citi end">{nameRegionLastPointUnload}</div>
          </div>
        </div>
        <div className={`bot-view ${contentVisible ? "full-view" : ""}`}>
          <div className="ens">
            <div className="info-line">
              <div className="info-line--title">Номер рейса</div>
              <div className="info-line--desc">{delivery.NumberDelivery}</div>
            </div>
            <div className="info-line">
              <div className="info-line--title">Дата создания рейса</div>
              <div className="info-line--desc"><FullDate date={delivery.DateCreated}/></div>
            </div>
            <div className="info-line">
              <div className="info-line--title">Юр. лицо</div>
              <div className="info-line--desc">{delivery.CustomerCompany} ООО</div>
            </div>
          </div>
        </div>
      </div>
      <div className="col s2">
        <div className="top-view">
          <div className="col-pos-top">
            <div className="informer-item">
              <div className="h-informer">Дата отгрузки</div>
              <div className="b-informer">
                <div className="date red"><FullDate date={delivery.DateShipment}/></div>
              </div>
            </div>
          </div>
          <div className="col-pos-bot ">
            <div className="double-bot clrfx">
              <div className="informer-item">
                <div className="h-informer">Объем</div>
                <div className="b-informer">
                  <div className="inf">{delivery.Volume} м<sup>3</sup></div>
                </div>
              </div>
              <div className="informer-item">
                <div className="h-informer">Вес</div>
                <div className="b-informer">
                  <div className="inf">{(delivery.Weight / 1000).toFixed(2)} т.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`bot-view ${contentVisible ? "full-view" : ""}`}>
          <div className="ens">
            <div className="info-line">
              <div className="info-line--title">Загрузка</div>
              <div className="info-line--desc">{delivery.PlaceLoad.Address}</div>
              <FullPlacePoints delivery={delivery} type={'load'}/>
            </div>
          </div>
        </div>
      </div>
      <div className="col s3">
        <div className="top-view">
          <div className="col-pos-top">
            <div className="triple-bot clrfx">
              <div className="informer-item">
                <div className="h-informer">Тип груза</div>
                <div className="b-informer">
                  <div className="inf">{delivery.CargoType}</div>
                </div>
              </div>
              <div className="informer-item">
                <div className="h-informer">Режим</div>
                <div className="b-informer">
                  <div className="inf">{delivery.CargoMode}</div>
                </div>
              </div>
              <div className="informer-item">
                <div className="h-informer">Расстояние</div>
                <div className="b-informer">
                  <div className="inf">{delivery.DeliveryDistance > 0 ? delivery.DeliveryDistance + " км." : "-"}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-pos-bot ">
            <div className="triple-bot clrfx">
              <div className="informer-item">
                <div className="h-informer">Упаковка</div>
                <div className="b-informer">
                  <div className="inf"></div>
                </div>
              </div>
              <div className="informer-item">
                <div className="h-informer">Отгрузка</div>
                <div className="b-informer">
                  <div className="inf"></div>
                </div>
              </div>
              <div className="informer-item">
                <div className="h-informer">Загрузка</div>
                <div className="b-informer">
                  <div className="inf"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`bot-view ${contentVisible ? "full-view" : ""}`}>
          <div className="ens">
            <div className="info-line">
              <div className="info-line--title">Выгрузка</div>
              <FullPlacePoints delivery={delivery} type={'unload'}/>
            </div>
          </div>
        </div>
      </div>
      <div className="col s4">
        <a href="#" onClick={toggleContentVisible} className={`view-other-info ${contentVisible ? "open" : ""}`}></a>
        <div className="top-view">
          <PriceElement delivery={delivery}/>
          <GuestHeaderButton delivery={delivery}/>
        </div>
        <div className={`bot-view ${contentVisible ? "full-view" : ""}`}>
          <div className="ens">
            <div className="info-line">
              <div className="info-line--title">Логист</div>
              <div className="info-line--desc"><LogistName delivery={delivery}/></div>
            </div>
            <div className="info-line">
              <div className="info-line--title">Телефон</div>
              <div className="info-line--desc"><LogistPhone delivery={delivery}/></div>
            </div>
          </div>
          <CommentsBlock comments={delivery.Comments}/>
        </div>
      </div>
    </div>
  );
}

export default function GuestNewDeliveries() {
  const [wasOpenedBeforeUpdate, setWasOpenedBeforeUpdate] = useState({});
  const [step, setStep] = useState(deliveriesConfig.pageCount);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const user = userServices.getUser();

  if (!user.isGuest()) {
    return <Error status={403} desc="Страница не доступна"/>;
  }

  const appContext = useContext(AppContext);

  useEffect(() => {
    appContext.initFilter([
      {
        name: "default",
        title: "По умолчанию"
      },
      {
        name: "new_desc",
        title: "Сначала новые"
      },
      {
        name: "new_asc",
        title: "Сначала старые"
      }
    ]);
  }, []);
  let onChange = (page, pageSize) => {
    if (page === currentPage) {
      return false;
    } else {
      setCurrentPage(page);
    }

    let val = 0;
    if (parseInt(page) === 1) {
      setOffset(0);
    } else {
      val = page * pageSize - pageSize;
      setOffset(val);
    }
    appContext.filter.offset = val;
    setNeedUpdate(true);
  };

  const [{
    error,
    loading,
    data: deliveries,
    openModal,
    setStopListUpdate,
    setNeedUpdate,
    totalCount = 0
  }] = useFetchDeliveriesList(
    "delivery/get-free-for-guests",
    true,
    true,
    true,
    false,
    deliveriesConfig.secondForUpdate,
    offset,
    step
  );

  if (error) {
    return <Error type={500} desc={error.message}/>;
  }

  let listItems = [];

  if (!loading) {
    listItems = deliveries.map((delivery) => {
      return (
        <DeliveryItem
          key={delivery.Id}
          header={HeaderItem}
          content={CarrierContentItem}
          delivery={delivery}
          openModal={openModal}
          setStopListUpdate={setStopListUpdate}
          setNeedUpdate={setNeedUpdate}
          wasOpenedBeforeUpdate={wasOpenedBeforeUpdate}
          setWasOpenedBeforeUpdate={setWasOpenedBeforeUpdate}
        />
      );
    });
  }

  return (
    <Spinner loading={loading}>
      <UserNotificationMessage/>
      {totalCount !== 0 ?
        <div className="application-block pagination  pagination__up">
          <Pagination
            style={{textAlign: "center"}}
            current={currentPage}
            pageSize={step}
            total={totalCount}
            showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
            onChange={onChange}
          />
        </div>
        : null}
      {!loading ? deliveries.length ? <DeliveryGroupList deliveriesItems={listItems}/> : <DeliveryEmptyContent/> : null}
      {totalCount !== 0 ?
        <div className="application-block pagination pagination__down">
          <Pagination
            style={{textAlign: "center"}}
            responsive={true}
            current={currentPage}
            pageSize={step}
            total={totalCount}
            showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
            onChange={onChange}
          />
        </div>
        : null}
    </Spinner>
  );
}
