import React, { useState } from "react";
import { Button, Col, Modal, notification, Row, Spin } from "antd";
import { roleService } from "../../../../services";
import InputField from "../../../input-field";
import { formHelper } from "../../../../helpers";
import '../../modals.css';

export default function ModalCreateRole({ isShow, onCloseModal }) {

    const [loadingContentModal, setLoadingContentModal] = useState(false);
    const [roleName, setRoleName] = useState('');
    const [roleDesc, setRoleDesc] = useState('');

    const [fieldsErrors, setFieldsErrors] = useState(null);

    let bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent" };

    const getFieldErrors = (nameField) => formHelper.getFieldErrors(nameField, fieldsErrors);

    const onClickCreate = () => {
        setLoadingContentModal(true);

        roleService.create(roleName, roleDesc)
            .then((response) => {
                setLoadingContentModal(false);

                if (response.status === 'success') {

                    notification.success({
                        message: "Создание роли",
                        description: response.message,
                    });

                    onCloseModal();

                } else if (response.status === 'error') {

                    setFieldsErrors(response.errors);
                }
            })
            .catch((error) => {
                setLoadingContentModal(false);
                notification.error({
                    message: "Создание роли",
                    description: error,
                });
            })
    };

    return (
        <Modal
            title={<span>Создание штатной роли</span>}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={onCloseModal}
            footer={[
                <Button htmlType="submit" key="submit" type="primary" onClick={onClickCreate} style={bigButtonStyle}>Создать</Button>,
            ]}
        >
            <Spin spinning={loadingContentModal} tip="Загрузка...">
                <Row gutter={[16, 24]} >
                    <Col span={24} >
                        <InputField name="title" label="Название" value={roleDesc} setValue={setRoleDesc}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col span={24} >
                        <InputField name="name" label="Тех. имя" value={roleName} setValue={setRoleName}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}