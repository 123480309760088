import React, { useState, useEffect } from 'react';
import { notification, Button, Table } from 'antd';
import Spinner from '../../../../spinner';
import { companyService } from '../../../../../services';
import ModalUploadLegalEntityDocs from '../../../../modals/upload-legal-entity-docs';
import ModalShowRefuseReason from "../../../../modals/show-refuse-reason";
import ModalEditLegalEntity from "../../../../modals/edit-legal-entity";
import ModalEditLegalEntityDocs from "../../../../modals/edit-legal-entity-docs";
import NotificationMessage from "../../../../modals/notification-message";
import { useFetchNotification } from "../../../../../hooks/use-fetch-notification";
import ModalEditLegalEntityNew from "../../../../modals/edit-legal-entity-new";


export default function OrdersToConsignor() {

    const [needUpdate, setNeedUpdate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [stopListUpdate, setStopListUpdate] = useState(false);

    const [data, setData] = useState(null);

    const [showRefuseReasonModal, setShowRefuseReasonModal] = useState(false);
    const [refuseModalText, setRefuseModalText] = useState('');

    const [isShowEditEntity, setShowEditEntity] = useState(false);
    const [isShowEditEntityDocs, setShowEditEntityDocs] = useState(false);

    const [currentEntityId, setCurrentEntityId] = useState(null);
    const [currentOrgFormName, setCurrentOrgFormId] = useState(null);

    const [{ notifications }] = useFetchNotification(true);


    const resendOrder = (orderId) => {
        setLoading(true);

        companyService
            .resendOrder(orderId)
            .then(resp => {
                setLoading(false);
                setStopListUpdate(false);
                setNeedUpdate(true);

                notification.success({
                    message: "Повторная подача заявки",
                    description: resp.message
                });
            })
            .catch(errMsg => notification.error(
                {
                    message: "Получение списка заявок",
                    description: errMsg
                }));
    };

    const showRefuseModal = (text) => {
        setRefuseModalText(text);
        setShowRefuseReasonModal(true);
    };

    useEffect(() => {
        if (!stopListUpdate) {
            setLoading(true);

            companyService
                .getOrdersList()
                .then(resp => {
                    setData(resp);

                    setLoading(false);
                })
                .catch(errMsg => notification.error(
                    {
                        message: "Получение списка заявок",
                        description: errMsg
                    }));
        }
    }, [needUpdate, stopListUpdate]);

    const handleEditLegalEntity = entityId => {
        setCurrentEntityId(entityId);
        setShowEditEntity(true);
    };

    const handleEditLegalEntityDocs = (entityId, orgForm) => {
        setCurrentEntityId(entityId);
        setCurrentOrgFormId(orgForm);
        setShowEditEntityDocs(true);
    };

    const columns = [
        { title: "Юр. лицо", key: "legalEntity", render: (record) => record.legalEntity.name },
        { title: "Грузоотправитель", key: "consignor", render: (record) => record.consignor.name },
        { title: "Статус", dataIndex: "status", key: "status" },
        { title: "Дата подачи", dataIndex: "dateCreated", key: "dateCreated" },
        {
            title: "", key: "actions", render: (record) => {
                if (record.status === 'Отклонено') {
                    return (
                        <React.Fragment>
                            <Button style={{ marginLeft: "0px", width: "100%" }} onClick={() => showRefuseModal(record.refuseData.refuseReason)} size="small" className="small-button typical-btn-blue">Причина отмены</Button>
                            <Button style={{ marginLeft: "0px", width: "100%" }} onClick={() => resendOrder(record.id)} size="small" className="small-button typical-btn-green">Отправить повторно</Button>
                        </React.Fragment>
                    );
                }
            }
        },
        {
            title: "", key: "actions", render: (record) => {
                if (record.status === 'Отклонено') {
                    return (
                        <React.Fragment>
                            <Button style={{ marginLeft: "0px", width: "100%" }} title="Редактировать" type="primary" icon="edit" htmlType="button" size="small" className="small-button typical-btn-green" onClick={() => handleEditLegalEntity(record.legalEntity.id)} />
                            <Button style={{ marginLeft: "0px", width: "100%" }} title="Редактировать документы" type="primary" icon="file-word" htmlType="button" size="small" className="small-button typical-btn-green" onClick={() => handleEditLegalEntityDocs(record.legalEntity.id, record.legalEntity.orgForm)} />
                        </React.Fragment>
                    );
                }

                return null;
            }
        }
    ];

    return (
        <Spinner loading={loading} tip="Загрузка...">
            <NotificationMessage notifications={notifications} setStopListUpdate={setStopListUpdate} loading={false} />

            <Table
                columns={columns}
                dataSource={data}
                locale={{ emptyText: 'Данные отсутствуют' }}
                rowKey="id"
                size="small"
                pagination={{
                    pageSize: 50
                }}
                className="car-park"
                rowClassName={(record, index) => ("car-park-row")
                }
            />
            <ModalShowRefuseReason text={refuseModalText} setIsShow={setShowRefuseReasonModal} isShow={showRefuseReasonModal} />

            {/*<ModalEditLegalEntity isShow={isShowEditEntity} setIsShow={setShowEditEntity} legalEntityId={currentEntityId} />*/}
            <ModalEditLegalEntityNew
                isShow={isShowEditEntity}
                setIsShow={setShowEditEntity}
                legalEntityId={currentEntityId}
                setLoadingList={() => {}}
                setStopListUpdate={setStopListUpdate}
            />
            <ModalEditLegalEntityDocs isShow={isShowEditEntityDocs} setIsShow={setShowEditEntityDocs} legalEntityId={currentEntityId} orgForm={currentOrgFormName} />
        </Spinner>
    );
}
