import React, {useEffect, useState} from "react";
import {notification} from "antd";
import moment from "moment";
import {companyService} from "../../../../services";
import MainDataFields from "../../create-legal-entity/kazakhstan-legal-entity/steps/main-data-fields";
import AddressesFields from "../../create-legal-entity/kazakhstan-legal-entity/steps/addresses-fields";
import GuarantorFields from "../../create-legal-entity/kazakhstan-legal-entity/steps/guarantor-fields";
import {legalEntityService} from "../../../../services/legal-entity.service";

export default function EditKazakhstanLegalEntity({
                                                      isActive = false,

                                                      currentStep,
                                                      setCurrentStep,

                                                      legalEntityId,
                                                      legalEntityData,

                                                      setLoading,

                                                      setStepsList,

                                                      changeStepEvent,
                                                      setChangeStepEvent,

                                                      editLegalEntityEvent,
                                                      setEditLegalEntityEvent,

                                                      setIsShowParent
                                                  }) {
    const [fieldsErrors, setFieldsErrors] = useState({});

    const [mainDataFields, setMainDataFields] = useState({
        orgFormID: legalEntityData.OrgFormId,
        workWithNds: legalEntityData.WorkWithNds === 1 ? 1 : 2,

        legalReason: legalEntityData.LegalReason,
        legalReasonsList: [
            {id: 'Устав', name: 'Устав'},
            {id: 'Приказ', name: 'Приказ'},
            {id: 'Доверенность (ТОО)', name: 'Доверенность (ТОО)'}
        ],

        name: legalEntityData.Name,
        iin: legalEntityData.Iin === 'null' ? '' : legalEntityData.Iin,
        bin: legalEntityData.Bin === 'null' ? '' : legalEntityData.Bin,
        dateRegistration: moment(legalEntityData.DateRegistration, 'DD.MM.YYYY'),
        firstName: legalEntityData.FirstName,
        secondName: legalEntityData.SecondName,
        patronymic: legalEntityData.Patronymic,
        position: legalEntityData.PositionStr,
        email: legalEntityData.Email,
        phone: legalEntityData.Phone,
        bankName: legalEntityData.BankName,
        rs: legalEntityData.Rs,
        bic: legalEntityData.Bic,
        iik: legalEntityData.Iik,
        bankLegalAddress: legalEntityData.BankLegalAddress,
        correspondentBankName: legalEntityData.CorrespondentBankName,
        correspondentBankInn: legalEntityData.CorrespondentBankInn,
        correspondentBankBic: legalEntityData.CorrespondentBankBic,
        correspondentBankLegalAddress: legalEntityData.CorrespondentBankLegalAddress,
        correspondentBankAccount: legalEntityData.CorrespondentBankAccount,
        correspondentBankCheckingAccount: legalEntityData.CorrespondentBankCheckingAccount,
        atiCode: legalEntityData.AtiCode,
        registrationNumber: legalEntityData.RegistrationNumber === 'null' ? '' : legalEntityData.RegistrationNumber,
        certificateSerial: legalEntityData.CertificateSerial === 'null' ? '' : legalEntityData.CertificateSerial,
        certificateNumber: legalEntityData.CertificateNumber === 'null' ? '' : legalEntityData.CertificateNumber,
    });

    const [addressesFields, setAddressesFields] = useState({
        legalRegion: legalEntityData.LegalAddressRegion,
        legalLocality: legalEntityData.LegalAddressLocality,
        legalStreet: legalEntityData.LegalAddressStreet,
        legalHouseNumber: legalEntityData.LegalAddressHouseNumber,
        legalPremiseNumber: legalEntityData.LegalAddressPremiseNumber,
        legalPostalCode: legalEntityData.LegalAddressPostalCode,

        regAddressCheckBox: false,
        regRegion: legalEntityData.RegAddressRegion,
        regLocality: legalEntityData.RegAddressLocality,
        regStreet: legalEntityData.RegAddressStreet,
        regHouseNumber: legalEntityData.RegAddressHouseNumber,
        regPremiseNumber: legalEntityData.RegAddressPremiseNumber,
        regPostalCode: legalEntityData.RegAddressPostalCode,
    });

    const [guarantorFields, setGuarantorFields] = useState({
        guarantorFirstName: legalEntityData.GuarantorFirstName,
        guarantorSecondName: legalEntityData.GuarantorSecondName,
        guarantorPatronymic: legalEntityData.GuarantorPatronymic,
        guarantorPassportSerial: legalEntityData.GuarantorPassportSerial,
        guarantorPassportNumber: legalEntityData.GuarantorPassportNumber,
        guarantorPassportDate: moment(legalEntityData.GuarantorPassportDate, 'DD.MM.YYYY'),
        guarantorPhone: legalEntityData.GuarantorPhone,
        guarantorEmail: legalEntityData.GuarantorEmail,
    });

    const getFieldErrors = (fieldName) => {
        return typeof fieldsErrors[fieldName] !== "undefined"
            ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
                return fieldErrors + "; " + error;
            })
            : null;
    };

    useEffect(() => {
        if (isActive) {
            if (mainDataFields.orgFormID === 27) {
                setStepsList([
                    'Основная информация',
                    'Адреса',
                ]);
            } else {
                setStepsList([
                    'Основная информация',
                    'Адреса',
                    'Поручитель',
                ]);
            }
        }
    }, [isActive]);

    useEffect(() => {
        if (changeStepEvent) {
            if (changeStepEvent === 'next') {
                nextStep();
            } else if (changeStepEvent === 'previous') {
                previousStep();
            }

            setChangeStepEvent('');
        }
    }, [changeStepEvent]);

    useEffect(() => {
        if (editLegalEntityEvent) {
            setLoading(true);

            let data = Object.assign(mainDataFields, guarantorFields, addressesFields);
            legalEntityService
                .editKazakhstanLegalEntity(legalEntityId, data, data.orgFormID)
                .then(response => {
                    if (response.code === 400) {
                        notification.error({
                            message: "Редактирование юридического лица",
                            description: response.message,
                        });
                        setFieldsErrors(response.errors);
                    } else {
                        notification.success({
                            message: "Редактирование юридического лица",
                            description: response.message,
                        });

                        setIsShowParent(false);
                    }

                    setLoading(false);
                    setEditLegalEntityEvent(false);
                })
                .catch(errMsg => {
                    notification.error({
                        message: "Создание юридического лица",
                        description: errMsg,
                    });

                    setLoading(false);
                    setEditLegalEntityEvent(false)
                });
        }
    }, [editLegalEntityEvent]);

    const nextStep = () => {
        let stepData = (currentStep) => {
            let stepFields = {
                1: mainDataFields,
                2: addressesFields,
                3: guarantorFields
            };

            return stepFields[currentStep];
        };

        setLoading(true);

        legalEntityService
            .validateKazakhstanLegalEntityByStep(stepData(currentStep), currentStep, mainDataFields.orgFormID, legalEntityId)
            .then(resp => {
                if (resp.status === 'success') {
                    if (currentStep < 3) {
                        // if ((currentStep + 1) === 3 && mainDataFields.orgFormID === 1) {
                        //     setCurrentStep(currentStep + 2);
                        // } else {
                        //     setCurrentStep(currentStep + 1);
                        // }
                        setCurrentStep(currentStep + 1);
                        setFieldsErrors([]);
                    }
                } else {
                    setFieldsErrors(resp.errors);
                }
            })
            .catch(error => {
                setLoading(false);
                notification.error({
                    message: "Создание юр.лица",
                    description: error,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const previousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        } else {
            setCurrentStep(0);
        }
    };

    const renderCurrentStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <MainDataFields
                        setFields={setMainDataFields}
                        stepFields={mainDataFields}
                        getFieldErrors={getFieldErrors}
                        isProprietor={mainDataFields.orgFormID === 27}
                        edit={true}
                        defAdditionalRequisitesType={
                            mainDataFields.registrationNumber ? 'fill_registration_number' : 'fill_certificate'
                        }
                    />
                );
            case 2:
                return (
                    <AddressesFields
                        setFields={setAddressesFields}
                        stepFields={addressesFields}
                        getFieldErrors={getFieldErrors}
                        edit={true}
                    />
                );
            case 3:
                return (
                    <GuarantorFields
                        setFields={setGuarantorFields}
                        stepFields={guarantorFields}
                        getFieldErrors={getFieldErrors}
                        edit={true}
                    />
                );
            default:
                return <div />;
        }
    };

    return (
        <div>
            {renderCurrentStep()}
        </div>
    );
}