import {Col} from "antd";
import React from "react";
import InputField from "../../input-field";

export default function LegalEntityFormFields({data, setFormFieldsValues, formFieldsValues, fields}){
  if(!data) {
    return null;
  }
  return fields.map((item) => {
    return <Col span={24}>
      <InputField
        name={item.key}
        title={item.title}
        label={item.title}
        value={formFieldsValues[item.key]}
        setValue={(value) => {
          setFormFieldsValues({...formFieldsValues, [item.key]: value});
        }}
        getFieldErrors={() => {
        }}
      />
    </Col>
  });
}
