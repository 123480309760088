import React, {useState} from "react";
import Spinner from "../../../spinner";
import {SERVICE_INFORMATION_MANAGEMENT} from "../../../../helpers/user_permission.helper";
import Error from "../../../error";
import {userServices} from "../../../../services";
import {formHelper} from "../../../../helpers";
import {Button, Col, notification, Row} from "antd";
import InputField from "../../../input-field";
import UserNotificationMessage from "../../../user-message";


export default function UserMessage() {
    const [loading, setLoading] = useState(false);
    const [fieldsErrors] = useState(null);
    const [subject] = useState('');
    const [message, setMessage] = useState('');
    const [messageLoading, setMessageLoading] = useState(Math.random);

    const getFieldErrors = (nameField) => formHelper.getFieldErrors(nameField, fieldsErrors);
    let smallButtonStyle = {height: '40px', color: 'white', backgroundColor: '#1CAA53', borderRadius: '7px', border:'none', marginBottom: "20px"};

    const user = userServices.getUser();
    if (!user.isAccessTo(SERVICE_INFORMATION_MANAGEMENT)) {
        return (<Error status={403} desc="Страница не доступна"/>);
    }

    const onClickCreate = () => {
        setLoading(true);
        userServices.addMessage(subject, message)
            .then((response) => {
                notification.success({
                    message: "Добавление сообщения",
                    description: response,
                    duration: 20
                });
            })
            .catch((errMsg) => {
                notification.error({
                    message: "Добавление сообщения",
                    description: errMsg,
                    duration: 20
                });
            })
            .then(() => {
                setLoading(false);
                setMessageLoading(Math.random());
            });
    };

    const onClickDelete = () => {
        setLoading(true);
        userServices.deleteMessage()
            .then((response) => {
                notification.success({
                    message: "Удаление сообщения",
                    description: response,
                    duration: 20
                });
            })
            .catch((errMsg) => {
                notification.error({
                    message: "Удаление сообщения",
                    description: errMsg,
                    duration: 20
                });
            })
            .then(() => {
                setLoading(false);
                setMessageLoading(Math.random());
            });
    };

    return (
        <React.Fragment>
            <h1 className="content-header" style={{marginBottom: 5}}>Сообщение для пользователей</h1>
            <Spinner loading={loading} label={null}>
                <Row gutter={16} className="modal-row-field">
                    <Col span={24} className="add-padding-10">
                        <InputField name="message" label="Текст сообщения" value={message} setValue={setMessage}
                                    getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Button style={{...smallButtonStyle, marginLeft: '8px'}} htmlType="submit" key="submit" type="primary" onClick={onClickCreate}>Установить</Button>
                    <Button style={{...smallButtonStyle, marginLeft: '8px', background: '#BE1C1C'}} htmlType="submit" key="submitDelete" type="danger" onClick={onClickDelete}>Удалить</Button>
                </Row>
            </Spinner>
            <UserNotificationMessage key={messageLoading} />
        </React.Fragment>
    );
}