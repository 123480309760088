import React, { useEffect, useState } from 'react';
import Spinner from '../../../spinner';
import { Button, Col, Table, Row } from 'antd';
import { companyService } from '../../../../services';
import ModalCreateEmployee from '../../../modals/create-employee';
import ModalEditEmployee from '../../../modals/edit-employee';
import { tableHelper } from '../../../../helpers';
import ModalNeedApprovedLegalEntity from "../../../modals/need-approved-legal-entity";
import NotificationMessage from "../../../modals/notification-message";
import { useFetchNotification } from "../../../../hooks/use-fetch-notification";
import ModalChangeUserLogin from "../../../modals/change-user-login";

export default function Employee() {
  const [loading, setLoading] = useState(false);
  const [isNeedUpdateEmployee, setIsNeedUpdateEmployee] = useState(false);
  const [stopListUpdate, setStopListUpdate] = useState(false);
  const [showModalCreateEmployee, setShowModalCreateEmployee] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [showUserChangeLoginModal, setShowUserChangeLoginModal] = useState(null);

  const [{ notifications }] = useFetchNotification(true);
  let bigButtonStyle = { width: '190px', height: '40px', background: '#1CAA53', position: 'static', borderRadius: '10px', float: 'right', borderColor: "#1CAA53" };
  let editButtonStyle = { fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '600', fontSize: '10px', lineHeight: '12px', color: '#2B3F63', borderRadius: '0px', borderColor: 'transparent', background: "transparent", boxShadow: 'none', padding: "0px" };

  useEffect(() => {
    if (loading === false && stopListUpdate !== true) {
      setLoading(true);
      setIsNeedUpdateEmployee(true);
    }
  }, [isNeedUpdateEmployee, stopListUpdate]);

  if (isNeedUpdateEmployee) {
    companyService
      .getEmployeeList()
      .then(response => {
        setEmployeeData([]);
        let reindexedTableData = [];

        response.Employees.forEach(item => {
          if (item.Status !== 'Архивный') {
            reindexedTableData.push({
              name: `${item.SecondName} ${item.FirstName} ${item.Patronymic}`,
              status: item.Status,
              id: item.Id,
              phone: item.Phone,
              firstName: item.FirstName,
              secondName: item.SecondName,
              UserId: item.UserId
            });
          }
        });

        setPermissions(response.Permissions);

        setIsNeedUpdateEmployee(false);
        setLoading(false);
        setEmployeeData(reindexedTableData);
      })
      .catch(errorMsg => {
        setIsNeedUpdateEmployee(false);
        setLoading(false);
        setErrorMsg(errorMsg);
      });
  }

  if (errorMsg) {
    return <div className="content-error-block">{errorMsg}</div>;
  }

  const renderCreateEmployeeBtn = (isCanCreateEmployee) => {
    if (isCanCreateEmployee) {
      return (
        <React.Fragment>
          <Row>
            <Col className={"content-header"} style={{ marginBottom: "0px" }} span={12}>Сотрудники</Col>
            <Col span={8} style={{ marginBottom: "0px" }} offset={4}>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  setShowModalCreateEmployee(true);
                }}
                style={bigButtonStyle}
              >
                Создать сотрудника
              </Button>
              <ModalCreateEmployee
                isShow={showModalCreateEmployee}
                setIsShow={setShowModalCreateEmployee}
                onSuccess={() => {
                  setShowModalCreateEmployee(false);
                  setIsNeedUpdateEmployee(true);
                }}
              />
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Row>
            <Col className={"content-header"} style={{ marginBottom: "0px" }} span={12}>Сотрудники</Col>
            <Col span={8} style={{ marginBottom: "0px" }} offset={4}>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  setShowModalCreateEmployee(true);
                }}
                style={bigButtonStyle}
              >
                Создать сотрудника
              </Button>
              <ModalNeedApprovedLegalEntity
                isShow={showModalCreateEmployee}
                text={'Для создания сотрудника нужно подтвержденное юр.лицо'}
                setIsShow={setShowModalCreateEmployee}
              />
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  };

  const columns = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
      width: '45%',
      ...tableHelper.getColumnSearchProps('name', searchText, setSearchText)
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Действия',
      key: 'edit',
      render: (data) => {
        return (
          <React.Fragment>
            <Button style={{marginLeft: "0px", width: "100%"}}
              type="primary"
              htmlType="button"
              onClick={() => setShowModalEdit(data.name)}
              size="small"
              className="small-button typical-btn-green"
            >
              Редактировать
            </Button>
            <ModalEditEmployee
              isShow={showModalEdit === data.name}
              onCloseModal={() => {
                setShowModalEdit(null);
                setLoading(true);
                setIsNeedUpdateEmployee(true);
              }}
              setIsNeedUpdateEmployee={setIsNeedUpdateEmployee}
              employee={data}
            />
            {data.status !== "Удален" && <>
              <Button style={{marginLeft: "0px", width: "100%"}}
                      size="small"
                      className="small-button typical-btn-green"
                      onClick={() => {
                        setShowUserChangeLoginModal(data.Id);
                      }}>
                Изменить логин
              </Button>
              <ModalChangeUserLogin
                id={data.UserId}
                isShow={showUserChangeLoginModal === data.Id}
                setIsShow={() => {
                  setShowUserChangeLoginModal(null)
                }}
                setNeedUpdateList={setIsNeedUpdateEmployee}
              />
            </>
            }
          </React.Fragment>
        );
      }
    }
  ];

  return (
    <React.Fragment>
      <NotificationMessage notifications={notifications} setStopListUpdate={setStopListUpdate} loading={false} />
      {renderCreateEmployeeBtn(permissions.IsCanCreateEmployee)}
      <Col span={24}>
        <Spinner loading={loading} tip="Загрузка...">
          <Table
            style={{ minWidth: '700px' }}
            columns={columns}
            dataSource={employeeData}
            locale={{ emptyText: 'Данные отсутствуют' }}
            rowKey="name"
            size="small"
            pagination={{
              pageSize: 50
            }}
            className="car-park"
            rowClassName={(record, index) => ("car-park-row")
            }
          />
        </Spinner>
      </Col>
    </React.Fragment>
  );
}
