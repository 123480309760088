import {httpRequest} from "./http-request";

export const orderDeliveryService = {
  createOrder,
  createWithReturnDelivery,
  refuseOrder,
  agreeWithDocs,
  agreeWithoutDocs,
  approveOrder,
  attachDriver,
  detachDriver,
  checkOrderLimit,
  makeCounteroffer,
  refuseCounteroffer,
  agreeCounteroffer,
  start,
  createAct,
  createInvoice,
  changeLoadingDate
};

/**
 * Создать заказ
 */
async function createOrder(deliveryId, companyId, personId, dateShipment, priceShipment, prepaymentPercent, paymentType) {

  return await httpRequest.post('order-delivery/create', {
    DeliveryId: deliveryId,
    LegalEntityId: companyId,
    PartnerPersonId: personId,
    DateShipment: (dateShipment) ? dateShipment.format("YYYY-MM-DD") : null,
    Price: priceShipment,
    PrepaymentPercent: prepaymentPercent,
    PaymentType: paymentType
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Создать заказы на рейсы туда и обратно
 */

async function createWithReturnDelivery(
  companyId,
  deliveryId,
  priceShipment,
  dateShipment,
  paymentType,
  prepaymentPercent,
  returnOrderDeliveryId,
  returnOrderPrice,
  returnOrderDateShipment,
  returnOrderPaymentType,
  returnOrderPrepaymentPercent
) {
  return await httpRequest.post('order-delivery/create-with-return-delivery', {
    LegalEntityId: companyId,
    MainOrderDeliveryId: deliveryId,
    MainOrderPrice: priceShipment,
    MainOrderDateShipment: (dateShipment) ? dateShipment.format("YYYY-MM-DD") : null,
    MainOrderPaymentType: paymentType,
    MainOrderPrepaymentPercent: prepaymentPercent,
    ReturnOrderDeliveryId: returnOrderDeliveryId,
    ReturnOrderPrice: returnOrderPrice,
    ReturnOrderDateShipment: (returnOrderDateShipment) ? returnOrderDateShipment.format("YYYY-MM-DD") : null,
    ReturnOrderPaymentType: returnOrderPaymentType,
    ReturnOrderPrepaymentPercent: returnOrderPrepaymentPercent
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Согласование заказа с подписанными документами
 */
async function agreeWithDocs(orderId, scanAgreement, scanProcuration, forceAgree = false) {

  const formData = new FormData();
  formData.append('Id', orderId);
  formData.append('ScanAgreement', scanAgreement);
  formData.append('ScanProcuration', scanProcuration);

  return await httpRequest.post(`order-delivery/agree-with-docs?forceAgree=${Number(forceAgree)}`, formData, {
    'Content-Type': 'multipart/form-data'
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Утвердить заказ без документов
 */
async function agreeWithoutDocs(orderId) {

  return await httpRequest.post('order-delivery/agree-without-docs', {
    Id: orderId,
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Отменить заказ
 */
async function refuseOrder(orderId) {

  return await httpRequest.post('order-delivery/refuse', {
    Id: orderId,
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Одобрить заказ
 */
async function approveOrder(orderId) {

  return await httpRequest.post('order-delivery/approve', {
    Id: orderId,
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Заполнить заказ
 */
async function attachDriver(
  id,
  driverId, truckId, trailId,
  procurationDate = null, procurationNumber = null,
  foreignPartnerId = null,
  containerNumber = ''
) {

  return await httpRequest.post('order-delivery/attach-driver', {
    Id: id,
    DriverId: driverId,
    TruckId: truckId,
    TrailId: trailId,
    ProcurationDate: (procurationDate) ? procurationDate.format("YYYY-MM-DD") : '',
    ProcurationNumber: procurationNumber,
    ForeignPartnerId: foreignPartnerId,
    ContainerNumber: containerNumber,
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Открепить водителя
 */
async function detachDriver(orderId) {

  return await httpRequest.get(`order-delivery/detach-driver?id=${orderId}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Проверить допустимое количество рейсов
 */
async function checkOrderLimit(orderId) {

  return await httpRequest.get(`order-delivery/${orderId}/check-order-limit`, {
    Id: orderId
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function start(orderId) {

  return await httpRequest.post(`order-delivery/start`, {
    Id: orderId
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Создание встречного предложения
 * @param data
 * @returns {Promise<*>}
 */
async function makeCounteroffer(data) {

  return await httpRequest.post('order-delivery/make-counteroffer', data)
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Отмена встречного предложения
 * @param orderDeliveryId
 * @returns {Promise<*>}
 */
async function refuseCounteroffer(orderDeliveryId) {

  return await httpRequest.post('order-delivery/refuse-counteroffer', {orderDeliveryID: orderDeliveryId})
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Подтверждение встречного предложения
 * @param orderDeliveryId
 * @returns {Promise<*>}
 */
async function agreeCounteroffer(orderDeliveryId) {

  return await httpRequest.post('order-delivery/agree-counteroffer', {orderDeliveryID: orderDeliveryId})
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function createInvoice(id, number, date, waybillsNumbers) {
  return await httpRequest.post(`order-delivery/create-invoice?id=${id}`, {
    number: number,
    date: date,
    waybillsNumbers: waybillsNumbers
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function createAct(id, number, date, waybillsNumbers) {
  return await httpRequest.post(`order-delivery/create-act?id=${id}`, {
    number: number,
    date: date,
    waybillsNumbers: waybillsNumbers
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Изменение даты загрузки в заявке
 * @param id
 * @param reason
 * @param newDate
 * @returns {Promise<any>}
 */

async function changeLoadingDate(id, reason, newDate){
  return await  httpRequest.post(`order-delivery/request-change-approved-delivery-date`, {
    id: id, //Заявки
    reason: reason,
    newdate: newDate.format("YYYY-MM-DD"),
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}
