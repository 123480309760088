import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Upload, Button, notification, Pagination } from "antd";
import { orderDeliveryService, userServices } from "../../../../../services";
import { useFetchDeliveriesList } from "../../../../../hooks/use-fetch-deliveries-list";
import Error from "../../../../error";
import Spinner from "../../../../spinner";
import CarrierContentItem from "../../../../delivery-item-content/carrier/content-item";
import DeliveryItem from "../../../../delivery-item-block";
import DeliveryEmptyContent from "../../../../delivery-empty-content";
import ModalAttachDriverOrderDelivery from "../../../../modals/attach-driver-order-delivery";
import { AppContext } from "../../../../../contexts/app-context";
import { VIEW_BOOKED_DELIVERIES_CARRIER } from "../../../../../helpers/user_permission.helper";
import UserNotificationMessage from "../../../../user-message";
import NotificationMessage from "../../../../modals/notification-message";
import { DELIVERY_ORDER_APPROVED, DELIVERY_ORDER_STARTED, DELIVERY_ORDER_FILLED } from "../../../../../helpers/delivery_status.helper";
import DeliveryGroupList from "../../../../delivery-group-list";
import DeterminedPrice from "../../../../determined-price";
import { deliveriesConfig } from "../../../../../config";

function ApprovedOrderHeader({
	delivery,
	setStopListUpdate,
	setNeedUpdate,
	setLoadingDelivItem,
	dateShipmentRus,
	nameRegionLastPointUnload,
	regionLoad,
}) {
	const [showModalAttachDriver, setShowModalAttachDriver] = useState(false);

	const onClickRefuseOrder = (e) => {
		e.preventDefault();
		setLoadingDelivItem(true);

		orderDeliveryService
			.refuseOrder(delivery.Order.Id)
			.then((response) => {
				notification.success({
					message: "Отмена заказа",
					description: response.message,
				});

				setLoadingDelivItem(false);
				setNeedUpdate(true);
			})
			.catch((error) => {
				notification.error({
					message: "Отмена заказа",
					description: error,
				});

				setNeedUpdate(true);
			});
	};

	const onClickShowModalAttachDriver = (e) => {
		e.preventDefault();

		setStopListUpdate(true);
		setShowModalAttachDriver(true);

		orderDeliveryService
			.start(delivery.Order.Id)
			.then((response) => {
				/*             notification.success({
						message: "Начало заполнения заявки",
						description: response.message
					}) */
			})
			.catch((error) => {
				/*             notification.error({
						message: "Начало заполнения заявки",
						description:error
					}) */
			});
	};

	const isForeignDelivery = delivery.IsForeignDelivery;

	return (
		<div className="application-topline" key={`header_${delivery.Id}_approved`}>
			<div className="application-topline-carrier">
				<div className="application-topline-carrier-item">
					<div className="application-topline-carrier-item-col application-topline-carrier-item-col-city">
						<p className="typical-icon-field icon-field-patch" title={regionLoad}>
							{regionLoad}
						</p>
						<p className="typical-icon-field icon-field-dispatch" title={nameRegionLastPointUnload}>
							{nameRegionLastPointUnload}
						</p>
					</div>
				</div>
				<div className="application-topline-carrier-item">
					<div className="application-topline-carrier-item application-topline-carrier-item-nowrap">
						<div className="application-topline-carrier-item application-topline-carrier-item-determined">
							<DeterminedPrice delivery={delivery} />
							<div className="application-topline-carrier-item-col application-topline-carrier-item-col-date">
								<div className="typical-icon-field icon-field-calendar">{dateShipmentRus}</div>
							</div>
						</div>
					</div>
					<div className="application-topline-carrier-item-col application-topline-carrier-item-col-button">
						<button className="btn typical-btn typical-btn-green" onClick={onClickShowModalAttachDriver}>
							Заполнить заявку <div className="typical-btn-icon typical-btn-green-icon" />
						</button>
						<button className="btn typical-btn typical-btn-blue" onClick={onClickRefuseOrder}>
							Отменить заявку <div className="typical-btn-icon typical-btn-red-icon" />
						</button>

						<ModalAttachDriverOrderDelivery
							key={`modal_${delivery.Id}`}
							isShow={showModalAttachDriver}
							setIsShow={setShowModalAttachDriver}
							orderId={delivery.Order.Id}
							companyId={delivery.Order.Company.Id}
							setStopListUpdate={setStopListUpdate}
							setNeedUpdateDeliveryList={setNeedUpdate}
							deliveryDateShipmentRus={dateShipmentRus}
							deliveryPrice={delivery.Order.Price}
							deliveryRegionLoad={regionLoad}
							deliveryRegionUnload={nameRegionLastPointUnload}
							isForeignDelivery={isForeignDelivery}
              deliveryContractType={delivery.ContractType}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

function FilledOrderHeader({
	delivery,
	setStopListUpdate,
	setNeedUpdate,
	setLoadingDelivItem,
	dateShipmentRus,
	nameRegionLastPointUnload,
	regionLoad,
}) {
	return (
		<div className="application-topline" key={`header_${delivery.Id}_filled`}>
			<div className="application-topline-carrier">
				<div className="application-topline-carrier-item">
					<div className="application-topline-carrier-item-col application-topline-carrier-item-col-city">
						<p className="typical-icon-field icon-field-patch" title={regionLoad}>
							{regionLoad}
						</p>
						<p className="typical-icon-field icon-field-dispatch" title={nameRegionLastPointUnload}>
							{nameRegionLastPointUnload}
						</p>
					</div>
				</div>
				<div className="application-topline-carrier-item application-topline-carrier-item-nowrap">
					<div className="application-topline-carrier-item application-topline-carrier-item-determined">
						<DeterminedPrice delivery={delivery} />
						<div className="application-topline-carrier-item-col application-topline-carrier-item-col-date">
							<div className="typical-icon-field icon-field-calendar">{dateShipmentRus}</div>
						</div>
					</div>
					<ButtonsUploadDocs
						delivery={delivery}
						setLoadingDelivItem={setLoadingDelivItem}
						setNeedUpdate={setNeedUpdate}
						setStopListUpdate={setStopListUpdate}
					/>
				</div>
			</div>
		</div>
	);
}

function ButtonsUploadDocs({ delivery, setLoadingDelivItem, setNeedUpdate, setStopListUpdate }) {
	const [scanAgreement, setScanAgreement] = useState(null);
	const [scanProcuration, setScanProcuration] = useState(null);
	const [loading, setLoading] = useState(false);

	const templateAgreement = delivery.Order.Docs.Agreement.Template;
	const isDisabledUpload = templateAgreement === null;

	const onClickDownloadTemplateAgreement = () => {
		if (!isDisabledUpload) {
			window.open(delivery.Order.Docs.Agreement.Template);
		}
	};

	const uploadPropsAgreement = {
		multiple: false,
		disabled: isDisabledUpload || scanAgreement !== null,
		showUploadList: false,
		onRemove: () => {
			setScanAgreement(null);
		},
		beforeUpload: (file) => {
			setStopListUpdate(true);
			setScanAgreement(file);
			return false;
		},
	};
	const uploadPropsProcuration = {
		multiple: false,
		disabled: isDisabledUpload || scanProcuration !== null,
		showUploadList: false,
		onRemove: () => {
			setScanProcuration(null);
		},
		beforeUpload: (file) => {
			setStopListUpdate(true);
			setScanProcuration(file);
			return false;
		},
	};

	const needScanProcuration = delivery.Order.Docs.Procuration !== null;

	if (((needScanProcuration && scanAgreement !== null && scanProcuration !== null) || (!needScanProcuration && scanAgreement !== null)) && !loading) {
		setLoading(true);
		setLoadingDelivItem(true);

		orderDeliveryService.agreeWithDocs(delivery.Order.Id, scanAgreement, scanProcuration).then((response) => {
			setScanAgreement(null);
			setScanProcuration(null);

			setLoading(false);
			setLoadingDelivItem(false);
			setStopListUpdate(false);

			if (response.status === "success") {
				setNeedUpdate(true);
				notification.success({
					message: "Прикрепление документов",
					description: response.message,
				});
			} else if (response.status === "error") {
				for (let fieldName in response.errors) {
					if (response.errors.hasOwnProperty(fieldName)) {
						const errorMsg = response.errors[fieldName][0];
						notification.error({
							message: "Прикрепление документов",
							description: errorMsg,
						});
					}
				}
			}
		});
	}

	const buttonExtClassName = isDisabledUpload ? "disabled" : "";
	const buttonAgreementColorClassName = scanAgreement === null ? "typical-btn-blue" : "typical-btn-green";
	const buttonProcurationColorClassName = scanProcuration === null ? "typical-btn-blue" : "typical-btn-green";

	if (needScanProcuration) {
		return (
			<div className="application-topline-carrier-item-col application-topline-carrier-item-col-button" style={{ display: "flex" }}>
				<button
					className={`btn typical-btn typical-btn-green ${buttonExtClassName}`}
					style={{ fontSize: "11px" }}
					onClick={onClickDownloadTemplateAgreement}
				>
					Скачать заявку
				</button>
				<Upload {...uploadPropsAgreement}>
					<Button
						className={`btn typical-btn ${buttonAgreementColorClassName} ${buttonExtClassName}`}
						style={{ fontSize: "11px", padding: 0, marginBottom: '20px' }}
						htmlType="button"
					>
						Загрузить заявку
					</Button>
				</Upload>
				<Upload {...uploadPropsProcuration}>
					<Button
						className={`btn typical-btn ${buttonProcurationColorClassName} ${buttonExtClassName}`}
						style={{ fontSize: "11px", padding: 0 }}
						htmlType="button"
					>
						Загрузить доверенность
					</Button>
				</Upload>
			</div>
		);
	} else {
		return (
			<div className="application-topline-carrier-item-col application-topline-carrier-item-col-button" style={{ display: "inline-flex" }}>
				<button className={`btn typical-btn typical-btn-green ${buttonExtClassName}`} onClick={onClickDownloadTemplateAgreement}>
					Скачать заявку
				</button>
				<Upload {...uploadPropsAgreement}>
					<Button className={`btn typical-btn ${buttonAgreementColorClassName} ${buttonExtClassName}`} htmlType="button">
						Загрузить заявку
					</Button>
				</Upload>
			</div>
		);
	}
}

export default function CarrierBookedDeliveries() {
	const [wasOpenedBeforeUpdate, setWasOpenedBeforeUpdate] = useState({});

	const user = userServices.getUser();
	const [step, setStep] = useState(deliveriesConfig.pageCount);
	const [offset, setOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);

	const appContext = useContext(AppContext);

	useEffect(() => {
		appContext.initFilter();
	}, []);

	let onChange = (page, pageSize) => {
		if (page === currentPage) {
			return false;
		} else {
			setCurrentPage(page);
		}

		let val = 0;
		if (parseInt(page) === 1) {
			setOffset(0);
		} else {
			val = page * pageSize - pageSize;
			setOffset(val);
		}
		appContext.filter.offset = val;
		setNeedUpdate(true);
	};

	const [{ error, loading, data: deliveries, setStopListUpdate, setNeedUpdate, notifications, permissions, totalCount }] = useFetchDeliveriesList(
		"delivery/get-booked",
		true,
		true,
		true,
		true,
		deliveriesConfig.secondForUpdate,
		offset,
		step
	);

    if (!user.isAccessTo(VIEW_BOOKED_DELIVERIES_CARRIER)) {
        return <Error status={403} desc="Страница не доступна" />;
    }

	if (error) {
		return <Error type={500} desc={error.message} />;
	}

	let listItems = [];

	if (!loading) {
		listItems = deliveries.map((delivery) => {
			let headerComponent = null;
			let blockColor = null;

			if (delivery.Order.Status === DELIVERY_ORDER_APPROVED || delivery.Order.Status === DELIVERY_ORDER_STARTED) {
				headerComponent = ApprovedOrderHeader;
				blockColor = "blue";
			} else if (delivery.Order.Status === DELIVERY_ORDER_FILLED) {
				headerComponent = FilledOrderHeader;
				blockColor = "red";
			}

			const nameRegionLastPointUnload = delivery.PlacesUnload.length ? delivery.PlacesUnload[delivery.PlacesUnload.length - 1].Region : "-";
			const dateShipmentRus = moment(delivery.DateShipment).format("D MMMM YYYY");

			return (
				<DeliveryItem
					color={blockColor}
					key={`${delivery.Id}_${delivery.Order.Status}`}
					header={headerComponent}
					content={CarrierContentItem}
					delivery={delivery}
					setStopListUpdate={setStopListUpdate}
					setNeedUpdate={setNeedUpdate}
					dateShipmentRus={dateShipmentRus}
					nameRegionLastPointUnload={nameRegionLastPointUnload}
					wasOpenedBeforeUpdate={wasOpenedBeforeUpdate}
					setWasOpenedBeforeUpdate={setWasOpenedBeforeUpdate}
					permissions={permissions}
				/>
			);
		});
	}

	return (
		<Spinner loading={loading}>
			<UserNotificationMessage />
			<NotificationMessage notifications={notifications} setNeedUpdate={false} setStopListUpdate={setStopListUpdate} loading={loading} />
			{totalCount != 0 ?
				<div className="application-block pagination pagination__up">
					<Pagination
						style={{ textAlign: "center" }}
						current={currentPage}
						pageSize={step}
						total={totalCount}
						showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
						onChange={onChange}
					/>
				</div>
				: null}
			{!loading ? deliveries.length ? <DeliveryGroupList deliveriesItems={listItems} /> : <DeliveryEmptyContent /> : null}
			{totalCount != 0 ?
				<div className="application-block pagination pagination__down">
					<Pagination
						style={{ textAlign: "center" }}
						current={currentPage}
						pageSize={step}
						total={totalCount}
						showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
						onChange={onChange}
					/>
				</div>
				: null}
		</Spinner>
	);
}
