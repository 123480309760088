import React, { useEffect, useState } from "react";
import Spinner from "../../../spinner";
import {Button, Col, notification, Table} from "antd";
import { tableHelper } from "../../../../helpers";
import { companyService } from "../../../../services";

export default function CarrierLegalEntities() {
  const [loading, setLoading] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [legalEntitiesData, setLegalEntitiesData] = useState(null);

  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdate(true);
    }
  }, [isNeedUpdate]);

  if (isNeedUpdate) {
    companyService
      .getEntitiesListAll()
      .then((response) => {

        setLegalEntitiesData(response);
        setIsNeedUpdate(false);
        setLoading(false);
      })
      .catch((errorMsg) => {
        setIsNeedUpdate(false);
        setErrorMsg(errorMsg);
        setLoading(false);
      });
  }

  if (errorMsg) {
    return <div className="content-error-block">{errorMsg}</div>;
  }

  const handleArchiveLegalEntity = (legalEntityId) => {
      setLoading(true);
      companyService
          .archiveLegalEntity(legalEntityId)
          .then((response) => {
              notification.success(
                  {
                      message: 'Архивирование Юр.лица',
                      description: response.message
                  }
              );

              setIsNeedUpdate(true);
          })
          .catch((err) => {
              notification.error(
                  {
                      message: 'Архивирование Юр.лица',
                      description: err.message
                  }
              );
          });
  }

  const handleUnarchiveLegalEntity = (legalEntityId) => {
      setLoading(true);
      companyService
          .unarchiveLegalEntity(legalEntityId)
          .then((response) => {
              notification.success(
                  {
                      message: 'Архивирование Юр.лица',
                      description: response.message
                  }
              );

              setIsNeedUpdate(true);
          })
          .catch((err) => {
              notification.error(
                  {
                      message: 'Архивирование Юр.лица',
                      description: err.message
                  }
              );
          });
  }

  const archiveButtonsRenderer = (isArchived, legalEntityId) => {
      if (isArchived == 1) {
          return (
              <>
                  <Button onClick={() => handleUnarchiveLegalEntity(legalEntityId)} size="small" className="small-button typical-btn-green">Убрать из архива</Button>
              </>
          );
      } else {
          return (
              <>
                  <Button onClick={() => handleArchiveLegalEntity(legalEntityId)} size="small" className="small-button typical-btn-red">Добавить в архив</Button>
              </>
          );
      }
  };

  const columns = [
      {
          title: "Название",
          dataIndex: "Name",
          key: "name",
          width: "20%",
          ...tableHelper.getColumnSearchProps("Name", searchText, setSearchText),
      },
      {
          title: "Архивировано",
          dataIndex: "Archived",
          width: "5%",
          render: (record) => {
              return record.Archived ? 'Да' : 'Нет';
          }
      },
      {
          title: "",
          dataIndex: "",
          width: "10%",
          render: (record) => {
              return archiveButtonsRenderer(record.Archived, record.Id);
          }
      }
  ];
  return (
    <React.Fragment>
      <h1 className="content-header">Юр.лица</h1>
      <Col span={24}>
        <Spinner loading={loading} tip="Загрузка...">
          <Table
            style={{ minWidth: "700px" }}
            columns={columns}
            dataSource={legalEntitiesData}
            locale={{ emptyText: "Данные отсутствуют" }}
            rowKey="name"
            size="small"
            pagination={{
              pageSize: 50,
            }}
          />
        </Spinner>
      </Col>
    </React.Fragment>
  );
}
