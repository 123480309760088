import React, { useState, useEffect } from 'react';
import { Button, Col, Modal, Row, Spin, Upload, Icon, DatePicker, notification } from "antd";
import {truckService, trailService, OWNERSHIP_TYPES} from "../../../services";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import InputField from '../../input-field';
import FieldBlock from '../../field-block';
import DividerBlock from '../../divider-block';
import SelectField from "../../select-field";
import moment from "moment";
import '../modals.css';
import { companyService, systemService } from "../../../services";
import NumberInputField from "../../number-input-field";

export default function ModalEditTruck({
	truckId,
	isShow,
	onCloseModal,
	blockedFields,
	setIsNeedUpdateList,
}) {
	const [loading, setLoading] = useState(false);

	const [fieldsErrors, setFieldsErrors] = useState(false);
	const [listBrands, setListBrands] = useState([]);


	const [driverId, setDriverId] = useState(null);
	const [companyId, setCompanyId] = useState(0);
	const [countryReg, setCountryReg] = useState('Россия');
	const [number, setNumber] = useState('');
	const [carrying, setCarrying] = useState([]);
	const [capacityValue, setCapacityValue] = useState(0);
	const [carryingValue, setCarryingValue] = useState(0);
	const [brand, setBrand] = useState(null);
	const [brandId, setBrandID] = useState(null);
	const [carryingCapacityValues, setCarryingCapacityValues] = useState([]);
	const [scanSts1, setScanSts1] = useState(null);
	const [scanSts2, setScanSts2] = useState(null);
	const [truckTypes, setTruckTypes] = useState(null);
	const [truckType, setTruckType] = useState(null);
	const [previewImage, setPreviewImage] = useState(null);
	const [previewVisible, setPreviewVisible] = useState(false);
	const [pallets, setPallets] = useState(0);
	const [ownershipType, setOwnershipType] = useState('');

	const [isNeedCarcassInfo, setIsNeedCarcassInfo] = useState(false);


	let bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px' };
	let dividerStyle = { margin: '15px 0px 45px 0px', display: "block", clear: "both", width: "100%", minWidth: "100%", height: "1px" }


	useEffect(() => {
		if (isShow && !loading) {
			companyService.getCompanyTrucksQuestionnaire(truckId).then((response) => {
				setBrand(response.brand);
				setBrandID(response.brandId)
				setCapacityValue(response.capacity);
				setCarryingValue(response.carrying);
				setNumber(response.number);
				setCountryReg(response.regCountry);
				setCompanyId(response.companyId);
				setTruckType(response.type);
				setScanSts1(response.scanSts1);
				setScanSts2(response.scanSts2);
				setPallets(response.pallets);
				setOwnershipType(response.ownershipType);
			})
			setLoading(true);
		}

		if (isShow) {
			systemService.getCarryingCapacity()
				.then((response) => {
					setCarryingCapacityValues(response);
				})
				.catch((error) => {
					setLoading(false);
					notification.error({
						message: "Получение грузоподъемностей и вместимостей",
						description: error,
					});
				});

			truckService.getListBrands()
				.then((resListBrands) => {
					setListBrands(resListBrands);
				})
				.catch((error) => {
					notification.error({
						message: "Получение списка марок",
						description: error,
					});
				});

			trailService.getTrailTypes()
				.then((response) => {
					setLoading(false);
					setTruckTypes(response);
				})
				.catch((error) => {
					notification.error({
						message: "Редактирование автомобиля",
						description: error,
					});
					setLoading(false);
				});
		}
	}, [isShow]);

	const compare = (a, b) => {
		return parseInt(a) - parseInt(b);
	};

    useEffect(() => {
        if (carryingCapacityValues) {
            setCarrying(Object.keys(carryingCapacityValues).sort(compare));
        }
    }, [carryingCapacityValues]);

	useEffect(() => {
		setIsNeedCarcassInfo(carryingValue < 20);
	}, [carryingValue]);

	const onClickEditTruck = () => {
		setLoading(true);
		setFieldsErrors(false);

		let brandIndex = null;
		let brandTitle = null;

		if (Number.isInteger(brand)) {
			brandIndex = listBrands.findIndex(x => x.Id === brand);
			brandTitle = (brandIndex !== -1) ? listBrands[brandIndex].Name : '';
			brandIndex = brand;
		} else {
			brandIndex = brandId;
			brandTitle = brand;
		}

		if (scanSts1 == null) {
			notification.error({
				message: "Отсутствует скан СТС1",
				description: "",
			});
			setLoading(false);
			return false;
		}
		if (scanSts2 == null) {
			notification.error({
				message: "Отсутствует скан СТС2",
				description: "",
			});
			setLoading(false);
			return false;
		}
		const truckData = {
			companyId: companyId,
			driverId: driverId,
			brandId: brandIndex,
			brand: brandTitle,
			countryReg: countryReg,
			number: number,
			type: truckType,
			carrying: carryingValue,
			capacity: capacityValue,
			scanSts1: scanSts1,
			scanSts2: scanSts2,
			pallets: pallets,
			ownershipType: ownershipType,
		};

		truckService.edit(truckId, truckData)
			.then((response) => {
				if (response.status === 'success') {
					notification.success({
						message: "Редактирование автомобиля",
						description: response.message,
					});
				} else if (response.status === 'error') {
					setFieldsErrors(response.errors);
				}
			})
			.catch((error) => {
				notification.error({
					message: "Редактирование автомобиля",
					description: error,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	function getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}

	const handlePreview = async file => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
	};

	const getFieldErrors = (nameField) => {
		return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
			? fieldsErrors[nameField][0]
			: null;
	};

	const uploaderPropsScanSts1 = {
		multiple: false,
		showUploadList: {
			showDownloadIcon: true,
			downloadIcon: 'download ',
			showRemoveIcon: true
		},
		onRemove: () => {
			setScanSts1(null);
		},
		beforeUpload: (file) => {
			setScanSts1(file);
			/* if (valuesInvalidFields) {
				setValuesInvalidFields({
					...valuesInvalidFields,
					scanSts1: file
				});
			} */
			return false;
		},
		fileList: (scanSts1 && scanSts1.length)
			? [{
				uid: '-1',
				name: "",
				url: !(scanSts1 instanceof File) ? scanSts1 : URL.createObjectURL(scanSts1),
				status: 'done'
			}]
			: null,
		listType: 'picture'
	};

	const uploaderPropsScanSts2 = {
		multiple: false,
		showUploadList: {
			showDownloadIcon: true,
			downloadIcon: 'download ',
			showRemoveIcon: true
		},
		onRemove: () => {
			setScanSts2(null);
		},
		beforeUpload: (file) => {
			setScanSts2(file);
			/* if (valuesInvalidFields) {
				setValuesInvalidFields({
					...valuesInvalidFields,
					scanSts1: file
				});
			} */
			return false;
		},
		fileList: (scanSts2 && scanSts2.length)
			? [{
				uid: '-1',
				name: "",
				url: !(scanSts2 instanceof File) ? scanSts2 : URL.createObjectURL(scanSts2),
				status: 'done'
			}]
			: null,
		listType: 'picture'
	};

	const handleCancel = () => setPreviewVisible(false);

	return (
		<Modal
			title={"Редактирование автомобиля"}
			cancelText="Закрыть"
			className="modal-window"
			align={null}
			visible={isShow}
			onCancel={onCloseModal}
			footer={[
				<Button htmlType="submit" key="submit" style={bigButtonStyle} type="primary" onClick={onClickEditTruck}>Сохранить</Button>,
			]}
		>
			<Spin spinning={loading} tip="Загрузка...">

				<Row gutter={[16, 24]}>
					<Col lg={12} md={24} sm={24} xs={24}>
						<SelectField
							title="Марка"
							name="brand"
							selectedValue={brand}
							values={listBrands}
							onChange={(value) => {
								setBrand(value);
							}}
							getErrors={getFieldErrors}
							placeholder="Выберите марку"
							notFoundText="Отсутствуют марки авто"
							style={{ width: "100%" }}
						/>
					</Col>
					<Col lg={12} md={24} sm={24} xs={24}>
						<SelectField
							title="Страна регистрации"
							name="countryReg"
							selectedValue={countryReg}
							values={[
								{ Id: 'Россия', Name: 'Россия' },
								{ Id: 'Беларусь', Name: 'Беларусь' },
								{ Id: 'Казахстан', Name: 'Казахстан' },
								{ Id: 'Другая страна', Name: 'Другая страна' }
							]}
							onChange={(value) => {
								setCountryReg(value);
							}}
							getErrors={getFieldErrors}
							style={{ width: "100%" }}
						/>
					</Col>
				</Row>
				<Row gutter={[16, 24]}>
					<Col lg={12} md={24} sm={24} xs={24}>
						<InputField
							name="number" label="Госномер"
							value={number} setValue={setNumber}
							getFieldErrors={getFieldErrors}
						/>
					</Col>
					<Col lg={6} md={12} sm={12} xs={12}>
						<SelectField
							showSearch={false}
							title="Грузоподъемность (тонны)"
							name="carrying"
							selectedValue={carryingValue}
							values={carrying}
							onChange={(value) => {
								setCapacityValue(carryingCapacityValues[value][0]);
								setCarryingValue(value);
								setTruckType(null);
							}}
							getErrors={getFieldErrors}
							style={{ width: "100%" }}
						/>
					</Col>
					{isNeedCarcassInfo ?
						<Col lg={6} md={12} sm={12} xs={12}>
							<SelectField
								showSearch={false}
								title="Вместимость (в м3)"
								name="capacity"
								selectedValue={capacityValue}
								values={carryingCapacityValues[carryingValue]}
								onChange={(value) => {
									setCapacityValue(value);
								}}
								getErrors={getFieldErrors}
								style={{width: "100%"}}
							/>
						</Col> : null}
					<Col lg={8} md={12} sm={12} xs={12}>
						<SelectField
							name="ownershipType"
							title="Тип владения"
							values={OWNERSHIP_TYPES}
							selectedValue={ownershipType}
							onChange={(value) => {
								setOwnershipType(value);
							}}
							getErrors={getFieldErrors}
							style={{width: "100%"}}
						/>
					</Col>
				</Row>
				{isNeedCarcassInfo ?
					<Row gutter={[16, 24]}>
						<Col lg={6}>
							<NumberInputField
								name="pallets" label="Количество паллет"
								value={pallets} setValue={setPallets}
								getFieldErrors={getFieldErrors}
							/>
						</Col>
						<Col lg={24} md={24} sm={24} xs={24}>
							<SelectField
								name="type"
								title="Тип кузова"
								values={truckTypes}
								selectedValue={truckType}
								onChange={(value) => {
									setTruckType(value);
								}}
								getErrors={getFieldErrors}
								style={{width: "100%"}}
							/>
						</Col>
					</Row> : null}

				<span className="divider-label">Загрузите сканы документов</span>
				<DividerBlock style={dividerStyle} />

				<Row gutter={[16, 24]}>
					<Col lg={12} md={24} sm={24} xs={24}>
						<FieldBlock name="scanSts1" getErrors={getFieldErrors}>
							<Upload className="single-btn-upload" {...uploaderPropsScanSts1} onPreview={handlePreview}>
								<Button htmlType="button">
									<svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
										<path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
										<path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
										<path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
									</svg> Скан СТС (1 сторона)
								</Button>
							</Upload>
						</FieldBlock>
					</Col>
					<Col lg={12} md={24} sm={24} xs={24}>
						<FieldBlock name="scanSts2" getErrors={getFieldErrors}>
							<Upload className="single-btn-upload" {...uploaderPropsScanSts2} onPreview={handlePreview}>
								<Button htmlType="button">
									<svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
										<path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
										<path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
										<path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
									</svg> Скан СТС (2 сторона)
								</Button>
							</Upload>
						</FieldBlock>
					</Col>
				</Row>
			</Spin>
			<Modal className="modal-window" visible={previewVisible} footer={null} onCancel={handleCancel}>
				<img alt="example" style={{ width: '100%' }} src={previewImage} />
			</Modal>
		</Modal>
	);
}