import React, {useEffect, useState} from 'react';
import Spinner from '../../../spinner';
import {Button, Col, Table, Row} from 'antd';
import ModalEditEmployee from '../../../modals/edit-employee';
import {tableHelper} from '../../../../helpers';
import {consignorService} from "../../../../services";
import ModalFormStaffUser from "../../../modals/form-staff-user";
import ModalChangeUserLogin from "../../../modals/change-user-login";

export default function StaffUsers() {
  const [loading, setLoading] = useState(false);
  const [isNeedUpdateStaffUsers, setIsNeedUpdateStaffUsers] = useState(true);
  const [showModalCreateEmployee, setShowModalCreateEmployee] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [staffUsersData, setStaffUsersData] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [currentUserID, setCurrentUserID] = useState(null);
  const [showUserChangeLoginModal, setShowUserChangeLoginModal] = useState(null);

  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      if (isNeedUpdateStaffUsers) {
        consignorService
          .getStaffUsersList()
          .then(response => {
            setStaffUsersData([]);
            let reindexedTableData = [];

            response.users.forEach(item => {
              if (item.Status !== 'Архивный') {
                reindexedTableData.push({
                  name: item.fio,
                  status: item.status.description,
                  id: item.id,
                  phone: item.phone,
                });
              }
            });

            setIsNeedUpdateStaffUsers(false);
            setLoading(false);
            setStaffUsersData(reindexedTableData);
          })
          .catch(errorMsg => {
            setIsNeedUpdateStaffUsers(false);
            setLoading(false);
            setErrorMsg(errorMsg);
          });
      }
    }
  }, [isNeedUpdateStaffUsers]);


  if (errorMsg) {
    return (<div className="content-error-block">{errorMsg}</div>);
  }

  const columns = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'id',
      width: '33%',
      ...tableHelper.getColumnSearchProps('name', searchText, setSearchText)
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: '10%'
    },
    {
      title: 'Редактирование',
      key: 'edit',
      width: '15%',
      render: data => {
        return (
          <React.Fragment>
            <Button
              style={{marginLeft: "0px", width: "100%"}}
              type="primary"
              htmlType="button"
              onClick={() => {
                setShowModalEdit(data.name);
                setCurrentUserID(data.id);
              }}
              size="small"
              className="small-button typical-btn-green"
            >
              Редактировать
            </Button>
            {data.status.name !== "deleted" && <>
              <Button style={{marginLeft: "0px", width: "100%"}}
                      size="small"
                      className="small-button typical-btn-green"
                      onClick={() => {
                        setShowUserChangeLoginModal(data.id);
                      }}>
                Изменить логин
              </Button>
              <ModalChangeUserLogin
                id={data.id}
                isShow={showUserChangeLoginModal === data.id}
                setIsShow={() => {
                  setShowUserChangeLoginModal(null)
                }}
                setNeedUpdateList={setIsNeedUpdateStaffUsers}
              />
            </>
            }
          </React.Fragment>
        );
      }
    }
  ];
  return (
    <React.Fragment>
      <h1 className="content-header">Сотрудники</h1>
      <Row gutter={48} style={{paddingRight: '0'}}>
        <Col span={24} style={{textAlign: 'right', height: '40px', marginBottom: '24px'}}>
          <Button
            type="primary"
            htmlType="button"
            onClick={() => {
              setShowModalCreateEmployee(true);
            }}
            style={{backgroundColor: '#1CAA53', border: 'none', height: '100%'}}
          >
            Создать сотрудника
          </Button>
          <ModalFormStaffUser
            isShow={showModalCreateEmployee}
            setIsShow={setShowModalCreateEmployee}
            onSuccess={() => {
              setShowModalCreateEmployee(false);
              setIsNeedUpdateStaffUsers(true);
            }}
          />
        </Col>
      </Row>
      <Col span={24}>
        <Spinner loading={loading} tip="Загрузка...">
          <Table
            style={{minWidth: '700px'}}
            columns={columns}
            dataSource={staffUsersData}
            locale={{emptyText: 'Данные отсутствуют'}}
            rowKey="id"
            size="small"
            pagination={{
              pageSize: 50
            }}
          />
          <ModalFormStaffUser
            isShow={showModalEdit}
            setIsShow={setShowModalEdit}
            onSuccess={() => {
              setShowModalCreateEmployee(false);
              setIsNeedUpdateStaffUsers(true);
            }}
            userID={currentUserID}
          />
        </Spinner>
      </Col>
    </React.Fragment>
  );
}
