import { orderDeliveryService } from "../../../services";
import InputField from "../../input-field";
import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Modal, notification, Row, Spin} from "antd";
import '../modals.css';
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import FieldBlock from "../../field-block";
import moment from "moment";

export default function ModalMakeCounteroffer({ isShow, setIsShow, orderDeliveryId, setLoadingMainModal, orderDateLoading, setNeedUpdateContact }) {

    const [loading, setLoading] = useState(false);
    const [fieldsErrors, setFieldsErrors] = useState([]);

    const [sum, setSum] = useState(0);
    const [dateLoading, setDateLoading] = useState(null);

    let bigButtonStyle = { width: '300px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px', marginTop: '60px' };

    useEffect(() => {
        if (orderDateLoading && isShow) {
            let date = moment(orderDateLoading, 'YYYY-MM-DD');
            setDateLoading(date);
        }
    }, [orderDateLoading, isShow]);

    const onClickMakeCounteroffer = () => {
        setLoading(true);

        const counterofferData = {
            orderDeliveryId: orderDeliveryId,
            sum: sum,
            dateLoading: dateLoading.format('DD.MM.YYYY'),
        };

        orderDeliveryService.makeCounteroffer(counterofferData)
            .then((response) => {
                setLoading(false);
                if (response.status === 'success') {
                    notification.success({
                        message: "Встречное предложение успешно создано",
                        description: response.message,
                    });

                    setLoading(false);
                    setIsShow(false);
                    setNeedUpdateContact(true);
                    setLoadingMainModal('counteroffer');
                } else if (response.status === 'error') {
                    notification.error({
                        message: "Встречное предложение отклонено",
                        description: response.message,
                    });

                    setLoading(false);
                    setFieldsErrors(response.errors);
                }
            })
            .catch((error) => {
                notification.error({
                    message: "Создание встречного предложения",
                    description: error,
                });

                setLoading(false);
            });
    };

    const getFieldErrors = (nameField) => {
        return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
            ? fieldsErrors[nameField][0]
            : null;
    };

    return (
        <Modal
            title={<span className="ant-modal-title">Сделать встречное предложение</span>}
            cancelText="Закрыть"
            className="modal-window"
            style={{ maxWidth: '650px' }}
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
            }}
            footer={[
                <Button htmlType="submit" style={bigButtonStyle} key="submit" type="primary" onClick={onClickMakeCounteroffer}>Отправить</Button>,
            ]}
        >
            <Spin spinning={loading} tip="Загрузка...">
                <Row gutter={[12, 24]}>
                    <Col lg={6} md={6} sm={6} xs={6}>
                        <InputField
                            name="name" label="Сумма"
                            value={sum} setValue={setSum}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                        <FieldBlock name="dateLoading" title="Дата загрузки" getErrors={getFieldErrors}>
                            <DatePicker
                                style={{ width: "100%" }}
                                defaultValue={dateLoading}
                                value={dateLoading}
                                format="DD.MM.YYYY"
                                size="large"
                                name="dateLoading"
                                locale={localeDatePicker}
                                onChange={value => {
                                    setDateLoading(value);
                                }}
                            />
                        </FieldBlock>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}
