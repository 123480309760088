import React, {useState} from 'react';
import {Row, Col, Checkbox, DatePicker} from 'antd';
import {systemService} from "../../../../../services";
import InputField from "../../../../input-field";
import SelectField from "../../../../select-field";
import DividerBlock from '../../../../divider-block';
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import FieldBlock from "../../../../field-block";


export default function FourthStep({ stepFields, setFields, getFieldErrors, country }) {
    const firstToUpper = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    
    function getPassportDivisionsList(divisionCode, setDivisions) {
        systemService.getPassportDivisionsList(divisionCode)
            .then(data => {
                setDivisions(data);
            })
            .catch();
    }

    return (
        <Row>
            <>
                <DividerBlock label="Поручитель" />
                <Row>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="guarantorSecondName"
                            label="Фамилия"
                            value={stepFields.guarantorSecondName}
                            setValue={value => setFields({...stepFields, guarantorSecondName: firstToUpper(value)})}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="guarantorFirstName"
                            label="Имя"
                            value={stepFields.guarantorFirstName}
                            setValue={value => setFields({...stepFields, guarantorFirstName: firstToUpper(value)})}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="guarantorPatronymic"
                            label="Отчество"
                            value={stepFields.guarantorPatronymic}
                            setValue={value => setFields({...stepFields, guarantorPatronymic: firstToUpper(value)})}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="guarantorPassportSerial"
                            label="Серия паспорта"
                            value={stepFields.guarantorPassportSerial}
                            setValue={value => setFields({...stepFields, guarantorPassportSerial: value.replace(/[^0-9]/g, '')})}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField
                            name="guarantorPassportNumber"
                            label="Номер паспорта"
                            value={stepFields.guarantorPassportNumber}
                            setValue={value => setFields({...stepFields, guarantorPassportNumber: value.replace(/[^0-9]/g, '')})}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <FieldBlock name="guarantorPassportDate" title="Дата выдачи паспорта" getErrors={getFieldErrors}>
                            <DatePicker
                                style={{ width: "100%" }}
                                defaultValue={stepFields.guarantorPassportDate}
                                value={stepFields.guarantorPassportDate}
                                format="DD.MM.YYYY"
                                size="large"
                                name="guarantorPassportDate"
                                locale={localeDatePicker}
                                onChange={value => setFields({...stepFields, guarantorPassportDate:  value})}
                            />
                        </FieldBlock>
                    </Col>
                    { country === 1 ? (
                        <Col lg={24} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                            <SelectField
                                title="Код подразделения паспорта"
                                name="guarantorPassportDivision"
                                selectedValue={stepFields.guarantorPassportDivision}
                                values={stepFields.guarantorPassportDivisionsList}
                                onChange={(value) => {
                                    setFields({...stepFields, guarantorPassportDivision: value});
                                }}
                                onSearch={(value) => {
                                    if (value.length > 2) {
                                        getPassportDivisionsList(value, (data) => {
                                            setFields({
                                                ...stepFields,
                                                guarantorPassportDivisionsList: data
                                            });
                                        });
                                    }
                                }}
                                getErrors={getFieldErrors}
                                placeholder="Укажите код подразделения"
                                notFoundText={null}
                                showArrow={false}
                                style={{ width: "100%" }}
                            />
                        </Col>
                    ) : (
                        <>
                            <Col lg={12} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorPassportDivision"
                                    label="Код подразделения паспорта"
                                    value={stepFields.guarantorPassportDivision}
                                    setValue={value => setFields({...stepFields, guarantorPassportDivision: value})}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                            <Col lg={12} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                                <InputField
                                    name="guarantorPassportRegistration"
                                    label="Где выдан паспорт"
                                    value={stepFields.guarantorPassportRegistration}
                                    setValue={value => setFields({...stepFields, guarantorPassportRegistration: value})}
                                    getFieldErrors={getFieldErrors}
                                />
                            </Col>
                        </>
                    )
                    }
                    <DividerBlock label="Адрес регистрации" />
                    <Row>
                        <Col lg={24} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                            <InputField name="guarantorRegAddress" label="Адрес" value={stepFields.guarantorRegAddress} setValue={value => setFields({...stepFields, guarantorRegAddress: value})} getFieldErrors={getFieldErrors} />
                        </Col>
                    </Row>

                    <DividerBlock label="Фактический адрес проживания" />
                    <Row>
                        <Col lg={24} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                            <InputField name="guarantorFactAddress" label="Адрес" value={stepFields.guarantorFactAddress} setValue={value => setFields({...stepFields, guarantorFactAddress: value})} getFieldErrors={getFieldErrors} />
                        </Col>
                    </Row>
                </Row>
            </>
        </Row>
    );
}
