import React from 'react';

import {Link} from 'react-router-dom';

import config from "./typeConfig";

export default function Error({status, img, desc = "Произошла непредвиденная ошибка"}) {

    const pageStatus = status in config ? status : 500;
    const imgStyle = {backgroundImage: `url('${img || config[pageStatus].img}')`};

    return (
        <div className="exception">
            <div className="imgBlock">
                <div className="imgEle" style={imgStyle}/>
            </div>
            <div className="content">
                <h1>{pageStatus}</h1>
                <div className="desc">{desc}</div>
                <div className="actions">
                    <Link to="/">Вернуться на главную</Link>
                </div>
            </div>
        </div>
    );
}
