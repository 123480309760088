import React, { useEffect, useState } from 'react';
import {Button, Col, Icon, notification, Row, Spin, Upload} from "antd";
import {companyService, consignorService, distributionCenterService, systemService} from "../../../../services";
import {approveOrderService} from "../../../../services/approve-order.service";
import {formHelper} from "../../../../helpers";
import DividerBlock from "../../../divider-block";
import FieldBlock from "../../../field-block";
import SelectField from "../../../select-field";
import InputField from "../../../input-field";


export default function CreateOrder({ legalEntityID, shipperCompanyID, needUpdate, contractType, onSuccess }) {

    const [loading, setLoading] = useState(true);
    const [distributionCentersList, setDistributionCentersList] = useState([]);

    const [swift, setSwift] = useState('');

    const [currencyTypesList, setCurrencyTypesList] = useState([]);
    const [currencyTypeId, setCurrencyTypeId] = useState(null);

    const [legalEntityInfo, setLegalEntityInfo] = useState(null);
    const [shipperCompanyInfo, setShipperCompanyInfo] = useState(null);

    const [isInternationalOrder, setIsInternationalOrder] = useState(false);

    const [fieldsErrors, setFieldsErrors] = useState({});

    const [addParams, setAddParams] = useState(null);
    const [addDocs, setAddDocs] = useState([]);

    const buttonStyle = {
        width: '290px'
    };

    useEffect(() => {
        if (needUpdate) {
            setDistributionCentersList([]);

            distributionCenterService.getCompanies(true, true, null, shipperCompanyID)
                .then((response) => {
                    setDistributionCentersList(response);
                })
                .catch((err) => {
                    notification.error({
                        message: "Получение списка РЦ",
                        description: err,
                    });
                });
        }
    }, [needUpdate]);

    useEffect(() => {
        if (legalEntityID && needUpdate) {
            setLegalEntityInfo(null);

            companyService
                .getLegalEntityFullInfo(legalEntityID)
                .then(resp => {
                    setLegalEntityInfo(resp);

                    if (resp.Swift) {
                        setSwift(resp.Swift);
                    }
                })
                .catch(err => {
                    notification.error({
                        message: "Получение информации о юр.лице перевозчика",
                        description: err
                    });
                });
        }
    }, [legalEntityID, needUpdate]);

    useEffect(() => {
        if (shipperCompanyID && needUpdate) {
            setShipperCompanyInfo(null);

            consignorService
                .geShipperCompanyInfo(shipperCompanyID)
                .then(resp => {
                    setShipperCompanyInfo(resp);
                })
                .catch(err => {
                    notification.error({
                        message: "Получение информации о юр.лице грузовладельца",
                        description: err
                    });
                });
        }
    }, [shipperCompanyID, needUpdate]);

    useEffect(() => {
        if (legalEntityID && shipperCompanyID && needUpdate) {
            systemService
                .getCurrencyListOnContractCreate(legalEntityID, shipperCompanyID)
                .then(resp => {
                    setCurrencyTypesList(resp)
                    if (resp.length === 1) {
                        setCurrencyTypeId(resp[0].Id);
                    }
                })
                .catch(err => {
                    notification.error({
                        message: "Получение списка валют",
                        description: err
                    });
                });

            approveOrderService
                .getAdditionalParamsOnContractCreate(legalEntityID, shipperCompanyID)
                .then(resp => {
                    let params = [];
                    let docs = [];

                    for (const [key, value] of Object.entries(resp)) {
                        if (value.Type === "doc") {
                            if (value.Value) {
                                let contentType = 'application/pdf';

                                let blob = formHelper.b64toBlob(value.Value, contentType);

                                let file = new File([blob], 'Докумнет.pdf');
                                file.uid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                                file.url = URL.createObjectURL(blob);
                                file.fileName = value.Name;
                                file.fileAlias = value.Alias;
                                file.thumbUrl = 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg';

                                value.Value = file;
                            }

                            docs.push(value);
                        } else {
                            params.push(value);
                        }
                    }

                    setAddParams(params);
                    setAddDocs(docs);
                })
                .catch(err => {
                    notification.error({
                        description: "Ошибка",
                        message: err,
                    });
                })
        }
    }, [legalEntityID, shipperCompanyID, needUpdate]);

    useEffect(() => {
        if (distributionCentersList.length && legalEntityInfo && shipperCompanyInfo && needUpdate && currencyTypesList) {
            setIsInternationalOrder(!(legalEntityInfo.CountryId === shipperCompanyInfo.Country.Id));

            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [distributionCentersList, legalEntityInfo, shipperCompanyInfo, currencyTypesList]);

    const getFieldErrors = (fieldName) => {
        return typeof fieldsErrors[fieldName] !== "undefined"
            ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
                return fieldErrors + "; " + error;
            })
            : null;
    };

    const getUploaderClass = (index) => {
        return {
            multiple: false,
            showUploadList: true,
            onRemove: () => {
                let docs = addDocs;
                docs[index].Value = null;

                setAddDocs([...docs]);
            },
            beforeUpload: (file) => {
                let docs = addDocs;
                docs[index].Value = file;
                setAddDocs([...docs]);
                return false;
            },
            fileList: addDocs[index].Value ? [addDocs[index].Value] : null,
            listType: 'picture',
        };
    };

    const handleSendOrder = (distCenterId) => {
        setLoading(true);
        setFieldsErrors({});

        companyService
            .createOrder(legalEntityID, shipperCompanyID, distCenterId, currencyTypeId, addParams, addDocs, contractType)
            .then(resp => {
                if (resp.code !== 400) {
                    notification.success({
                        message: "Отправка заявки",
                        description: resp.message,
                    });
                    setLoading(false);
                    onSuccess();
                } else {
                    notification.error({
                        message: "Отправка заявки",
                        description: resp.message,
                    });
                    setFieldsErrors(resp.errors);
                    setLoading(false);
                }
            })
            .catch(errMsg => {
                notification.error({
                    message: "Отправка заявки",
                    description: errMsg,
                });
                setLoading(false);
            });
    }

    const ordersButtonsRender = () => {
        return distributionCentersList.map((distCenter) => {
            if (distCenter.IsActive) {
                return (
                    <Col lg={8} sm={24}>
                        <Button style={buttonStyle} onClick={() => handleSendOrder(distCenter.Id)} className="small-button typical-btn-green">
                            {distCenter.VisibleName}
                        </Button>
                    </Col>
                );
            }
            return null;
        })
    };

    const renderAddParams = () => {
        if (!addParams || addParams.length === 0) {
            return null;
        }

        let formFields = addParams.map((value, index) => {
            switch (value.Type) {
                case "param":
                    return <Col lg={8} sm={24}>
                        <InputField
                            name={value.Name}
                            title={value.Alias}
                            label={value.Alias}
                            required={true}
                            value={addParams[index].Value}
                            setValue={(value) => {
                                let params = addParams;
                                params[index].Value = value;
                                setAddParams([...params]);
                            }}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                default:
                    return null;
            }
        });

        return (
            <Row gutter={5}>
                <DividerBlock label="Допольнительные параметры" />
                {formFields}
            </Row>
        );
    };

    const renderAddDocs = () => {
        if (!addDocs || addDocs.length === 0) {
            return null;
        }

        let docsFields = addDocs.map((file, index) => {
            return <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name={file.Name} getErrors={getFieldErrors}>
                    <Upload
                        className="single-btn-upload"
                        name={file.Name}
                        {...getUploaderClass(index)}
                    >
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> {file.Alias}
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
        });

        return (
            <Row>
                <DividerBlock label="Допольнительные документы" />
                {docsFields}
            </Row>
        );
    };


    return (
        <Spin spinning={loading} tip="Загрузка...">
            <Row gutter={10}>
                {isInternationalOrder && (
                    <>
                        <Col lg={8} sm={24}>
                            <SelectField
                                title="Валюта договора"
                                name="currencyId"
                                selectedValue={currencyTypeId}
                                values={currencyTypesList}
                                onChange={(value) => {
                                    setCurrencyTypeId(value);
                                }}
                                getErrors={getFieldErrors}
                                placeholder="Выберите валюту договора"
                                style={{ width: "100%" }}
                            />
                        </Col>
                    </>
                )}
            </Row>
            {renderAddParams()}
            {renderAddDocs()}
            <Row style={{marginBottom: "300px"}}>
                {ordersButtonsRender()}
            </Row>
        </Spin>
    );
}