import React, {useContext, useEffect, useState} from 'react';
import moment from "moment";
import {userServices} from "../../../../../services";
import {useFetchDeliveriesList} from "../../../../../hooks/use-fetch-deliveries-list";
import Error from "../../../../error";
import Spinner from '../../../../spinner';
import DeliveryItem from "../../../../delivery-item-block";
import ModalCallNumber from '../../../../modals/call-number';
import CarrierContentItem from '../../../../delivery-item-content/carrier/content-item';
import DeliveryEmptyContent from '../../../../delivery-empty-content';
import DeliveryGroupList from "../../../../delivery-group-list";
import {AppContext} from "../../../../../contexts/app-context";
import GoogleAndroidAppLink from "../../../../google-android-app-link";
import {VIEW_NEW_DELIVERIES_DRIVER} from "../../../../../helpers/user_permission.helper";
import UserNotificationMessage from "../../../../user-message";
import NotificationMessage from "../../../../modals/notification-message";
import Price from "../../../../price"



function HeaderItem ({delivery, buttonEnabled, openModal, setNeedUpdate, setStopListUpdate, setLoadingDelivItem}) {

    const [showModalCallNumber, setShowModalCallNumber] = useState(false);
    const [paymentType, setPaymentType] = useState(delivery.AllowedPaymentTypes[0].Id);

    const renderHeaderBtn = (delivery) => {
        const user = userServices.getUser();

    if (delivery.Order) {
        if (user.roleName === 'driver' && buttonEnabled) {
            return (
                <div className="application-topline-carrier-item-col application-topline-carrier-item-col-button">
                    <button className="btn typical-btn typical-btn-blue"
                            onClick={() => {
                                setStopListUpdate(true);
                                setShowModalCallNumber(true);
                            }}
                    >
                        Подать заявку
                    </button>
                    <ModalCallNumber
                        isShow={showModalCallNumber}
                        setIsShow={setShowModalCallNumber}
                        delivery={delivery}
                        setNeedUpdateDeliveryList={setNeedUpdate}
                        setStopListUpdate={setStopListUpdate}
                        paymentType={paymentType}
                    />
                </div>
            )
        }
    }
};

    const dateShipmentRus = moment(delivery.DateShipment).format('D MMMM YYYY');

    const nameRegionLastPointUnload = (delivery.PlacesUnload.length)
        ? delivery.PlacesUnload[delivery.PlacesUnload.length - 1].Region
        : '-';

    const regionLoad = (delivery.PlaceLoad.Region)
        ? delivery.PlaceLoad.Region
        : '-';
    return (
        <div className="application-topline">
            <div className="application-topline-carrier">
                <div className="application-topline-carrier-item">
                    <div
                        className="application-topline-carrier-item-col application-topline-carrier-item-col-city">
                        <p className="typical-icon-field icon-field-patch">{regionLoad}</p>
                        <p className="typical-icon-field icon-field-dispatch">{nameRegionLastPointUnload}</p>
                    </div>
                    <div className="application-topline-carrier-item-col application-topline-carrier-item-col-date">
                        <p className="typical-icon-field icon-field-calendar">{dateShipmentRus}</p>
                    </div>
                </div>
                <div className="application-topline-carrier-item">
                    <div className="application-topline-carrier-item-col">
                        <Price delivery={delivery} setPaymentType={setPaymentType}/>
                    </div>
                    {renderHeaderBtn(delivery)}
                </div>
            </div>
        </div>
    );
}

export default function CarrierNewDeliveries() {

    const user = userServices.getUser();

    if (!user.isAccessTo(VIEW_NEW_DELIVERIES_DRIVER)) {
        return (<Error status={403} desc="Страница не доступна"/>);
    }

    const appContext = useContext(AppContext);

    useEffect(() => {
        appContext.initFilter();
    }, []);

    const [{
        error,
        loading,
        data: deliveries,
        openModal,
        setStopListUpdate,
        setNeedUpdate,
        notifications
    }] = useFetchDeliveriesList('delivery/get-free', true);

    if (error) {
        return (<Error type={500} desc={error.message}/>);
    }
    if (loading) {
        return (<Spinner loading={loading}/>);
    }

    let listGroupDeliveries = {};

    for (let itemIdx in deliveries) {
        //TODO !deliveries.hasOwnProperty('secondsToRefuse') - временно
        if (deliveries.hasOwnProperty(itemIdx) && !deliveries.hasOwnProperty('secondsToRefuse')) {
            const delivery = deliveries[itemIdx];
            const region = delivery.PlaceLoad.Region;

            if (typeof listGroupDeliveries[region] !== "undefined") {
                listGroupDeliveries[region].push(delivery);
            } else {

                const itemsGroup = [];
                itemsGroup.push(delivery);

                listGroupDeliveries[region] = itemsGroup;
            }
        }
    }

    let listDeliveries = [];

    Object.entries(listGroupDeliveries).forEach(([nameRegion, itemsDelivery]) => {

        const deliveriesElements = itemsDelivery.map((delivery) => {

            let initMoment;
            if(delivery.secondsToAutoRefuse){
                initMoment = +new Date();
            }

            return (
                <DeliveryItem
                    key={delivery.Id}
                    header={HeaderItem}
                    content={CarrierContentItem}
                    delivery={delivery}
                    openModal={openModal}
                    setStopListUpdate={setStopListUpdate}
                    setNeedUpdate={setNeedUpdate}
                    secondsToAutoRefuse={delivery.secondsToAutoRefuse}
                    initMoment={initMoment}
                />
            );
        });

        listDeliveries.push(
            <DeliveryGroupList key={nameRegion} groupName={nameRegion} deliveriesItems={deliveriesElements}/>
        );
    });

    return (
        <Spinner loading={loading}>
            <UserNotificationMessage/>
            <GoogleAndroidAppLink/>
            <NotificationMessage notifications={notifications} setNeedUpdate={setNeedUpdate} setStopListUpdate={setStopListUpdate}/>
            {(deliveries.length) ? listDeliveries : <DeliveryEmptyContent/>}
        </Spinner>
    );
}
