import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Table, Row, Icon, Tooltip, notification} from "antd";
import {reportService} from "../../../../services/report.services";
import SelectField from "../../../select-field";
import {distributionCenterService, companyService} from "@services";
import ModalDeliveryInfo from "../../../modals/delivery-info";
import {httpRequest} from "../../../../services/http-request";
import ModalListOrdersDelivery from "../../../modals/list-orders-delivery";

export default function StatsFreeDeliveries() {
  const [loading, setLoading] = useState(false);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [expandAll, setExpandAll] = useState(false);

  const [distCenters, setDistCenters] = useState([]);
  const [selectedDistCenters, setSelectedDistCenters] = useState([]);

  const [shipperCompanyID, setShipperCompanyID] = useState([]);
  const [selectedShipperCompanyID, setSelectedShipperCompanyID] = useState([]);

  const [isReturn, setIsReturn] = useState('null');

  const [showModal, setShowModal] = useState(false);
  const [response, getResponse] = useState(0);
  const [deliveryInfo, setDeliveryInfo] = useState(undefined);

  const [showModalOrdersDelivery, setShowModalOrdersDelivery] = useState(false);
  const [responseOrder, getResponseOrder] = useState(0);

  // полученные данные отчета
  const [reportData, setReportData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);

  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateReport(true);
      distributionCenterService.getCompanies()
        .then((response) => {
          setDistCenters(response);
        }).catch((errorMsg) => {
        setErrorMsg(errorMsg);
      });
      companyService.getShipperCompanyList()
        .then((response) => {
          setShipperCompanyID(response);
        }).catch((errorMsg) => {
        setErrorMsg(errorMsg);
      });
    }
  }, []);

  useEffect(() => {
    if (isNeedUpdateReport) {
      let isReturnData = null;
      if (isReturn !== 'null') {
        isReturnData = isReturn;
      }

      // запрос на получение данных отчета
      reportService.getStatsFreeDeliveries(selectedDistCenters, selectedShipperCompanyID, isReturnData)
        .then((reportData) => {

          const reportFullDataParsed = reportData.fullData.map((item, index) => {
            return {
              id: index + 1,
              title: item.title,
              data: item.data,
              deliveriesCount: item.summary.deliveriesCount,
              assessedVal: item.summary.assessedVal,
              pointsCount: item.summary.pointsCount,
            }
          });

          const summaryDataParsed = reportData.summaryByDistCenter.map((item, index) => {
            return {
              id: item.distributionCenter.id,
              distributionCenter: item.distributionCenter.name,
              deliveriesCount: item.summary.deliveriesCount,
              assessedVal: item.summary.assessedVal,
              pointsCount: item.summary.pointsCount
            }
          })

          setIsNeedUpdateReport(false);
          setLoading(false);
          setReportData(reportFullDataParsed);
          setSummaryData(summaryDataParsed);

        }).catch((errorMsg) => {
          setIsNeedUpdateReport(false);
          setLoading(false);
          setErrorMsg(errorMsg);
        });
    }
  }, [isNeedUpdateReport])

  const closeModalHandler = () => {
    setShowModal(false);
    getResponse(0);
    setDeliveryInfo(undefined);
  }

  // получаем данные для модалки "История рейса"
  useEffect(() => {
    if (response !== 0) {
      let url = "delivery/get-delivery-history?deliveryId=" + response;
      httpRequest.get(url).then(function (res) {
        setDeliveryInfo(res.data);
        getResponse(0);
      }).catch((e) => {
        notification.error({
          message: "У Вас нет доступа!",
          description: e,
        });
        return false;
      })
    }

  }, [response]);

  // отображение фатальной ошибки
  if (errorMsg) {
    return (<div className="content-error-block">{errorMsg}</div>);
  }

  // список столбцов таблиц
  const columns = [
    {
      title: '',
      width: 45
    },
    {
      title: '',
      width: 45
    },
    {
      title: 'Период',
      dataIndex: 'title',
      key: 'title',
      width: 380
    },
    {
      title: 'Рейсов',
      dataIndex: 'deliveriesCount',
      key: 'deliveriesCount',
    },
    {
      title: "Стоимость",
      key: 'assessedVal',
      width: 150,
      render: (record) => (
        <>{record.assessedVal.toLocaleString("ru")}</>
      )
    },
    {
      title: "Кол-во ТТ",
      dataIndex: 'pointsCount',
      key: 'pointsCount',
      width: 90
    },
  ];

  const nestedColumns = [
    {
      title: '',
      width: 45
    },
    {
      title: '',
      width: 45
    },
    {
      title: 'Подразделение',
      dataIndex: 'distributionCenter.name',
      key: 'distributionCenter.name',
      width: 380
    },
    {
      title: 'Рейсов',
      dataIndex: 'summary.deliveriesCount',
      key: 'summary.deliveriesCount',
    },
    {
      title: "Стоимость",
      render: (record) => (
        <>{record.summary.assessedVal.toLocaleString("ru")}</>
      ),
      key: 'summary.assessedVal',
      width: 150,
    },
    {
      title: "Кол-во ТТ",
      dataIndex: 'summary.pointsCount',
      key: 'summary.pointsCount',
      width: 90
    },
  ];

  const deliveriesTableColumns = [
    {
      title: '',
      width: 45
    },
    {
      title: '',
      width: 45
    },
    {
      title: <>Номер<br/>рейса</>,
      key: 'number',
      render: (record) => (
        <a className="link link--underline"
           href="#"
           onClick={(e) => {
             e.preventDefault();
             setShowModal(true);
             getResponse(record.id);
           }}
        >{record.number}</a>
      )
    },
    {
      title: <>Дата<br/>создания</>,
      dataIndex: 'dateCreated',
      key: 'dateCreated',
    },
    {
      title: <>Способ<br/>доставки</>,
      dataIndex: 'carcassType',
      key: 'carcassType',
    },
    {
      title: <>Время<br/>поиска</>,
      dataIndex: 'hoursInSearch',
      key: 'hoursInSearch',
      className: "center",
    },
    {
      title: "Стоимость",
      render: (record) => (
        <>{record.assessedVal.toLocaleString("ru")}</>
      ),
      key: 'assessedVal',
    },
    {
      title: <>Точек<br/>в рейсе</>,
      dataIndex: 'pointsCount',
      key: 'pointsCount',
      className: "center",
    },
    {
      title: <>Активных<br/> заявок</>,
      key: 'newOrdersCount',
      render: (record) => {
        return <>
          {(record.newOrdersCount === 0) && ''}
          {(record.newOrdersCount > 0) &&
            <a className="link link--underline"
               style={{marginLeft: '10px'}}
               href="#"
               onClick={(e) => {
                 e.preventDefault();
                 setShowModalOrdersDelivery(true);
                 getResponseOrder(record.id);
               }}
            >{record.newOrdersCount} <Icon type="eye"/></a>
          }
        </>;
      },
    },
  ]

  const summaryTableColumns = [
    {
      title: "Подразделение",
      dataIndex: 'distributionCenter',
      key: 'distributionCenter',
      width: "min-content"
    },
    {
      title: <>Кол-во<br/>рейсов</>,
      dataIndex: 'deliveriesCount',
      key: 'deliveriesCount',
    },
    {
      title: <>Оценочная <br/>стоимость</>,
      render: (record) => (
        <>{record.assessedVal.toLocaleString("ru")}</>
      ),
      key: 'assessedVal',
    },
    {
      title: <>Кол-во<br/>ТТ</>,
      dataIndex: 'pointsCount',
      key: 'pointsCount',
    },
  ]

  return (
    <>
      <div className="reports__header">
        <h1 className="content-header">Отчет «Свободные рейсы»</h1>
        <Icon type='filter'
              theme={isFilterOpen ? '' : "filled"}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen)
              }}
              className="reports__filter-toggle"/>
        {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
          <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start">
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <SelectField
                name="distributionCenters"
                selectedValue={selectedDistCenters}
                values={distCenters}
                onChange={(value) => {
                  setSelectedDistCenters(value);
                }}
                notFoundText="РЦ отсутствуют"
                getErrors={() => {
                }}
                style={{width: "100%"}}
                mode={'multiple'}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <SelectField
                name="distributionCenters"
                selectedValue={selectedShipperCompanyID}
                values={shipperCompanyID}
                onChange={(value) => {
                  setSelectedShipperCompanyID(value);
                }}
                notFoundText="Отсутствуют значения"
                getErrors={() => {
                }}
                style={{width: "100%"}}
                mode={'multiple'}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <SelectField
                  name="isReturn"
                  selectedValue={isReturn}
                  values={[
                    {id: 'null', name: 'Все'},
                    {id: true, name: 'Только обратные'},
                    {id: false, name: 'Без обратных'},
                  ]}
                  onChange={(value) => {
                    setIsReturn(value);
                  }}
                  notFoundText="Отсутствуют значения"
                  getErrors={() => {
                  }}
                  style={{width: "100%"}}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={24} style={{textAlign: 'right', height: '40px'}}>
              <Button type="primary"
                      htmlType="button"
                      onClick={() => {
                        setLoading(true);
                        setIsNeedUpdateReport(true);
                      }}
                      style={{backgroundColor: '#1CAA53', border: 'none', height: '100%', width: '100%'}}
              >
                Применить
              </Button>
            </Col>
          </Row>
        </div>}
      </div>
      <div className="reports__table">
        <div className="reports__table-wrapper">
          <Col span={24}>
            <Table
              style={{width: 'max-content'}}
              columns={columns}
              dataSource={reportData}
              bordered={true}
              locale={{emptyText: "Данные отсутствуют"}}
              rowKey="id"
              size={"small"}
              expandedRowRender={(record) => {
                return <Table
                  columns={nestedColumns}
                  rowKey="key"
                  pagination={false}
                  size={"small"}
                  expandIconAsCell={false}
                  expandIconColumnIndex={1}
                  locale={{emptyText: "Данные отсутствуют"}}
                  bordered={true}
                  footer={() => ''}
                  dataSource={record.data}
                  expandRowByClick={true}
                  expandedRowRender={(record) => {
                    return <Table
                      columns={deliveriesTableColumns}
                      dataSource={record.deliveries}
                      pagination={false}
                      size={"small"}
                      locale={{emptyText: "Данные отсутствуют"}}
                      bordered={true}
                      footer={() => ''}
                      expandRowByClick={true}
                      rowKey="id"
                    />
                  }}
                />
              }}
              expandRowByClick={true}
              defaultExpandAllRows={false}
              expandIconAsCell={false}
              expandIconColumnIndex={0}
              loading={loading}
              pagination={false}
              footer={(currentPageData) => {
                let totalDeliveriesCount = 0;
                let totalAssessedVal = 0;
                let totalPointsCount = 0;
                currentPageData.forEach(({deliveriesCount, assessedVal, pointsCount}) => {
                  totalDeliveriesCount += deliveriesCount;
                  totalAssessedVal += assessedVal;
                  totalPointsCount += pointsCount;
                });
                return <table>
                  <tbody className="ant-table-tbody">
                  <tr className="ant-table-row">
                    <td style={{width: 40}}></td>
                    <td style={{width: 45}}></td>
                    <td className="ant-table-row-cell-break-word" style={{width: 380}}>Итого</td>
                    <td className="">{totalDeliveriesCount}</td>
                    <td className="" style={{width: 150}}>{totalAssessedVal.toLocaleString('ru')}</td>
                    <td className="" style={{width: 90}}>{totalPointsCount}</td>
                  </tr>
                  </tbody>
                </table>;
              }}
            />

          </Col>
          <Col span={24} style={{marginTop: "40px"}}>
            <Table
              style={{width: 'max-content'}}
              columns={summaryTableColumns}
              bordered={true}
              dataSource={summaryData}
              locale={{emptyText: "Данные отсутствуют"}}
              rowKey={"id"}
              loading={loading}
              size="small"
              pagination={false}
              footer={(currentPageData) => {
                let totalDeliveriesCount = 0;
                let totalAssessedVal = 0;
                let totalPointsCount = 0;
                currentPageData.forEach(({deliveriesCount, assessedVal, pointsCount}) => {
                  totalDeliveriesCount += deliveriesCount;
                  totalAssessedVal += assessedVal;
                  totalPointsCount += pointsCount;
                });
                return <table>
                  <tbody className="ant-table-tbody">
                  <tr className="ant-table-row">
                    <td className="ant-table-row-cell-break-word" style={{width: "60%"}}>Итого</td>
                    <td className="">{totalDeliveriesCount}</td>
                    <td className="">{totalAssessedVal.toLocaleString('ru')}</td>
                    <td className="">{totalPointsCount}</td>
                  </tr>
                  </tbody>
                </table>;
              }}
            />

          </Col>
        </div>
      </div>
      {deliveryInfo && <ModalDeliveryInfo
        deliveryInfo={deliveryInfo}
        isShow={showModal}
        setIsShow={closeModalHandler}
      />}
      {<ModalListOrdersDelivery
        isShow={showModalOrdersDelivery}
        setIsShow={setShowModalOrdersDelivery}
        deliveryId={responseOrder}
      />}
    </>
  );
}
