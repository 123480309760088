import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Table, Row, Icon, Tooltip} from "antd";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import {reportService} from "../../../../services/report.services";
import moment from "moment";
import {tableHelper} from "../../../../helpers";
import SelectField from "../../../select-field";
import {distributionCenterService} from "../../../../services";


export default function ReportStatsOrdersDelivery() {

  const [loading, setLoading] = useState(false);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [searchText, setSearchText] = useState('');

  const [distCenters, setDistCenters] = useState([]);
  const [selectedDistCenters, setSelectedDistCenters] = useState([]);

  // параметры для построения отчета
  const [dateStart, setDateStart] = useState(moment().startOf('month'));
  const [dateEnd, setDateEnd] = useState(moment().endOf('month'));
  // полученные данные отчета
  const [reportData, setReportData] = useState([]);

  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateReport(true);
      distributionCenterService.getCompanies()
        .then((response) => {
          setDistCenters(response);
        }).catch((errorMsg) => {
        setErrorMsg(errorMsg);
      });
    }
  }, []);

  if (isNeedUpdateReport) {

    // запрос на получение данных отчета
    reportService.getStatsOrdersDelivery(dateStart.format('YYYY-MM-D'), dateEnd.format('YYYY-MM-D'), selectedDistCenters)
      .then((reportData) => {

        const reportDataParsed = reportData.map((item, index) => {
          return {
            userId: item.userId,
            userFio: item.userFio,
            partnerId: item.partnerId,
            partnerName: item.partnerName,
            countCreatedOrders: item.countCreatedOrders,
            countCompletedOrders: item.countCompletedOrders,
            countRefusedOrders: item.countRefusedOrders
          };
        });

        setIsNeedUpdateReport(false);
        setLoading(false);
        setReportData(reportDataParsed);

      }).catch((errorMsg) => {
      setIsNeedUpdateReport(false);
      setLoading(false);
      setErrorMsg(errorMsg);
    });
  }

  // отображение фатальной ошибки
  if (errorMsg) {
    return (<div className="content-error-block">{errorMsg}</div>);
  }

  // список столбцов таблицы
  const columns = [
    {
      title: 'Пользователь',
      dataIndex: 'userFio',
      key: 'userFio',
      width: '35%',
      sorter: (a, b) => {
        return a.userFio.localeCompare(b.userFio);
      },
      ...tableHelper.getColumnSearchProps('userFio', searchText, setSearchText)
    },
    {
      title: 'Компания перевозчика',
      dataIndex: 'partnerName',
      key: 'partnerName',
      width: '35%',
      ...tableHelper.getColumnSearchProps('partnerName', searchText, setSearchText)
    },
    {
      title: <Tooltip title='Кол-во созданных'>Кол-во<br/> созданных</Tooltip>,
      dataIndex: 'countCreatedOrders',
      key: 'countCreatedOrders',
      ellipsis: true,
      className: "center"
    },
    {
      title: <Tooltip title='Кол-во обработанных'>Кол-во<br/> обработанных</Tooltip>,
      dataIndex: 'countCompletedOrders',
      key: 'countCompletedOrders',
      ellipsis: true,
      className: "center"
    },
    {
      title: <Tooltip title='Кол-во отмененнных'>Кол-во<br/> отмененнных</Tooltip>,
      dataIndex: 'countRefusedOrders',
      key: 'countRefusedOrders',
      ellipsis: true,
      className: "center"
    },
  ];

  return (
    <React.Fragment>
      <div className="reports__header">
        <h1 className="content-header">Отчет «Статистика по заявкам»</h1>
        <Icon type='filter'
              theme={isFilterOpen ? '' : "filled"}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen)
              }}
              className="reports__filter-toggle"/>
        {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
          <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start">
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateStart}
                format="DD.MM.YYYY"
                size="normal"
                name="dateStart"
                placeholder="Выберите дату начала периода"
                locale={localeDatePicker}
                allowClear={false}
                onChange={(date) => {
                  setDateStart(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateEnd}
                format="DD.MM.YYYY"
                size="normal"
                name="dateEnd"
                placeholder="Выберите дату окончания периода"
                locale={localeDatePicker}
                allowClear={false}
                onChange={(date) => {
                  setDateEnd(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <SelectField
                name="distributionCenters"
                selectedValue={selectedDistCenters}
                values={distCenters}
                onChange={(value) => {
                  setSelectedDistCenters(value);
                }}
                notFoundText="РЦ отсутствуют"
                getErrors={() => {
                }}
                style={{width: "100%"}}
                mode={'multiple'}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{textAlign: 'right', height: '40px'}}>
              <Button type="primary"
                      htmlType="button"
                      onClick={() => {
                        setLoading(true);
                        setIsNeedUpdateReport(true);
                      }}
                      style={{backgroundColor: '#1CAA53', border: 'none', height: '100%', width: '100%'}}
              >
                Применить
              </Button>
            </Col>
          </Row>
        </div>}
      </div>
      <div className="reports__table">
        <div className="reports__table-wrapper">
          <Col span={24}>
              <Table
                style={{minWidth: '100%'}}
                columns={columns}
                dataSource={reportData}
                locale={{emptyText: "Данные отсутствуют"}}
                rowKey={"userId"}
                loading={loading}
                size="small"
                pagination={{pageSize: 50}}
              />

          </Col>
        </div>
      </div>
    </React.Fragment>
  );
}
