import React, {useState, useEffect, useContext} from 'react';
import {Layout, Menu, Icon} from 'antd';
import {Link, Route} from 'react-router-dom';

import {AppContext} from "../../../contexts/app-context";
import {userServices} from "../../../services";

import Instruction from "./instruction";
import Support from "./support";
import LogistFaq from "./faq";


let menuElements = [
    {
        parentKey: 'group1',
        link: "/service/instruction",
        label: <span><Icon type="apartment" /><span>Инструкции</span></span>,
        subMenu: false,
        items: []
    },
    {
        parentKey: 'group2',
        link: "/service/support",
        label: <span><Icon type="apartment" /><span>Поддержка</span></span>,
        subMenu: false,
        items: []
    },
];

function LeftMenu({collapsed, menuElementsData}) {

    const menuElements = menuElementsData.map((menuItemData) => {

        if (menuItemData.subMenu){

            const groupItems = menuItemData.items.map((item) => {
                return (<Menu.Item key={item.key}><Link to={item.link}>{item.label}</Link></Menu.Item>);
            });

            return (
                <Menu.SubMenu key={menuItemData.parentKey} title={menuItemData.label}>
                    {groupItems}
                </Menu.SubMenu>
            );
        } else {
            return (<Menu.Item key={menuItemData.parentKey}><Link to={menuItemData.link}>{menuItemData.label}</Link></Menu.Item>);
        }

    });

    const menuSelectedItem = menuElementsData
        .filter((data) => {

            if (data.subMenu) {

                const itemsLinks = data.items.filter((dataItem) => {
                    return (dataItem.link === window.location.pathname);
                });

                return (itemsLinks.length);
            } else {
                return (data.link === window.location.pathname);
            }

        })
        .map((item) => {

            if (item.subMenu) {

                const itemsLinks = item.items.filter((dataItem) => {
                    return (dataItem.link === window.location.pathname);
                });

                return {parentKey: item.parentKey, itemKey: itemsLinks[0].key};
            } else {
                return {parentKey: item.parentKey, itemKey: item.parentKey};
            }
        })
        .shift();

    return (
        <Menu
            defaultSelectedKeys={(typeof menuSelectedItem !== "undefined") ? [menuSelectedItem.itemKey] : []}
            defaultOpenKeys={(typeof menuSelectedItem !== "undefined") ? [menuSelectedItem.parentKey] : []}
            mode="inline"
            theme="light"
            inlineCollapsed={collapsed}
        >
            {menuElements}
        </Menu>
    );

}

export default function ServicePage() {

    const [collapsed, setCollapsed] = useState(false);
    const appContext = useContext(AppContext);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    const handleResize = () => {
        if (collapsed === false && window.innerWidth < 1000) {
            setCollapsed(true);
        } else if (collapsed === true && window.innerWidth > 1000) {
            setCollapsed(false);
        }
    };

    handleResize();

    if (appContext.filter.enable) {
        appContext.disableFilter();
    }

    const user = userServices.getUser();
    if (user.info.userType === 'inner' && menuElements.length == 2) {
        menuElements.push(    {
                parentKey: 'group3',
                link: "/service/logist-faq",
                label: <span><Icon type="apartment" /><span>FAQ для логистов</span></span>,
                subMenu: false,
                items: []
            }
        );
    }


    // if (!user.isAccessTo(SERVICE_INFORMATION_MANAGEMENT)) {
    //     return (<Error status={403} desc="Страница не доступна"/>);
    // }

    return (
        <Layout tagName="section" style={{marginTop: '50px', padding: '25px 0', background: '#fff'}}>
            <Layout.Sider
                collapsed={collapsed}
                theme="light"
                width={256}
            >
                <LeftMenu collapsed={collapsed} menuElementsData={menuElements} />
            </Layout.Sider>
            <Layout.Content style={{padding: '0 24px', minHeight: 350}} className="table-window">
                <Route path="/service/support" exact component={Support} />
                <Route path="/service/instruction" exact component={Instruction} />
                <Route path="/service/logist-faq" exact component={LogistFaq} />
            </Layout.Content>
        </Layout>
    );
}