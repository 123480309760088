import React, {useState, useEffect} from 'react';
import {notification, Button, Table, Row, Col, Tooltip} from 'antd';

import Spinner from '../../../spinner';
import {distributionCenterService} from '../../../../services';
import ModalEditDistributionCenter from "../../../modals/edit-distribution-center";
import ModalCreateDistributionCenter from "../../../modals/create-distribution-center";
import ModalEditDistributionCenterOrderTimeSums from "../../../modals/edit-distribution-center-order-time-sums";
import ModalListOfGates from "../../../modals/gates-list";
import ModalListLoadingSchedule from "../../../modals/list-loading-shedule";


export default function DistributionCentres({consignorSettings}) {

  const [loading, setLoading] = useState(true);
  const [isNeedUpdateList, setIsNeedUpdateList] = useState(true);
  const [data, setData] = useState([]);
  const [showModalEditDistCenter, setShowModalEditDistCenter] = useState(null);
  const [showModalEditOrderTimeSums, setShowModalEditOrderTimeSums] = useState(null);
  const [showModalCreateDistCenter, setShowModalCreateDistCenter] = useState(null);
  const [showModalListOfGates, setShowModalListOfGates] = useState(null);
  const [showModalListLoadingSchedule, setShowModalListLoadingSchedule] = useState(null);

  let bigButtonStyle = {
    color: 'white',
    backgroundColor: '#1CAA53',
    borderRadius: '7px',
    height: '35px',
    border: 'none'
  };

  useEffect(() => {
    if (isNeedUpdateList) {
      distributionCenterService.getCompanies()
        .then((response) => {

          const distributionsCentersData = response.map((item) => {
            return {
              id: item.Id,
              name: item.Name,
              delayDelivDispatcher: item.DelayDelivDispatcher,
              delayReserveDeliv: item.DelayReserveDeliv,
              maxCountLoadAuto: item.MaxCountLoadAuto,
              startFillingOrder: item.StartFillingOrder,
              fillingOrder: item.FillingOrder,
              downloadDocument: item.DownloadDocument,
              closingTime: item.ClosingTime,
              minOrdersToApprove: item.MinOrdersToApprove,
              minHoursToRefuse: item.MinHoursToRefuse,
              cargoCollectTime: item.CargoCollectTime,
              countSkipAvailableTimesNonImportantDelivery: item.CountSkipAvailableTimesNonImportantDelivery,
              importantDeliveryMaxTimeLoad: item.ImportantDeliveryMaxTimeLoad,
              maxInnerDistance: item.MaxInnerDistance,
            };
          });

          setIsNeedUpdateList(false);
          setLoading(false);
          setData(distributionsCentersData);

        }).catch((errMsg) => notification.error(
        {
          message: "Получение списка распределительных центров",
          description: errMsg
        }));
    }

  }, [isNeedUpdateList]);

  const columns = [
    {
      title: 'Название',
      key: 'name',
      width: '25%',
      sorter: (a, b) => a.id - b.id,
      render: (data) => {
        return (
          <React.Fragment>
            <p className="column-title">{data.name}</p>
            <Tooltip title="Изменить">
              <Button type="primary"
                      htmlType="button"
                      onClick={() => setShowModalEditDistCenter(data.id)}
                      size="small"
                      icon="edit"
                      style={{margin: '0 5px 10px 0'}}/>
            </Tooltip>
            <ModalEditDistributionCenter
              isShow={(showModalEditDistCenter === data.id)}
              onCloseModal={() => {
                setShowModalEditDistCenter(null);
                setLoading(true);
                setIsNeedUpdateList(true);
              }}
              {...data}
            />
            <Tooltip title="Повышение">
              <Button type="primary"
                      htmlType="button"
                      onClick={() => {
                        setShowModalEditOrderTimeSums(data.id);
                      }}
                      icon="arrow-up"
                      size="small"
                      style={{margin: '0 5px 10px 0'}}/>
            </Tooltip>
            <ModalEditDistributionCenterOrderTimeSums
              isShow={(showModalEditOrderTimeSums === data.id)}
              distCenterId={data.id}
              onCloseModal={() => {
                setShowModalEditOrderTimeSums(null);
                setLoading(true);
                setIsNeedUpdateList(true);
              }}
              {...data}
            />
            <Tooltip title="Ворота погрузки">
              <Button type="primary"
                      htmlType="button"
                      onClick={() => {
                        setShowModalListOfGates(data.id);
                      }}
                      size="small"
                      style={{margin: '0 5px 10px 0'}}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 47.09"
                     focusable="false"
                     width="1em"
                     height="1em"
                     fill="currentColor"
                     aria-hidden="true"
                     style={{display: "inline-block", verticalAlign: "middle"}}>
                  <path d="M1.25 13.63h44.88v2.48H1.25z" fill="currentcolor"/>
                  <path d="M1.25 19.83h44.88v2.48H1.25z" fill="currentcolor"/>
                  <path d="M1.25 26.03h44.88v2.48H1.25z" fill="currentcolor"/>
                  <path d="M1.25 32.22h44.88v2.48H1.25z" fill="currentcolor"/>
                  <path d="M1.25 0h44.88v9.91H1.25z" fill="currentcolor"/>
                  <path d="M17.45 3.72h12.47V6.2H17.45z" fill="black" opacity="0.7"/>
                  <path d="M43.01 0H48v47.09h-4.99z" fill="currentcolor"/>
                  <path d="M0 0h4.99v47.09H0z" fill="currentcolor"/>
                </svg>
              </Button>
            </Tooltip>
            <ModalListOfGates
              isShow={(showModalListOfGates === data.id)}
              distCenterId={data.id}
              name={data.name}
              onCloseModal={() => {
                setShowModalListOfGates(null);
                setLoading(true);
                setIsNeedUpdateList(true);
              }}
              {...data}
            />
            <Tooltip title="Список активных записей на погрузку">
              <Button type="primary"
                      htmlType="button"
                      onClick={() => {
                        setShowModalListLoadingSchedule(data.id);
                      }}
                      size="small"
                      icon="clock-circle"/>
              <ModalListLoadingSchedule
                isShow={(showModalListLoadingSchedule === data.id)}
                distCenterId={data.id}
                name={data.name}
                onCloseModal={() => {
                  setShowModalListLoadingSchedule(null);
                }}
                {...data}
              />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
    {
      title: 'Задержка отображения рейсов для диспетчера, в мин',
      dataIndex: 'delayDelivDispatcher',
      key: 'delayDelivDispatcher',
      width: '10%',
    },
    {
      title: 'Отсрочка резервирования рейса, в мин',
      dataIndex: 'delayReserveDeliv',
      key: 'delayReserveDeliv',
      width: '10%',
    },
    {
      title: 'Максимальное количество погрузок авто',
      dataIndex: 'maxCountLoadAuto',
      key: 'maxCountLoadAuto',
      width: '10%',
    },
    {
      title: 'Начало заполнения заявки, мин',
      dataIndex: 'startFillingOrder',
      key: 'startFillingOrder',
      width: '10%',
    },
    {
      title: 'Заполнение заявки, мин',
      dataIndex: 'fillingOrder',
      key: 'fillingOrder',
      width: '10%',
    },
    {
      title: 'Загрузка документов, мин',
      dataIndex: 'downloadDocument',
      key: 'downloadDocument',
      width: '10%',
    }
  ];

  return (
    <Spinner loading={loading} tip="Загрузка...">
      <h1 className="content-header">Распределительные центры</h1>
      {(consignorSettings && !consignorSettings.isVital) &&
        <Row gutter={48} style={{paddingRight: "0"}}>
          <Col span={24} style={{textAlign: "right", height: "40px", marginBottom: "24px"}}>
            <Button
              type="primary"
              htmlType="button"
              onClick={() => {
                setShowModalCreateDistCenter(true);
              }}
              style={{backgroundColor: "#1CAA53", border: "none", height: "100%"}}
            >
              Добавить РЦ
            </Button>
          </Col>
        </Row>
      }
      <Table
        columns={columns}
        dataSource={data}
        locale={{emptyText: 'Данные отсутствуют'}}
        rowKey="id"
        size="small"
        pagination={{
          pageSize: 50
        }}
      />
      <ModalCreateDistributionCenter
        isShow={showModalCreateDistCenter}
        onCloseModal={() => {
          setShowModalCreateDistCenter(null);
          setLoading(true);
          setIsNeedUpdateList(true);
        }}
      />
    </Spinner>
  );
}
