import React, {useEffect, useState} from "react";
import {notification} from "antd";
import moment from "moment";
import {companyService} from "../../../../services";
import GuarantorFields from "../../create-legal-entity/belarus-legal-entity/steps/guarantor-fields";
import AddressesFields from "./steps/addresses-fields";
import MainDataFields from "./steps/main-data-fields";

export default function EditBelarusLegalEntity({
    isActive = false,

    currentStep,
    setCurrentStep,

    legalEntityId,
    legalEntityData,

    setLoading,

    setStepsList,

    changeStepEvent,
    setChangeStepEvent,

    editLegalEntityEvent,
    setEditLegalEntityEvent,

    setIsShowParent
}) {
  const [fieldsErrors, setFieldsErrors] = useState({});

  const [mainDataFields, setMainDataFields] = useState({
      orgForm: legalEntityData.OrgForm,
      orgFormId: legalEntityData.OrgFormId,

      name: legalEntityData.Name,

      workWithNds: legalEntityData.WorkWithNds === 1 ? 1 : 2,
      legalReason: legalEntityData.LegalReason,
      firstName: legalEntityData.FirstName,
      secondName: legalEntityData.SecondName,
      patronymic: legalEntityData.Patronymic,
      country: legalEntityData.Country,
      countryId: legalEntityData.CountryId,
      position: legalEntityData.Position,
      positionStr: legalEntityData.PositionStr,
      legalNumber: legalEntityData.LegalNumber,

      bankName: legalEntityData.BankName,
      bankUnp: legalEntityData.BankUnp,
      bankAddress: legalEntityData.BankAddress,
      bic: legalEntityData.Bic,
      accountNumber: legalEntityData.AccountNumber,
      swift: legalEntityData.Swift,
      currencyType: legalEntityData.CurrencyType,

      email: legalEntityData.Email,
      phone: legalEntityData.Phone,
      atiCode: legalEntityData.AtiCode,
  });

    const [addressesFields, setAddressesFields] = useState({
        addressCity: legalEntityData.AddressCity,
        address: legalEntityData.Address,
        mailingAddress: legalEntityData.MailingAddress,
    });

  const [guarantorFields, setGuarantorFields] = useState({
    guarantorFirstName: legalEntityData.GuarantorFirstName,
    guarantorSecondName: legalEntityData.GuarantorSecondName,
    guarantorPatronymic: legalEntityData.GuarantorPatronymic,
    guarantorPassportSerial: legalEntityData.GuarantorPassportSerial,
    guarantorPassportNumber: legalEntityData.GuarantorPassportNumber,
    guarantorPassportRegistration: legalEntityData.GuarantorPassportRegistration,
    guarantorPassportDate: moment(legalEntityData.GuarantorPassportDate, 'DD.MM.YYYY'),
    guarantorAddressFact: legalEntityData.GuarantorAddressFact,
    guarantorAddressRegistration: legalEntityData.GuarantorAddressRegistration,
    guarantorCountry: legalEntityData.GuarantorCountry,
    guarantorPhone: legalEntityData.GuarantorPhone,
    guarantorEmail: legalEntityData.GuarantorEmail,
  });

  const getFieldErrors = (fieldName) => {
    return typeof fieldsErrors[fieldName] !== "undefined"
        ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
          return fieldErrors + "; " + error;
        })
        : null;
  };

  useEffect(() => {
    if (isActive) {
        if (mainDataFields.orgFormId === 16) {
          setStepsList([
              'Основная информация',
              'Адреса',
          ]);
        } else {
          setStepsList([
              'Основная информация',
              'Адреса',
              'Поручитель',
          ]);
        }
    }
  }, [isActive]);

  useEffect(() => {
    if (changeStepEvent) {
      if (changeStepEvent === 'next') {
        nextStep();
      } else if (changeStepEvent === 'previous') {
        previousStep();
      }

      setChangeStepEvent('');
    }
  }, [changeStepEvent]);

  useEffect(() => {
    if (editLegalEntityEvent) {
      setLoading(true);

      let data = Object.assign(mainDataFields, guarantorFields, addressesFields);
      companyService.editBelarusLegalEntity(data, legalEntityId, true)
          .then(response => {
            if (response.code === 400) {
              notification.error({
                message: "Редактирование юридического лица",
                description: response.message,
              });
              setFieldsErrors(response.errors);
            } else {
              notification.success({
                message: "Редактирование юридического лица",
                description: response.message,
              });

              setIsShowParent(false);
            }

            setLoading(false);
            setEditLegalEntityEvent(false);
          })
          .catch(errMsg => {
            notification.error({
              message: "Создание юридического лица",
              description: errMsg,
            });

            setLoading(false);
            setEditLegalEntityEvent(false)
          });
    }
  }, [editLegalEntityEvent]);

  const nextStep = () => {
    let stepData = (currentStep) => {
      let stepFields = {
          1: mainDataFields,
          2: addressesFields,
          3: guarantorFields
      };

      // Баг. Без этого виесто stepFields undefiend
      console.log(stepFields, currentStep, mainDataFields.orgFormId);

      return stepFields[currentStep];
    };

    setLoading(true);

    companyService
        .createBelarusLegalEntityValidateStepData(stepData(currentStep), currentStep, mainDataFields.orgFormId, mainDataFields.countryId, true)
        .then(response => {
          setLoading(false);

          if (response.status === 'success') {
            if (currentStep < 5) {
              if ((currentStep + 1) === 4 && mainDataFields.orgFormId === 1) {
                setCurrentStep(currentStep + 2);
              } else {
                setCurrentStep(currentStep + 1);
              }
              setFieldsErrors([]);
            }
          } else {
            setFieldsErrors(response.errors);
          }
        })
        .catch(error => {
          setLoading(false);
          notification.error({
            message: "Создание юр.лица",
            description: error,
          });
        });
  };

  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      setCurrentStep(0);
    }
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
            <MainDataFields
                setFields={setMainDataFields}
                stepFields={mainDataFields}
                getFieldErrors={getFieldErrors}
                orgFormId={mainDataFields.orgFormId}
                edit={true}
            />
        );
      case 2:
        return (
            <AddressesFields
                setFields={setAddressesFields}
                stepFields={addressesFields}
                getFieldErrors={getFieldErrors}
                edit={true}
            />
        );
        case 3:
            return (
                <GuarantorFields
                    setFields={setGuarantorFields}
                    stepFields={guarantorFields}
                    getFieldErrors={getFieldErrors}
                    edit={true}
                />
            );
      default:
        return <div />;
    }
  };

  return (
      <div>
        {renderCurrentStep()}
      </div>
  );
}