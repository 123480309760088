import React from "react";
import moment from "moment";
import RefuseDeliveryButton from "../../refuse-delivery-button";

function CommentsBlock({ comments }) {
  if (comments) {
    return (
      <div className="comments-block">
        <h4>Комментарии</h4>
        <p className="comments-block__text">{comments}</p>
      </div>
    );
  } else {
    return <div />;
  }
}

export default function LogistFreeContentItem({ delivery, setLoadingDelivItem, setNeedUpdate, setStopListUpdate, contentVisible }) {
  const placesUnload = delivery.PlacesUnload.map((placeUnload, idx) => {
    return <p key={idx + 1}>{placeUnload.Address}</p>;
  });
  const dateCreatedRus = moment(delivery.DateCreated).format("D MMMM YYYY");

  return (
    <div className={`application-info ${contentVisible ? "application-info-visible" : ""}`}>
      <div className="application-info-row">
        <div className="application-info-col">
          <div className="application-info-col-item">
            <h4>Номер рейса</h4>
            <p>{delivery.NumberDelivery}</p>
          </div>
          <div className="application-info-col-item">
            <h4>Дата создания рейса</h4>
            <p>{dateCreatedRus}</p>
          </div>
          <div className="application-info-col-item">
            <h4>Юр. лицо</h4>
            <p>{delivery.CustomerCompany}</p>
          </div>
        </div>
        <div className="application-info-col">
          <div className="application-info-col-item">
            <h4>Загрузка</h4>
            <p>{delivery.PlaceLoad.Address}</p>
          </div>
          <div className="application-info-col-item">
            <h4>Выгрузка</h4>
            {placesUnload}
          </div>
        </div>
        <div className="application-info-col">
          <div className="application-info-col-item" />
          <div className="application-info-col-item application-info-col-item-columned">
            <div className="application-info-col-item">
              <h4>Тип груза</h4>
              <p>{delivery.CargoType}</p>
            </div>
            <div className="application-info-col-item">
              <h4>Вес, т</h4>
              <p>{(delivery.Weight / 1000).toFixed(2)}</p>
            </div>
          </div>
          <div className="application-info-col-item" />
          <div className="application-info-col-item application-info-col-item-columned">
            <div className="application-info-col-item">
              <h4>Режим</h4>
              <p>{delivery.CargoMode}</p>
            </div>
            <div className="application-info-col-item">
              <h4>
                Обьем, м<sup>3</sup>
              </h4>
              <p>{delivery.Volume}</p>
            </div>
          </div>
          <CommentsBlock comments={delivery.Comments} />
          <div className="application-info-col-item" />
        </div>
      </div>
      <div className="application-info-refuse-button">
        <RefuseDeliveryButton
          deliveryId={delivery.Id}
          isCanRefuse={delivery.Permissions ? delivery.Permissions.IsAvailableRefuseDelivery : false}
          setLoadingDelivItem={setLoadingDelivItem}
          setNeedUpdate={setNeedUpdate}
          setStopListUpdate={setStopListUpdate}
        />
      </div>
    </div>
  );
}
