import React, { useEffect, useState } from 'react';
import { companyService, consignorService } from '../../../services';
import { Modal, Row, Col, Table } from 'antd';
import Spinner from "../../spinner";
import moment from "moment";

export default function ModalDeliveryInfo({ deliveryInfo, deliveryId, isShow, setIsShow }) {

	const [tableData, setTableData] = useState([]);
	const [dataDelivery, setDataDelivery] = useState([]);
	const [reportData, setReportData] = useState([]);
	const [prices, setPrices] = useState([]);

	useEffect(() => {
		if (isShow && deliveryInfo !== undefined) {
			setDataDelivery(deliveryInfo.deliveryInfo);
			setTableData(deliveryInfo.ordersDelivery);
			setPrices(deliveryInfo.deliveryInfo.deliveryPrices);

			if (deliveryInfo.ordersDelivery) {
				deliveryInfo.ordersDelivery.map((item, index) => {
					tableData[index] = {
						number: index,
						fio: item.name,
						price: item.price,
						title: item.title,
						legalEntityName: item.legal_entity_name,
						date_created: moment(item.date_created).format("DD.MM.YYYY HH:mm:ss"),
						last_update: moment(item.last_update).format("DD.MM.YYYY HH:mm:ss"),
						children: []
					};
					if (item.counterOffers.length !== 0) {
						item.counterOffers.map((val, index2) => {
							tableData[index].children.push({
								key: index2,
								type: "Встречное предложение",
								fio: val.name,
								price: val.sum,
								title: val.title,
								date_created: moment(val.date_created).format("DD.MM.YYYY HH:mm:ss"),
								last_update: moment(val.date_updated).format("DD.MM.YYYY HH:mm:ss"),
							});
						});
					}
					return null;
				});

				let reindexedTableData = [];
				for (let key in tableData) {
					reindexedTableData.push(tableData[key]);
				}

				setReportData(reindexedTableData);
			} else {
				setReportData([]);
			}
		}
	}, [isShow]);

	const columns = [
		{
			dataIndex: 'type',
			key: 'type',
			width: '25%'
		},
		{
			title: 'Юр. лицо',
			dataIndex: 'legalEntityName',
			key: 'legalEntityName',
			width: '25%'
		},
		{
			title: 'Цена',
			dataIndex: 'price',
			key: 'price',
			width: "10%"
		},
		{
			title: 'Статус',
			dataIndex: 'title',
			key: 'title',
			width: "10%"
		},
		{
			title: 'Дата создания',
			dataIndex: 'date_created',
			key: 'date_created',
			width: "15%"
		},
		{
			title: 'Дата обновления',
			dataIndex: 'last_update',
			key: 'last_update',
			width: "15%"
		},
	];


	return (
		<Modal
			title={'История рейса № ' + dataDelivery.number_delivery}
			cancelText="Закрыть"
			className="modal-window"
			align={null}
			visible={isShow}
			onCancel={() => {
				setIsShow(false);
			}}
			footer={<div></div>}
		>
			<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
				<Col lg={12} md={12} sm={12} xs={12} className="label calendar" style={{ marginBottom: '10px' }}>
					Утвердил(а) заявку:
				</Col>
				<Col lg={12} md={12} sm={12} xs={12} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
					{dataDelivery.name}
				</Col>
			</Row>

			<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
				<Col lg={12} md={12} sm={12} xs={12} className="label calendar" style={{ marginBottom: '10px' }}>
					Дата утверждения:
				</Col>
				<Col lg={12} md={12} sm={12} xs={12} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
					{dataDelivery.date_approved != null ? moment(dataDelivery.date_approved).format("DD.MM.YYYY HH:mm:ss") : dataDelivery.date_approved}
				</Col>
			</Row>

			{prices.map(item => {
				return (
					<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
						<Col lg={12} md={12} sm={12} xs={12} className="label calendar" style={{ marginBottom: '10px' }}>
							{item.paymentType.title}:
						</Col>
						<Col lg={12} md={12} sm={12} xs={12} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
							{item.price} {item.paymentType.currency.symbol}
						</Col>
					</Row>
				);
			})}
			<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
				<Col lg={12} md={12} sm={12} xs={12} className="label patch" style={{ marginBottom: '10px' }}>
					Дата загрузки:
				</Col>
				<Col lg={12} md={12} sm={12} xs={12} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
					{dataDelivery.date_shipment != null ? moment(dataDelivery.date_shipment).format("DD.MM.YYYY") : ""}
				</Col>
			</Row>
			<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
				<Col lg={12} md={12} sm={12} xs={12} className="label calendar" style={{ marginBottom: '10px' }}>
					Дата создания:
				</Col>
				<Col lg={12} md={12} sm={12} xs={12} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
					{moment(dataDelivery.date_created).format("DD.MM.YYYY HH:mm:ss")}
				</Col>
			</Row>
			<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
				<Col lg={12} md={12} sm={12} xs={12} className="label dispatch" style={{ marginBottom: '10px' }}>
					Дата переноса в архив:
				</Col>
				<Col lg={12} md={12} sm={12} xs={12} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
					{dataDelivery.max_date !== "-" ? moment(dataDelivery.max_date).format("DD.MM.YYYY HH:mm:ss") : "-"}
				</Col>
			</Row>
			<Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
				<Col lg={12} md={12} sm={12} xs={12} className="label dispatch" style={{ marginBottom: '10px' }}>
					Интервал:
				</Col>
				<Col lg={12} md={12} sm={12} xs={12} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
					{dataDelivery.date_diff}
				</Col>
			</Row>

			<Table
				columns={columns}
				dataSource={reportData}
				rowKey="name"
				pagination={false}
				size="small"
			/>
		</Modal>
	);

}
