import {Button, Form, Icon, Modal, Row, Spin, Steps} from "antd";
import React, {useEffect, useState} from "react";
import ChooseContractType from "./steps/choose-contract-type";
import CreateOrder from "./steps/create-order";
import {useHistory} from "react-router-dom";

export default function ModalCreateContractOrder({isShow, setIsShow, legalEntityID, shipperCompanyID}) {
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [needUpdateInfo, setNeedUpdateInfo] = useState(true);
    const [contractType, setContractType] = useState('');

    const history = useHistory();

    useEffect(() => {
        setCurrentStep(1);
        setContractType('');
    }, [isShow]);

    const handleSuccessOrderCreate = () => {
        setIsShow(false);
        setCurrentStep(1);
        history.push('/company/orders-to-shipper-company');
    };

    const renderCurrentStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <ChooseContractType
                        legalEntityID={legalEntityID}
                        shipperCompanyID={shipperCompanyID}
                        needUpdate={needUpdateInfo}
                        onChooseContract={onChooseContract}
                    />
                );
            case 2:
                return (
                    <CreateOrder
                        legalEntityID={legalEntityID}
                        shipperCompanyID={shipperCompanyID}
                        needUpdate={needUpdateInfo}
                        contractType={contractType}
                        onSuccess={handleSuccessOrderCreate}
                    />
                );
            default:
                return <div/>;
        }
    };

    const onChooseContract = (contractType) => {
        setContractType(contractType);
        setCurrentStep(2);
        setNeedUpdateInfo(true);
    };

    return (
        <Modal
            title={"Заключение договоров"}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
            }}
            footer={null}
        >
            <Spin spinning={loading} tip="Загрузка...">
                <Row gutter={16} className="add-padding-10">
                    <Steps current={currentStep - 1} size="small" progressDot className="dots-custom">
                        <Steps.Step title="Выбор договора" />
                        <Steps.Step title="Заключение договора" />
                    </Steps>
                </Row>
                <Form onSubmit={() => alert(11)}>
                    {renderCurrentStep()}
                </Form>
            </Spin>
        </Modal>
    );
}