import React, {useEffect,useState} from 'react';
import {Modal,Table,notification,Button,Row,Col} from 'antd';
import {companyService} from '../../../services/company.services';
import Spinner from "../../spinner";
import { useHistory } from "react-router-dom";
import ModalCreateOrderToConsignor from "../create-order-to-consignor";
import ModalShipperCompanyFullInfo from "../shipper-company-full-info";
import ModalCreateContractOrder from "../create-contract-order";

export default function ModalListShipCompanies({isShow,setIsShow,legalEntityId,setIsShowParent = null}) {

    const [data, setData] = useState(null);
    const [needUpdate, setNeedUpdate] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showShipperCompanyInfoModal, setShowShipperCompanyInfoModal] = useState(false);
    const [currentOrderId, setCurrentOrderId] = useState(null);
    const [loadingContentModal, setLoadingContentModal] = useState(false);

    const [showOrderToConsignorModal, setShowOrderToConsignorModal] = useState(false);
    const [currentConsignorId, setCurrentConsignorId] = useState(false);

    const [showOrderToShipperCompanyModal, setShowOrderToShipperCompanyModal] = useState(false);
    const [currentShipperCompanyId, setCurrentShipperCompanyId] = useState(false);

    const [helpText, setHelpText] = useState('Для начала работы подайте заявку на согласование юридического лица у грузоотправителя');

    const history = useHistory();

    useEffect(() => {
        if ((isShow || needUpdate) && legalEntityId !== null){
            setLoadingContentModal(true);
            setNeedUpdate(false);
            companyService
                .getShipperCompaniesList(legalEntityId)
                .then(resp => {
                    let respData = [];
                    resp.forEach(element => {
                        if (element.ConsignorData.Order && element.ConsignorData.Order.Status.Id === 2) {
                            setHelpText('Для заключения договора нажмите на "+" около грузоотправителя, и выбирете то юридическое лицо, с кем хотите заключить договор');
                        }

                        respData.push({
                            'Id': element.ConsignorData.Id,
                            'Name': element.ConsignorData.Name,
                            'Order': element.ConsignorData.Order,
                            'IsVital': element.ConsignorData.IsVital,
                            'AgreeWithoutVitalAgreed': element.ConsignorData.AgreeWithoutVitalAgreed,
                            'ShipperCompanies': element.ShipperCompanies,
                            'Permissions': element.ConsignorData.Permissions,
                        })
                    });
                    setData(respData);
                })
                .catch(errMsg => notification.error({
                    message: "Получение списка грузоотправителей",
                    description: errMsg
                }))
                .finally(() => setLoadingContentModal(false))
            ;
        }
    }, [isShow, legalEntityId, needUpdate]);

    const handleSendOrderToShipperCompany = (shipperCompanyId) => {
        setCurrentShipperCompanyId(shipperCompanyId);
        setShowOrderToShipperCompanyModal(true);
    };

    const handleRedirectToOrdersToShipComp = () => {
        history.push('/company/orders-to-shipper-company');
    };

    const handleRedirectToOrdersToConsignor = () => {
        history.push('/company/orders-to-consignor');
    };

    const handleSendOrderToConsignor = (consignorId) => {
        setCurrentConsignorId(consignorId);
        setShowOrderToConsignorModal(true);
    };

    const orderToShipperCompanyButtonRender = (record) => {
        if (!record.Order) {
            if (!record.Permissions.IsCanCreateOrder) {
                return (
                    <span>Нужно подтверждение от грузоотправителя</span>
                );
            }

            return (
                <Button onClick={() => handleSendOrderToShipperCompany(record.Id)} className="small-button typical-btn-green">
                  Заключить договор
                </Button>
            );
        } else if (record.Order.Status.Id === 4) {
            return (
                <Button onClick={() => handleRedirectToOrdersToShipComp()} className="small-button typical-btn-green">
                    Скачать и подписать договор
                </Button>
            );
        } else {
            return (
                <span>{record.Order.Status.Title}</span>
            );
        }
    };

    const orderToConsignorButtonRender = (record) => {
        if (!record.IsVital && !record.AgreeWithoutVitalAgreed) {
            return null;

        }

        if (!record.Order) {
            return (
                <Button disabled={!record.Permissions.IsCanCreateOrder} onClick={() => handleSendOrderToConsignor(record.Id)} className="small-button typical-btn-green">
                    Подать заявку
                </Button>
            );
        } else if (record.Order.Status.Id === 3) {
            return (
                <span>
                    {record.Order.Status.Title}
                </span>
            );
        }else {
            return (
                <span>{record.Order.Status.Title}</span>
            );
        }
    };

    const redirectToAdditionalInfoBtnRender = (record) => {
         if (record.Order && record.Order.Status.Id === 3) {
            return (
                <span>
                    <Button onClick={() => handleRedirectToOrdersToConsignor()} className="small-button typical-btn-green">
                        Подробности
                    </Button>
                </span>
            );
        }

         return null;
    }

    const handleClickShowShipperCompanyInfo = (record) => {
        setCurrentShipperCompanyId(record.Id);
        setShowShipperCompanyInfoModal(true);
    }



    const expandedRowRender = (record) => {
        const columns = [
            { title: "Юр. лицо", dataIndex: "Name", key: "Name" },
            { title: "Статус", key: "Id", render: (id, record) => {
                return orderToShipperCompanyButtonRender(record);
            } },
            { title: "", key: "", render: (id, record) => {
                    return (
                        <span>
                            <Button onClick={() => handleClickShowShipperCompanyInfo(record)} className="small-button typical-btn-blue">
                                Подробнее
                            </Button>
                        </span>
                    );
                }
            },
        ];
        return <Table columns={columns} dataSource={record.ShipperCompanies} pagination={false} rowKey="Id" />;
    };

    const tableRender = () => {
        const columns = [
            { title: "Грузоотправитель", dataIndex: "Name", key: "Name" },
            { title: "", key: "Id", render: (id, record) => {
                    return orderToConsignorButtonRender(record);
                }
            },
            { title: "", key: "", render: (id, record) => {
                    return redirectToAdditionalInfoBtnRender(record);
                }
            },
        ];
        
        return <Table columns={columns} dataSource={data} pagination={false} rowKey="Id" expandedRowRender={expandedRowRender} />;
    };
    
    return(
        <>
            <Modal
                title="Заключение договоров"
                cancelText="Закрыть"
                className="modal-window"
                align={null}
                visible={isShow}
                onCancel={() => {
                    setIsShow(false);
                    if (setIsShowParent) {
                        setIsShowParent(false);
                    }
                }}
                footer={null}
            >
                <Spinner loading={loadingContentModal} label={null}>
                    <h4 style={{textAlign: 'center', color: 'black'}}>{helpText}</h4>
                  {tableRender()}
                </Spinner>
            </Modal>

            <OrderInfoModal isShow={showInfoModal} setIsShow={setShowInfoModal} orderId={currentOrderId} />
            <ModalCreateOrderToConsignor setNeedUpdateOrdersList={setNeedUpdate} isShow={showOrderToConsignorModal} setIsShow={setShowOrderToConsignorModal} legalEntityId={legalEntityId} consignorId={currentConsignorId} />
            <ModalShipperCompanyFullInfo shipperCompanyId={currentShipperCompanyId} setIsShow={setShowShipperCompanyInfoModal} isShow={showShipperCompanyInfoModal} />
            <ModalCreateContractOrder shipperCompanyID={currentShipperCompanyId} legalEntityID={legalEntityId} isShow={showOrderToShipperCompanyModal} setIsShow={setShowOrderToShipperCompanyModal} />
        </>
    );
}

function OrderInfoModal({isShow, setIsShow, orderId}) {
    
    const [orderInfoData, setOrderInfoData] = useState({});
    const [loadingContentModal, setLoadingContentModal] = useState(false);
    
    useEffect(() => {
        if (isShow) {
            setLoadingContentModal(true);
            companyService
                .getOrderInfo(orderId)
                .then(resp => {
                    if (resp.code !== 400) {
                        setOrderInfoData(resp);
                    } else {
                        notification.error({
                            message: "Получение информации о заявке",
                            description: resp.message,
                        });
                        setIsShow(false);
                    }
                })
                .catch(errMsg => {
                    notification.error({
                        message: "Получение информации о заявке",
                        description: errMsg,
                    });
                    setIsShow(false);
                })
                .finally(() => setLoadingContentModal(false))
            ;
        }
    },[isShow,orderId]);

    const handleResendOrder = () => {
        companyService
            .resendOrder(orderId)
            .then(resp => {
                if (resp.code !== 400) {
                    notification.success({
                        message: "Повторная отправка заявки",
                        description: resp.message,
                    });
                } else {
                    notification.error({
                        message: "Повторная отправка заявки",
                        description: resp.message,
                    });
                }
            })
            .catch(errMsg => {
                notification.error({
                    message: "Повторная отправка заявки",
                    description: errMsg,
                });
                setIsShow(false);
            })
        ;
    };

    return (
        <Modal
          title="Информация о заявке"
          cancelText="Закрыть"
          className="modal-window"
          align={null}
          visible={isShow}
          onCancel={() => {
              setIsShow(false);
          }}
          footer={orderInfoData.refuseReason ? <Button onClick={handleResendOrder} className="small-button typical-btn-green">Отправить повторно</Button> : null}
        >
          <Spinner loading={loadingContentModal} label={null}>
            <Row gutter={[16,24]}>
              <Col sm={8} offset={8}>
                  <p>Грузоотправитель: {orderInfoData.legalEntityName}</p>
                  <p>Грузоперевозчик: {orderInfoData.shipperCompanyName}</p>
                  <p>Статус: {orderInfoData.status}</p>
                  <p>Дата создания: {orderInfoData.dateCreated}</p>
                  { orderInfoData.refuseReason && (
                      <>
                        <p>Причина отмены: {orderInfoData.refuseReason}</p>
                        <p>Дата отмены: {orderInfoData.refuseDate}</p>                        
                      </>
                  )}
              </Col>
            </Row>
          </Spinner>
        </Modal>
    );
}
