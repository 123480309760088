import React, {useEffect, useState} from "react";
import {Col, Tabs} from "antd";
import Spinner from "../../../spinner";
import AddParamsOnContract from "./add-params-on-contract";
import {useFetchNotification} from "../../../../hooks/use-fetch-notification";
import NotificationMessage from "../../../modals/notification-message";
import {consignorService} from "../../../../services";

export default function ShipperCompanySettings() {
    const [{ notifications }] = useFetchNotification(true);
    const [loading, setLoading] = useState(false);

    const [shipperCompany, setShipperCompany] = useState({});

    const { TabPane } = Tabs;

    const queryParams = new URLSearchParams(window.location.search);
    const shipperCompanyID = queryParams.get('shipperCompanyID');

    useEffect(() => {
        if (shipperCompanyID) {
            setLoading(true);

            consignorService
                .geShipperCompanyInfo(shipperCompanyID)
                .then((resp) => {
                    setShipperCompany(resp);
                    setLoading(false);
                });
        }
    }, [shipperCompanyID])

    return (
        <Spinner loading={loading} tip="Загрузка...">
            <NotificationMessage notifications={notifications} setStopListUpdate={(val) => {}} loading={false} />

            <Col className={"content-header"} style={{ marginBottom: "0px" }} span={12}>Настройки {shipperCompany.Name}</Col>
            <br />
            <br />
            <br />
            <Col span={24}>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Документы для договора" key="1">
                        <AddParamsOnContract shipperCompanyID={shipperCompanyID} />
                    </TabPane>
                </Tabs>
            </Col>
        </Spinner>
    );
}