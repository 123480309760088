import React, { useEffect, useState } from 'react';
import { Modal, notification, Button } from "antd";
import { notificationServices } from "../../../services/notification.services";


export default function NotificationMessage({ notifications, setNeedUpdate, setStopListUpdate, loading }) {
    const [modalIndex, setModalIndex] = useState(0);
    const [visible, setVisible] = useState(true);
    const bigButtonStyle = { width: '190px', height: '40px', background: '#1CAA53', position: 'static', borderRadius: '10px', borderColor: "#1CAA53" };
    useEffect(() => {
        if (notifications) {
            setVisible(true);
            setStopListUpdate(true);
        }
    }, [notifications]);

    if (setNeedUpdate) {
        setNeedUpdate(false);
    }

    if (loading) {
        return null;
    }

    const handleOk = (e) => {
        notificationServices.readNotification(notifications[modalIndex].id)
            .then((response) => {
                setStopListUpdate(true);
                notification.success({
                    message: "Уведомление прочитано",
                    description: response.message,
                });
                if (modalIndex < notifications.length - 1) {
                    setModalIndex(modalIndex + 1);
                } else {
                    setVisible(false);
                    setStopListUpdate(false);

                    if (setNeedUpdate) {
                        setNeedUpdate(true);
                    }
                }
            })
            .catch((error) => {
                notification.error({
                    message: "Ошибка",
                    description: error,
                });
                setVisible(false);
                setStopListUpdate(false);

                if (setNeedUpdate) {
                    setNeedUpdate(true);
                }
            })
    }

    if (!notifications || !notifications.length) {
        return null;
    }

    return (
        <Modal
            title="Уведомление"
            visible={visible}
            footer={null}
            onCancel={handleOk}
        >
            <div style={{textAlign: "center"}}>
               <p style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: notifications[modalIndex].message}} />
            </div>
            <div className="center"  style={{padding: "30px"}}>
                <Button type="primary" onClick={handleOk} style={bigButtonStyle}>OK</Button>
            </div>
        </Modal>);
}