import React from 'react';
// import YandexDirect from "../yandex-direct";

export default function Content(props) {

  return (
    <main className="main">
      <div className="container">
        {props.children}
        {/*<YandexDirect/>*/}
      </div>
    </main>
  );
}
