import React, {useContext, useEffect, useState} from "react";
import {notification, Pagination} from "antd";
import moment from "moment";
import {userServices, orderDeliveryService} from "../../../../../services";
import {useFetchDeliveriesList} from "../../../../../hooks/use-fetch-deliveries-list";
import Error from "../../../../error";
import Spinner from "../../../../spinner";
import DeliveryItem from "../../../../delivery-item-block";
import ModalCreateOrder from "../../../../modals/create-order-delivery";
import CarrierContentItem from "../../../../delivery-item-content/carrier/content-item";
import DeliveryEmptyContent from "../../../../delivery-empty-content";
import DeliveryGroupList from "../../../../delivery-group-list";
import {AppContext} from "../../../../../contexts/app-context";
import {VIEW_FREE_DELIVERIES_CARRIER} from "../../../../../helpers/user_permission.helper";
import UserNotificationMessage from "../../../../user-message";
import NotificationMessage from "../../../../modals/notification-message";
import Price from "../../../../price";
import {DELIVERY_FREE} from "../../../../../helpers/delivery_status.helper";
import ModalNeedApprovedLegalEntity from "../../../../modals/need-approved-legal-entity";
import {deliveriesConfig} from "../../../../../config";
import {Redirect} from "react-router-dom";
import ShowTerms from "../../../../modals/show-terms";
import ShowOffer from "../../../../modals/show-offer";
import AdsBanner from "../../../../ads-banner";

function HeaderItem({
                      delivery,
                      timerEnabled,
                      openModal,
                      setNeedUpdate,
                      setStopListUpdate,
                      setLoadingDelivItem,
                      nameRegionLastPointUnload,
                      regionLoad,
                      permissions
                    }) {
  const [showModalCreateOrder, setShowModalCreateOrder] = useState(false);
  const [paymentType, setPaymentType] = useState(delivery.AllowedPaymentTypes[0].Id);

  const onClickRefuseOrder = (e) => {
    e.preventDefault();
    setLoadingDelivItem(true);

    orderDeliveryService
      .refuseOrder(delivery.Order.Id)
      .then((response) => {
        notification.success({
          message: "Отмена заявки",
          description: response.message,
        });
        setLoadingDelivItem(false);
        setNeedUpdate(true);
      })
      .catch((error) => {
        notification.error({
          message: "Отмена заявки",
          description: error,
        });
        setNeedUpdate(true);
      });
  };

  const onClickRefuseCounteroffer = (e) => {
    e.preventDefault();
    setLoadingDelivItem(true);

    orderDeliveryService
      .refuseCounteroffer(delivery.Order.Id)
      .then((response) => {
        notification.success({
          message: "Отмена встречного предложения",
          description: response.message,
        });
        setLoadingDelivItem(false);
        setNeedUpdate(true);
      })
      .catch((error) => {
        notification.error({
          message: "Отмена встречного предложения",
          description: error,
        });
        setNeedUpdate(true);
      });
  };

  const onClickAgreeCounteroffer = (e) => {
    e.preventDefault();
    setLoadingDelivItem(true);

    orderDeliveryService
      .agreeCounteroffer(delivery.Order.Id)
      .then((response) => {
        notification.success({
          message: "Подтверждение встречного предложения",
          description: response.message,
        });
        setLoadingDelivItem(false);
        setNeedUpdate(true);
      })
      .catch((error) => {
        notification.error({
          message: "Подтверждение встречного предложения",
          description: error,
        });
        setNeedUpdate(true);
      });
  };

  const renderHeaderBtn = (delivery, permissions) => {
    if (delivery.Order) {
      if (delivery.Order.Counteroffer) {
        return (
          <React.Fragment>
            <div className="application-topline-carrier-item-col">
              <p className="typical-icon-field grey-txt">
                Предложение: <span>{delivery.Order.Counteroffer.Price} </span>₽
              </p>
            </div>
            <div style={{display: "flex", flexDirection: "column", marginLeft: "20px"}}>
              <button className="btn typical-btn typical-btn-green typical-btn-xs"
                      onClick={onClickAgreeCounteroffer}>
                Принять
              </button>
              <button className="btn typical-btn typical-btn-red typical-btn-xs"
                      onClick={onClickRefuseCounteroffer}>
                Отменить
              </button>
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            {/*                         <div className="application-topline-carrier-item-col">
                            <p className="typical-icon-field grey-txt">
                                Цена: <span>{deliveryOrderPrice} </span>₽
                            </p>
                        </div> */}
            <div
              className="application-topline-carrier-item-col application-topline-carrier-item-col-button">
              <button className="btn typical-btn typical-btn-red" onClick={onClickRefuseOrder}>
                Отменить заявку <div className="typical-btn-icon typical-btn-red-icon"/>
              </button>
            </div>
          </React.Fragment>
        );
      }
    } else {
      if (typeof (permissions.IsCanCreateOrder) != "undefined" && permissions.IsCanCreateOrder === false) {
        return (
          <div className="application-topline-carrier-item-col application-topline-carrier-item-col-button">
            <button
              className="btn typical-btn typical-btn-green"
              onClick={() => {
                setStopListUpdate(true);
                setShowModalCreateOrder(true);
              }}
            >
              Подать заявку <div className="typical-btn-icon typical-btn-green-icon"/>
            </button>
            <ModalNeedApprovedLegalEntity
              isShow={showModalCreateOrder}
              setIsShow={setShowModalCreateOrder}
              text={'Для подачи заявки на рейс Вам необходимо добавить и согласовать юридическое лицо, и заключить договор'}
              title={'Подача заявки'}
            />
          </div>
        );
      }
      if (!timerEnabled) {
        return (
          <div className="application-topline-carrier-item-col application-topline-carrier-item-col-button">
            <button
              className="btn typical-btn typical-btn-green"
              onClick={() => {
                setStopListUpdate(true);
                setShowModalCreateOrder(true);
              }}
            >
              Подать заявку <div className="typical-btn-icon typical-btn-green-icon"/>
            </button>
            <ModalCreateOrder
              isShow={showModalCreateOrder}
              setIsShow={setShowModalCreateOrder}
              delivery={delivery}
              setNeedUpdateDeliveryList={setNeedUpdate}
              setStopListUpdate={setStopListUpdate}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
            />
          </div>
        );
      } else {
        return null;
      }
    }
  };

  const dateShipmentRus = moment(delivery.DateShipment).format("D MMMM YYYY");

  return (
    <div className="application-topline">
      <div className="application-topline-carrier">
        <div className="application-topline-carrier-item">
          <div className="application-topline-carrier-item-col application-topline-carrier-item-col-city">
            <p className="typical-icon-field icon-field-patch" title={regionLoad}>
              {regionLoad}
            </p>
            <p className="typical-icon-field icon-field-dispatch" title={nameRegionLastPointUnload}>
              {nameRegionLastPointUnload}
            </p>
          </div>
          <div className="application-topline-carrier-item-col application-topline-carrier-item-col-date">
            <div className="typical-icon-field icon-field-calendar">{dateShipmentRus}</div>
          </div>
        </div>
        <div className="application-topline-carrier-item">
          <div className="application-topline-carrier-item-col application-topline-carrier-item-price">
            {permissions.IsCanCreateOrder && <Price delivery={delivery}/>}
          </div>
          {renderHeaderBtn(delivery, permissions)}
        </div>
      </div>
    </div>
  );
}

export default function CarrierNewDeliveries() {
  const [user, setUser] = useState(userServices.getUser());
  const [isShowTerms, setIsShowTerms] = useState(false);
  const [isShowOffer, setIsShowOffer] = useState(false);

  const [wasOpenedBeforeUpdate, setWasOpenedBeforeUpdate] = useState({});
  const [step, setStep] = useState(deliveriesConfig.pageCount);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [{
    error,
    loading,
    data: deliveries,
    TotalCount,
    openModal,
    setStopListUpdate,
    setNeedUpdate,
    notifications,
    permissions,
    totalCount,
    extData
  }] = useFetchDeliveriesList(
    "delivery/get-free",
    true,
    true,
    true,
    true,
    deliveriesConfig.secondForUpdate,
    offset,
    step
  );

  const appContext = useContext(AppContext);

  useEffect(() => {
    appContext.initFilter();
    setUser(userServices.getUser());
  }, []);

  useEffect(() => {
    if (user.info.termsOfUse) {
      setIsShowTerms(true);
    }
  });

  useEffect(() => {
    if (user.info.privacyPolicy) {
      setIsShowOffer(true);
    }
  });

  const setIsTermsAgreed = () => {
    userServices.readTermsOfUse()
      .then(() => {
        userServices.updateUserInfo()
          .then(() => {
            window.location.replace("/");
          })
      })
      .catch((err) => {
        notification.error({
          message: err,
          description: "Ошибка"
        })
      })
  }

  const setIsOfferAgreed = () => {
    userServices.readPrivacyPolicy()
      .then(() => {
        userServices.updateUserInfo()
          .then(() => {
            window.location.replace("/");
          })
      })
      .catch((err) => {
        notification.error({
          message: err,
          description: "Ошибка"
        })
      })
  }

  let onChange = (page, pageSize) => {
    if (page === currentPage) {
      return false;
    } else {
      setCurrentPage(page);
    }

    let val = 0;
    if (parseInt(page) === 1) {
      setOffset(0);
    } else {
      val = page * pageSize - pageSize;
      setOffset(val);
    }
    appContext.filter.offset = val;
    setNeedUpdate(true);
  };

  if (!user.isAccessTo(VIEW_FREE_DELIVERIES_CARRIER)) {
    return <Error status={403} desc="Страница не доступна"/>;
  }

  if (error) {
    return <Error type={500} desc={error.message}/>;
  }

  let listGroupDeliveries = {};

  for (let itemIdx in deliveries) {
    if (deliveries.hasOwnProperty(itemIdx)) {
      const delivery = deliveries[itemIdx];
      const region = delivery.PlaceLoad.Region;

      if (typeof listGroupDeliveries[region] !== "undefined") {
        listGroupDeliveries[region].push(delivery);
      } else {
        const itemsGroup = [];
        itemsGroup.push(delivery);

        listGroupDeliveries[region] = itemsGroup;
      }
    }
  }

  let listItems = [];

  if (!loading) {
    listItems = deliveries.map((delivery, index) => {
      return (
        <React.Fragment key={delivery.Id}>
          <DeliveryItem
            key={delivery.Id}
            header={HeaderItem}
            content={CarrierContentItem}
            delivery={delivery}
            openModal={openModal}
            setStopListUpdate={setStopListUpdate}
            setNeedUpdate={setNeedUpdate}
            secondsToAutoRefuse={delivery.secondsToAutoRefuse}
            wasOpenedBeforeUpdate={wasOpenedBeforeUpdate}
            setWasOpenedBeforeUpdate={setWasOpenedBeforeUpdate}
            permissions={permissions}
          />
          {index === 1 && <AdsBanner type={"2"}/>}
        </React.Fragment>
      );
    });
  }

  return (
    <>
      <Spinner loading={loading}>
        <UserNotificationMessage/>
        <NotificationMessage notifications={notifications} setNeedUpdate={false}
                             setStopListUpdate={setStopListUpdate} loading={loading}/>
        {totalCount !== 0 ?
          <div className="application-block pagination pagination__up">
            <Pagination
              style={{textAlign: "center"}}
              current={currentPage}
              pageSize={step}
              total={totalCount}
              showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
              onChange={onChange}
            />
          </div>
          : null}
        {!loading ? deliveries.length ? <DeliveryGroupList deliveriesItems={listItems}/> :
          <DeliveryEmptyContent extData/> : null}
        {totalCount !== 0 ?
          <div className="application-block pagination pagination__down">
            <Pagination
              style={{textAlign: "center"}}
              current={currentPage}
              pageSize={step}
              total={totalCount}
              showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount} рейсов`}
              onChange={onChange}
            />
          </div>
          : null}
      </Spinner>
      <ShowTerms setIsShow={setIsShowTerms} setIsTermAgreed={setIsTermsAgreed}
                 isShow={isShowTerms}/>
      <ShowOffer setIsShow={setIsShowOffer} setIsOfferAgreed={setIsOfferAgreed}
                 isShow={isShowOffer}/>
    </>
  );
}
