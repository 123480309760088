import React, {useEffect, useState} from "react";
import {Switch, Route, BrowserRouter, useLocation, useRouteMatch} from "react-router-dom";
import {notification} from "antd";

import {mainConfig} from "@config";
import {AppContext} from "@contexts/app-context";

import Header from "@components/header";
import Content from "@components/content";
import MainFooter from "@components/main-page-blocks/footer";
import HomePage from "@pages/home-page";
import LoginPage from "@pages/login-page";
import ResetPage from "@pages/reset-page";
import RegisterPage from "@pages/register-page";
import CarrierNewDeliveries from "@pages/deliveries/carrier/new-deliveries";
import CarrierBookedDeliveries from "@pages/deliveries/carrier/booked-deliveries";
import CarrierArchivedDeliveries from "@pages/deliveries/carrier/archived-deliveries";
import LogistFreeDeliveries from "@pages/deliveries/logist/free-deliveries";
import LogistReservedDeliveries from "@pages/deliveries/logist/reserved-deliveries";
import LogistBookedDeliveries from "@pages/deliveries/logist/booked-deliveries";
import LogistArchivedDeliveries from "@pages/deliveries/logist/archived-deliveries";
import DriverNewDeliveries from "@pages/deliveries/driver/new-deliveries";
import LogistOnLoadingDeliveries from "@pages/deliveries/logist/on-load-deliveries";
import LogistEnRouteDeliveries from "@pages/deliveries/logist/enroute-deliveries";
import CarrierOnLoadingDeliveries from "@pages/deliveries/carrier/on-load-deliveries";
import CarrierEnRouteDeliveries from "@pages/deliveries/carrier/enroute-deliveries";
import Error from "@components/error";

import {userServices} from "@services";
import "moment/locale/ru";
import "@assets/style.less";
import SettingsPage from "@pages/settings-page";
import ReportsPage from "@pages/reports-page";
import GuestNewDeliveries from "@pages/deliveries/guest/free-deliveries";
import ConsignorPage from "@pages/consignor-page";
import CompanyPage from "@pages/company-page";
import TermsOfUse from "@pages/terms-of-use";
import ServicePage from "@pages/service-page";
import PrivateRoute from "@components/routers/private-router";
import ModalCreateNewLegalEntity from "@modals/create-legal-entity";
import ReturnDeliveries from "@pages/deliveries/carrier/return-deliveries";
import DriverPage from "@pages/driver-page";
import StartPage from "@pages/start-page";
import PrivacyPage from "@pages/privacy";
import AdsBanner from "../ads-banner";


notification.config({
  placement: "bottomRight",
  bottom: 25,
  duration: 5,
});

export default function App() {
  document.title = mainConfig.companyName;
  document.querySelector('#root').className = window.location.pathname !== '/' ? 'theme' : 'main-page';


  const [user, setUser] = useState(null);
  const [needUpdateUser, setNeedUpdateUser] = useState(false);

  const [showModalCreateEntity, setShowModalCreateEntity] = useState(false);

  let location = useLocation();


  const updateUser = (callback = null, isNeedSetUser = true) => {
    userServices.updateUserInfo()
      .then(() => {
        let userData = userServices.getUser();

        if (isNeedSetUser) {
          setUser(userData);
        }

        if (callback instanceof Function) {
          callback(userData);
        }
      });
  };

  /**
   * Автообновление информации о пользователе
   * Каждую минуту
   */
  useEffect(() => {
    updateUser();

    const updateInterval = setInterval(() => updateUser(null, false), 1000 * 600);
    return () => clearInterval(updateInterval);
  }, []);

  useEffect(() => {
    if (needUpdateUser) {
      updateUser((userData) => {
        if (userData.info.isStaff) {
          // history.push("/deliveries/list-free");
          window.location.href = "/deliveries/list-free";
        } else {
          // history.push("/deliveries/list-new");
          window.location.href = "/deliveries/list-new";
        }
      }, true);
      setNeedUpdateUser(false);
    }
  }, [needUpdateUser]);

  useEffect(() => {
    if (user && user.loggedIn) {
      if (user.info.rules.IsNeedCreateLegalEntity) {
        setShowModalCreateEntity(true);
      }
    }
  }, [user])

  const AppProvider = (props) => {
    const userData = userServices.getUser();

    const defaultSort = [
      {
        name: "default",
        title: "По умолчанию",
      },
      {
        name: "new_desc",
        title: "Сначала новые",
      },
      {
        name: "new_asc",
        title: "Сначала старые",
      },
      {
        name: "price_desc",
        title: "Сначала дорогие",
      },
      {
        name: "price_asc",
        title: "Сначала дешевые",
      },
    ];

    const [userStore, setUserStore] = useState({
      ...userData,
    });

    const [filterStore, setFilterStore] = useState({
      enable: false,
      selectedRegionsLoading: {},
      selectedRegionsUnloading: {},
      selectedSorting: defaultSort[0].name,
      regionsLoadings: [],
      regionsUnloadings: [],
      sort: defaultSort,
    });

    const stores = {
      user: userStore,
      filter: {
        ...filterStore,
      },
    };

    const appStoreProvider = {
      ...stores,
      applyFilterParams: (selectedRegionsLoading, selectedRegionsUnloading, selectedSorting) => {
        setFilterStore({
          ...filterStore,
          enable: true,
          selectedRegionsLoading: selectedRegionsLoading,
          selectedRegionsUnloading: selectedRegionsUnloading,
          selectedSorting: selectedSorting,
        });
      },
      initFilter: (listSort = defaultSort) => {
        setFilterStore({
          ...filterStore,
          sort: listSort,
        });
      },
      enableFilter: (regionsLoadings, regionsUnloadings) => {
        setFilterStore({
          ...filterStore,
          enable: true,
          regionsLoadings: regionsLoadings,
          regionsUnloadings: regionsUnloadings,
        });
      },
      disableFilter: () => {
        setFilterStore({
          ...filterStore,
          enable: false,
          regionsLoadings: [],
          regionsUnloadings: [],
          selectedRegionsLoading: [],
          selectedRegionsUnloading: [],
          selectedSorting: defaultSort[0].name,
        });
      },
      login: async (username, password) => {
        return await userServices.login(username, password).then((resUserData) => {
          const objJsonStr = JSON.stringify(resUserData);
          const objJsonB64 = Buffer.from(objJsonStr).toString("base64");

          localStorage.setItem("user_auth_v2", objJsonB64);

          const userData = userServices.getUser();
          setUserStore({...userData});

          updateUser();
        });
      },

      logout: () => {
        userServices.logout().then(() => {
          const userData = userServices.getUser();

          setFilterStore({...filterStore, enable: false});
          setUserStore({...userData});

          updateUser();
        });
      },
      reset: async (username) => {
        return await userServices.resetPass(username).then((data) => {
          return username;
        });
      },
    };

    return <AppContext.Provider value={appStoreProvider}>{props.children}</AppContext.Provider>;
  };

  return (
    <AppProvider>
      <BrowserRouter>
        <div className="wrapper">
          {(window.location.pathname !== '/') &&
            <Header user={user} setNeedUpdateUser={setNeedUpdateUser}/>
          }
          <Switch>
            <Route path="/" exact component={HomePage}/>
            <Route path="/login" component={LoginPage} user={user}/>
            <Route path="/register" component={RegisterPage}/>
            <Route path="/reset" component={ResetPage}/>
            <Route path="/terms-of-use" component={TermsOfUse}/>
            <Route path="/privacy" component={PrivacyPage}/>

            <Route path="/deliveries/dashboard-deliveries">
              <Content>
                <GuestNewDeliveries/>
              </Content>
            </Route>

            <PrivateRoute path="/start" component={<StartPage/>}/>

            <PrivateRoute path="/deliveries/list-new" component={<CarrierNewDeliveries/>}/>
            <PrivateRoute path="/deliveries/return-deliveries/:id" component={<ReturnDeliveries/>}/>
            <PrivateRoute path="/deliveries/list-my" component={<CarrierBookedDeliveries/>}/>
            <PrivateRoute path="/deliveries/list-archived-carrier" component={<CarrierArchivedDeliveries/>}/>
            <PrivateRoute path="/deliveries/list-onload-carrier" component={<CarrierOnLoadingDeliveries/>}/>
            <PrivateRoute path="/deliveries/list-enroute-carrier" component={<CarrierEnRouteDeliveries/>}/>

            <PrivateRoute path="/deliveries/list-free" component={<LogistFreeDeliveries/>}/>
            <PrivateRoute path="/deliveries/list-reserve" component={<LogistReservedDeliveries/>}/>
            <PrivateRoute path="/deliveries/list-booked" component={<LogistBookedDeliveries/>}/>
            <PrivateRoute path="/deliveries/list-archived-logist" component={<LogistArchivedDeliveries/>}/>
            <PrivateRoute path="/deliveries/list-onload-logist" component={<LogistOnLoadingDeliveries/>}/>
            <PrivateRoute path="/deliveries/list-enroute-logist" component={<LogistEnRouteDeliveries/>}/>

            <PrivateRoute path="/deliveries/list-new-driver" component={<DriverNewDeliveries/>}/>
            <PrivateRoute path="/settings" component={<SettingsPage/>}/>
            <PrivateRoute path="/reports" component={<ReportsPage/>} aside={true}/>
            <PrivateRoute path="/driver" component={<DriverPage/>}/>

            <PrivateRoute path="/company" component={<CompanyPage/>}/>
            <PrivateRoute path="/consignor" component={<ConsignorPage/>}/>
            <PrivateRoute path="/service" component={<ServicePage/>}/>

            <Route render={() => <Content><Error status={404} desc="Страница не найдена"/></Content>}/>
          </Switch>
          {(window.location.pathname !== '/') &&
            <MainFooter mod={"dark"}/>
          }
        </div>
        {/*<AdsBanner type={"1"}/>*/}
        <ModalCreateNewLegalEntity
          isShow={showModalCreateEntity}
          setIsShow={setShowModalCreateEntity}
          setLoadingList={() => {
          }}
          setStopListUpdate={() => {
          }}
        />
      </BrowserRouter>
    </AppProvider>
  );
}
