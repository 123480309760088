import React, { useState } from "react";
import { userServices } from "@services";

export default function UserNotificationMessage() {
  const [needUpdate, setNeedUpdate] = useState(true);
  const [data, setData] = useState(null);

  const user = userServices.getUser();

  if (needUpdate && !user.isGuest()) {
    setNeedUpdate(false);
    userServices.getMessage().then((response) => {
      setData(response);
    });
  }

  if (data) {
    return (
      <div className="user-notification">
        <p className="user-notification__text">{data.subject}</p>
      </div>
    );
  }
  return null;
}
