import {FILE_TYPES, MULTIPLE_FILES_SIZE_LIMIT} from "./form-inputs-limits.helper";

export const formHelper = {
    getFieldErrors,
    b64toBlob,
    firstToUpperOtherToLower,
    checkFiles,
    createFile,
};

function getFieldErrors(nameField, fieldsErrors) {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
        ? fieldsErrors[nameField][0]
        : null;
}

function b64toBlob (b64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
}

function firstToUpperOtherToLower(string) {
    if (!string) {
        return string
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
}

function checkFiles (files, uploadedFiles=[], setError, errors, name) {
    let uploadedFilesSize = 0;
    let isCorrectSize;
    let isCorrectType;
    let errorText='';
    let isIncludePdfUploadedFiles = false;
    let isIncludePdfFiles = false;

    if (uploadedFiles.length) {
        uploadedFilesSize = uploadedFiles.reduce((accumulator, currentValue) => accumulator + currentValue.size,
          uploadedFilesSize);
        isIncludePdfUploadedFiles = uploadedFiles.some(el => el.type === "application/pdf");
    }

    const newFilesSize = files.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0);
    isIncludePdfFiles = files.some(el => el.type === "application/pdf");

    isCorrectType = files.every((file) => FILE_TYPES.includes(file.type.split('/')[1]));
    isCorrectSize = uploadedFilesSize + newFilesSize < MULTIPLE_FILES_SIZE_LIMIT;

    if (!isCorrectType || isIncludePdfUploadedFiles || (uploadedFiles.length > 1 && isIncludePdfFiles) || (files.length > 1 && isIncludePdfFiles)) {
        errorText = `Можно загрузить либо 1 файл pdf, либо несколько файлов png/jpg`;
        setError({...errors, [name]: errorText});
        isCorrectType = false;
    }

    if (!isCorrectSize) {
        errorText = `Общий размер загружаемых файлов не должен превышать ${MULTIPLE_FILES_SIZE_LIMIT / 1024 / 1024}MB`;
        setError({...errors, [name]: errorText});
    }

    return isCorrectSize && isCorrectType;
}

function createFile(
    b64,
    contentType,
    name,
    alias = 'Документ',
    thumbUrl = 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg',
    extension = 'pdf'
) {
    let blob = formHelper.b64toBlob(b64, contentType);
    let file = new File([blob], `${alias}.${extension}`);

    file.uid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    file.url = URL.createObjectURL(blob);
    file.fileName = name;
    file.fileAlias = alias;
    file.thumbUrl = thumbUrl;

    return file;
}
