import React from "react";
import {Link} from "react-router-dom";
import {Button} from "antd";
import './style.less';

export default function MainHeader() {
  return(
    <header className="header">
      <div className="container">
        <div className="header__inner">
          <a className="header__logo">
            <svg width="264" height="36" viewBox="0 0 264 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_298_4448)">
                <path d="M114.578 19.6386C112.438 20.5989 110.154 21.0481 107.316 21.0481C100.028 21.0481 95.5997 17.934 95.5997 12.362C95.5997 6.79003 100.024 3.73755 107.316 3.73755C110.124 3.73755 112.434 4.21766 114.578 5.20432V9.01001C113.102 8.17311 110.643 7.30098 107.376 7.30098C102.547 7.30098 99.7988 9.1862 99.7988 12.362C99.7988 15.5378 102.488 17.5111 107.495 17.5111C110.592 17.5111 113.251 16.7623 114.583 16.0444V19.6386H114.578Z" fill="#44813F"/>
                <path d="M116.548 20.7179V4.00635H126.963C129.915 4.00635 133.646 4.15611 133.646 8.67977C133.646 10.0893 132.43 11.2565 131.592 11.5252C132.808 11.7058 135.004 12.8422 135.004 15.5996C135.004 19.8546 131.272 20.7223 128.235 20.7223H116.548V20.7179ZM120.454 7.20859V10.7148H127.252C128.614 10.7148 129.452 10.2038 129.452 8.97929C129.452 7.75477 128.584 7.21299 127.252 7.21299H120.454V7.20859ZM120.454 13.6791V17.5113H128.175C129.707 17.5113 130.809 17.0928 130.809 15.5952C130.809 14.0976 129.711 13.6791 128.175 13.6791H120.454Z" fill="#44813F"/>
                <path d="M136.629 20.7179V4.00635H152.944V7.21299H140.534V10.8381H150.689V13.8025H140.534V17.5157H152.944V20.7223H136.629V20.7179Z" fill="#44813F"/>
                <path d="M161.359 20.7179V7.51251H154.216V4.00635H172.382V7.51251H165.265V20.7223H161.359V20.7179Z" fill="#44813F"/>
                <path d="M183.95 21.0481C177.76 21.0481 172.667 17.7534 172.667 12.362C172.667 6.97062 177.76 3.73755 183.95 3.73755C190.14 3.73755 195.233 6.97062 195.233 12.362C195.233 17.7534 190.2 21.0481 183.95 21.0481ZM183.95 7.33181C179.815 7.33181 176.862 9.21704 176.862 12.362C176.862 15.507 179.81 17.4847 183.95 17.4847C188.089 17.4847 191.038 15.507 191.038 12.362C191.038 9.21704 188.085 7.33181 183.95 7.33181Z" fill="#44813F"/>
                <path d="M206.46 20.7179V19.0705C205.532 19.3392 204.579 19.4317 203.656 19.4317C199.98 19.4317 196.424 16.6171 196.424 12.2124C196.424 7.23942 200.444 5.2617 203.626 5.2617C204.579 5.2617 205.537 5.34979 206.46 5.62288V4.00635H210.365V5.62288C211.293 5.3542 212.276 5.2617 213.228 5.2617C216.411 5.2617 220.431 7.23942 220.431 12.2124C220.431 16.6127 216.874 19.4317 213.199 19.4317C212.246 19.4317 211.288 19.3436 210.365 19.0705V20.7179H206.46ZM206.46 15.9256V8.53001C205.766 8.31858 205.073 8.23048 204.35 8.23048C202.325 8.23048 200.619 9.49024 200.619 12.2124C200.619 14.6085 202.384 16.2251 204.205 16.2251C204.958 16.2251 205.711 16.1634 206.46 15.9256ZM210.365 8.53001V15.9256C211.118 16.1634 211.871 16.2251 212.65 16.2251C214.471 16.2251 216.236 14.6085 216.236 12.2124C216.236 9.48583 214.53 8.23048 212.505 8.23048C211.752 8.23048 211.059 8.31858 210.365 8.53001Z" fill="#44813F"/>
                <path d="M232.871 21.0481C226.681 21.0481 221.588 17.7534 221.588 12.362C221.588 6.97062 226.681 3.73755 232.871 3.73755C239.061 3.73755 244.153 6.97062 244.153 12.362C244.153 17.7534 239.121 21.0481 232.871 21.0481ZM232.871 7.33181C228.736 7.33181 225.783 9.21704 225.783 12.362C225.783 15.507 228.731 17.4847 232.871 17.4847C237.01 17.4847 239.959 15.507 239.959 12.362C239.959 9.21704 237.006 7.33181 232.871 7.33181Z" fill="#44813F"/>
                <path d="M250.258 15.6877V20.7179H246.353V4.00635H257.546C262.405 4.00635 263.996 7.36275 263.996 9.81619C263.996 12.2696 262.405 15.6877 257.546 15.6877H250.258ZM250.258 7.54335V12.1551H256.678C259.397 12.1551 259.831 10.8381 259.831 9.82059C259.831 8.86036 259.397 7.54335 256.678 7.54335H250.258Z" fill="#44813F"/>
                <path d="M107.508 28.8665C107.508 29.0603 107.491 29.2805 107.444 29.5051L106.823 32.496H105.78L106.402 29.4435C106.44 29.2585 106.457 29.0955 106.457 28.9369C106.457 28.2146 106.036 27.8534 105.278 27.8534C104.381 27.8534 103.832 28.3071 103.606 29.3862L102.96 32.4916H101.288L101.458 31.637H102.113L102.581 29.373C102.913 27.7565 103.874 26.968 105.385 26.968C106.712 26.968 107.512 27.6684 107.512 28.8665H107.508Z" fill="#44813F"/>
                <path d="M112.081 30.2451C112.081 28.3996 113.464 26.968 115.548 26.968C117.254 26.968 118.254 27.9194 118.254 29.2937C118.254 31.1393 116.871 32.5708 114.787 32.5708C113.081 32.5708 112.081 31.6194 112.081 30.2451ZM117.199 29.3598C117.199 28.4656 116.586 27.8578 115.455 27.8578C114.034 27.8578 113.145 28.9237 113.145 30.1835C113.145 31.0776 113.749 31.6855 114.88 31.6855C116.301 31.6855 117.199 30.6195 117.199 29.3598Z" fill="#44813F"/>
                <path d="M127.337 27.9019H124.517L123.589 32.4916H122.547L123.678 27.043H127.524L127.337 27.8975V27.9019Z" fill="#44813F"/>
                <path d="M136.986 27.043L135.854 32.4916H134.846L134.974 31.875C134.531 32.3242 133.94 32.5665 133.229 32.5665C132.081 32.5665 131.336 31.919 131.336 30.7694C131.336 30.5668 131.362 30.3509 131.409 30.1175L132.047 27.043H133.098L132.451 30.1483C132.413 30.3289 132.396 30.4919 132.396 30.6328C132.396 31.3332 132.8 31.6812 133.553 31.6812C134.395 31.6812 135.072 31.2319 135.289 30.1791L135.944 27.043H136.986Z" fill="#44813F"/>
                <path d="M141.262 30.2451C141.262 28.3996 142.644 26.968 144.746 26.968C145.716 26.968 146.499 27.2852 146.895 27.8798L146.133 28.4965C145.827 28.0824 145.342 27.8578 144.669 27.8578C143.223 27.8578 142.317 28.9105 142.317 30.1835C142.317 31.0864 142.929 31.6855 144.087 31.6855C144.716 31.6855 145.265 31.4829 145.695 31.0556L146.307 31.6943C145.759 32.3021 144.942 32.5752 144.006 32.5752C142.283 32.5752 141.257 31.6238 141.257 30.2495L141.262 30.2451Z" fill="#44813F"/>
                <path d="M155.756 28.3995C155.288 28.1176 154.769 27.9459 154.174 27.8798L153.212 32.4959H152.161L153.131 27.8578C152.493 27.9106 151.885 28.0912 151.387 28.3775L151.111 27.5935C151.804 27.1794 152.774 26.9724 153.697 26.9724C154.684 26.9724 155.556 27.1971 156.229 27.6243L155.761 28.4039L155.756 28.3995Z" fill="#44813F"/>
                <path d="M165.814 27.043L164.682 32.4916H163.674L163.801 31.875C163.359 32.3242 162.768 32.5665 162.057 32.5665C160.908 32.5665 160.164 31.919 160.164 30.7694C160.164 30.5668 160.189 30.3509 160.236 30.1175L160.874 27.043H161.925L161.278 30.1483C161.24 30.3289 161.223 30.4919 161.223 30.6328C161.223 31.3332 161.627 31.6812 162.38 31.6812C163.223 31.6812 163.899 31.2319 164.116 30.1791L164.771 27.043H165.814Z" fill="#44813F"/>
                <path d="M173.459 29.699L174.897 32.4916H173.774L172.595 30.1879H171.489L171.012 32.4916H169.962L171.093 27.043H172.144L171.668 29.3246H172.693L174.795 27.043H175.973L173.459 29.699Z" fill="#44813F"/>
                <path d="M185.026 28.8753C185.026 29.0779 185.009 29.2805 184.963 29.5051L184.341 32.496H183.299L183.584 31.0953H180.649L180.359 32.496H179.326L179.972 29.373C180.304 27.7565 181.3 26.968 182.84 26.968C184.205 26.968 185.031 27.6684 185.031 28.8753H185.026ZM183.759 30.2451L183.92 29.4435C183.958 29.2629 183.976 29.0999 183.976 28.9546C183.976 28.2234 183.533 27.8578 182.729 27.8578C181.793 27.8578 181.219 28.3115 180.993 29.3906L180.815 30.2451H183.759Z" fill="#44813F"/>
                <path d="M90.086 35.5L82.258 10.4988H80.8966L73.0686 35.5H90.086Z" fill="#44813F"/>
                <path d="M80.7052 5.05884L67.4486 35.4999H39.3443L78.7822 5.05884H80.7052Z" fill="#FFDB06"/>
                <path d="M80.6414 0.5L32.1715 35.5H0L78.9907 0.5H80.6414Z" fill="#EB5C3B"/>
              </g>
              <defs>
                <clipPath id="clip0_298_4448">
                  <rect width="264" height="35" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
              </defs>
            </svg>
          </a>
          {/*<div className="header__contacts">*/}
          {/*  <a href="tel:88008888888" className="header__phone text16 mob-text12 extra-bold-weight">*/}
          {/*    8-800-888-88-88*/}
          {/*  </a>*/}
          {/*  <span className="text12 mob-text8">Поддержка клиентов</span>*/}
          {/*</div>*/}
          <div className="header__action">
            <a className="header__login"
                  href='/login'
            >
              <svg width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.9068 6.5C11.0433 3.19073 14.7636 1 18.9956 1C25.6231 1 30.9956 6.37258 30.9956 13C30.9956 19.6274 25.6231 25 18.9956 25C14.7636 25 11.0433 22.8093 8.9068 19.5"
                  stroke="#44803F" strokeWidth="2"/>
                <path d="M0.995667 13.5H20.9957M20.9957 13.5L17.4957 10M20.9957 13.5L17.4957 17" stroke="#44803F"
                      strokeWidth="2"/>
              </svg>
              <span className="text13 bold-weight">Войти</span>
            </a>
            {/*<a className="header__btn m-btn m-btn-primary m-btn-small m-btn-triangle-right"*/}
            {/*      href='/register'*/}
            {/*>*/}
            {/*    <span className="text13 mob-text10 bold-weight">*/}
            {/*        Подключиться*/}
            {/*    </span>*/}
            {/*</a>*/}
          </div>
        </div>
      </div>
      {/*<a className="header__btn-mob m-btn"*/}
      {/*   href='/register'*/}
      {/*>*/}
      {/*  Подключиться*/}
      {/*</a>*/}
    </header>
  )
}
