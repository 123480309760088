import React from "react";
import './style.less'
import {FullDate} from "../../tools/delivery-tools";

export default function ModalNews({newsData}) {
  return (
    <>
      {newsData !== null &&
        <div className="news-modal" id={`${newsData.category}-${newsData.id}`}
             style={{display: "none", maxWidth: "600px"}}>
          <div className="news-modal__dialog">
            <div className="news-card__date text14">
              {<FullDate date={newsData.dateCreated.date}/>}
            </div>
            <h3 className="news-card__title display4">
              {newsData.header}
            </h3>
            <p className="news-card__desc text20">
              {newsData.text}
            </p>
          </div>
        </div>
      }
    </>
  );
}
