import React from 'react';
import {Row,Col,Upload,Icon,Button} from 'antd';
import DividerBlock from '../../../../divider-block';
import FieldBlock from '../../../../field-block';
import '../buttons.css';
import {mainConfig} from "../../../../../config";
import {systemService} from "../../../../../services";
import {formHelper} from "../../../../../helpers";

export default function DocsFields({ stepFields, setFields, getFieldErrors, organizationalForm }) {
    const _apiBase = mainConfig.apiUrl;

    const getUploaderClass = (name, multiple = false) => {
        return {
            multiple: multiple,
            showUploadList: true,
            onRemove: (file) => {
                if (multiple) {
                    stepFields[name].forEach((stepFile, index, object) => {
                        if (stepFile.uid === file.uid) {
                            stepFields[name].splice(index, 1);
                        }
                    });
                } else {
                    setFields({...stepFields, [name]: null});
                }
            },
            beforeUpload: (file, fileList) => {
                if (file.type === 'application/pdf') {
                    file.thumbUrl = 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg';
                } else {
                    file.url = URL.createObjectURL((file instanceof File) ? file : formHelper.b64toBlob(file, 'image/png'));
                }

                if (multiple) {
                    if (stepFields[name]) {
                        setFields({...stepFields, [name]: stepFields[name].concat(fileList)});
                    } else {
                        setFields({...stepFields, [name]: fileList});
                    }
                } else {
                    setFields({...stepFields, [name]: fileList});
                }
                return false;
            },
            listType: 'picture',
        };
    };

    return (
        <Row gutter={20}>
          <DividerBlock label="Загрузите сканы документов"/>
            {organizationalForm === 13 ? (
                <>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="documentConfirmingTheStateRegistrationOfAnIndividualEntrepreneur" getErrors={getFieldErrors}>
                            <Upload defaultFileList={stepFields.documentConfirmingTheStateRegistrationOfAnIndividualEntrepreneur} fileList={stepFields.documentConfirmingTheStateRegistrationOfAnIndividualEntrepreneur} className="single-btn-upload" {...getUploaderClass('documentConfirmingTheStateRegistrationOfAnIndividualEntrepreneur', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Документ подтверждающий государственную регистрацию индивидуального предпринимателя
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                </>
            ) : (
                <>
                        <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                            <FieldBlock name="documentConfirmingTheStateRegistrationOfALegalEntity" getErrors={getFieldErrors}>
                                <Upload defaultFileList={stepFields.documentConfirmingTheStateRegistrationOfALegalEntity} className="single-btn-upload" {...getUploaderClass('documentConfirmingTheStateRegistrationOfALegalEntity', true)}>
                                    <Button htmlType="button">
                                        <Icon type="upload" className="single-btn-upload__icon"/> Документ подтверждающий государственную регистрацию юридического лица
                                    </Button>
                                </Upload>
                            </FieldBlock>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                            <FieldBlock name="proofOfOwnership" getErrors={getFieldErrors}>
                                <Upload defaultFileList={stepFields.proofOfOwnership} className="single-btn-upload" {...getUploaderClass('proofOfOwnership', true)}>
                                    <Button htmlType="button">
                                        <Icon type="upload" className="single-btn-upload__icon"/>  Документы, подтверждающие право собственности или иные права на офисные помещения, в которых контрагент осуществляет деятельность
                                    </Button>
                                </Upload>
                            </FieldBlock>
                        </Col>
                </>
            )}

            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="certificateOfExclusionOfDoubleTaxation" getErrors={getFieldErrors}>
                    <Upload defaultFileList={stepFields.certificateOfExclusionOfDoubleTaxation} className="single-btn-upload" {...getUploaderClass('certificateOfExclusionOfDoubleTaxation', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Справка об исключении/избежании двойного налогообложения (справка статусе налогового резидента соответствующей страны для применения положений соглашений об избежании двойного налогообложения, с заверенным переводом на русский язык)
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="copyOfThePassport" getErrors={getFieldErrors}>
                    <Upload defaultFileList={stepFields.copyOfThePassport} className="single-btn-upload" {...getUploaderClass('copyOfThePassport', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Копия паспорта
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="extractFromTheTradeRegister" getErrors={getFieldErrors}>
                    <Upload defaultFileList={stepFields.extractFromTheTradeRegister} fileList={stepFields.extractFromTheTradeRegister} className="single-btn-upload" {...getUploaderClass('extractFromTheTradeRegister', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Выписка из торгового реестра
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="permissionForTheRightToCarryOutActivitiesInTheFieldOfInternationalRoadTransport" getErrors={getFieldErrors}>
                    <Upload defaultFileList={stepFields.permissionForTheRightToCarryOutActivitiesInTheFieldOfInternationalRoadTransport} fileList={stepFields.permissionForTheRightToCarryOutActivitiesInTheFieldOfInternationalRoadTransport} className="single-btn-upload" {...getUploaderClass('permissionForTheRightToCarryOutActivitiesInTheFieldOfInternationalRoadTransport', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Специальное разрешение (лицензия) на право осуществления деятельности в области международных автомобильных перевозок
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
        </Row>
    );
}
