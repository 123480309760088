import {Button, Dropdown, Icon, Menu, notification, Popconfirm, Upload} from "antd";
import React, {useState} from "react";
import ModalListOrdersDelivery from "../../../modals/list-orders-delivery";
import {deliveriesService, orderDeliveryService} from "../../../../services";
import {
  DELIVERY_BOOKED,
  DELIVERY_ENROUTE,
  DELIVERY_LOADING,
  DELIVERY_ORDER_FILLED
} from "../../../../helpers/delivery_status.helper";
import ModalAttachDriverOrderDelivery from "../../../modals/attach-driver-order-delivery";
import moment from "moment/moment";
import {FormatPrice} from "../../../tools/delivery-tools";
import ModalRefuseDelivery from "../../../modals/refuse-delivery";
import ModalDriverInfo from "../../../modals/driver-info";
import {EN_ROUTE_DELIVERY} from "../../../../helpers/user_permission.helper";

const ItemBlock = (props) => {
  const {data, type, setNeedUpdateDeliveryList, user} = props;
  const {
    id,
    number,
    status,
    placeLoad,
    placeUnload,
    prices,
    countNewOrders,
    order,
    isForeignDelivery,
    contractType = {}
  } = data;
  const [showModalOrdersDelivery, setShowModalOrdersDelivery] = useState(false);
  const [showRefuseDeliveryModal, setShowRefuseDeliveryModal] = useState(false);
  const [showModalDriverInfo, setShowDriverModalInfo] = useState(false);
  const [showModalAttachDriver, setShowModalAttachDriver] = useState(false);

  const nameRegionLastPointUnload = placeUnload.city ? placeUnload.city : "-";

  const regionLoad = placeLoad.city ? placeLoad.city : "-";

  const isStaff = user.info.isStaff;

  // для блока onLoading

  const isCanRefuse = user.isAccessTo("agree_without_docs") || false;

  const onClickAddToArchive = (e) => {
    deliveriesService
      .archive(id)
      .then((response) => {
        notification.success({
          message: "Добавление в архив",
          description: response.message,
        });

        setNeedUpdateDeliveryList(true);
      })
      .catch((error) => {
        notification.error({
          message: "Добавление в архив",
          description: error,
        });

        setNeedUpdateDeliveryList(true);
      });
  };

  const onClickChangeToLoading = (e) => {
    deliveriesService
      .changeToLoading(id)
      .then((response) => {
        notification.success({
          message: 'Изменение статуса на "На погрузке"',
          description: response.message,
        });

        setNeedUpdateDeliveryList(true);
      })
      .catch((error) => {
        notification.error({
          message: 'Изменение статуса на "На погрузке"',
          description: error,
        });

        setNeedUpdateDeliveryList(true);
      });
  };

  const onDetachDriver = (e) => {
    orderDeliveryService
      .detachDriver(order.id)
      .then((response) => {
        notification.success({
          message: "Открепление водителя",
          description: response.message,
        });
        setNeedUpdateDeliveryList(true);
      })
      .catch((error) => {
        notification.error({
          message: "Открепление водителя",
          description: error,
        });
        setNeedUpdateDeliveryList(true);
      });
  }

  const onClickEnRouteDelivery = (e) => {
    deliveriesService
      .enRoute(id)
      .then((response) => {
        notification.success({
          message: "Отправка в путь",
          description: response.message,
        });

        setNeedUpdateDeliveryList(true);
      })
      .catch((error) => {
        notification.error({
          message: "Отправка в путь",
          description: error,
        });

        setNeedUpdateDeliveryList(true);
      });
  };

  const onCLickShowUserDoc = (filePath) => {
    window.open(filePath);
  };

  // для блока awaitingDocs
  const onClickAgreeWithoutDocs = (e) => {
    orderDeliveryService
      .agreeWithoutDocs(order.id)
      .then((response) => {
        notification.success({
          message: "Утверждение заявки без документов",
          description: response.message,
        });

        setNeedUpdateDeliveryList(true);
      })
      .catch((error) => {
        notification.error({
          message: "Утверждение заявки без документов",
          description: error,
        });

        setNeedUpdateDeliveryList(true);
      });
  };

  const menu = (
    <Menu>
      {type === "onLoading" && user.isAccessTo("refuse_delivery") && order.status.id === 12 &&
        <Menu.Item key="1"
                   onClick={() => setShowRefuseDeliveryModal(true)}
        >Отменить рейс</Menu.Item>}
      {type === "onLoading" && status.alias !== DELIVERY_LOADING && user.isAccessTo(EN_ROUTE_DELIVERY) &&
        <Menu.Item key="2">
          <Popconfirm
            title='Вы уверены?'
            okText='Да'
            cancelText='Нет'
            onConfirm={onClickChangeToLoading}
          >Погрузка
          </Popconfirm>
        </Menu.Item>}
      {type === "onLoading" && status.alias === DELIVERY_ENROUTE &&
        <Menu.Item key="3">
          <Popconfirm
            title='Вы уверены?'
            okText='Да'
            cancelText='Нет'
            onConfirm={onClickAddToArchive}
          >
            В архив
          </Popconfirm>
        </Menu.Item>}
      {type === "onLoading" && status.alias === DELIVERY_LOADING && user.isAccessTo(EN_ROUTE_DELIVERY) &&
        <Menu.Item key="4">
          <Popconfirm
            title='Вы уверены?'
            okText='Да'
            cancelText='Нет'
            onConfirm={onClickEnRouteDelivery}
          >
            Отправить в путь
          </Popconfirm>
        </Menu.Item>}
      {type === "onLoading" &&
        <Menu.Item key="5"
        >
          <Popconfirm
            title='Вы уверены?'
            okText='Да'
            cancelText='Нет'
            onConfirm={onDetachDriver}
          >Открепить водителя</Popconfirm>
        </Menu.Item>}
      {(type === "onLoading" || type === "awaitingDocs") && order && order.docs.agreement &&
        <Menu.Item key="6"
                   onClick={() => onCLickShowUserDoc(order.docs.agreement.file ? order.docs.agreement.file : order.docs.agreement.template)}
        >Посмотреть приложение</Menu.Item>}
      {(type === "onLoading" || type === "awaitingDocs") && order && order.docs.procuration &&
        <Menu.Item key="7"
                   onClick={() => onCLickShowUserDoc(order.docs.procuration.file ? order.docs.procuration.file : order.docs.procuration.template)}
        >Посмотреть доверенность</Menu.Item>}
      {type === "awaitingDocs" && order.status.alias === DELIVERY_ORDER_FILLED && user.isAccessTo("agree_without_docs") &&
        <Menu.Item key="8">
          <Popconfirm
            title='Вы уверены?'
            okText='Да'
            cancelText='Нет'
            onConfirm={onClickAgreeWithoutDocs}
          >Утвердить без документов</Popconfirm>
        </Menu.Item>}
      {type === "awaitingDocs" && order.driver &&
        <Menu.Item key={"9"}
                   onClick={() => setShowDriverModalInfo(true)}
        >
          Информация о водителе
        </Menu.Item>
      }
    </Menu>
  );

  const onClickShowOrders = (e) => {
    e.preventDefault();
    setShowModalOrdersDelivery(true);
  };

  const setStopListUpdate = () => {
  };

  const onClickRefuseOrder = (e) => {
    e.preventDefault();

    orderDeliveryService
      .refuseOrder(order.id)
      .then((response) => {
        notification.success({
          message: "Отмена заказа",
          description: response.message,
        });
      })
      .catch((error) => {
        notification.error({
          message: "Отмена заказа",
          description: error,
        });
      });
  };

  return <div className="item-block">
    <div className="item-block__title">{number}</div>
    <div className="item-block__points">
      <div>
        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 1.715L1.21125 0.5L7.2 6.5L1.21125 12.5L0 11.285L4.77375 6.5L0 1.715Z"/>
        </svg>
        <span>{placeLoad.city}</span>
      </div>
      <div>
        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.19995 1.715L5.9887 0.5L-4.86374e-05 6.5L5.9887 12.5L7.19995 11.285L2.4262 6.5L7.19995 1.715Z"/>
        </svg>
        <span>{placeUnload.city}</span>
      </div>
    </div>
    {prices && prices.length && (type === "free" || isStaff) &&
      <div className={`item-block__info`}>
        <div className="item-block__info-title">Цена рейса:</div>
        {prices.map((item, index) => <div
          key={`price-${id}-${index}`}
          className="item-block__info-text">
          {item.price.toLocaleString("ru")}{item.paymentType.currencySymbol} {item.paymentType.title}
        </div>)}
      </div>
    }
    {order && order.price && (type === "free" || !isStaff) &&
      <div className={`item-block__info`}>
        <div className="item-block__info-title">Моя ставка:</div>
        <div
          className="item-block__info-text">{order.price} {order.paymentType.currencySymbol} {order.paymentType.title}</div>
      </div>
    }
    {order && order.dateLoading && (type === "onFilling" || type === "awaitingDocs" || type === "onLoading") && isStaff &&
      <div className={`item-block__info`}>
        <div className="item-block__info-title">Дата загрузки:</div>
        <div className="item-block__info-text">{order.dateLoading.split(" ")[0]}</div>
      </div>
    }
    {order && order.dateLoading && type === "onFilling" && !isStaff &&
      <div className={`item-block__info`}>
        <div className="item-block__info-title">Дата загрузки:</div>
        <div className="item-block__info-text">{order.dateLoading.split(" ")[0]}</div>
      </div>
    }
    {status && status.alias !== DELIVERY_BOOKED && !isStaff && type === "onFilling" &&
      <div className={`item-block__info item-block__info--no-bg`}>
        {status.title}
      </div>
    }
    {order && order.driver && type === "onLoading" && isStaff &&
      <div className={`item-block__info item-block__info--no-bg`}>
        <div className="item-block__info-title">Водитель:</div>
        <div className="item-block__info-text">{order.driver.fio}</div>
        <div className="item-block__info-text">Тел. <a
          href={`tel:+7${order.driver.phone}`}>{order.driver.phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, `+7 ($1) $2 $3 $4`)}</a>
        </div>
      </div>
    }
    {type === "onLoading" && !isStaff &&
      <>
        <div className={`item-block__info`}>
          <div className="item-block__info-title">Дата погрузки:</div>
          <div className="item-block__info-text">{order.dateLoading.split(" ")[0]}</div>
        </div>
        <div className={`item-block__info item-block__info--no-bg`}>
          <div className="item-block__info-title">Адрес:</div>
          <div className="item-block__info-text">{placeLoad.address}</div>
        </div>
      </>
    }
    {type === "onLoading" && isStaff &&
      <div className="item-block__buttons">
        <Dropdown overlay={menu}
                  className="btn btn--bordered btn--bordered-green">
          <Button>
            Выбрать действие <Icon type="down"/>
          </Button>
        </Dropdown>
        <ModalRefuseDelivery
          isShow={showRefuseDeliveryModal}
          setIsShow={setShowRefuseDeliveryModal}
          deliveryId={id}
          setNeedUpdate={setNeedUpdateDeliveryList}
          setLoadingDelivItem={() => {
          }}
          setStopListUpdate={() => {
          }}
        />
      </div>
    }
    {/*{type === "enRoute" && !isStaff &&*/}
    {/*  <div className={`item-block__info`}>*/}
    {/*    <div className="item-block__info-title">Дата окончания:</div>*/}
    {/*    <div className="item-block__info-text">{order.dateLoading.split(" ")[0]}</div>*/}
    {/*  </div>*/}
    {/*}*/}
    {status.alias === DELIVERY_BOOKED && !isStaff && type === "onFilling" && order.status.id === 7 &&
      <>
        <Button
          className={`btn typical-btn typical-btn-green`}
          onClick={() => setShowModalAttachDriver(true)}
          style={{width: "100%", height: "auto"}}
        >
          <span className="speedometer">Заполнить заявку</span>
        </Button>
        <ModalAttachDriverOrderDelivery
          key={`modal_${id}`}
          isShow={showModalAttachDriver}
          setIsShow={setShowModalAttachDriver}
          companyId={order.company.id}
          orderId={order.id}
          deliveryDateShipmentRus={order.dateLoading.split(" ")[0]}
          deliveryPrice={order.price}
          deliveryRegionLoad={regionLoad}
          deliveryRegionUnload={nameRegionLastPointUnload}
          setNeedUpdateDeliveryList={setNeedUpdateDeliveryList}
          setStopListUpdate={setStopListUpdate}
          isForeignDelivery={isForeignDelivery}
          deliveryContractType={contractType}
        />
      </>
    }
    {order && order.legalEntity && isStaff && type === "onFilling" &&
      <div className={`item-block__info item-block__info--no-bg`}>
        <div className="item-block__info-title">Исполнитель</div>
        {order.legalEntity.name}
      </div>
    }
    {countNewOrders && type === "free" && isStaff &&
      <>
        <Button className={`btn typical-btn typical-btn-green`}
                onClick={onClickShowOrders}
                style={{width: "100%", height: "auto"}}>Заявки
          ({countNewOrders})</Button>
        <ModalListOrdersDelivery
          isShow={showModalOrdersDelivery}
          setIsShow={setShowModalOrdersDelivery}
          deliveryId={id}
        />
      </>
    }
    {type === "free" && !isStaff &&
      <>
        <Button className="btn typical-btn typical-btn-red"
                onClick={onClickRefuseOrder}
                style={{width: "100%", height: "auto"}}
        >
          Отменить заявку
        </Button>
      </>
    }
    {type === "awaitingDocs" &&
      <div className="item-block__buttons">
        <Dropdown overlay={menu}
                  className="btn btn--bordered btn--bordered-green">
          <Button>
            Выбрать действие <Icon type="down"/>
          </Button>
        </Dropdown>
        <ModalDriverInfo isShow={showModalDriverInfo}
                         setIsShow={setShowDriverModalInfo}
                         driverId={order.driver.id}
                         truckId={order.driver.truck ? order.driver.truck.id : null}
                         trailId={order.driver.trail ? order.driver.trail.id : null}
        />
      </div>
    }
  </div>
}

export default ItemBlock;
