import React, {useState} from 'react';
import {Col, Row, DatePicker, Checkbox} from 'antd';
import localeDatePicker from 'antd/lib/date-picker/locale/ru_RU';
import InputField from '../../../../input-field';
import NumberInputField from '../../../../number-input-field';
import SelectField from '../../../../select-field';
import FieldBlock from '../../../../field-block';
import * as moment from 'moment';
import DividerBlock from "../../../../divider-block";

export default function GuarantorFields({ stepFields, setFields, getFieldErrors, edit = false }) {
	let dividerStyle = {
		margin: "15px 0px 45px 0px",
		display: "block",
		clear: "both",
		width: "100%",
		minWidth: "100%",
		height: "1px",
	};
    return (
        <>
           <Row gutter={[16,24]}>
            <Col lg={8} sm={24}>
              <InputField
                name="guarantorFirstName"
                title='Имя'
                label="Vardas"
                required={true}
                value={stepFields.guarantorFirstName}
                setValue={(value) => {setFields({...stepFields, guarantorFirstName: value});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
              <Col lg={8} sm={24}>
                <InputField
                  name="guarantorSecondName"
                  title='Фамилия'
                  label="Pavardė"
                  required={true}
                  value={stepFields.guarantorSecondName}
                  setValue={(value) => {setFields({...stepFields, guarantorSecondName: value});}}
                  getFieldErrors={getFieldErrors}
                />
            </Col>
              <Col lg={8} sm={24}>
                <InputField
                  name="guarantorPatronymic"
                  title='Отчество'
                  label="Antras vardas"
                  required={true}
                  value={stepFields.guarantorPatronymic}
                  setValue={(value) => {setFields({...stepFields, guarantorPatronymic: value});}}
                  getFieldErrors={getFieldErrors}
                />
              </Col>
          </Row>
			<span className="divider-label">Paso duomenys</span>
			<DividerBlock style={dividerStyle} />
           <Row gutter={[16,24]}>
            <Col lg={12} sm={24}>
              <InputField
                name="guarantorPassportSerial"
                title='Серия паспорта'
                label="Serija"
                required={true}
                value={stepFields.guarantorPassportSerial}
                setValue={(value) => {setFields({...stepFields, guarantorPassportSerial: value});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={12} sm={24}>
              <InputField
                name="guarantorPassportNumber"
                title='Номер паспорта'
                label="Paso ID"
                required={true}
                value={stepFields.guarantorPassportNumber}
                setValue={(value) => {setFields({...stepFields, guarantorPassportNumber: value});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
			<span className="divider-label">Kontaktiniai duomenys</span>
			<DividerBlock style={dividerStyle} />
           <Row gutter={[16,24]}>
            <Col lg={12} sm={24}>
              <InputField
                name="guarantorCountry"
                title='Страна'
                label="Registracijos šalis"
                required={true}
                value={stepFields.guarantorCountry}
                setValue={(value) => {setFields({...stepFields, guarantorCountry: value});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={12} sm={24}>
              <InputField
                name="guarantorAddressFact"
                title='Адрес'
                label="Adresas"
                required={true}
                value={stepFields.guarantorAddressFact}
                setValue={(value) => {setFields({...stepFields, guarantorAddressFact: value});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
           <Row gutter={[16,24]}>
            <Col lg={12} sm={24}>
              <InputField
                name="guarantorPhone"
                title='Моб. номер'
                label="Telefono numeris"
                required={true}
                value={stepFields.guarantorPhone}
                setValue={(value) => {setFields({...stepFields, guarantorPhone: value});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={12} sm={24}>
              <InputField
                name="guarantorEmail"
                label="Email"
                required={true}
                value={stepFields.guarantorEmail}
                setValue={(value) => {setFields({...stepFields, guarantorEmail: value});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
        </>
    );
}
