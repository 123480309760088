import axios from "axios";
import { mainConfig } from "../config";
import { userServices } from "./user.services";

const _apiBase = mainConfig.apiUrl;

export const httpRequest = {
  get,
  post,
  put,
  patch,
  getDefaultMessageError,
};

function handleErrorResponse(error) {
  let errorMsg = getDefaultMessageError();

  if (typeof error.response !== "undefined" && error.response.status === 401) {
    // выход пользователя и перезагрузка страницы
    userServices.logout().then(() => {
      window.location.reload(true);
    });
  } else if (typeof error.response !== "undefined" && typeof error.response.data.message !== "undefined") {
    errorMsg = error.response.data.message;
  }

  return Promise.reject(errorMsg);
}

async function get(urlRelativePath, config = {}) {
  const user = userServices.getUser();
  const userAccessToken = user.getAuthToken();

  const newConfigHeader = typeof config.headers !== "undefined" ? config.headers : {};

  return axios
    .get(`${_apiBase}/${urlRelativePath}`, {
      ...config,
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ...newConfigHeader,
      },
    })
    .catch(handleErrorResponse);
}

async function post(urlRelativePath, data, config = {}) {
  const user = userServices.getUser();
  const userAccessToken = user.getAuthToken();

  const newConfigHeader = typeof config.headers !== "undefined" ? config.headers : {};

  return await axios
    .post(`${_apiBase}/${urlRelativePath}`, data, {
      ...config,
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ...newConfigHeader,
      },
    })
    .catch(handleErrorResponse);
}

async function put(urlRelativePath, data, config = {}) {
  const newConfigHeader = typeof config.headers !== "undefined" ? config.headers : {};

  const user = userServices.getUser();
  const userAccessToken = user.getAuthToken();

  return await axios
    .put(`${_apiBase}/${urlRelativePath}`, data, {
      ...config,
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ...newConfigHeader,
      },
    })
    .catch(handleErrorResponse);
}

async function patch(urlRelativePath, data, config = {}) {
  const newConfigHeader = typeof config.headers !== "undefined" ? config.headers : {};

  const user = userServices.getUser();
  const userAccessToken = user.getAuthToken();

  return await axios
    .patch(`${_apiBase}/${urlRelativePath}`, data, {
      ...config,
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ...newConfigHeader,
      },
    })
    .catch(handleErrorResponse);
}

function getDefaultMessageError() {
  return "При выполнении запроса произошла ошибка. Повторите пожалуйста позже";
}
