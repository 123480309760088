import {httpRequest} from "./http-request";

export const questService = {
  getStats,
  getCoordinates,
  getNews,
  getNewsList
};

async function getStats() {
  return await httpRequest.get(`guest/deliveries-carriers-stats`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getCoordinates() {
  return await httpRequest.get(`guest/all-deliveries-coordinates`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getNews(id) {
  return await httpRequest.get(`news/get?id=${id}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getNewsList() {
  return await httpRequest.get(`news/list?offset=0&limit=3`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}
