import React from "react";
import {Form, Icon, Input, Button, Row, Col, notification} from "antd";

//import "antd/lib/button/style/index.less";
import {Redirect} from "react-router-dom";
import {AppContext} from "../../../contexts/app-context";
import Spinner from "../../spinner";
import {Link} from "react-router-dom";
// import GoogleAndroidAppLink from "../../google-android-app-link";
import {userServices} from "../../../services";
import Banner from "../../banner";

class LoginPage extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      username: this.resetData()
    };

    this.buttonStyle = {
      width: "100%",
      fontSize: "20px",
      height: "40px",
      backgroundColor: "#1CAA53",
      borderRadius: "7px"
    };
  }

  onSubmitFormLogin = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });

        this.context.login(values.login, values.password).catch((errorMsg) => {
          notification.warning({
            message: "Авторизация",
            description: errorMsg,
          });
          this.setState({
            loading: false,
          });
        });
      }
    });
  };

  resetData = () => {
    let username = "";
    if (this.props.location.state) {
      if (this.props.location.state.reset) {
        username = this.props.location.state.reset.username;
        notification.success({
          message: "Восстановление пароля",
          description: "Ваш пароль был успешно восстановлен и отправлен вам по СМС на номер: " + username,
          duration: 20,
        });
      }
    }
    return username;
  };

  render() {
    this.user = userServices.getUser();

    if (this.user.loggedIn) {
      return <Redirect to="/"/>;
    }

    if (this.context.filter.enable) {
      this.context.disableFilter();
    }

    const {getFieldDecorator} = this.props.form;

    return (
      <Spinner loading={this.state.loading} label="Подождите">
        <div className="container">
          <Row>
            <Col span={12} offset={6} className="login-page-content">
              <h1>Авторизация</h1>
              <Form onSubmit={this.onSubmitFormLogin} className="login-form">
                <Form.Item>
                  {getFieldDecorator("login", {
                    rules: [{required: true, message: "Заполните логин"}],
                    initialValue: this.state.username,
                  })(<Input size="large" prefix={<Icon type="user" style={{color: "rgba(0,0,0,.25)"}}/>}
                            placeholder="Логин"/>)}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("password", {
                    rules: [{required: true, message: "Заполните пароль"}],
                  })(<Input.Password size="large" prefix={<Icon type="lock" style={{color: "rgba(0,0,0,.25)"}}/>}
                                     type="password" placeholder="Пароль"/>)}
                </Form.Item>
                <Form.Item>
                  <Button type="primary" style={this.buttonStyle} htmlType="submit" className="login-form-button">
                    Войти
                  </Button>
                </Form.Item>
              </Form>
              <Row type="flex" justify="space-between" style={{maxWidth: "700px", margin: "0 auto"}}>
                <Link to="/reset" className="link-login">
                  Восстановить пароль
                </Link>
                <Link to="/register" className="link-register">
                  Регистрация
                </Link>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              {/*<GoogleAndroidAppLink />*/}
              <Banner banner={1}/>
            </Col>
            <Col xs={24} lg={12}>
              <Banner banner={2}/>
            </Col>
          </Row>
        </div>
      </Spinner>
    );
  }
}

export default Form.create({name: "normal_login"})(LoginPage);
