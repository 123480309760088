import React, {useEffect, useState} from 'react';
import {consignorService, deliveriesService, distributionCenterService} from '../../../services';
import {Modal, notification, Button, Spin, Col, Row} from 'antd';
import SelectField from "../../select-field";

export default function ModalChangeDeliveryShipperCompany({isShow, setIsShow, setIsNeedUpdate, setStopListUpdate, currentShipperCompany, currentDistCenter, deliveryID}) {

    const [loading, setLoading] = useState(true);

    const [newShipperCompany, setNewShipperCompany] = useState(null);
    const [newDistCenter, setNewDistCenter] = useState(null);

    const [shipperCompaniesList, setShipperCompaniesList] = useState([]);
    const [distCentersList, setDistCentersList] = useState([]);

    const [fieldsErrors, setFieldsErrors] = useState([]);
    const getFieldErrors = (nameField) => {
        return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
            ? fieldsErrors[nameField][0]
            : null;
    };

    useEffect(() => {
        setNewShipperCompany(currentShipperCompany);
        setNewDistCenter(currentDistCenter);
    }, [currentShipperCompany, currentDistCenter]);

    useEffect(() => {
        if (isShow) {
            setLoading(true);

            consignorService
                .getShipperCompaniesList()
                .then(resp => {
                    setShipperCompaniesList(resp);
                })
                .catch(err => {
                    notification.error({
                        message: 'Загрузка юр.лиц отпарвителя',
                        description: err
                    });
                });

            distributionCenterService
                .getCompanies()
                .then(resp => {
                    setDistCentersList(resp);
                })
                .catch(err => {
                    notification.error({
                        message: 'Загрузка РЦ отпарвителя',
                        description: err
                    });
                });
        }
    }, [isShow]);

    useEffect(() => {
        if (shipperCompaniesList && distCentersList) {
            setLoading(false);
        }
    }, [shipperCompaniesList, distCentersList]);

    const handleSubmit = () => {
        if (deliveryID) {
            setLoading(true);
            deliveriesService.changeShipperCompany(deliveryID, newShipperCompany, newDistCenter)
                .then(resp => {
                    notification.success({
                        message: 'Изменение данных в рейсе',
                        description: resp.message
                    });
                    setIsShow(false);
                    setIsNeedUpdate(true);
                    setStopListUpdate(false);
                })
                .catch(err => {
                    notification.error({
                        message: 'Изменение данных в рейсе',
                        description: err
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <Modal
            title={'Отмена заявки'}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
            }}
            footer={[
                <Button onClick={handleSubmit} className="small-button typical-btn-green">Сохранить</Button>
            ]}
        >
            <Spin spinning={loading} tip="Загрузка...">
                <Row gutter={[16, 24]}>
                    <Col lg={12} md={24} sm={24} sx={24}>
                        <SelectField
                            title="Компания"
                            name="shipperCompanyID"
                            selectedValue={newShipperCompany}
                            values={shipperCompaniesList}
                            onChange={value => {
                                setNewShipperCompany(value);
                            }}
                            getErrors={getFieldErrors}
                            style={{ width: "100%" }}
                        />
                    </Col>

                    <Col lg={12} md={24} sm={24} sx={24}>
                        <SelectField
                            title="Подразделение"
                            name="distributionCenterID"
                            selectedValue={newDistCenter}
                            values={distCentersList}
                            onChange={value => {
                                setNewDistCenter(value);
                            }}
                            getErrors={getFieldErrors}
                            style={{ width: "100%" }}
                        />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );

}