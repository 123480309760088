import React, {useEffect, useState} from "react";
import FirstStep from "./steps/first";
import SecondStep from "./steps/second";
import ThirdStep from "./steps/third";
import FourthStep from "./steps/fourth";
import {notification} from "antd";
import moment from "moment";
import {companyService} from "../../../../services";

export default function EditRusLegalEntity({
    isActive = false,

    currentStep,
    setCurrentStep,

    legalEntityId,
    legalEntityData,

    setLoading,

    setStepsList,

    changeStepEvent,
    setChangeStepEvent,

    editLegalEntityEvent,
    setEditLegalEntityEvent,

    setIsShowParent
}) {
  const [fieldsErrors, setFieldsErrors] = useState({});

  const [firstStepFields, setFirstStepFields] = useState({
    ndsList: [
      {
        Id: 1,
        Name: 'С НДС'
      },
      {
        Id: 2,
        Name: 'Без НДС'
      }
    ],

    name: legalEntityData.Name,
    orgFormId: legalEntityData.OrgFormId,
    country: legalEntityData.CountryId,
    dateRegistration: moment(legalEntityData.DateRegistration, 'DD.MM.YYYY'),
    inn: legalEntityData.Inn,
    ogrn: legalEntityData.Ogrn,
    ogrnip: legalEntityData.Ogrnip,
    okpo: legalEntityData.Okpo,
    atiCode: legalEntityData.AtiCode,
    kpp: legalEntityData.Kpp,
    workWithNds: legalEntityData.WorkWithNds == 1 ? 1 : 2,
    purposeType: legalEntityData.PurposeType,
  });

  const [secondStepFields, setSecondStepFields] = useState({
    legalReasonsList: [
      {
        Id: 'Устав',
        Name: 'Устав'
      },
      {
        Id: 'Приказ',
        Name: 'Приказ'
      },
      {
        Id: 'Доверенность (ООО)',
        Name: 'Доверенность (ООО)'
      }
    ],
    legalReason: legalEntityData.LegalReason,
    firstName: legalEntityData.FirstName,
    secondName: legalEntityData.SecondName,
    patronymic: legalEntityData.Patronymic,

    banksList: [],
    bic: legalEntityData.Bic,
    bankName: legalEntityData.BankName,
    bankCorS: legalEntityData.BankCorS,
    rs: legalEntityData.Rs,
    position: legalEntityData.PositionStr
  });

  const [thirdStepFields, setThirdStepFields] = useState({
    regAddress: legalEntityData.RegAddress,
    regAddressPostalCode: legalEntityData.RegAddressPostalCode,

    mailingAddress: legalEntityData.MailingAddress,
    mailingAddressPostalCode: legalEntityData.MailingAddressPostalCode,

    legalAddress: legalEntityData.LegalAddress,
    legalAddressCity: legalEntityData.LegalAddressCity,
    legalAddressPostalCode: legalEntityData.LegalAddressPostalCode,
  });

  const [fourthStepFields, setFourthStepFields] = useState({
    guarantorFirstName: legalEntityData.GuarantorFirstName,
    guarantorSecondName: legalEntityData.GuarantorSecondName,
    guarantorPatronymic: legalEntityData.GuarantorPatronymic,
    guarantorPassportSerial: legalEntityData.GuarantorPassportSerial,
    guarantorPassportNumber: legalEntityData.GuarantorPassportNumber,
    guarantorPassportDivisionsList: [],
    guarantorPassportDivision: legalEntityData.GuarantorPassportDivision,
    guarantorPassportRegistration: legalEntityData.GuarantorPassportRegistration,
    guarantorPassportDate: moment(legalEntityData.GuarantorPassportDate, 'DD.MM.YYYY'),

    guarantorFactAddress: legalEntityData.GuarantorAddressFact,

    guarantorRegAddress: legalEntityData.GuarantorAddressRegistration,
  });

  const getFieldErrors = (fieldName) => {
    return typeof fieldsErrors[fieldName] !== "undefined"
        ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
          return fieldErrors + "; " + error;
        })
        : null;
  };

  useEffect(() => {
    if (isActive) {
      if (firstStepFields.orgFormId === 1 || legalEntityData.PurposeType === 'consignor_purpose_type') {
        setStepsList([
          'Основные реквизиты',
          'Основная информация',
          'Адреса'
        ]);
      } else {
        setStepsList([
          'Основные реквизиты',
          'Основная информация',
          'Адреса',
          'Поручитель',
        ]);
      }
    }
  }, [isActive]);

  useEffect(() => {
    if (changeStepEvent) {
      if (changeStepEvent === 'next') {
        nextStep();
      } else if (changeStepEvent === 'previous') {
        previousStep();
      }

      setChangeStepEvent('');
    }
  }, [changeStepEvent]);

  useEffect(() => {
    if (editLegalEntityEvent) {
      setLoading(true);

      let data = Object.assign(firstStepFields, secondStepFields, thirdStepFields, fourthStepFields);
      companyService.editLegalEntityData(data, legalEntityId)
          .then(response => {
            if (response.code === 400) {
              notification.error({
                message: "Редактирование юридического лица",
                description: response.message,
              });
              setFieldsErrors(response.errors);
            } else {
              notification.success({
                message: "Редактирование юридического лица",
                description: response.message,
              });

              setIsShowParent(false);
            }

            setLoading(false);
            setEditLegalEntityEvent(false);
          })
          .catch(errMsg => {
            notification.error({
              message: "Создание юридического лица",
              description: errMsg,
            });

            setLoading(false);
            setEditLegalEntityEvent(false)
          });
    }
  }, [editLegalEntityEvent]);

  const nextStep = () => {
    let stepData = (currentStep) => {
      let stepFields = {
        1: firstStepFields,
        2: secondStepFields,
        3: thirdStepFields,
        4: fourthStepFields
      };

      // Баг. Без этого виесто stepFields undefiend
      console.log(stepFields, currentStep, firstStepFields.orgFormId);

      return stepFields[currentStep];
    };

    setLoading(true);

    companyService
        .editLegalEntityValidateStepData(stepData(currentStep), currentStep, firstStepFields.orgFormId, legalEntityId)
        .then(response => {
          setLoading(false);

          if (response.status === 'success') {
            if (currentStep < 5) {
              if ((currentStep + 1) === 4 && firstStepFields.orgFormId === 1) {
                setCurrentStep(currentStep + 2);
              } else {
                setCurrentStep(currentStep + 1);
              }
              setFieldsErrors([]);
            }
          } else {
            setFieldsErrors(response.errors);
          }
        })
        .catch(error => {
          setLoading(false);
          notification.error({
            message: "Создание юр.лица",
            description: error,
          });
        });
  };

  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      setCurrentStep(0);
    }
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
            <FirstStep
                setFields={setFirstStepFields}
                stepFields={firstStepFields}
                getFieldErrors={getFieldErrors}
                edit={true}
            />
        );
      case 2:
        return (
            <SecondStep
                setFields={setSecondStepFields}
                stepFields={secondStepFields}
                getFieldErrors={getFieldErrors}
                organizationalForm={firstStepFields.orgFormId}
                country={firstStepFields.country}
            />
        );
      case 3:
        return (
            <ThirdStep
                setFields={setThirdStepFields}
                stepFields={thirdStepFields}
                getFieldErrors={getFieldErrors}
                organizationalForm={firstStepFields.orgFormId}
                country={firstStepFields.country}
            />
        );
      case 4:
        return (
            <FourthStep
                setFields={setFourthStepFields}
                stepFields={fourthStepFields}
                getFieldErrors={getFieldErrors}
                country={firstStepFields.country}
            />
        );
      default:
        return <div />;
    }
  };

  return (
      <div>
        {renderCurrentStep()}
      </div>
  );
}