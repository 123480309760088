import React, {useState} from 'react';
import {Col, Row, DatePicker, Checkbox} from 'antd';
import localeDatePicker from 'antd/lib/date-picker/locale/ru_RU';
import InputField from '../../../../input-field';
import NumberInputField from '../../../../number-input-field';
import SelectField from '../../../../select-field';
import FieldBlock from '../../../../field-block';
import * as moment from 'moment';

export default function FirstStep({ stepFields, setFields, getFieldErrors, organisationFormsList, countryList, isProprietor, edit = false }) {
    return (
        <>
           <Row gutter={[16, 24]}>
            <Col lg={8} sm={24}>
              <InputField
                name="name"
                label="Название"
                required={true}
                value={stepFields.name}
                setValue={(value) => {setFields({...stepFields, name: value});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={8} sm={24}>
              <FieldBlock name="dateRegistration" title="Дата регистрации юр. лица" getErrors={getFieldErrors}>
                <DatePicker
                  style={{ width: "100%" }}
                  value={stepFields.dateRegistration}
                  defaultValue={stepFields.dateRegistration}
                  format="DD.MM.YYYY"
                  size="large"
                  name="dateRegistration"
                  locale={localeDatePicker}
                  onChange={value => setFields({...stepFields, dateRegistration:  value})}
                />
              </FieldBlock>
            </Col>
          </Row>
           <Row gutter={[16, 24]}>
              <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                  <InputField name="inn" label="ИНН" isDisabled={edit} value={stepFields.inn} setValue={value => setFields({...stepFields, inn: value.replace(/[^0-9]/g, ''), archiveByInn: false})} getFieldErrors={getFieldErrors} />
              </Col>
            { isProprietor ? (
                <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                  <InputField name="ogrnip" label="ОГРНИП" isDisabled={edit} value={stepFields.ogrnip} setValue={value => setFields({...stepFields, ogrnip: value.replace(/[^0-9]/g, '')})} getFieldErrors={getFieldErrors} />
                </Col>
            ) : (
                <>
					<Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField name="kpp" label="КПП" value={stepFields.kpp} setValue={value => setFields({...stepFields, kpp: value.replace(/[^0-9]/g, '')})} getFieldErrors={getFieldErrors} />
                    </Col>
					<Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField name="ogrn" label="ОГРН" isDisabled={edit} value={stepFields.ogrn} setValue={value => setFields({...stepFields, ogrn: value.replace(/[^0-9]/g, '')})} getFieldErrors={getFieldErrors} />
                    </Col>
                </>
            )}
            <Col lg={8} md={12} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                <InputField name="okpo" label="ОКПО" value={stepFields.okpo} setValue={value => setFields({...stepFields, okpo: value.replace(/[^0-9]/g, '')})} getFieldErrors={getFieldErrors} />
            </Col>
          </Row>
          {getFieldErrors('archiveByInn') ? 
            <Row gutter={[16, 24]}>
              <Col lg={24} md={24} sm={24} xs={24} style={{ margin: "0 0 20px 0" }}>
                <Checkbox
                name="archiveByInn"
                checked={stepFields.archiveByInn}
                onChange={(e) => {
                  setFields({
                    ...stepFields,
                    archiveByInn: e.target.checked,
                  });
                }}
                style={{fontSize: "10pt", paddingTop: "15px"}}
                getFieldErrors={getFieldErrors}
                >
                Архивировать предыдущее юр. лицо с этим ИНН
                </Checkbox>
              </Col>
            </Row> : null}
        </>
    );
}
