import React, {useEffect, useState} from 'react';
import Spinner from "../../../spinner";
import {Button, Col, DatePicker, Row, Table, notification, Icon} from "antd";
import {reportService} from "../../../../services/report.services";
import moment from "moment";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import SelectField from "../../../select-field";
import {distributionCenterService} from "../../../../services";
import {tableHelper} from "../../../../helpers";

export default function ReportStatsCostKilometer() {

  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [fieldsErrors, setFieldsErrors] = useState([]);
  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
      ? fieldsErrors[nameField][0]
      : null;
  };
  // параметры для построения отчета
  const [distributionCenterList, setDistributionCenterList] = useState([]);
  const [distributionCenterListLoaded, setDistributionCenterListLoaded] = useState(false);
  const [dateStart, setDateStart] = useState(moment().startOf('month'));
  const [dateEnd, setDateEnd] = useState(moment().endOf('month'));
  const [data, setData] = useState([]);
  const [distributionCenterId, setDistributionCenterId] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [avgPrice, setAvgPrice] = useState(0);

  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateReport(true);
    }
  }, []);

  useEffect(() => {
    if (!distributionCenterListLoaded) {
      distributionCenterService.getCompanies()
        .then(res => {
          res.unshift({id: 0, Name: "Все РЦ"});
          setDistributionCenterListLoaded(true);
          setDistributionCenterList(res);

        })
        .catch(errMsg => {
          setDistributionCenterListLoaded(true);
          notification.error(
            {
              message: "Получение списка распределительных центров",
              description: errMsg
            });
        })
      ;
    }
  }, []);


  if (isNeedUpdateReport) {
    // запрос на получение данных отчета
    reportService.getStatsCostKilometer(dateStart.format('YYYY-MM-DD'), dateEnd.format('YYYY-MM-DD'), distributionCenterId)
      .then((response) => {
        setAvgPrice(response.Params.averagePrice);
        setIsNeedUpdateReport(false);
        setLoading(false);
        let tableData = [];

        Object.keys(response.Rows).forEach(key => {
          tableData[key] = {
            name: response.Rows[key].name,
            children: response.Rows[key].children
          }
        });

        let reindexedTableData = [];
        for (let key in tableData) {
          reindexedTableData.push(tableData[key]);
        }

        setData(reindexedTableData);

      }).catch((errorMsg) => {
      setIsNeedUpdateReport(false);
      setLoading(false);
      setErrorMsg(errorMsg);
    });
  }

  // список столбцов таблицы
  const columns = [
    {
      title: 'РЦ',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: 'Дата создания',
      dataIndex: 'date_created',
      key: 'date_created',
      width: '15%',
    },
    {
      title: 'Дата оформления',
      dataIndex: 'date_of_issue',
      key: 'date_of_issue',
      width: '15%',
      /* sorter: (a, b) => {
        return moment(a.date_of_issue).unix() - moment(b.date_of_issue).unix();
      }, */
    },
    {
      title: 'Логист',
      dataIndex: 'logist_fio',
      key: 'logist_fio',
      width: '10%',
    },
    {
      title: 'Расстояние, км',
      dataIndex: 'total_distance',
      key: 'total_distance',
      width: '10%',
      /* sorter: (a, b) => {
        return Number(a.CallsCount) - Number(b.CallsCount);
      }, */
    },
    {
      title: 'Ставка',
      dataIndex: 'price',
      key: 'price',
      width: '10%',
      /* sorter: (a, b) => {
        return Number(a.OrdersCount) - Number(b.OrdersCount);
      }, */
    },
    {
      title: 'Стоимость 1 км',
      dataIndex: 'price_km',
      key: 'price_km',
      width: '10%',
      /* sorter: (a, b) => {
        return Number(a.price_km) - Number(b.price_km);
      }, */
    }
  ];

  return (
    <React.Fragment>
      <div className="reports__header">
        <h1 className="content-header">Отчет «Статистика по стоимости километра»</h1>
        <Icon type='filter'
              theme={isFilterOpen ? '' : "filled"}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen)
              }}
              className="reports__filter-toggle"/>
        {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
          <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start">
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateStart}
                format="DD.MM.YYYY"
                size="normal"
                name="dateStart"
                placeholder="Выберите дату начала периода"
                locale={localeDatePicker}
                onChange={(date) => {
                  setDateStart(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateEnd}
                format="DD.MM.YYYY"
                size="normal"
                name="dateEnd"
                placeholder="Выберите дату окончания периода"
                locale={localeDatePicker}
                onChange={(date) => {
                  setDateEnd(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <SelectField
                placeholder="Подразделение"
                name="distributionCenterId"
                selectedValue={distributionCenterId}
                values={distributionCenterList}
                onChange={value => {
                  setDistributionCenterId(value);
                }}
                getErrors={getFieldErrors}
                style={{width: "100%"}}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{textAlign: 'right', height: '40px'}}>
              <Button type="primary"
                      htmlType="button"
                      onClick={() => {
                        setLoading(true);
                        setIsNeedUpdateReport(true);
                      }}
                      style={{backgroundColor: '#1CAA53', border: 'none', height: '40px', width: '100%'}}
              >
                Применить
              </Button>
            </Col>
          </Row>
        </div>}
        <Row gutter={12} style={{padding: "0", rowGap: "10px", marginTop: '10px', width: '100%'}} type="flex" justify="start">
          <Col lg={12} md={12} sm={24} xs={24} style={{textAlign: 'left', marginBottom: '5px'}}>Cредняя стоимость километра за период: {avgPrice} рублей</Col>
        </Row>
      </div>
      <div className="reports__table">
        <div className="reports__table-wrapper">
          <Col span={24}>
            <Table
              style={{minWidth: '700px'}}
              columns={columns}
              dataSource={data}
              locale={{emptyText: "Данные отсутствуют"}}
              rowKey="key"
              size="small"
              loading={loading}
              pagination={{
                pageSize: 50
              }}
            />
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
}
