import React, {useEffect,useState} from 'react';
import {notification,Row,Col,Button,Upload,Icon} from 'antd';
import {companyService} from '../../../../services';
import FieldBlock from '../../../field-block';
import DividerBlock from "../../../divider-block";

export default function EditLithuaniaLegalEntityDocs({legalEntityData, docFields, setDocFields, fieldsErrors}) {
    const getFieldErrors = (fieldName) => {
        return typeof fieldsErrors[fieldName] !== "undefined"
            ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
                return fieldErrors + "; " + error;
            })
            : null;
    };    

    const getUploaderClass = (name, multiple = true) => {
        return {
            multiple: multiple,
            showUploadList: true,
            onRemove: (file) => {
                setDocFields({...docFields, [name]: null});
            },
            beforeUpload: (file, fileList) => {
                if (multiple) {
                    if (docFields[name]) {
                        setDocFields({...docFields, [name]: docFields[name].concat(fileList)});
                    } else {
                        setDocFields({...docFields, [name]: fileList});
                    }
                } else {
                    setDocFields({...docFields, [name]: file});
                }
                return false;
            },
            fileList: docFields[name],
            listType: 'picture',
        };
    };

    return (
        <Row gutter={20}>
            <DividerBlock label="Загрузите сканы документов"/>
            {legalEntityData.OrgFormId === 13 ? (
                <>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="documentConfirmingTheStateRegistrationOfAnIndividualEntrepreneur" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.documentConfirmingTheStateRegistrationOfAnIndividualEntrepreneur} fileList={docFields.documentConfirmingTheStateRegistrationOfAnIndividualEntrepreneur} className="single-btn-upload" {...getUploaderClass('documentConfirmingTheStateRegistrationOfAnIndividualEntrepreneur', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Документ подтверждающий государственную регистрацию индивидуального предпринимателя
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                </>
            ) : (
                <>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="documentConfirmingTheStateRegistrationOfALegalEntity" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.documentConfirmingTheStateRegistrationOfALegalEntity} className="single-btn-upload" {...getUploaderClass('documentConfirmingTheStateRegistrationOfALegalEntity', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Документ подтверждающий государственную регистрацию юридического лица
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="proofOfOwnership" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.proofOfOwnership} className="single-btn-upload" {...getUploaderClass('proofOfOwnership', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/>  Документы, подтверждающие право собственности или иные права на офисные помещения, в которых контрагент осуществляет деятельность
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                </>
            )}

            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="certificateOfExclusionOfDoubleTaxation" getErrors={getFieldErrors}>
                    <Upload defaultFileList={docFields.certificateOfExclusionOfDoubleTaxation} className="single-btn-upload" {...getUploaderClass('certificateOfExclusionOfDoubleTaxation', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Справка об исключении/ избежании двойного налогообложения. (справка о подтверждении статуса налогового резидента соответствующей страны для применения положений соглашений об избежании двойного налогообложения) (С обязательным переводом на русский язык, заверенным надлежащим образом)
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="copyOfThePassport" getErrors={getFieldErrors}>
                    <Upload defaultFileList={docFields.copyOfThePassport} className="single-btn-upload" {...getUploaderClass('copyOfThePassport', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Копия паспорта
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="extractFromTheTradeRegister" getErrors={getFieldErrors}>
                    <Upload defaultFileList={docFields.extractFromTheTradeRegister} fileList={docFields.extractFromTheTradeRegister} className="single-btn-upload" {...getUploaderClass('extractFromTheTradeRegister', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Выписка из торгового реестра
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                <FieldBlock name="permissionForTheRightToCarryOutActivitiesInTheFieldOfInternationalRoadTransport" getErrors={getFieldErrors}>
                    <Upload defaultFileList={docFields.permissionForTheRightToCarryOutActivitiesInTheFieldOfInternationalRoadTransport} fileList={docFields.permissionForTheRightToCarryOutActivitiesInTheFieldOfInternationalRoadTransport} className="single-btn-upload" {...getUploaderClass('permissionForTheRightToCarryOutActivitiesInTheFieldOfInternationalRoadTransport', true)}>
                        <Button htmlType="button">
                            <Icon type="upload" className="single-btn-upload__icon"/> Специальное разрешение (лицензия) на право осуществления деятельности в области международных автомобильных перевозок
                        </Button>
                    </Upload>
                </FieldBlock>
            </Col>
        </Row>
    );
}
