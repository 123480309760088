import React, { useEffect, useState } from 'react';
import {Button, Row, Col, Modal, Spin, Typography, notification, Select} from 'antd';
import { formHelper } from "../../../helpers";
import InputField from "../../input-field";
import {userServices} from "../../../services";
import SelectField from "../../select-field";
import '../modals.css';

export default function ModalDeactiveUser({ isShow, onCloseModal, phone}) {
    const {Text} = Typography;
    const [userData, setUserData] = useState([]);
    const [fieldsErrors, setFieldsErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [codeFieldVisible, setCodeFieldVisible] = useState(false);
    const [tokensData, setTokensData] = useState(null);
    const [phoneCode, setPhoneCode] = useState(null);
    const [deactiveShow, setDeactiveShow] = useState(null);
    const [loadingContentModal, setLoadingContentModal] = useState(null);

    const [countryList, setCountryList] = useState([
        {
            Id: 1,
            Name: 'Россия'
        },
        {
            Id: 3,
            Name: 'Беларусь'
        }
    ]);

    const getFieldErrors = (nameField) => formHelper.getFieldErrors(nameField, fieldsErrors);

    let bigButtonStyle = {height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent" };
    let approveNumberButton = {height: '43px', background: '#2b3f63', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent", fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '500', lineHeight: '20px' };
    let redButton = {height: '43px', background: 'red', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent", fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '500', lineHeight: '20px' };
    
    const handleRecieveCodes = (e) => {
      userServices
        .getSmsApprovePhone({phone})
        .then((data) => {
          if (data.status === "error") {
            notification.error({
              message: "Получение кода подтверждения",
              description: data.message,
            });
            setCodeFieldVisible(false);
          } else {
            notification.success({
              message: "Код подтверждения отправлен на указанный номер",
            });
            setCodeFieldVisible(true);
            setTokensData(data);
          }
          if (data.errors) {
            setFieldsErrors(data.errors);
          }
        })
        .catch((error) => {
          setCodeFieldVisible(false);
          notification.error({
            message: "Получение кода подтверждения",
            description: error,
          });
        });
    };
    
    const handleGetUserInfoViaCodes = (e) => {
      setLoadingContentModal(true);
      userServices
        .getUserInfoByPhone({
          phoneToken: tokensData.token,
          phoneCode,
          phone,
        })
        .then((data) => {
          if (data.status === "error") {
            notification.error({
              message: "Информация о пользователе",
              description: data.message,
            });
            setDeactiveShow(false);
            setUserData([]);
            setLoadingContentModal(true);
          } else {
            notification.success({
              message: "Информация о пользователе",
              description: data.message,
            });
            setDeactiveShow(true);
            setUserData(data);
            setLoadingContentModal(false);
          }
          if (data.errors) {
            setFieldsErrors(data.errors);
          }
        })
        .catch((error) => {
          notification.error({
            message: "Информация о пользователе",
            description: error,
          });
        });
    };

    const handleDeactiveUserViaCodes = (e) => {
      userServices
        .deactiveUserByPhone({
          phoneToken: tokensData.token,
          phoneCode,
          phone,
        })
        .then((data) => {
          if (data.status === "error") {
            notification.error({
              message: "Деактивация пользователя",
              description: data.message,
            });
          } else {
            notification.success({
              message: "Деактивация пользователя",
              description: data.message,
            });
            setDeactiveShow(false);
            setUserData([]);
            setTokensData([]);
            onCloseModal();
          }
          if (data.errors) {
            setFieldsErrors(data.errors);
          }
        })
        .catch((error) => {
          notification.error({
            message: "Деактивация пользователя",
            description: error,
          });
        });
    };

    return (
        <Modal
            title={<span>Деактивация учетной записи</span>}
            cancelText="Закрыть"
            style={{ top: 20, minWidth: "700px" }}
            align={null}
            visible={isShow}
            onCancel={() => {
              setTokensData([]);
              setDeactiveShow(false);
              setPhoneCode('');
              onCloseModal();
            }}
            footer={null}
        >
          <Row gutter={[16, 24]}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Text strong style={{fontSize: "20px"}}> Номер телефона: {phone}</Text>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col lg={12} md={24} sm={24} xs={24}>
              {codeFieldVisible ? (
                <InputField name="phoneCode" label="Код"  value={phoneCode} setValue={setPhoneCode} getFieldErrors={getFieldErrors} />
              ) : null}
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{ marginTop: '25px', marginBottom: '15px' }}>
              {codeFieldVisible ? (
                <Button block style={bigButtonStyle} htmlType="button" type="primary" key="change-number" onClick={handleGetUserInfoViaCodes}>
                  Информация о пользователе
                </Button>
              ) : null}
            </Col>
          </Row>
          {deactiveShow ? (
          <Spin spinning={loadingContentModal} tip="Загрузка...">
            <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
              <Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
                ФИО:
              </Col>
              <Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
                  {userData.Name}
              </Col>
            </Row>
            <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
              <Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
                Роль:
              </Col>
              <Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
                  {userData.RoleName}
              </Col>
            </Row>
            <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
              <Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
                Компания:
              </Col>
              <Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
                  {userData.CompanyName}
              </Col>
            </Row>
            <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
              <Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
                Тип компании:
              </Col>
              <Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
                  {userData.CompanyType}
              </Col>
            </Row>
            <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
              <Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
                Грузоотправитель:
              </Col>
              <Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
                  {userData.Consignor}
              </Col>
            </Row>
            <Row gutter={[16, 24]}>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Text strong>
                  <h2>ВНИМАНИЕ!!!</h2>
                  <p>Вы можете деактивировать существующего пользователя, привязанного к этому номеру телефона, для последующего создания нового.</p>
                  <p>Данное действие является необратимым, зайти под предыдущим пользователем уже не будет возможности.</p>
                  <p>Все соответствующие роли пользователя привязки также будут деактивированы (если он являлся сотрудником другой организации, или 
                    зарегистрировал на себя ИП)
                  </p>
                </Text>
              </Col>
            </Row>
          </Spin> ) : null}
          <Row gutter={[16, 24]}>
            <Col lg={12} md={24} sm={24} xs={24} style={{ marginTop: '15px', marginBottom: '15px' }}>
              <Button 
                block
                style={approveNumberButton}
                htmlType="button"
                type="primary"
                onClick={handleRecieveCodes}
                key="get-codes"
              >
                Получить код подтверждения номера
              </Button>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{ marginTop: '15px', marginBottom: '15px' }}>
            {deactiveShow ? (
              <Button block style={redButton} htmlType="button" type="primary" key="change-number" onClick={handleDeactiveUserViaCodes}>
                Деактивировать пользователя
              </Button>
              ) : null}
            </Col>
          </Row>
        </Modal>
    );
}