import {httpRequest} from "./http-request";

export const logsServices = {
    getLogsUserActions,
    getLogsExchange,
    getDetailsLogExchange,
};


/**
 * Получить логи пользовательских действий
 */
async function getLogsUserActions() {

    return await httpRequest.get('log/user-actions')
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

/**
 * Получить логи обмена с 1С
 */
async function getLogsExchange() {

    return await httpRequest.get('log/logs-exchange')
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

/**
 * Запросить детализацию лога обмена
 */
async function getDetailsLogExchange(id) {

    return await httpRequest.get(`log/details-log-exchange/${id}`)
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}