import React, {useState, useEffect} from "react";
import {Button, Card, Col, Modal, notification, Popconfirm, Row, Spin} from "antd";
import '../../../components/modals/modals.css';
import ModalCreateOrderDocs from "../create-order-docs";
import {carrierService, deliveriesService} from "../../../services";

export default function ModalShowOrderDocs({isShow, onCloseModal, id}) {

  const [loadingContentModal, setLoadingContentModal] = useState(false);
  const [data, setData] = useState({
    invoice: null,
    act: null,
    id: null,
    num: null
  });
  const [isModalOrderDocsCreateShow, setIsModalOrderDocsCreateShow] = useState(false);

  const bigButtonStyle = {
    width: '100%',
    minHeight: '40px',
    background: '#1CAA53',
    borderRadius: '10px',
    borderColor: "#1CAA53"
  };
  const imgStyle = {
    width: "115px",
    height: "auto",
    border: "1px solid #1CAA53",
    borderRadius: "10px",
    display: "block",
    padding: "5px",
    margin: "15px auto"
  }

  useEffect(() => {
    if (loadingContentModal) {
      deliveriesService.getInfoDelivery(id)
        .then((res) => {
          setData({
            invoice: res.Order.Docs.Invoice ? res.Order.Docs.Invoice : null,
            act: res.Order.Docs.Act ? res.Order.Docs.Act : null,
            id: res.Order.Id,
            num: res.NumberDelivery
          });
          setLoadingContentModal(false);
        })
        .catch((error) => {
          setLoadingContentModal(false);
          notification.error({
            message: "Получение данных о рейсе", description: error,
          });
        });
    }
  }, [loadingContentModal]);

  useEffect(() => {
    if (isShow && !loadingContentModal) {
      setLoadingContentModal(true);
    }
  }, [isShow]);

  return (
    <Modal
      title={`Рейс № ${data.num ? data.num : ''}. Счет и акт`}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={onCloseModal}
      footer={<Popconfirm
        title='Вы уверены?'
        okText='Да'
        cancelText='Нет'
        onConfirm={() => setIsModalOrderDocsCreateShow(true)}
      >
        <Button type="primary"
                style={bigButtonStyle}
                icon={data.invoice || data.act ? `retweet` : `plus`}>
          {data.invoice || data.act ? `Создать повторно` : `Создать`}
        </Button>
      </Popconfirm>}
    >
      <Spin spinning={loadingContentModal} tip="Загрузка...">
        <Row gutter={8} type={"flex"}>
          <Col md={24} lg={12}>
            <Card title={"Счет"} size={"small"}>
              {
                data.invoice &&
                <a download href={data.invoice} target='_blank'>
                  <img
                    src={data.invoice.substring(data.invoice.length - 4, data.invoice.length) === '.pdf' ? 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg' : data.invoice}
                    alt="img" style={imgStyle}></img>
                </a>
              }
              {
                !data.invoice &&
                <p style={{textAlign: "center"}}>Отсутствует</p>
              }
            </Card>
          </Col>
          <Col md={24} lg={12}>
            <Card title={"Акт"} size={"small"}>
              {
                data.act &&
                <a download href={data.act} target='_blank'>
                  <img
                    src={data.act.substring(data.act.length - 4, data.act.length) === '.pdf' ? 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg' : data.act}
                    alt="img" style={imgStyle}></img>
                </a>
              }
              {
                !data.act &&
                <p style={{textAlign: "center"}}>Отсутствует</p>
              }
            </Card>
          </Col>
        </Row>
        {data && <ModalCreateOrderDocs id={data.id}
                                       num={data.num}
                                       isShow={isModalOrderDocsCreateShow}
                                       onCloseModal={() => {
                                         setIsModalOrderDocsCreateShow(false);
                                         setLoadingContentModal(true);
                                       }}/>}
      </Spin>
    </Modal>
  );
}
