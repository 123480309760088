import React, {useEffect, useState} from "react";
import moment from "moment";
import {consignorService, distributionCenterService, systemService} from "../../../../services";
import {Button, Checkbox, Col, DatePicker, Icon, notification, Row, Table} from "antd";
import {reportService} from "../../../../services/report.services";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import SelectField from "../../../select-field";
import Spinner from "../../../spinner";
import InputField from "../../../input-field";

export default function ReportStatsOrdersDeliveryByTime() {
  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [fieldsErrors, setFieldsErrors] = useState([]);
  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
      ? fieldsErrors[nameField][0]
      : null;
  };

  // параметры для построения отчета
  const [distributionCenterList, setDistributionCenterList] = useState([]);
  const [distributionCenterListLoaded, setDistributionCenterListLoaded] = useState(false);
  const [dateStart, setDateStart] = useState(moment().startOf('month'));
  const [dateEnd, setDateEnd] = useState(moment().endOf('month'));
  const [data, setData] = useState([]);
  const [distributionCenterId, setDistributionCenterId] = useState([]);
  const [minutesIntervalStart, setMinutesIntervalStart] = useState(null)
  const [minutesIntervalEnd, setMinutesIntervalEnd] = useState(null)
  const [onlyAgreedOrders, setOnlyAgreedOrders] = useState(false)

  const [countryId, setCountryId] = useState([]);
  const [countriesList, setCountriesList] = useState([]);

  const [approvingEmployeeId, setApprovingEmployeeId] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);

  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateReport(true);
    }
  }, []);

  useEffect(() => {
    if (!distributionCenterListLoaded) {
      distributionCenterService.getCompanies()
        .then(res => {
          setDistributionCenterListLoaded(true);
          setDistributionCenterList(res);
        })
        .catch(errMsg => {
          setDistributionCenterListLoaded(true);
          notification.error(
            {
              message: "Получение списка распределительных центров",
              description: errMsg
            });
        });
    }
  }, []);

  useEffect(() => {
    consignorService.getStaffUsersList()
      .then((res) => {
        const list = res.users.map(userData => {
          return {
            id: userData.id,
            name: userData.fio,
          }
        });
        setEmployeesList(list);
      })
      .catch(errMsg => {
        setDistributionCenterListLoaded(true);
        notification.error({
          message: "Получение списка стран",
          description: errMsg
        });
      });
  }, []);

  useEffect(() => {
    systemService.getCountryList()
      .then((res) => {
        setCountriesList(res);
      })
      .catch(errMsg => {
        setDistributionCenterListLoaded(true);
        notification.error({
          message: "Получение списка стран",
          description: errMsg
        });
      });
  }, []);

  useEffect(() => {
    if (isNeedUpdateReport) {
      setData([]);
      reportService.getStatsOrdersDeliveryByTime(
        dateStart.format('YYYY-MM-DD'), dateEnd.format('YYYY-MM-DD'),
        distributionCenterId,
        countryId,
        minutesIntervalStart,
        minutesIntervalEnd,
        onlyAgreedOrders,
        approvingEmployeeId
      )
        .then((response) => {
          let tblData = [];
          for (let item of response) {
            tblData.push({
              name: item.name,
              children: item.ordersDelivery
            })
          }

          setData(tblData);
          setLoading(false);
          setIsNeedUpdateReport(false);
        }).catch((errorMsg) => {
        setIsNeedUpdateReport(false);
        setLoading(false);
        setErrorMsg(errorMsg);
      });
    }
  }, [isNeedUpdateReport]);

  // список столбцов таблицы
  const columns = [
    {
      title: 'Название юр.лица',
      dataIndex: 'name',
      key: 'name',
      width: '7%',
    },
    {
      title: '№',
      dataIndex: 'deliveryNumber',
      width: '3%',
    },
    {
      title: 'Дата создания рейса',
      dataIndex: 'dateDeliveryCreated',
      width: '6%',
    },
    {
      title: 'Цена рейса',
      dataIndex: 'deliveryPrice',
      width: '4%',
    },
    {
      title: 'Откуда',
      dataIndex: 'distributionCenterName',
      width: '7%',
    },
    {
      title: 'Куда',
      dataIndex: 'destinationCountry',
      width: '5%',
    },
    {
      title: 'Цена в заявке',
      dataIndex: 'orderPrice',
      width: '4%',
    },
    {
      title: 'Время подачи заявки после появления рейса',
      dataIndex: 'timeBetweenOrderAndDelivery',
      width: '6%',
    },
    {
      title: 'Стат.',
      dataIndex: 'orderStatus',
      width: '5%',
    },
    {
      title: 'Дата согласования',
      dataIndex: 'dateTimeOrderAgree',
      width: '6%',
    },
  ];

  return (
    <React.Fragment>
      <div className="reports__header">
        <h1 className="content-header">Отчет «Статистика по времени создания заявки»</h1>
        <Icon type='filter'
              theme={isFilterOpen ? '' : "filled"}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen)
              }}
              className="reports__filter-toggle"/>
        {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
          <Row gutter={12} style={{padding: "0", rowGap: "10px", marginBottom: "12px"}} type="flex" justify="start"
               align="bottom">
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateStart}
                format="DD.MM.YYYY"
                size="normal"
                name="dateStart"
                placeholder="Выберите дату начала периода"
                locale={localeDatePicker}
                onChange={(date) => {
                  setDateStart(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateEnd}
                format="DD.MM.YYYY"
                size="normal"
                name="dateEnd"
                placeholder="Выберите дату окончания периода"
                locale={localeDatePicker}
                onChange={(date) => {
                  setDateEnd(date);
                }}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <InputField
                name="minutesIntervalStart"
                label="От (минуты)"
                value={minutesIntervalStart}
                setValue={setMinutesIntervalStart}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <InputField
                name="minutesIntervalEnd"
                label="До (минуты)"
                value={minutesIntervalEnd}
                setValue={setMinutesIntervalEnd}
                getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
          <Row gutter={12} style={{padding: "0", rowGap: "10px", marginBottom: "12px"}} type="flex" justify="start">
            <Col lg={8} md={24} sm={24} xs={24}>
              <SelectField
                placeholder="Страна"
                name="countryId"
                mode="multiple"
                selectedValue={countryId}
                values={countriesList}
                onChange={value => {
                  setCountryId(value);
                }}
                getErrors={getFieldErrors}
                style={{width: "100%"}}
              />
            </Col>
            <Col lg={8} md={24} sm={24} xs={24}>
              <SelectField
                placeholder="Подразделение"
                name="distributionCenterId"
                mode="multiple"
                selectedValue={distributionCenterId}
                values={distributionCenterList}
                onChange={value => {
                  setDistributionCenterId(value);
                }}
                getErrors={getFieldErrors}
                style={{width: "100%"}}
              />
            </Col>
            <Col lg={8} md={24} sm={24} xs={24}>
              <SelectField
                placeholder="Сотрудник"
                name="employeeId"
                mode="multiple"
                selectedValue={approvingEmployeeId}
                values={employeesList}
                onChange={value => {
                  setApprovingEmployeeId(value);
                }}
                getErrors={getFieldErrors}
                style={{width: "100%"}}
              />
            </Col>
          </Row>
          <Row gutter={12} style={{padding: "0", rowGap: "10px", marginBottom: "12px"}} type="flex" justify="start">
            <Col lg={16} md={16} sm={24} xs={24} style={{paddingLeft: "6px"}}>
              <Checkbox
                name="onlyAgreedOrders"
                checked={onlyAgreedOrders}
                onChange={(e) => {
                  setOnlyAgreedOrders(e.target.checked);
                }}
                style={{paddingTop: "15px"}}
              >
                Только согласованные заявки
              </Checkbox>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24} style={{padding: "0 0 0 6px"}}>
              <Button type="primary"
                      htmlType="button"
                      onClick={() => {
                        setLoading(true);
                        setIsNeedUpdateReport(true);
                      }}
                      style={{backgroundColor: '#1CAA53', border: 'none', height: '40px', width: '100%'}}
              >
                Применить
              </Button>
            </Col>
          </Row>
        </div>}
      </div>
      <div className="reports__table">
        <div className="reports__table-wrapper">
          <Col span={24}>
            <Table
              style={{minWidth: '700px'}}
              columns={columns}
              dataSource={data}
              locale={{emptyText: "Данные отсутствуют"}}
              rowKey="key"
              size="small"
              loading={loading}
              pagination={{
                pageSize: 50
              }}
            />
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
}
