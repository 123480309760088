import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Modal, notification, Row, Spin} from "antd";
import {companyService} from "../../../services";
import InputField from "../../input-field";

export default function ModalEditCompanyByConsignor({ isShow, setIsShow, companyID, setNeedUpdateList }) {
    const [loading, setLoading] = useState(true);
    const [fieldsErrors, setFieldsErrors] = useState([]);
    const [companyInfo, setCompanyInfo] = useState({});

    useEffect(() => {
        if (companyID && isShow) {
            setLoading(true);
            companyService.getCompanyInfo(companyID)
                .then(resp => {
                    setCompanyInfo(resp);
                    setLoading(false);
                })
                .catch((err) => {
                    notification.error({
                        message: "Получение настроек компании",
                        description: err,
                    });
                });
        }
    }, [companyID, isShow]);

    const getFieldErrors = (nameField) => {
        return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
            ? fieldsErrors[nameField][0]
            : null;
    };

    const handleSave = () => {
        if (companyID && companyInfo) {
            setLoading(true);
            companyService.editCompanyByConsignor(companyID, companyInfo)
                .then(resp => {
                    if (resp.status === "success") {
                        notification.success({
                            message: "Обновление данных компании",
                            description: resp.message,
                        });
                        setNeedUpdateList(true);
                    } else {
                        if (resp.message) {
                            notification.error({
                                message: "Обновление данных компании",
                                description: resp.message
                            });
                        }
                        if (resp.errors) {
                            setFieldsErrors(resp.errors);
                        }
                    }

                    setLoading(false);
                })
                .catch(err => {
                    notification.error({
                        message: "Обновление данных компании",
                        description: err,
                    });
                    setLoading(false);
                });
        }
    };

    let bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px' };
    return (
        <Modal
            title={`Настройки`}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
            }}
            footer={<Button htmlType="submit" key="submit" type="primary" style={bigButtonStyle} onClick={() => handleSave()}>Сохранить</Button>}
        >
            <Spin spinning={loading} tip="Загрузка...">
                <Row gutter={48}>
                    <Col span={8}>
                        <InputField
                            name="phone"
                            label="Моб. номер ответственного компании"
                            required={true}
                            value={companyInfo.MainUserPhone}
                            setValue={(value) => {
                                setCompanyInfo({...companyInfo, MainUserPhone: value});
                            }}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}
