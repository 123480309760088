import React, {useEffect, useState} from "react";
import {Button, Row, Col, Modal, Radio, notification} from "antd";
import '../modals.css';
import {deliveriesService, distributionCenterService} from "../../../services";
import Spinner from "../../spinner";
import {ShortDate} from "../../tools/delivery-tools";


export default function ModalChooseLoadingGate({
                                                 isShow, id, onCloseModal, setNeedUpdate, date
                                               }) {
  let bigButtonStyle = {
    width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px'
  };

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [loadingTime, setLoadingTime] = useState('');

  useEffect(() => {
    if (isShow && loading) {
      deliveriesService.getAvailableLoadingTime(id)
        .then((response) => {
          setData(response);
          setLoading(false);
        })
        .catch((errMsg) => {
          notification.error({
            message: "Список доступных для записи периодов", description: errMsg
          });
          setLoading(false);
        })
    }
  }, [loading, isShow])


  const onSignUp = () => {
    deliveriesService.signUpLoadingTime(id, loadingTime)
      .then((response) => {
        notification.success({
          message: "Запись на погрузку", description: response.message,
        });
        onCloseModal();
        setNeedUpdate(true);
      })
      .catch((err) => {
        notification.error({
          message: "Запись на погрузку", description: err,
        });
        setLoading(true);
      })
  }

  return (<Modal
      title={<>
        <div>Записаться на погрузку</div>
        <ShortDate date={date}/></>}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        onCloseModal();
        setLoading(true);
      }}
      forceRender={true}
      footer={data.length ? [<Button htmlType="submit" key="submit" type="primary"
                                     style={bigButtonStyle}
                                     onClick={() => onSignUp()}
      >Записаться</Button>,] : [<Button htmlType="button" key="button" type="primary"
                                        style={bigButtonStyle}
                                        onClick={() => {
                                          onCloseModal();
                                          setLoading(true);
                                        }}
      >Закрыть</Button>,]}
    >
      <Spinner loading={loading}>
        <Row gutter={[16, 24]} style={{marginBottom: "34px"}}>
          <Col span={24}>
            <Radio.Group className="radio-group-primary">
              {(data && data.length) ? data.map((item, index) => {
                return <Radio.Button key={index} value={item}
                                     style={{margin: "5px"}}
                                     onClick={() => setLoadingTime(item)}
                >{item}</Radio.Button>
              }) : null}
              {!data.length ? (<h2><b>Запись на погрузку недоступна</b></h2>) : null}
            </Radio.Group>
          </Col>
        </Row>
      </Spinner>
    </Modal>);
}
