import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Descriptions, Icon, notification, Pagination, Row, Table, Tooltip} from 'antd';
import Spinner from "../../../spinner";
import ModalCreateStaffUser from "../../../modals/user/create-staff-user";
import ModalEditStaffUser from "../../../modals/user/edit-staff-user";
import {tableHelper} from "../../../../helpers";
import {roleService, userServices} from "../../../../services";
import './user-list.css';
import ModalEditCarrierUser from "../../../modals/user/edit-carrier-user";

export default function UserList() {

  const [searchText, setSearchText] = useState('');
  const [listStaffRoles, setListStaffRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isNeedUpdateList, setIsNeedUpdateList] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(null);
  const [isStaffUser, setIsStaffUser] = useState(true);
  const [params, setParams] = useState(null);
  const [pagination, setPagination] = useState({
    defaultPageSize: 50,
    pageSize: 50,
    position: "both"
  });
  const [currentUserData, setCurrentUserData] = useState({
    fio: '',
    login: ' ',
    password: null,
    email: '',
    phone: '',
    role: '',
    status: '',
    distributionsCentersIds: [],
  });

  const [columns, setColumns] = useState([
    {
      title: 'ФИО',
      dataIndex: 'fio',
      key: 'fio',
      sorter: (a, b) => a.fio.length - b.fio.length,
      ...tableHelper.getColumnSearchProps('fio', searchText, setSearchText)
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      filters: listStaffRoles,
      //...tableHelper.getColumnSearchProps('role', searchText, setSearchText),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '15%',
      ...tableHelper.getColumnSearchProps('email', searchText, setSearchText)
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      width: '15%',
      ...tableHelper.getColumnSearchProps('phone', searchText, setSearchText)
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      filters: [{text: 'Активен', value: '10'}, {text: 'Удален', value: '0'}],
      width: '15%'
    },
    {
      title: null,
      key: 'edit',
      width: '120px',
      render: (data) => {

        if (data.isStaffUser) {

          return (
            <React.Fragment>
              <Button type="primary" icon={"edit"} htmlType="button" onClick={() => {
                setShowModalEdit(data.id);
                setCurrentUserData(data);
                setIsStaffUser(data.isStaffUser);
              }}
                      size="small" style={smallButtonStyle}/>
            </React.Fragment>
          );

        } else {
          return (
            <React.Fragment>
              <Button type="primary" htmlType="button" onClick={() => {
                setShowModalEdit(data.id);
                setCurrentUserData(data);
                setIsStaffUser(data.isStaffUser);
              }}
                      size="small" style={smallButtonStyle}>
                Изменить
              </Button>
            </React.Fragment>
          );
        }
      }
    },
  ]);

  let smallButtonStyle = {
    width: '60px',
    height: '40px',
    background: '#1CAA53',
    position: 'static',
    borderRadius: '10px',
    color: "white",
    borderColor: "transparent"
  };
  let addUserButton = {
    width: "100%",
    maxWidth: '270px',
    height: '43px',
    background: '#1CAA53',
    position: 'static',
    borderRadius: '10px',
    color: "white",
    borderColor: "transparent"
  };

  const getUserList = (params) => {
    setLoading(true);
    userServices.getUsersList({...params})
      .then((res) => {
        const usersData = res.users.map((item) => {
          return {
            id: item.id,
            fio: item.fio,
            login: item.login,
            email: item.email,
            phone: item.phone,
            roleName: item.role.name,
            role: item.role.description,
            statusName: item.status.name,
            status: item.status.description,
            distributionsCenters: item.distributionsCenters,
            isStaffUser: item.isStaffUser,
            isAccreditedIntDeliveries: item.isAccreditedIntDeliveries,
            delivDisplayDelay: item.delivDisplayDelay,
          };
        });

        setPagination({
          ...pagination,
          total: res.totalCount
        });

        setIsNeedUpdateList(false);
        setLoading(false);
        setUsersList(usersData);
      })
      .catch((errorMsg) => {
        setIsNeedUpdateList(false);
        setLoading(false);
        setErrorMsg(errorMsg);
      });
  };

  useEffect(() => {
    if (isNeedUpdateList && !params) {
      getUserList({step: pagination.pageSize, offset: 0});
    }

    if (isNeedUpdateList && params) {
      getUserList({...params});
    }

  }, [isNeedUpdateList]);

  useEffect(() => {
    roleService.getRolesForFilters()
      .then((response) => {
        const staffRolesData = response.map((roleData) => {
          return {
            value: [roleData.Name],
            text: roleData.Description,
          }
        });

        setListStaffRoles(staffRolesData);
      })
      .catch((error) => {
        notification.error({
          message: "Получение ролей",
          description: error,
        });
      });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    handleResize();
  }, [listStaffRoles]);

  const handleResize = () => {
    if (window.innerWidth > 1023) {
      setColumns([
        {
          title: 'ФИО',
          dataIndex: 'fio',
          key: 'fio',
          sorter: (a, b) => a.fio.length - b.fio.length,
          ...tableHelper.getColumnSearchProps('fio', searchText, setSearchText),
        },
        {
          title: 'Роль',
          dataIndex: 'role',
          key: 'role',
          filters: listStaffRoles,
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          width: '15%',
          ...tableHelper.getColumnSearchProps('email', searchText, setSearchText)
        },
        {
          title: 'Телефон',
          dataIndex: 'phone',
          key: 'phone',
          width: '15%',
          ...tableHelper.getColumnSearchProps('phone', searchText, setSearchText)
        },
        {
          title: 'Статус',
          dataIndex: 'status',
          key: 'status',
          filters: [{text: 'Активен', value: '10'}, {text: 'Удален', value: '0'}],
          width: '15%'
        },
        {
          title: null,
          key: 'edit',
          width: '60px',
          render: (data) => {

            if (data.isStaffUser) {

              return (
                <React.Fragment>
                  <Button type="primary" icon={"edit"} htmlType="button" onClick={() => {
                    setShowModalEdit(data.id);
                    setCurrentUserData(data);
                    setIsStaffUser(data.isStaffUser);
                  }}
                          size="small" style={smallButtonStyle}/>
                </React.Fragment>
              );

            } else {
              return (
                <React.Fragment>
                  <Button type="primary" icon={"edit"} htmlType="button" onClick={() => {
                    setShowModalEdit(data.id);
                    setCurrentUserData(data);
                    setIsStaffUser(data.isStaffUser);
                  }}
                          size="small" style={smallButtonStyle}/>
                </React.Fragment>
              );
            }
          }
        },
      ]);
    } else if (window.innerWidth <= 1023) {
      setColumns([
        {
          title: <><Icon type="user"/><span>  ФИО</span></>,
          key: 'fio',
          width: "100%",
          sorter: (a, b) => a.fio - b.fio,
          dataIndex: "fio",
          ...tableHelper.getColumnSearchProps('fio', searchText, setSearchText),
          children: [
            {
              title: <Tooltip title="Роль"><><Icon type="solution"/><span>  Роль</span></>
              </Tooltip>,
              dataIndex: "role",
              filters: listStaffRoles,
              render: (text, record, index) => {
                return {
                  children: <>
                    <Card title={<b>{record.fio}</b>}
                          extra={<Icon type="edit" key="edit" onClick={() => {
                            setShowModalEdit(record.id);
                            setCurrentUserData(record);
                            setIsStaffUser(record.isStaffUser);
                          }}/>}
                          size={"small"}
                    >
                      <Descriptions column={1} size={"small"}>
                        <Descriptions.Item label="Роль">{record.role}</Descriptions.Item>
                        <Descriptions.Item label="Email">{record.email}</Descriptions.Item>
                        <Descriptions.Item label="Телефон">{record.phone}</Descriptions.Item>
                        <Descriptions.Item label="Статус">{record.status}</Descriptions.Item>
                      </Descriptions>
                    </Card>
                  </>,
                  props: {colSpan: 4}
                }
              },
            },
            {
              title: <Tooltip title="Статус"><><Icon type="info-circle"/><span>  Статус</span></>
              </Tooltip>,
              dataIndex: "status",
              filters: [{text: 'Активен', value: '10'}, {text: 'Удален', value: '0'}],
              render: () => {
                return null
              }
            }
          ]
        },
        {
          title: <Tooltip title="Дата создания"><><Icon type="calendar"/><span>  Дата создания</span></>
          </Tooltip>,
          dataIndex: "created_at",
          sorter: (a, b) => a["created_at"] - b["created_at"],
          children: [
            {
              title: <Tooltip title="Email"><><Icon type="mail"/><span>  Email</span></>
              </Tooltip>,
              dataIndex: "email",
              ...tableHelper.getColumnSearchProps('email', searchText, setSearchText),
              render: () => {
                return null
              }
            },
            {
              title: <Tooltip title="Телефон"><><Icon type="phone"/><span> Телефон</span></>
              </Tooltip>,
              dataIndex: "phone",
              ...tableHelper.getColumnSearchProps('phone', searchText, setSearchText),
              render: () => {
                return null
              }
            },
          ]
        }
      ]);
    }
  };

  if (errorMsg) {
    return (<div className="content-error-block">{errorMsg}</div>);
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPagination({
      ...pagination
    });

    const params = {
      offset: (pagination.current - 1) * pagination.pageSize,
      step: pagination.pageSize,
      orderBy: sorter.order && sorter.columnKey === 'fio' ?
        'name' :
        sorter.order ? sorter.field : '',
      isSortASC: Boolean(sorter.order === 'ascend'),
      ...filters
    };

    setParams(params);

    getUserList({...params});
  }

  return (
    <React.Fragment>
      <h1 className="content-header" style={{marginBottom: 5}}>Список пользователей</h1>
      <Spinner loading={loading} label={null}>
        <Row type="flex" justify="end">
          <Col span={4} className="btn-create-entity-block">
            <Button htmlType='button' type="primary" style={addUserButton} onClick={() => setShowModalCreate(true)}>
              Создать штатного пользователя
            </Button>
            <ModalCreateStaffUser
              onCloseModal={() => {
                setShowModalCreate(false);
                setLoading(true);
                setIsNeedUpdateList(true);
              }}
              isShow={showModalCreate}
            />
          </Col>
        </Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={usersList}
            loading={loading}
            locale={{emptyText: "Список пользователей отсутствует"}}
            rowKey="id"
            size="small"
            pagination={pagination}
            onChange={onTableChange}
            className="app-table app-table--with-card-mobile"
          />
          <ModalEditStaffUser
            isShow={(showModalEdit !== null && isStaffUser)}
            onCloseModal={() => {
              setShowModalEdit(null);
              setLoading(true);
              setIsNeedUpdateList(true);
              setCurrentUserData({
                fio: '',
                login: ' ',
                password: null,
                email: '',
                phone: '',
                role: '',
                status: '',
                distributionsCentersIds: [],
              });
            }}
            currentUserData={{
              fio: currentUserData.fio,
              login: currentUserData.login,
              password: null,
              email: currentUserData.email,
              phone: currentUserData.phone,
              role: currentUserData.roleName,
              status: currentUserData.statusName,
              distributionsCentersIds: currentUserData.distributionsCentersIds,
            }}
            userId={showModalEdit}
          />
          <ModalEditCarrierUser
            isShow={(showModalEdit !== null && !isStaffUser)}
            onCloseModal={() => {
              setShowModalEdit(null);
              setLoading(true);
              setIsNeedUpdateList(true);
            }}
            userId={currentUserData.id}
            userStatus={currentUserData.statusName}
            userFio={currentUserData.fio}
            delivDisplayDelay={currentUserData.delivDisplayDelay}
            userAccreditedIntDeliveries={currentUserData.isAccreditedIntDeliveries}
            roleName={currentUserData.roleName}
          />
        </Col>
      </Spinner>
    </React.Fragment>
  );
}
