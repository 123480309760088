import React, {useEffect, useState} from "react";
import ModalCreateOrder from "../modals/create-order-delivery";
import { deliveriesService, orderDeliveryService, userServices } from "../../services";
import {Button, notification, Popconfirm, Upload} from "antd";
import ModalAttachDriverOrderDelivery from "../modals/attach-driver-order-delivery";
import moment from "moment";
import {
    DELIVERY_AWAITING_LOADING,
    DELIVERY_BOOKED,
    DELIVERY_ENROUTE,
    DELIVERY_ORDER_FILLED,
    DELIVERY_LOADING,
    DELIVERY_AGREED,
    DELIVERY_RESERVED,
    DELIVERY_FREE,
    DELIVERY_ORDER_APPROVED,
    DELIVERY_ORDER_AGREED,
    DELIVERY_ORDER_AWAITING_AGREED
} from "../../helpers/delivery_status.helper";
import ModalListOrdersDelivery from "../modals/list-orders-delivery";
import {
    CREATE_DELIVERY, EN_ROUTE_DELIVERY,
    UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY,
    VIEW_AGREED_DELIVERIES_LOGIST, VIEW_BOOKED_DELIVERIES_LOGIST
} from "../../helpers/user_permission.helper";
import ModalDriverInfo from "../modals/driver-info";
import ModalUploadDeliveryDocs from "../modals/attach-delivery-docs-consignor";
import ModalChangeDeliveryShipperCompany from "../modals/change-delivery-shipper-company";
import ModalAddDriversPhotosOnLoadingDelivery from "../modals/add-driver-photos-on-loading-delivery";
import ModalShowDriversPhotosByDistCenter from "../modals/show-drivers-photos-by-dist-center";
import ModalReturnLoadingDelivery from "../modals/return-loading-delivery";
import ModalShowOrderDocs from "../modals/order-docs";
import ModalReturnToEnRoute from "../modals/return-to-en-route";


export function LogistShortButton({ delivery, permissions, setNeedUpdate, setStopListUpdate, setLoadingDelivItem, timerEnabled }) {
    const user = userServices.getUser();

    if (typeof (delivery.Permissions) == "undefined") {
        return null;
    }
    if (delivery.Permissions.IsAvailableManageDelivery === false) {
        return null;
    }

    const dateShipmentRus = moment(delivery.DateShipment).format("D MMMM YYYY");

    const [showModalOrdersDelivery, setShowModalOrdersDelivery] = useState(false);
    const [showModalCreateOrder, setShowModalCreateOrder] = useState(false);
    const [showModalAttachDriver, setShowModalAttachDriver] = useState(false);
    const [showModalDriverInfo, setShowDriverModalInfo] = useState(false);
    const [showModalAgreeDelivery, setShowAgreeDelivery] = useState(false);
    const [showModalChangeShipperCompany, setShowModalChangeShipperCompany] = useState(false);
    const [showModalUploadDriversPhotos, setShowModalUploadDriversPhotos] = useState(false);
    const [showModalDriversPhotos, setShowModalDriversPhotos] = useState(false);
    const [isModalReturnLoadingShow, setIsModalReturnLoadingShow] = useState(null);
    const [isModalReturnToEnRouteShow, setIsModalReturnToEnRouteShow] = useState(null);

    const [paymentType, setPaymentType] = useState(null);
    useEffect(() => {
        if (delivery.Prices) {
            const price = delivery.Prices.find(e => e.Price !== null);
            if (price) {
                setPaymentType(price.PaymentType.Id);
            }
        }
    }, [delivery]);

    const onClickAddToArchive = (e) => {
        e.preventDefault();
        setLoadingDelivItem(true);

        deliveriesService
            .archive(delivery.Id)
            .then((response) => {
                notification.success({
                    message: "Добавление в архив",
                    description: response.message,
                });

                setLoadingDelivItem(false);
                setNeedUpdate(true);
            })
            .catch((error) => {
                notification.error({
                    message: "Добавление в архив",
                    description: error,
                });

                setLoadingDelivItem(false);
                setNeedUpdate(true);
            });
    };

    const onClickEnRouteDelivery = (e) => {
        e.preventDefault();
        setLoadingDelivItem(true);

        deliveriesService
            .enRoute(delivery.Id)
            .then((response) => {
                notification.success({
                    message: "Отправка в путь",
                    description: response.message,
                });

                setLoadingDelivItem(false);
                setNeedUpdate(true);
            })
            .catch((error) => {
                notification.error({
                    message: "Отправка в путь",
                    description: error,
                });

                setLoadingDelivItem(false);
                setNeedUpdate(true);
            });
    };

    const onClickChangeToLoading = (e) => {
        e.preventDefault();
        setLoadingDelivItem(true);

        deliveriesService
            .changeToLoading(delivery.Id)
            .then((response) => {
                notification.success({
                    message: 'Изменение статуса на "На погрузке"',
                    description: response.message,
                });

                setLoadingDelivItem(false);
                setNeedUpdate(true);
            })
            .catch((error) => {
                notification.error({
                    message: 'Изменение статуса на "На погрузке"',
                    description: error,
                });

                setLoadingDelivItem(false);
                setNeedUpdate(true);
            });
    };

    const onClickNoteActualLoadTime = (e) => {
        e.preventDefault();
        setLoadingDelivItem(true);

        deliveriesService
            .noteActualLoadTime(delivery.Id)
            .then((response) => {
                notification.success({
                    message: 'Запись времени погрузки',
                    description: response.message,
                });

                setLoadingDelivItem(false);
                setNeedUpdate(true);
            })
            .catch((error) => {
                notification.error({
                    message: 'Запись времени погрузки',
                    description: error,
                });

                setLoadingDelivItem(false);
                setNeedUpdate(true);
            });
    };

    const onClickNoteDriverArrivedTime = (e) => {
        e.preventDefault();
        setLoadingDelivItem(true);

        deliveriesService
            .noteDriverArrivedTime(delivery.Id)
            .then((response) => {
                notification.success({
                    message: 'Запись времени прибытия водителя',
                    description: response.message,
                });

                setLoadingDelivItem(false);
                setNeedUpdate(true);
            })
            .catch((error) => {
                notification.error({
                    message: 'Запись времени прибытия водителя',
                    description: error,
                });

                setLoadingDelivItem(false);
                setNeedUpdate(true);
            });
    };

    const onClickAgreeWithoutDocs = (e) => {
        e.preventDefault();
        setLoadingDelivItem(true);

        orderDeliveryService
            .agreeWithoutDocs(delivery.Order.Id)
            .then((response) => {
                notification.success({
                    message: "Утверждение заявки без документов",
                    description: response.message,
                });

                setLoadingDelivItem(false);
                setNeedUpdate(true);
            })
            .catch((error) => {
                notification.error({
                    message: "Утверждение заявки без документов",
                    description: error,
                });

                setLoadingDelivItem(false);
                setNeedUpdate(true);
            });
    };

    const onClickShowOrders = (e) => {
        e.preventDefault();
        setStopListUpdate(true);
        setShowModalOrdersDelivery(true);
    };

    const onClickCreateOrderDelivery = (e) => {
        e.preventDefault();
        setStopListUpdate(true);
        setShowModalCreateOrder(true);
    };

    const onClickShowModalDriverInfo = (e) => {
        e.preventDefault();
        setShowDriverModalInfo(true);
    };

    const onClickShowAgreeDelivery = (e) => {
        e.preventDefault();
        setStopListUpdate(true);
        setShowAgreeDelivery(true);
    };

    const onCLickShowUserDoc = (filePath) => {
        window.open(filePath);
    };

    const onClickDriversPhoto = (e) => {
        e.preventDefault();
        setShowModalUploadDriversPhotos(true);
    };

    const onClickShowDriversPhotos = (e) => {
        e.preventDefault();
        setShowModalDriversPhotos(true);
    };

    const onDetachDriver = (e) => {
        e.preventDefault();

        setLoadingDelivItem(true);

        orderDeliveryService
            .detachDriver(delivery.Order.Id)
            .then((response) => {
                notification.success({
                    message: "Открепление водителя",
                    description: response.message,
                });

                setLoadingDelivItem(false);
                setNeedUpdate(true);
            })
            .catch((error) => {
                notification.error({
                    message: "Открепление водителя",
                    description: error,
                });

                setLoadingDelivItem(false);
                setNeedUpdate(true);
            });
    }

    let btnViewOrders = null;
    let btnCreateOrder = null;
    let btnAgreeWithoutDocs = null;
    let btnFillOrder = null;
    let btnSetEnroute = null;
    let btnSetArchive = null;
    let btnShowDriverInfo = null;
    let btnShowUserDoc = null;
    let btnShowProcuration = null;
    let btnEnRouteDelivery = null;
    let btnUploadDriversPhotosOnLoading = null;
    let btnDetachDriver = null;
    let btnAgreeOrderDelivery = null;
    let btnChangeShipperCompany = null;
    let btnShowDriversPhotos = null;
    let btnNoteActualLoadTime = null;
    let btnReturnLoadingDelivery = null;
    let btnReturnToEnRoute = null;
    let btnNoteDriverArrivedTime = null;

    if (delivery.Status === DELIVERY_ENROUTE) {
        btnSetArchive = (
            <a className="action-button typical-btn-blue" onClick={onClickAddToArchive}>
                В архив
            </a>
        );
    }
    if(delivery.Status === DELIVERY_ENROUTE) {
        btnReturnLoadingDelivery = (
          <React.Fragment key={`return-loading-button-${delivery.Id}`}>
              <button className="action-button"
                      onClick={() => {
                          setIsModalReturnLoadingShow(delivery.Id);
                      }}
              >Вернуть на погрузку
              </button>
              <ModalReturnLoadingDelivery
                onCloseModal={() => {
                    setIsModalReturnLoadingShow(null);
                }}
                isShow={isModalReturnLoadingShow === delivery.Id}
                id={delivery.Id}/>
          </React.Fragment>
        );
    }
    if(delivery.Status === DELIVERY_LOADING) {
        btnReturnLoadingDelivery = (
          <React.Fragment key={`return-loading-button-${delivery.Id}`}>
              <button className="action-button"
                      onClick={() => {
                          setIsModalReturnLoadingShow(delivery.Id);
                      }}
              >Отменить погрузку
              </button>
              <ModalReturnLoadingDelivery
                onCloseModal={() => {
                    setIsModalReturnLoadingShow(null);
                }}
                isShow={isModalReturnLoadingShow === delivery.Id}
                id={delivery.Id}/>
          </React.Fragment>
        );
    }
    const accessToSetOnLoading = (
        user.isAccessTo(VIEW_AGREED_DELIVERIES_LOGIST) ||
        user.isAccessTo(CREATE_DELIVERY) ||
        user.isAccessTo(UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY)
    ) && (delivery.Status === DELIVERY_AWAITING_LOADING || delivery.Status === DELIVERY_LOADING);

    if (accessToSetOnLoading) {
        if (delivery.Permissions.IsNeedAddParams) {
            if (user.isAccessTo(UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY)) {
                btnUploadDriversPhotosOnLoading = (
                    <React.Fragment>
                        <a className="action-button typical-btn-blue" onClick={onClickDriversPhoto}>
                            Прикрепить фото
                        </a>
                        <ModalAddDriversPhotosOnLoadingDelivery
                            deliveryID={delivery.Id}
                            isShow={showModalUploadDriversPhotos}
                            setIsShow={setShowModalUploadDriversPhotos}
                            setNeedUpdate={setNeedUpdate}
                            setStopListUpdate={setStopListUpdate}
                        />
                    </React.Fragment>
                );
            }
        } else {
            if (
                (user.isAccessTo(EN_ROUTE_DELIVERY) || user.isAccessTo(UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY)) &&
                delivery.Status === DELIVERY_AWAITING_LOADING
            ) {
                btnSetEnroute = (
                    <Popconfirm
                        title='Вы уверены?'
                        okText='Да'
                        cancelText='Нет'
                        onConfirm={onClickChangeToLoading}
                    >
                        <a className="action-button typical-btn-blue">
                            <span className="speedometer">Погрузка</span>
                        </a>
                    </Popconfirm>
                );
            }
        }
    }
    if(delivery.Status == DELIVERY_AWAITING_LOADING && delivery.DriverArrivedTime === null) {
        btnNoteDriverArrivedTime = (
            <Popconfirm
                title='Вы уверены?'
                okText='Да'
                cancelText='Нет'
                onConfirm={onClickNoteDriverArrivedTime}
            >
                <a className="action-button">
                    Водитель прибыл
                </a>
            </Popconfirm>
        );
    }

    if (delivery.Status === DELIVERY_LOADING && user.isAccessTo(EN_ROUTE_DELIVERY)) {
        btnEnRouteDelivery = (
            <Popconfirm
                title='Вы уверены?'
                okText='Да'
                cancelText='Нет'
                onConfirm={onClickEnRouteDelivery}
            >
                <a className="action-button typical-btn-blue">
                    Отправить в путь
                </a>
            </Popconfirm>
        );
    }

    if (delivery.Order && delivery.Order.Status === DELIVERY_ORDER_FILLED) {
        if (delivery.Permissions.IsAvailableAgreeWithoutDocs) {
            btnAgreeWithoutDocs = (
                <a className="action-button typical-btn-blue" onClick={onClickAgreeWithoutDocs}>
                    Утвердить без документов
                </a>
            );
        }
    }

    if (delivery.Order && delivery.Order.Company.Driver) {
        btnShowDriverInfo = (
            <>
                <a className="action-button typical-btn-blue" onClick={onClickShowModalDriverInfo}>
                    Информация о водителе
                </a>
                <ModalDriverInfo isShow={showModalDriverInfo}
                                 setIsShow={setShowDriverModalInfo}
                                 driverId={delivery.Order.Company.Driver.Id}
                                 truckId={delivery.Order.Company.Driver.Truck ? delivery.Order.Company.Driver.Truck.Id : null}
                                 trailId={delivery.Order.Company.Driver.Trail ? delivery.Order.Company.Driver.Trail.Id : null}
                />
            </>
        );
    }

    if (delivery.Permissions.IsAvailableChangeShipperCompany) {
        console.log(delivery);
        btnChangeShipperCompany = (
            <>
                <a className="action-button typical-btn-red" onClick={() => {
                    setShowModalChangeShipperCompany(true);
                }}>
                    Изменить юр.лицо
                </a>
                <ModalChangeDeliveryShipperCompany
                    isShow={showModalChangeShipperCompany}
                    setIsShow={setShowModalChangeShipperCompany}
                    setIsNeedUpdate={setNeedUpdate}
                    setStopListUpdate={setStopListUpdate}
                    currentShipperCompany={delivery.CustomerCompanyId}
                    currentDistCenter={delivery.DistributionCenter.Id}
                    deliveryID={delivery.Id}
                />
            </>
        );
    }

    if ((delivery.Status === DELIVERY_FREE || delivery.Status === DELIVERY_RESERVED)) {
        const countOrders = (typeof (delivery.CountOrders) !== "undefined") ? delivery.CountOrders : 0;// delivery.Orders !== null ? delivery.Orders.length : 0;
        if (countOrders && delivery.Permissions.IsAvailableViewListOrders) {
            btnViewOrders = (
                <div>
                    <a className="action-button typical-btn-red" onClick={onClickShowOrders}>
                        Посмотреть заявки ({countOrders})
                    </a>
                    <ModalListOrdersDelivery
                        isShow={showModalOrdersDelivery}
                        setIsShow={setShowModalOrdersDelivery}
                        deliveryId={delivery.Id}
                        setNeedUpdateDeliveryList={setNeedUpdate}
                        setStopListUpdate={setStopListUpdate}
                        delivery={delivery}
                    />
                </div>
            );
        }
    }

    if (delivery.Status === DELIVERY_AGREED) {
        btnReturnToEnRoute = (
          <React.Fragment key={`invoice-button-${delivery.Id}`}>
              <button className="action-button"
                      onClick={() => {
                          setIsModalReturnToEnRouteShow(delivery.Id);
                      }}
              >Вернуть из архива
              </button>
              <ModalReturnToEnRoute
                onCloseModal={() => {
                    setIsModalReturnToEnRouteShow(null);
                }}
                isShow={isModalReturnToEnRouteShow === delivery.Id}
                id={delivery.Id}/>
          </React.Fragment>
        );
    }

    //Заполнить заявку
    if (delivery.Permissions.IsAccessCreatedOrder && delivery.Order.Status !== DELIVERY_ORDER_FILLED && delivery.Order.Status !== DELIVERY_ORDER_AWAITING_AGREED) {
        const nameRegionLastPointUnload = delivery.PlacesUnload.length
            ? delivery.PlacesUnload[delivery.PlacesUnload.length - 1].City
            : "-";

        const regionLoad = delivery.PlaceLoad.City ? delivery.PlaceLoad.City : "-";
        const isForeignDelivery = delivery.IsForeignDelivery;

        btnFillOrder = (
            <>
                <a
                    className="action-button typical-btn-green"
                    onClick={(e) => {
                        e.preventDefault();
                        setStopListUpdate(true);
                        setShowModalAttachDriver(true);
                        orderDeliveryService
                            .start(delivery.Order.Id)
                            .then((response) => {
                                console.log("success");
                                /*                                 notification.success({
                                                    message: "Начало заполнения заявки",
                                                    description: response.message
                                                }) */
                            })
                            .catch((error) => {
                                console.log("error");
                                /*                                 notification.error({
                                                    message: "Начало заполнения заявки",
                                                    description:error
                                                })
                                                setShowModalAttachDriver(false); */
                            });
                    }}
                >
                    Заполнить заявку
                </a>
                <ModalAttachDriverOrderDelivery
                    key={`modal_${delivery.Id}`}
                    isShow={showModalAttachDriver}
                    setIsShow={setShowModalAttachDriver}
                    setNeedUpdateDeliveryList={setNeedUpdate}
                    orderId={delivery.Order.Id}
                    setStopListUpdate={setStopListUpdate}
                    companyId={delivery.Order.Company.Id}
                    deliveryDateShipmentRus={dateShipmentRus}
                    deliveryPrice={delivery.Order.Price}
                    deliveryRegionLoad={regionLoad}
                    deliveryRegionUnload={nameRegionLastPointUnload}
                    isForeignDelivery={isForeignDelivery}
                    deliveryContractType={delivery.ContractType}
                />
            </>
        );
    }

    if (delivery.Permissions.IsAvailableCreate && !timerEnabled && (delivery.Status === DELIVERY_FREE || delivery.Status === DELIVERY_RESERVED || delivery.Status === DELIVERY_BOOKED)) {
        btnCreateOrder = (
            <React.Fragment>
                <a className="action-button" onClick={onClickCreateOrderDelivery}>
                    <span className="speedometer">Подать заявку</span>
                </a>
                <ModalCreateOrder
                    isShow={showModalCreateOrder}
                    setIsShow={setShowModalCreateOrder}
                    delivery={delivery}
                    setNeedUpdateDeliveryList={setNeedUpdate}
                    setStopListUpdate={setStopListUpdate}
                    paymentType={paymentType}
                    setPaymentType={setPaymentType}
                />
            </React.Fragment>
        );
    }

    if (user.isAccessTo(VIEW_BOOKED_DELIVERIES_LOGIST) && delivery.Order && delivery.Order.Doc && delivery.Order.Status !== DELIVERY_ORDER_AWAITING_AGREED) {
        btnShowUserDoc = (
            <React.Fragment>
                <a className="action-button typical-btn-blue" onClick={() => onCLickShowUserDoc(delivery.Order.Doc)}>
                    Просмотреть приложение
                </a>
            </React.Fragment>
        );
    }

    if (user.isAccessTo(VIEW_BOOKED_DELIVERIES_LOGIST) && delivery.Order && delivery.Order.Procuration && delivery.Order.Status !== DELIVERY_ORDER_AWAITING_AGREED) {
        btnShowProcuration = (
            <React.Fragment>
                <a className="action-button typical-btn-blue" onClick={() => onCLickShowUserDoc(delivery.Order.Procuration)}>
                    Просмотреть доверенность
                </a>
            </React.Fragment>
        );
    }

    if (delivery.Order && delivery.Order.Status === DELIVERY_ORDER_AWAITING_AGREED) {
        btnAgreeOrderDelivery = (
            <>
                <a className="action-button typical-btn-green" onClick={onClickShowAgreeDelivery}>
                    Подтвердить
                </a>
                <ModalUploadDeliveryDocs
                    isShow={showModalAgreeDelivery}
                    setIsShow={setShowAgreeDelivery}
                    orderDelivery={delivery.Order}
                    setStopListUpdate={setStopListUpdate}
                    setNeedUpdateDeliveries={setNeedUpdate}
                />
            </>
        );
    }

    if (delivery && user.isAccessTo(EN_ROUTE_DELIVERY) && (delivery.Status === DELIVERY_BOOKED || delivery.Status === DELIVERY_AWAITING_LOADING)) {
        if (delivery.Order && (delivery.Order.Status === DELIVERY_ORDER_FILLED || delivery.Order.Status === DELIVERY_ORDER_AGREED)) {
            btnDetachDriver = (
                <Popconfirm
                    title='Вы уверены?'
                    okText='Да'
                    cancelText='Нет'
                    onConfirm={onDetachDriver}
                >
                    <a className="action-button typical-btn-red">
                        Открепить водителя
                    </a>
                </Popconfirm>
            );
        }
    }

    if (delivery.Permissions.IsAddParamsUploaded) {
        btnShowDriversPhotos = (
            <React.Fragment>
                <a className="action-button typical-btn-blue" onClick={onClickShowDriversPhotos}>
                    Фото водителя от РЦ
                </a>

                <ModalShowDriversPhotosByDistCenter
                    isShow={showModalDriversPhotos}
                    setIsShow={setShowModalDriversPhotos}
                    deliveryID={delivery.Id}
                    setStopListUpdate={setStopListUpdate}
                    setNeedUpdate={setNeedUpdate}
                />
            </React.Fragment>
        );
    }

    return (
        <div>
            {btnAgreeOrderDelivery}
            {btnViewOrders}
            {btnCreateOrder}
            {btnChangeShipperCompany}
            {btnFillOrder}
            {btnUploadDriversPhotosOnLoading}
            {btnEnRouteDelivery}
            {btnSetEnroute}
            {btnAgreeWithoutDocs}
            {btnSetArchive}
            {btnShowDriverInfo}
            {btnShowUserDoc}
            {btnShowProcuration}
            {btnShowDriversPhotos}
            {btnNoteActualLoadTime}
            {btnNoteDriverArrivedTime}
            {btnDetachDriver}
            {btnReturnLoadingDelivery}
            {btnReturnToEnRoute}
        </div>
    );
}
