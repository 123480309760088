import {Modal} from "antd";
import React from "react";

export default function ShowTermsOfUse({isShow, setIsShow, setIsTermOfUseAgreed}) {
  return (
    <Modal
      title={`Пользовательское соглашение`}
      cancelText="Закрыть"
      okText={`Принять`}
      className="modal-window modal-window--terms"
      apgn={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
      }}
      onOk={() => {
        setIsTermOfUseAgreed(true);
        setIsShow(false);
      }}
    >
      <div>
        <p>Дата вступления в&nbsp;силу <nobr>02.03.2023 г.</nobr></p>
        <p>Настоящее Пользовательское соглашение (именуемое в&nbsp;дальнейшем &laquo;Соглашение&raquo;) регулирует
          отношения между <nobr>ООО &laquo;Витал&raquo;</nobr> ИНН 2463257198 (именуемое
          в&nbsp;дальнейшем &laquo;Компания&raquo;), а&nbsp;также являющимся правообладателем Сайта, расположенного
          в&nbsp;сети Интернет по&nbsp;адресу &laquo;<a
            href="https://svetofore.com">https://svetofore.com</a>&raquo; (далее&nbsp;&mdash; &laquo;Сайт&raquo;),
          и&nbsp;физическим или юридическим лицом (именуемым в&nbsp;дальнейшем &laquo;Пользователь&raquo;), использующим
          Сайт.
        </p>
        <p>1. Общие положения<br/>
          1.1. Компания сохраняет за&nbsp;собой право изменять настоящее Соглашение в&nbsp;любое время
          без <nobr>какого-либо</nobr> специального уведомления. Если Пользователь продолжает пользоваться Сайтом после
          публикации изменений в&nbsp;Соглашении, считается, что Пользователь тем самым принимает изменения условий
          Соглашения.<br/>
          1.2. Функциональность Сайта определяется Компанией по&nbsp;собственному усмотрению и&nbsp;включает
          в&nbsp;себя, но&nbsp;не&nbsp;ограничивается: предоставлением Пользователю информации, размещаемой
          на&nbsp;Сайте, предоставлением Пользователю возможности размещать свою информацию на&nbsp;Сайте
          и&nbsp;передавать информацию Компании. В&nbsp;отношении отдельной функциональности Сайта Компания вправе
          устанавливать дополнительные условия её предоставления. Для обработки данных Пользователя Компания вправе
          привлекать третьих лиц, оставаясь ответственной за&nbsp;их&nbsp;действия.<br/>
          1.3. Если Пользователь использует функциональность Сайта по&nbsp;приглашению третьего лица, уполномоченного
          Компанией, использование Сайта осуществляется на&nbsp;основании договора между Пользователем и&nbsp;таким
          третьим лицом.
        </p>
        <p>2. Осуществление регистрации на&nbsp;Сайте<br/>
          2.1. При осуществлении регистрации на&nbsp;Сайте Пользователь обязан предоставить Компании информацию,
          запрашиваемую в&nbsp;соответствующих формах на&nbsp;Сайте. Пользователь несет ответственность
          за&nbsp;достоверность, актуальность и&nbsp;соответствие законодательству Российской Федерации информации,
          предоставленной им&nbsp;при осуществлении регистрации и&nbsp;использовании Сайта.<br/>
          2.2. Пользователь не&nbsp;вправе осуществлять регистрацию на&nbsp;Сайте от&nbsp;имени&nbsp;другого лица,
          а&nbsp;также использовать учетные записи третьих лиц.<br/>
          2.3. Регистрируясь на&nbsp;сайте, Пользователь подтверждает, что все указанные им&nbsp;персональные данные
          принадлежат лично ему.<br/>
          2.4. Пользователь обязуется сохранять конфиденциальность сведений, используемых для доступа к&nbsp;Сайту. Все
          действия на&nbsp;Сайте, совершенные с&nbsp;использованием регистрационных сведений Пользователя, считаются
          совершенными Пользователем.</p>
        <p>3. Пользование Сайтом<br/>
          3.1. Пользователь вправе пользоваться функциональностью Сайта, открытой Компаний для конкретного Пользователя.<br/>
          3.2. При пользовании Сайта Пользователь обязан соблюдать положения законодательства Российской Федерации,
          данного Соглашения и&nbsp;иных договоров с&nbsp;Компанией.<br/>
          3.3. При пользовании Сайтом Пользователь не&nbsp;вправе загружать, распространять, публиковать, предоставлять
          доступ или иным образом использовать:<br/>
          <ul>
            <li>информацию, содержащую бранные слова, непристойные и&nbsp;оскорбительные образы, сравнения
              и&nbsp;выражения;
            </li>
            <li>информацию, призывающую к&nbsp;насилию и&nbsp;жестокости, побуждающую к&nbsp;совершению противоправных
              действий, содержащую угрозы в&nbsp;отношении третьих лиц, порочащую честь, достоинство или деловую
              репутацию третьих лиц, содержащую недостоверную информацию о&nbsp;третьих лицах;
            </li>
            <li>вульгарную или непристойную информацию, информацию порнографического характера;</li>
            <li>информацию с&nbsp;ограниченным доступом (в&nbsp;том числе информацию, содержащую государственную,
              коммерческую, или иную тайну, конфиденциальную информацию, информацию о&nbsp;частной жизни третьих лиц);
            </li>
            <li>рекламную информацию, нарушающую требования применимых положений российского законодательства;</li>
            <li>информацию о&nbsp;наркотических и&nbsp;психотропных веществах;</li>
            <li>информацию, направленную на&nbsp;пропаганду войны, разжигание национальной, расовой или религиозной
              ненависти и&nbsp;вражды, а&nbsp;также иную информацию, за&nbsp;распространение которой предусмотрена
              уголовная или административная ответственность согласно законодательству Российской Федерации;
            </li>
            <li>специальные категории персональных данных.</li>
          </ul>
          3.4. При использовании Сайта Пользователь не&nbsp;вправе:<br/>
          &mdash;&nbsp;незаконно использовать результаты интеллектуальной деятельности Компании и&nbsp;третьих лиц;<br/>
          &mdash;&nbsp;нарушать нормальное функционирование Сайта;<br/>
          &mdash;&nbsp;осуществлять <nobr>какие-либо</nobr> действия, которые по&nbsp;мнению Компании являются
          нежелательными для функционирования Сайта;<br/>
          &mdash;&nbsp;нарушать права и&nbsp;интересы третьих лиц;<br/>
          &mdash;&nbsp;осуществлять распространение рекламы на&nbsp;Сайте;<br/>
          &mdash;&nbsp;
          <nobr>каким-либо</nobr>
          иным образом нарушать положения законодательства Российской Федерации.<br/>
          3.5. Размещая на&nbsp;Сайте <nobr>какие-либо</nobr> материалы, Пользователь безвозмездно предоставляет
          Компании простую (неисключительную) лицензию на&nbsp;использование Компанией таких материалов с&nbsp;правом
          сублицензирования на&nbsp;территории всего мира в&nbsp;течение всего срока действия исключительного права
          на&nbsp;такие материалы путём воспроизведения и&nbsp;доведения до&nbsp;всеобщего сведения.<br/>
          3.6. Компания вправе по&nbsp;собственному усмотрению, без объяснения причин и&nbsp;без уведомления
          Пользователя:<br/>
          <ul>
            <li>удалить или заблокировать доступ Пользователя к&nbsp;Сайту;</li>
            <li>удалить и/или обезличить любую информацию, размещенную Пользователем на&nbsp;Сайте;</li>
            <li>ограничить Пользователю возможность размещения информации на&nbsp;Сайте.</li>
          </ul>
        </p>
        <p>4. Ответственность<br/>
          4.1. Пользователь несет ответственность перед Компанией и/или третьими лицами за&nbsp;размещение,
          распространение, публикацию им&nbsp;на&nbsp;Сайте <nobr>какой-либо</nobr> информации, если эти действия
          приведут к&nbsp;нарушению прав и&nbsp;законных интересов Компании и/или третьих лиц.<br/>
          4.2. На&nbsp;Сайте могут размещаться ссылки на&nbsp;сайты в&nbsp;сети Интернет, принадлежащие третьим лицам.
          Компания не&nbsp;несет ответственность за&nbsp;содержание таких сайтов, на&nbsp;которые Пользователь
          переходит по&nbsp;таким ссылкам.<br/>
          4.3. Компания не&nbsp;несет ответственности за&nbsp;любой ущерб, возникший вследствие использования Сайта,
          включая ущерб, возникший <nobr>из-за</nobr> предоставления <nobr>каких-либо</nobr> функций Сайта
          Пользователю, или вследствие удаления <nobr>какой-либо</nobr> информации Пользователя.<br/>
          4.4. Компания не&nbsp;несет ответственности и&nbsp;не&nbsp;имеет прямых или косвенных обязательств перед
          Пользователем в&nbsp;связи с&nbsp;любыми возможными или возникшими потерями или убытками, связанными
          с&nbsp;использованием Сайта, в&nbsp;связи с&nbsp;рекламой, которая может быть размещена на&nbsp;Сайте, или
          в&nbsp;связи с&nbsp;техническими неполадками на&nbsp;Сайте.
        </p>
        <p>5. Заключительные положения<br/>
          5.1. Компания вправе в&nbsp;одностороннем порядке и&nbsp;без уведомления Пользователя расторгнуть данное
          Соглашение.<br/>
          5.2. Действующая редакция Соглашения находится на&nbsp;Сайте на&nbsp;странице по&nbsp;адресу <a
            href="https://svetofore.com">https://svetofore.com</a><br/>
          5.3. Компания вправе передать свои права и/или обязанности по&nbsp;настоящему Соглашению третьей стороне как
          полностью, так и&nbsp;частично.<br/>
          5.4. К&nbsp;настоящему Соглашению и&nbsp;отношениям между Пользователем и&nbsp;Компанией, возникающим
          в&nbsp;связи с&nbsp;исполнением Соглашения, подлежит применению законодательство Российской Федерации.<br/>
          5.5. Признание одного или нескольких положений настоящего Соглашения недействительными или не&nbsp;имеющими
          юридической силы не&nbsp;влияет на&nbsp;действительность или применимость остальных положений настоящего
          Соглашения.</p>
      </div>
    </Modal>
  );
}
